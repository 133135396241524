import { Inject, Injectable } from '@angular/core';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { IMPORT_ARCHIVE_CONFIG } from '@bcdbio/app-tokens';
import { from, Observable } from 'rxjs';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

@Injectable({
    providedIn: 'root',
})
export class FileClient {
    public readonly client: S3Client;

    constructor(
        @Inject(IMPORT_ARCHIVE_CONFIG) private importArchiveConfig: any
    ) {
        this.client = new S3Client({
            region: importArchiveConfig.awsRegion,
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({
                    region: importArchiveConfig.awsRegion,
                }),
                identityPoolId: importArchiveConfig.cognitoIdentityPoolId,
            }),
        });
    }

    public getSignedUrl(filePath: string): Observable<string> {
        const command = new GetObjectCommand({
            Bucket: this.importArchiveConfig.importFileBucket,
            Key: filePath,
        });
        return from(getSignedUrl(this.client, command));
    }
}
