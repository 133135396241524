<form [formGroup]="processEditForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Process</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="bsModalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="metadata-group" formArrayName="generalMetadata">
            <h4>General</h4>
            <ng-container
                *ngFor="let md of generalMetadataGroup.controls; let i = index"
                class="form-group"
            >
                <ng-container
                    *ngIf="
                        generalMetadata[i].name !== 'Notes' &&
                            generalMetadata[i].name !== 'Notebook page';
                        else isFullWidth
                    "
                >
                    <div class="form-group">
                        <input
                            *ngIf="generalMetadata[i].name === 'Date Prepared'"
                            [formControlName]="i"
                            id="general-metadata-group-a-{{ i }}"
                            bsDatepicker
                            autocomplete="off"
                            [bsConfig]="{
                                dateInputFormat: 'MM/DD/YYYY',
                                showWeekNumbers: false
                            }"
                        />
                        <input
                            *ngIf="generalMetadata[i].name !== 'Date Prepared'"
                            type="text"
                            [formControlName]="i"
                            id="general-metadata-group-b-{{ i }}"
                        />
                        <label for="general-metadata-group-b-{{ i }}">
                            {{ generalMetadata[i].name }}
                        </label>
                    </div>
                </ng-container>
                <ng-template #isFullWidth>
                    <div class="form-group full-width">
                        <textarea
                            *ngIf="generalMetadata[i].name === 'Notes'"
                            type="text"
                            [formControlName]="i"
                            id="general-metadata-group-c-{{ i }}"
                        ></textarea>
                        <input
                            *ngIf="generalMetadata[i].name === 'Notebook page'"
                            class="wide-input"
                            type="text"
                            [formControlName]="i"
                            id="general-metadata-group-d-{{ i }}"
                        />
                        <label for="general-metadata-group-d-{{ i }}">
                            {{ generalMetadata[i].name }}
                        </label>
                    </div>
                </ng-template>
            </ng-container>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata"
            *ngIf="processTypeMetadata"
        >
            <h4>{{ process.type }}</h4>
            <div *ngIf="processTypeMetadataGroup.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-{{ i }}"
                />
                <label for="process-type-metadata-{{ i }}">
                    {{ processTypeMetadata[i].name }}
                    {{
                        processTypeMetadata[i].units
                            ? '(' + processTypeMetadata[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div *ngIf="process.type === 'Pre-processing'">
            <form [formGroup]="form">
                <label for="select-select">
                    <h4>Pre-processing Type</h4>
                    <div>
                        <select
                            id="section-select"
                            formControlName="section"
                            (change)="onSectionChange()"
                        >
                            <option value="">Select Pre-processing Type</option>
                            <!-- <option value="Enzyme">Enzyme</option> -->
                            <option value="Solvent/Extraction">
                                Solvent/Extraction
                            </option>
                            <option value="Acid Extraction">
                                Acid Extraction
                            </option>
                            <option value="Base Extraction">
                                Base Extraction
                            </option>
                            <option value="Enzyme Treatment">
                                Enzyme Treatment
                            </option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </label>
            </form>
        </div>

        <!--div class="metadata-group" formArrayName="processTypeMetadata" *ngIf="processTypeMetadata && form.get('section').value === 'Enzyme'">
            <h4>{{ 'Pre-processing: Enzyme' }}</h4>
            <div *ngIf="processTypeMetadataGroup.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-{{ i }}"
                />
                <label for="process-type-metadata-{{ i }}">
                    {{ processTypeMetadata[i].name }}
                    {{
                        processTypeMetadata[i].units
                            ? '(' + processTypeMetadata[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div-->

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_AE"
            *ngIf="
                processTypeMetadata_AE &&
                form.get('section').value === 'Acid Extraction'
            "
        >
            <h4>{{ 'Pre-processing: Acid Extraction' }}</h4>
            <div *ngIf="processTypeMetadataGroup_AE.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_AE.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-ae-{{ i }}"
                />
                <label for="process-type-metadata-ae-{{ i }}">
                    {{ processTypeMetadata_AE[i].name }}
                    {{
                        processTypeMetadata_AE[i].units
                            ? '(' + processTypeMetadata_AE[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_BE"
            *ngIf="
                processTypeMetadata_BE &&
                form.get('section').value === 'Base Extraction'
            "
        >
            <h4>{{ 'Pre-processing: Base Extraction' }}</h4>
            <div *ngIf="processTypeMetadataGroup_BE.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_BE.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-be-{{ i }}"
                />
                <label for="process-type-metadata-be-{{ i }}">
                    {{ processTypeMetadata_BE[i].name }}
                    {{
                        processTypeMetadata_BE[i].units
                            ? '(' + processTypeMetadata_BE[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_ET"
            *ngIf="
                processTypeMetadata_ET &&
                form.get('section').value === 'Enzyme Treatment'
            "
        >
            <h4>{{ 'Pre-processing: Enzyme Treatment' }}</h4>
            <div *ngIf="processTypeMetadataGroup_ET.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_ET.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-et-{{ i }}"
                />
                <label for="process-type-metadata-et-{{ i }}">
                    {{ processTypeMetadata_ET[i].name }}
                    {{
                        processTypeMetadata_ET[i].units
                            ? '(' + processTypeMetadata_ET[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_CF"
            *ngIf="
                processTypeMetadata_CF &&
                form.get('section').value === 'Centrifugal Filter'
            "
        >
            <h4>{{ 'Pre-processing: Centrifugal Filter' }}</h4>
            <div *ngIf="processTypeMetadataGroup_CF.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_CF.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-cf-{{ i }}"
                />
                <label for="process-type-metadata-cf-{{ i }}">
                    {{ processTypeMetadata_CF[i].name }}
                    {{
                        processTypeMetadata_CF[i].units
                            ? '(' + processTypeMetadata_CF[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadataB"
            *ngIf="
                processTypeMetadataB &&
                form.get('section').value === 'Solvent/Extraction'
            "
        >
            <h4>{{ 'Pre-processing: Solvent/Extraction' }}</h4>
            <div *ngIf="processTypeMetadataGroupB.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroupB.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-b-{{ i }}"
                />
                <label for="process-type-metadata-b-{{ i }}">
                    {{ processTypeMetadataB[i].name }}
                    {{
                        processTypeMetadataB[i].units
                            ? '(' + processTypeMetadataB[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>
        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Other"
            *ngIf="
                processTypeMetadata_Other &&
                form.get('section').value === 'Other'
            "
        >
            <h4>Pre-processing: Other</h4>
            <div *ngIf="processTypeMetadataGroup_Other.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Other.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-other-{{ i }}"
                />
                <label for="process-type-metadata-other-{{ i }}">
                    {{ processTypeMetadata_Other[i].name }}
                    {{
                        processTypeMetadata_Other[i].units
                            ? '(' + processTypeMetadata_Other[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div *ngIf="process.type === 'COG'">
            <!--form [formGroup]="form">
                <label for="select-select">
                    <h4>COG Type</h4>
                    <div>
                        <select id="section-select" formControlName="section" (change)="onSectionChange()">
                            <option value="">Select COG Type</option>
                            <option value="COG">COG</option>
                            <option value="COG: Fenton">COG: Fenton</option>
                            <option value="COG: Base Cleavage">Acid Extraction</option>
                        </select>
                    </div>
                </label>
            </form-->
        </div>

        <!-- *ngIf="processTypeMetadata_COG_F && form.get('section').value === 'COG: Fenton'"> -->
        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_COG_F"
            *ngIf="processTypeMetadata_COG_F"
        >
            <h4>{{ 'COG: Fenton' }}</h4>
            <div *ngIf="processTypeMetadataGroup_COG_F.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_COG_F.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-cog-f-{{ i }}"
                />
                <label for="process-type-metadata-{{ i }}">
                    {{ processTypeMetadata_COG_F[i].name }}
                    {{
                        processTypeMetadata_COG_F[i].units
                            ? '(' + processTypeMetadata_COG_F[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <!--*ngIf="processTypeMetadata_COG_BC && form.get('section').value === 'COG: Base Cleavage'"-->
        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_COG_BC"
            *ngIf="processTypeMetadata_COG_BC"
        >
            <h4>{{ 'COG: Base Cleavage' }}</h4>
            <div *ngIf="processTypeMetadataGroup_COG_BC.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_COG_BC.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-cog-bc-{{ i }}"
                />
                <label for="process-type-metadata-cog-bc-{{ i }}">
                    {{ processTypeMetadata_COG_BC[i].name }}
                    {{
                        processTypeMetadata_COG_BC[i].units
                            ? '(' + processTypeMetadata_COG_BC[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <!--div class="metadata-group" formArrayName="processTypeMetadata_COG"
        *ngIf="processTypeMetadata_COG && form.get('section').value === 'COG'">
            <h4>COG</h4>
            <div *ngIf="processTypeMetadataGroup_COG.controls.length === 0">
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_COG.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-cog-{{ i }}"
                />
                <label for="process-type-metadata-cog-{{ i }}">
                    {{ processTypeMetadata_COG[i].name }}
                    {{
                        processTypeMetadata_COG[i].units
                            ? '(' + processTypeMetadata_COG[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div-->

        <div *ngIf="process.type === 'Post-processing'">
            <form [formGroup]="form">
                <label for="select-select">
                    <h4>Post-processing Type</h4>
                    <div>
                        <select
                            id="section-select"
                            formControlName="section"
                            (change)="onSectionChange()"
                        >
                            <option value="">Select Post-processing</option>
                            <option value="Ethanol">Ethanol</option>
                            <option value="Crystallization">
                                Crystallization
                            </option>
                            <option value="Microfilter">Microfilter</option>
                            <option value="Centrifugal Filter">
                                Centrifugal Filter
                            </option>
                            <option value="Ultra Filtration">
                                Ultra Filtration
                            </option>
                            <option value="Nano Filtration">
                                Nano Filtration
                            </option>
                            <option value="Spray Drying">Spray Drying</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </label>
            </form>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_Ethanol"
            *ngIf="
                processTypeMetadata_Post_Ethanol &&
                form.get('section').value === 'Ethanol'
            "
        >
            <h4>{{ 'Post-processing: Ethanol' }}</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_Ethanol.controls.length === 0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_Ethanol.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-ethanol-{{ i }}"
                />
                <label for="process-type-metadata-post-ethanol-{{ i }}">
                    {{ processTypeMetadata_Post_Ethanol[i].name }}
                    {{
                        processTypeMetadata_Post_Ethanol[i].units
                            ? '(' +
                              processTypeMetadata_Post_Ethanol[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_Crystal"
            *ngIf="
                processTypeMetadata_Post_Crystal &&
                form.get('section').value === 'Crystallization'
            "
        >
            <h4>Post-processing: Crystallization</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_Crystal.controls.length === 0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_Crystal.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-crystal-{{ i }}"
                />
                <label for="process-type-metadata-post-crystal-{{ i }}">
                    {{ processTypeMetadata_Post_Crystal[i].name }}
                    {{
                        processTypeMetadata_Post_Crystal[i].units
                            ? '(' +
                              processTypeMetadata_Post_Crystal[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_MicroFilt"
            *ngIf="
                processTypeMetadata_Post_MicroFilt &&
                form.get('section').value === 'Microfilter'
            "
        >
            <h4>Post-processing: Microfilter</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_MicroFilt.controls.length ===
                    0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_MicroFilt.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-microfilt-{{ i }}"
                />
                <label for="process-type-metadata-post-microfilt-{{ i }}">
                    {{ processTypeMetadata_Post_MicroFilt[i].name }}
                    {{
                        processTypeMetadata_Post_MicroFilt[i].units
                            ? '(' +
                              processTypeMetadata_Post_MicroFilt[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_CentrFilt"
            *ngIf="
                processTypeMetadata_Post_CentrFilt &&
                form.get('section').value === 'Centrifugal Filter'
            "
        >
            <h4>Post-processing: Centrifugal Filter</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_CentrFilt.controls.length ===
                    0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_CentrFilt.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-centrfilt-{{ i }}"
                />
                <label for="process-type-metadata-post-centrfilt-{{ i }}">
                    {{ processTypeMetadata_Post_CentrFilt[i].name }}
                    {{
                        processTypeMetadata_Post_CentrFilt[i].units
                            ? '(' +
                              processTypeMetadata_Post_CentrFilt[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_UltraFilt"
            *ngIf="
                processTypeMetadata_Post_UltraFilt &&
                form.get('section').value === 'Ultra Filtration'
            "
        >
            <h4>Post-processing: Ultra Filtration</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_UltraFilt.controls.length ===
                    0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_UltraFilt.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-ultrafilt-{{ i }}"
                />
                <label for="process-type-metadata-post-ultrafilt-{{ i }}">
                    {{ processTypeMetadata_Post_UltraFilt[i].name }}
                    {{
                        processTypeMetadata_Post_UltraFilt[i].units
                            ? '(' +
                              processTypeMetadata_Post_UltraFilt[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_NanoFilt"
            *ngIf="
                processTypeMetadata_Post_NanoFilt &&
                form.get('section').value === 'Nano Filtration'
            "
        >
            <h4>Post-processing: Nano Filtration</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_NanoFilt.controls.length === 0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_NanoFilt.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-nanofilt-{{ i }}"
                />
                <label for="process-type-metadata-post-nanofilt-{{ i }}">
                    {{ processTypeMetadata_Post_NanoFilt[i].name }}
                    {{
                        processTypeMetadata_Post_NanoFilt[i].units
                            ? '(' +
                              processTypeMetadata_Post_NanoFilt[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_SprayDry"
            *ngIf="
                processTypeMetadata_Post_SprayDry &&
                form.get('section').value === 'Spray Drying'
            "
        >
            <h4>Post-processing: Spray Drying</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_SprayDry.controls.length === 0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_SprayDry.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-spraydry-{{ i }}"
                />
                <label for="process-type-metadata-post-spraydry-{{ i }}">
                    {{ processTypeMetadata_Post_SprayDry[i].name }}
                    {{
                        processTypeMetadata_Post_SprayDry[i].units
                            ? '(' +
                              processTypeMetadata_Post_SprayDry[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            class="metadata-group"
            formArrayName="processTypeMetadata_Post_Other"
            *ngIf="
                processTypeMetadata_Post_Other &&
                form.get('section').value === 'Other'
            "
        >
            <h4>Post-processing: Other</h4>
            <div
                *ngIf="
                    processTypeMetadataGroup_Post_Other.controls.length === 0
                "
            >
                No additional metadata
            </div>
            <div
                *ngFor="
                    let md of processTypeMetadataGroup_Post_Other.controls;
                    let i = index
                "
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="process-type-metadata-post-other-{{ i }}"
                />
                <label for="process-type-metadata-post-other-{{ i }}">
                    {{ processTypeMetadata_Post_Other[i].name }}
                    {{
                        processTypeMetadata_Post_Other[i].units
                            ? '(' +
                              processTypeMetadata_Post_Other[i].units +
                              ')'
                            : ''
                    }}
                </label>
            </div>
        </div>

        <div
            *ngIf="showMicrobioMetadata"
            class="metadata-group"
            formArrayName="microBioMetadata"
        >
            <h4>Microbio</h4>
            <div
                *ngFor="let md of microBioMetadataGroup.controls; let i = index"
                class="form-group"
            >
                <input
                    type="text"
                    [formControlName]="i"
                    id="microbio-metadata-{{ i }}"
                />
                <label for="microbio-metadata-{{ i }}">
                    {{ microBioMetadata[i].name }}
                    {{
                        microBioMetadata[i].units
                            ? '(' + microBioMetadata[i].units + ')'
                            : ''
                    }}
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveProcess()">
            Save
        </button>
        <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">
            Close
        </button>
    </div>
</form>
