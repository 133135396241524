import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUi from './+state/ui.reducer';
import {
    UiEffects,
    SearchEffects,
    DataImportEffects,
    ProjectEffects,
    StudyEffects,
} from './+state/ui.effects';
import { UnCamelCasePipe } from './pipes/un-camel-case.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { TableCellStylesPipe } from './pipes/table-cell-styles.pipe';
import { FilePathPipe } from './pipes/file-path.pipe';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromUi.UI_FEATURE_KEY, fromUi.reducer),
        EffectsModule.forFeature([
            UiEffects,
            SearchEffects,
            DataImportEffects,
            StudyEffects,
            ProjectEffects,
        ]),
    ],
    declarations: [
        UnCamelCasePipe,
        PluckPipe,
        TableCellStylesPipe,
        FilePathPipe,
    ],
    exports: [UnCamelCasePipe, PluckPipe, TableCellStylesPipe, FilePathPipe],
})
export class UiModule {}
