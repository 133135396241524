<div class="container top-buffer border rounded p-3 bg-light">
    <div class="row 25 justify-content-center align-items-center">
        <h1>BCD Unified Database</h1>
    </div>
    <div class="row justify-content-center top-buffer">
        <bcdbio-authentication-button
            class="d-block"
        ></bcdbio-authentication-button>
    </div>
</div>
