import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tableCellStyles',
})
export class TableCellStylesPipe implements PipeTransform {
    transform(analyte: string, value: any): any {
        switch (analyte) {
            case 'exampleColumnNameHere':
                return {
                    backgroundColor: 'red',
                };
            default:
                return null;
        }
    }
}
