import { DataImportValidator } from './data-import-validator';
import { DataImportDataSource } from '../data/data-import-data-source';
import { DataImportData } from '../data/data-import-data';
import { Observable, of } from 'rxjs';
import { DataImportType } from '@bcdbio/data';

export class DataImportValidatorSource extends DataImportValidator {
    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        // validate header
        const headerRow = data.getHeaderRow();
        if (headerRow.length !== DataImportDataSource.NUM_COLUMNS) {
            errors.push(
                `Header row should have ${DataImportDataSource.NUM_COLUMNS} columns.  Received ${headerRow.length}.`
            );
        } else {
            // validate matching headers
            for (let h = 0; h < DataImportDataSource.NUM_COLUMNS; h++) {
                if (headerRow[h] !== DataImportDataSource.HEADERS[h]) {
                    errors.push(
                        `Header mismatch: Expected '${DataImportDataSource.HEADERS[h]}'.  Received '${headerRow[h]}'.`
                    );
                }
            }
        }

        // validate all rows have correct number of columns
        const rows = data.getDataRows();
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].length !== DataImportDataSource.NUM_COLUMNS) {
                errors.push(
                    `Row ${i + 1}: Expected ${
                        DataImportDataSource.NUM_COLUMNS
                    } columns.  Received ${rows[i].length}.`
                );
            }
            const dateTimeStr =
                rows[i][DataImportDataSource.HEADERS.indexOf('Date Received')];
            if (isNaN(Date.parse(dateTimeStr))) {
                errors.push(
                    `${name} (${dateTimeStr}): Invalid date for 'Date Received'.`
                );
            }
        }

        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
