<div class="container">
    <div class="row">
        <div class="col-md-8"></div>
        <div
            *ngIf="!isTrayNameAllSelected"
            class="col-md-4 d-flex justify-content-end"
        >
            <button type="button" class="btn btn-link" (click)="selectAll()">
                Select All
            </button>
            <button
                class="btn btn-outline-primary float-md-right ml-3"
                type="button"
                [disabled]="selectedSamplesCount === 0"
                (click)="openExportModal(exportConfirmation)"
            >
                Export Selected
                <span *ngIf="selectedSamplesCount > 0"
                    >({{ selectedSamplesCount }})</span
                >
            </button>
        </div>
        <div *ngIf="isTrayNameAllSelected" class="col-md-4">
            <button
                disabled
                type="button"
                class="btn btn-outline-primary invisible-button"
            >
                Invisible button
            </button>
        </div>
    </div>
</div>
<tabset>
    <tab
        *ngFor="let tray of trayNames"
        [heading]="tray"
        id="summary-tab"
        (selectTab)="selectTrayName($event)"
    >
        <div
            *ngIf="grid.length > 0; else noData"
            class="table-responsive shaded"
        >
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th></th>
                        <th *ngFor="let header of xAxisLabels">
                            <div class="rotated">
                                <a
                                    [routerLink]="['/sample-detail', header]"
                                    [matTooltip]="header"
                                >
                                    {{
                                        header
                                            | bcdIdTruncatePipe
                                                : columnHeaderLength
                                    }}
                                </a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tr
                    *ngFor="let dataRow of grid; index as i"
                    class="thead-light"
                >
                    <ng-container *ngFor="let cell of dataRow; index as i">
                        <th *ngIf="i === 0" class="yAxisHeader">
                            <a
                                [routerLink]="['/sample-detail', cell.label]"
                                [matTooltip]="cell.label"
                            >
                                {{
                                    cell.label
                                        | bcdIdTruncatePipe : rowHeaderLength
                                }}
                            </a>
                        </th>
                        <td
                            *ngIf="i !== 0"
                            (click)="onClickCell(cell, $event, tray)"
                            [ngClass]="{ selected: cell.isSelected }"
                            [matTooltip]="cell.label"
                            matTooltipClass="larger-tooltip"
                        >
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <ng-container
                                    *ngFor="
                                        let type of observedDataTypesInSamples;
                                        let i = index
                                    "
                                >
                                    <span
                                        *ngIf="cell.observedDataType[type]"
                                        [style.backgroundColor]="
                                            observedDataColors[i]
                                        "
                                        [matTooltip]="type"
                                        class="observed-data-icon"
                                    ></span>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </div>
        <ng-template #noData
            ><p class="mt-2">
                No trays associated with this study id yet.
            </p></ng-template
        >
    </tab>
</tabset>

<!-- Modal -->
<ng-template #exportConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">Export...</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="exportData">
            <div *ngFor="let type of exportDataTypes">
                <mat-checkbox [formControlName]="type">
                    {{ type }}
                </mat-checkbox>
            </div>
        </form>

        <bcdbio-export-samples
            class="float-md-right"
            [buttonLabel]="'Export (' + selectedDataSetsCount + ')'"
            [samplesToExport]="samplesToExport"
            [includeMetadata]="exportData.controls['Metadata'].value"
            [studyId]="studyId"
            (clickExport)="onClickExport()"
        ></bcdbio-export-samples>
        <button
            type="button"
            class="btn btn-outline-danger float-right"
            (click)="modalRef.hide()"
        >
            Cancel
        </button>
    </div>
</ng-template>
