import { DataImportDataTabular } from './data-import-data-tabular';

export class DataImportDataMetabAnalytes extends DataImportDataTabular {
    public static HEADERS = [
        '',
        'Name',
        'Data File',
        'Type',
        'Level',
        'Acq. Date-Time',
        'Dil',
        'Pos.',
        'User Defined',
        'Time',
        'Experiment',
        'Formate Results|Final Conc.',
        'Propionate Results|Final Conc.',
        'Butyrate Results|Final Conc.',
        'Isobutyrate Results|Final Conc.',
        'Isovalerate Results|Final Conc.',
        'Valerate Results|Final Conc.',
        '3-Hydroxybutyrate Results|Final Conc.',
        'Glycerate Results|Final Conc.',
        'Hexanoate Results|Final Conc.',
        'Isovalerylglycine Results|Final Conc.',
        'L-Acetylcarnitine Results|Final Conc.',
        'Propionylcarnitine Results|Final Conc.',
        'Acetate Results|Final Conc.',
        'Benzoate Results|Final Conc.',
        'L-Malate Results|Final Conc.',
        'Citrate Results|Final Conc.',
        'Pyruvate Results|Final Conc.',
        'Succinate Results|Final Conc.',
        'Isocitrate Results|Final Conc.',
        '2-Hydroxyglutarate Results|Final Conc.',
        'Lactate Results|Final Conc.',
        '2-Hydroxybutyrate Results|Final Conc.',
        'Butyrylcarnitine Results|Final Conc.',
    ];

    constructor(data: Array<string[]>) {
        super(data);
        this.headers = DataImportDataMetabAnalytes.HEADERS;
    }
}
