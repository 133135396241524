import { GenericDataset } from './generic-dataset.model';
import { DataRow } from './data-row.model';

export class MonoDataset extends GenericDataset {
    defaultColumnOrder = [
        'Glucose',
        'Galactose',
        'Fructose',
        'Xylose',
        'Arabinose',
        'Fucose',
        'Rhamnose',
        'GlcA',
        'GalA',
        'GlcNAc',
        'GalNAc',
        'Mannose',
        'Allose',
        'Ribose',
        'NeuAc',
        'NeuGc',
        'IdoA',
        'Apiose',
        'GlcN',
        'GalN',
    ];

    constructor(
        observationDate: Date,
        data?: Array<DataRow>,
        uuid?: string,
        monoMethodType?: string,
        sopVersion?: string
    ) {
        super(observationDate, data, uuid, monoMethodType, sopVersion);
        this.observationDate = observationDate;
        this.monoMethodType = monoMethodType;
        this.sopVersion = sopVersion;
        // necessary since super doesnt have mono analytes in defaultColumnOrder
        if (data) {
            this.appendFieldsToDefaultColumnOrder(data);
            this.markMissingFieldsNull(data);
            this.data = data;
        }
    }

    getGQLMeasurementInputs(): any[] {
        // make sure all analytes are in input obj
        return this.data.map((measurements) => {
            this.defaultColumnOrder.forEach((analyte) => {
                if (!(analyte in measurements)) {
                    measurements[analyte] = null;
                }
            });
            return measurements;
        });
    }
}
