import {
    DataImportFileReader,
    DataImportFileReaderResults,
} from './data-import-file-reader';
import { Papa } from 'ngx-papaparse';
import { Observable } from 'rxjs';

export class DataImportFileReaderCsv extends DataImportFileReader {
    readFile(file: File): Observable<DataImportFileReaderResults> {
        return new Observable((subscriber) => {
            const papa = new Papa();

            papa.parse(file, {
                header: false,
                worker: true,
                complete: (parseResult) => {
                    const rows = (parseResult.data as Array<string[]>).filter(
                        (r) => r.length > 1 && r.some(Boolean)
                    );
                    subscriber.next({
                        data: rows,
                        errors: parseResult.errors.map(
                            (err) => `(Row ${err.row}): ${err.message}`
                        ),
                    });
                    subscriber.complete();
                },
                error: (fileError) => {
                    console.log('papaparse error', fileError);
                    subscriber.next({
                        data: [],
                        errors: [fileError.message],
                    });
                    subscriber.complete();
                },
            });
        });
    }
}
