import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { saveSampleMetadata, UiPartialState } from '@bcdbio/ui';
import { FormArray, FormBuilder } from '@angular/forms';
import { Sample, SampleApiService } from '@bcdbio/data';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bcdbio-sample-metadata-edit',
    templateUrl: './sample-metadata-edit.component.html',
    styleUrls: ['./sample-metadata-edit.component.scss'],
})
export class SampleMetadataEditComponent implements OnInit {
    sample: Sample;
    subscriptions = new Subscription();
    sourceMetadataNames: string[] = [];

    sampleMetadataEditForm = this.fb.group({
        sourceMetadata: this.fb.array([]),
    });

    constructor(
        public bsModalRef: BsModalRef,
        private storeUi: Store<UiPartialState>,
        private sampleApiService: SampleApiService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        // todo: need to get the date received field and sort out the name field
        //     this.sourceMetadataNames.push('name');
        this.subscriptions.add(
            this.sampleApiService
                .getSampleTypesMetadata()
                .subscribe((sampleTypes) => {
                    const metadata = sampleTypes
                        .find((st) => st.name === this.sample.type)
                        .metadata.map((md) => md.name);
                    if (metadata !== null) {
                        metadata.forEach((md) => {
                            this.sourceMetadataNames.push(md);
                        });
                    }

                    metadata?.forEach((md, idx) => {
                        this.sourceMetadata.push(
                            this.fb.control(this.getSampleMetadataValue(md))
                        );
                        if (this.isUneditable(md)) {
                            this.sourceMetadata.controls[idx].disable();
                        }
                    });
                })
        );
    }

    getSampleMetadataValue(name: string) {
        let retValue = '';
        if (this.sample?.metadata && this.sample.metadata[name]) {
            retValue = this.sample.metadata[name].toString();
        }
        return retValue;
    }

    get sourceMetadata() {
        return this.sampleMetadataEditForm.get('sourceMetadata') as FormArray;
    }

    saveSample() {
        //    todo: possible have 'source' and 'sample' groups in analogy to process edit
        const metadata = this.sourceMetadataNames.reduce((acc, name, idx) => {
            acc[name] = this.sourceMetadata.at(idx).value;
            return acc;
        }, {});

        this.storeUi.dispatch(
            saveSampleMetadata({
                id: this.sample.id,
                metadata: metadata,
            })
        );

        this.bsModalRef.hide();
    }

    formatMetadataLabel(md: string): string {
        return md.replace(/((?<=[a-z])([A-Z]))|(([A-Z])(?=[a-z]))/g, ' $1');
    }

    isUneditable(nameOfMetadata: string): boolean {
        return (
            this.sample.uneditableMetadata &&
            this.sample.uneditableMetadata[nameOfMetadata]
        );
    }
}
