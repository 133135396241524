import { DataImportPersist } from './data-import-persist';
import { Sample } from '../../model/sample.model';
import { Observable } from 'rxjs';
import { SaveResult } from '../sample-save-result';
import { MapperData } from '../mapping/mapper-data';
import { DataImportType } from '@bcdbio/data';

export class DataImportPersistTray extends DataImportPersist {
    save(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return this.processApiService.saveTrayData(data);
    }
}
