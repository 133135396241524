import { Sample, SampleType } from '../../model/sample.model';
import { DataImportMapper } from './data-import-mapper';
import { DataImportData } from '../data/data-import-data';

export class DataImportMapperSource extends DataImportMapper {
    public MAPPING = {
        'Data Source': 'dataSource',
        'Unique Identification Number': 'id',
        'Lay Name (all details)': 'name',
        'Plant Part Analyzed': 'plantPart',
        'Primary Lay Categorization': 'primaryCategory',
        'Secondary Categorization': 'secondaryCategory',
        'Plant Name': 'plantName',
        'Variety or Unique IDs': 'variety',
        Source: 'source',
        'Location of raw material': 'location',
        'NDA or MTA Restrictions?': 'ndaMta',
        'Date Received': 'dateReceived',
        'From Person': 'fromPerson',
        Phone: 'phone',
        'From Address': 'fromAddress',
        'Additional information about sample': 'addlInfo',
        Notes: 'notes',
    };

    public rowMap(headerRow: string[], row: string[]): Sample {
        const sample = new Sample();
        sample.id = row[headerRow.indexOf('Unique Identification Number')];
        sample.type = SampleType.Source;
        sample.metadata = {};

        for (let i = 0; i < row.length; i++) {
            if (row[i].length > 0) {
                sample.metadata[this.MAPPING[headerRow[i]]] = row[i];
            }
        }

        return sample;
    }
}
