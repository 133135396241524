import { DataImportPersist } from './data-import-persist';
import { DataImportPersistSource } from './data-import-persist-source';
import { Injectable } from '@angular/core';
import { SampleApiService } from '../../sample-api.service';
import { DataImportPersistMono } from './data-import-persist-mono';
import { DataImportPersistLinkage } from './data-import-persist-linkage';
import { DataImportPersistTray } from './data-import-persist-tray';
import { DataImportPersistGrowth } from './data-import-persist-growth';
import { DataImportPersistFile } from './data-import-persist-file';
import { DataImportType } from '../data-import-type';
import { ProcessApiService } from '../../process-api.service';
import { DataImportPersistFastqMapping } from './data-import-persist-fastq-mapping';
import { DataImportPersistMetabAnalytes } from './data-import-persist-metab-analytes';

@Injectable({
    providedIn: 'root',
})
export class DataImportPersistFactory {
    constructor() {}

    public static createPersist(
        sampleApiService: SampleApiService,
        processApiService: ProcessApiService,
        dataFileType: DataImportType
    ): DataImportPersist {
        switch (dataFileType) {
            case DataImportType.SOURCE:
                return new DataImportPersistSource(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.MONO:
                return new DataImportPersistMono(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.LINKAGE:
                return new DataImportPersistLinkage(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.TRAY_METADATA:
                return new DataImportPersistTray(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.GROWTH_CURVE:
                return new DataImportPersistGrowth(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.FASTQ_MAPPING:
                return new DataImportPersistFastqMapping(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.METABOLOMICS_ANALYTES:
                return new DataImportPersistMetabAnalytes(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.FASTQ:
            case DataImportType.METABOLOMICS:
                return new DataImportPersistFile(
                    sampleApiService,
                    processApiService
                );
            case DataImportType.FECAL_SOURCE:
            case DataImportType.SINGLE_STRAIN:
            case DataImportType.FECAL_SLURRY:
            case DataImportType.FECAL_POOL:
                return new DataImportPersistSource(
                    sampleApiService,
                    processApiService
                );
            default:
                throw Error('DataImportPersistFactory: Invalid data file type');
        }
    }
}
