import { DataImportValidator } from './data-import-validator';
import { SamplesByBcdIdGQL } from '@bcdbio/udb-graphql';
import { DataImportData } from '../data/data-import-data';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataImportType } from '../data-import-type';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataImportValidatorFile extends DataImportValidator {
    constructor(private samplesByBcdIdGQL: SamplesByBcdIdGQL) {
        super();
    }

    public static getFilenamePattern(dataFileType: DataImportType): RegExp {
        switch (dataFileType) {
            case DataImportType.METABOLOMICS:
                return /([\w-]+)\.metab/;
        }
    }

    private static getTextFilenamePattern(
        dataFileType: DataImportType
    ): string {
        switch (dataFileType) {
            case DataImportType.METABOLOMICS:
                return '[SampleID].metab';
        }
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const m = file.name.match(
            DataImportValidatorFile.getFilenamePattern(dataFileType)
        );
        if (!m || m.length < 2) {
            const stringDesc =
                DataImportValidatorFile.getTextFilenamePattern(dataFileType);
            return of({
                isValid: false,
                errors: [
                    `File name does not match expected pattern '${stringDesc}'.`,
                ],
            });
        } else {
            return this.samplesByBcdIdGQL.fetch({ bcdIds: [m[1]] }).pipe(
                map((result) => {
                    if (result.data.samples.length === 1) {
                        return {
                            isValid: true,
                            errors: [],
                        };
                    } else {
                        return {
                            isValid: false,
                            errors: [`'${m[1]}' does not match a Sample ID`],
                        };
                    }
                })
            );
        }
    }
}
