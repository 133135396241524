import { DataImportMapper } from './data-import-mapper';
import { Sample } from '../../model/sample.model';
import { Observable, of } from 'rxjs';
import { MapperData } from './mapper-data';
import { DataImportValidatorFile } from '../validator/data-import-validator-file';
import { DataImportData, DataImportType } from '@bcdbio/data';

export class DataImportMapperFile extends DataImportMapper {
    map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const m = file.name.match(
            DataImportValidatorFile.getFilenamePattern(dataFileType)
        );
        // Just a dummy Sample, which already exists, so that the file can be attached to it during the save.
        const s: Sample = new Sample();
        s.id = m[1];
        return of({
            samples: [s],
        });
    }

    rowMap(headerRow: string[], row: string[]): Sample {
        throw new Error(
            'rowMap() should not be called on DataImportMapperFile'
        );
    }
}
