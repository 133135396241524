<ng-container *ngIf="currentSample">
    <div class="container bottom-buffer" style="padding-bottom: 100px">
        <div class="row top-buffer">
            <div class="col-md-8 d-flex flex-nowrap">
                <h2>{{ currentSample?.id }}</h2>
                <div>
                    <a
                        class="nav-link"
                        href="https://bcdbio.slite.com/p/TWkV44_ue-BJSP/Samples"
                        target="_blank"
                    >
                        <i class="bi-box-arrow-up-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-4 sample-action-panel">
                <bcdbio-export-samples
                    class="float-md-right"
                    [buttonLabel]="'Export Sample'"
                    [samplesToExport]="[currentSample]"
                    [includeMetadata]="true"
                ></bcdbio-export-samples>
                <button
                    class="btn btn-outline-primary float-md-right"
                    type="button"
                    (click)="createProcessAndSample()"
                >
                    Create Process/Sample
                </button>
            </div>
        </div>

        <ng-container
            *ngIf="currentSample.isSourceSample(); else isDerivativeSample"
        >
            <div class="top-buffer bcd-info-panel">
                <div class="row">
                    <div class="col-sm-6">
                        <h3>Sample info ({{ currentSample.type }} sample)</h3>
                    </div>
                    <div class="col-sm-6">
                        <button
                            class="btn btn-outline-primary float-md-right"
                            type="button"
                            (click)="editSampleMetadata()"
                        >
                            Edit
                        </button>
                    </div>
                </div>
                <bcdbio-sample-metadata
                    [sample]="currentSample"
                ></bcdbio-sample-metadata>

                <div *ngIf="currentSample.type === sampleType.Source">
                    <h4>Ontology</h4>
                    <bcdbio-sample-ontology
                        [sample]="currentSample"
                    ></bcdbio-sample-ontology>
                </div>
                <div *ngIf="currentSample.type === sampleType.SingleStrain">
                    <h4>Taxonomy</h4>
                    <div class="sample-taxonomy">
                        {{ currentSample.taxonomy }}
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="urlWidgetWrap"></ng-container>
            </div>
        </ng-container>

        <ng-template #isDerivativeSample>
            <div class="top-buffer border rounded p-3 bg-light">
                <div class="row">
                    <div class="col-sm-6">
                        <h3>Process Info</h3>
                    </div>
                    <div class="col-sm-6">
                        <button
                            class="btn btn-outline-primary float-md-right"
                            type="button"
                            (click)="editProcess()"
                        >
                            Edit
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <bcdbio-parent-process-metadata
                            [process]="currentSample.parentProcess"
                        ></bcdbio-parent-process-metadata>
                    </div>
                </div>

                <ng-container *ngTemplateOutlet="urlWidgetWrap"></ng-container>
            </div>
        </ng-template>

        <div
            class="top-buffer bcd-info-panel"
            *ngIf="currentSample.observedData"
        >
            <h3>Observed Data</h3>
            <ng-container
                *ngIf="!currentSample.observedData; else hasObservedData"
            >
                <p>No observations present.</p>
            </ng-container>
            <ng-template #hasObservedData>
                <div
                    *ngFor="
                        let observationDataset of currentSample.observedData
                            | keyvalue
                    "
                    class="col-md-12"
                >
                    <bcdbio-display-tabular-data
                        *ngIf="!isFileData(observationDataset.value)"
                        [displayData]="observationDataset.value"
                        [userIsAdmin]="userIsAdmin"
                    ></bcdbio-display-tabular-data>
                    <bcdbio-display-file-data
                        *ngIf="isFileData(observationDataset.value)"
                        [displayData]="observationDataset.value"
                    ></bcdbio-display-file-data>
                </div>
            </ng-template>
        </div>

        <div class="top-buffer bcd-info-panel">
            <ng-container *ngIf="currentSample.parentProcess">
                <div class="border rounded p-3 bg-light">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3>Sample info</h3>
                        </div>
                        <div class="col-sm-6">
                            <button
                                class="btn btn-outline-primary float-md-right"
                                type="button"
                                (click)="editSampleMetadata()"
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                    <bcdbio-sample-metadata
                        [sample]="currentSample"
                    ></bcdbio-sample-metadata>
                </div>
            </ng-container>
            <bcdbio-source-sample-metadata
                *ngIf="
                    currentSample.type === sampleType.FecalSlurry &&
                    currentSample.parentSamples[0].type ===
                        sampleType.FecalSource
                "
                [sample]="currentSample"
            ></bcdbio-source-sample-metadata>
            <bcdbio-pool-sources
                *ngIf="
                    currentSample.type === sampleType.FecalPool ||
                    currentSample.type === sampleType.SpikedFecalPool
                "
                [sample]="currentSample"
            ></bcdbio-pool-sources>
            <div class="top-buffer bcd-info-panel">
                <bcdbio-ancestry-graph
                    [sampleAncestry]="currentSample?.ancestry"
                ></bcdbio-ancestry-graph>
            </div>
        </div>
        <div class="top-buffer border rounded p-3 bg-light" *ngIf="userIsAdmin">
            <div class="row">
                <div class="col-sm-6">
                    <h3 class="text-danger">Delete Sample/Sub-samples</h3>
                </div>
                <div class="col-sm-6">
                    <button
                        style="margin-top: 20px"
                        class="btn btn-outline-danger float-md-right"
                        type="button"
                        (click)="deleteSample()"
                    >
                        Delete Sample/Sub-samples
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #urlWidgetWrap>
    <div>
        <button
            *ngIf="!isEditingSampleUrls"
            class="btn btn-outline-primary float-md-right"
            type="button"
            (click)="isEditingSampleUrls = true"
        >
            Edit
        </button>
        <button
            *ngIf="isEditingSampleUrls && isUrlInfoListDirty"
            class="btn btn-primary float-md-right"
            type="button"
            (click)="upsertDeleteSampleUrls()"
        >
            Save
        </button>
        <button
            *ngIf="isEditingSampleUrls"
            class="btn btn-outline-danger float-md-right"
            type="button"
            (click)="cancelUrlInfoListEdits()"
        >
            Cancel
        </button>
    </div>
    <bcdbio-url-info-widget
        [editModeEnabled]="isEditingSampleUrls"
        [existingUrlInfo]="existingUrlInfo"
        [urlInfoToAdd]="urlInfoToAdd"
        [urlInfoToDelete]="existingUrlInfoToDelete"
        [isUrlInfoListDirty]="isUrlInfoListDirty"
        (clickAddUrl)="onClickAddUrl($event)"
        (toggleExistingUrlInfo)="onToggleExistingUrlInfo($event)"
        (toggleAddedUrlInfo)="onToggleAddedUrlInfo($event)"
        (mergeUrlInfo)="onMergeUrlInfo($event)"
    >
    </bcdbio-url-info-widget>
</ng-template>
