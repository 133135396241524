import {
    Component,
    Input,
    OnChanges,
    Pipe,
    PipeTransform,
    TemplateRef,
} from '@angular/core';
import { Sample } from '@bcdbio/udb-graphql';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { SampleWithObservedData } from '../../pages/study-detail/study-detail.component';
import {
    BiologicalSampleTypes,
    ChemicalSampleTypes,
    DataImportType,
    Sample as BcdSample,
} from '@bcdbio/data';
import { Router } from '@angular/router';
interface SelectableCell {
    label?: string;
    samples: Sample[];
    isSelected: boolean;
    observedDataType: {
        [key: string]: any;
    };
}

export const TRUNCATOR = '(...)';
export const COLUMN_HEADER_LENGTH = 35;
export const ROW_HEADER_LENGTH = 22;
const LENGTH_BCD_ID = 8;

@Pipe({
    name: 'bcdIdTruncatePipe',
})
export class BcdIdTruncatePipe implements PipeTransform {
    transform(
        bcdId: string,
        length: number,
        truncIndicator = TRUNCATOR
    ): string {
        if (bcdId.length > length) {
            const beginningOfId = bcdId.substring(0, LENGTH_BCD_ID);
            const restOfString = bcdId.substring(LENGTH_BCD_ID, bcdId.length);
            const numAffixCharsToKeep: number = Math.floor(
                (length - LENGTH_BCD_ID - truncIndicator.length) / 2
            );
            const middleTruncatedRestOfString =
                restOfString.substring(0, numAffixCharsToKeep) +
                truncIndicator +
                restOfString.substring(
                    restOfString.length - numAffixCharsToKeep,
                    restOfString.length
                );
            return beginningOfId + middleTruncatedRestOfString;
        }
        return bcdId;
    }
}

@Component({
    selector: 'bcdbio-selectable-table',
    templateUrl: './selectable-table.component.html',
    styleUrls: ['./selectable-table.component.scss'],
})
export class SelectableTableComponent implements OnChanges {
    selectedSamplesCount = 0;
    selectedDataSetsCount = 0;
    observedDataTypesInSamples;
    exportDataTypes;
    observedDataColors = [
        '#89b674',
        '#00B8FF',
        '#FFD588',
        '#FF8196',
        '#9999CC',
        '#042B00',
        '#FA8C5E',
        '#96B0B7',
    ];
    @Input() samples: SampleWithObservedData[];
    @Input() trayNames: string[];
    @Input() studyId: string;
    modalRef: BsModalRef;
    exportData: FormGroup;
    samplesToExport: Sample[];
    xAxisLabels: string[];
    yAxisLabels: string[];
    grid: SelectableCell[][] = [];
    columnHeaderLength = COLUMN_HEADER_LENGTH;
    rowHeaderLength = ROW_HEADER_LENGTH;
    isTrayNameAllSelected = true;

    constructor(private modalService: BsModalService, private router: Router) {}

    ngOnChanges(): void {
        this.trayNames = Array.from(new Set(this.trayNames));
        this.trayNames.unshift('All'); // adds to front of array
        if (this.samples) {
            this.determineParentsAndDataTypesFromStudyRelatedSamples();
            this.initTableAxisLabels();
            this.buildGridFromSamples();
        }
    }

    initTableAxisLabels() {
        this.grid = new Array(this.yAxisLabels.length)
            .fill(null)
            .map(() => new Array(this.xAxisLabels.length + 1).fill(null));
        this.initBioParentLabel();
        this.resetCells();
    }

    selectTrayName(tray) {
        this.selectedSamplesCount = 0;
        if (tray.heading === 'All') {
            this.isTrayNameAllSelected = true;
            this.buildGridFromSamples();
        } else {
            this.isTrayNameAllSelected = false;
            this.buildGridFromSamples(tray.heading);
        }
    }

    determineParentsAndDataTypesFromStudyRelatedSamples() {
        const yAxisParents = new Set();
        const xAxisParents = new Set();
        this.observedDataTypesInSamples = [];
        this.samples.forEach((sample) => {
            const bioParent = BcdSample.getBiologicalParentFromSample(sample);
            const chemicalParent =
                BcdSample.getChemicalParentFromSample(sample);
            if (bioParent) {
                yAxisParents.add(bioParent.bcdId);
            }
            if (chemicalParent) {
                xAxisParents.add(chemicalParent.bcdId);
            }
            Object.keys(sample.observedData).forEach((type) => {
                if (!this.observedDataTypesInSamples.includes(type)) {
                    this.observedDataTypesInSamples.push(type);
                }
            });
        });
        this.exportDataTypes = ['Metadata'].concat(
            this.observedDataTypesInSamples
        );

        this.yAxisLabels = Array.from(yAxisParents) as string[];
        this.xAxisLabels = Array.from(xAxisParents) as string[];
    }

    initBioParentLabel() {
        this.grid.forEach((row, index) => {
            row[0] = {
                label: this.yAxisLabels[index],
                samples: null,
                isSelected: false,
                observedDataType: {},
            };
        });
    }

    resetCells() {
        this.grid.forEach((row) => {
            row.forEach((cell, index) => {
                if (index !== 0) {
                    row[index] = {
                        label: null,
                        samples: [],
                        isSelected: false,
                        observedDataType: {},
                    };
                }
            });
        });
    }

    getSampleTrayName(s: Sample) {
        if (s && s.outputByProcess && s.outputByProcess.metadata) {
            const trayNameMetadata = s.outputByProcess.metadata.find((data) => {
                return data.metadata.name === 'Tray Name';
            });
            if (trayNameMetadata) {
                return trayNameMetadata?.value;
            }
        } else {
            console.log('Sample: ', s.bcdId, ' missing associated tray name.');
        }
    }

    populateGridCell(s: Sample, trayNameFilter?: string) {
        if (trayNameFilter && this.getSampleTrayName(s) !== trayNameFilter) {
            return; // Grid won't include this sample
        }
        const bioParent = BcdSample.getBiologicalParentFromSample(s);
        const chemicalParent = BcdSample.getChemicalParentFromSample(s);
        if (bioParent && chemicalParent) {
            const yIndex = this.yAxisLabels.indexOf(bioParent.bcdId);
            const xIndex = this.xAxisLabels.indexOf(chemicalParent.bcdId) + 1;
            this.grid[yIndex][xIndex].label = s.bcdId;
            this.grid[yIndex][xIndex].samples.push(s);
        }
    }

    computeAndDrawGridCellSampleTypes() {
        this.grid.forEach((row) => {
            row.forEach((cell: SelectableCell, index) => {
                if (index !== 0) {
                    const observedDataTypes = {};
                    this.observedDataTypesInSamples.forEach((type: string) => {
                        observedDataTypes[type] = cell.samples.some(
                            (s: SampleWithObservedData) => {
                                return Object.keys(s.observedData).includes(
                                    type
                                );
                            }
                        );
                    });
                    row[index].observedDataType = observedDataTypes;
                }
            });
        });
    }

    buildGridFromSamples(withTrayFilter?: string) {
        this.resetCells();
        this.samples.forEach((s) => {
            this.populateGridCell(s, withTrayFilter);
        });
        this.computeAndDrawGridCellSampleTypes();
    }

    onClickCell(c: SelectableCell, event: MouseEvent, tray) {
        if (tray !== 'All') {
            if (event.shiftKey) {
                this.router.navigate(['/sample-detail', c.label]);
            }
            if (c.isSelected) {
                this.selectedSamplesCount -= c.samples.length;
                c.isSelected = false;
            } else {
                this.selectedSamplesCount += c.samples.length;
                c.isSelected = true;
            }
        }
    }

    getSelectedSamples(): SampleWithObservedData[] {
        let selectedSamples = [];
        this.grid.forEach((r) => {
            r.forEach((cell) => {
                if (cell.samples && cell.isSelected) {
                    selectedSamples = selectedSamples.concat(cell.samples);
                }
            });
        });
        return selectedSamples;
    }

    filterSelectedSamplesByExportTypes(selectedExportTypes) {
        let selectedCount = 0;
        const filteredSamplesWithData = this.getSelectedSamples().map(
            (sample: SampleWithObservedData) => {
                const filteredObservedData = {};
                selectedExportTypes.forEach((type) => {
                    if (type in sample.observedData) {
                        filteredObservedData[type] = sample.observedData[type];
                        selectedCount += sample.observedData[type].length;
                    } else if (type === 'Metadata') {
                        selectedCount += 1;
                    }
                });
                return {
                    ...sample,
                    observedData: filteredObservedData,
                };
            }
        );
        this.selectedDataSetsCount = selectedCount;
        return filteredSamplesWithData;
    }

    onClickExport() {
        this.modalRef.hide();
    }

    openExportModal(template: TemplateRef<any>) {
        this.exportData = new FormGroup({});
        this.exportDataTypes.forEach((value) => {
            this.exportData.addControl(value, new FormControl(false));
        });
        let bcdIds = [];
        this.grid.forEach((r) => {
            r.forEach((cell) => {
                if (cell.samples) {
                    bcdIds = bcdIds.concat(cell.samples.map((s) => s.bcdId));
                }
            });
        });
        this.modalRef = this.modalService.show(template);
        this.exportData.valueChanges.subscribe((value) => {
            const selectedExportTypes = [];
            Object.keys(value).forEach((entry) => {
                if (value[entry]) {
                    selectedExportTypes.push(entry);
                }
            });
            this.samplesToExport =
                this.filterSelectedSamplesByExportTypes(selectedExportTypes);
            this.samples.filter((sample) =>
                selectedExportTypes.includes(sample.sampleType)
            );
        });
    }

    selectAll() {
        this.selectedSamplesCount = 0;
        this.grid.forEach((r) => {
            r.forEach((cell) => {
                if (cell.samples) {
                    cell.isSelected = true;
                    this.selectedSamplesCount += cell.samples.length;
                }
            });
        });
    }
}
