import { DataRow } from './data-row.model';
import { GrowthCalculatedDataset } from './growth-calculated-dataset.model';

export class FecalFermentGrowthCalculatedDataset extends GrowthCalculatedDataset {
    defaultColumnOrder = [
        'Replicate',
        ...this.coreODColumnOrder,
        ...this.additionalGrowthColumns,
    ];

    constructor(observationDate: Date, data?: Array<DataRow>, uuid?: string) {
        super(observationDate, data, uuid);
    }
}
