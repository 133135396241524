import { DataImportData } from './data-import-data';

export class DataImportDataTray extends DataImportData {
    public static HEADERS = [
        'Column',
        'Row',
        'Micro_sampleID',
        'Biologic_source_ID',
        'Glycan_source_ID',
    ];
}
