import { Observable } from 'rxjs';

export abstract class DataImportFileReader {
    constructor() {}

    abstract readFile(file: File): Observable<DataImportFileReaderResults>;
}

export interface DataImportFileReaderResults {
    data: Array<string[]>;
    errors: string[];
}
