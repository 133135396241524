import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import {
    DataImportType,
    GenericDataset,
    GrowthCalculatedDataset,
    Sample,
} from '@bcdbio/data';
import {
    deleteMeasurement,
    deleteSampleAndDescendants,
    UiPartialState,
} from '@bcdbio/ui';
import { formatDate } from '@angular/common';

@Component({
    selector: 'bcdbio-delete-sample-data-modal',
    templateUrl: './delete-sample-data-modal.component.html',
    styleUrls: ['./delete-sample-data-modal.component.scss'],
})
export class DeleteSampleDataModalComponent implements OnInit {
    sample: Sample;
    measurement: GenericDataset;
    datasetTitle: string;
    title: string;
    isGrowthCurveData: boolean;
    growthCurveVersion: string;
    observedDate: string;

    constructor(
        public bsModalRef: BsModalRef,
        private storeUi: Store<UiPartialState>
    ) {}

    ngOnInit(): void {
        if (this.sample) {
            this.title = 'Delete Sample and Sub-samples';
        } else if (this.measurement) {
            this.title = 'Delete Observed Data';
        }
        this.isGrowthCurveData =
            this.datasetTitle === DataImportType.GROWTH_CURVE;
        this.growthCurveVersion = this.isGrowthCurveData
            ? 'Version ' +
              String(
                  this.measurement?.data[0][GrowthCalculatedDataset.VERSION_KEY]
              )
            : '';
        this.observedDate =
            this.measurement?.observationDate != null
                ? formatDate(
                      this.measurement.observationDate,
                      'M/d/y',
                      'en-US',
                      'GMT'
                  )
                : 'Date N/A';
    }

    delete(): void {
        if (this.sample) {
            this.storeUi.dispatch(
                deleteSampleAndDescendants({
                    sampleId: this.sample.id,
                    isSourceSample: this.sample.isSourceSample(),
                })
            );
        } else if (this.measurement) {
            this.storeUi.dispatch(
                deleteMeasurement({
                    measurementUuid: this.measurement.uuid,
                })
            );
        }
        this.bsModalRef.hide();
    }
}
