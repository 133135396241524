<!--todo: check if sample is source or not and decide whether to render a source block or a nonsource block-->
<form [formGroup]="sampleMetadataEditForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Sample</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="bsModalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group metadata-group" formArrayName="sourceMetadata">
            <h4>{{ sample.type }} metadata</h4>
            <div *ngFor="let md of sourceMetadata.controls; let i = index">
                <ng-container
                    *ngIf="
                        sourceMetadataNames[i] !== 'ndaMta';
                        else isNdaMtaField
                    "
                >
                    <label class="">
                        {{
                            formatMetadataLabel(sourceMetadataNames[i])
                                | titlecase
                        }}
                        <input type="text" [formControlName]="i" />
                    </label>
                </ng-container>
                <ng-template #isNdaMtaField>
                    <label class="emphasize-metadata">
                        NDA/MTA
                        <input type="text" [formControlName]="i" />
                    </label>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveSample()">
            Save
        </button>
        <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">
            Close
        </button>
    </div>
</form>
