import { ObservedDataset } from './observed-dataset.model';
import { Process } from './process.model';
import { Ancestry } from './ancestry.model';
import { Sample as SampleGQL, Sample_Url } from '@bcdbio/udb-graphql';
import * as moment from 'moment';
import { MeasurementFromGqlGenerator } from './measurement-from-gql-generator';
import { DataImportType } from '../data-import/data-import-type';
import { forEach } from 'lodash';
export const TAXONOMY_RANKS = [
    'kingdom',
    'phylum',
    'class',
    'order',
    'family',
    'genus',
    'species',
];

export enum SampleType {
    Source = 'Source',
    SingleStrain = 'Single Strain',
    FecalSource = 'Fecal Source',
    FecalSlurry = 'Fecal Slurry',
    FecalPool = 'Fecal Pool',
    SpikedFecalPool = 'Spiked Fecal Pool',
    Processed = 'Processed',
    Cross = 'Cross',
}

export const DISPLAYED_SOURCE_METADATA = [
    'dataSource',
    'name',
    'source',
    'dateReceived',
    'ndaMta',
    'fromPerson',
    'fromAddress',
    'location',
    'addlInfo',
    'notes',
    'lastEditDate',
    'lastEditUser',
];
export const DISPLAYED_SINGLE_STRAIN_METADATA = [
    'genus',
    'species',
    'strain',
    'otherStrainNames',
    'ndaMta',
    'bsl',
    'mediaCommercial',
    'mediaMinimal',
    'apTimeToLateExp',
    'storageLocation',
    'propagationNotes',
    'lastEditDate',
    'lastEditUser',
];
export const DISPLAYED_FECAL_SAMPLE_METADATA = [
    'donorNumber',
    'collectionDate',
    'expirationDate',
    'storageLocation',
    'ndaMta',
    'unitId',
    'sex',
    'type',
    'healthStatus',
    'aliquotesLeft',
    'appearance',
    'notes',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_FECAL_SLURRY_METADATA = [
    'name',
    'storageLocation',
    'aliquotesLeft',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_SOURCE_FECAL_SAMPLE_METADATA = [
    'name',
    'donorNumber',
    'sex',
    'collectionDate',
    'healthStatus',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_FECAL_POOL_METADATA = [
    'name',
    'sex',
    'storageLocation',
    'aliquotesLeft',
    'healthStatus',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_SPIKED_FECAL_POOL_METADATA = [
    'name',
    'storageLocation',
    'aliquotesLeft',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_PROCESSED_METADATA = [
    'name',
    'location',
    'aliquotesLeft',
    'notes',
    'lastEditDate',
    'lastEditUser',
];
const DISPLAYED_CROSS_METADATA = [
    'name',
    'notes',
    'lastEditDate',
    'lastEditUser',
];

export const BiologicalSampleTypes = [
    SampleType.SingleStrain,
    SampleType.FecalSource,
    SampleType.FecalSlurry,
    SampleType.FecalPool,
    SampleType.SpikedFecalPool,
];

export const ChemicalSampleTypes = [SampleType.Source, SampleType.Processed];

export class Sample {
    id: string;
    name: string;
    source: {
        id: string;
        name: string;
        metadata?: { [key: string]: number | string | Date };
    };
    sampleRows: Sample_Url[];
    parentProcess: Process | null;
    childProcesses: Array<Process> = [];
    metadata: { [key: string]: number | string | Date };
    uneditableMetadata: { [key: string]: boolean };
    ancestry: Ancestry;
    ontology: { [key: string]: string };
    taxonomy: string;
    observedData: { [key: string]: Array<ObservedDataset> };
    type: string;
    displayedSampleMetadata: string[];
    displayedSourceSampleMetadata: string[];
    ancestorSamples: Sample[];
    parentSamples: Sample[];
    poolInputMetadata: { [key: string]: number | string | Date }[];

    public static fromGQLData(sampleFromGql) {
        const sample = new Sample();
        if (sampleFromGql && sampleFromGql.bcdId) {
            sample.id = sampleFromGql.bcdId;
            sample.sampleRows = sampleFromGql.sampleRows;
            Sample.setTypeAndMetadataDisplayed(
                sample,
                sampleFromGql.sampleType?.name
            );

            Sample.setSampleMetadata(sample, sampleFromGql);
            Sample.setProcessInfo(sample, sampleFromGql);
            Sample.setOntology(sample, sampleFromGql);
            Sample.setTaxonomy(sample, sampleFromGql);
            Sample.setAncestry(
                sample,
                sampleFromGql,
                sampleFromGql.sourceDescendantSamples
            );
            if (sample.isSourceSample()) {
                sample.source = {
                    id: sampleFromGql.bcdId,
                    name: sampleFromGql.name,
                };
            } else {
                sample.source = {
                    id: sampleFromGql.sourceSamples[0]?.bcdId,
                    name: sampleFromGql.sourceSamples[0]?.name,
                    metadata: this.getSourceSampleMetadata(sampleFromGql),
                };
            }
            sample.ancestorSamples = sampleFromGql.ancestorSamples;
            Sample.setParentSamples(sample, sampleFromGql);
            sample.addMeasurementsToSample(sampleFromGql.measurements);
            sample.poolInputMetadata = sampleFromGql.poolInputMetadata;
        }
        return sample;
    }

    private static addParentsFromGqlToAncestry(
        sample: Sample,
        parentsFromGql: any,
        sourceDescendantsFromGql: any
    ) {
        parentsFromGql?.map((parentFromGql) => {
            const parentSample = Sample.makeSampleForAncestryFromGqlData(
                sample,
                parentFromGql
            );
            parentSample.ancestry = {
                currentSample: parentSample,
                childSamples: [sample],
                parentSamples: [],
            };
            const descendantFromGqlMatchingCurrentParent =
                sourceDescendantsFromGql?.find(
                    (descendant) =>
                        descendant.bcdId &&
                        descendant.bcdId === parentFromGql.bcdId
                );
            Sample.setProcessInfo(
                parentSample,
                descendantFromGqlMatchingCurrentParent
            );
            Sample.setAncestry(
                parentSample,
                descendantFromGqlMatchingCurrentParent,
                sourceDescendantsFromGql
            );
            sample.ancestry.parentSamples.push(parentSample);
        });
    }

    private static setAncestry(
        sample: Sample,
        sampleFromGql: any,
        sourceDescendantsFromGql: any
    ) {
        sample.ancestry = sample.ancestry
            ? sample.ancestry
            : {
                  currentSample: sample,
                  childSamples: [],
                  parentSamples: [],
              };
        //recursion bottoms out when sample has at least one parent in ancestry/the gql version of sample has no parents
        const sampleAncestryHasNoParents =
            sample.ancestry.parentSamples.length === 0;
        if (sampleAncestryHasNoParents) {
            Sample.addParentsFromGqlToAncestry(
                sample,
                sampleFromGql?.parentSamples,
                sourceDescendantsFromGql
            );
        }
        //recursion bottoms out when there are no children in the sampleFromGql
        Sample.addChildrenFromGqlToAncestry(
            sample,
            sampleFromGql?.childSamples,
            sourceDescendantsFromGql
        );
    }

    private static addChildrenFromGqlToAncestry(
        sample: Sample,
        childrenFromGql: any,
        sourceDescendantsFromGql: any
    ) {
        childrenFromGql?.map((childFromGql) => {
            const childSampleFromAlreadySetAncestry =
                sample.ancestry.childSamples.find(
                    (child) => child.id === childFromGql?.bcdId
                );
            if (!childSampleFromAlreadySetAncestry) {
                const childSample = Sample.makeSampleForAncestryFromGqlData(
                    sample,
                    childFromGql
                );
                childSample.ancestry = {
                    currentSample: childSample,
                    childSamples: [],
                    parentSamples: [sample],
                };
                const descendantFromGqlMatchingCurrentChild =
                    sourceDescendantsFromGql?.find(
                        (descendant) =>
                            descendant.bcdId &&
                            descendant.bcdId === childFromGql.bcdId
                    );
                Sample.setProcessInfo(
                    childSample,
                    descendantFromGqlMatchingCurrentChild,
                    childFromGql
                );
                Sample.setAncestry(
                    childSample,
                    descendantFromGqlMatchingCurrentChild,
                    sourceDescendantsFromGql
                );
                sample.ancestry.childSamples.push(childSample);
            }
        });
    }

    private static makeSampleForAncestryFromGqlData(
        currentSample: Sample,
        sampleFromGql: any
    ): Sample {
        const sampleForAncestry = new Sample();
        sampleForAncestry.id = sampleFromGql.bcdId;
        sampleForAncestry.ontology = currentSample.ontology;
        sampleForAncestry.type = sampleFromGql.sampleType?.name;
        Sample.setSampleMetadata(sampleForAncestry, sampleFromGql);
        sampleForAncestry.parentProcess = sampleFromGql.outputByProcess;
        return sampleForAncestry;
    }

    //todo: need to loop over parent and children to add to arrays (method to handle this?)
    private static setProcessInfo(
        sample: Sample,
        sampleFromGql: any,
        childFromGql = null
    ) {
        const gqlSample = sampleFromGql?.outputByProcess?.bcdId
            ? sampleFromGql
            : childFromGql;
        if (gqlSample?.outputByProcess?.bcdId) {
            sample.parentProcess = {
                id: gqlSample.outputByProcess.bcdId,
                type: gqlSample.outputByProcess?.processType.name,
                parentSamples: [],
                childSamples: [],
                metadata: null,
                metadataUnits: null,
            };

            if (gqlSample.outputByProcess.metadata) {
                sample.parentProcess.metadata = {};
                sample.parentProcess.metadataUnits = {};
                sample.parentProcess.uneditableMetadata = {};
                gqlSample.outputByProcess.metadata.forEach((md) => {
                    if (!sample.parentProcess.metadata[md.metadataGroup.name]) {
                        sample.parentProcess.metadata[md.metadataGroup.name] =
                            {};
                    }
                    if (md.metadata.name === 'Cells') {
                        sample.parentProcess.metadata[md.metadataGroup.name][
                            'Process[' + md.replicate + ']'
                        ] = md.value;
                    } else {
                        sample.parentProcess.metadata[md.metadataGroup.name][
                            md.metadata.name
                        ] = md.value;
                    }

                    if (
                        !sample.parentProcess.metadataUnits[
                            md.metadataGroup.name
                        ]
                    ) {
                        sample.parentProcess.metadataUnits[
                            md.metadataGroup.name
                        ] = {};
                    }
                    sample.parentProcess.metadataUnits[md.metadataGroup.name][
                        md.metadata.name
                    ] = md.metadata.units;

                    if (
                        !sample.parentProcess.uneditableMetadata[
                            md.metadataGroup.name
                        ]
                    ) {
                        sample.parentProcess.uneditableMetadata[
                            md.metadataGroup.name
                        ] = {};
                    }
                    if (md.uneditable) {
                        sample.parentProcess.uneditableMetadata[
                            md.metadataGroup.name
                        ][md.metadata.name] = true;
                    }
                });
            }
        } else {
            sample.parentProcess = null;
        }
        sample.childProcesses = [];
    }

    // todo: use sampleFromGql.metadata.dateReceived instead?
    private static setSampleMetadata(sample: Sample, sampleFromGql: any) {
        sample.metadata = {};
        sample.uneditableMetadata = {};

        if (!sampleFromGql.metadata) {
            return; // no metadata requested in GQL
        }
        sampleFromGql.metadata.forEach((item) => {
            sample.metadata[item.name] = item.value;
            if (item.uneditable) {
                sample.uneditableMetadata[item.name] = true;
            }
        });
        if (sample.metadata.dateReceived) {
            sample.metadata.dateReveived = new Date(
                sample.metadata.dateReveived
            );
        }
        sample.metadata['name'] = sampleFromGql.name;
    }

    private static getSourceSampleMetadata(sampleFromGql: any) {
        const metadata = {};
        sampleFromGql.sourceSamples[0]?.metadata.forEach((item) => {
            metadata[item.name] = item.value;
        });
        metadata['name'] = sampleFromGql.sourceSamples[0]?.name;
        return metadata;
    }

    // todo: skip ontology keys when setting other metadata?
    private static setOntology(sample: Sample, sampleFromGql: any) {
        const sourceMetadata = this.extractSourceMetadata(
            sample,
            sampleFromGql
        );

        sample.ontology = {
            plantName: sourceMetadata.plantName as string,
            plantPart: sourceMetadata.plantPart as string,
            primaryLayCategory: sourceMetadata.primaryCategory as string,
            secondaryLayCategory: sourceMetadata.secondaryCategory as string,
        };
    }

    private static setTaxonomy(sample: Sample, sampleFromGql: any) {
        let taxonomy = '';
        const sourceMetadata = this.extractSourceMetadata(
            sample,
            sampleFromGql
        );

        for (const rank of TAXONOMY_RANKS) {
            if (sourceMetadata[rank]) {
                taxonomy += sourceMetadata[rank];
                if (rank !== 'species') {
                    taxonomy += ' -> ';
                }
            }
        }
        sample.taxonomy = taxonomy;
    }

    private static extractSourceMetadata(sample: Sample, sampleFromGql: any) {
        let sourceMetadata: { [key: string]: number | string | Date } = {};

        if (sample.isSourceSample()) {
            sourceMetadata = sample.metadata;
        } else {
            const sourceSamples = sampleFromGql.sourceSamples;
            if (sourceSamples && sourceSamples.length > 0) {
                for (const item of sampleFromGql.sourceSamples[0].metadata) {
                    sourceMetadata[item.name] = item.value;
                }
            }
        }
        return sourceMetadata;
    }

    private static setTypeAndMetadataDisplayed(sample, sampleType) {
        switch (sampleType) {
            case 'Single Strain':
                sample.type = SampleType.SingleStrain;
                sample.displayedSampleMetadata =
                    DISPLAYED_SINGLE_STRAIN_METADATA;
                break;
            case 'Fecal Source':
                sample.type = SampleType.FecalSource;
                sample.displayedSampleMetadata =
                    DISPLAYED_FECAL_SAMPLE_METADATA;
                break;
            case 'Fecal Slurry':
                sample.type = SampleType.FecalSlurry;
                sample.displayedSampleMetadata =
                    DISPLAYED_FECAL_SLURRY_METADATA;
                sample.displayedSourceSampleMetadata =
                    DISPLAYED_SOURCE_FECAL_SAMPLE_METADATA;
                break;
            case 'Fecal Pool':
                sample.type = SampleType.FecalPool;
                sample.displayedSampleMetadata = DISPLAYED_FECAL_POOL_METADATA;
                break;
            case 'Spiked Fecal Pool':
                sample.type = SampleType.SpikedFecalPool;
                sample.displayedSampleMetadata =
                    DISPLAYED_SPIKED_FECAL_POOL_METADATA;
                break;
            case 'Source':
                sample.type = SampleType.Source;
                sample.displayedSampleMetadata = DISPLAYED_SOURCE_METADATA;
                break;
            case 'Processed':
                sample.type = SampleType.Processed;
                sample.displayedSampleMetadata = DISPLAYED_PROCESSED_METADATA;
                break;
            case 'Cross':
                sample.type = SampleType.Cross;
                sample.displayedSampleMetadata = DISPLAYED_CROSS_METADATA;
                break;
            default:
                sample.type = SampleType.Source;
                sample.displayedSampleMetadata = DISPLAYED_SOURCE_METADATA;
                break;
        }
    }

    private static setParentSamples(sample: Sample, sampleFromGql: any) {
        sample.parentSamples = [];
        if (!sampleFromGql.parentSamples) {
            return; // no parent samples requested in GQL
        }
        if (sampleFromGql.parentSamples.length > 0) {
            sampleFromGql.parentSamples.forEach((parentSample) => {
                const tempSample = {
                    ...parentSample,
                    id: parentSample.bcdId,
                    type: parentSample.sampleType?.name,
                };
                sample.parentSamples.push(tempSample);
            });
        }
    }

    public static getBiologicalParentFromSample(sample: SampleGQL) {
        const bioParent = sample.parentSamples.find((s) =>
            (<string[]>BiologicalSampleTypes).includes(s.sampleType?.name)
        );
        if (bioParent) {
            return bioParent;
        } else {
            console.log(
                'Biological parent of sample: ',
                sample.bcdId || sample.name,
                ' not found.'
            );
        }
    }
    public static getChemicalParentFromSample(sample: SampleGQL) {
        const chemicalParent = sample.parentSamples.find((s) =>
            (<string[]>ChemicalSampleTypes).includes(s.sampleType?.name)
        );
        if (chemicalParent) {
            return chemicalParent;
        } else {
            console.log(
                'Chemical parent of sample: ',
                sample.bcdId || sample.name,
                ' not found.'
            );
        }
    }

    private insertMeasurementFromGqlIntoCorrespondingDateBin(measurement: any) {
        const obsMoment: moment.Moment = moment
            .utc(measurement.observationDate)
            .startOf('day');

        // see if there's an Observed Dataset for this timestamp already
        const timestampBin = this.observedData[
            measurement.measurementType.name
        ].find((dataset) => {
            const dsMoment = moment.utc(dataset.observationDate).startOf('day');
            if (dataset.observationDate) {
                return dsMoment.isValid() ? dsMoment.isSame(obsMoment) : false;
            } else {
                return !dsMoment.isValid() && !obsMoment.isValid(); // true if obsDate and dataset.observationDate are both nullish
            }
        });

        if (timestampBin) {
            timestampBin.addDataRowFromGQLMeasurement(measurement);
        } else {
            const observedDataBioSampleTypes = [
                SampleType.SingleStrain,
                SampleType.FecalSlurry,
                SampleType.FecalPool,
            ];
            const bioParentSample = this.parentSamples?.find((ps: any) => {
                return observedDataBioSampleTypes.includes(ps.type);
            });
            const bioParentSampleType = bioParentSample
                ? bioParentSample.type
                : '';
            this.observedData[measurement.measurementType.name].push(
                MeasurementFromGqlGenerator.fromGQLMeasurementData(
                    measurement,
                    bioParentSampleType
                )
            );
        }
    }

    public isSourceSample() {
        return this.parentProcess == null;
    }

    private transformGrowthCurveData(measurement: any): any {
        if (measurement && measurement.data) {
            const rawOrCalculatedData = measurement.data[0];
            if (
                rawOrCalculatedData &&
                rawOrCalculatedData.name === 'Calculated Values' &&
                rawOrCalculatedData.stringValue !== undefined
            ) {
                const growthJSONObject = JSON.parse(
                    rawOrCalculatedData.stringValue
                );
                growthJSONObject['Replicate'] = rawOrCalculatedData.replicate;
                growthJSONObject['Media'] = '';
                if (this.parentProcess) {
                    growthJSONObject['Assay'] =
                        this.parentProcess.metadata['Interaction'][
                            'Process[' + rawOrCalculatedData.replicate + ']'
                        ];
                    if (this.parentProcess.metadata['Interaction']['Media']) {
                        growthJSONObject['Media'] =
                            this.parentProcess.metadata['Interaction']['Media'];
                    }
                }

                if (this.parentSamples) {
                    this.parentSamples.forEach((sample) => {
                        if (
                            (<string[]>BiologicalSampleTypes).includes(
                                sample.type
                            )
                        )
                            growthJSONObject['Bug'] = sample.name;
                        if (
                            (<string[]>ChemicalSampleTypes).includes(
                                sample.type
                            )
                        )
                            growthJSONObject['Glycan'] = sample.name;
                        // TODO:  see if below is needed
                        //if (growthJSONObject['Media'] === '' && sample.type === 'Single Strain') {
                        //    sample.metadata.forEach((md)=>{
                        //        if (md['name'] === 'mediaMinimal' || md['name'] === 'mediaCommercial') {
                        //            growthJSONObject['Media'] = md['value'];
                        //        }
                        //    });
                        //}
                    });
                }

                const transformedData = Object.keys(growthJSONObject).map(
                    (key) => ({
                        name: key,
                        value: growthJSONObject[key],
                    })
                );
                return {
                    ...measurement,
                    data: transformedData,
                    replicate: rawOrCalculatedData.replicate,
                };
            }
        }
        return null;
    }

    private transformMeasurementData(measurement: any): any {
        if (measurement && measurement.measurementType) {
            switch (measurement.measurementType.name) {
                case DataImportType.GROWTH_CURVE:
                    return this.transformGrowthCurveData(measurement);
                default:
                    return measurement;
            }
        }
        return null;
    }

    private addMeasurementsToSample(measurements: any[]) {
        this.observedData = this.observedData || {};
        measurements.forEach((measurement) => {
            const validMeasurement = this.transformMeasurementData(measurement);
            if (validMeasurement) {
                this.observedData[measurement.measurementType.name] =
                    this.observedData[measurement.measurementType.name] || [];
                this.insertMeasurementFromGqlIntoCorrespondingDateBin(
                    validMeasurement
                );
            }
        });
    }
}
