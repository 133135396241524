import { DataImportData } from './data-import-data';

/**
 * Class to handle imports that are just a file upload.
 */
export class DataImportDataFile extends DataImportData {
    getDataRows(): Array<string[]> {
        return undefined;
    }

    getHeaderRow(): string[] {
        return [];
    }
}
