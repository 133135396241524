import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UI_FEATURE_KEY, State, UiPartialState, uiAdapter } from './ui.reducer';

// Lookup the 'Ui' feature state managed by NgRx
export const getUiState = createFeatureSelector<State>(UI_FEATURE_KEY);

const { selectAll, selectEntities } = uiAdapter.getSelectors();

export const getUiLoaded = createSelector(
    getUiState,
    (state: State) => state.loaded
);

export const getUiError = createSelector(
    getUiState,
    (state: State) => state.error
);

export const getAllUi = createSelector(getUiState, (state: State) =>
    selectAll(state)
);

export const getUiEntities = createSelector(getUiState, (state: State) =>
    selectEntities(state)
);

export const getSelectedId = createSelector(
    getUiState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getUiEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getSearchResults = createSelector(
    getUiState,
    (state: State) => state.searchResults
);

export const getCurrentSample = createSelector(
    getUiState,
    (state: State) => state.currentSample
);

export const getCurrentStudy = createSelector(
    getUiState,
    (state: State) => state.currentStudy
);

export const getStudies = createSelector(
    getUiState,
    (state: State) => state.studies
);

export const getStudyListRetrieved = createSelector(
    getUiState,
    (state: State) => state.studyListRetrieved
);

export const getCurrentProject = createSelector(
    getUiState,
    (state: State) => state.currentProject
);

export const getProjects = createSelector(
    getUiState,
    (state: State) => state.projects
);

export const getUsers = createSelector(
    getUiState,
    (state: State) => state.users
);

export const getRoles = createSelector(
    getUiState,
    (state: State) => state.roles
);

export const getCurrentSampleId = createSelector(
    getUiState,
    (state: State) => state.currentSampleId
);

export const getDataImportConsoleLog = createSelector(
    getUiState,
    (state: State) => state.dataImportConsoleLog
);

export const getSearchQueryParameters = createSelector(
    getUiState,
    (state: State) => state.searchQuery
);

export const getPoolSourcesSearchQueryParameters = createSelector(
    getUiState,
    (state: State) => state.poolSourcesSearchQuery
);

export const getRelatedSamplesSearchQueryParameters = createSelector(
    getUiState,
    (state: State) => state.relatedSamplesSearchQuery
);

export const getExportSampleIds = createSelector(
    getUiState,
    (state: State) => state.exportSampleIds
);
export const getListSearchTerm = createSelector(
    getUiState,
    (state: State) => state.listSearchTerm
);
export const getStudyListSort = createSelector(
    getUiState,
    (state: State) => state.studyListSort
);
