import { DataRow } from './data-row.model';
import { deviation, mean } from 'd3';
import { DatasetSummaryStats } from './dataset-summary-stats.model';
import { ObservedDataset } from './observed-dataset.model';

export class GenericDataset implements ObservedDataset {
    defaultColumnOrder: string[] = [];
    observationDate: Date = undefined;
    data: Array<DataRow> = [];
    hasSummaryStats = true;
    uuid: string;

    monoMethodType: string;
    sopVersion: string;
    monoMethodTypes: Array<string> = [];
    sopVersions: Array<string> = [];

    constructor(
        observationDate: Date,
        data?: Array<DataRow>,
        uuid?: string,
        monoMethodType?: string,
        sopVersion?: string
    ) {
        this.observationDate = observationDate;
        if (data) {
            this.appendFieldsToDefaultColumnOrder(data);
            this.markMissingFieldsNull(data);
            this.data = data;
        }

        if (uuid) {
            this.uuid = uuid;
        }

        if (monoMethodType) {
            // monoMethodType is used when importing Mono data to distinguish standard, visco, short.
            this.monoMethodType = monoMethodType;
            this.monoMethodTypes.push(monoMethodType);
        }

        if (sopVersion) {
            this.sopVersion = sopVersion;
            this.sopVersions.push(sopVersion);
        }
    }

    public static flattenGQLMeasurementData(measurementFromGql): DataRow {
        const flattenedDataRow: DataRow = {};
        measurementFromGql.data.forEach((analyte) => {
            flattenedDataRow[analyte.name] = analyte.value;
        });
        if (measurementFromGql.replicate) {
            flattenedDataRow['Replicate'] = measurementFromGql.replicate;
        }
        return flattenedDataRow;
    }

    public static analyteAvgAcrossDataRows(
        analyte: string,
        dataRows: Array<DataRow>
    ): number {
        const analyteValues = [];
        dataRows.forEach((dataRow) => analyteValues.push(dataRow[analyte]));
        return mean(analyteValues.filter((value) => typeof value === 'number'));
    }

    public static analyteStdDevAcrossDataRows(
        analyte: string,
        dataRows: Array<DataRow>
    ): number {
        const analyteValues = [];
        dataRows.forEach((dataRow) => analyteValues.push(dataRow[analyte]));
        return deviation(
            analyteValues.filter((value) => typeof value === 'number')
        );
    }

    public static sortAnalytesByAbundance(dataRow: DataRow): string[] {
        return Object.keys(dataRow).sort((a, b) => {
            if (dataRow[a] > dataRow[b] || dataRow[b] == null) {
                return -1;
            } else if (dataRow[a] === dataRow[b]) {
                return 0;
            } else {
                return 1;
            }
        });
    }

    public static sumOverDataRowValues(dataRow: DataRow): number {
        return Object.values(dataRow).reduce((a, b) => (b ? a + b : a), 0);
    }

    public addDataRowFromGQLMeasurement(measurementFromGql) {
        //console.log("given gql measurement to add as a DataRow:", measurementFromGql);
        const dataRow =
            GenericDataset.flattenGQLMeasurementData(measurementFromGql);
        const dataRowContainer = [dataRow];

        this.appendFieldsToDefaultColumnOrder(dataRowContainer);
        this.markMissingFieldsNull(dataRowContainer);

        this.data.push(dataRow);
        this.monoMethodTypes.push(measurementFromGql.monoMethodType);
        this.sopVersions.push(measurementFromGql.sopVersion);
        // console.log("added row to dataset, current data object:", this.data);
    }

    public getGQLDateInput() {
        const obsDate = this.observationDate || new Date();
        return {
            year: obsDate.getFullYear(),
            month: obsDate.getMonth() + 1,
            day: obsDate.getDate(),
            hour: (obsDate.getHours() + 1) % 24,
            minute: obsDate.getMinutes(),
            second: obsDate.getSeconds(),
        };
    }

    public getGQLMeasurementInputs() {
        return this.data;
    }

    public getSummaryStats(): DatasetSummaryStats {
        const result: DatasetSummaryStats = {
            analyteAbundanceOrder: [],
            analyteAvgValues: {},
            analyteStdDevs: {},
            avgNormalizationFactor: undefined,
            dataRowTotals: [],
            avgOfTotals: undefined,
            stdDevOfTotals: undefined,
        };
        result.analyteAvgValues = this.generateAvgOfDataRows(this.data);
        result.analyteAbundanceOrder = GenericDataset.sortAnalytesByAbundance(
            result.analyteAvgValues
        );
        result.analyteStdDevs = this.generateStdDevOfDataRows(this.data);
        result.avgNormalizationFactor = GenericDataset.sumOverDataRowValues(
            result.analyteAvgValues
        );
        this.data.forEach((row) => {
            result.dataRowTotals.push(GenericDataset.sumOverDataRowValues(row));
        });
        result.avgOfTotals = mean(result.dataRowTotals);
        result.stdDevOfTotals = deviation(result.dataRowTotals);
        return result;
    }

    protected generateAvgOfDataRows(dataRows: DataRow[]): DataRow {
        const avgDataRow: DataRow = {};
        this.defaultColumnOrder.forEach((analyte) => {
            avgDataRow[analyte] = GenericDataset.analyteAvgAcrossDataRows(
                analyte,
                dataRows
            );
        });

        return avgDataRow;
    }

    protected generateStdDevOfDataRows(dataRows: DataRow[]): DataRow {
        const stdDevDataRow = {};
        this.defaultColumnOrder.forEach((analyte) => {
            stdDevDataRow[analyte] = GenericDataset.analyteStdDevAcrossDataRows(
                analyte,
                dataRows
            );
        });

        return stdDevDataRow;
    }

    protected appendFieldsToDefaultColumnOrder(data: Array<DataRow>) {
        data.forEach((row) => {
            for (const analyte in row) {
                if (!this.defaultColumnOrder.includes(analyte)) {
                    this.defaultColumnOrder.push(analyte);
                }
            }
        });
    }

    protected markMissingFieldsNull(data: Array<DataRow>) {
        data.forEach((row) => {
            for (const field of this.defaultColumnOrder) {
                row[field] = row[field] === 0 || row[field] ? row[field] : null;
            }
        });
    }

    processViscoData() {
        /*
            3. Viscozyme (Vz) files need to undergo processing steps after import but before the data is displayed.
                ◦ Average the replicates of the sample called “Viscozyme Blank”.
                ◦ For each monosaccharide (column), subtract the average-viscozyme-blank from each sample (row).
                ◦ Set any negative values to 0.
        */

        // Create an Array of the visco Blank datarows.
        const blankData: Array<DataRow> = [];
        const blankIndexes: number[] = [];
        this.monoMethodTypes.forEach((datatype, index) => {
            if (
                datatype != null &&
                datatype.toLowerCase().includes('blank') &&
                datatype.toLowerCase().includes('vz')
            ) {
                blankData.push(this.data[index]);
                blankIndexes.push(index);
            }
        });

        // Create the Visco Blank mean array, then handle unassigned values.
        var blankMean = this.generateAvgOfDataRows(blankData);

        // Remove "Blank" datarows and datatypes
        var filteredData = this.data.filter(
            (_, index) => !blankIndexes.includes(index)
        );
        var filteredMonoMethodTypes = this.monoMethodTypes.filter(
            (_, index) => !blankIndexes.includes(index)
        );
        var filteredSOPVersions = this.sopVersions.filter(
            (_, index) => !blankIndexes.includes(index)
        );
        var results: Array<DataRow> = [];

        // Subtract mean array from datarows with "vz"
        for (let i = 0; i < filteredData.length; i++) {
            if (
                filteredMonoMethodTypes[i] != null &&
                filteredMonoMethodTypes[i].toLowerCase().includes('vz')
                ) {
                const avgDataRow: DataRow = {};
                const dataRowObject = filteredData[i];

                for (const analyte of Object.keys(dataRowObject)) {
                    avgDataRow[analyte] = Math.max(
                        dataRowObject[analyte] - blankMean[analyte],
                        0.0
                    );
                }

                results.push(avgDataRow);
            } else {
                results.push(filteredData[i]);
            }
        }

        // Sort monoMethodTypes, sopVersions and the data by the customSortOrder below.
        // Create an array of objects pairing fmmt and r
        const pairedArray = filteredMonoMethodTypes.map((value, index) => ({
            fmmt: value,
            r: results[index],
            ffmv: filteredSOPVersions[index],
        }));

        // Define the custom sort order
        const customSortOrder = ['Vz', 'ShortH', 'Standard'];

        // Sort the paired array based on the custom sort order
        pairedArray.sort(
            (a, b) =>
                customSortOrder.indexOf(a.fmmt) -
                customSortOrder.indexOf(b.fmmt)
        );

        // Extract sorted elements back into fmmt and r arrays
        for (let i = 0; i < pairedArray.length; i++) {
            filteredMonoMethodTypes[i] = pairedArray[i].fmmt;
            results[i] = pairedArray[i].r;
            filteredSOPVersions[i] = pairedArray[i].ffmv;
        }

        //
        // Unable to assign to data here, failing miserably. Instead create and
        // return a new GenericDataset instance.
        // This nonsense may cause the earth's axis to shift. #WCH
        //

        const newDataset = new GenericDataset(this.observationDate);
        newDataset.data = results;
        newDataset.monoMethodType = this.monoMethodType;
        newDataset.monoMethodTypes = filteredMonoMethodTypes;
        newDataset.sopVersion = this.sopVersion;
        newDataset.sopVersions = filteredSOPVersions;
        newDataset.defaultColumnOrder = this.defaultColumnOrder;
        newDataset.hasSummaryStats = this.hasSummaryStats;

        return newDataset;
    }
}
