import { DataImportMapper } from './data-import-mapper';
import { Sample } from '../../model/sample.model';
import { DataImportData } from '../data/data-import-data';
import { DataImportType } from '../data-import-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapperData } from './mapper-data';
import { SamplesByMappingFileKeyGQL } from '@bcdbio/udb-graphql';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataImportMapperFastqFile extends DataImportMapper {
    constructor(
        private samplesByMappingFileKeyGQL: SamplesByMappingFileKeyGQL
    ) {
        super();
    }

    map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const mappingFileKey = file.name.substring(0, file.name.indexOf('.'));
        return this.samplesByMappingFileKeyGQL
            .fetch({ mappingFileKey: mappingFileKey })
            .pipe(
                map((result) => {
                    const samples: Sample[] =
                        result.data.samplesByMappingFileKey.map((s) => {
                            const sample = new Sample();
                            sample.id = s.bcdId;
                            return sample;
                        });
                    return {
                        samples: samples,
                    };
                })
            );
    }

    rowMap(headerRow: string[], row: string[]): Sample {
        throw new Error(
            'rowMap() should not be called on DataImportMapperFastqFile'
        );
    }
}
