import { Injectable } from '@angular/core';
import { DataImportFileReaderFactory } from './reader/data-import-file-reader.factory';
import { Observable, of } from 'rxjs';
import { DataImportValidatorFactory } from './validator/data-import-validator.factory';
import { map, mergeMap } from 'rxjs/operators';
import { SampleApiService } from '../sample-api.service';
import { DataImportMapperFactory } from './mapping/data-import-mapper.factory';
import { DataImportDataFactory } from './data/data-import-data.factory';
import { DataImportData, DataImportDataResults } from './data/data-import-data';
import { DataImportPersistFactory } from './persist/data-import-persist.factory';
import { SaveResult } from './sample-save-result';
import { DataImportType } from './data-import-type';
import { FileApiService } from '../file-api.service';
import { ProcessApiService } from '../process-api.service';

@Injectable({
    providedIn: 'root',
})
export class DataImportService {
    constructor(
        private sampleApiService: SampleApiService,
        private fileApiService: FileApiService,
        private processApiService: ProcessApiService,
        private dataImportValidatorFactory: DataImportValidatorFactory,
        private dataImportMapperFactory: DataImportMapperFactory
    ) {}

    public readFile(
        dataFile: File,
        dataFileType: DataImportType
    ): Observable<DataImportDataResults> {
        const reader = DataImportFileReaderFactory.createReader(dataFileType);
        return reader.readFile(dataFile).pipe(
            map((readResults) => {
                if (readResults.errors.length < 1) {
                    return {
                        data: DataImportDataFactory.createData(
                            dataFileType,
                            readResults.data
                        ),
                        errors: [],
                    };
                } else {
                    return {
                        data: null,
                        errors: readResults.errors,
                    };
                }
            })
        );
    }

    public validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const validator =
            this.dataImportValidatorFactory.createValidator(dataFileType);
        return validator.validate(file, data, dataFileType);
    }

    public save(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return this.fileApiService
            .archiveMultipartDataFile(file, dataFileType)
            .pipe(
                mergeMap((result) => {
                    if (result.success) {
                        const mapper =
                            this.dataImportMapperFactory.createMapper(
                                dataFileType
                            );
                        return mapper.map(file, data, dataFileType).pipe(
                            mergeMap((mapperData) => {
                                const persist =
                                    DataImportPersistFactory.createPersist(
                                        this.sampleApiService,
                                        this.processApiService,
                                        dataFileType
                                    );
                                return persist.save(
                                    mapperData,
                                    result.filePath,
                                    dataFileType
                                );
                            })
                        );
                    } else {
                        return of(result);
                    }
                })
            );
    }

    public postProcess(
        file: File,
        data: DataImportData,
        dataFileType: string
    ): Observable<SaveResult> {
        console.log('data import svc: postProc');
        if (dataFileType === DataImportType.GROWTH_CURVE)
            return this.sampleApiService.calculateCurvesAndMerge(file.name);
        else {
            return of({
                filePath: file.name,
                success: true,
                error: '',
                message: 'No Post-Processing',
            });
        }
    }
}
