import { Injectable } from '@angular/core';
import { DataImportData } from './data-import-data';
import { DataImportDataSource } from './data-import-data-source';
import { DataImportDataMono } from './data-import-data-mono';
import { DataImportDataLinkage } from './data-import-data-linkage';
import { DataImportDataTray } from './data-import-data-tray';
import { DataImportDataGrowth } from './data-import-data-growth';
import { DataImportDataFile } from './data-import-data-file';
import { DataImportType } from '../data-import-type';
import { DataImportDataFastqMapping } from './data-import-data-fastq-mapping';
import { DataImportDataMetabAnalytes } from './data-import-data-metab-analytes';
import { DataImportDataFecalSource } from './data-import-data-fecal-source';
import { DataImportDataSingleStrain } from './data-import-data-single-strain';

@Injectable({
    providedIn: 'root',
})
export class DataImportDataFactory {
    constructor() {}

    public static createData(
        dataFileType: DataImportType,
        data: Array<string[]>
    ): DataImportData {
        switch (dataFileType) {
            case DataImportType.SOURCE:
                return new DataImportDataSource(data);
            case DataImportType.MONO:
                return new DataImportDataMono(data);
            case DataImportType.LINKAGE:
                return new DataImportDataLinkage(data);
            case DataImportType.TRAY_METADATA:
                return new DataImportDataTray(data);
            case DataImportType.GROWTH_CURVE:
                return new DataImportDataGrowth(data);
            case DataImportType.FASTQ_MAPPING:
                return new DataImportDataFastqMapping(data);
            case DataImportType.METABOLOMICS_ANALYTES:
                return new DataImportDataMetabAnalytes(data);
            case DataImportType.FASTQ:
            case DataImportType.METABOLOMICS:
                return new DataImportDataFile(data);
            case DataImportType.FECAL_SOURCE:
                return new DataImportDataFecalSource(data);
            case DataImportType.SINGLE_STRAIN:
                return new DataImportDataSingleStrain(data);
            case DataImportType.FECAL_SLURRY:
            // return new DataImportDataFecalSlurry(data);
            case DataImportType.FECAL_POOL:
            // return new DataImportDataFecalPool(data);
            default:
                throw Error('DataImportPersistFactory: Invalid data file type');
        }
    }
}
