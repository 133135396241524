import { DataImportMapper } from './data-import-mapper';
import { DataImportType } from '../data-import-type';
import { Sample } from '../../model/sample.model';
import { DataImportData } from '../data/data-import-data';
import { MapperData } from './mapper-data';
import { forkJoin, Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { SamplesByTrayCellGQL } from '@bcdbio/udb-graphql';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataImportMapperFastqMapping extends DataImportMapper {
    constructor(private samplesByTrayCellGQL: SamplesByTrayCellGQL) {
        super();
    }

    map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        const trayCellObs: Observable<boolean>[] = [];
        const trayIdx = headerRow.indexOf('Tray.Name');
        const cellIdx = headerRow.indexOf('Sample.Name');
        const sampleIdIdx = headerRow.indexOf('SampleID');
        const samples: Sample[] = [];
        for (let i = 0, n = rows.length; i < n; i++) {
            const trayName = rows[i][trayIdx];
            const cell = rows[i][cellIdx];
            const sampleId = rows[i][sampleIdIdx];
            if (trayName !== 'null' && cell !== 'null') {
                trayCellObs.push(
                    this.samplesByTrayCellGQL
                        .fetch({ trayName: trayName, cellId: cell })
                        .pipe(
                            tap((result) => {
                                const traySamples: Sample[] =
                                    result.data.samplesByTrayCell.map((s) => {
                                        const sample = new Sample();
                                        sample.id = s.bcdId;
                                        return sample;
                                    });
                                samples.push(...traySamples);
                            }),
                            mapTo(true)
                        )
                );
            } else if (sampleId !== 'null') {
                const sample = new Sample();
                sample.id = sampleId;
                samples.push(sample);
            }
        }
        return forkJoin(trayCellObs).pipe(
            map(() => {
                return {
                    samples: samples,
                    fastqInfo: {
                        mappingFileKey: file.name.substring(
                            0,
                            file.name.indexOf('.')
                        ),
                    },
                };
            })
        );
    }

    public rowMap(headerRow: string[], row: string[]): Sample {
        throw new Error(
            'rowMap() should not be called on DataImportMapperFastqMapping'
        );
    }
}
