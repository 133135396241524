import { DataImportData } from '../data/data-import-data';
import { DataImportType, Sample } from '@bcdbio/data';
import { Observable, of } from 'rxjs';
import { MapperData } from './mapper-data';
import { map as rxjsMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

export abstract class DataImportMapper {
    constructor() {}

    public map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();

        return of({
            samples: rows.map((row) => this.rowMap(headerRow, row)),
        }).pipe(
            rxjsMap((mapperData: MapperData) => {
                // An entire spreadsheet of Mono data of MethodType Vz may include
                // multiple sample ids but only a single set of Vz Blank data. The Blank data needs
                // to be averaged and subtracted from all Visco samples, but this occurs at the
                // SampleDetails step for a sample; therefore, the Vz data is assigned to only
                // samples with a matching id.
                //
                // A solution is to replicate the Vz Blanks to the other Vz samples.

                // 1. Identify Unique Sample IDs where 'Mono' exists, is not empty, and the first 'Mono' has monoMethodType 'Vz'.
                const uniqueSampleIds = new Set(
                    mapperData.samples
                        .filter(
                            (sample) =>
                                sample.observedData &&
                                sample.observedData.hasOwnProperty('Mono') &&
                                sample.observedData['Mono'].length > 0 &&
                                sample.observedData['Mono'][0]
                                    .monoMethodType === 'Vz'
                        )
                        .map((sample) => sample.id)
                );

                // 2. Filter for 'Vz Blank' Samples.
                const vzBlankSamples = mapperData.samples.filter(
                    (sample) =>
                        sample.observedData &&
                        'Mono' in sample.observedData &&
                        sample.observedData['Mono'][0].monoMethodType ===
                            'Vz Blank'
                );

                // 3. For each Vz unique sample id, iterate the vzBlanks to find Vz unique sample ids without a matching blank.
                //    When found clone the Vz Blank and give it the Vz unique sample id.
                uniqueSampleIds.forEach((id) => {
                    const samplesForId = mapperData.samples.filter(
                        (sample) => sample.id === id
                    );

                    const vzBlankMissing = vzBlankSamples.filter(
                        (vzSample) =>
                            !samplesForId.some(
                                (sample) => sample.id === vzSample.id
                            )
                    );

                    // Replicate missing Vz Blank samples for this ID
                    vzBlankMissing.forEach((missingSample) => {
                        const replicatedSample: Sample =
                            cloneDeep(missingSample);
                        replicatedSample.id = id;
                        mapperData.samples.push(replicatedSample);
                    });
                });

                return mapperData;
            })
        );
    }

    public abstract rowMap(headerRow: string[], row: string[]): Sample;
}
