import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    searchResultsRequested,
    UiPartialState,
    loadMoreSearchResults,
    getSearchQueryParameters,
    searchResultsRetrieved,
    addExportSampleIds,
    getExportSampleIds,
} from '@bcdbio/ui';
import { Observable, Subscription } from 'rxjs';
import { Sample } from '@bcdbio/data';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
    CountSampleFullTextSearchGQL,
    CountSamplesByBcdIdSearchGQL,
    CountSamplesByTypeSearchGQL,
    SampleFullTextSearchGQL,
    SamplesByBcdIdSearchGQL,
    SamplesByTypeSearchGQL,
} from '@bcdbio/udb-graphql';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { forEach } from 'lodash';
import { ExportSelectedSamplesComponent } from '../../components/export-selected-samples/export-selected-samples.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bcdbio-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    searchQuery$: Observable<{
        searchTerm: string;
        searchQueryMaxResults: number;
        bcdIdOnly: boolean;
        dataPresent: string[];
        dataSource: string[];
        processInputTypes: string[];
        processOutputTypes: string[];
        sortType: string;
        sortDir: string;
    }> = this.storeUi.select(getSearchQueryParameters);

    readonly RESULTS_PER_SEARCH: number = 10;
    currentNumberOfResults: number;
    searchResultsExhausted = false;
    currentMaxResults: number = this.RESULTS_PER_SEARCH;
    totalNumSearchResults: number;
    searchResults$: Observable<Sample[]>;
    searchResults: Sample[] = [];
    searchResultsJSON: string;
    samplesToExport: string[] = [];
    samplesToExport$: Observable<string[]>;
    showExportButton = false;
    isAdvancedSearch = false;
    subscriptions: Subscription = new Subscription();
    showSpinner = false;
    backbutton = false;
    gotmore = false;
    dataPresentFilters: FormArray;
    dataSourceFilters: FormArray;
    processInputFilters: FormArray;
    processOutputFilters: FormArray;
    sortFilters: FormArray;
    sortDirFilters: FormArray;
    sortTypeSelected: string;
    sortDirSelected: string;
    form: FormGroup;
    term: string;
    bsModalRef: BsModalRef;
    searchObservedDataTypes = [
        { description: 'Mono', value: 'Mono', selected: false },
        { description: 'Free Mono', value: 'Free Mono', selected: false },
        { description: 'Linkage', value: 'Linkage', selected: false },
        { description: 'Growth Curve', value: 'Growth Curve', selected: false },
        {
            description: 'Metabolomics Analytes',
            value: 'Metabolomics Analytes',
            selected: false,
        },
    ];
    searchDataSourceTypes = [
        { description: 'BCD', value: 'BCD', selected: true },
        { description: 'UCD', value: 'UCD', selected: true },
    ];
    searchProcessInputTypes = [
        {
            description: 'Native Material',
            value: 'Source',
            selected: false,
            active: true,
        },
        {
            description: 'Fecal Source',
            value: 'Fecal Source',
            selected: false,
            active: true,
        },
        {
            description: 'Single Strain',
            value: 'Single Strain',
            selected: false,
            active: true,
        },
        {
            description: 'Fecal Slurry',
            value: 'Fecal Slurry',
            selected: false,
            active: false,
        },
        {
            description: 'Fecal Pool',
            value: 'Fecal Pool',
            selected: false,
            active: false,
        },
        {
            description: 'Spiked Fecal Pool',
            value: 'Spiked Fecal Pool',
            selected: false,
            active: false,
        },
        {
            description: 'Processed',
            value: 'Processed',
            selected: false,
            active: false,
        },
        {
            description: 'Cross',
            value: 'Cross',
            selected: false,
            active: false,
        },
    ];
    searchProcessOutputTypes = [
        {
            description: 'Fecal Pool',
            value: 'Pool slurry - PBS glycerol',
            selected: false,
            active: true,
            submenu: 'Micro',
        },
        {
            description: 'Fecal Slurry',
            value: 'Slurry - PBS glycerol',
            selected: false,
            active: true,
            submenu: 'Micro',
        },
        {
            description: 'Pre-inoculum',
            value: 'Micro (pre-inoculum)',
            selected: false,
            active: true,
            submenu: 'Micro',
        },
        {
            description: 'Spiked Fecal Pool',
            value: 'Spiked-fecal prep',
            selected: false,
            active: true,
            submenu: 'Micro',
        },
        {
            description: 'COG',
            value: 'COG',
            selected: false,
            active: true,
            submenu: 'Chemistry',
        },
        {
            description: 'EtOH',
            value: 'EtOH',
            selected: false,
            active: true,
            submenu: 'Chemistry',
        },
        {
            description: 'Post-processing',
            value: 'Post-processing',
            selected: false,
            active: true,
            submenu: 'Chemistry',
        },
        {
            description: 'Pre-processing',
            value: 'Pre-processing',
            selected: false,
            active: true,
            submenu: 'Chemistry',
        },
        {
            description: 'RESIN',
            value: 'RESIN',
            selected: false,
            active: true,
            submenu: 'Chemistry',
        },
        {
            description: 'Interaction',
            value: 'Interaction',
            selected: false,
            active: true,
            submenu: 'Cross',
        },
        {
            description: 'Other',
            value: 'Other',
            selected: false,
            active: true,
            submenu: 'Other',
        },
    ];
    searchSortTypes = [
        { description: 'Sample Id', value: 'bcdId', selected: false },
        { description: 'Source Name', value: 'SourceName', selected: false },
        { description: 'Date', value: 'srtdate', selected: false },
    ];
    searchSortDirs = [
        { description: 'Ascending', value: 'asc', selected: true },
        { description: 'Descending', value: 'desc', selected: false },
    ];

    valueOrder = (
        a: KeyValue<null, Sample>,
        b: KeyValue<null, Sample>
    ): number => {
        return a.value.id > b.value.id ? 1 : b.value.id > a.value.id ? -1 : 0;
    };

    constructor(
        private storeUi: Store<UiPartialState>,
        private sampleFullTextSearchGQL: SampleFullTextSearchGQL,
        private samplesByBcdIdSearchGQL: SamplesByBcdIdSearchGQL,
        private samplesByTypeSearchGQL: SamplesByTypeSearchGQL,
        private countSampleFullTextSearchGQL: CountSampleFullTextSearchGQL,
        private countSamplesByBcdIdSearchGQL: CountSamplesByBcdIdSearchGQL,
        private countSamplesByTypeSearchGQL: CountSamplesByTypeSearchGQL,
        private fb: FormBuilder,
        private modalService: BsModalService
    ) {
        this.dataPresentFilters = this.fb.array(
            this.searchObservedDataTypes.map((dt) =>
                this.fb.control(dt.selected)
            )
        );
        this.dataSourceFilters = this.fb.array(
            this.searchDataSourceTypes.map((dt) => this.fb.control(dt.selected))
        );
        this.processInputFilters = this.fb.array(
            this.searchProcessInputTypes.map((spit) =>
                this.fb.control(spit.selected)
            )
        );
        this.processOutputFilters = this.fb.array(
            this.searchProcessOutputTypes.map((spot) =>
                this.fb.control(spot.selected)
            )
        );
        this.sortFilters = this.fb.array(
            this.searchSortTypes.map((st) => this.fb.control(st.selected))
        );
        this.sortDirFilters = this.fb.array(
            this.searchSortDirs.map((sd) => this.fb.control(sd.selected))
        );
        this.form = this.fb.group({
            dataPresentFilters: this.dataPresentFilters,
            dataSourceFilters: this.dataSourceFilters,
            processInputFilters: this.processInputFilters,
            processOutputFilters: this.processOutputFilters,
            sortFilters: this.sortFilters,
            sortDirFilters: this.sortDirFilters,
        });
    }

    ngOnInit(): void {
        console.log('OnInit');
        this.term = '';
        this.searchResultsJSON = localStorage.getItem('searchResults');
        if (this.searchResultsJSON && !this.gotmore) {
            this.backbutton = true;
            this.totalNumSearchResults = -1;
        }
        this.samplesToExport$ = this.storeUi.select(getExportSampleIds);
        this.samplesToExport$.subscribe((sampids) => {
            let samplesToExport = [];
            forEach(sampids, function (sampid, key) {
                samplesToExport.push(sampid);
            });
            this.samplesToExport = samplesToExport;
        });
        this.searchResults$ = this.searchQuery$.pipe(
            tap((searchQuery) => {
                this.currentMaxResults = searchQuery.searchQueryMaxResults;
                this.currentNumberOfResults = 0;
            }),
            filter(
                (searchQuery) =>
                    searchQuery.searchTerm !== '' ||
                    (!(
                        searchQuery.dataPresent.includes('Any') &&
                        searchQuery.processInputTypes.includes('Any') &&
                        searchQuery.processOutputTypes.includes('Any')
                    ) &&
                        searchQuery.dataPresent.length > 0 &&
                        searchQuery.processInputTypes.length > 0 &&
                        searchQuery.processOutputTypes.length > 0)
            ),
            switchMap((searchQuery) => {
                if (!this.backbutton) this.showSpinner = true;
                else this.term = searchQuery.searchTerm;

                if (searchQuery.searchTerm === '') {
                    if (this.totalNumSearchResults === -1) {
                        this.subscriptions.add(
                            this.countSamplesByTypeSearchGQL
                                .fetch({
                                    dataPresent: searchQuery.dataPresent,
                                    dataSource: searchQuery.dataSource,
                                    processInputTypes:
                                        searchQuery.processInputTypes,
                                    processOutputTypes:
                                        searchQuery.processOutputTypes,
                                })
                                .pipe(
                                    map(
                                        (result) =>
                                            result.data.countSamplesByTypeSearch
                                    )
                                )
                                .subscribe((result) => {
                                    console.log(result);
                                    this.totalNumSearchResults = result;
                                })
                        );
                    }
                    return this.samplesByTypeSearchGQL
                        .fetch({
                            first: searchQuery.searchQueryMaxResults,
                            dataPresent: searchQuery.dataPresent,
                            dataSource: searchQuery.dataSource,
                            processInputTypes: searchQuery.processInputTypes,
                            processOutputTypes: searchQuery.processOutputTypes,
                            sortType: searchQuery.sortType,
                            sortDir: searchQuery.sortDir,
                        })
                        .pipe(
                            map((results) => {
                                return results.data.samplesByTypeSearch.map(
                                    (gqlData) => {
                                        return Sample.fromGQLData(gqlData);
                                    }
                                );
                            })
                        );
                } else {
                    const bcdIdSearchString =
                        'bcdId: "' + searchQuery.searchTerm + '"';

                    if (searchQuery.bcdIdOnly) {
                        if (this.totalNumSearchResults === -1) {
                            this.subscriptions.add(
                                this.countSamplesByBcdIdSearchGQL
                                    .fetch({
                                        bcdIdSearchString: bcdIdSearchString,
                                        dataPresent: searchQuery.dataPresent,
                                        dataSource: searchQuery.dataSource,
                                        processInputTypes:
                                            searchQuery.processInputTypes,
                                        processOutputTypes:
                                            searchQuery.processOutputTypes,
                                    })
                                    .pipe(
                                        map(
                                            (result) =>
                                                result.data
                                                    .countSamplesByBcdIdSearch
                                        )
                                    )
                                    .subscribe((result) => {
                                        this.totalNumSearchResults = result;
                                    })
                            );
                        }

                        return this.samplesByBcdIdSearchGQL
                            .fetch({
                                first: searchQuery.searchQueryMaxResults,
                                bcdIdSearchString: bcdIdSearchString,
                                dataPresent: searchQuery.dataPresent,
                                dataSource: searchQuery.dataSource,
                                processInputTypes:
                                    searchQuery.processInputTypes,
                                processOutputTypes:
                                    searchQuery.processOutputTypes,
                                sortType: searchQuery.sortType,
                                sortDir: searchQuery.sortDir,
                            })
                            .pipe(
                                map((results) => {
                                    return results.data.samplesByBcdIdSearch.map(
                                        (gqlData) => {
                                            return Sample.fromGQLData(gqlData);
                                        }
                                    );
                                })
                            );
                    } else {
                        if (this.totalNumSearchResults === -1) {
                            this.subscriptions.add(
                                this.countSampleFullTextSearchGQL
                                    .fetch({
                                        searchString: searchQuery.searchTerm,
                                        bcdIdSearchString: bcdIdSearchString,
                                        dataPresent: searchQuery.dataPresent,
                                        dataSource: searchQuery.dataSource,
                                        processInputTypes:
                                            searchQuery.processInputTypes,
                                        processOutputTypes:
                                            searchQuery.processOutputTypes,
                                    })
                                    .pipe(
                                        map(
                                            (result) =>
                                                result.data
                                                    .countSamplesFullTextSearch
                                        )
                                    )
                                    .subscribe((result) => {
                                        this.totalNumSearchResults = result;
                                    })
                            );
                        }
                        return this.sampleFullTextSearchGQL
                            .fetch({
                                first: searchQuery.searchQueryMaxResults,
                                searchString: searchQuery.searchTerm,
                                bcdIdSearchString: bcdIdSearchString,
                                dataPresent: searchQuery.dataPresent,
                                dataSource: searchQuery.dataSource,
                                processInputTypes:
                                    searchQuery.processInputTypes,
                                processOutputTypes:
                                    searchQuery.processOutputTypes,
                                sortType: searchQuery.sortType,
                                sortDir: searchQuery.sortDir,
                            })
                            .pipe(
                                map((results) => {
                                    return results.data.samplesFullTextSearch.map(
                                        (gqlData) => {
                                            return Sample.fromGQLData(gqlData);
                                        }
                                    );
                                })
                            );
                    }
                }
            }),
            map((results) => {
                if (!this.backbutton) {
                    this.searchResultsJSON =
                        localStorage.getItem('searchResults');
                    if (this.searchResultsJSON !== '') {
                        this.searchResults = JSON.parse(this.searchResultsJSON);
                        results.forEach((res) => {
                            this.searchResults.push(res);
                        });
                    } else {
                        this.searchResults = results;
                    }
                    this.storeUi.dispatch(
                        searchResultsRetrieved({
                            searchResults: results,
                        })
                    );
                    localStorage.setItem(
                        'searchResults',
                        JSON.stringify(this.searchResults, this.setcirc())
                    );
                } else {
                    console.log('detect back button');
                    if (this.searchResultsJSON !== '') {
                        this.searchResults = JSON.parse(this.searchResultsJSON);
                    }
                }
                this.showSpinner = false;

                // the template displays datePrepared in the results table.
                // For types that have some other date that should be shown
                //   put the date to be shown into datePrepared

                // was results
                return this.searchResults.map((samp) => {
                    //console.log(samp);
                    //console.log(samp.metadata);
                    /*
                    if (samp.metadata) {
                        if (samp.type === 'Fecal Source') {
                            samp.metadata.dateReceived =
                                samp.metadata.collectionDate;
                        } else if (
                            samp.type === 'Fecal Slurry' ||
                            samp.type === 'Fecal Pool'
                        ) {
                            if (
                                samp.parentProcess?.metadata?.Process &&
                                samp.parentProcess.metadata.Process[
                                    'Date Prepared'
                                ]
                            ) {
                                if (samp.metadata.dateReceived)
                                    samp.metadata.dateReceived =
                                        samp.parentProcess.metadata.Process[
                                            'Date Prepared'
                                        ];
                            } else {
                                samp.metadata.dateReceived = undefined;
                            }
                        }
                    }
                     */
                    return samp;
                });
            }),
            tap((results) => {
                this.searchResultsExhausted = this.areSearchResultsExhausted(
                    results,
                    this.currentMaxResults
                );
                this.currentNumberOfResults = results.length;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    searchForTerm(
        searchTerm: string,
        bcdIdOnly: boolean,
        filterForm: FormGroup
    ) {
        const dataPresent = this.buildFilterArray(
            filterForm,
            'dataPresentFilters',
            this.searchObservedDataTypes
        );
        const processInputTypes = this.buildFilterArray(
            filterForm,
            'processInputFilters',
            this.searchProcessInputTypes
        );
        const dataSource = this.buildFilterArray(
            filterForm,
            'dataSourceFilters',
            this.searchDataSourceTypes,
            !processInputTypes.includes('Source')
        );
        const processOutputTypes = this.buildFilterArray(
            filterForm,
            'processOutputFilters',
            this.searchProcessOutputTypes
        );

        const sortType = this.buildFilterArray(
            filterForm,
            'sortFilters',
            this.searchSortTypes
        );
        const sortDir = this.buildFilterArray(
            filterForm,
            'sortDirFilters',
            this.searchSortDirs
        );
        this.totalNumSearchResults = -1;
        if (this.sortTypeSelected == null) {
            this.sortTypeSelected = 'bcdId';
        }
        if (this.sortDirSelected == null) {
            this.sortDirSelected = 'asc';
        }

        const otherDataSource =
            dataSource.includes('Any') && processInputTypes.includes('Source');

        this.storeUi.dispatch(
            searchResultsRequested({
                searchTerm: searchTerm,
                maxResults: this.RESULTS_PER_SEARCH,
                bcdIdOnly: bcdIdOnly,
                dataPresent: dataPresent,
                dataSource: otherDataSource ? ['Other'] : dataSource,
                processInputTypes: processInputTypes,
                processOutputTypes: processOutputTypes,
                sortType: this.sortTypeSelected,
                sortDir: this.sortDirSelected,
            })
        );
        localStorage.setItem('searchResults', '');
        this.searchResultsJSON = null;
        this.searchResults = [];
        this.gotmore = false;
        this.backbutton = false;
    }

    buildFilterArray(
        form,
        formArrayName,
        types,
        allDataSources = false
    ): string[] {
        const formArray = form.get(formArrayName) as FormArray;
        const filterArray = [];
        if (!allDataSources) {
            formArray.controls.forEach((control, i) => {
                if (control.value) {
                    filterArray.push(types[i].value);
                }
            });
        }
        if (filterArray.length === 0 || allDataSources) {
            filterArray.push('Any');
        }
        return filterArray;
    }

    clearAdvancedSearch() {
        this.dataPresentFilters.controls.forEach((control) => {
            control.setValue(false);
        });
        this.dataSourceFilters.controls.forEach((control) => {
            control.setValue(false);
        });
        this.processInputFilters.controls.forEach((control) => {
            control.setValue(false);
        });
        this.processOutputFilters.controls.forEach((control) => {
            control.setValue(false);
        });
        this.sortFilters.controls.forEach((control) => {
            control.setValue(false);
        });
        this.sortDirFilters.controls.forEach((control) => {
            control.setValue(false);
        });
    }

    getMoreResults(requestedMoreSearchResults: boolean) {
        this.gotmore = true;
        this.backbutton = false;
        this.searchResultsJSON = localStorage.getItem('searchResults');
        if (this.searchResultsJSON !== '')
            this.searchResults = JSON.parse(this.searchResultsJSON);
        this.storeUi.dispatch(
            searchResultsRetrieved({
                searchResults: this.searchResults,
            })
        );
        localStorage.setItem(
            'searchResults',
            JSON.stringify(this.searchResults, this.setcirc())
        );
        this.storeUi.dispatch(
            loadMoreSearchResults({
                maxResults:
                    this.currentNumberOfResults + this.RESULTS_PER_SEARCH,
            })
        );
    }

    // resolves the circular dependency error from JSON stringify
    setcirc() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    }

    areSearchResultsExhausted(
        searchResults: Sample[],
        maxResults: number
    ): boolean {
        if (searchResults == null || maxResults == null) {
            return false;
        } else {
            return searchResults.length === this.totalNumSearchResults;
        }
    }

    toggleIsAdvancedSearch() {
        this.isAdvancedSearch = !this.isAdvancedSearch;
    }

    addSamples(): void {
        for (let samp of this.searchResults) {
            let childElem = <HTMLInputElement>(
                document.getElementById('EXP_' + samp.id + '-input')
            );
            if (childElem.checked) {
                if (this.samplesToExport.indexOf(samp.id) < 0) {
                    this.samplesToExport.push(samp.id);
                }
                childElem.checked = false;
            }
        }
        let tempExp = [];
        tempExp = tempExp.concat(this.samplesToExport);
        this.storeUi.dispatch(
            addExportSampleIds({
                exportSampleIds: tempExp,
            })
        );

        this.fetchSamplesFromStore();
        this.showExportButton = false;
    }

    checkExports(event): void {
        this.showExportButton = false;
        for (let samp of this.searchResults) {
            let childElem = <HTMLInputElement>(
                document.getElementById('EXP_' + samp.id + '-input')
            );
            if (childElem.checked) this.showExportButton = true;
        }
    }

    exportSamples() {
        this.fetchSamplesFromStore();
        const initialState = {
            samplesToExport: this.samplesToExport,
        };
        this.bsModalRef = this.modalService.show(
            ExportSelectedSamplesComponent,
            {
                initialState: initialState,
                class: 'modal-lg',
            }
        );
    }

    fetchSamplesFromStore() {
        let tempsearchResults$: Observable<string[]>;
        let samplesSelectedToExport: string[] = [];
        tempsearchResults$ = this.storeUi.select(getExportSampleIds);
        tempsearchResults$.subscribe((sampids) => {
            forEach(sampids, function (sampid, key) {
                if (sampid) {
                    if (samplesSelectedToExport.indexOf(sampid) < 0)
                        samplesSelectedToExport.push(sampid);
                }
            });
        });
        this.samplesToExport = samplesSelectedToExport;
    }
}
