<div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="bsModalRef.hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="sample" class="modal-body">
    <div>Are you sure you want to permanently delete</div>
    <div class="px-5 py-2">
        <strong>{{ sample?.id }}</strong>
    </div>
    <div class="">and all of its descendants?</div>
</div>
<div *ngIf="measurement" class="modal-body">
    <div>Are you sure you want to permanently delete</div>
    <div class="px-5 py-2">
        <strong>{{ datasetTitle }}</strong>
        data from
        <strong *ngIf="isGrowthCurveData; else elseBlock">{{
            growthCurveVersion
        }}</strong>
        <ng-template #elseBlock>
            <strong *ngIf="!isGrowthCurveData">{{ observedDate }}</strong>
        </ng-template>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="delete()">
        Confirm Deletion
    </button>
    <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">
        Close
    </button>
</div>
