import { Sample } from '../../model/sample.model';
import { DataImportMapper } from './data-import-mapper';
import { DataImportData, DataImportType } from '@bcdbio/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapperData } from './mapper-data';
import { CreateBcdIdGQL } from '@bcdbio/udb-graphql';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataImportMapperBio extends DataImportMapper {
    private mappingInfo;
    constructor(private createBcdIdGQL: CreateBcdIdGQL) {
        super();
    }

    public map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        this.mappingInfo = data;
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();

        const headers = headerRow.map((label) => label.toLowerCase());

        const samples = rows.map((row) => this.rowMap(headers, row));

        return this.createBcdIdGQL.mutate({ n: samples.length }).pipe(
            map((result) => {
                result.data.create_bcd_id.forEach((row, i) => {
                    const s = samples[i];
                    s.id = row.bcd_id + '-' + s.name;
                });
                return { samples: samples };
            })
        );
    }

    public rowMap(headers: string[], row: string[]): Sample {
        const sample = new Sample();

        sample.name = this.mappingInfo.getName(headers, row);
        sample.type = this.mappingInfo.getType();

        const metadata: { [key: string]: number | string | Date } = {};
        this.mappingInfo.mapping.forEach(
            ({ importColumnHeader, neo4jName }) => {
                if (
                    neo4jName &&
                    headers.indexOf(importColumnHeader.toLowerCase()) > -1
                ) {
                    const value =
                        row[headers.indexOf(importColumnHeader.toLowerCase())];
                    if (value && value !== 'null') {
                        metadata[neo4jName] = value;
                    }
                }
            }
        );

        sample.metadata = metadata;

        return sample;
    }
}
