import { DataImportValidator } from './data-import-validator';
import { DataImportType } from '../data-import-type';
import { Observable, of } from 'rxjs';
import { DataImportData } from '../data/data-import-data';

export class DataImportValidatorFastqFile extends DataImportValidator {
    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        return of({ isValid: true, errors: [] });
    }
}
