import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    dataImportInitiated,
    getDataImportConsoleLog,
    UiPartialState,
} from '@bcdbio/ui';
import { Observable } from 'rxjs';
import { DataImportType } from '@bcdbio/data';

export interface ImportCategory {
    category: string;
    subcategories: Array<string>;
}

@Component({
    selector: 'bcdbio-data-import',
    templateUrl: './data-import.component.html',
    styleUrls: ['./data-import.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DataImportComponent implements OnInit {
    consoleLog$: Observable<string[]> = this.storeUi.select(
        getDataImportConsoleLog
    );
    fileLabel = 'Choose an import file...';

    dataImportForm: FormGroup = this.fb.group({
        file: ['', Validators.required],
        fileSource: [''],
        dataFileType: ['', Validators.required],
    });

    importMenu: Array<ImportCategory> = [
        {
            category: 'Sources',
            subcategories: [
                DataImportType.SOURCE,
                DataImportType.SINGLE_STRAIN,
                DataImportType.FECAL_SOURCE,
                DataImportType.FECAL_POOL,
                DataImportType.FECAL_SLURRY,
            ],
        },
        {
            category: 'Observed Data',
            subcategories: [
                DataImportType.MONO,
                DataImportType.LINKAGE,
                DataImportType.METABOLOMICS,
                DataImportType.GROWTH_CURVE,
                // DataImportType.PH,
                DataImportType.FASTQ,
                DataImportType.FASTQ_MAPPING,
                DataImportType.METABOLOMICS_ANALYTES,
            ],
        },
        {
            category: 'Metadata',
            subcategories: [DataImportType.TRAY_METADATA],
        },
    ];

    constructor(
        private storeUi: Store<UiPartialState>,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {}

    get formControls() {
        return this.dataImportForm.controls;
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            this.fileLabel = file.name;
            this.dataImportForm.patchValue({
                fileSource: file,
            });
        }
    }

    submit() {
        // console.log('form data submitted', this.dataImportForm.get('dataFileType').value, this.dataImportForm.get('fileSource').value);
        if (this.dataImportForm.valid) {
            this.storeUi.dispatch(
                dataImportInitiated({
                    file: this.dataImportForm.get('fileSource').value,
                    dataFileType: this.dataImportForm.get('dataFileType').value,
                })
            );
        }
    }
}
