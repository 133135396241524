<ng-container *ngIf="showSpinner">
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <mat-spinner diameter="125"></mat-spinner>
        </div>
    </div>
</ng-container>

<div class="container" *ngIf="{ results: (searchResults$ | async) } as obs">
    <div class="top-buffer d-flex">
        <h2>Sample search</h2>
        <a
            class="nav-link"
            href="https://bcdbio.slite.com/api/s/uYYLUP03tqEjjo/Searching"
            target="_blank"
        >
            <i class="bi-box-arrow-up-right"></i>
        </a>
    </div>
    <div>
        <div>
            <bcdbio-search-bar
                (newSearchEvent)="
                    searchForTerm($event, bcdIdOnly.checked, form)
                "
                [newval]="term"
            ></bcdbio-search-bar>

            <mat-checkbox #bcdIdOnly>Search by BcdId Only</mat-checkbox>
        </div>
        <div>
            <form [formGroup]="form">
                <div>
                    <button
                        type="button"
                        class="btn btn-link"
                        (click)="toggleIsAdvancedSearch()"
                    >
                        {{
                            isAdvancedSearch
                                ? 'Hide Advanced Search'
                                : 'Advanced Search'
                        }}
                    </button>
                    <button
                        *ngIf="isAdvancedSearch"
                        type="button"
                        class="btn btn-link"
                        (click)="clearAdvancedSearch()"
                    >
                        Clear Advanced Search
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="top-buffer">
        <div class="container">
            <div class="row">
                <div class="col-sm-3" [hidden]="!isAdvancedSearch">
                    <div [hidden]="!isAdvancedSearch">
                        <div class="d-flex flex-row pt-1">
                            <div class="d-flex flex-column pr-3">
                                <label class="font-weight-bold"
                                    >Observed Data</label
                                >
                                <div
                                    *ngFor="
                                        let control of dataPresentFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchObservedDataTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Sources</label
                                >
                                <div
                                    *ngFor="
                                        let control of processInputFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        *ngIf="
                                            searchProcessInputTypes[i].active
                                        "
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchProcessInputTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                    <div
                                        *ngIf="
                                            searchProcessInputTypes[i]
                                                .description ===
                                                'Native Material' &&
                                            control.value
                                        "
                                    >
                                        <div
                                            *ngFor="
                                                let innerControl of dataSourceFilters.controls;
                                                let j = index
                                            "
                                            class="ml-5"
                                        >
                                            <mat-checkbox
                                                class="checkbox_2"
                                                [formControl]="innerControl"
                                                ngDefaultControl
                                            >
                                                {{
                                                    searchDataSourceTypes[j]
                                                        .description
                                                }}
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Micro Process</label
                                >
                                <div
                                    *ngFor="
                                        let control of processOutputFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        *ngIf="
                                            searchProcessOutputTypes[i]
                                                .active &&
                                            searchProcessOutputTypes[i]
                                                .submenu == 'Micro'
                                        "
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchProcessOutputTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Chemistry Process</label
                                >
                                <div
                                    *ngFor="
                                        let control of processOutputFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        *ngIf="
                                            searchProcessOutputTypes[i]
                                                .active &&
                                            searchProcessOutputTypes[i]
                                                .submenu == 'Chemistry'
                                        "
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchProcessOutputTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Crossings</label
                                >
                                <div
                                    *ngFor="
                                        let control of processOutputFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        *ngIf="
                                            searchProcessOutputTypes[i]
                                                .active &&
                                            searchProcessOutputTypes[i]
                                                .submenu == 'Cross'
                                        "
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchProcessOutputTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                </div>

                                <div
                                    *ngFor="
                                        let control of processOutputFilters.controls;
                                        let i = index
                                    "
                                >
                                    <mat-checkbox
                                        *ngIf="
                                            searchProcessOutputTypes[i]
                                                .active &&
                                            searchProcessOutputTypes[i]
                                                .submenu == 'Other'
                                        "
                                        [formControl]="control"
                                        ngDefaultControl
                                    >
                                        {{
                                            searchProcessOutputTypes[i]
                                                .description
                                        }}
                                    </mat-checkbox>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Sort Order</label
                                >
                                <div>
                                    <mat-radio-group
                                        [(ngModel)]="sortTypeSelected"
                                    >
                                        <div
                                            *ngFor="
                                                let control of sortFilters.controls;
                                                let i = index
                                            "
                                        >
                                            <mat-radio-button
                                                [formControl]="control"
                                                ngDefaultControl
                                                [value]="
                                                    searchSortTypes[i].value
                                                "
                                            >
                                                {{
                                                    searchSortTypes[i]
                                                        .description
                                                }}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>

                                <label class="font-weight-bold mt-4"
                                    >Direction</label
                                >
                                <div>
                                    <mat-radio-group
                                        [(ngModel)]="sortDirSelected"
                                    >
                                        <div
                                            *ngFor="
                                                let control of sortDirFilters.controls;
                                                let i = index
                                            "
                                        >
                                            <mat-radio-button
                                                [formControl]="control"
                                                ngDefaultControl
                                                [value]="
                                                    searchSortDirs[i].value
                                                "
                                            >
                                                {{
                                                    searchSortDirs[i]
                                                        .description
                                                }}
                                            </mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isAdvancedSearch ? 'col-lg-9' : 'col-lg-12'">
                    <h2>Search results</h2>
                    <table class="table table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th>Export</th>
                                <th>Sample (ID)</th>
                                <th>Process (Type: ID)</th>
                                <th>Source (Name: ID)</th>
                                <th>Data Present</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <ng-container
                            *ngIf="
                                currentNumberOfResults > 0;
                                else noResultsBlock
                            "
                        >
                            <tr *ngFor="let sample of obs.results">
                                <td>
                                    <mat-checkbox
                                        ngDefaultControl
                                        (change)="checkExports($event)"
                                        id="EXP_{{ sample.id }}"
                                    >
                                    </mat-checkbox>
                                </td>

                                <td>
                                    <a
                                        [routerLink]="[
                                            '/sample-detail',
                                            sample.id
                                        ]"
                                    >
                                        {{ sample.id }}
                                    </a>
                                </td>
                                <td>
                                    <ng-container
                                        *ngIf="
                                            sample.parentProcess?.type != null
                                        "
                                    >
                                        {{ sample.parentProcess?.type }}:
                                        {{ sample.parentProcess?.id }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            sample.type != null &&
                                            sample.type != 'Source'
                                        "
                                    >
                                        {{ sample.type }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            sample.type != null &&
                                            sample.type == 'Source'
                                        "
                                    >
                                        Native material
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container
                                        *ngIf="
                                            sample.source.id ||
                                                sample.parentSamples;
                                            else genericNaBlock
                                        "
                                    >
                                        <a
                                            *ngFor="
                                                let sampleSource of sample.parentSamples
                                                    | keyvalue : valueOrder
                                            "
                                            [routerLink]="[
                                                '/sample-detail',
                                                sampleSource.value.id
                                            ]"
                                        >
                                            <span style="white-space: nowrap"
                                                >{{ sampleSource.value.name }}:
                                                {{
                                                    sampleSource.value.id
                                                }}</span
                                            ><br />
                                        </a>
                                        <a
                                            *ngIf="
                                                sample.parentSamples.length ===
                                                0
                                            "
                                            [routerLink]="[
                                                '/sample-detail',
                                                sample.source.id
                                            ]"
                                        >
                                            {{ sample.source.name }}:{{
                                                sample.source.id
                                            }}<br />
                                        </a>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container
                                        *ngIf="
                                            sample.observedData != null;
                                            else genericNaBlock
                                        "
                                    >
                                        <ng-container
                                            *ngFor="
                                                let dataset of sample.observedData
                                                    | keyvalue;
                                                let last = last
                                            "
                                        >
                                            {{ dataset.key
                                            }}{{ !last ? ',' : '' }}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td
                                    *ngIf="
                                        sample.metadata?.dateReceived != null
                                    "
                                >
                                    <ng-container>
                                        {{
                                            sample.metadata?.dateReceived
                                                | date : 'M/d/y' : 'GMT'
                                        }}
                                    </ng-container>
                                </td>
                                <td
                                    *ngIf="
                                        sample.metadata?.collectionDate != null
                                    "
                                >
                                    <ng-container>
                                        {{
                                            sample.metadata?.collectionDate
                                                | date : 'M/d/y' : 'GMT'
                                        }}
                                    </ng-container>
                                </td>
                                <td
                                    *ngIf="
                                        sample.parentProcess?.metadata?.Process
                                            ? ['Date Prepared'] !== null
                                            : null
                                    "
                                >
                                    <ng-container
                                        *ngIf="
                                            sample.parentProcess.metadata
                                                .Process['Date Prepared'] !==
                                            'Invalid date'
                                        "
                                    >
                                        {{
                                            sample.parentProcess.metadata
                                                .Process['Date Prepared']
                                                | date : 'M/d/y' : 'GMT'
                                        }}
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #noResultsBlock>
                            <tr>
                                <td colspan="4">No matches.</td>
                            </tr>
                        </ng-template>
                    </table>
                    <div class="row top-buffer">
                        <div class="col-md-12 pb-3">
                            <button
                                class="btn btn-outline-primary"
                                type="button"
                                name="SelectExport"
                                (click)="addSamples()"
                                [disabled]="!showExportButton"
                            >
                                Select for Export
                            </button>
                            &nbsp;&nbsp;
                            <button
                                class="btn btn-outline-primary"
                                type="button"
                                (click)="exportSamples()"
                                [disabled]="
                                    !samplesToExport ||
                                    (samplesToExport$ | async).length === 0
                                "
                            >
                                Export Selected Samples ({{
                                    (samplesToExport$ | async).length
                                }})
                            </button>
                            &nbsp;&nbsp;
                            <bcdbio-export-samples
                                [buttonLabel]="'Export All Search Results'"
                                [samplesToExport]="obs.results"
                            ></bcdbio-export-samples>
                        </div>
                        <ng-container *ngIf="currentNumberOfResults > 0">
                            <div class="d-flex align-items-center">
                                <div class="pr-2">
                                    Showing {{ currentNumberOfResults }} of
                                    {{ totalNumSearchResults }} search
                                    {{
                                        totalNumSearchResults === 1
                                            ? 'result'
                                            : 'results'
                                    }}.
                                </div>
                                <bcdbio-search-pagination
                                    *ngIf="!searchResultsExhausted"
                                    (getMoreResultsEvent)="
                                        getMoreResults($event)
                                    "
                                ></bcdbio-search-pagination>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #genericNaBlock> --</ng-template>
    </div>
</div>
