export enum DataImportType {
    SOURCE = 'Native Material',
    MONO = 'Mono',
    FREE_MONO = 'Free Mono',
    LINKAGE = 'Linkage',
    TRAY_METADATA = 'Tray Metadata',
    GROWTH_CURVE = 'Growth Curve',
    FASTQ_MAPPING = 'FASTQ Mapping',
    FASTQ = 'FASTQ',
    METABOLOMICS = 'Metabolomics',
    METABOLOMICS_ANALYTES = 'Metabolomics Analytes',
    FECAL_SOURCE = 'Fecal Source',
    SINGLE_STRAIN = 'Single Strain',
    FECAL_SLURRY = 'Fecal Slurry',
    FECAL_POOL = 'Fecal Pool',
    PH = 'pH',
}
