import { DataImportValidator } from './data-import-validator';
import { DataImportType } from '../data-import-type';
import { DataImportData } from '../data/data-import-data';
import { Observable, of } from 'rxjs';

export class DataImportValidatorMetabAnalytes extends DataImportValidator {
    private static validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): void {
        for (let i = 0; i < dataRows.length; i++) {
            const checkHeaders = headerRow.map((string) =>
                string.toLowerCase()
            );
            const name = dataRows[i][checkHeaders.indexOf('name')];
            const pos = dataRows[i][checkHeaders.indexOf('pos.')];
            const dateTimeStr =
                dataRows[i][checkHeaders.indexOf('acq. date-time')];
            if (dataRows[i].length !== headerRow.length) {
                errors.push(
                    `${name} ${pos} (${dateTimeStr}): Expected ${headerRow.length} columns.  Received ${dataRows[i].length}.`
                );
            }
            if (isNaN(Date.parse(dateTimeStr))) {
                errors.push(
                    `${name} ${pos} (${dateTimeStr}): Invalid date for 'Acq. Date-Time'.`
                );
            }
        }
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        DataImportValidatorMetabAnalytes.validateDataRows(
            headerRow,
            rows,
            errors
        );

        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
