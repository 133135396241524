import { DataImportValidator } from './data-import-validator';
import { DataImportData } from '../data/data-import-data';
import { DataImportDataTray } from '../data/data-import-data-tray';
import { SamplesByBcdIdGQL, StudiesByStudyIdGQL } from '@bcdbio/udb-graphql';
import { Injectable } from '@angular/core';
import { map, mapTo } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { DataImportType } from '@bcdbio/data';

@Injectable({ providedIn: 'root' })
export class DataImportValidatorTray extends DataImportValidator {
    public static TRAY_METADATA_FILE_PATTERN = /([\w-]*)\.metadata\./;

    constructor(
        private samplesByBcdIdGQL: SamplesByBcdIdGQL,
        private studiesByStudyIdGQL: StudiesByStudyIdGQL
    ) {
        super();
    }

    private static ignoreSampleId(id: string) {
        return id === 'E' || id === 'NA';
    }

    private static collectSampleIds(
        headerRow: string[],
        dataRows: Array<string[]>
    ): string[] {
        const sampleIds: Set<string> = new Set<string>();
        const biologicIdx = headerRow.indexOf('Biologic_source_ID');
        const glycanIdx = headerRow.indexOf('Glycan_source_ID');
        for (let i = 0, n = dataRows.length; i < n; i++) {
            const biologic = dataRows[i][biologicIdx];
            if (!DataImportValidatorTray.ignoreSampleId(biologic)) {
                sampleIds.add(biologic.trim());
            }
            const glycan = dataRows[i][glycanIdx];
            if (!DataImportValidatorTray.ignoreSampleId(glycan)) {
                sampleIds.add(glycan.trim());
            }
        }
        return Array.from(sampleIds);
    }

    private static collectStudyIds(
        headerRow: string[],
        dataRows: Array<string[]>
    ): string[] {
        const studyIds: Set<string> = new Set<string>();
        const s1Idx = headerRow.indexOf('Study_1_ID');
        const s2Idx = headerRow.indexOf('Study_2_ID');
        for (let i = 0; i < dataRows.length; i++) {
            let studyId = dataRows[i][s1Idx];
            if (studyId !== undefined && studyId !== 'null') {
                studyIds.add(studyId.trim());
            }
            studyId = dataRows[i][s2Idx];
            if (studyId !== undefined && studyId !== 'null') {
                studyIds.add(studyId.trim());
            }
        }
        return Array.from(studyIds);
    }

    private static validateFileName(fileName: string, errors: any[]) {
        const m = fileName.match(
            DataImportValidatorTray.TRAY_METADATA_FILE_PATTERN
        );
        if (!m || m.length < 2) {
            errors.push(
                "File name does not match expected pattern '[Tray Name].metadata'."
            );
        }
    }

    private validateHeaders(headerRow: string[], errors: any[]): void {
        DataImportDataTray.HEADERS.forEach((h) => {
            if (!headerRow.includes(h)) {
                errors.push(`Missing header: '${h}'.`);
            }
        });
    }

    private validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): Observable<boolean> {
        const sampleIds: string[] = DataImportValidatorTray.collectSampleIds(
            headerRow,
            dataRows
        );
        const studyIds: string[] = DataImportValidatorTray.collectStudyIds(
            headerRow,
            dataRows
        );
        return forkJoin([
            this.samplesByBcdIdGQL.fetch({ bcdIds: sampleIds }).pipe(
                map((result) => {
                    const indexedSamples: Set<string> = new Set<string>();
                    result.data.samples.forEach((s) => {
                        indexedSamples.add(s.bcdId);
                    });
                    sampleIds.forEach((id) => {
                        if (!indexedSamples.has(id)) {
                            errors.push(`Sample does not exist: '${id}'.`);
                        }
                    });
                    return true;
                })
            ),
            this.studiesByStudyIdGQL.fetch({ studyIds: studyIds }).pipe(
                map((result) => {
                    const indexedStudies: Set<string> = new Set<string>();
                    result.data.studyNodes.forEach((s) => {
                        indexedStudies.add(s.studyId);
                    });
                    studyIds.forEach((id) => {
                        if (!indexedStudies.has(id)) {
                            errors.push(`Study does not exist: '${id}'.`);
                        }
                    });
                    return true;
                })
            ),
        ]).pipe(mapTo(true));
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        DataImportValidatorTray.validateFileName(file.name, errors);
        this.validateHeaders(headerRow, errors);
        return this.validateDataRows(headerRow, rows, errors).pipe(
            map((done) => {
                return {
                    isValid: errors.length < 1,
                    errors: errors,
                };
            })
        );
    }
}
