import { Injectable } from '@angular/core';
import { forkJoin, from, merge, Observable, of } from 'rxjs';
import { SampleSaveResult, SaveResult } from './data-import/sample-save-result';
import { catchError, map, mapTo, mergeMap, reduce } from 'rxjs/operators';
import { Metadata } from './model/metadata.model';
import { Sample } from './model/sample.model';
import {
    AddPoolSourceGQL,
    CreateBcdIdGQL,
    CreateInteractionProcessAndSampleGQL,
    CreateInteractionProcessAndSampleSingleParentGQL,
    CreateProcessAndSampleGQL,
    DeleteSampleAndDescendantsGQL,
    MergeProcessMetadataGQL,
    MergeProcessReplicateMetadataGQL,
    MergeProcessStudyGQL,
    MetadataGroupGQL,
    SavePoolSourceMetadataGQL,
} from '@bcdbio/udb-graphql';
import { ProcessSaveResult } from './process-save-result';
import { MapperData } from './data-import/mapping/mapper-data';
import { Process } from './model/process.model';
import { FetchResult } from '@apollo/client';

interface CellData {
    replicate: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProcessApiService {
    constructor(
        private createBcdIdGQL: CreateBcdIdGQL,
        private createSampleAndProcessGQL: CreateProcessAndSampleGQL,
        private deleteSampleAndDescendantsGQL: DeleteSampleAndDescendantsGQL,
        private addPoolSourceGQL: AddPoolSourceGQL,
        private createInteractionProcessAndSampleGQL: CreateInteractionProcessAndSampleGQL,
        private createInteractionProcessAndSampleSingleParentGQL: CreateInteractionProcessAndSampleSingleParentGQL,
        private metadataGroupGQL: MetadataGroupGQL,
        private mergeProcessMetadataGQL: MergeProcessMetadataGQL,
        private mergeProcessReplicateMetadataGQL: MergeProcessReplicateMetadataGQL,
        private mergeProcessStudyGQL: MergeProcessStudyGQL,
        private savePoolSourceMetadataGQL: SavePoolSourceMetadataGQL
    ) {}

    public createProcessAndSample(
        fromId: string,
        processType: string,
        descriptiveText: string,
        sourceSex: string,
        sourceHealthStatus: string
    ): Observable<SampleSaveResult> {
        return this.createBcdIdGQL.mutate().pipe(
            mergeMap((bcdIdResults) => {
                // console.log('createProcessAndSample: id created', bcdIdResults);
                if (bcdIdResults.data.create_bcd_id.length > 0) {
                    return this.createSampleAndProcessGQL.mutate({
                        fromId: fromId,
                        id: bcdIdResults.data.create_bcd_id[0].bcd_id,
                        processType: processType,
                        descriptiveName: descriptiveText,
                        sourceSex: sourceSex,
                        sourceHealthStatus: sourceHealthStatus,
                    });
                } else {
                    return undefined;
                }
            }),
            map((createProcessSampleResults) => {
                if (
                    createProcessSampleResults &&
                    createProcessSampleResults.data.createProcessAndSample
                        .length > 0
                ) {
                    const sample = Sample.fromGQLData(
                        createProcessSampleResults.data
                            .createProcessAndSample[0]
                    );
                    return {
                        sample: sample,
                        success: true,
                        error: '',
                        message: '',
                    };
                } else {
                    return {
                        sample: null,
                        success: false,
                        error: 'Failed to create process and sample',
                        message: '',
                    };
                }
            })
        );
    }

    public deleteSampleAndDescendants(
        sampleId: string,
        isSourceSample: boolean
    ): Observable<{ sampleId: string }> {
        return this.deleteSampleAndDescendantsGQL
            .mutate({
                sampleId: sampleId,
                isSourceSample: isSourceSample,
            })
            .pipe(
                map((deleteSampleAndDescendantsResults) => {
                    return { sampleId: sampleId };
                })
            );
    }

    public addPoolSource(
        newPoolSourceId: string,
        processId: string,
        processOutputId: string,
        processOutputType: string,
        numDonors: string,
        sex: string,
        healthStatus: string
    ): Observable<SampleSaveResult> {
        console.log({
            newPoolSourceId,
            processId,
            processOutputId,
            processOutputType,
            numDonors,
            sex,
            healthStatus,
        });
        return this.addPoolSourceGQL
            .mutate({
                newPoolSourceId: newPoolSourceId,
                processId: processId,
                processOutputId: processOutputId,
                processOutputType: processOutputType,
                numDonors: numDonors,
                sex: sex,
                healthStatus: healthStatus,
            })
            .pipe(
                map((results) => {
                    console.log({ results });
                    if (results.data.addPoolSource.length > 0) {
                        const sample = Sample.fromGQLData(
                            results.data.addPoolSource[0]
                        );
                        return {
                            sample: sample,
                            success: true,
                            error: '',
                            message: '',
                        };
                    } else {
                        return {
                            sample: null,
                            success: false,
                            error: 'Failed to add pool source',
                            message: '',
                        };
                    }
                })
            );
    }

    public savePoolSourceMetadata(
        sampleId: string,
        processId: string,
        sourceName: string,
        metadataName: string,
        value: string
    ): Observable<SampleSaveResult> {
        return this.savePoolSourceMetadataGQL
            .mutate({
                sampleId: sampleId,
                processId: processId,
                sourceName: sourceName,
                metadataName: metadataName,
                value: value,
            })
            .pipe(
                map((results) => {
                    if (results.data.savePoolSourceMetadata.length > 0) {
                        return {
                            sample: null,
                            success: true,
                            error: '',
                            message:
                                results.data.savePoolSourceMetadata[0].bcdId,
                        };
                    } else {
                        return {
                            sample: null,
                            success: false,
                            error: 'Failed to save pool source metadata',
                            message: '',
                        };
                    }
                })
            );
    }

    public getMetadataForGroup(name: string): Observable<Metadata[]> {
        return this.metadataGroupGQL
            .fetch({
                name: name,
            })
            .pipe(
                map((result) => {
                    if (result.data.metadataGroups.length > 0) {
                        return result.data.metadataGroups[0].metadata
                            .map((md) => {
                                return {
                                    name: md.name,
                                    units: md.units,
                                };
                            })
                            .sort((a, b) => {
                                if (a.name === 'Other') {
                                    return 1;
                                } else if (b.name === 'Other') {
                                    return -1;
                                } else {
                                    return a.name > b.name ? 1 : -1;
                                }
                            });
                    } else {
                        return null;
                    }
                })
            );
    }

    public saveMetadataForGroups(
        processId: string,
        metadataGroups: {
            [key: string]: { [key: string]: number | string | Date };
        }
    ) {
        return from(Object.keys(metadataGroups)).pipe(
            mergeMap((groupName) => {
                return this.saveMetadataForGroup(
                    processId,
                    groupName,
                    metadataGroups[groupName]
                );
            }),
            reduce(
                (acc, curr) => {
                    if (!curr.success) {
                        acc.success = false;
                        acc.errors.push(curr.error);
                    }

                    return acc;
                },
                { success: true, message: '', errors: [] }
            )
        );
    }

    private saveMetadataForGroup(
        processId: string,
        groupName: string,
        metadata: { [key: string]: number | string | Date }
    ): Observable<ProcessSaveResult> {
        return from(Object.keys(metadata)).pipe(
            mergeMap((metadataName) => {
                return this.mergeProcessMetadataGQL.mutate({
                    processId: processId,
                    metadataGroup: groupName,
                    metadataName: metadataName,
                    metadataValue: metadata[metadataName]?.toString(),
                });
            }),
            map(() => {
                return {
                    processId: processId,
                    success: true,
                    message: '',
                    error: '',
                };
            }),
            catchError((result) => {
                return of({
                    processId: processId,
                    success: false,
                    message: '',
                    error: result.toString(),
                });
            })
        );
    }

    saveProcessGroupMetadataValue(
        processId: string,
        groupName: string,
        metadataName: string,
        metadataValue: string
    ): Observable<ProcessSaveResult> {
        return this.mergeProcessMetadataGQL
            .mutate({
                processId: processId,
                metadataGroup: groupName,
                metadataName: metadataName,
                metadataValue: metadataValue,
            })
            .pipe(
                map(() => {
                    return {
                        processId: processId,
                        success: true,
                        message: '',
                        error: '',
                    };
                }),
                catchError((result) => {
                    return of({
                        processId: processId,
                        success: false,
                        message: '',
                        error: result.toString(),
                    });
                })
            );
    }

    saveProcessGroupReplicateMetadataValue(
        processId: string,
        groupName: string,
        metadataName: string,
        metadataValue: string
    ): Observable<ProcessSaveResult> {
        const cells: CellData[] = JSON.parse(metadataValue);

        const observables = cells.map((c) =>
            this.mergeProcessReplicateMetadataGQL
                .mutate({
                    processId: processId,
                    metadataGroup: groupName,
                    metadataName: metadataName,
                    metadataValue: c.value,
                    replicateName: c.replicate,
                })
                .pipe(
                    map(() => {
                        return {
                            processId: processId,
                            success: true,
                            message: '',
                            error: '',
                        } as ProcessSaveResult;
                    }),
                    catchError((result) => {
                        return of({
                            processId: processId,
                            success: false,
                            message: '',
                            error: result.toString(),
                        } as ProcessSaveResult);
                    })
                )
        );
        return forkJoin(observables).pipe(
            mapTo({
                processId: processId,
                success: true,
                message: '',
                error: '',
            }),
            catchError((result) => {
                return of({
                    processId: processId,
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    public saveTrayData(data: MapperData): Observable<SaveResult> {
        return from(data.processes).pipe(
            mergeMap((process) => {
                return this.saveTrayProcess(process);
            }, 5),
            catchError((result) => {
                return of({
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    private saveTrayProcess(process: Process): Observable<ProcessSaveResult> {
        if (process.parentSamples.length === 2) {
            return this.createInteractionProcessAndSampleGQL
                .mutate({
                    processId: process.id,
                    sampleId: process.childSamples[0].id,
                    parent1Id: process.parentSamples[0].id,
                    parent2Id: process.parentSamples[1].id,
                })
                .pipe(
                    mergeMap((saveResult) => {
                        if (
                            saveResult.data.createInteractionProcessAndSample
                                .length > 0
                        ) {
                            return this.saveMetadataForInteractionProcess(
                                process
                            );
                        } else {
                            return this.getErrorFromResult(saveResult, process);
                        }
                    })
                );
        } else {
            return this.createInteractionProcessAndSampleSingleParentGQL
                .mutate({
                    processId: process.id,
                    sampleId: process.childSamples[0].id,
                    parentId: process.parentSamples[0].id,
                })
                .pipe(
                    mergeMap((saveResult) => {
                        if (
                            saveResult.data
                                .createInteractionProcessAndSampleSingleParent
                                .length > 0
                        ) {
                            return this.saveMetadataForInteractionProcess(
                                process
                            );
                        } else {
                            return this.getErrorFromResult(saveResult, process);
                        }
                    })
                );
        }
    }

    private getErrorFromResult(
        saveResult: FetchResult,
        process: Process
    ): Observable<ProcessSaveResult> {
        const errorMsg = saveResult.errors ? saveResult.errors.join('; ') : '';
        return of({
            processId: process.id,
            success: false,
            message: '',
            error: `${process.id}: [SAVE ERROR] ` + errorMsg,
        });
    }

    private saveMetadataForInteractionProcess(
        process: Process
    ): Observable<ProcessSaveResult> {
        const obs = {};
        for (const [key, value] of Object.entries(
            process.metadata['Interaction']
        )) {
            if (key === 'Cells') {
                obs[key] = this.saveProcessGroupReplicateMetadataValue(
                    process.id,
                    'Interaction',
                    key,
                    value as string
                );
            } else {
                obs[key] = this.saveProcessGroupMetadataValue(
                    process.id,
                    'Interaction',
                    key,
                    value as string
                );
            }
        }
        process.studies.forEach((study) => {
            obs[study.studyId] = this.mergeProcessStudyGQL.mutate({
                processId: process.id,
                studyId: study.studyId,
                sampleId: process.childSamples[0].id,
            });
        });
        return forkJoin(obs).pipe(
            mapTo({
                processId: process.id,
                success: true,
                message: '',
                error: '',
            }),
            catchError((result) => {
                return of({
                    processId: process.id,
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }
}
