import { Component, Input, OnChanges } from '@angular/core';
import { Ancestry, SampleType } from '@bcdbio/data';
import {
    HierarchyNode,
    HierarchyPointNode,
    Selection,
    linkVertical,
    select,
    selectAll,
    tree,
    hierarchy,
} from 'd3';
import { Router } from '@angular/router';

const FORBIDDEN_CHAR_REPLACEMENT = '';

@Component({
    selector: 'bcdbio-ancestry-graph',
    templateUrl: './ancestry-graph.component.html',
    styleUrls: ['./ancestry-graph.component.scss'],
})
export class AncestryGraphComponent implements OnChanges {
    @Input() sampleAncestry: Ancestry;
    ancestorHierarchy: HierarchyNode<object>;
    descendantHierarchy: HierarchyNode<object>;
    d3Calc = {
        graphWidth: null,
        graphHeight: null,
        ancestorLevels: null,
        ancestorBreadth: null,
        descendantLevels: null,
        descendantBreadth: null,
    };

    ui = {
        treeLinkLength: 70,
        nodeSpacing: 100,
        fontSize: 14,
        nodeRadius: 4.5,
        lineWidth: 2.5,
        tooltipLineHeight: 10,
        tooltipGutter: 5,
        tooltipMargin: 4,
        tooltipColor: '#eee',
        tooltipBackgroundColor: '#777',
        MAX_STRING_LEN: 10,
        TRUNCATOR: '...',
        ONTOLOGY_SEP: '\u2192',
    };

    xOffset: number = 0;

    constructor(private router: Router) {}

    ngOnChanges(): void {
        this.processDataIntoHierarchies();
        this.calculateSvgDimensions();
        this.graphAncestorTree();
        this.graphDescendantTree();
        this.animateNodesOnLabelHover();
    }

    processDataIntoHierarchies() {
        this.ancestorHierarchy = hierarchy(this.sampleAncestry, (d) => {
            const children = [];
            d.parentSamples.forEach((parentSample) =>
                children.push(parentSample.ancestry)
            );
            return children;
        });
        this.descendantHierarchy = hierarchy(this.sampleAncestry, (d) => {
            const children = [];
            d.childSamples.forEach((childSample) =>
                children.push(childSample.ancestry)
            );
            return children;
        });
    }

    calculateSvgDimensions() {
        const ancestors = this.ancestorHierarchy;
        const descendants = this.descendantHierarchy;
        this.d3Calc.ancestorLevels = ancestors.height;
        this.d3Calc.ancestorBreadth = ancestors.count().value;
        this.d3Calc.descendantLevels = descendants.height;
        this.d3Calc.descendantBreadth = descendants.count().value;
        this.d3Calc.graphWidth = (
            select('#ancestry-graph').node() as SVGElement
        ).clientWidth;
        this.ui.nodeSpacing =
            this.d3Calc.graphWidth /
            Math.max(
                this.d3Calc.ancestorBreadth,
                this.d3Calc.descendantBreadth
            );
        this.d3Calc.graphHeight = Math.max(
            this.ui.treeLinkLength *
                (this.d3Calc.ancestorLevels + this.d3Calc.descendantLevels),
            1
        );

        select('#ancestry-graph')
            .attr('width', this.d3Calc.graphWidth)
            .attr('height', this.d3Calc.graphHeight)
            .attr(
                'viewBox',
                `0, 0, ${this.d3Calc.graphWidth}, ${this.d3Calc.graphHeight}`
            )
            .style('overflow', 'visible')
            .style('font-size', `${this.ui.fontSize}px`);
    }

    graphDescendantTree() {
        const descendantRoot = tree<object>().size([
            Math.max(this.d3Calc.descendantBreadth * this.ui.nodeSpacing, 1),
            Math.max(this.d3Calc.descendantLevels * this.ui.treeLinkLength, 1),
        ])(this.descendantHierarchy);
        const width = Math.max(
            this.d3Calc.descendantBreadth * this.ui.nodeSpacing,
            1
        );
        const height = Math.max(
            this.d3Calc.descendantLevels * this.ui.treeLinkLength,
            1
        );
        const yRatio =
            this.d3Calc.ancestorLevels /
            Math.max(
                this.d3Calc.ancestorLevels + this.d3Calc.descendantLevels,
                1
            );

        select('#descendant').selectAll('*').remove();
        const descendantsSvg = select('#descendant')
            .attr('height', height)
            .attr('x', 0)
            .attr('y', `${yRatio * 100}%`)
            .attr('viewBox', `0, 0, ${width}, ${height}`)
            .style('overflow', 'visible');
        if (
            descendantRoot.data['currentSample'].type ===
            SampleType.SpikedFecalPool
        ) {
            if (this.xOffset < descendantRoot.x) {
                descendantRoot.x -= this.xOffset;
            }
        }
        this.drawTreeLinks(descendantRoot, descendantsSvg);
        this.drawTreeNodes(descendantRoot, descendantsSvg);
    }

    graphAncestorTree() {
        select('#ancestor').selectAll('*').remove();

        const ancestorRoot = tree<object>().size([
            Math.max(this.d3Calc.ancestorBreadth * this.ui.nodeSpacing, 1),
            Math.max(this.d3Calc.ancestorLevels * this.ui.treeLinkLength, 1),
        ])(this.ancestorHierarchy);
        const width = Math.max(
            this.d3Calc.ancestorBreadth * this.ui.nodeSpacing,
            1
        );
        const height = Math.max(
            this.d3Calc.ancestorLevels * this.ui.treeLinkLength,
            1
        );

        const ancestorsSvg = select('#ancestor')
            .attr('height', height)
            .attr('x', 0)
            .attr('y', 0)
            .attr('viewBox', `0, 0, ${width}, ${height}`)
            .style('overflow', 'visible');
        if (
            ancestorRoot.data['currentSample'].type ===
            SampleType.SpikedFecalPool
        ) {
            if (ancestorRoot.children && ancestorRoot.children.length === 2) {
                this.xOffset =
                    (ancestorRoot.children[1].x - ancestorRoot.children[0].x) /
                    2;
            }
        }
        this.drawTreeLinks(ancestorRoot, ancestorsSvg, true);
        this.drawTreeNodes(ancestorRoot, ancestorsSvg, true);
    }

    drawTreeLinks(
        iterableTree: HierarchyPointNode<any>,
        container,
        inverted = false
    ): void {
        const height = inverted
            ? Math.max(this.d3Calc.ancestorLevels * this.ui.treeLinkLength, 1)
            : Math.max(
                  this.d3Calc.descendantLevels * this.ui.treeLinkLength,
                  1
              );
        container
            .append('g')
            .attr('fill', 'none')
            .attr('stroke', '#555')
            .attr('stroke-opacity', 0.4)
            .attr('stroke-width', this.ui.lineWidth)
            .selectAll('path')
            .data(iterableTree.links())
            .join('path')
            .attr('stroke', (d) => {
                return this.isCurrentSample(d.target.data) ||
                    this.isCurrentSample(d.source.data)
                    ? 'red'
                    : null;
            })
            .attr('stroke-opacity', (d) => {
                return this.isCurrentSample(d.target.data) ||
                    this.isCurrentSample(d.source.data)
                    ? 1
                    : null;
            })
            .attr(
                'd',
                linkVertical<HierarchyPointNode<any>, HierarchyPointNode<any>>()
                    .x((d) => d.x)
                    .y((d) => (inverted ? height - d.y : d.y)) // flips y-axis
            );
    }

    drawTreeNodes(
        iterableTree: HierarchyPointNode<object>,
        container,
        inverted = false
    ): void {
        const treeNodes = this.createVisualTreeNodes(
            iterableTree,
            container,
            inverted
        );
        this.drawCircleAtNodes(treeNodes);
        this.attachIdToNodes(treeNodes);

        const nativeMaterialsNodes = treeNodes.filter(
            (d) => d.data.currentSample.type === SampleType.Source
        );
        this.attachOntologyTooltipToNode(nativeMaterialsNodes);

        const fecalSourceNodes = treeNodes.filter((d) => {
            return d.data.currentSample.type === SampleType.FecalSource;
        });
        this.attachFecalSourceTooltipToNode(fecalSourceNodes);

        const singleStrainNodes = treeNodes.filter(
            (d) => d.data.currentSample.type === SampleType.SingleStrain
        );
        this.attachTaxonomyTooltipToNode(singleStrainNodes);

        const nodesWithProcessData = treeNodes.filter(
            (d) =>
                d.data.currentSample.type === SampleType.Processed ||
                d.data.currentSample.type === SampleType.FecalSlurry ||
                d.data.currentSample.type === SampleType.FecalPool ||
                d.data.currentSample.type === SampleType.Cross ||
                d.data.currentSample.type === SampleType.SpikedFecalPool
        );
        this.attachProcessInfoTooltipToNodes(nodesWithProcessData);
    }

    createVisualTreeNodes(
        iterableTree: HierarchyPointNode<object>,
        container: Selection<SVGGElement, any, HTMLElement, any>,
        inverted = false
    ): Selection<any, any, any, any> {
        const height = inverted
            ? Math.max(this.d3Calc.ancestorLevels * this.ui.treeLinkLength, 1)
            : Math.max(
                  this.d3Calc.descendantLevels * this.ui.treeLinkLength,
                  1
              );
        container
            .append('g')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-width', 3)
            .selectAll('g')
            .data(iterableTree.descendants())
            .join('g')
            // note(alee): hide the root node on the top ancestors graph so it doesn't get duplicated
            .filter((d) => !inverted || d.depth !== 0)
            .attr(
                'transform',
                (d) => `translate(${d.x},${inverted ? height - d.y : d.y})`
            )
            .attr('class', 'ancestry-nodes');
        return container.selectAll('.ancestry-nodes');
    }

    drawCircleAtNodes(treeNodes: Selection<any, any, SVGGElement, any>): void {
        treeNodes
            .append('circle')
            .attr('fill', (d) =>
                this.isCurrentSample(d.data)
                    ? 'red'
                    : d.data.children
                    ? '#555'
                    : '#999'
            )
            .attr('r', (d) =>
                this.isCurrentSample(d.data) || d?.parent === null
                    ? this.ui.nodeRadius + 1
                    : this.ui.nodeRadius
            )
            .clone(true)
            .attr('r', this.ui.nodeRadius - this.ui.lineWidth)
            .attr('fill', 'white');
    }

    attachIdToNodes(treeNodes: Selection<any, any, SVGGElement, any>): void {
        treeNodes = this.wrapVisualTreeNodesWithSampleHyperLink(treeNodes);
        treeNodes
            .append('text')
            .attr('class', `ancestry-node-label`)
            .attr('fill', (d) => (this.isCurrentSample(d.data) ? 'red' : null))
            .attr('font-weight', (d) =>
                this.isCurrentSample(d.data) || d?.parent === null
                    ? 'bold'
                    : 'normal'
            )
            .attr('dy', '0.31em')
            .attr('x', (d) =>
                d.children || this.isNodeAtEndOfCurrentLayer(d) ? -6 : 6
            )
            .attr('text-anchor', (d) =>
                d.children || this.isNodeAtEndOfCurrentLayer(d)
                    ? 'end'
                    : 'start'
            )
            .text((d) => this.truncateLongString(d.data.currentSample.id));
    }

    wrapVisualTreeNodesWithSampleHyperLink(
        visualTreeNodes: Selection<any, any, SVGGElement, any>
    ) {
        return visualTreeNodes
            .append('a')
            .style('cursor', 'pointer')
            .on('click', (e, d) => {
                this.changeSampleRoute(d.data.currentSample.id);
            });
    }

    changeSampleRoute(sampleId) {
        this.router.navigate(['/sample-detail', sampleId]);
    }

    attachOntologyTooltipToNode(node: Selection<any, any, any, any>): void {
        const toolTips = node.append('g');

        this.appendTextToTooltips(
            toolTips,
            this.ui.tooltipLineHeight,
            'Ontology:'
        );
        this.appendTextToTooltips(
            toolTips,
            2 * this.ui.tooltipLineHeight + this.ui.tooltipGutter,
            (d) => {
                const ontology = [
                    d.data?.currentSample?.ontology?.primaryLayCategory,
                    d.data?.currentSample?.ontology?.secondaryLayCategory,
                    d.data?.currentSample?.ontology?.plantName,
                    d.data?.currentSample?.ontology?.plantPart,
                ];
                return ontology
                    .map((element) => (element == null ? '(n/a)' : element))
                    .join(' ' + this.ui.ONTOLOGY_SEP + ' ');
            }
        );
        this.drawCanvasAtNodes(toolTips);
    }

    attachFecalSourceTooltipToNode(node: Selection<any, any, any, any>): void {
        const toolTips = node.append('g');

        this.appendTextToTooltips(
            toolTips,
            this.ui.tooltipLineHeight,
            'Metadata: '
        );
        this.appendTextToTooltips(
            toolTips,
            2 * this.ui.tooltipLineHeight + this.ui.tooltipGutter,
            (d) => {
                return 'ID: ' + d.data?.currentSample?.id;
            }
        );

        this.appendTextToTooltips(
            toolTips,
            3 * this.ui.tooltipLineHeight + 2 * this.ui.tooltipGutter,
            (d) =>
                `Donor Number: ${
                    'donorNumber' in d.data?.currentSample?.metadata
                        ? d.data?.currentSample?.metadata['donorNumber']
                        : '--'
                }`
        );

        this.appendTextToTooltips(
            toolTips,
            4 * this.ui.tooltipLineHeight + 3 * this.ui.tooltipGutter,
            (d) =>
                `Sex: ${
                    'sex' in d.data?.currentSample?.metadata
                        ? d.data?.currentSample?.metadata['sex']
                        : '--'
                }`
        );
        this.drawCanvasAtNodes(toolTips);
    }

    attachTaxonomyTooltipToNode(node: Selection<any, any, any, any>): void {
        const toolTips = node.append('g');
        this.appendTextToTooltips(
            toolTips,
            this.ui.tooltipLineHeight,
            'Sample info:'
        );

        this.appendTextToTooltips(
            toolTips,
            2 * this.ui.tooltipLineHeight + this.ui.tooltipGutter,
            (d) =>
                `Genus: ${
                    'genus' in d.data?.currentSample?.metadata
                        ? d.data?.currentSample?.metadata['genus']
                        : '--'
                }`
        );

        this.appendTextToTooltips(
            toolTips,
            3 * this.ui.tooltipLineHeight + 2 * this.ui.tooltipGutter,
            (d) =>
                `Species: ${
                    'species' in d.data?.currentSample?.metadata
                        ? d.data?.currentSample?.metadata['species']
                        : '--'
                }`
        );

        this.appendTextToTooltips(
            toolTips,
            4 * this.ui.tooltipLineHeight + 3 * this.ui.tooltipGutter,
            (d) => {
                const s = d.data?.currentSample;
                if (s?.taxonomy) {
                    return s?.taxonomy;
                } else {
                    const taxonomyArray = [
                        s?.metadata.kingdom,
                        s?.metadata.phylum,
                        s?.metadata.order,
                        s?.metadata.family,
                        s?.metadata.genus,
                        s?.metadata.species,
                    ];
                    return taxonomyArray
                        .map((element) => (element == null ? '(n/a)' : element))
                        .join(' ' + this.ui.ONTOLOGY_SEP + ' ');
                }
            }
        );
        this.drawCanvasAtNodes(toolTips);
    }

    attachProcessInfoTooltipToNodes(
        treeNodes: Selection<any, any, any, any>
    ): void {
        const toolTips = treeNodes.append('g');
        this.appendTextToTooltips(
            toolTips,
            this.ui.tooltipLineHeight,
            'Process Info:'
        );
        this.appendTextToTooltips(
            toolTips,
            2 * this.ui.tooltipLineHeight + this.ui.tooltipGutter,
            (d) => {
                return d.data?.currentSample?.parentProcess?.id != null
                    ? `ID: ${this.truncateLongString(
                          d.data?.currentSample?.parentProcess?.id
                      )}`
                    : 'ID: --';
            }
        );

        this.appendTextToTooltips(
            toolTips,
            3 * this.ui.tooltipLineHeight + 2 * this.ui.tooltipGutter,
            (d) => {
                return d.data?.currentSample?.parentProcess?.type != null
                    ? `Type: ${d.data?.currentSample?.parentProcess?.type}`
                    : 'Type: --';
            }
        );

        this.appendTextToTooltips(
            toolTips,
            4 * this.ui.tooltipLineHeight + 3 * this.ui.tooltipGutter,
            (d) => {
                return d.data?.currentSample?.parentProcess?.metadata
                    ?.Process !== undefined &&
                    'Notebook page' in
                        d.data?.currentSample?.parentProcess?.metadata?.Process
                    ? `NBP: ${d.data?.currentSample?.parentProcess?.metadata?.Process['Notebook page']}`
                    : d.data?.currentSample?.parentProcess?.metadata &&
                      'Tray Name' in
                          d.data?.currentSample?.parentProcess?.metadata
                              ?.Interaction
                    ? `Tray Name: ${d.data?.currentSample?.parentProcess?.metadata?.Interaction['Tray Name']}`
                    : 'NBP: --';
            }
        );

        this.drawCanvasAtNodes(toolTips);
    }

    sanitizeSelector(selector: string): string {
        return selector
            .split(' ')
            .join('')
            .replace(/^[^a-zA-Z0-9]+|[^\w_-]+/g, FORBIDDEN_CHAR_REPLACEMENT);
    }

    drawCanvasAtNodes(treeNodes: Selection<any, any, any, any>): void {
        const width = this.computeTooltipCanvasWidth(treeNodes);
        const height = this.computeTooltipCanvasHeight(treeNodes);

        treeNodes
            .insert('rect', 'text')
            .attr(
                'class',
                (d) =>
                    'tooltip-' +
                    this.sanitizeSelector(d.data?.currentSample?.id)
            )
            .attr('width', width)
            .attr('height', height)
            .attr('fill', this.ui.tooltipBackgroundColor)
            .attr('rx', '3')
            .attr('x', (d) =>
                d.children || this.isNodeAtEndOfCurrentLayer(d)
                    ? -this.ui.tooltipMargin
                    : -(width - this.ui.tooltipMargin)
            )
            .attr('y', 0)
            .attr('visibility', 'hidden')
            .attr('opacity', 0);
    }

    computeTooltipCanvasWidth(
        treeNodes: Selection<any, any, any, any>
    ): number {
        let width = 0;
        const textElements = treeNodes.selectChildren('text');
        textElements.nodes().forEach((d: SVGGElement) => {
            return width < d.getBBox().width
                ? (width = d.getBBox().width)
                : null;
        });
        width += 2 * this.ui.tooltipMargin;

        return width;
    }

    computeTooltipCanvasHeight(
        treeNodes: Selection<any, any, any, any>
    ): number {
        let height = 0;
        treeNodes.nodes().forEach((d: SVGGElement) => {
            return height < d.getBBox().height
                ? (height = d.getBBox().height)
                : null;
        });
        height += this.ui.tooltipGutter;

        return height;
    }

    animateNodesOnLabelHover(): void {
        const transitionTime = 500;
        const nodeLabels: Selection<any, any, any, any> = selectAll(
            '.ancestry-node-label'
        );
        nodeLabels
            .on('mouseover', (e, d) => {
                select(e.currentTarget)
                    .transition()
                    .duration(transitionTime)
                    .style('font-size', `${this.ui.fontSize + 2}px`);
                const newid = d.data?.currentSample?.id
                    .replace('(', '\\(')
                    .replace(')', '\\)');
                selectAll(`.tooltip-${this.sanitizeSelector(newid)}`)
                    .transition()
                    .duration(transitionTime)
                    .attr('opacity', 0.95)
                    .attr('visibility', null)
                    .attr(
                        'transform',
                        d.children || this.isNodeAtEndOfCurrentLayer(d)
                            ? `translate(10,5)`
                            : `translate(-15,5)`
                    );
            })
            .on('mouseout', (e, d) => {
                select(e.currentTarget)
                    .transition()
                    .duration(transitionTime)
                    .style('font-size', null);
                const newid = d.data?.currentSample?.id
                    .replace('(', '\\(')
                    .replace(')', '\\)');
                selectAll(`.tooltip-${this.sanitizeSelector(newid)}`)
                    .transition()
                    .duration(transitionTime)
                    .attr('opacity', 0)
                    .attr('visibility', 'hidden')
                    .attr(
                        'transform',
                        d.children || this.isNodeAtEndOfCurrentLayer(d)
                            ? `translate(-10,-5)`
                            : `translate(15,-5)`
                    );
            });
    }

    appendTextToTooltips(
        toolTips: Selection<any, any, any, any>,
        verticalShift: number,
        message: string | ((any) => string)
    ): void {
        const renderMessage = this.makeCallable(message);

        toolTips
            .append('text')
            .attr(
                'class',
                (d) =>
                    'tooltip-' +
                    this.sanitizeSelector(d.data?.currentSample?.id)
            )
            .attr('fill', this.ui.tooltipColor)
            .attr('font-size', '0.75em')
            .attr('dy', verticalShift)
            .attr('text-anchor', 'start')
            .attr('opacity', 0)
            .text((d) => renderMessage(d));

        this.alignTooltipTextLeft(toolTips);
    }

    alignTooltipTextLeft(toolTips: Selection<any, any, any, any>): void {
        let width = 0;
        const allTextChildren: Selection<any, any, any, any> =
            toolTips.selectChildren('text');

        allTextChildren.nodes().forEach((d: SVGGElement) => {
            return width < d.getBBox().width
                ? (width = d.getBBox().width)
                : null;
        });

        allTextChildren.attr('dx', (d) =>
            d.children || this.isNodeAtEndOfCurrentLayer(d) ? 0 : 0 - width
        );
    }

    makeCallable(message: string | ((any) => string)): (any) => string {
        if (typeof message === 'function') {
            return message;
        } else {
            return (d) => message;
        }
    }

    isCurrentSample(node): boolean {
        return node?.currentSample?.id === this.sampleAncestry.currentSample.id;
    }

    truncateLongString(
        str,
        maxLength = this.ui.MAX_STRING_LEN,
        truncIndicator = this.ui.TRUNCATOR
    ): string {
        if (str.length > maxLength) {
            str = str.substring(0, maxLength) + truncIndicator;
        }
        return str;
    }

    isNodeAtEndOfCurrentLayer(node): boolean {
        return true;
        // return (node.parent?.children.indexOf(node) === 0) || (node.parent?.children.indexOf(node) === node.parent?.children?.length);
    }
}
