import { Injectable } from '@angular/core';
import { DataImportMapperSource } from './data-import-mapper-source';
import { DataImportMapper } from './data-import-mapper';
import { DataImportMapperMono } from './data-import-mapper-mono';
import { DataImportMapperLinkage } from './data-import-mapper-linkage';
import { DataImportMapperTray } from './data-import-mapper-tray';
import { DataImportMapperGrowth } from './data-import-mapper-growth';
import { DataImportMapperFile } from './data-import-mapper-file';
import { DataImportType } from '../data-import-type';
import { DataImportMapperFastqMapping } from './data-import-mapper-fastq-mapping';
import { DataImportMapperFastqFile } from './data-import-mapper-fastq-file';
import { DataImportMapperMetabAnalytes } from './data-import-mapper-metab-analytes';
import { DataImportMapperBio } from './data-import-mapper-bio';

@Injectable({
    providedIn: 'root',
})
export class DataImportMapperFactory {
    constructor(
        private dataImportMapperTray: DataImportMapperTray,
        private dataImportMapperFastqMapping: DataImportMapperFastqMapping,
        private dataImportMapperFastqFile: DataImportMapperFastqFile,
        private dataImportMapperBio: DataImportMapperBio
    ) {}

    public createMapper(dataFileType: DataImportType): DataImportMapper {
        switch (dataFileType) {
            case DataImportType.SOURCE:
                return new DataImportMapperSource();
            case DataImportType.MONO:
                return new DataImportMapperMono();
            case DataImportType.LINKAGE:
                return new DataImportMapperLinkage();
            case DataImportType.TRAY_METADATA:
                return this.dataImportMapperTray;
            case DataImportType.GROWTH_CURVE:
                return new DataImportMapperGrowth();
            case DataImportType.FASTQ_MAPPING:
                return this.dataImportMapperFastqMapping;
            case DataImportType.FASTQ:
                return this.dataImportMapperFastqFile;
            case DataImportType.METABOLOMICS_ANALYTES:
                return new DataImportMapperMetabAnalytes();
            case DataImportType.METABOLOMICS:
                return new DataImportMapperFile();
            case DataImportType.FECAL_SOURCE:
            case DataImportType.SINGLE_STRAIN:
            case DataImportType.FECAL_SLURRY:
            case DataImportType.FECAL_POOL:
                return this.dataImportMapperBio;
            default:
                throw Error('DataImportPersistFactory: Invalid data file type');
        }
    }
}
