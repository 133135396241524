import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as UiActions from './ui.actions';
import {
    DataImportService,
    ProcessApiService,
    ProjectApiService,
    Sample,
    SampleApiService,
} from '@bcdbio/data';
import { finalize, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UiPartialState } from '@bcdbio/ui';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { Project, Study } from '@bcdbio/udb-graphql';
import { StudyApiService } from '@bcdbio/data';
import { formatDate } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class UiEffects {
    loadUi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.loadUi),
            fetch({
                run: (action) => {
                    // Your custom service 'load' logic goes here. For now just return a success action...
                    return UiActions.loadUiSuccess({ ui: [] });
                },

                onError: (action, error) => {
                    console.error('Error', error);
                    return UiActions.loadUiFailure({ error });
                },
            })
        )
    );

    constructor(private actions$: Actions) {}
}
@Injectable()
export class StudyEffects {
    constructor(
        private actions$: Actions,
        private storeUi: Store<UiPartialState>,
        private studyApiService: StudyApiService,
        private projectApiService: ProjectApiService,
        private sampleApiService: SampleApiService,
        private router: Router
    ) {}
    retrieveSingleStudy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.singleStudyRequested),
            fetch({
                run: (singleStudyRequestedAction) => {
                    if (!singleStudyRequestedAction.studyId) {
                        return UiActions.studyRetrieved({
                            currentStudy: null,
                        });
                    }
                    return this.studyApiService
                        .getStudyDetail(singleStudyRequestedAction.studyId)
                        .pipe(
                            map((p) => {
                                return UiActions.studyRetrieved({
                                    currentStudy: p,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('singleStudyRequested Error', error);
                    return null;
                },
            })
        )
    );

    retrieveStudyList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.studyListRequested),
            fetch({
                run: (studyListRequestedAction) => {
                    return this.studyApiService.getStudyList().pipe(
                        map((data) => {
                            return UiActions.studyListRetrieved({
                                studies: data,
                            });
                        })
                    );
                },
                onError: (action, error) => {
                    console.error('studyListRequested Error', error);
                    return null;
                },
            })
        )
    );

    createStudy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.createNewStudy),
            fetch({
                run: (createNewStudyAction) => {
                    return this.studyApiService
                        .createNewStudy(
                            createNewStudyAction.study,
                            createNewStudyAction.studyId
                        )
                        .pipe(
                            tap((data: Study) => {
                                this.router.navigate(['/study', data.studyId]);
                            }),
                            map((data) => {
                                return UiActions.studyRetrieved({
                                    currentStudy: data,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    editStudy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.editStudy),
            fetch({
                run: (editStudyAction) => {
                    return this.studyApiService
                        .editStudy(editStudyAction.study)
                        .pipe(
                            map((data) => {
                                return UiActions.studyRetrieved({
                                    currentStudy: data,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    deleteStudy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteStudy),
            fetch({
                run: (deleteStudyAction) => {
                    return this.studyApiService
                        .deleteStudy(deleteStudyAction.studyId)
                        .pipe(
                            map((data) => {
                                return UiActions.studyDeleted({
                                    studyId: data.studyId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    studyDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.studyDeleted),
            fetch({
                run: (studyDeletedAction) => {
                    this.router.navigate(['/study']);
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    addStudyToProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.addStudyToProject),
            fetch({
                run: (addStudyToProjectAction) => {
                    return this.studyApiService
                        .addStudyToProject(
                            addStudyToProjectAction.studyId,
                            addStudyToProjectAction.projectId
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleStudyRequested({
                                    studyId: addStudyToProjectAction.studyId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    deleteStudyFromProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteStudyFromProject),
            fetch({
                run: (deleteStudyFromProjectAction) => {
                    return this.studyApiService
                        .deleteStudyFromProject(
                            deleteStudyFromProjectAction.studyProjectId
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleStudyRequested({
                                    studyId:
                                        deleteStudyFromProjectAction.studyId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    upsertDeleteStudyUrlInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.upsertDeleteStudyUrlInfo),
            fetch({
                run: (upsertDeleteStudyUrlInfoAction) => {
                    return this.studyApiService
                        .editStudyUrlInfo(
                            upsertDeleteStudyUrlInfoAction.deleted,
                            upsertDeleteStudyUrlInfoAction.upserted
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleStudyRequested({
                                    studyId:
                                        upsertDeleteStudyUrlInfoAction.studyId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    addRelatedSampleToProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.addRelatedSampleToProject),
            fetch({
                run: (addRelatedSampleToProjectAction) => {
                    return this.projectApiService
                        .addRelatedSampleToProject(
                            addRelatedSampleToProjectAction.sampleBcdId,
                            addRelatedSampleToProjectAction.projectId
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleProjectRequested({
                                    projectId:
                                        addRelatedSampleToProjectAction.projectId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    deleteRelatedSampleFromProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteRelatedSampleFromProject),
            fetch({
                run: (deleteRelatedSampleFromProjectAction) => {
                    return this.projectApiService
                        .deleteRelatedSampleFromProject(
                            deleteRelatedSampleFromProjectAction.projectSampleId
                        )
                        .pipe(
                            map((data) => {
                                console.log(
                                    'in effect, data returned from delete action: ',
                                    data
                                );
                                return UiActions.singleProjectRequested({
                                    projectId: data.projectId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    upsertDeleteProjectUrlInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.upsertDeleteProjectUrlInfo),
            fetch({
                run: (upsertDeleteProjectUrlInfoAction) => {
                    return this.projectApiService
                        .editProjectUrlInfo(
                            upsertDeleteProjectUrlInfoAction.deleted,
                            upsertDeleteProjectUrlInfoAction.upserted
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleProjectRequested({
                                    projectId:
                                        upsertDeleteProjectUrlInfoAction.projectId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    upsertDeleteSampleUrlInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.upsertDeleteSampleUrlInfo),
            fetch({
                run: (upsertDeleteSampleUrlInfoAction) => {
                    return this.sampleApiService
                        .editSampleUrlInfo(
                            upsertDeleteSampleUrlInfoAction.deleted,
                            upsertDeleteSampleUrlInfoAction.upserted
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleSampleRequested({
                                    sampleId:
                                        upsertDeleteSampleUrlInfoAction.sampleBcdId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );
}

@Injectable()
export class ProjectEffects {
    constructor(
        private actions$: Actions,
        private storeUi: Store<UiPartialState>,
        private sampleApiService: SampleApiService,
        private projectApiService: ProjectApiService,
        private processApiService: ProcessApiService,
        private router: Router
    ) {}
    retrievedSingleProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.singleProjectRequested),
            fetch({
                run: (singleProjectRequestedAction) => {
                    if (!singleProjectRequestedAction.projectId) {
                        return UiActions.projectRetrieved({
                            currentProject: null,
                        });
                    }
                    return this.projectApiService
                        .getProjectDetail(
                            singleProjectRequestedAction.projectId
                        )
                        .pipe(
                            map((p) => {
                                return UiActions.projectRetrieved({
                                    currentProject: p,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('singleProjectRequested Error', error);
                    return null;
                },
            })
        )
    );

    retrievedProjectList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.projectListRequested),
            fetch({
                run: (projectListRequestedAction) => {
                    return this.projectApiService.getProjectList().pipe(
                        map((data) => {
                            return UiActions.projectListRetrieved({
                                projects: data,
                            });
                        })
                    );
                },
                onError: (action, error) => {
                    console.error('projectListRequested Error', error);
                    return null;
                },
            })
        )
    );

    retrieveUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.userListRequested),
            fetch({
                run: (userListRequested) => {
                    return this.projectApiService.getUserList().pipe(
                        map((data) =>
                            UiActions.userListRetrieved({
                                users: data,
                            })
                        )
                    );
                },
                onError: (action, error) => {
                    console.error('userListRequested Error', error);
                    return null;
                },
            })
        )
    );

    retrieveRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.rolesListRequested),
            fetch({
                run: (rolesListRequested) => {
                    return this.projectApiService.getRolesList().pipe(
                        map((data) =>
                            UiActions.rolesListRetrieved({
                                roles: data,
                            })
                        )
                    );
                },
                onError: (action, error) => {
                    console.error('rolesListRequested Error', error);
                    return null;
                },
            })
        )
    );

    createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.createNewProject),
            fetch({
                run: (createNewProjectAction) => {
                    return this.projectApiService
                        .createNewProject(createNewProjectAction.project)
                        .pipe(
                            tap((data: Project) => {
                                this.router.navigate([
                                    '/project-detail',
                                    data.projectId,
                                ]);
                            }),
                            map((data) => {
                                return UiActions.projectRetrieved({
                                    currentProject: data,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    editProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.editProject),
            fetch({
                run: (editProjectAction) => {
                    return this.projectApiService
                        .editProject(editProjectAction.project)
                        .pipe(
                            map((data) => {
                                return UiActions.projectRetrieved({
                                    currentProject: data as Project,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    deleteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteProject),
            fetch({
                run: (deleteProjectAction) => {
                    return this.projectApiService
                        .deleteProject(deleteProjectAction.projectId)
                        .pipe(
                            map((data) => {
                                return UiActions.projectDeleted({
                                    projectId: data.projectId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    addOrDeleteProjectUserAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.addDeleteProjectUsers),
            fetch({
                run: (addDeleteProjectUsersAction) => {
                    return this.projectApiService
                        .editProjectUsers(
                            addDeleteProjectUsersAction.deleted,
                            addDeleteProjectUsersAction.added
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.singleProjectRequested({
                                    projectId:
                                        addDeleteProjectUsersAction.projectId,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );
}

// todo: remove effects that have been replaced with apollo
@Injectable()
export class SearchEffects {
    retrievedSingleSample$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UiActions.singleSampleRequested,
                UiActions.savePoolSourceMetadataSuccess,
                UiActions.addPoolSourceSuccess
            ),
            fetch({
                run: (singleSampleRequestedAction) => {
                    return this.sampleApiService
                        .getSampleDetail(singleSampleRequestedAction.sampleId)
                        .pipe(
                            map((s) => {
                                return UiActions.sampleRetrieved({
                                    currentSample: s,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('singleSampleRequested Error', error);
                    return null;
                },
            })
        )
    );

    createProcessAndSample$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.createProcessAndSample),
            fetch({
                run: (createProcessAndSampleAction) => {
                    return this.processApiService
                        .createProcessAndSample(
                            createProcessAndSampleAction.fromId,
                            createProcessAndSampleAction.processType,
                            createProcessAndSampleAction.descriptiveText,
                            createProcessAndSampleAction.sourceSex,
                            createProcessAndSampleAction.sourceHealthStatus
                        )
                        .pipe(
                            mergeMap((createProcessSampleResults) =>
                                forkJoin({
                                    saveMetadataNotebookPage:
                                        this.processApiService.saveProcessGroupMetadataValue(
                                            createProcessSampleResults.sample
                                                .parentProcess.id,
                                            'Process',
                                            'Notebook page',
                                            createProcessAndSampleAction.notebookPage
                                        ),
                                    saveMetadataDatePrepared:
                                        this.processApiService.saveProcessGroupMetadataValue(
                                            createProcessSampleResults.sample
                                                .parentProcess.id,
                                            'Process',
                                            'Date Prepared',
                                            createProcessAndSampleAction.datePrepared
                                        ),
                                    sample: of(
                                        createProcessSampleResults.sample
                                    ),
                                })
                            ),
                            map((results) => {
                                if (
                                    results.saveMetadataNotebookPage.success &&
                                    results.saveMetadataDatePrepared.success
                                ) {
                                    return UiActions.createProcessAndSampleSuccess(
                                        {
                                            fromId: createProcessAndSampleAction.fromId,
                                            processType:
                                                createProcessAndSampleAction.processType,
                                            descriptiveText:
                                                createProcessAndSampleAction.descriptiveText,
                                            sourceSex:
                                                createProcessAndSampleAction.sourceSex,
                                            sourceHealthStatus:
                                                createProcessAndSampleAction.sourceHealthStatus,
                                            sample: results.sample,
                                        }
                                    );
                                } else {
                                    return UiActions.createProcessAndSampleFail(
                                        {
                                            fromId: createProcessAndSampleAction.fromId,
                                            processType:
                                                createProcessAndSampleAction.processType,
                                            descriptiveText:
                                                createProcessAndSampleAction.descriptiveText,
                                            sourceSex:
                                                createProcessAndSampleAction.sourceSex,
                                            sourceHealthStatus:
                                                createProcessAndSampleAction.sourceHealthStatus,
                                            errors: [
                                                results.saveMetadataNotebookPage
                                                    .error,
                                                results.saveMetadataDatePrepared
                                                    .error,
                                            ],
                                        }
                                    );
                                }
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('createProcessAndSample Error', error);
                    return null;
                },
            })
        )
    );

    deleteSampleAndDescendants = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteSampleAndDescendants),
            fetch({
                run: (deleteSampleAndDescendantsAction) => {
                    return this.processApiService
                        .deleteSampleAndDescendants(
                            deleteSampleAndDescendantsAction.sampleId,
                            deleteSampleAndDescendantsAction.isSourceSample
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.deleteSampleAndDescendantsSuccess(
                                    {
                                        sampleId: data.sampleId,
                                    }
                                );
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('deleteSampleAndDescendants Error', error);
                    return null;
                },
            })
        )
    );

    deleteMeasurement = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.deleteMeasurement),
            fetch({
                run: (deleteMeasurementAction) => {
                    return this.sampleApiService
                        .deleteMeasurement(
                            deleteMeasurementAction.measurementUuid
                        )
                        .pipe(
                            map((data) => {
                                return UiActions.deleteMeasurementSuccess({
                                    measurementUuid: data.measurementUuid,
                                });
                            })
                        );
                },
                onError: (action, error) => {
                    console.error('deleteMeasurement Error', error);
                    return null;
                },
            })
        )
    );

    addPoolSource$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.addPoolSource),
            fetch({
                run: (addPoolSourceAction) => {
                    return this.processApiService
                        .addPoolSource(
                            addPoolSourceAction.newPoolSourceId,
                            addPoolSourceAction.processId,
                            addPoolSourceAction.processOutputId,
                            addPoolSourceAction.processOutputType,
                            addPoolSourceAction.numDonors,
                            addPoolSourceAction.sex,
                            addPoolSourceAction.healthStatus
                        )
                        .pipe(
                            map((results) => {
                                if (results.success) {
                                    return UiActions.addPoolSourceSuccess({
                                        newPoolSourceId:
                                            addPoolSourceAction.newPoolSourceId,
                                        processId:
                                            addPoolSourceAction.processId,
                                        sampleId:
                                            addPoolSourceAction.processOutputId,
                                        sampleType:
                                            addPoolSourceAction.processOutputType,
                                        numDonors:
                                            addPoolSourceAction.numDonors,
                                        sex: addPoolSourceAction.sex,
                                        healthStatus:
                                            addPoolSourceAction.healthStatus,
                                        sample: results.sample,
                                    });
                                } else {
                                    return UiActions.addPoolSourceFail({
                                        newPoolSourceId:
                                            addPoolSourceAction.newPoolSourceId,
                                        processId:
                                            addPoolSourceAction.processId,
                                        sampleId:
                                            addPoolSourceAction.processOutputId,
                                        sampleType:
                                            addPoolSourceAction.processOutputType,
                                        numDonors:
                                            addPoolSourceAction.numDonors,
                                        sex: addPoolSourceAction.sex,
                                        healthStatus:
                                            addPoolSourceAction.healthStatus,
                                        error: results.error,
                                    });
                                }
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    savePoolSourceMetadata$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.savePoolSourceMetadata),
            fetch({
                run: (savePoolSourceMetadataAction) => {
                    return this.processApiService
                        .savePoolSourceMetadata(
                            savePoolSourceMetadataAction.sampleId,
                            savePoolSourceMetadataAction.processId,
                            savePoolSourceMetadataAction.sourceName,
                            savePoolSourceMetadataAction.metadataName,
                            savePoolSourceMetadataAction.value
                        )
                        .pipe(
                            map((results) => {
                                if (results.success) {
                                    return UiActions.savePoolSourceMetadataSuccess(
                                        {
                                            sampleId:
                                                savePoolSourceMetadataAction.sampleId,
                                            processId:
                                                savePoolSourceMetadataAction.processId,
                                            sourceName:
                                                savePoolSourceMetadataAction.sourceName,
                                            metadataName:
                                                savePoolSourceMetadataAction.metadataName,
                                            value: savePoolSourceMetadataAction.value,
                                            sample: results.sample,
                                        }
                                    );
                                } else {
                                    return UiActions.savePoolSourceMetadataFail(
                                        {
                                            processId:
                                                savePoolSourceMetadataAction.processId,
                                            sampleId:
                                                savePoolSourceMetadataAction.sampleId,
                                            sourceName:
                                                savePoolSourceMetadataAction.sourceName,
                                            metadataName:
                                                savePoolSourceMetadataAction.metadataName,
                                            value: savePoolSourceMetadataAction.value,
                                            error: results.error,
                                        }
                                    );
                                }
                            })
                        );
                },
                onError: (action, error) => {
                    return null;
                },
            })
        )
    );

    refreshCurrentSample$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UiActions.saveProcessMetadataSuccess,
                UiActions.createProcessAndSampleSuccess,
                UiActions.saveSampleMetadataSuccess
            ),
            withLatestFrom(this.storeUi),
            fetch({
                run: (action: any) => {
                    return of(action).pipe(
                        withLatestFrom(this.storeUi),
                        map(([a, store]) => {
                            return UiActions.singleSampleRequested({
                                sampleId: store.ui.currentSampleId,
                            });
                        })
                    );
                },
            })
        )
    );

    saveProcessMetadata$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.saveProcessMetadata),
            fetch({
                run: (saveProcessMetadataAction) => {
                    return this.processApiService
                        .saveMetadataForGroups(
                            saveProcessMetadataAction.id,
                            saveProcessMetadataAction.metadataGroups
                        )
                        .pipe(
                            map((result) => {
                                if (result.success) {
                                    return UiActions.saveProcessMetadataSuccess(
                                        {
                                            id: saveProcessMetadataAction.id,
                                            metadataGroups:
                                                saveProcessMetadataAction.metadataGroups,
                                            message: result.message,
                                        }
                                    );
                                } else {
                                    return UiActions.saveProcessMetadataFail({
                                        id: saveProcessMetadataAction.id,
                                        metadataGroups:
                                            saveProcessMetadataAction.metadataGroups,
                                        errors: result.errors,
                                    });
                                }
                            })
                        );
                },
            })
        )
    );
    today = new Date();
    username: string = '';
    saveSampleMetadata$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.saveSampleMetadata),
            fetch({
                onError(a: any, e: any): any {
                    console.error('onError', a, e);
                },
                run: (saveSampleMetadataAction) => {
                    const sampleWrapperForMetadata = new Sample();
                    sampleWrapperForMetadata.id = saveSampleMetadataAction.id;

                    sampleWrapperForMetadata.metadata = {
                        ...saveSampleMetadataAction.metadata,
                        lastEditDate: formatDate(this.today, 'M/d/yy', 'en-US'),
                        lastEditUser: this.username,
                    };
                    sampleWrapperForMetadata.uneditableMetadata = {
                        ...saveSampleMetadataAction.uneditableMetadata,
                        lastEditDate: true,
                        lastEditUser: true,
                    };

                    return this.sampleApiService
                        .saveSampleMetadata(sampleWrapperForMetadata)
                        .pipe(
                            map((result) => {
                                if (result.success) {
                                    return UiActions.saveSampleMetadataSuccess({
                                        id: saveSampleMetadataAction.id,
                                        metadata:
                                            saveSampleMetadataAction.metadata,
                                        message: result.message,
                                    });
                                } else {
                                    return UiActions.saveSampleMetadataFail({
                                        id: saveSampleMetadataAction.id,
                                        metadata:
                                            saveSampleMetadataAction.metadata,
                                        errors: result.error,
                                    });
                                }
                            })
                        );
                },
            })
        )
    );

    constructor(
        private actions$: Actions,
        private storeUi: Store<UiPartialState>,
        private sampleApiService: SampleApiService,
        private processApiService: ProcessApiService,
        private auth: AuthService
    ) {
        this.auth.user$.subscribe((user) => {
            this.username = user.name;
        });
    }
}

@Injectable()
export class DataImportEffects {
    dataImportInitiatedResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.dataImportInitiated),
            fetch({
                run: (dataImportInitiatedAction) => {
                    try {
                        return this.dataImportService
                            .readFile(
                                dataImportInitiatedAction.file,
                                dataImportInitiatedAction.dataFileType
                            )
                            .pipe(
                                map((resultsData) => {
                                    if (resultsData.errors.length < 1) {
                                        return UiActions.dataImportFileReadSuccess(
                                            {
                                                file: dataImportInitiatedAction.file,
                                                data: resultsData.data,
                                                dataFileType:
                                                    dataImportInitiatedAction.dataFileType,
                                            }
                                        );
                                    } else {
                                        return UiActions.dataImportFileReadFail(
                                            {
                                                file: dataImportInitiatedAction.file,
                                                dataFileType:
                                                    dataImportInitiatedAction.dataFileType,
                                                errors: resultsData.errors,
                                            }
                                        );
                                    }
                                })
                            );
                    } catch (e) {
                        console.error('Data import file read error', e);
                        return UiActions.dataImportFileReadFail({
                            file: dataImportInitiatedAction.file,
                            dataFileType:
                                dataImportInitiatedAction.dataFileType,
                            errors: [e.toString()],
                        });
                    }
                },
            })
        )
    );

    dataImportFileReadSuccessResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.dataImportFileReadSuccess),
            fetch({
                run: (dataImportFileReadSuccessAction) => {
                    try {
                        return this.dataImportService
                            .validate(
                                dataImportFileReadSuccessAction.file,
                                dataImportFileReadSuccessAction.data,
                                dataImportFileReadSuccessAction.dataFileType
                            )
                            .pipe(
                                map((results) => {
                                    if (results.isValid) {
                                        return UiActions.dataImportFileValidationSuccess(
                                            {
                                                file: dataImportFileReadSuccessAction.file,
                                                data: dataImportFileReadSuccessAction.data,
                                                dataFileType:
                                                    dataImportFileReadSuccessAction.dataFileType,
                                            }
                                        );
                                    } else {
                                        return UiActions.dataImportFileValidationFail(
                                            {
                                                file: dataImportFileReadSuccessAction.file,
                                                data: dataImportFileReadSuccessAction.data,
                                                dataFileType:
                                                    dataImportFileReadSuccessAction.dataFileType,
                                                errors: results.errors,
                                            }
                                        );
                                    }
                                })
                            );
                    } catch (e) {
                        return UiActions.dataImportFileValidationFail({
                            file: dataImportFileReadSuccessAction.file,
                            data: dataImportFileReadSuccessAction.data,
                            dataFileType:
                                dataImportFileReadSuccessAction.dataFileType,
                            errors: [e.toString()],
                        });
                    }
                },
            })
        )
    );

    dataImportFileValidResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.dataImportFileValidationSuccess),
            fetch({
                run: (dataImportFileValidationSuccessAction) => {
                    try {
                        return this.dataImportService
                            .save(
                                dataImportFileValidationSuccessAction.file,
                                dataImportFileValidationSuccessAction.data,
                                dataImportFileValidationSuccessAction.dataFileType
                            )
                            .pipe(
                                map((result) => {
                                    console.log(result);
                                    if (result.success) {
                                        return UiActions.dataImportFileSaveSuccess(
                                            {
                                                file: dataImportFileValidationSuccessAction.file,
                                                data: dataImportFileValidationSuccessAction.data,
                                                dataFileType:
                                                    dataImportFileValidationSuccessAction.dataFileType,
                                                message: result.message,
                                            }
                                        );
                                    } else {
                                        return UiActions.dataImportFileSaveFail(
                                            {
                                                file: dataImportFileValidationSuccessAction.file,
                                                data: dataImportFileValidationSuccessAction.data,
                                                errors: [result.error],
                                            }
                                        );
                                    }
                                }),
                                finalize(() => {
                                    this.storeUi.dispatch(
                                        UiActions.dataImportFilePostProc({
                                            file: dataImportFileValidationSuccessAction.file,
                                            data: dataImportFileValidationSuccessAction.data,
                                            dataFileType:
                                                dataImportFileValidationSuccessAction.dataFileType,
                                            message:
                                                'Data file import complete.',
                                        })
                                    );
                                })
                            );
                    } catch (e) {
                        return UiActions.dataImportFileSaveFail({
                            file: dataImportFileValidationSuccessAction.file,
                            data: dataImportFileValidationSuccessAction.data,
                            errors: [e.toString()],
                        });
                    }
                },
                onError: (action, error) => {
                    console.error('dataImportFileSave Error', error);
                    return UiActions.dataImportFileSaveFail({
                        file: action.file,
                        data: action.data,
                        errors: [JSON.stringify(error)],
                    });
                },
            })
        )
    );
    dataImportFilePostProc$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UiActions.dataImportFilePostProc),
            fetch({
                run: (dataImportFilePostProcAction) => {
                    try {
                        return this.dataImportService
                            .postProcess(
                                dataImportFilePostProcAction.file,
                                dataImportFilePostProcAction.data,
                                dataImportFilePostProcAction.dataFileType
                            )
                            .pipe(
                                map((result) => {
                                    if (result.success) {
                                        return UiActions.dataImportFileSaveSuccess(
                                            {
                                                file: dataImportFilePostProcAction.file,
                                                data: dataImportFilePostProcAction.data,
                                                dataFileType:
                                                    dataImportFilePostProcAction.dataFileType,
                                                message:
                                                    'Data file Post Process complete.',
                                            }
                                        );
                                    }
                                })
                            );
                    } catch (e) {
                        return UiActions.dataImportFileSaveFail({
                            file: dataImportFilePostProcAction.file,
                            data: dataImportFilePostProcAction.data,
                            errors: [e.toString()],
                        });
                    }
                },
                onError: (action, error) => {
                    return UiActions.dataImportFileSaveFail({
                        file: action.file,
                        data: action.data,
                        errors: [JSON.stringify(error)],
                    });
                },
            })
        )
    );

    constructor(
        private actions$: Actions,
        private storeUi: Store<UiPartialState>,
        private dataImportService: DataImportService
    ) {}
}
