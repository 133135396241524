import { DataImportValidator } from './data-import-validator';
import { DataImportData } from '../data/data-import-data';
import { DataImportDataLinkage } from '../data/data-import-data-linkage';
import { Observable, of } from 'rxjs';
import { DataImportType } from '../data-import-type';

export class DataImportValidatorLinkage extends DataImportValidator {
    private static validateHeaders(headerRow: string[], errors: any[]): void {
        // Make case insensitive by checking all lowercase
        const checkHeaders = DataImportDataLinkage.HEADERS.map((string) =>
            string.toLowerCase()
        );
        headerRow.forEach((label) => {
            if (!checkHeaders.includes(label.toLowerCase())) {
                errors.push(`Invalid header: '${label}'.`);
            }
        });
    }

    private static validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): void {
        for (let i = 0; i < dataRows.length; i++) {
            const checkHeaders = headerRow.map((string) =>
                string.toLowerCase()
            );
            const name = dataRows[i][checkHeaders.indexOf('name')];
            const dateTimeStr =
                dataRows[i][checkHeaders.indexOf('acq. date-time')];
            const sopValue = dataRows[i][checkHeaders.indexOf('sop version')];
            if (dataRows[i].length !== headerRow.length) {
                errors.push(
                    `${name} (${dateTimeStr}): Expected ${headerRow.length} columns.  Received ${dataRows[i].length}.`
                );
            }
            if (isNaN(Date.parse(dateTimeStr))) {
                errors.push(
                    `${name} (${dateTimeStr}): Invalid date for 'Acq. Date-Time'.`
                );
            }
            if (
                sopValue === 'null' ||
                sopValue === null ||
                sopValue === undefined ||
                sopValue.trim() === ''
            ) {
                errors.push(
                    `${name}: The 'SOP Version' column must not be empty.`
                );
            }
        }
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        DataImportValidatorLinkage.validateHeaders(headerRow, errors);
        DataImportValidatorLinkage.validateDataRows(headerRow, rows, errors);

        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
