import { LinkageDataset } from './linkage-dataset.model';
import { MonoDataset } from './mono-dataset.model';
import { FreeMonoDataset } from './free-mono-dataset.model';
import { GenericDataset } from './generic-dataset.model';
import { DataImportType } from '../data-import/data-import-type';
import { FileDataset } from './file-dataset.model';
import { GrowthCalculatedDataset } from './growth-calculated-dataset.model';
import { MetabAnalytesDataset } from './metab-analytes-dataset.model';
import { SampleType } from './sample.model';
import { SingleStrainGrowthCalculatedDataset } from './single-strain-growth-calculated.dataset.model';
import { FecalFermentGrowthCalculatedDataset } from './fecal-ferment-growth-calculated.dataset.model';

export class MeasurementFromGqlGenerator {
    public static fromGQLMeasurementData(
        measurementFromGql,
        sampleType
    ): GenericDataset {
        // initialize everything needed to make a dataset from scratch
        const observationDate: Date =
            measurementFromGql.observationDate != null
                ? new Date(measurementFromGql.observationDate)
                : null;
        const flattenedDataRow =
            GenericDataset.flattenGQLMeasurementData(measurementFromGql);
        const uuid = measurementFromGql.uuid;
        const monoMethodType = measurementFromGql.monoMethodType;
        const sopVersion = measurementFromGql.sopVersion;

        // make new dataset instance
        switch (measurementFromGql.measurementType.name) {
            case DataImportType.LINKAGE.valueOf():
                return new LinkageDataset(
                    observationDate,
                    [flattenedDataRow],
                    uuid,
                    sopVersion
                );
            case DataImportType.MONO.valueOf():
                return new MonoDataset(
                    observationDate,
                    [flattenedDataRow],
                    uuid,
                    monoMethodType,
                    sopVersion
                );
            case DataImportType.FREE_MONO.valueOf():
                return new FreeMonoDataset(
                    observationDate,
                    [flattenedDataRow],
                    uuid,
                    monoMethodType,
                    sopVersion
                );
            case DataImportType.GROWTH_CURVE.valueOf():
                if (sampleType === SampleType.SingleStrain) {
                    return new SingleStrainGrowthCalculatedDataset(
                        observationDate,
                        [flattenedDataRow],
                        uuid
                    );
                } else if (
                    sampleType === SampleType.FecalSlurry ||
                    sampleType === SampleType.FecalPool
                ) {
                    return new FecalFermentGrowthCalculatedDataset(
                        observationDate,
                        [flattenedDataRow],
                        uuid
                    );
                } else {
                    return new GrowthCalculatedDataset(
                        observationDate,
                        [flattenedDataRow],
                        uuid
                    );
                }
            case DataImportType.FASTQ_MAPPING.valueOf():
            case DataImportType.METABOLOMICS.valueOf():
                const urlVar = measurementFromGql.data.find(
                    (v) => v.name === 'URL'
                );
                const url = urlVar ? urlVar.stringValue : '';
                return new FileDataset(
                    observationDate,
                    measurementFromGql.measurementType.name,
                    url,
                    uuid
                );
            case DataImportType.METABOLOMICS_ANALYTES.valueOf():
                return new MetabAnalytesDataset(
                    measurementFromGql.replicate,
                    observationDate,
                    [flattenedDataRow],
                    uuid
                );
            default:
                return new GenericDataset(
                    observationDate,
                    [flattenedDataRow],
                    uuid
                );
        }
    }
}
