import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from './pages/search/search.component';
import { SampleDetailComponent } from './pages/sample-detail/sample-detail.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DisplayTabularDataComponent } from './components/display-tabular-data/display-tabular-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MetadataFormatPipe,
    SampleMetadataComponent,
} from './components/sample-metadata/sample-metadata.component';
import { SampleOntologyComponent } from './components/sample-ontology/sample-ontology.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { UiModule } from '@bcdbio/ui';
import { AncestryGraphComponent } from './components/ancestry-graph/ancestry-graph.component';
import { ParentProcessMetadataComponent } from './components/parent-process-metadata/parent-process-metadata.component';
import {
    AuthGuard,
    AuthHttpInterceptor,
    AuthModule,
} from '@auth0/auth0-angular';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { AuthenticationButtonComponent } from './components/authentication-button/authentication-button.component';
import { AuthNavComponent } from './components/auth-nav/auth-nav.component';
import { LoginComponent } from './pages/login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProfileComponent } from './pages/profile/profile.component';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SearchPaginationComponent } from './components/search-pagination/search-pagination.component';
import { DataImportComponent } from './pages/data-import/data-import.component';
import { CreateProcessSampleComponent } from './components/create-process-sample/create-process-sample.component';
import { DeleteSampleDataModalComponent } from './components/delete-sample-data-modal/delete-sample-data-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProcessEditComponent } from './components/process-edit/process-edit.component';
import { SampleMetadataEditComponent } from './components/sample-metadata-edit/sample-metadata-edit.component';
import { ExportSamplesComponent } from './components/export-samples/export-samples.component';
import { ExportSelectedSamplesComponent } from './components/export-selected-samples/export-selected-samples.component';
import { IMPORT_ARCHIVE_CONFIG } from '@bcdbio/app-tokens';
import { ProjectDetailComponent } from './pages/project-detail/project-detail.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { StudyComponent } from './pages/study/study.component';
import { StudyDetailComponent } from './pages/study-detail/study-detail.component';
import {
    SelectableTableComponent,
    BcdIdTruncatePipe,
} from './components/selectable-table/selectable-table.component';
import { DisplayFileDataComponent } from './components/display-file-data/display-file-data.component';
import { SourceSampleMetadataComponent } from './components/source-sample-metadata/source-sample-metadata.component';
import { PoolSourcesComponent } from './components/pool-sources/pool-sources.component';
import { PoolSourcesReadonlyComponent } from './components/pool-sources/pool-sources-readonly.component';
import { PoolSourcesFormComponent } from './components/pool-sources/pool-sources-form.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PoolSourcesEditComponent } from './components/pool-sources-edit/pool-sources-edit.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { UrlInfoWidgetComponent } from './components/url-info-widget/url-info-widget.component';
import { RelatedSamplesEditComponent } from './components/related-samples-edit/related-samples-edit.component';
import { SearchTermEntryComponent } from './search-term-entry/search-term-entry.component';

@NgModule({
    declarations: [
        AppComponent,
        SearchComponent,
        SampleDetailComponent,
        PageNotFoundComponent,
        SearchBarComponent,
        DisplayTabularDataComponent,
        SampleMetadataComponent,
        SampleOntologyComponent,
        AncestryGraphComponent,
        ParentProcessMetadataComponent,
        LoginButtonComponent,
        LogoutButtonComponent,
        AuthenticationButtonComponent,
        AuthNavComponent,
        LoginComponent,
        ProfileComponent,
        SearchPaginationComponent,
        DataImportComponent,
        CreateProcessSampleComponent,
        DeleteSampleDataModalComponent,
        ProcessEditComponent,
        SampleMetadataEditComponent,
        MetadataFormatPipe,
        ExportSamplesComponent,
        ExportSelectedSamplesComponent,
        ProjectDetailComponent,
        ProjectListComponent,
        StudyDetailComponent,
        StudyComponent,
        SelectableTableComponent,
        BcdIdTruncatePipe,
        DisplayFileDataComponent,
        SourceSampleMetadataComponent,
        PoolSourcesComponent,
        PoolSourcesReadonlyComponent,
        PoolSourcesFormComponent,
        PoolSourcesEditComponent,
        UrlInfoWidgetComponent,
        RelatedSamplesEditComponent,
        SearchTermEntryComponent,
    ],
    imports: [
        ModalModule.forRoot(),
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            authorizationParams: {
                redirect_uri: environment.auth.redirectUri,
                audience: environment.auth.audience,
            },
            httpInterceptor: {
                allowedList: [
                    `${environment.graphqlUrl}`,
                    `${environment.importArchive.endpointUrl}/*`,
                ],
            },
        }),
        ApolloModule,
        BrowserModule,
        RouterModule.forRoot(
            [
                {
                    path: 'login',
                    component: LoginComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Login',
                },
                {
                    path: 'logout',
                    component: LoginComponent,
                    canActivate: [AuthGuard],
                },
                {
                    path: 'profile',
                    component: ProfileComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Profile',
                },
                {
                    path: 'search',
                    component: SearchComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Search',
                },
                {
                    path: 'sample-detail',
                    component: SampleDetailComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Sample Detail',
                },
                {
                    path: 'sample-detail/:id',
                    component: SampleDetailComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Sample Detail',
                },
                {
                    path: 'project-detail',
                    component: ProjectDetailComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Project',
                },
                {
                    path: 'project-detail/:id',
                    component: ProjectDetailComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Project Detail',
                },
                {
                    path: 'study',
                    component: StudyComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Study',
                },
                {
                    path: 'study/:id',
                    component: StudyDetailComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Study Detail',
                },
                {
                    path: 'data-import',
                    component: DataImportComponent,
                    canActivate: [AuthGuard],
                    title: 'UDB - Import',
                },
                {
                    path: '',
                    redirectTo: '/search',
                    pathMatch: 'full',
                },
                { path: '**', component: PageNotFoundComponent, title: 'UDB' },
            ],
            {
                scrollPositionRestoration: 'enabled',
            }
        ),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        TabsModule.forRoot(),
        StoreModule.forRoot(
            {},
            {
                metaReducers: !environment.production ? [] : [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            }
        ),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot(),
        UiModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSortModule,
        MatSelectModule,
        MatRadioModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: environment.graphqlUrl,
                    }),
                };
            },
            deps: [HttpLink],
        },
        {
            provide: IMPORT_ARCHIVE_CONFIG,
            useValue: environment.importArchive,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
