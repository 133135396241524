import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    addPoolSource,
    getPoolSourcesSearchQueryParameters,
    loadMorePoolSourcesSearchResults,
    poolSourcesSearchResultsRequested,
    UiPartialState,
} from '@bcdbio/ui';
import { Sample, SampleType } from '@bcdbio/data';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PoolSourcesFullTextSearchGQL } from '@bcdbio/udb-graphql';

const MAX_FECAL_POOL_SOURCES = 8;
const MAX_SPIKED_POOL_SOURCES = 3;

@Component({
    selector: 'bcdbio-pool-sources-edit',
    templateUrl: './pool-sources-edit.component.html',
    styleUrls: ['./pool-sources-edit.component.scss'],
})
export class PoolSourcesEditComponent implements OnInit, OnDestroy {
    sample: Sample;
    searchQuery$: Observable<{
        searchTerm: string;
        searchQueryMaxResults: number;
    }> = this.storeUi.select(getPoolSourcesSearchQueryParameters);
    searchType: String;

    readonly RESULTS_PER_SEARCH: number = 10;
    maxSources: Number;
    currentNumberOfResults: number;
    searchResultsExhausted = false;
    currentMaxResults: number = this.RESULTS_PER_SEARCH;

    searchResults: Sample[];
    subscriptions: Subscription = new Subscription();

    constructor(
        private storeUi: Store<UiPartialState>,
        private poolSourcesFullTextSearchGQL: PoolSourcesFullTextSearchGQL
    ) {}

    ngOnInit(): void {
        this.searchType =
            this.sample.type === SampleType.FecalPool
                ? SampleType.FecalSlurry
                : SampleType.SingleStrain;
        this.maxSources =
            this.sample.type === SampleType.FecalPool
                ? MAX_FECAL_POOL_SOURCES
                : MAX_SPIKED_POOL_SOURCES;
        this.subscriptions.add(
            this.searchQuery$
                .pipe(
                    tap((searchQuery) => {
                        this.currentMaxResults =
                            searchQuery.searchQueryMaxResults;
                    }),
                    filter((searchQuery) => searchQuery.searchTerm !== ''),
                    switchMap((searchQuery) => {
                        console.log({ searchQuery });
                        console.log('type: ', this.sample.type);
                        return this.poolSourcesFullTextSearchGQL.fetch({
                            first: searchQuery.searchQueryMaxResults,
                            searchString: searchQuery.searchTerm,
                            type:
                                this.sample.type === SampleType.FecalPool
                                    ? SampleType.FecalSlurry
                                    : SampleType.SingleStrain,
                        });
                    }),
                    map((results) => {
                        console.log({ results });
                        return results.data.poolSourcesFullTextSearch.map(
                            (gqlData) => {
                                return Sample.fromGQLData(gqlData);
                            }
                        );
                    })
                )
                .subscribe((results) => {
                    this.searchResultsExhausted =
                        this.areSearchResultsExhausted(
                            results,
                            this.currentMaxResults
                        );
                    this.currentNumberOfResults = results.length;
                    this.searchResults = results;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    searchForTerm(searchTerm: string) {
        this.storeUi.dispatch(
            poolSourcesSearchResultsRequested({
                searchTerm: searchTerm,
                maxResults: this.RESULTS_PER_SEARCH,
            })
        );
    }

    getMoreResults(requestedMoreSearchResults: boolean) {
        this.storeUi.dispatch(
            loadMorePoolSourcesSearchResults({
                maxResults:
                    this.currentNumberOfResults + this.RESULTS_PER_SEARCH,
            })
        );
    }

    areSearchResultsExhausted(
        searchResults: Sample[],
        maxResults: number
    ): boolean {
        if (searchResults == null || maxResults == null) {
            return false;
        } else {
            return searchResults.length < maxResults;
        }
    }

    sampleIsNotCurrentInput(resultSampleId: string): boolean {
        return (
            this.sample.parentSamples &&
            !this.sample.parentSamples.find(
                (ps) => ps && ps.id === resultSampleId
            )
        );
    }

    addPoolSource(resultSample: Sample) {
        this.storeUi.dispatch(
            addPoolSource({
                newPoolSourceId: resultSample.id,
                processId: this.sample.parentProcess.id,
                processOutputId: this.sample.id,
                processOutputType: this.sample.type,
                numDonors: (this.sample.parentSamples.length + 1).toString(),
                sex:
                    this.sample.type === SampleType.FecalPool
                        ? this.calculatePoolProperty(
                              this.sample.metadata.sex,
                              resultSample.source.metadata.sex
                          )
                        : '',
                healthStatus:
                    this.sample.type === SampleType.FecalPool
                        ? this.calculatePoolProperty(
                              this.sample.metadata.healthStatus,
                              resultSample.source.metadata.healthStatus
                          )
                        : '',
            })
        );
    }

    calculatePoolProperty(currentPoolProperty, newSourceProperty): string {
        return currentPoolProperty === newSourceProperty
            ? currentPoolProperty
            : 'mixed';
    }
}
