import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import { Sample } from '@bcdbio/data';
import { MetadataDisplay } from '../sample-metadata/sample-metadata.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { savePoolSourceMetadata, UiPartialState } from '@bcdbio/ui';

const MAX_STRING_LEN = 20;
const TRUNCATOR = '(...)';

@Component({
    selector: 'bcdbio-pool-sources-form',
    templateUrl: './pool-sources-form.component.html',
    styleUrls: ['./pool-sources-form.component.scss'],
})
export class PoolSourcesFormComponent implements OnChanges {
    @Input() sample: Sample;
    @Input() orderedMetadata: MetadataDisplay[][];
    @Output() finished = new EventEmitter();
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private storeUi: Store<UiPartialState>
    ) {
        this.form = fb.group({
            sampleId: '',
            sources: fb.array([]),
        });
    }

    get sources() {
        return this.form.controls['sources'] as FormArray;
    }
    ngOnChanges() {
        if (this.sample && this.orderedMetadata) {
            this.form.controls['sampleId'].setValue(this.sample.id);
            // reset form (side effect is that changes in the form will be lost on adding)
            const sourcesCtl = this.form.controls['sources'] as FormArray;
            while (sourcesCtl.length !== 0) {
                sourcesCtl.removeAt(0);
            }
            this.orderedMetadata.forEach((source) => {
                const sourceForm = this.newSourceFormGroup();
                source.forEach((item) => {
                    if (item.name === 'sourceName') {
                        sourceForm.controls['truncSourceName'].setValue(
                            this.truncateLongString(item.value)
                        );
                    }
                    sourceForm.controls[item.name].setValue(item.value);
                });
                sourcesCtl.push(sourceForm);
            });
            this.form.markAsPristine();
            this.form.markAsUntouched();
        }
    }

    save() {
        const sources = this.form.controls['sources'] as FormArray;
        if (this.form.dirty && this.form.valid) {
            sources.controls.forEach((formGroup: FormGroup) => {
                if (formGroup.dirty && formGroup.valid) {
                    const sourceName = formGroup.controls['sourceName'].value;
                    const concentration = formGroup.controls['concentration'];
                    if (concentration.dirty && concentration.valid) {
                        this.storeUi.dispatch(
                            savePoolSourceMetadata({
                                sampleId: this.sample.id,
                                processId: this.sample.parentProcess.id,
                                sourceName: sourceName,
                                metadataName: 'concentration',
                                value: concentration.value,
                            })
                        );
                    }
                    const ulAdded = formGroup.controls['ulAdded'];
                    if (ulAdded.dirty && ulAdded.valid) {
                        this.storeUi.dispatch(
                            savePoolSourceMetadata({
                                sampleId: this.sample.id,
                                processId: this.sample.parentProcess.id,
                                sourceName: sourceName,
                                metadataName: 'ulAdded',
                                value: ulAdded.value,
                            })
                        );
                    }
                }
            });
        }
        this.finished.emit(1);
    }
    cancel() {
        this.finished.emit(2);
    }

    truncateLongString(
        str,
        maxLength = MAX_STRING_LEN,
        truncIndicator = TRUNCATOR
    ): string {
        if (str.length > maxLength) {
            const numAffixCharsToKeep: number = Math.floor(
                (maxLength - truncIndicator.length) / 2
            );
            str =
                str.substring(0, numAffixCharsToKeep) +
                truncIndicator +
                str.substring(str.length - numAffixCharsToKeep, str.length);
        }
        return str;
    }

    private newSourceFormGroup(): FormGroup {
        return this.fb.group({
            truncSourceName: [{ value: '', disabled: true }],
            concentration: ['', Validators.required],
            ulAdded: ['', Validators.required],
            sourceName: [{ value: '', disabled: true }],
            sex: [{ value: '', disabled: true }],
            healthStatus: [{ value: '', disabled: true }],
        });
    }
}
