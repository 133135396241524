import { Observable } from 'rxjs';
import { SampleApiService } from '../../sample-api.service';
import { SaveResult } from '../sample-save-result';
import { MapperData } from '../mapping/mapper-data';
import { ProcessApiService } from '../../process-api.service';
import { DataImportType } from '../data-import-type';

export abstract class DataImportPersist {
    constructor(
        protected sampleApiService: SampleApiService,
        protected processApiService: ProcessApiService
    ) {}

    abstract save(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult>;
}
