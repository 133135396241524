import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as UiActions from './ui.actions';
import { UiEntity } from './ui.models';
import { DataImportFileReaderResults, Sample } from '@bcdbio/data';
import { Project, Role, Study, User } from '@bcdbio/udb-graphql';
import { setExportSampleIds } from './ui.actions';
import { Sort } from '@angular/material/sort';

export const UI_FEATURE_KEY = 'ui';

export interface State extends EntityState<UiEntity> {
    selectedId?: string | number; // which Ui record has been selected
    loaded: boolean; // has the Ui list been loaded
    error?: string | null; // last known error (if any)
    searchResults: Sample[];
    currentSample: Sample;
    currentStudy: Study;
    studies: Study[];
    studyListRetrieved: boolean;
    currentProject: Project;
    projects: Project[];
    users: User[];
    roles: Role[];
    currentSampleId: string;
    dataImportConsoleLog: string[];
    exportSampleIds: string[];
    searchQuery: {
        searchTerm: string;
        searchQueryMaxResults: number;
        bcdIdOnly: boolean;
        dataPresent: string[];
        dataSource: string[];
        processInputTypes: string[];
        processOutputTypes: string[];
        sortType: string;
        sortDir: string;
    };
    poolSourcesSearchQuery: {
        searchTerm: string;
        searchQueryMaxResults: number;
    };
    relatedSamplesSearchQuery: {
        searchTerm: string;
        searchQueryMaxResults: number;
    };
    listSearchTerm: string;
    studyListSort: Sort;
}

export interface UiPartialState {
    readonly [UI_FEATURE_KEY]: State;
}

export const uiAdapter: EntityAdapter<UiEntity> =
    createEntityAdapter<UiEntity>();

export const initialState: State = uiAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    searchResults: [],
    currentSample: undefined,
    currentSampleId: undefined,
    currentProject: undefined,
    currentStudy: undefined,
    studies: [],
    studyListRetrieved: false,
    projects: [],
    users: [],
    roles: [],
    dataImportConsoleLog: [],
    searchQuery: {
        searchTerm: '',
        searchQueryMaxResults: undefined,
        bcdIdOnly: false,
        dataPresent: [],
        dataSource: [],
        processInputTypes: [],
        processOutputTypes: [],
        sortType: '',
        sortDir: '',
    },
    poolSourcesSearchQuery: {
        searchTerm: '',
        searchQueryMaxResults: undefined,
    },
    relatedSamplesSearchQuery: {
        searchTerm: '',
        searchQueryMaxResults: undefined,
    },
    exportSampleIds: [],
    listSearchTerm: '',
    studyListSort: { active: '', direction: '' },
});

const uiReducer = createReducer(
    initialState,
    on(UiActions.loadUi, (state) => ({ ...state, loaded: false, error: null })),
    on(UiActions.loadUiSuccess, (state, { ui }) =>
        uiAdapter.setAll(ui, { ...state, loaded: true })
    ),
    on(UiActions.loadUiFailure, (state, { error }) => ({ ...state, error })),
    on(
        UiActions.searchResultsRequested,
        (
            state,
            {
                searchTerm,
                maxResults,
                bcdIdOnly,
                dataPresent,
                dataSource,
                processInputTypes,
                processOutputTypes,
                sortType,
                sortDir,
            }
        ) => ({
            ...state,
            searchQuery: {
                searchTerm: searchTerm,
                searchQueryMaxResults: maxResults,
                bcdIdOnly: bcdIdOnly,
                dataPresent: dataPresent,
                dataSource: dataSource,
                processInputTypes: processInputTypes,
                processOutputTypes: processOutputTypes,
                sortType: sortType,
                sortDir: sortDir,
            },
        })
    ),
    on(UiActions.searchResultsRetrieved, (state, { searchResults }) => ({
        ...state,
        searchResults: [...state.searchResults],
    })),
    on(
        UiActions.poolSourcesSearchResultsRequested,
        (state, { searchTerm, maxResults }) => ({
            ...state,
            poolSourcesSearchQuery: {
                searchTerm: searchTerm,
                searchQueryMaxResults: maxResults,
            },
        })
    ),
    on(UiActions.loadMorePoolSourcesSearchResults, (state, { maxResults }) => ({
        ...state,
        poolSourcesSearchQuery: {
            searchTerm: state.poolSourcesSearchQuery.searchTerm,
            searchQueryMaxResults: maxResults,
        },
    })),
    on(
        UiActions.relatedSamplesSearchResultsRequested,
        (state, { searchTerm, maxResults }) => ({
            ...state,
            relatedSamplesSearchQuery: {
                searchTerm: searchTerm,
                searchQueryMaxResults: maxResults,
            },
        })
    ),
    on(UiActions.clearRelatedSamplesSearchResults, (state) => ({
        ...state,
        relatedSamplesSearchQuery: {
            searchTerm: '',
            searchQueryMaxResults:
                state.relatedSamplesSearchQuery.searchQueryMaxResults,
        },
    })),
    on(
        UiActions.loadMoreRelatedSamplesSearchResults,
        (state, { maxResults }) => ({
            ...state,
            relatedSamplesSearchQuery: {
                searchTerm: state.relatedSamplesSearchQuery.searchTerm,
                searchQueryMaxResults: maxResults,
            },
        })
    ),
    on(UiActions.setExportSampleIds, (state, { exportSampleIds }) => ({
        ...state,
        exportSampleIds: exportSampleIds,
    })),
    on(UiActions.addExportSampleIds, (state, { exportSampleIds }) => ({
        ...state,
        exportSampleIds: state.exportSampleIds
            .concat(exportSampleIds)
            .filter((el, i, a) => i === a.indexOf(el)),
    })),
    on(
        UiActions.poolSourcesSearchResultsRetrieved,
        (state, { searchResults }) => ({
            ...state,
            searchResults,
        })
    ),
    on(UiActions.sampleRetrieved, (state, { currentSample }) => ({
        ...state,
        currentSample,
    })),
    on(UiActions.projectRetrieved, (state, { currentProject }) => ({
        ...state,
        currentProject,
    })),
    on(UiActions.projectListRetrieved, (state, { projects }) => ({
        ...state,
        projects,
    })),
    on(UiActions.studyRetrieved, (state, { currentStudy }) => ({
        ...state,
        currentStudy,
    })),
    on(UiActions.studyListRetrieved, (state, { studies }) => ({
        ...state,
        studies,
        studyListRetrieved: true,
    })),
    on(UiActions.studyDeleted, (state, { studyId }) => ({
        ...state,
        studies: state.studies.filter((s) => s.studyId !== studyId),
        currentStudy: null,
    })),
    on(UiActions.userListRetrieved, (state, { users }) => ({
        ...state,
        users,
    })),
    on(UiActions.rolesListRetrieved, (state, { roles }) => ({
        ...state,
        roles,
    })),
    on(UiActions.projectDeleted, (state, { projectId }) => ({
        ...state,
        projects: state.projects.filter((p) => p.projectId !== projectId),
        currentProject: null,
    })),
    on(UiActions.singleSampleRequested, (state, { sampleId }) => ({
        ...state,
        currentSampleId: sampleId,
    })),
    on(UiActions.loadMoreSearchResults, (state, { maxResults }) => ({
        ...state,
        searchQuery: {
            searchTerm: state.searchQuery.searchTerm,
            searchQueryMaxResults: maxResults,
            bcdIdOnly: state.searchQuery.bcdIdOnly,
            dataPresent: state.searchQuery.dataPresent,
            dataSource: state.searchQuery.dataSource,
            processInputTypes: state.searchQuery.processInputTypes,
            processOutputTypes: state.searchQuery.processOutputTypes,
            sortType: state.searchQuery.sortType,
            sortDir: state.searchQuery.sortDir,
        },
    })),
    // Data import console log messages below
    on(UiActions.dataImportInitiated, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog.concat(
            'Importing file: ' + results.file.name + '...'
        ),
    })),
    on(UiActions.dataImportFileReadSuccess, (state, results) => ({
        ...state,
        dataImportConsoleLog:
            state.dataImportConsoleLog.concat('Import file read.'),
    })),
    on(UiActions.dataImportFileReadFail, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog
            .concat(results.errors)
            .concat('File read failed.'),
    })),
    on(UiActions.dataImportFileValidationSuccess, (state, results) => ({
        ...state,
        dataImportConsoleLog:
            state.dataImportConsoleLog.concat('Validation passed.'),
    })),
    on(UiActions.dataImportFileValidationFail, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog
            .concat(results.errors)
            .concat('File validation failed.'),
    })),
    on(UiActions.dataImportFileSaveSuccess, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog.concat(
            results.message
        ),
    })),
    on(UiActions.dataImportFileSaveFail, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog.concat(results.errors),
    })),
    on(UiActions.dataImportFilePostProc, (state, results) => ({
        ...state,
        dataImportConsoleLog: state.dataImportConsoleLog.concat(
            results.message
        ),
    })),
    on(UiActions.changeListSearchTerm, (state, { searchTerm }) => ({
        ...state,
        listSearchTerm: searchTerm,
    })),
    on(UiActions.changeStudyListSort, (state, { sort }) => ({
        ...state,
        studyListSort: sort,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return uiReducer(state, action);
}
