import { DataImportFileReader } from './data-import-file-reader';
import { DataImportFileReaderCsv } from './data-import-file-reader-csv';
import { DataImportFileReaderFile } from './data-import-file-reader-file';
import { DataImportType } from '../data-import-type';

export class DataImportFileReaderFactory {
    constructor() {}

    public static createReader(dataFileType: string): DataImportFileReader {
        switch (dataFileType) {
            case DataImportType.SOURCE:
            case DataImportType.MONO:
            case DataImportType.LINKAGE:
            case DataImportType.TRAY_METADATA:
            case DataImportType.GROWTH_CURVE:
            case DataImportType.FASTQ_MAPPING:
            case DataImportType.METABOLOMICS_ANALYTES:
            case DataImportType.FECAL_SOURCE:
            case DataImportType.SINGLE_STRAIN:
            case DataImportType.FECAL_SLURRY:
            case DataImportType.FECAL_POOL:
                return new DataImportFileReaderCsv();
            case DataImportType.FASTQ:
            case DataImportType.METABOLOMICS:
                return new DataImportFileReaderFile();
            default:
                throw new Error(
                    'DataImportFileReaderFactory: Unknown Data File Type'
                );
        }
    }
}
