import { Sample } from '../../model/sample.model';
import { DataImportMapper } from './data-import-mapper';
import { DataRow } from '../../model/data-row.model';
import { MonoDataset } from '../../model/mono-dataset.model';
import { FreeMonoDataset } from '../../model/free-mono-dataset.model';
import { DataImportType } from '../data-import-type';

export class DataImportMapperMono extends DataImportMapper {
    public MAPPING = {
        'Fructose Results|Final Conc.': 'Fructose',
        'Mannose Results|Final Conc.': 'Mannose',
        'Ribose Results|Final Conc.': 'Ribose',
        'Allose Results|Final Conc.': 'Allose',
        'Rhamnose Results|Final Conc.': 'Rhamnose',
        'GlcA Results|Final Conc.': 'GlcA',
        'GalA Results|Final Conc.': 'GalA',
        'Glucose Results|Final Conc.': 'Glucose',
        'GlcNAc Results|Final Conc.': 'GlcNAc',
        'Xylose Results|Final Conc.': 'Xylose',
        'GalNAc Results|Final Conc.': 'GalNAc',
        'Galactose Results|Final Conc.': 'Galactose',
        'Arabinose Results|Final Conc.': 'Arabinose',
        'Fucose Results|Final Conc.': 'Fucose',
        'NeuAc Results|Final Conc.': 'NeuAc',
        'NeuGc Results|Final Conc.': 'NeuGc',
        'IdoA Results|Final Conc.': 'IdoA',
        'Apiose Results|Final Conc.': 'Apiose',
        'GlcN Results|Final Conc.': 'GlcN',
        'GalN Results|Final Conc.': 'GalN',
    };

    public rowMap(headerRow: string[], row: string[]): Sample {
        const sample = new Sample();
        sample.id = row[headerRow.indexOf('Name')];

        const dataRow: DataRow = {};
        Object.keys(this.MAPPING).forEach((key) => {
            if (headerRow.indexOf(key) > -1) {
                const value = row[headerRow.indexOf(key)];
                dataRow[this.MAPPING[key]] =
                    value === 'null' || value === null
                        ? null
                        : parseFloat(value);
            }
        });

        let observedDataType;
        let dataset;
        // if Data File name contains 'free' followed by 'mono', then treat as a 'Free Mono' dataset,
        // no matter their case (title, upper, lower) or how many varied characters separate these substrings
        const dataFile = row[headerRow.indexOf('Data File')].toLowerCase();
        if (
            dataFile.includes('free') &&
            dataFile.slice(dataFile.indexOf('free')).includes('mono')
        ) {
            observedDataType = DataImportType.FREE_MONO;
            dataset = new FreeMonoDataset(
                new Date(row[headerRow.indexOf('Acq. Date-Time')])
            );
            dataset.monoMethodType = ' ';
        } else {
            observedDataType = DataImportType.MONO;
            dataset = new MonoDataset(
                new Date(row[headerRow.indexOf('Acq. Date-Time')])
            );

            // Set the Mono Method based on the dataFile name, similar to Free.
            //if (dataFile.includes('vz') || dataFile.includes('visco')) {
            if (
                ['_vz', 'vz_', 'visco'].some((label) =>
                    dataFile.includes(label)
                )
            ) {
                if (dataFile.includes('blank')) {
                    dataset.monoMethodType = 'Vz Blank';
                } else {
                    dataset.monoMethodType = 'Vz';
                }
                //} else if (dataFile.includes('_sh')) {
            } else if (
                ['_sh', 'sh_'].some((label) => dataFile.includes(label))
            ) {
                dataset.monoMethodType = 'ShortH';
            } else {
                dataset.monoMethodType = 'Standard';
            }
        }

        // Read a Method Version column to set the MonoDataset Version field.
        const versionColumnIndex = headerRow.indexOf('SOP Version');
        dataset.sopVersion = ' ';
        if (versionColumnIndex >= 0) {
            dataset.sopVersion = row[versionColumnIndex];
        }

        dataset.data = [dataRow];
        sample.observedData = {};
        sample.observedData[observedDataType] = [dataset];
        return sample;
    }
}
