import { DataImportValidator } from './data-import-validator';
import { DataImportData } from '../data/data-import-data';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { DataImportDataGrowth } from '../data/data-import-data-growth';
import { DataImportType } from '@bcdbio/data';

export class DataImportValidatorGrowth extends DataImportValidator {
    public static GROWTH_CURVE_FILE_PATTERN = /([\w-]+)_(\d+)\.growthdata\./;

    private static validateFileName(fileName: string, errors: any[]) {
        const m = fileName.match(this.GROWTH_CURVE_FILE_PATTERN);
        if (!m || m.length < 3) {
            errors.push(
                "File name does not match expected pattern '[Tray Name]_[Date].growthdata'."
            );
        } else {
            const date = moment(m[2], 'MMDDYY');
            if (!date.isValid()) {
                errors.push(`Invalid date '${m[2]}' in file name.`);
            }
        }
    }

    private static validateHeaders(headerRow: string[], errors: any[]): void {
        if (!headerRow.includes(DataImportDataGrowth.TIME_HEADER)) {
            errors.push(
                `Missing header: '${DataImportDataGrowth.TIME_HEADER}'.`
            );
        }
    }

    private static validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: string[]
    ) {
        const timeIdx = headerRow.indexOf('Time');
        dataRows.forEach((row) => {
            const timeStr = row[timeIdx];
            if (row.length !== headerRow.length) {
                errors.push(
                    `${name} (${timeStr}): Expected ${headerRow.length} columns.  Received ${row.length}.`
                );
            }
            if (!timeStr.match(/^\d{1,2}:\d{1,2}:\d{1,2}$/)) {
                errors.push(
                    `${name} (${timeStr}): Invalid time for '${DataImportDataGrowth.TIME_HEADER}'.`
                );
            }
        });
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        DataImportValidatorGrowth.validateFileName(file.name, errors);
        DataImportValidatorGrowth.validateHeaders(headerRow, errors);
        DataImportValidatorGrowth.validateDataRows(headerRow, rows, errors);
        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
