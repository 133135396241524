<div class="container">
    <div class="row">
        <div class="col-md-4">
            <div class="top-buffer d-flex">
                <h2>Data Import</h2>
                <a
                    class="nav-link"
                    href="https://bcdbio.slite.com/api/s/tOk1H4MoFGJ2QO/Importing"
                    target="_blank"
                >
                    <i class="bi-box-arrow-up-right"></i>
                </a>
            </div>

            <form [formGroup]="dataImportForm" (ngSubmit)="submit()">
                <div class="form-group">
                    <label for="dataFileType">Import Type</label>
                    <mat-select
                        formControlName="dataFileType"
                        id="dataFileType"
                        class="form-control custom-select"
                        panelClass="import-dropdown"
                        required
                        ngDefaultControl
                    >
                        <ng-container *ngFor="let group of importMenu">
                            <mat-optgroup [label]="group.category">
                                <mat-option
                                    *ngFor="let sc of group.subcategories"
                                    [style.line-height]="'1em'"
                                    [value]="sc"
                                    >{{ sc }}</mat-option
                                >
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                </div>
                <div class="form-group">
                    <div class="custom-file">
                        <input
                            formControlName="file"
                            id="customFile"
                            type="file"
                            class="form-control custom-file-input"
                            (change)="onFileChange($event)"
                            required
                        />
                        <label for="customFile" class="custom-file-label">{{
                            fileLabel
                        }}</label>
                    </div>
                    <div
                        *ngIf="
                            formControls.file.touched &&
                            formControls.file.invalid
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="formControls.file.errors.required">
                            File is required.
                        </div>
                    </div>
                </div>

                <button class="btn btn-primary" type="submit">Submit</button>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="console-log-output-container col-md-8">
            <h4 class="top-buffer">Output</h4>
            <div class="console-log-output">
                <ng-container
                    *ngFor="
                        let entry of (consoleLog$ | async)?.slice().reverse()
                    "
                >
                    <div class="console-log-entry">{{ entry }}</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
