import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

@Pipe({
    name: 'unCamelCase',
})
export class UnCamelCasePipe implements PipeTransform {
    transform(camelCaseString: string): string {
        return startCase(camelCaseString);
    }
}
