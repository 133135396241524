import { DataImportMapper } from './data-import-mapper';
import { Sample } from '../../model/sample.model';
import { DataImportData } from '../data/data-import-data';
import { MapperData } from './mapper-data';
import { Observable, of } from 'rxjs';
import { DataImportDataGrowth } from '../data/data-import-data-growth';
import * as moment from 'moment';
import { DataImportType, GenericDataset, Process } from '@bcdbio/data';
import { DataImportValidatorGrowth } from '../validator/data-import-validator-growth';

export class DataImportMapperGrowth extends DataImportMapper {
    public static getTrayNameAndDateFromFileName(fileName: string): {
        trayName: string;
        date: moment.Moment;
    } {
        const m = fileName.match(
            DataImportValidatorGrowth.GROWTH_CURVE_FILE_PATTERN
        );
        const date = moment(m[2], 'MMDDYY');
        return {
            trayName: m[1],
            date: date,
        };
    }

    map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const fileInfo = DataImportMapperGrowth.getTrayNameAndDateFromFileName(
            file.name
        );
        const headers = data.getHeaderRow();
        const rows = data.getDataRows();
        const timeIdx = headers.indexOf(DataImportDataGrowth.TIME_HEADER);

        // Start by getting all the cell IDs from the headers: A1, A2, etc.
        const cells: string[] = headers.filter(
            (header) =>
                header !== DataImportDataGrowth.TIME_HEADER &&
                !header.match(/Growth/)
        );
        const cellIdxs: { [cell: string]: number } = cells.reduce(
            (idxs, cell) => {
                idxs[cell] = headers.indexOf(cell);
                return idxs;
            },
            {}
        );

        // Build up map of measurements, where each column from the data becomes a measurement, indexed by the cell.
        const measurements: {
            [cell: string]: { [time: number]: number };
        } = cells.reduce((measMap, cell) => {
            measMap[cell] = {};
            return measMap;
        }, {});
        rows.forEach((row) => {
            let key = 0;
            const m = row[timeIdx].match(/(\d{1,2}):(\d{1,2}):(\d{1,2})/);
            if (m && m.length > 3) {
                key =
                    3600 * parseInt(m[1], 10) +
                    60 * parseInt(m[2], 10) +
                    parseInt(m[3], 10);
            }
            Object.entries(cellIdxs).forEach(([cell, idx]) => {
                measurements[cell][key] = parseFloat(row[idx]);
            });
        });

        return of({ measurements: measurements, fileInfo: fileInfo });
    }

    rowMap(headerRow: string[], row: string[]): Sample {
        throw new Error(
            'rowMap() should not be called on DataImportMapperGrowth'
        );
    }
}
