import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
} from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import {
    changeListSearchTerm,
    getListSearchTerm,
    UiPartialState,
} from '@bcdbio/ui';
import { Store } from '@ngrx/store';

@Component({
    selector: 'bcdbio-search-term-entry',
    templateUrl: './search-term-entry.component.html',
    styleUrls: ['./search-term-entry.component.scss'],
})
export class SearchTermEntryComponent
    implements OnInit, AfterViewInit, ControlValueAccessor
{
    constructor(
        private storeUi: Store<UiPartialState>,
        private cd: ChangeDetectorRef
    ) {
        this.searchTerm$ = this.storeUi.select(getListSearchTerm);
    }

    @Input() placeholderLabel: string;
    searchTerm$: Observable<string>;
    searchTermSub: Subscription;
    value: string | any;

    @ViewChild('input') input: ElementRef;
    private propagateChange = (_: string) => {};

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.searchTermSub = fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                filter((event: KeyboardEvent) => {
                    const length = (<HTMLInputElement>event.target).value
                        .length;
                    return length >= 3 || length === 0;
                })
            )
            .subscribe((event: KeyboardEvent) => {
                this.value = (<HTMLInputElement>event.target).value;
                this.storeUi.dispatch(
                    changeListSearchTerm({ searchTerm: this.value })
                );
                this.propagateChange(this.value);
            });
        this.searchTerm$.pipe(first()).subscribe((value) => {
            this.value = value;
            this.propagateChange(value);
        });
        this.cd.detectChanges();
    }

    registerOnChange(fn: (_: string) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: string): void {
        this.value = value;
    }
}
