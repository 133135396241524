<div class="container">
    <div class="row">
        <div class="col-md-9">
            <h4>
                {{ displayData.length > 0 ? datasetTitle : 'No data present' }}
            </h4>
        </div>
        <div class="col-md-3">
            <div *ngIf="showNormalizationAbundanceToggle" class="form-check">
                <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        (change)="toggleDataNormalization()"
                        [id]="'normToggle-' + datasetTitle"
                    />
                    <label
                        class="custom-control-label"
                        [for]="'normToggle-' + datasetTitle"
                        >Normalized/Relative</label
                    >
                </div>
                <div class="custom-control custom-switch">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        (change)="toggleAbundanceOrdering()"
                        [id]="'abundanceToggle-' + datasetTitle"
                    />
                    <label
                        class="custom-control-label"
                        [for]="'abundanceToggle-' + datasetTitle"
                        >Abundance Ordering</label
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<tabset>
    <tab *ngIf="displayData.length > 1" heading="Summary" id="summary-tab">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th *ngIf="!normalizeData && abundanceOrdering">
                            Total
                        </th>
                        <th
                            *ngFor="
                                let analyte of abundanceOrdering
                                    ? summaryStatsForSummaryTab.analyteAbundanceOrder
                                    : displayData[0].defaultColumnOrder
                            "
                        >
                            {{ analyte }}
                        </th>
                        <th *ngIf="!normalizeData && !abundanceOrdering">
                            Total
                        </th>
                    </tr>
                </thead>
                <tfoot class="thead-light">
                    <tr>
                        <th [colSpan]="displayTableTotalColumns">Average</th>
                    </tr>
                    <tr>
                        <td *ngIf="!normalizeData && abundanceOrdering">
                            {{
                                summaryStatsForSummaryTab.avgOfTotals
                                    | number : '.3'
                            }}
                        </td>
                        <td
                            *ngFor="
                                let analyte of abundanceOrdering
                                    ? summaryStatsForSummaryTab.analyteAbundanceOrder
                                    : displayData[0].defaultColumnOrder
                            "
                        >
                            {{
                                summaryStatsForSummaryTab.analyteAvgValues[
                                    analyte
                                ] === 0 ||
                                summaryStatsForSummaryTab.analyteAvgValues[
                                    analyte
                                ]
                                    ? normalizeData
                                        ? (summaryStatsForSummaryTab
                                              .analyteAvgValues[analyte] /
                                              summaryStatsForSummaryTab.avgNormalizationFactor
                                          | percent : '.3')
                                        : (summaryStatsForSummaryTab
                                              .analyteAvgValues[analyte]
                                          | number : '.3')
                                    : '--'
                            }}
                        </td>
                        <td *ngIf="!normalizeData && !abundanceOrdering">
                            {{
                                summaryStatsForSummaryTab.avgOfTotals
                                    | number : '.3'
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th [colSpan]="displayTableTotalColumns">
                            Std. Deviation
                        </th>
                    </tr>
                    <tr>
                        <td *ngIf="!normalizeData && abundanceOrdering">
                            {{
                                summaryStatsForSummaryTab.stdDevOfTotals
                                    | number : '.3'
                            }}
                        </td>
                        <td
                            *ngFor="
                                let analyte of abundanceOrdering
                                    ? summaryStatsForSummaryTab.analyteAbundanceOrder
                                    : displayData[0].defaultColumnOrder
                            "
                        >
                            {{
                                summaryStatsForSummaryTab.analyteStdDevs[
                                    analyte
                                ] === 0 ||
                                summaryStatsForSummaryTab.analyteStdDevs[
                                    analyte
                                ]
                                    ? normalizeData
                                        ? (summaryStatsForSummaryTab
                                              .analyteStdDevs[analyte] /
                                              summaryStatsForSummaryTab.avgNormalizationFactor
                                          | percent : '.3')
                                        : (summaryStatsForSummaryTab
                                              .analyteStdDevs[analyte]
                                          | number : '.3')
                                    : '--'
                            }}
                        </td>
                        <td *ngIf="!normalizeData && !abundanceOrdering">
                            {{
                                summaryStatsForSummaryTab.stdDevOfTotals
                                    | number : '.3'
                            }}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </tab>
    <tab
        *ngFor="let currentDataset of sortedDisplayData; index as i"
        heading="{{ headings[i] }}"
        [id]="
            'tab-' +
            datasetTitle +
            '-' +
            currentDataset.observationDate?.getTime()
        "
    >
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th *ngIf="isMonoDataset">Method</th>
                        <th *ngIf="isMonoDataset || isLinkageDataset">
                            SOP Version
                        </th>
                        <th *ngIf="!normalizeData && abundanceOrdering">
                            Total
                        </th>
                        <th
                            *ngFor="
                                let analyte of abundanceOrdering
                                    ? currentDataset.getSummaryStats()
                                          .analyteAbundanceOrder
                                    : currentDataset.defaultColumnOrder
                            "
                        >
                            {{ analyte }}
                        </th>
                        <th
                            *ngIf="
                                !normalizeData &&
                                !abundanceOrdering &&
                                currentDataset.hasSummaryStats
                            "
                        >
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dataRow of currentDataset.data; index as j">
                        <td class="row-label-column" *ngIf="isMonoDataset">
                            {{ currentDataset.monoMethodTypes[j] }}
                        </td>

                        <td
                            class="row-label-column"
                            *ngIf="isMonoDataset || isLinkageDataset"
                        >
                            {{ currentDataset.sopVersions[j] }}
                        </td>

                        <td *ngIf="!normalizeData && abundanceOrdering">
                            {{
                                currentDataset.getSummaryStats().dataRowTotals[
                                    j
                                ] | number : '.3'
                            }}
                        </td>
                        <td
                            *ngFor="
                                let analyte of abundanceOrdering
                                    ? currentDataset.getSummaryStats()
                                          .analyteAbundanceOrder
                                    : currentDataset.defaultColumnOrder
                            "
                            [style]="
                                analyte | tableCellStyles : dataRow[analyte]
                            "
                        >
                            {{
                                type(dataRow[analyte]) === 'number'
                                    ? normalizeData
                                        ? (dataRow[analyte] /
                                              currentDataset.getSummaryStats()
                                                  .dataRowTotals[j]
                                          | percent : '.3')
                                        : (dataRow[analyte] | number : '.3')
                                    : dataRow[analyte]
                            }}
                        </td>
                        <td
                            *ngIf="
                                !normalizeData &&
                                !abundanceOrdering &&
                                currentDataset.hasSummaryStats
                            "
                        >
                            {{
                                currentDataset.getSummaryStats().dataRowTotals[
                                    j
                                ] | number : '.3'
                            }}
                        </td>
                    </tr>
                </tbody>
                <ng-container *ngIf="currentDataset.data.length > 1">
                    <tfoot class="thead-light">
                        <tr>
                            <th [colSpan]="displayTableTotalColumns">
                                Average
                            </th>
                        </tr>
                        <tr>
                            <td class="row-label-column" *ngIf="isMonoDataset">
                                <!-- blank column to match Method column above-->
                            </td>
                            <td class="row-label-column" *ngIf="isMonoDataset">
                                <!-- blank column to match Version column above-->
                            </td>
                            <td *ngIf="!normalizeData && abundanceOrdering">
                                {{
                                    currentDataset.getSummaryStats().avgOfTotals
                                        | number : '.3'
                                }}
                            </td>
                            <td
                                *ngFor="
                                    let analyte of abundanceOrdering
                                        ? currentDataset.getSummaryStats()
                                              .analyteAbundanceOrder
                                        : currentDataset.defaultColumnOrder
                                "
                            >
                                {{
                                    currentDataset.getSummaryStats()
                                        .analyteAvgValues[analyte] === 0 ||
                                    currentDataset.getSummaryStats()
                                        .analyteAvgValues[analyte]
                                        ? normalizeData
                                            ? (currentDataset.getSummaryStats()
                                                  .analyteAvgValues[analyte] /
                                                  currentDataset.getSummaryStats()
                                                      .avgNormalizationFactor
                                              | percent : '.3')
                                            : (currentDataset.getSummaryStats()
                                                  .analyteAvgValues[analyte]
                                              | number : '.3')
                                        : '--'
                                }}
                            </td>
                            <td
                                *ngIf="
                                    !normalizeData &&
                                    !abundanceOrdering &&
                                    !isGrowthCurveDataset
                                "
                            >
                                {{
                                    currentDataset.getSummaryStats().avgOfTotals
                                        | number : '.3'
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th [colSpan]="displayTableTotalColumns">
                                Std. Deviation
                            </th>
                        </tr>
                        <tr>
                            <td class="row-label-column" *ngIf="isMonoDataset">
                                <!-- blank column to match Method column above-->
                            </td>
                            <td class="row-label-column" *ngIf="isMonoDataset">
                                <!-- blank column to match Version column above-->
                            </td>
                            <td *ngIf="!normalizeData && abundanceOrdering">
                                {{
                                    currentDataset.getSummaryStats()
                                        .stdDevOfTotals | number : '.3'
                                }}
                            </td>
                            <td
                                *ngFor="
                                    let analyte of abundanceOrdering
                                        ? currentDataset.getSummaryStats()
                                              .analyteAbundanceOrder
                                        : currentDataset.defaultColumnOrder
                                "
                            >
                                {{
                                    currentDataset.getSummaryStats()
                                        .analyteStdDevs[analyte] === 0 ||
                                    currentDataset.getSummaryStats()
                                        .analyteStdDevs[analyte]
                                        ? normalizeData
                                            ? (currentDataset.getSummaryStats()
                                                  .analyteStdDevs[analyte] /
                                                  currentDataset.getSummaryStats()
                                                      .avgNormalizationFactor
                                              | percent : '.3')
                                            : (currentDataset.getSummaryStats()
                                                  .analyteStdDevs[analyte]
                                              | number : '.3')
                                        : '--'
                                }}
                            </td>
                            <td
                                *ngIf="
                                    !normalizeData &&
                                    !abundanceOrdering &&
                                    !isGrowthCurveDataset
                                "
                            >
                                {{
                                    currentDataset.getSummaryStats()
                                        .stdDevOfTotals | number : '.3'
                                }}
                            </td>
                        </tr>
                    </tfoot>
                </ng-container>
            </table>
        </div>
        <div *ngIf="userIsAdmin" class="delete-button">
            <button
                class="btn btn-outline-danger"
                type="button"
                (click)="deleteTabData(sortedDisplayData[i], datasetTitle)"
            >
                Delete this data
            </button>
        </div>
    </tab>
</tabset>
