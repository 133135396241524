import { Sample } from '../../model/sample.model';
import { DataImportMapper } from './data-import-mapper';
import { DataRow } from '../../model/data-row.model';
import { LinkageDataset } from '../../model/linkage-dataset.model';

export class DataImportMapperLinkage extends DataImportMapper {
    public rowMap(headerRow: string[], row: string[]): Sample {
        const sample = new Sample();
        const headers = headerRow.map((label) => label.toLowerCase());
        sample.id = row[headers.indexOf('name')];

        const dataRow: DataRow = {};
        this.MAPPING.forEach(({ importColumnHeader, neo4jName }) => {
            if (headers.indexOf(importColumnHeader.toLowerCase()) > -1) {
                const value =
                    row[headers.indexOf(importColumnHeader.toLowerCase())];
                dataRow[neo4jName] =
                    value === 'null' || value === null
                        ? null
                        : parseFloat(value);
            }
        });

        const dataset = new LinkageDataset(
            new Date(row[headers.indexOf('acq. date-time')])
        );

        // Read a Method Version column to set the MonoDataset Version field.
        const versionColumnIndex = headerRow.indexOf('SOP Version');
        dataset.sopVersion = ' ';
        if (versionColumnIndex >= 0) {
            dataset.sopVersion = row[versionColumnIndex];
        }

        dataset.data = [dataRow];
        sample.observedData = {};
        sample.observedData['Linkage'] = [dataset];

        return sample;
    }

    public MAPPING = [
        {
            importColumnHeader: 'T-Glucose Results|Resp.',
            neo4jName: 'T-Glucose',
        },
        {
            importColumnHeader: 'T-Glucose-f Results|Resp.',
            neo4jName: 'T-Glucose-f',
        },
        {
            importColumnHeader: 'T-Glucose-p Results|Resp.',
            neo4jName: 'T-Glucose-p',
        },
        {
            importColumnHeader: '6-Glucose Results|Resp.',
            neo4jName: '6-Glucose',
        },
        {
            importColumnHeader: '4-Glucose Results|Resp.',
            neo4jName: '4-Glucose',
        },
        {
            importColumnHeader: '3-Glucose Results|Resp.',
            neo4jName: '3-Glucose',
        },
        {
            importColumnHeader: '2-Glucose Results|Resp.',
            neo4jName: '2-Glucose',
        },
        {
            importColumnHeader: '2,X-Glucose a Results|Resp.',
            neo4jName: '2,X-Glucose a',
        },
        {
            importColumnHeader: 'X,X-Glucose (I) Results|Resp.',
            neo4jName: 'X,X-Glucose (I)',
        },
        {
            importColumnHeader: 'X,X-Glucose (II) Results|Resp.',
            neo4jName: 'X,X-Glucose (II)',
        },
        {
            importColumnHeader: '2,X-Glucose (I) Results|Resp.',
            neo4jName: '2,X-Glucose (I)',
        },
        {
            importColumnHeader: '3,4,6-Hexose (I) Results|Resp.',
            neo4jName: '3,4,6-Hexose (I)',
        },
        {
            importColumnHeader: '3,4,6-Hexose (II) Results|Resp.',
            neo4jName: '3,4,6-Hexose (II)',
        },
        {
            importColumnHeader: '3,4,6-Hexose (III) Results|Resp.',
            neo4jName: '3,4,6-Hexose (III)',
        },
        {
            importColumnHeader: '3,4,6-Hexose (IV) Results|Resp.',
            neo4jName: '3,4,6-Hexose (IV)',
        },
        {
            importColumnHeader: '3,4,6-Hexose (V) Results|Resp.',
            neo4jName: '3,4,6-Hexose (V)',
        },
        {
            importColumnHeader: '3,4,6-Glucose Results|Resp.',
            neo4jName: '3,4,6-Glucose',
        },
        {
            importColumnHeader: '3,6-Glucose Results|Resp.',
            neo4jName: '3,6-Glucose',
        },
        {
            importColumnHeader: 'T-Galactose Results|Resp.',
            neo4jName: 'T-Galactose',
        },
        {
            importColumnHeader: '6-Galactose Results|Resp.',
            neo4jName: '6-Galactose',
        },
        {
            importColumnHeader: '4-Galactose Results|Resp.',
            neo4jName: '4-Galactose',
        },
        {
            importColumnHeader: '3-Galactose Results|Resp.',
            neo4jName: '3-Galactose',
        },
        {
            importColumnHeader: '2-Galactose Results|Resp.',
            neo4jName: '2-Galactose',
        },
        {
            importColumnHeader: '3,4,6-Galactose Results|Resp.',
            neo4jName: '3,4,6-Galactose',
        },
        {
            importColumnHeader: 'T-Fructose Results|Resp.',
            neo4jName: 'T-Fructose',
        },
        {
            importColumnHeader: 'T-F-Xylose Results|Resp.',
            neo4jName: 'T-F-Xylose',
        },
        {
            importColumnHeader: 'T-P-Xylose Results|Resp.',
            neo4jName: 'T-P-Xylose',
        },
        {
            importColumnHeader: '4-P-Xylose Results|Resp.',
            neo4jName: '4-P-Xylose',
        },
        {
            importColumnHeader: '2-P-Xylose Results|Resp.',
            neo4jName: '2-P-Xylose',
        },
        {
            importColumnHeader: '3,4-P-Xylose Results|Resp.',
            neo4jName: '3,4-P-Xylose',
        },
        {
            importColumnHeader: 'T-F-Arabinose Results|Resp.',
            neo4jName: 'T-F-Arabinose',
        },
        {
            importColumnHeader: 'T-P-Arabinose Results|Resp.',
            neo4jName: 'T-P-Arabinose',
        },
        {
            importColumnHeader: '5-F-Arabinose Results|Resp.',
            neo4jName: '5-F-Arabinose',
        },
        {
            importColumnHeader: '3-P-Arabinose Results|Resp.',
            neo4jName: '3-P-Arabinose',
        },
        {
            importColumnHeader: '2-F-Arabinose Results|Resp.',
            neo4jName: '2-F-Arabinose',
        },
        {
            importColumnHeader: '3,4-F-Arabinose Results|Resp.',
            neo4jName: '3,4-F-Arabinose',
        },
        {
            importColumnHeader: '3,4-P-Arabinose Results|Resp.',
            neo4jName: '3,4-P-Arabinose',
        },
        {
            importColumnHeader: '2,3,5-Arabinose Results|Resp.',
            neo4jName: '2,3,5-Arabinose',
        },
        {
            importColumnHeader: '2,5-Arabinose Results|Resp.',
            neo4jName: '2,5-Arabinose',
        },
        { importColumnHeader: 'T-Fucose Results|Resp.', neo4jName: 'T-Fucose' },
        { importColumnHeader: '2-Fucose Results|Resp.', neo4jName: '2-Fucose' },
        {
            importColumnHeader: '3,4-Fucose Results|Resp.',
            neo4jName: '3,4-Fucose',
        },
        {
            importColumnHeader: 'T-Rhamnose Results|Resp.',
            neo4jName: 'T-Rhamnose',
        },
        {
            importColumnHeader: '2-Rhamnose Results|Resp.',
            neo4jName: '2-Rhamnose',
        },
        {
            importColumnHeader: '3,4-Rhamnose Results|Resp.',
            neo4jName: '3,4-Rhamnose',
        },
        {
            importColumnHeader: '2-Glucuronic Acid Results|Resp.',
            neo4jName: '2-Glucuronic Acid',
        },
        {
            importColumnHeader: '3,4-Glucuronic Acid Results|Resp.',
            neo4jName: '3,4-Glucuronic Acid',
        },
        {
            importColumnHeader: '3,4-Galacturonic Acid Results|Resp.',
            neo4jName: '3,4-Galacturonic Acid',
        },
        {
            importColumnHeader: 'T-Mannose Results|Resp.',
            neo4jName: 'T-Mannose',
        },
        {
            importColumnHeader: '6-Mannose Results|Resp.',
            neo4jName: '6-Mannose',
        },
        {
            importColumnHeader: '4-Mannose Results|Resp.',
            neo4jName: '4-Mannose',
        },
        {
            importColumnHeader: '3-Mannose Results|Resp.',
            neo4jName: '3-Mannose',
        },
        {
            importColumnHeader: '2-Mannose Results|Resp.',
            neo4jName: '2-Mannose',
        },
        {
            importColumnHeader: '3,6-Mannose Results|Resp.',
            neo4jName: '3,6-Mannose',
        },
        {
            importColumnHeader: '3,4,6-Mannose Results|Resp.',
            neo4jName: '3,4,6-Mannose',
        },
        { importColumnHeader: 'T-Allose Results|Resp.', neo4jName: 'T-Allose' },
        { importColumnHeader: '2-Allose Results|Resp.', neo4jName: '2-Allose' },
        {
            importColumnHeader: '3,4,6-Allose Results|Resp.',
            neo4jName: '3,4,6-Allose',
        },
        { importColumnHeader: 'T-Ribose Results|Resp.', neo4jName: 'T-Ribose' },
        { importColumnHeader: '2-Ribose Results|Resp.', neo4jName: '2-Ribose' },
        {
            importColumnHeader: '3,4-Ribose Results|Resp.',
            neo4jName: '3,4-Ribose',
        },
        { importColumnHeader: 'X-Hexose Results|Resp.', neo4jName: 'X-Hexose' },
        {
            importColumnHeader: 'X-Hexose (I) Results|Resp.',
            neo4jName: 'X-Hexose (I)',
        },
        {
            importColumnHeader: 'X-Hexose (II) Results|Resp.',
            neo4jName: 'X-Hexose (II)',
        },
        {
            importColumnHeader: 'X,X-Hexose (I) Results|Resp.',
            neo4jName: 'X,X-Hexose (I)',
        },
        {
            importColumnHeader: 'X,X-Hexose (II) Results|Resp.',
            neo4jName: 'X,X-Hexose (II)',
        },
        {
            importColumnHeader: 'X,X-Hexose (III) Results|Resp.',
            neo4jName: 'X,X-Hexose (III)',
        },
        {
            importColumnHeader: '2,X-Hexose (I) Results|Resp.',
            neo4jName: '2,X-Hexose (I)',
        },
        {
            importColumnHeader: '2,X-Hexose (II) Results|Resp.',
            neo4jName: '2,X-Hexose (II)',
        },
        {
            importColumnHeader: '2,X-Hexose (III) Results|Resp.',
            neo4jName: '2,X-Hexose (III)',
        },
        {
            importColumnHeader: '2,X-Hexose (IV) Results|Resp.',
            neo4jName: '2,X-Hexose (IV)',
        },
        {
            importColumnHeader: '2,X-Hexose (V) Results|Resp.',
            neo4jName: '2,X-Hexose (V)',
        },
        {
            importColumnHeader: '2,X,X-Hexose (I) Results|Resp.',
            neo4jName: '2,X,X-Hexose (I)',
        },
        {
            importColumnHeader: '2,X,X-Hexose (II) Results|Resp.',
            neo4jName: '2,X,X-Hexose (II)',
        },
        {
            importColumnHeader: '2,X,X-Hexose (III) Results|Resp.',
            neo4jName: '2,X,X-Hexose (III)',
        },
        {
            importColumnHeader: 'X-Pentose (I) Results|Resp.',
            neo4jName: 'X-Pentose (I)',
        },
        {
            importColumnHeader: 'X-Pentose (II) Results|Resp.',
            neo4jName: 'X-Pentose (II)',
        },
        {
            importColumnHeader: 'X-Pentose (III) Results|Resp.',
            neo4jName: 'X-Pentose (III)',
        },
        {
            importColumnHeader: '2,X-Pentose (I) Results|Resp.',
            neo4jName: '2,X-Pentose (I)',
        },
        {
            importColumnHeader: '2,X-Pentose (II) Results|Resp.',
            neo4jName: '2,X-Pentose (II)',
        },
        {
            importColumnHeader: '2,X-Pentose (III) Results|Resp.',
            neo4jName: '2,X-Pentose (III)',
        },
        {
            importColumnHeader: '2,X-Pentose (IV) Results|Resp.',
            neo4jName: '2,X-Pentose (IV)',
        },
        {
            importColumnHeader: '2,X-Pentose (V) Results|Resp.',
            neo4jName: '2,X-Pentose (V)',
        },
        {
            importColumnHeader: '2,X-Pentose (VI) Results|Resp.',
            neo4jName: '2,X-Pentose (VI)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (I) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (I)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (II) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (II)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (III) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (III)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (IV) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (IV)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (V) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (V)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (VI) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (VI)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (VII) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (VII)',
        },
        {
            importColumnHeader: 'X-Deoxyhexose (VIII) Results|Resp.',
            neo4jName: 'X-Deoxyhexose (VIII)',
        },
        {
            importColumnHeader: '2-Deoxyhexose Results|Resp.',
            neo4jName: '2-Deoxyhexose',
        },
        {
            importColumnHeader: '2,X-Deoxyhexose Results|Resp.',
            neo4jName: '2,X-Deoxyhexose',
        },
        {
            importColumnHeader: 'T-Hexuronic Acid (I) Results|Resp.',
            neo4jName: 'T-Hexuronic Acid (I)',
        },
        {
            importColumnHeader: 'T-Hexuronic Acid (II) Results|Resp.',
            neo4jName: 'T-Hexuronic Acid (II)',
        },
        {
            importColumnHeader: 'X-Hexuronic (I) Results|Resp.',
            neo4jName: 'X-Hexuronic (I)',
        },
        {
            importColumnHeader: 'X-Hexuronic (II) Results|Resp.',
            neo4jName: 'X-Hexuronic (II)',
        },
        {
            importColumnHeader: 'X-Hexuronic (III) Results|Resp.',
            neo4jName: 'X-Hexuronic (III)',
        },
        {
            importColumnHeader: 'X-Hexuronic (IV) Results|Resp.',
            neo4jName: 'X-Hexuronic (IV)',
        },
        {
            importColumnHeader: 'X-Hexuronic (V) Results|Resp.',
            neo4jName: 'X-Hexuronic (V)',
        },
        {
            importColumnHeader: '2-Pentose a Results|Resp.',
            neo4jName: '2-Pentose a',
        },
        {
            importColumnHeader: '2-Pentose b Results|Resp.',
            neo4jName: '2-Pentose b',
        },
        {
            importColumnHeader: '2-Pentose c Results|Resp.',
            neo4jName: '2-Pentose c',
        },
        {
            importColumnHeader: '2-Pentose d Results|Resp.',
            neo4jName: '2-Pentose d',
        },
        {
            importColumnHeader: '2-Pentose e Results|Resp.',
            neo4jName: '2-Pentose e',
        },
        {
            importColumnHeader: '2-Pentose f Results|Resp.',
            neo4jName: '2-Pentose f',
        },
        {
            importColumnHeader: '2-Pentose g Results|Resp.',
            neo4jName: '2-Pentose g',
        },
        {
            importColumnHeader: '2-Pentose h Results|Resp.',
            neo4jName: '2-Pentose h',
        },
        {
            importColumnHeader: '2-Pentose i Results|Resp.',
            neo4jName: '2-Pentose i',
        },
        {
            importColumnHeader: '2-Pentose j Results|Resp.',
            neo4jName: '2-Pentose j',
        },
        {
            importColumnHeader: '2-Pentose k Results|Resp.',
            neo4jName: '2-Pentose k',
        },
        {
            importColumnHeader: '2-Pentose l Results|Resp.',
            neo4jName: '2-Pentose l',
        },
        {
            importColumnHeader: '2-Pentose m Results|Resp.',
            neo4jName: '2-Pentose m',
        },
        {
            importColumnHeader: '2-Pentose n Results|Resp.',
            neo4jName: '2-Pentose n',
        },
        {
            importColumnHeader: '2-Pentose o Results|Resp.',
            neo4jName: '2-Pentose o',
        },
        {
            importColumnHeader: '2-Pentose p Results|Resp.',
            neo4jName: '2-Pentose p',
        },
        { importColumnHeader: '2-Xylose Results|Resp.', neo4jName: '2-Xylose' },
        {
            importColumnHeader: '2,3,4-Xylose Results|Resp.',
            neo4jName: '2,3,4-Xylose',
        },
        {
            importColumnHeader: '2,4-Xylose Results|Resp.',
            neo4jName: '2,4-Xylose',
        },
        {
            importColumnHeader: '2,X-Hexose a Results|Resp.',
            neo4jName: '2,X-Hexose a',
        },
        {
            importColumnHeader: '2,X-Hexose b Results|Resp.',
            neo4jName: '2,X-Hexose b',
        },
        {
            importColumnHeader: '2,X-Hexose c Results|Resp.',
            neo4jName: '2,X-Hexose c',
        },
        {
            importColumnHeader: '2,X-Hexose d Results|Resp.',
            neo4jName: '2,X-Hexose d',
        },
        {
            importColumnHeader: '2,X-Hexose e Results|Resp.',
            neo4jName: '2,X-Hexose e',
        },
        {
            importColumnHeader: '2,X-Hexose f Results|Resp.',
            neo4jName: '2,X-Hexose f',
        },
        {
            importColumnHeader: '2,X-Hexose g Results|Resp.',
            neo4jName: '2,X-Hexose g',
        },
        {
            importColumnHeader: '2,X-Hexose h Results|Resp.',
            neo4jName: '2,X-Hexose h',
        },
        {
            importColumnHeader: '2,X-Hexose i Results|Resp.',
            neo4jName: '2,X-Hexose i',
        },
        {
            importColumnHeader: '2,X-Hexose j Results|Resp.',
            neo4jName: '2,X-Hexose j',
        },
        {
            importColumnHeader: '2,X-Hexose k Results|Resp.',
            neo4jName: '2,X-Hexose k',
        },
        {
            importColumnHeader: '2,X-Hexose l Results|Resp.',
            neo4jName: '2,X-Hexose l',
        },
        {
            importColumnHeader: '2,X-Hexose m Results|Resp.',
            neo4jName: '2,X-Hexose m',
        },
        {
            importColumnHeader: '2,X-Hexose n Results|Resp.',
            neo4jName: '2,X-Hexose n',
        },
        {
            importColumnHeader: '2,X-Hexose o Results|Resp.',
            neo4jName: '2,X-Hexose o',
        },
        {
            importColumnHeader: '2,X-Hexose p Results|Resp.',
            neo4jName: '2,X-Hexose p',
        },
        {
            importColumnHeader: '2,X-Pentose a Results|Resp.',
            neo4jName: '2,X-Pentose a',
        },
        {
            importColumnHeader: '2,X-Pentose b Results|Resp.',
            neo4jName: '2,X-Pentose b',
        },
        {
            importColumnHeader: '2,X-Pentose c Results|Resp.',
            neo4jName: '2,X-Pentose c',
        },
        {
            importColumnHeader: '2,X-Pentose d Results|Resp.',
            neo4jName: '2,X-Pentose d',
        },
        {
            importColumnHeader: '2,X-Pentose e Results|Resp.',
            neo4jName: '2,X-Pentose e',
        },
        {
            importColumnHeader: '2,X-Pentose f Results|Resp.',
            neo4jName: '2,X-Pentose f',
        },
        {
            importColumnHeader: '2,X-Pentose g Results|Resp.',
            neo4jName: '2,X-Pentose g',
        },
        {
            importColumnHeader: '2,X-Pentose h Results|Resp.',
            neo4jName: '2,X-Pentose h',
        },
        {
            importColumnHeader: '2,X-Pentose i Results|Resp.',
            neo4jName: '2,X-Pentose i',
        },
        {
            importColumnHeader: '2,X-Pentose j Results|Resp.',
            neo4jName: '2,X-Pentose j',
        },
        {
            importColumnHeader: '2,X-Pentose k Results|Resp.',
            neo4jName: '2,X-Pentose k',
        },
        {
            importColumnHeader: '2,X-Pentose l Results|Resp.',
            neo4jName: '2,X-Pentose l',
        },
        {
            importColumnHeader: '2,X-Pentose m Results|Resp.',
            neo4jName: '2,X-Pentose m',
        },
        {
            importColumnHeader: '2,X-Pentose n Results|Resp.',
            neo4jName: '2,X-Pentose n',
        },
        {
            importColumnHeader: '2,X-Pentose o Results|Resp.',
            neo4jName: '2,X-Pentose o',
        },
        {
            importColumnHeader: '2,X-Pentose p Results|Resp.',
            neo4jName: '2,X-Pentose p',
        },
        {
            importColumnHeader: '2,X,X-Hexose a Results|Resp.',
            neo4jName: '2,X,X-Hexose a',
        },
        {
            importColumnHeader: '2,X,X-Hexose b Results|Resp.',
            neo4jName: '2,X,X-Hexose b',
        },
        {
            importColumnHeader: '2,X,X-Hexose c Results|Resp.',
            neo4jName: '2,X,X-Hexose c',
        },
        {
            importColumnHeader: '2,X,X-Hexose d Results|Resp.',
            neo4jName: '2,X,X-Hexose d',
        },
        {
            importColumnHeader: '2,X,X-Hexose e Results|Resp.',
            neo4jName: '2,X,X-Hexose e',
        },
        {
            importColumnHeader: '2,X,X-Hexose f Results|Resp.',
            neo4jName: '2,X,X-Hexose f',
        },
        {
            importColumnHeader: '2,X,X-Hexose g Results|Resp.',
            neo4jName: '2,X,X-Hexose g',
        },
        {
            importColumnHeader: '2,X,X-Hexose h Results|Resp.',
            neo4jName: '2,X,X-Hexose h',
        },
        {
            importColumnHeader: '2,X,X-Hexose i Results|Resp.',
            neo4jName: '2,X,X-Hexose i',
        },
        {
            importColumnHeader: '2,X,X-Hexose j Results|Resp.',
            neo4jName: '2,X,X-Hexose j',
        },
        {
            importColumnHeader: '2,X,X-Hexose k Results|Resp.',
            neo4jName: '2,X,X-Hexose k',
        },
        {
            importColumnHeader: '2,X,X-Hexose l Results|Resp.',
            neo4jName: '2,X,X-Hexose l',
        },
        {
            importColumnHeader: '2,X,X-Hexose m Results|Resp.',
            neo4jName: '2,X,X-Hexose m',
        },
        {
            importColumnHeader: '2,X,X-Hexose n Results|Resp.',
            neo4jName: '2,X,X-Hexose n',
        },
        {
            importColumnHeader: '2,X,X-Hexose o Results|Resp.',
            neo4jName: '2,X,X-Hexose o',
        },
        {
            importColumnHeader: '2,X,X-Hexose p Results|Resp.',
            neo4jName: '2,X,X-Hexose p',
        },
        {
            importColumnHeader: '2,X,X-Rhamnose Results|Resp.',
            neo4jName: '2,X,X-Rhamnose',
        },
        {
            importColumnHeader: '3,4-Galactose Results|Resp.',
            neo4jName: '3,4-Galactose',
        },
        {
            importColumnHeader: '3,4-Galactose/3,6-Mannose Results|Resp.',
            neo4jName: '3,4-Galactose/3,6-Mannose',
        },
        {
            importColumnHeader: '3,4-Xylose Results|Resp.',
            neo4jName: '3,4-Xylose',
        },
        {
            importColumnHeader: '3,4-Xylose/3,5-Arabinose Results|Resp.',
            neo4jName: '3,4-Xylose/3,5-Arabinose',
        },
        {
            importColumnHeader: '3,5-Arabinose Results|Resp.',
            neo4jName: '3,5-Arabinose',
        },
        {
            importColumnHeader: '3,6-Galactose Results|Resp.',
            neo4jName: '3,6-Galactose',
        },
        {
            importColumnHeader: '3/4-Mannose Results|Resp.',
            neo4jName: '3/4-Mannose',
        },
        { importColumnHeader: '4-GlcA Results|Resp.', neo4jName: '4-GlcA' },
        {
            importColumnHeader: '4-Rhamnose Results|Resp.',
            neo4jName: '4-Rhamnose',
        },
        { importColumnHeader: '4-Xylose Results|Resp.', neo4jName: '4-Xylose' },
        {
            importColumnHeader: '4-Xylose/5-Arabinose Results|Resp.',
            neo4jName: '4-Xylose/5-Arabinose',
        },
        {
            importColumnHeader: '4,6-Glucose Results|Resp.',
            neo4jName: '4,6-Glucose',
        },
        {
            importColumnHeader: '4,6-Mannose Results|Resp.',
            neo4jName: '4,6-Mannose',
        },
        {
            importColumnHeader: '5-Arabinose Results|Resp.',
            neo4jName: '5-Arabinose',
        },
        {
            importColumnHeader: 'T-Arabinose-f Results|Resp.',
            neo4jName: 'T-Arabinose-f',
        },
        {
            importColumnHeader: 'T-Arabinose-f/4-Rhamnose Results|Resp.',
            neo4jName: 'T-Arabinose-f/4-Rhamnose',
        },
        {
            importColumnHeader: 'T-Arabinose-p Results|Resp.',
            neo4jName: 'T-Arabinose-p',
        },
        {
            importColumnHeader: 'T-Fucose a Results|Resp.',
            neo4jName: 'T-Fucose a',
        },
        {
            importColumnHeader: 'T-Fucose a/T-Rhamnose Results|Resp.',
            neo4jName: 'T-Fucose a/T-Rhamnose',
        },
        {
            importColumnHeader: 'T-Fucose b Results|Resp.',
            neo4jName: 'T-Fucose b',
        },
        { importColumnHeader: 'T-GalA a Results|Resp.', neo4jName: 'T-GalA a' },
        { importColumnHeader: 'T-GalA b Results|Resp.', neo4jName: 'T-GalA b' },
        {
            importColumnHeader: 'T-Galactose-f Results|Resp.',
            neo4jName: 'T-Galactose-f',
        },
        {
            importColumnHeader: 'T-Galactose-p Results|Resp.',
            neo4jName: 'T-Galactose-p',
        },
        { importColumnHeader: 'T-GlcA Results|Resp.', neo4jName: 'T-GlcA' },
        {
            importColumnHeader: 'T-Glucose/T-Mannose Results|Resp.',
            neo4jName: 'T-Glucose/T-Mannose',
        },
        {
            importColumnHeader: 'T-Ribose a Results|Resp.',
            neo4jName: 'T-Ribose a',
        },
        {
            importColumnHeader: 'T-Ribose a/T-Arabinose-p Results|Resp.',
            neo4jName: 'T-Ribose a/T-Arabinose-p',
        },
        {
            importColumnHeader: 'T-Ribose b Results|Resp.',
            neo4jName: 'T-Ribose b',
        },
        { importColumnHeader: 'T-Xylose Results|Resp.', neo4jName: 'T-Xylose' },
        {
            importColumnHeader: 'X-Fucose a Results|Resp.',
            neo4jName: 'X-Fucose a',
        },
        {
            importColumnHeader: 'X-Hexose a Results|Resp.',
            neo4jName: 'X-Hexose a',
        },
        {
            importColumnHeader: 'X-Hexose b Results|Resp.',
            neo4jName: 'X-Hexose b',
        },
        {
            importColumnHeader: 'X-Hexose c Results|Resp.',
            neo4jName: 'X-Hexose c',
        },
        {
            importColumnHeader: 'X-Hexose d Results|Resp.',
            neo4jName: 'X-Hexose d',
        },
        {
            importColumnHeader: 'X-Hexose e Results|Resp.',
            neo4jName: 'X-Hexose e',
        },
        {
            importColumnHeader: 'X-Hexose f Results|Resp.',
            neo4jName: 'X-Hexose f',
        },
        {
            importColumnHeader: 'X-Hexose g Results|Resp.',
            neo4jName: 'X-Hexose g',
        },
        {
            importColumnHeader: 'X-Hexose h Results|Resp.',
            neo4jName: 'X-Hexose h',
        },
        {
            importColumnHeader: 'X-Hexose i Results|Resp.',
            neo4jName: 'X-Hexose i',
        },
        {
            importColumnHeader: 'X-Hexose j Results|Resp.',
            neo4jName: 'X-Hexose j',
        },
        {
            importColumnHeader: 'X-Hexose k Results|Resp.',
            neo4jName: 'X-Hexose k',
        },
        {
            importColumnHeader: 'X-Hexose l Results|Resp.',
            neo4jName: 'X-Hexose l',
        },
        {
            importColumnHeader: 'X-Hexose m Results|Resp.',
            neo4jName: 'X-Hexose m',
        },
        {
            importColumnHeader: 'X-Hexose n Results|Resp.',
            neo4jName: 'X-Hexose n',
        },
        {
            importColumnHeader: 'X-Hexose o Results|Resp.',
            neo4jName: 'X-Hexose o',
        },
        {
            importColumnHeader: 'X-Hexose p Results|Resp.',
            neo4jName: 'X-Hexose p',
        },
        {
            importColumnHeader: 'X-Pentose a Results|Resp.',
            neo4jName: 'X-Pentose a',
        },
        {
            importColumnHeader: 'X-Pentose b Results|Resp.',
            neo4jName: 'X-Pentose b',
        },
        {
            importColumnHeader: 'X-Pentose c Results|Resp.',
            neo4jName: 'X-Pentose c',
        },
        {
            importColumnHeader: 'X-Pentose d Results|Resp.',
            neo4jName: 'X-Pentose d',
        },
        {
            importColumnHeader: 'X-Pentose e Results|Resp.',
            neo4jName: 'X-Pentose e',
        },
        {
            importColumnHeader: 'X-Pentose f Results|Resp.',
            neo4jName: 'X-Pentose f',
        },
        {
            importColumnHeader: 'X-Pentose g Results|Resp.',
            neo4jName: 'X-Pentose g',
        },
        {
            importColumnHeader: 'X-Pentose h Results|Resp.',
            neo4jName: 'X-Pentose h',
        },
        {
            importColumnHeader: 'X-Pentose i Results|Resp.',
            neo4jName: 'X-Pentose i',
        },
        {
            importColumnHeader: 'X-Pentose j Results|Resp.',
            neo4jName: 'X-Pentose j',
        },
        {
            importColumnHeader: 'X-Pentose k Results|Resp.',
            neo4jName: 'X-Pentose k',
        },
        {
            importColumnHeader: 'X-Pentose l Results|Resp.',
            neo4jName: 'X-Pentose l',
        },
        {
            importColumnHeader: 'X-Pentose m Results|Resp.',
            neo4jName: 'X-Pentose m',
        },
        {
            importColumnHeader: 'X-Pentose n Results|Resp.',
            neo4jName: 'X-Pentose n',
        },
        {
            importColumnHeader: 'X-Pentose o Results|Resp.',
            neo4jName: 'X-Pentose o',
        },
        {
            importColumnHeader: 'X-Pentose p Results|Resp.',
            neo4jName: 'X-Pentose p',
        },
        {
            importColumnHeader: 'X,X-Hexose a Results|Resp.',
            neo4jName: 'X,X-Hexose a',
        },
        {
            importColumnHeader: 'X,X-Hexose b Results|Resp.',
            neo4jName: 'X,X-Hexose b',
        },
        {
            importColumnHeader: 'X,X-Hexose c Results|Resp.',
            neo4jName: 'X,X-Hexose c',
        },
        {
            importColumnHeader: 'X,X-Hexose d Results|Resp.',
            neo4jName: 'X,X-Hexose d',
        },
        {
            importColumnHeader: 'X,X-Hexose e Results|Resp.',
            neo4jName: 'X,X-Hexose e',
        },
        {
            importColumnHeader: 'X,X-Hexose f Results|Resp.',
            neo4jName: 'X,X-Hexose f',
        },
        {
            importColumnHeader: 'X,X-Hexose g Results|Resp.',
            neo4jName: 'X,X-Hexose g',
        },
        {
            importColumnHeader: 'X,X-Hexose h Results|Resp.',
            neo4jName: 'X,X-Hexose h',
        },
        {
            importColumnHeader: 'X,X-Hexose i Results|Resp.',
            neo4jName: 'X,X-Hexose i',
        },
        {
            importColumnHeader: 'X,X-Hexose j Results|Resp.',
            neo4jName: 'X,X-Hexose j',
        },
        {
            importColumnHeader: 'X,X-Hexose k Results|Resp.',
            neo4jName: 'X,X-Hexose k',
        },
        {
            importColumnHeader: 'X,X-Hexose l Results|Resp.',
            neo4jName: 'X,X-Hexose l',
        },
        {
            importColumnHeader: 'X,X-Hexose m Results|Resp.',
            neo4jName: 'X,X-Hexose m',
        },
        {
            importColumnHeader: 'X,X-Hexose n Results|Resp.',
            neo4jName: 'X,X-Hexose n',
        },
        {
            importColumnHeader: 'X,X-Hexose o Results|Resp.',
            neo4jName: 'X,X-Hexose o',
        },
        {
            importColumnHeader: 'X,X-Hexose p Results|Resp.',
            neo4jName: 'X,X-Hexose p',
        },
        {
            importColumnHeader: 'X,X-Pentose a Results|Resp.',
            neo4jName: 'X,X-Pentose a',
        },
        {
            importColumnHeader: 'X,X-Pentose b Results|Resp.',
            neo4jName: 'X,X-Pentose b',
        },
        {
            importColumnHeader: 'X,X-Pentose c Results|Resp.',
            neo4jName: 'X,X-Pentose c',
        },
        {
            importColumnHeader: 'X,X-Pentose d Results|Resp.',
            neo4jName: 'X,X-Pentose d',
        },
        {
            importColumnHeader: 'X,X-Pentose e Results|Resp.',
            neo4jName: 'X,X-Pentose e',
        },
        {
            importColumnHeader: 'X,X-Pentose f Results|Resp.',
            neo4jName: 'X,X-Pentose f',
        },
        {
            importColumnHeader: 'X,X-Pentose g Results|Resp.',
            neo4jName: 'X,X-Pentose g',
        },
        {
            importColumnHeader: 'X,X-Pentose h Results|Resp.',
            neo4jName: 'X,X-Pentose h',
        },
        {
            importColumnHeader: 'X,X-Pentose i Results|Resp.',
            neo4jName: 'X,X-Pentose i',
        },
        {
            importColumnHeader: 'X,X-Pentose j Results|Resp.',
            neo4jName: 'X,X-Pentose j',
        },
        {
            importColumnHeader: 'X,X-Pentose k Results|Resp.',
            neo4jName: 'X,X-Pentose k',
        },
        {
            importColumnHeader: 'X,X-Pentose l Results|Resp.',
            neo4jName: 'X,X-Pentose l',
        },
        {
            importColumnHeader: 'X,X-Pentose m Results|Resp.',
            neo4jName: 'X,X-Pentose m',
        },
        {
            importColumnHeader: 'X,X-Pentose n Results|Resp.',
            neo4jName: 'X,X-Pentose n',
        },
        {
            importColumnHeader: 'X,X-Pentose o Results|Resp.',
            neo4jName: 'X,X-Pentose o',
        },
        {
            importColumnHeader: 'X,X-Pentose p Results|Resp.',
            neo4jName: 'X,X-Pentose p',
        },
        {
            importColumnHeader: 'X,X,X-Hexose a Results|Resp.',
            neo4jName: 'X,X,X-Hexose a',
        },
        {
            importColumnHeader: 'X,X,X-Hexose b Results|Resp.',
            neo4jName: 'X,X,X-Hexose b',
        },
        {
            importColumnHeader: 'X,X,X-Hexose c Results|Resp.',
            neo4jName: 'X,X,X-Hexose c',
        },
        {
            importColumnHeader: 'X,X,X-Hexose d Results|Resp.',
            neo4jName: 'X,X,X-Hexose d',
        },
        {
            importColumnHeader: 'X,X,X-Hexose e Results|Resp.',
            neo4jName: 'X,X,X-Hexose e',
        },
        {
            importColumnHeader: 'X,X,X-Hexose f Results|Resp.',
            neo4jName: 'X,X,X-Hexose f',
        },
        {
            importColumnHeader: 'X,X,X-Hexose g Results|Resp.',
            neo4jName: 'X,X,X-Hexose g',
        },
        {
            importColumnHeader: 'X,X,X-Hexose h Results|Resp.',
            neo4jName: 'X,X,X-Hexose h',
        },
        {
            importColumnHeader: 'X,X,X-Hexose i Results|Resp.',
            neo4jName: 'X,X,X-Hexose i',
        },
        {
            importColumnHeader: 'X,X,X-Hexose j Results|Resp.',
            neo4jName: 'X,X,X-Hexose j',
        },
        {
            importColumnHeader: 'X,X,X-Hexose k Results|Resp.',
            neo4jName: 'X,X,X-Hexose k',
        },
        {
            importColumnHeader: 'X,X,X-Hexose l Results|Resp.',
            neo4jName: 'X,X,X-Hexose l',
        },
        {
            importColumnHeader: 'X,X,X-Hexose m Results|Resp.',
            neo4jName: 'X,X,X-Hexose m',
        },
        {
            importColumnHeader: 'X,X,X-Hexose n Results|Resp.',
            neo4jName: 'X,X,X-Hexose n',
        },
        {
            importColumnHeader: 'X,X,X-Hexose o Results|Resp.',
            neo4jName: 'X,X,X-Hexose o',
        },
        {
            importColumnHeader: 'X,X,X-Hexose p Results|Resp.',
            neo4jName: 'X,X,X-Hexose p',
        },
        {
            importColumnHeader: 'X,X,X-Pentose a Results|Resp.',
            neo4jName: 'X,X,X-Pentose a',
        },
        {
            importColumnHeader: 'X,X,X-Pentose b Results|Resp.',
            neo4jName: 'X,X,X-Pentose b',
        },
        {
            importColumnHeader: 'X,X,X-Pentose c Results|Resp.',
            neo4jName: 'X,X,X-Pentose c',
        },
        {
            importColumnHeader: 'X,X,X-Pentose d Results|Resp.',
            neo4jName: 'X,X,X-Pentose d',
        },
        {
            importColumnHeader: 'X,X,X-Pentose e Results|Resp.',
            neo4jName: 'X,X,X-Pentose e',
        },
        {
            importColumnHeader: 'X,X,X-Pentose f Results|Resp.',
            neo4jName: 'X,X,X-Pentose f',
        },
        {
            importColumnHeader: 'X,X,X-Pentose g Results|Resp.',
            neo4jName: 'X,X,X-Pentose g',
        },
        {
            importColumnHeader: 'X,X,X-Pentose h Results|Resp.',
            neo4jName: 'X,X,X-Pentose h',
        },
        {
            importColumnHeader: 'X,X,X-Pentose i Results|Resp.',
            neo4jName: 'X,X,X-Pentose i',
        },
        {
            importColumnHeader: 'X,X,X-Pentose j Results|Resp.',
            neo4jName: 'X,X,X-Pentose j',
        },
        {
            importColumnHeader: 'X,X,X-Pentose k Results|Resp.',
            neo4jName: 'X,X,X-Pentose k',
        },
        {
            importColumnHeader: 'X,X,X-Pentose l Results|Resp.',
            neo4jName: 'X,X,X-Pentose l',
        },
        {
            importColumnHeader: 'X,X,X-Pentose m Results|Resp.',
            neo4jName: 'X,X,X-Pentose m',
        },
        {
            importColumnHeader: 'X,X,X-Pentose n Results|Resp.',
            neo4jName: 'X,X,X-Pentose n',
        },
        {
            importColumnHeader: 'X,X,X-Pentose o Results|Resp.',
            neo4jName: 'X,X,X-Pentose o',
        },
        {
            importColumnHeader: 'X,X,X-Pentose p Results|Resp.',
            neo4jName: 'X,X,X-Pentose p',
        },
    ];
}
