import {
    DataImportFileReader,
    DataImportFileReaderResults,
} from './data-import-file-reader';
import { Observable, of } from 'rxjs';

export class DataImportFileReaderFile extends DataImportFileReader {
    readFile(file: File): Observable<DataImportFileReaderResults> {
        return of({
            data: [],
            errors: [],
        });
    }
}
