<ng-container *ngIf="showSpinner; else loaded">
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <mat-spinner diameter="125"></mat-spinner>
        </div>
    </div>
</ng-container>

<ng-template #loaded>
    <div class="container mt-3 mb-1">
        <div class="d-flex align-items-center float-md-right">
            <bcdbio-search-term-entry
                class="pr-3"
                [placeholderLabel]="'Filter By Name or ID'"
            ></bcdbio-search-term-entry>
            <button
                class="btn btn-outline-primary"
                type="button"
                (click)="createNewStudy()"
            >
                Create New Study
            </button>
        </div>
        <div class="d-flex">
            <h2>Study List</h2>
            <a
                class="nav-link"
                href="https://bcdbio.slite.com/api/s/uymP8TioVaEiHf/Studies"
                target="_blank"
            >
                <i class="bi-box-arrow-up-right"></i>
            </a>
        </div>
        <table
            matSort
            class="table table-hover"
            (matSortChange)="changeStudiesSort($event)"
        >
            <thead class="thead-light">
                <tr>
                    <th mat-sort-header="studyName">Study Name</th>
                    <th mat-sort-header="studyId">Study ID</th>
                    <th mat-sort-header="studyPeriod">Study Period</th>
                    <th mat-sort-header="numCrosses">Crosses</th>
                    <th mat-sort-header="numTrays">Trays</th>
                    <th mat-sort-header="objective">Objective</th>
                    <th mat-sort-header="notes">Notes</th>
                </tr>
            </thead>
            <ng-container *ngIf="studies$; else noResultsBlock">
                <ng-container *ngIf="sortedStudies?.length > 0">
                    <tr *ngFor="let s of sortedStudies">
                        <td>
                            <a [routerLink]="['/study', s.studyId]">
                                {{ s.studyName }}
                            </a>
                        </td>
                        <td>
                            {{ s.studyId }}
                        </td>
                        <td>
                            {{ s.studyPeriod }}
                        </td>
                        <td>
                            {{ s.numCrosses }}
                        </td>
                        <td>
                            {{ s.numTrays }}
                        </td>
                        <td>
                            {{ s.objective }}
                        </td>
                        <td>
                            {{ s.notes }}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <ng-template #noResultsBlock>
                <tr>
                    <td colspan="4">No studies created.</td>
                </tr>
            </ng-template>
        </table>
    </div>
</ng-template>
