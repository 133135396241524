import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
    Sample,
    ExportData,
    CsvData,
    ObservedDataset,
    MonoDataset,
    LinkageDataset,
    DISPLAYED_SOURCE_METADATA,
    DISPLAYED_SINGLE_STRAIN_METADATA,
    DISPLAYED_FECAL_SAMPLE_METADATA,
} from '@bcdbio/data';
import { Papa } from 'ngx-papaparse';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import {
    Metadata,
    ProcessMetadataValue,
    Sample as SampleGQL,
} from '@bcdbio/udb-graphql';
import { sample, startCase } from 'lodash';

@Component({
    selector: 'bcdbio-export-samples',
    templateUrl: './export-samples.component.html',
    styleUrls: ['./export-samples.component.scss'],
})
export class ExportSamplesComponent implements OnInit {
    @Input() samplesToExport: Array<Sample>;
    @Input() buttonLabel = 'Export data';
    @Input() primaryButtonStyle = true;
    @Input() includeMetadata = false;
    @Input() studyId: string;
    @Output() clickExport = new EventEmitter<boolean>();

    SAMPLE_METADATA_EXPORT_FIELDS: Array<string> = ['sample id', 'date'];
    LINKAGE_METADATA_EXPORT_FIELDS: Array<string> = [
        'sample id',
        'date',
        'sop version',
    ];
    MONO_METADATA_EXPORT_FIELDS: Array<string> = [
        'sample id',
        'date',
        'method',
        'sop version',
    ];
    INTERACTION_METADATA_EXPORT_FIELDS: Array<string> = [
        'cell_reference',
        'cross id',
        'bug id',
        'glycan id',
        'Study id',
    ];

    constructor(private papa: Papa) {}

    ngOnInit(): void {}

    exportSamples(): void {
        if (!this.samplesToExport || this.samplesToExport?.length === 0) {
            return;
        }

        const csvDataFiles: ExportData = {};

        this.samplesToExport.forEach((sample) => {
            Object.keys(sample.observedData).forEach((obsType) => {
                this.createObsTypeInExportsIfDoesNotExist(
                    obsType,
                    csvDataFiles
                );
                sample.observedData[obsType].forEach((dataset) => {
                    this.updateCSVFields(
                        csvDataFiles[obsType].fields,
                        dataset.defaultColumnOrder
                    );
                    this.addObsDatasetToCsvData(
                        csvDataFiles[obsType],
                        dataset,
                        sample.id
                    );
                });
            });
        });

        if (this.includeMetadata) {
            this.addMetadataToCsvData(csvDataFiles, this.samplesToExport);
        }

        this.downloadExportData(csvDataFiles);
        this.clickExport.emit(true);
    }

    addMetadataToCsvData(csvDataFiles: ExportData, samples) {
        csvDataFiles['Interaction_Metadata'] = {
            fields: this.INTERACTION_METADATA_EXPORT_FIELDS.map((field) =>
                startCase(field)
            ),
            data: [],
        };

        const startingFields = ['Sample Id'];
        csvDataFiles['Native_Material_Metadata'] = {
            fields: startingFields.concat(
                DISPLAYED_SOURCE_METADATA.map((field) => startCase(field))
            ),
            data: [],
        };
        csvDataFiles['Single_Strain_Metadata'] = {
            fields: startingFields.concat(
                DISPLAYED_SINGLE_STRAIN_METADATA.map((field) =>
                    startCase(field)
                )
            ),
            data: [],
        };
        csvDataFiles['Fecal_Source_Metadata'] = {
            fields: startingFields.concat(
                DISPLAYED_FECAL_SAMPLE_METADATA.map((field) => startCase(field))
            ),
            data: [],
        };

        samples.forEach((sample: SampleGQL) => {
            const bug = Sample.getBiologicalParentFromSample(sample);
            const glycan = Sample.getChemicalParentFromSample(sample);
            const s2 = sample as unknown as Sample;
            const parentProcess = s2.parentProcess;
            if (parentProcess && parentProcess.type === 'Interaction') {
                const processMd = Object.entries(
                    s2.parentProcess.metadata.Interaction
                );
                const trayNameEntry = processMd.find(
                    (arr) => arr[0] === 'Tray Name'
                );
                const studyId = trayNameEntry[1] as string;
                const cellEntries = processMd.filter(
                    (arr) => arr[1] === 'growth'
                );
                cellEntries.forEach((cellEntry) => {
                    const matchingStrings = cellEntry[0].match(/\[(.*?)\]/);
                    const cell: string = matchingStrings[1];

                    csvDataFiles['Interaction_Metadata'].data.push([
                        cell,
                        s2.id,
                        bug.bcdId,
                        glycan.bcdId,
                        studyId,
                    ]);
                });
            } else if (
                sample.outputByProcess?.processType?.name === 'Interaction'
            ) {
                const cellsMetadata = sample.outputByProcess.metadata.filter(
                    (md: ProcessMetadataValue) => {
                        return md.metadata.name === 'Cells';
                    }
                );
                const trayMetadata = sample.outputByProcess.metadata.find(
                    (md: ProcessMetadataValue) => {
                        return md.metadata.name === 'Tray Name';
                    }
                );
                const trayName = trayMetadata.value;
                const listOfGrowthCells = cellsMetadata
                    .filter((md) => md.value === 'growth')
                    .map(
                        (metadata: ProcessMetadataValue) => metadata.replicate
                    );

                listOfGrowthCells.forEach((cell) => {
                    csvDataFiles['Interaction_Metadata'].data.push([
                        cell,
                        sample.bcdId,
                        bug.bcdId,
                        glycan.bcdId,
                        this.studyId,
                    ]);
                });
            } else {
                const md = s2.metadata as unknown as string;
                const row = [s2.id];
                switch (s2.type) {
                    case 'Source':
                        DISPLAYED_SOURCE_METADATA.forEach((field) => {
                            row.push(md[field]);
                        });
                        csvDataFiles['Native_Material_Metadata'].data.push(row);
                        break;
                    case 'Single Strain':
                        DISPLAYED_SINGLE_STRAIN_METADATA.forEach((field) => {
                            row.push(md[field]);
                        });
                        csvDataFiles['Single_Strain_Metadata'].data.push(row);
                        break;
                    case 'Fecal Source':
                        DISPLAYED_FECAL_SAMPLE_METADATA.forEach((field) => {
                            row.push(md[field]);
                        });
                        csvDataFiles['Fecal_Source_Metadata'].data.push(row);
                        break;
                }
            }
        });

        if (csvDataFiles['Interaction_Metadata'].data.length === 0) {
            delete csvDataFiles['Interaction_Metadata'];
        }
        if (csvDataFiles['Native_Material_Metadata'].data.length === 0) {
            delete csvDataFiles['Native_Material_Metadata'];
        }
        if (csvDataFiles['Single_Strain_Metadata'].data.length === 0) {
            delete csvDataFiles['Single_Strain_Metadata'];
        }
        if (csvDataFiles['Fecal_Source_Metadata'].data.length === 0) {
            delete csvDataFiles['Fecal_Source_Metadata'];
        }
    }

    createObsTypeInExportsIfDoesNotExist(
        obsType: string,
        csvDataFiles: ExportData
    ): void {
        if (!csvDataFiles[obsType]) {
            var sample_fields = (sample_fields =
                this.SAMPLE_METADATA_EXPORT_FIELDS);

            if (['Mono', 'Free Mono'].includes(obsType)) {
                sample_fields = this.MONO_METADATA_EXPORT_FIELDS;
            } else if (obsType == 'Linkage') {
                sample_fields = this.LINKAGE_METADATA_EXPORT_FIELDS;
            }

            csvDataFiles[obsType] = {
                fields: Object.assign(
                    [],
                    sample_fields.map((field) => startCase(field))
                ),
                data: [],
            };
        }
    }

    downloadExportData(csvDataFiles: ExportData): void {
        const zip = new JSZip();
        const downloadFolder = zip.folder('data');

        this.zipExportData(csvDataFiles, downloadFolder);
        zip.generateAsync({ type: 'blob' }).then((content) =>
            FileSaver.saveAs(content, 'exported_samples.zip')
        );
    }

    updateCSVFields(
        currentFields: string[],
        possibleNewFields: string[]
    ): void {
        possibleNewFields.forEach((newField) => {
            if (!currentFields.includes(newField)) {
                currentFields.push(newField);
            }
        });
    }

    addObsDatasetToCsvData(
        csvData: CsvData,
        observedDataset: ObservedDataset,
        sampleId: string
    ): void {
        var sampleMetadataExportFieldLength =
            this.SAMPLE_METADATA_EXPORT_FIELDS.length;

        if (observedDataset instanceof MonoDataset) {
            sampleMetadataExportFieldLength += 2;
        } else if (observedDataset instanceof LinkageDataset) {
            sampleMetadataExportFieldLength += 1;
        }

        observedDataset.data.forEach((dataRow, dataRowIndex) => {
            // Added dataRowIndex for handling SOP Version in Mono and Linkage Data. These are
            // stored in ObservedDataset.sopVersions.
            const csvDataRow = this.makeBlankCsvDataRow(
                sampleId,
                observedDataset,
                dataRowIndex
            );

            csvData.fields.forEach((field, index) => {
                if (index >= sampleMetadataExportFieldLength) {
                    csvDataRow.push(dataRow[field]);
                }
            });

            csvData.data.push(csvDataRow);
        });
    }

    makeBlankCsvDataRow(
        sampleId: string,
        observedDataset: ObservedDataset,
        dataRowIndex: number
    ): Array<string | number> {
        var csvDataRow: (number | string)[] = [sampleId, 'date n/a'];

        if (observedDataset instanceof MonoDataset) {
            csvDataRow = [
                sampleId,
                'date n/a',
                observedDataset.monoMethodTypes[dataRowIndex],
                observedDataset.sopVersions[dataRowIndex],
            ];
        } else if (observedDataset instanceof LinkageDataset) {
            csvDataRow = [
                sampleId,
                'date n/a',
                observedDataset.sopVersions[dataRowIndex],
            ];
        }

        const obsDate = observedDataset.observationDate;
        const dateWrapper = moment.utc(obsDate).startOf('day');
        if (dateWrapper.isValid()) {
            csvDataRow[1] = dateWrapper.format('M/D/YY');
        }

        return csvDataRow;
    }

    zipExportData(exportData: ExportData, zipContainer: JSZip): void {
        if (Object.keys(exportData).length > 0) {
            Object.keys(exportData).forEach((obsType) => {
                const filename = obsType + '.csv';
                const csvString = this.papa.unparse(exportData[obsType]);
                zipContainer.file(filename, csvString);
            });
        }
    }
}
