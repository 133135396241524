import { DataImportPersist } from './data-import-persist';
import { MapperData } from '../mapping/mapper-data';
import { SaveResult } from '../sample-save-result';
import { Observable } from 'rxjs';
import { DataImportType } from '@bcdbio/data';

export class DataImportPersistFile extends DataImportPersist {
    save(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return this.sampleApiService.saveFileTypeData(
            data,
            filePath,
            dataFileType
        );
    }
}
