<ng-container *ngIf="!createNew && !projects$ && showSpinner">
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <mat-spinner diameter="125"></mat-spinner>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="{ currentProject: (currentProject$ | async) } as data">
    <bcdbio-project-list
        *ngIf="!createNew && !data.currentProject"
        [projects]="projects$ | async"
        (clickCreateNew)="createNewProject()"
    >
    </bcdbio-project-list>
    <div class="container" *ngIf="createNew || data?.currentProject as project">
        <div class="row top-buffer">
            <div class="col-md-6">
                <button
                    class="btn btn-outline-danger float-md-left"
                    type="button"
                    (click)="goToProjectList()"
                >
                    Back To Projects
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn btn-outline-primary float-md-right"
                    type="button"
                    (click)="exportProject(project)"
                >
                    Export Project
                </button>
            </div>
        </div>

        <form [formGroup]="projectForm">
            <div class="top-buffer bcd-info-panel">
                <div class="row">
                    <div class="col-sm-6">
                        <h3 *ngIf="projectForm.disabled">
                            {{ projectForm.get('projectName').value }}
                        </h3>
                        <ng-container *ngIf="projectForm.enabled">
                            <label for="projectName">Project Name: </label>
                            <input
                                formControlName="projectName"
                                class="form-control"
                                type="text"
                                id="projectName"
                            />
                        </ng-container>
                    </div>

                    <!-- Modal -->
                    <ng-template #deleteConfirmation>
                        <div class="modal-header">
                            <h4 class="modal-title">
                                Are you sure you want to delete?
                            </h4>
                            <button
                                type="button"
                                class="close pull-right"
                                aria-label="Close"
                                (click)="modalRef.hide()"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <button
                                type="button"
                                class="btn float-right ml-1 btn-danger"
                                (click)="deleteProject()"
                            >
                                Yes, Delete
                            </button>
                            <button
                                type="button"
                                class="btn btn-outline-primary float-right"
                                (click)="modalRef.hide()"
                            >
                                Cancel
                            </button>
                        </div>
                    </ng-template>

                    <div class="col-sm-6">
                        <button
                            *ngIf="
                                projectForm.get('projectId').value &&
                                projectForm.disabled
                            "
                            class="btn btn-danger float-md-right ml-1"
                            type="button"
                            (click)="openDeleteConfirmation(deleteConfirmation)"
                        >
                            Delete
                        </button>
                        <button
                            *ngIf="
                                projectForm.get('projectId').value &&
                                projectForm.enabled
                            "
                            class="btn btn-outline-secondary float-md-right ml-1"
                            type="button"
                            (click)="cancelEdits()"
                        >
                            Cancel
                        </button>
                        <button
                            *ngIf="projectForm.disabled"
                            class="btn btn-outline-primary float-md-right"
                            type="button"
                            (click)="projectForm.enable()"
                        >
                            Edit
                        </button>
                        <button
                            *ngIf="projectForm.enabled"
                            class="btn btn-primary float-md-right"
                            type="button"
                            (click)="saveOrCreateProject()"
                        >
                            Save
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    <div class="d-flex">
                        <div class="mr-2">
                            <label for="startDate">Start Date</label>
                            <input
                                class="form-control"
                                formControlName="startDate"
                                bsDatepicker
                                [bsConfig]="{
                                    dateInputFormat: 'l',
                                    showWeekNumbers: false
                                }"
                                id="startDate"
                            />
                        </div>
                        <div class="mr-2">
                            <label for="startDate">Completion Date</label>
                            <input
                                class="form-control"
                                formControlName="completionDate"
                                bsDatepicker
                                [bsConfig]="{
                                    dateInputFormat: 'l',
                                    showWeekNumbers: false
                                }"
                                id="completionDate"
                            />
                        </div>
                    </div>

                    <div class="d-flex">
                        <div>
                            <label for="projectUsers">Project Users:</label>
                            <div class="font-italic">
                                {{
                                    projectUserListDirty
                                        ? 'Click Save to store your Project User changes'
                                        : ''
                                }}
                            </div>
                            <div id="projectUsers">
                                <table class="table table-light">
                                    <thead class="thead-light">
                                        <tr>
                                            <th
                                                *ngIf="projectForm.enabled"
                                            ></th>
                                            <th>User</th>
                                            <th>Role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let existing of project.assignedUsers
                                            "
                                        >
                                            <td
                                                *ngIf="projectForm.enabled"
                                                class="add-delete-icons"
                                                (click)="
                                                    toggleExistingUser(
                                                        existing.id
                                                    )
                                                "
                                            >
                                                <i class="bi bi-trash3"></i>
                                            </td>
                                            <td
                                                [class]="
                                                    projectUsersToDelete.includes(
                                                        existing.id
                                                    )
                                                        ? 'strikethrough'
                                                        : ''
                                                "
                                            >
                                                {{ existing.user.userName }}
                                            </td>
                                            <td
                                                [class]="
                                                    projectUsersToDelete.includes(
                                                        existing.id
                                                    )
                                                        ? 'strikethrough'
                                                        : ''
                                                "
                                            >
                                                {{ existing.role.role }}
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let user_assignment of projectUsersToAdd;
                                                let i = index
                                            "
                                        >
                                            <td
                                                *ngIf="projectForm.enabled"
                                                class="add-delete-icons text-success"
                                                (click)="
                                                    toggleUserAssignment(i)
                                                "
                                            >
                                                <i class="bi bi-trash3"></i>
                                            </td>
                                            <td
                                                class="text-success"
                                                [class]="
                                                    user_assignment.deletePending
                                                        ? 'strikethrough'
                                                        : ''
                                                "
                                            >
                                                {{ user_assignment.userName }}
                                            </td>
                                            <td
                                                class="text-success"
                                                [class]="
                                                    user_assignment.deletePending
                                                        ? 'strikethrough'
                                                        : ''
                                                "
                                            >
                                                {{ user_assignment.roleName }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="projectForm.enabled" class="add-delete-icons">
                        <button
                            type="button"
                            class="btn btn-link"
                            (click)="openAddUserModal(addProjectUserModal)"
                        >
                            <i class="bi bi-plus-lg"></i>
                            Add Project User
                        </button>
                    </div>

                    <label for="client">Client</label>
                    <input
                        class="form-control"
                        type="text"
                        formControlName="client"
                        id="client"
                    />

                    <label for="objective">Objective</label>
                    <textarea
                        class="form-control"
                        type="text"
                        formControlName="objective"
                        id="objective"
                    >
                    </textarea>

                    <label for="notes">Notes</label>
                    <textarea
                        class="form-control"
                        type="text"
                        formControlName="notes"
                        id="notes"
                    >
                    </textarea>

                    <bcdbio-url-info-widget
                        [editModeEnabled]="projectForm.enabled"
                        [existingUrlInfo]="existingUrlInfo"
                        [urlInfoToAdd]="urlInfoToAdd"
                        [urlInfoToDelete]="existingUrlInfoToDelete"
                        [isUrlInfoListDirty]="isUrlInfoListDirty"
                        (toggleExistingUrlInfo)="
                            onToggleExistingUrlInfo($event)
                        "
                        (toggleAddedUrlInfo)="onToggleAddedUrlInfo($event)"
                        (mergeUrlInfo)="onMergeUrlInfo($event)"
                    >
                    </bcdbio-url-info-widget>
                </div>
            </div>
        </form>

        <div class="top-buffer bcd-info-panel">
            <div>
                <div class="row pb-2">
                    <div class="col-sm-6">
                        <h3>Related Samples</h3>
                    </div>
                    <div class="col-sm-6">
                        <button
                            class="btn btn-outline-primary float-md-right"
                            type="button"
                            (click)="addRelatedSample()"
                        >
                            Add
                        </button>
                    </div>
                </div>
                <div>
                    <table class="table table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th>Sample</th>
                                <th>Process Type</th>
                                <th>Date Created</th>
                                <th></th>
                            </tr>
                        </thead>
                        <ng-container
                            *ngIf="
                                project?.relatedSamples?.length > 0;
                                else noRelSampleInfoBlock
                            "
                        >
                            <tr
                                *ngFor="let relSampleInfo of relatedSamplesInfo"
                            >
                                <td>
                                    <a
                                        [routerLink]="[
                                            '/sample-detail',
                                            relSampleInfo.sampleBcdId
                                        ]"
                                    >
                                        {{ relSampleInfo.sampleBcdId }}
                                    </a>
                                </td>
                                <td>
                                    {{ relSampleInfo.processType ?? 'n/a' }}
                                </td>
                                <td>
                                    {{ relSampleInfo.datePrepared ?? 'n/a' }}
                                </td>
                                <td>
                                    <span
                                        class="delete-icon"
                                        [style.cursor]="'pointer'"
                                        (click)="
                                            deleteRelatedSampleFromProject(
                                                relSampleInfo.id
                                            )
                                        "
                                    >
                                        <i class="bi bi-trash3"></i>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #noRelSampleInfoBlock>
                            <tr>
                                <td colspan="4">No samples linked.</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>

        <div class="top-buffer bcd-info-panel">
            <div>
                <h3>Related Studies</h3>
                <div>
                    <table class="table table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th>Study Name</th>
                                <th>Objective</th>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                        </thead>
                        <ng-container
                            *ngIf="
                                project?.relatedStudies?.length > 0;
                                else noStudiesBlock
                            "
                        >
                            <tr *ngFor="let study of project.relatedStudies">
                                <td>
                                    <a
                                        [routerLink]="[
                                            '/study',
                                            study.study?.studyId
                                        ]"
                                    >
                                        {{ study.study?.studyName }}
                                    </a>
                                </td>
                                <td>{{ study.study?.objective }}</td>
                                <td>{{ study.study?.startDate }}</td>
                                <td>{{ study.study?.endDate }}</td>
                            </tr>
                        </ng-container>
                        <ng-template #noStudiesBlock>
                            <tr>
                                <td colspan="4">No studies linked.</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Modal -->
<ng-template #addProjectUserModal>
    <div class="modal-header">
        <h4 class="modal-title">Add Project User</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex justify-content-center">
        <div
            class="d-flex align-items-center"
            *ngIf="{
                users: users$ | async,
                roles: roles$ | async
            } as data"
        >
            <div class="d-flex flex-column">
                <div class="d-flex flex-row w-100">
                    <div>
                        <select
                            #user
                            [disabled]="!projectForm.enabled"
                            class="form-control custom-select"
                            id="processType"
                        >
                            <option disabled selected value="Select"></option>
                            <option
                                *ngFor="let user of data.users"
                                [value]="user.userId"
                            >
                                {{ user.userName }}
                            </option>
                        </select>
                    </div>
                    <div class="ml-2">
                        <select
                            #role
                            [disabled]="!projectForm.enabled"
                            class="form-control custom-select"
                            id="roleType"
                        >
                            <option disabled selected value="Select"></option>
                            <option
                                *ngFor="let role of data.roles"
                                [value]="role.id"
                            >
                                {{ role.role }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-end w-100 pt-3">
                    <div>
                        <button
                            type="button"
                            class="btn btn-outline-danger float-right"
                            (click)="modalRef.hide()"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="ml-2">
                        <button
                            [disabled]="!projectForm.enabled"
                            class="btn btn-primary float-md-right text-nowrap"
                            type="button"
                            (click)="
                                addProjectUser(user.value, role.value, data)
                            "
                        >
                            Add User
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
