import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, Validators } from '@angular/forms';
import { UiPartialState, createProcessAndSample } from '@bcdbio/ui';
import { Store } from '@ngrx/store';
import { Sample, SampleType } from '@bcdbio/data';
import * as moment from 'moment';

const BIOLOGICAL_SAMPLE_TYPES = [
    SampleType.SingleStrain,
    SampleType.FecalSource,
    SampleType.FecalSlurry,
    SampleType.FecalPool,
    SampleType.SpikedFecalPool,
];
const CHEMICAL_SAMPLE_TYPES = [SampleType.Source, SampleType.Processed];

@Component({
    selector: 'bcdbio-create-process-sample',
    templateUrl: './create-process-sample.component.html',
    styleUrls: ['./create-process-sample.component.scss'],
})
export class CreateProcessSampleComponent implements OnInit {
    sample: Sample;

    processType = new FormControl('', [
        Validators.required,
        Validators.minLength(1),
    ]);
    descriptiveText = new FormControl('');
    notebookPage = new FormControl('');
    datePrepared = new FormControl('');
    processOptions: string[];

    constructor(
        public bsModalRef: BsModalRef,
        private storeUi: Store<UiPartialState>
    ) {}

    ngOnInit(): void {
        this.processOptions = this.calcProcessOptions();
    }

    createProcessAndSample(): void {
        const processType = this.processType.value;
        const descriptiveText = this.descriptiveText.value;
        const notebookPage = this.notebookPage.value;
        const datePrepared = moment(this.datePrepared.value).format(
            'MM/DD/YYYY'
        );
        const sourceSex =
            processType === 'Pool slurry - PBS glycerol'
                ? (this.sample.source.metadata['sex'] as string)
                : '';
        const sourceHealthStatus =
            processType === 'Pool slurry - PBS glycerol'
                ? (this.sample.source.metadata['healthStatus'] as string)
                : '';
        console.log(
            'creating process and sample:',
            this.sample.id,
            processType,
            descriptiveText,
            notebookPage,
            datePrepared,
            sourceSex,
            sourceHealthStatus
        );
        this.storeUi.dispatch(
            createProcessAndSample({
                fromId: this.sample.id,
                processType: processType,
                descriptiveText: descriptiveText,
                notebookPage: notebookPage,
                datePrepared: datePrepared,
                sourceSex: sourceSex,
                sourceHealthStatus: sourceHealthStatus,
            })
        );
        this.bsModalRef.hide();
    }

    calcProcessOptions(): string[] {
        if (
            this.sample &&
            CHEMICAL_SAMPLE_TYPES.includes(<SampleType>this.sample.type)
        ) {
            return [
                'Pre-processing',
                'COG',
                'RESIN',
                'EtOH',
                'Post-processing',
                'Other',
            ];
        } else {
            switch (this.sample && this.sample.type) {
                case 'Single Strain':
                    return ['Other'];
                case 'Fecal Source':
                    return ['Slurry - PBS glycerol', 'Other'];
                case 'Fecal Slurry':
                    return [
                        'Pool slurry - PBS glycerol',
                        'Micro (pre-inoculum)',
                        'Spiked-fecal prep',
                        'Other',
                    ];
                case 'Fecal Pool':
                    return [
                        'Micro (pre-inoculum)',
                        'Spiked-fecal prep',
                        'Other',
                    ];
                case 'Spiked Fecal Pool':
                    return ['Micro (pre-inoculum)', 'Other'];
                default:
                    return ['Other'];
            }
        }
    }
}
