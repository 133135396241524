import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '@bcdbio/data';

@Component({
    selector: 'bcdbio-sample-ontology',
    templateUrl: './sample-ontology.component.html',
    styleUrls: ['./sample-ontology.component.scss'],
})
export class SampleOntologyComponent implements OnInit {
    @Input() sample: Sample;

    constructor() {}

    ngOnInit(): void {}
}
