import { DataImportDataTabular } from './data-import-data-tabular';

export class DataImportDataLinkage extends DataImportDataTabular {
    public static HEADERS = [
        '',
        '',
        'Name',
        'Data File',
        'Type',
        'SOP Version',
        'Level',
        'Acq. Date-Time',
        // NOTE: ordering of column pairs will vary in columns below
        'T-Glucose Results|Final Conc.',
        'T-Glucose Results|Resp.',
        'T-Glucose-f Results|Final Conc.',
        'T-Glucose-f Results|Resp.',
        'T-Glucose-p Results|Final Conc.',
        'T-Glucose-p Results|Resp.',
        '6-Glucose Results|Final Conc.',
        '6-Glucose Results|Resp.',
        '4-Glucose Results|Final Conc.',
        '4-Glucose Results|Resp.',
        '3-Glucose Results|Final Conc.',
        '3-Glucose Results|Resp.',
        '2-Glucose Results|Final Conc.',
        '2-Glucose Results|Resp.',
        '2,X-Glucose a Results|Final Conc.',
        '2,X-Glucose a Results|Resp.',
        'X,X-Glucose (I) Results|Final Conc.',
        'X,X-Glucose (I) Results|Resp.',
        'X,X-Glucose (II) Results|Final Conc.',
        'X,X-Glucose (II) Results|Resp.',
        '2,X-Glucose (I) Results|Final Conc.',
        '2,X-Glucose (I) Results|Resp.',
        '3,4,6-Hexose (I) Results|Final Conc.',
        '3,4,6-Hexose (I) Results|Resp.',
        '3,4,6-Hexose (II) Results|Final Conc.',
        '3,4,6-Hexose (II) Results|Resp.',
        '3,4,6-Hexose (III) Results|Final Conc.',
        '3,4,6-Hexose (III) Results|Resp.',
        '3,4,6-Hexose (IV) Results|Final Conc.',
        '3,4,6-Hexose (IV) Results|Resp.',
        '3,4,6-Hexose (V) Results|Final Conc.',
        '3,4,6-Hexose (V) Results|Resp.',
        '3,4,6-Glucose Results|Final Conc.',
        '3,4,6-Glucose Results|Resp.',
        '3,6-Glucose Results|Final Conc.',
        '3,6-Glucose Results|Resp.',
        'T-Galactose Results|Final Conc.',
        'T-Galactose Results|Resp.',
        '6-Galactose Results|Final Conc.',
        '6-Galactose Results|Resp.',
        '4-Galactose Results|Final Conc.',
        '4-Galactose Results|Resp.',
        '3-Galactose Results|Final Conc.',
        '3-Galactose Results|Resp.',
        '2-Galactose Results|Final Conc.',
        '2-Galactose Results|Resp.',
        '3,4,6-Galactose Results|Final Conc.',
        '3,4,6-Galactose Results|Resp.',
        'T-Fructose Results|Final Conc.',
        'T-Fructose Results|Resp.',
        'T-F-Xylose Results|Final Conc.',
        'T-F-Xylose Results|Resp.',
        'T-P-Xylose Results|Final Conc.',
        'T-P-Xylose Results|Resp.',
        '4-P-Xylose Results|Final Conc.',
        '4-P-Xylose Results|Resp.',
        '2-P-Xylose Results|Final Conc.',
        '2-P-Xylose Results|Resp.',
        '3,4-P-Xylose Results|Final Conc.',
        '3,4-P-Xylose Results|Resp.',
        'T-F-Arabinose Results|Final Conc.',
        'T-F-Arabinose Results|Resp.',
        'T-P-Arabinose Results|Final Conc.',
        'T-P-Arabinose Results|Resp.',
        '5-F-Arabinose Results|Final Conc.',
        '5-F-Arabinose Results|Resp.',
        '3-P-Arabinose Results|Final Conc.',
        '3-P-Arabinose Results|Resp.',
        '2-F-Arabinose Results|Final Conc.',
        '2-F-Arabinose Results|Resp.',
        '3,4-F-Arabinose Results|Final Conc.',
        '3,4-F-Arabinose Results|Resp.',
        '3,4-P-Arabinose Results|Final Conc.',
        '3,4-P-Arabinose Results|Resp.',
        '2,3,5-Arabinose Results|Final Conc.',
        '2,3,5-Arabinose Results|Resp.',
        '2,5-Arabinose Results|Final Conc.',
        '2,5-Arabinose Results|Resp.',
        'T-Fucose Results|Final Conc.',
        'T-Fucose Results|Resp.',
        '2-Fucose Results|Final Conc.',
        '2-Fucose Results|Resp.',
        '3,4-Fucose Results|Final Conc.',
        '3,4-Fucose Results|Resp.',
        'T-Rhamnose Results|Final Conc.',
        'T-Rhamnose Results|Resp.',
        '2-Rhamnose Results|Final Conc.',
        '2-Rhamnose Results|Resp.',
        '3,4-Rhamnose Results|Final Conc.',
        '3,4-Rhamnose Results|Resp.',
        '2-Glucuronic Acid Results|Final Conc.',
        '2-Glucuronic Acid Results|Resp.',
        '3,4-Glucuronic Acid Results|Final Conc.',
        '3,4-Glucuronic Acid Results|Resp.',
        '3,4-Galacturonic Acid Results|Final Conc.',
        '3,4-Galacturonic Acid Results|Resp.',
        'T-Mannose Results|Final Conc.',
        'T-Mannose Results|Resp.',
        '6-Mannose Results|Final Conc.',
        '6-Mannose Results|Resp.',
        '4-Mannose Results|Final Conc.',
        '4-Mannose Results|Resp.',
        '3-Mannose Results|Final Conc.',
        '3-Mannose Results|Resp.',
        '2-Mannose Results|Final Conc.',
        '2-Mannose Results|Resp.',
        '3,6-Mannose Results|Final Conc.',
        '3,6-Mannose Results|Resp.',
        '3,4,6-Mannose Results|Final Conc.',
        '3,4,6-Mannose Results|Resp.',
        'T-Allose Results|Final Conc.',
        'T-Allose Results|Resp.',
        '2-Allose Results|Final Conc.',
        '2-Allose Results|Resp.',
        '3,4,6-Allose Results|Final Conc.',
        '3,4,6-Allose Results|Resp.',
        'T-Ribose Results|Final Conc.',
        'T-Ribose Results|Resp.',
        '2-Ribose Results|Final Conc.',
        '2-Ribose Results|Resp.',
        '3,4-Ribose Results|Final Conc.',
        '3,4-Ribose Results|Resp.',
        'X-Hexose Results|Final Conc.',
        'X-Hexose Results|Resp.',
        'X-Hexose (I) Results|Final Conc.',
        'X-Hexose (I) Results|Resp.',
        'X-Hexose (II) Results|Final Conc.',
        'X-Hexose (II) Results|Resp.',
        'X,X-Hexose (I) Results|Final Conc.',
        'X,X-Hexose (I) Results|Resp.',
        'X,X-Hexose (II) Results|Final Conc.',
        'X,X-Hexose (II) Results|Resp.',
        'X,X-Hexose (III) Results|Final Conc.',
        'X,X-Hexose (III) Results|Resp.',
        '2,X-Hexose (I) Results|Final Conc.',
        '2,X-Hexose (I) Results|Resp.',
        '2,X-Hexose (II) Results|Final Conc.',
        '2,X-Hexose (II) Results|Resp.',
        '2,X-Hexose (III) Results|Final Conc.',
        '2,X-Hexose (III) Results|Resp.',
        '2,X-Hexose (IV) Results|Final Conc.',
        '2,X-Hexose (IV) Results|Resp.',
        '2,X-Hexose (V) Results|Final Conc.',
        '2,X-Hexose (V) Results|Resp.',
        '2,X,X-Hexose (I) Results|Final Conc.',
        '2,X,X-Hexose (I) Results|Resp.',
        '2,X,X-Hexose (II) Results|Final Conc.',
        '2,X,X-Hexose (II) Results|Resp.',
        '2,X,X-Hexose (III) Results|Final Conc.',
        '2,X,X-Hexose (III) Results|Resp.',
        'X-Pentose (I) Results|Final Conc.',
        'X-Pentose (I) Results|Resp.',
        'X-Pentose (II) Results|Final Conc.',
        'X-Pentose (II) Results|Resp.',
        'X-Pentose (III) Results|Final Conc.',
        'X-Pentose (III) Results|Resp.',
        '2,X-Pentose (I) Results|Final Conc.',
        '2,X-Pentose (I) Results|Resp.',
        '2,X-Pentose (II) Results|Final Conc.',
        '2,X-Pentose (II) Results|Resp.',
        '2,X-Pentose (III) Results|Final Conc.',
        '2,X-Pentose (III) Results|Resp.',
        '2,X-Pentose (IV) Results|Final Conc.',
        '2,X-Pentose (IV) Results|Resp.',
        '2,X-Pentose (V) Results|Final Conc.',
        '2,X-Pentose (V) Results|Resp.',
        '2,X-Pentose (VI) Results|Final Conc.',
        '2,X-Pentose (VI) Results|Resp.',
        'X-Deoxyhexose (I) Results|Final Conc.',
        'X-Deoxyhexose (I) Results|Resp.',
        'X-Deoxyhexose (II) Results|Final Conc.',
        'X-Deoxyhexose (II) Results|Resp.',
        'X-Deoxyhexose (III) Results|Final Conc.',
        'X-Deoxyhexose (III) Results|Resp.',
        'X-Deoxyhexose (IV) Results|Final Conc.',
        'X-Deoxyhexose (IV) Results|Resp.',
        'X-Deoxyhexose (V) Results|Final Conc.',
        'X-Deoxyhexose (V) Results|Resp.',
        'X-Deoxyhexose (VI) Results|Final Conc.',
        'X-Deoxyhexose (VI) Results|Resp.',
        'X-Deoxyhexose (VII) Results|Final Conc.',
        'X-Deoxyhexose (VII) Results|Resp.',
        'X-Deoxyhexose (VIII) Results|Final Conc.',
        'X-Deoxyhexose (VIII) Results|Resp.',
        '2-Deoxyhexose Results|Final Conc.',
        '2-Deoxyhexose Results|Resp.',
        '2,X-Deoxyhexose Results|Final Conc.',
        '2,X-Deoxyhexose Results|Resp.',
        'T-Hexuronic Acid (I) Results|Final Conc.',
        'T-Hexuronic Acid (I) Results|Resp.',
        'T-Hexuronic Acid (II) Results|Final Conc.',
        'T-Hexuronic Acid (II) Results|Resp.',
        'X-Hexuronic (I) Results|Final Conc.',
        'X-Hexuronic (I) Results|Resp.',
        'X-Hexuronic (II) Results|Final Conc.',
        'X-Hexuronic (II) Results|Resp.',
        'X-Hexuronic (III) Results|Final Conc.',
        'X-Hexuronic (III) Results|Resp.',
        'X-Hexuronic (IV) Results|Final Conc.',
        'X-Hexuronic (IV) Results|Resp.',
        'X-Hexuronic (V) Results|Final Conc.',
        'X-Hexuronic (V) Results|Resp.',
        '2-Pentose a Results|Final Conc.',
        '2-Pentose a Results|Resp.',
        '2-Pentose b Results|Final Conc.',
        '2-Pentose b Results|Resp.',
        '2-Pentose c Results|Final Conc.',
        '2-Pentose c Results|Resp.',
        '2-Pentose d Results|Final Conc.',
        '2-Pentose d Results|Resp.',
        '2-Pentose e Results|Final Conc.',
        '2-Pentose e Results|Resp.',
        '2-Pentose f Results|Final Conc.',
        '2-Pentose f Results|Resp.',
        '2-Pentose g Results|Final Conc.',
        '2-Pentose g Results|Resp.',
        '2-Pentose h Results|Final Conc.',
        '2-Pentose h Results|Resp.',
        '2-Pentose i Results|Final Conc.',
        '2-Pentose i Results|Resp.',
        '2-Pentose j Results|Final Conc.',
        '2-Pentose j Results|Resp.',
        '2-Pentose k Results|Final Conc.',
        '2-Pentose k Results|Resp.',
        '2-Pentose l Results|Final Conc.',
        '2-Pentose l Results|Resp.',
        '2-Pentose m Results|Final Conc.',
        '2-Pentose m Results|Resp.',
        '2-Pentose n Results|Final Conc.',
        '2-Pentose n Results|Resp.',
        '2-Pentose o Results|Final Conc.',
        '2-Pentose o Results|Resp.',
        '2-Pentose p Results|Final Conc.',
        '2-Pentose p Results|Resp.',
        '2-Xylose Results|Final Conc.',
        '2-Xylose Results|Resp.',
        '2,3,4-Xylose Results|Final Conc.',
        '2,3,4-Xylose Results|Resp.',
        '2,4-Xylose Results|Final Conc.',
        '2,4-Xylose Results|Resp.',
        '2,X-Hexose a Results|Final Conc.',
        '2,X-Hexose a Results|Resp.',
        '2,X-Hexose b Results|Final Conc.',
        '2,X-Hexose b Results|Resp.',
        '2,X-Hexose c Results|Final Conc.',
        '2,X-Hexose c Results|Resp.',
        '2,X-Hexose d Results|Final Conc.',
        '2,X-Hexose d Results|Resp.',
        '2,X-Hexose e Results|Final Conc.',
        '2,X-Hexose e Results|Resp.',
        '2,X-Hexose f Results|Final Conc.',
        '2,X-Hexose f Results|Resp.',
        '2,X-Hexose g Results|Final Conc.',
        '2,X-Hexose g Results|Resp.',
        '2,X-Hexose h Results|Final Conc.',
        '2,X-Hexose h Results|Resp.',
        '2,X-Hexose i Results|Final Conc.',
        '2,X-Hexose i Results|Resp.',
        '2,X-Hexose j Results|Final Conc.',
        '2,X-Hexose j Results|Resp.',
        '2,X-Hexose k Results|Final Conc.',
        '2,X-Hexose k Results|Resp.',
        '2,X-Hexose l Results|Final Conc.',
        '2,X-Hexose l Results|Resp.',
        '2,X-Hexose m Results|Final Conc.',
        '2,X-Hexose m Results|Resp.',
        '2,X-Hexose n Results|Final Conc.',
        '2,X-Hexose n Results|Resp.',
        '2,X-Hexose o Results|Final Conc.',
        '2,X-Hexose o Results|Resp.',
        '2,X-Hexose p Results|Final Conc.',
        '2,X-Hexose p Results|Resp.',
        '2,X-Pentose a Results|Final Conc.',
        '2,X-Pentose a Results|Resp.',
        '2,X-Pentose b Results|Final Conc.',
        '2,X-Pentose b Results|Resp.',
        '2,X-Pentose c Results|Final Conc.',
        '2,X-Pentose c Results|Resp.',
        '2,X-Pentose d Results|Final Conc.',
        '2,X-Pentose d Results|Resp.',
        '2,X-Pentose e Results|Final Conc.',
        '2,X-Pentose e Results|Resp.',
        '2,X-Pentose f Results|Final Conc.',
        '2,X-Pentose f Results|Resp.',
        '2,X-Pentose g Results|Final Conc.',
        '2,X-Pentose g Results|Resp.',
        '2,X-Pentose h Results|Final Conc.',
        '2,X-Pentose h Results|Resp.',
        '2,X-Pentose i Results|Final Conc.',
        '2,X-Pentose i Results|Resp.',
        '2,X-Pentose j Results|Final Conc.',
        '2,X-Pentose j Results|Resp.',
        '2,X-Pentose k Results|Final Conc.',
        '2,X-Pentose k Results|Resp.',
        '2,X-Pentose l Results|Final Conc.',
        '2,X-Pentose l Results|Resp.',
        '2,X-Pentose m Results|Final Conc.',
        '2,X-Pentose m Results|Resp.',
        '2,X-Pentose n Results|Final Conc.',
        '2,X-Pentose n Results|Resp.',
        '2,X-Pentose o Results|Final Conc.',
        '2,X-Pentose o Results|Resp.',
        '2,X-Pentose p Results|Final Conc.',
        '2,X-Pentose p Results|Resp.',
        '2,X,X-Hexose a Results|Final Conc.',
        '2,X,X-Hexose a Results|Resp.',
        '2,X,X-Hexose b Results|Final Conc.',
        '2,X,X-Hexose b Results|Resp.',
        '2,X,X-Hexose c Results|Final Conc.',
        '2,X,X-Hexose c Results|Resp.',
        '2,X,X-Hexose d Results|Final Conc.',
        '2,X,X-Hexose d Results|Resp.',
        '2,X,X-Hexose e Results|Final Conc.',
        '2,X,X-Hexose e Results|Resp.',
        '2,X,X-Hexose f Results|Final Conc.',
        '2,X,X-Hexose f Results|Resp.',
        '2,X,X-Hexose g Results|Final Conc.',
        '2,X,X-Hexose g Results|Resp.',
        '2,X,X-Hexose h Results|Final Conc.',
        '2,X,X-Hexose h Results|Resp.',
        '2,X,X-Hexose i Results|Final Conc.',
        '2,X,X-Hexose i Results|Resp.',
        '2,X,X-Hexose j Results|Final Conc.',
        '2,X,X-Hexose j Results|Resp.',
        '2,X,X-Hexose k Results|Final Conc.',
        '2,X,X-Hexose k Results|Resp.',
        '2,X,X-Hexose l Results|Final Conc.',
        '2,X,X-Hexose l Results|Resp.',
        '2,X,X-Hexose m Results|Final Conc.',
        '2,X,X-Hexose m Results|Resp.',
        '2,X,X-Hexose n Results|Final Conc.',
        '2,X,X-Hexose n Results|Resp.',
        '2,X,X-Hexose o Results|Final Conc.',
        '2,X,X-Hexose o Results|Resp.',
        '2,X,X-Hexose p Results|Final Conc.',
        '2,X,X-Hexose p Results|Resp.',
        '2,X,X-Rhamnose Results|Final Conc.',
        '2,X,X-Rhamnose Results|Resp.',
        '3,4-Galactose Results|Final Conc.',
        '3,4-Galactose Results|Resp.',
        '3,4-Galactose/3,6-Mannose Results|Final Conc.',
        '3,4-Galactose/3,6-Mannose Results|Resp.',
        '3,4-Xylose Results|Final Conc.',
        '3,4-Xylose Results|Resp.',
        '3,4-Xylose/3,5-Arabinose Results|Final Conc.',
        '3,4-Xylose/3,5-Arabinose Results|Resp.',
        '3,5-Arabinose Results|Final Conc.',
        '3,5-Arabinose Results|Resp.',
        '3,6-Galactose Results|Final Conc.',
        '3,6-Galactose Results|Resp.',
        '3/4-Mannose Results|Final Conc.',
        '3/4-Mannose Results|Resp.',
        '4-GlcA Results|Final Conc.',
        '4-GlcA Results|Resp.',
        '4-Rhamnose Results|Final Conc.',
        '4-Rhamnose Results|Resp.',
        '4-Xylose Results|Final Conc.',
        '4-Xylose Results|Resp.',
        '4-Xylose/5-Arabinose Results|Final Conc.',
        '4-Xylose/5-Arabinose Results|Resp.',
        '4,6-Glucose Results|Final Conc.',
        '4,6-Glucose Results|Resp.',
        '4,6-Mannose Results|Final Conc.',
        '4,6-Mannose Results|Resp.',
        '5-Arabinose Results|Final Conc.',
        '5-Arabinose Results|Resp.',
        'T-Arabinose-f Results|Final Conc.',
        'T-Arabinose-f Results|Resp.',
        'T-Arabinose-f/4-Rhamnose Results|Final Conc.',
        'T-Arabinose-f/4-Rhamnose Results|Resp.',
        'T-Arabinose-p Results|Final Conc.',
        'T-Arabinose-p Results|Resp.',
        'T-Fucose a Results|Final Conc.',
        'T-Fucose a Results|Resp.',
        'T-Fucose a/T-Rhamnose Results|Final Conc.',
        'T-Fucose a/T-Rhamnose Results|Resp.',
        'T-Fucose b Results|Final Conc.',
        'T-Fucose b Results|Resp.',
        'T-GalA a Results|Final Conc.',
        'T-GalA a Results|Resp.',
        'T-GalA b Results|Final Conc.',
        'T-GalA b Results|Resp.',
        'T-Galactose-f Results|Final Conc.',
        'T-Galactose-f Results|Resp.',
        'T-Galactose-p Results|Final Conc.',
        'T-Galactose-p Results|Resp.',
        'T-GlcA Results|Final Conc.',
        'T-GlcA Results|Resp.',
        'T-Glucose/T-Mannose Results|Final Conc.',
        'T-Glucose/T-Mannose Results|Resp.',
        'T-Ribose a Results|Final Conc.',
        'T-Ribose a Results|Resp.',
        'T-Ribose a/T-Arabinose-p Results|Final Conc.',
        'T-Ribose a/T-Arabinose-p Results|Resp.',
        'T-Ribose b Results|Final Conc.',
        'T-Ribose b Results|Resp.',
        'T-Xylose Results|Final Conc.',
        'T-Xylose Results|Resp.',
        'X-Fucose a Results|Final Conc.',
        'X-Fucose a Results|Resp.',
        'X-Hexose a Results|Final Conc.',
        'X-Hexose a Results|Resp.',
        'X-Hexose b Results|Final Conc.',
        'X-Hexose b Results|Resp.',
        'X-Hexose c Results|Final Conc.',
        'X-Hexose c Results|Resp.',
        'X-Hexose d Results|Final Conc.',
        'X-Hexose d Results|Resp.',
        'X-Hexose e Results|Final Conc.',
        'X-Hexose e Results|Resp.',
        'X-Hexose f Results|Final Conc.',
        'X-Hexose f Results|Resp.',
        'X-Hexose g Results|Final Conc.',
        'X-Hexose g Results|Resp.',
        'X-Hexose h Results|Final Conc.',
        'X-Hexose h Results|Resp.',
        'X-Hexose i Results|Final Conc.',
        'X-Hexose i Results|Resp.',
        'X-Hexose j Results|Final Conc.',
        'X-Hexose j Results|Resp.',
        'X-Hexose k Results|Final Conc.',
        'X-Hexose k Results|Resp.',
        'X-Hexose l Results|Final Conc.',
        'X-Hexose l Results|Resp.',
        'X-Hexose m Results|Final Conc.',
        'X-Hexose m Results|Resp.',
        'X-Hexose n Results|Final Conc.',
        'X-Hexose n Results|Resp.',
        'X-Hexose o Results|Final Conc.',
        'X-Hexose o Results|Resp.',
        'X-Hexose p Results|Final Conc.',
        'X-Hexose p Results|Resp.',
        'X-Pentose a Results|Final Conc.',
        'X-Pentose a Results|Resp.',
        'X-Pentose b Results|Final Conc.',
        'X-Pentose b Results|Resp.',
        'X-Pentose c Results|Final Conc.',
        'X-Pentose c Results|Resp.',
        'X-Pentose d Results|Final Conc.',
        'X-Pentose d Results|Resp.',
        'X-Pentose e Results|Final Conc.',
        'X-Pentose e Results|Resp.',
        'X-Pentose f Results|Final Conc.',
        'X-Pentose f Results|Resp.',
        'X-Pentose g Results|Final Conc.',
        'X-Pentose g Results|Resp.',
        'X-Pentose h Results|Final Conc.',
        'X-Pentose h Results|Resp.',
        'X-Pentose i Results|Final Conc.',
        'X-Pentose i Results|Resp.',
        'X-Pentose j Results|Final Conc.',
        'X-Pentose j Results|Resp.',
        'X-Pentose k Results|Final Conc.',
        'X-Pentose k Results|Resp.',
        'X-Pentose l Results|Final Conc.',
        'X-Pentose l Results|Resp.',
        'X-Pentose m Results|Final Conc.',
        'X-Pentose m Results|Resp.',
        'X-Pentose n Results|Final Conc.',
        'X-Pentose n Results|Resp.',
        'X-Pentose o Results|Final Conc.',
        'X-Pentose o Results|Resp.',
        'X-Pentose p Results|Final Conc.',
        'X-Pentose p Results|Resp.',
        'X,X-Hexose a Results|Final Conc.',
        'X,X-Hexose a Results|Resp.',
        'X,X-Hexose b Results|Final Conc.',
        'X,X-Hexose b Results|Resp.',
        'X,X-Hexose c Results|Final Conc.',
        'X,X-Hexose c Results|Resp.',
        'X,X-Hexose d Results|Final Conc.',
        'X,X-Hexose d Results|Resp.',
        'X,X-Hexose e Results|Final Conc.',
        'X,X-Hexose e Results|Resp.',
        'X,X-Hexose f Results|Final Conc.',
        'X,X-Hexose f Results|Resp.',
        'X,X-Hexose g Results|Final Conc.',
        'X,X-Hexose g Results|Resp.',
        'X,X-Hexose h Results|Final Conc.',
        'X,X-Hexose h Results|Resp.',
        'X,X-Hexose i Results|Final Conc.',
        'X,X-Hexose i Results|Resp.',
        'X,X-Hexose j Results|Final Conc.',
        'X,X-Hexose j Results|Resp.',
        'X,X-Hexose k Results|Final Conc.',
        'X,X-Hexose k Results|Resp.',
        'X,X-Hexose l Results|Final Conc.',
        'X,X-Hexose l Results|Resp.',
        'X,X-Hexose m Results|Final Conc.',
        'X,X-Hexose m Results|Resp.',
        'X,X-Hexose n Results|Final Conc.',
        'X,X-Hexose n Results|Resp.',
        'X,X-Hexose o Results|Final Conc.',
        'X,X-Hexose o Results|Resp.',
        'X,X-Hexose p Results|Final Conc.',
        'X,X-Hexose p Results|Resp.',
        'X,X-Pentose a Results|Final Conc.',
        'X,X-Pentose a Results|Resp.',
        'X,X-Pentose b Results|Final Conc.',
        'X,X-Pentose b Results|Resp.',
        'X,X-Pentose c Results|Final Conc.',
        'X,X-Pentose c Results|Resp.',
        'X,X-Pentose d Results|Final Conc.',
        'X,X-Pentose d Results|Resp.',
        'X,X-Pentose e Results|Final Conc.',
        'X,X-Pentose e Results|Resp.',
        'X,X-Pentose f Results|Final Conc.',
        'X,X-Pentose f Results|Resp.',
        'X,X-Pentose g Results|Final Conc.',
        'X,X-Pentose g Results|Resp.',
        'X,X-Pentose h Results|Final Conc.',
        'X,X-Pentose h Results|Resp.',
        'X,X-Pentose i Results|Final Conc.',
        'X,X-Pentose i Results|Resp.',
        'X,X-Pentose j Results|Final Conc.',
        'X,X-Pentose j Results|Resp.',
        'X,X-Pentose k Results|Final Conc.',
        'X,X-Pentose k Results|Resp.',
        'X,X-Pentose l Results|Final Conc.',
        'X,X-Pentose l Results|Resp.',
        'X,X-Pentose m Results|Final Conc.',
        'X,X-Pentose m Results|Resp.',
        'X,X-Pentose n Results|Final Conc.',
        'X,X-Pentose n Results|Resp.',
        'X,X-Pentose o Results|Final Conc.',
        'X,X-Pentose o Results|Resp.',
        'X,X-Pentose p Results|Final Conc.',
        'X,X-Pentose p Results|Resp.',
        'X,X,X-Hexose a Results|Final Conc.',
        'X,X,X-Hexose a Results|Resp.',
        'X,X,X-Hexose b Results|Final Conc.',
        'X,X,X-Hexose b Results|Resp.',
        'X,X,X-Hexose c Results|Final Conc.',
        'X,X,X-Hexose c Results|Resp.',
        'X,X,X-Hexose d Results|Final Conc.',
        'X,X,X-Hexose d Results|Resp.',
        'X,X,X-Hexose e Results|Final Conc.',
        'X,X,X-Hexose e Results|Resp.',
        'X,X,X-Hexose f Results|Final Conc.',
        'X,X,X-Hexose f Results|Resp.',
        'X,X,X-Hexose g Results|Final Conc.',
        'X,X,X-Hexose g Results|Resp.',
        'X,X,X-Hexose h Results|Final Conc.',
        'X,X,X-Hexose h Results|Resp.',
        'X,X,X-Hexose i Results|Final Conc.',
        'X,X,X-Hexose i Results|Resp.',
        'X,X,X-Hexose j Results|Final Conc.',
        'X,X,X-Hexose j Results|Resp.',
        'X,X,X-Hexose k Results|Final Conc.',
        'X,X,X-Hexose k Results|Resp.',
        'X,X,X-Hexose l Results|Final Conc.',
        'X,X,X-Hexose l Results|Resp.',
        'X,X,X-Hexose m Results|Final Conc.',
        'X,X,X-Hexose m Results|Resp.',
        'X,X,X-Hexose n Results|Final Conc.',
        'X,X,X-Hexose n Results|Resp.',
        'X,X,X-Hexose o Results|Final Conc.',
        'X,X,X-Hexose o Results|Resp.',
        'X,X,X-Hexose p Results|Final Conc.',
        'X,X,X-Hexose p Results|Resp.',
        'X,X,X-Pentose a Results|Final Conc.',
        'X,X,X-Pentose a Results|Resp.',
        'X,X,X-Pentose b Results|Final Conc.',
        'X,X,X-Pentose b Results|Resp.',
        'X,X,X-Pentose c Results|Final Conc.',
        'X,X,X-Pentose c Results|Resp.',
        'X,X,X-Pentose d Results|Final Conc.',
        'X,X,X-Pentose d Results|Resp.',
        'X,X,X-Pentose e Results|Final Conc.',
        'X,X,X-Pentose e Results|Resp.',
        'X,X,X-Pentose f Results|Final Conc.',
        'X,X,X-Pentose f Results|Resp.',
        'X,X,X-Pentose g Results|Final Conc.',
        'X,X,X-Pentose g Results|Resp.',
        'X,X,X-Pentose h Results|Final Conc.',
        'X,X,X-Pentose h Results|Resp.',
        'X,X,X-Pentose i Results|Final Conc.',
        'X,X,X-Pentose i Results|Resp.',
        'X,X,X-Pentose j Results|Final Conc.',
        'X,X,X-Pentose j Results|Resp.',
        'X,X,X-Pentose k Results|Final Conc.',
        'X,X,X-Pentose k Results|Resp.',
        'X,X,X-Pentose l Results|Final Conc.',
        'X,X,X-Pentose l Results|Resp.',
        'X,X,X-Pentose m Results|Final Conc.',
        'X,X,X-Pentose m Results|Resp.',
        'X,X,X-Pentose n Results|Final Conc.',
        'X,X,X-Pentose n Results|Resp.',
        'X,X,X-Pentose o Results|Final Conc.',
        'X,X,X-Pentose o Results|Resp.',
        'X,X,X-Pentose p Results|Final Conc.',
        'X,X,X-Pentose p Results|Resp.',
    ];

    constructor(data: Array<string[]>) {
        super(data);
        this.headers = DataImportDataLinkage.HEADERS;
    }
}
