import { DataImportPersist } from './data-import-persist';
import { Sample } from '../../model/sample.model';
import { of, Observable } from 'rxjs';
import { SampleSaveResult, SaveResult } from '../sample-save-result';
import { MapperData } from '../mapping/mapper-data';
import { DataImportType } from '@bcdbio/data';

export class DataImportPersistSource extends DataImportPersist {
    save(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return this.sampleApiService.saveSourceSamples(data.samples);
    }
}
