import { Inject, Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { FileSaveResult } from './data-import/sample-save-result';
import { catchError, mapTo } from 'rxjs/operators';
import { InsertImportFileOneGQL } from '@bcdbio/udb-graphql';
import { IMPORT_ARCHIVE_CONFIG } from '@bcdbio/app-tokens';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import {
    CompletedPart,
    CompleteMultipartUploadCommand,
    CreateMultipartUploadCommand,
    CreateMultipartUploadOutput,
    UploadPartCommand,
    UploadPartCommandOutput,
} from '@aws-sdk/client-s3';
import { FileClient } from './file-client';

@Injectable({
    providedIn: 'root',
})
export class FileApiService {
    constructor(
        private http: HttpClient,
        private fileClient: FileClient,
        private importFileInsertOneGQL: InsertImportFileOneGQL,
        @Inject(IMPORT_ARCHIVE_CONFIG) private importArchiveConfig: any
    ) {}

    /**
     * create s3 filename (uuid for filename, folder is dataFileType with non-word chars changed to _)
     */
    public static getFilePath(name: string, dataFileType: string): string {
        const extWithPeriod = name.substring(name.lastIndexOf('.'));

        let tempname = name.replace('.csv', '');
        tempname = tempname.replace('.metadata', '');
        tempname = tempname.replace('.growthdata', '');

        return (
            dataFileType.replace(/\W+/g, '_') +
            '/' +
            tempname +
            '_' +
            uuidv4() +
            extWithPeriod
        );
    }

    public archiveMultipartDataFile(
        file: File,
        dataFileType: string
    ): Observable<FileSaveResult> {
        const filePath = FileApiService.getFilePath(file.name, dataFileType);
        console.log('Archive Filename: ' + filePath);
        return from(
            this.fileClient.client
                .send(
                    new CreateMultipartUploadCommand({
                        Bucket: this.importArchiveConfig.importFileBucket,
                        Key: filePath,
                    })
                )
                .then((creationResponse: CreateMultipartUploadOutput) => {
                    const parts: CompletedPart[] = [];
                    const uploads: Promise<number>[] = [];
                    const chunkSize = 10e6;
                    const numChunks = Math.ceil(file.size / chunkSize);
                    for (let i = 0; i < numChunks; i++) {
                        const uploadPartCommand = new UploadPartCommand({
                            Bucket: this.importArchiveConfig.importFileBucket,
                            Key: filePath,
                            UploadId: creationResponse.UploadId,
                            Body: file.slice(
                                i * chunkSize,
                                i * chunkSize + chunkSize
                            ),
                            PartNumber: i + 1,
                        });
                        uploads.push(
                            this.fileClient.client
                                .send(uploadPartCommand)
                                .then((output: UploadPartCommandOutput) => {
                                    return parts.push({
                                        PartNumber: i + 1,
                                        ETag: output.ETag,
                                    });
                                })
                        );
                    }

                    return Promise.all(uploads).then(() => {
                        return this.fileClient.client.send(
                            new CompleteMultipartUploadCommand({
                                Bucket: this.importArchiveConfig
                                    .importFileBucket,
                                Key: filePath,
                                UploadId: creationResponse.UploadId,
                                MultipartUpload: {
                                    Parts: parts.sort(
                                        (a, b) => a.PartNumber - b.PartNumber
                                    ),
                                },
                            })
                        );
                    });
                })
        ).pipe(
            mapTo({
                filePath: filePath,
                success: true,
                error: '',
                message: 'Import file archived',
            }),
            catchError((error) => {
                return of({
                    filePath: filePath,
                    success: false,
                    error: error.toString(),
                    message: '',
                });
            })
        );
    }
}
