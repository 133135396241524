<div class="border rounded p-3 bg-light">
    <div class="row">
        <div class="col-sm-6">
            <h3>Spiked Sources</h3>
        </div>
        <div class="col-sm-6">
            <button
                *ngIf="!editMode"
                class="btn btn-outline-primary float-md-right"
                type="button"
                (click)="editPoolSources()"
            >
                Edit
            </button>
            <button
                *ngIf="editMode"
                class="btn btn-outline-primary float-md-right"
                type="button"
                (click)="addPoolSource()"
            >
                Add
            </button>
        </div>
    </div>
    <h4>Metadata</h4>
    <div class="metadata-group">
        <ng-container *ngFor="let header of metadataHeaders">
            <label *ngIf="header !== 'ulAdded'" class="metadata-label">
                {{ header | unCamelCase }}:
            </label>
            <label *ngIf="header === 'ulAdded'" class="metadata-label"
                >ul Added:
            </label>
        </ng-container>
    </div>
    <bcdbio-pool-sources-readonly
        *ngIf="!editMode"
        [orderedMetadata]="orderedMetadata"
    ></bcdbio-pool-sources-readonly>
    <bcdbio-pool-sources-form
        *ngIf="editMode"
        [sample]="sample"
        [orderedMetadata]="orderedMetadata"
        (finished)="finishEditMode()"
    ></bcdbio-pool-sources-form>
</div>
