import { DataImportMapper } from './data-import-mapper';
import { Sample } from '../../model/sample.model';
import { DataImportData } from '../data/data-import-data';
import { Injectable } from '@angular/core';
import { CreateBcdIdGQL, StudyNode } from '@bcdbio/udb-graphql';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MapperData } from './mapper-data';
import { DataImportType, Process } from '@bcdbio/data';
import { DataImportValidatorTray } from '../validator/data-import-validator-tray';

interface CellData {
    replicate: string;
    value: string;
}
@Injectable({ providedIn: 'root' })
export class DataImportMapperTray extends DataImportMapper {
    constructor(private createBcdIdGQL: CreateBcdIdGQL) {
        super();
    }

    private optionalMetadataMap = {
        'Glycan_Production_Book#': 'Glycan Production Book#',
        Glycan_origin: 'Glycan origin',
        Glycan_concentration_mgmL: 'Glycan concentration',
        'Glycan_type(PD)': 'Glycan type (PD)',
        Biologic_concentration_mgmL: 'Biologic concentration',
        Media: 'Media',
        Replicate: 'Replicate',
        negative_control: 'Negative control',
        positive_control: 'Positive control',
        notes: 'Notes',
    };
    private optionalHeaderIndices = {
        'Glycan_Production_Book#': -1,
        Glycan_origin: -1,
        Glycan_concentration_mgmL: -1,
        'Glycan_type(PD)': -1,
        Biologic_concentration_mgmL: -1,
        Media: -1,
        Replicate: -1,
        negative_control: -1,
        positive_control: -1,
        notes: -1,
    };

    private static getTrayNameFromFileName(fileName: string): string {
        const m = fileName.match(
            DataImportValidatorTray.TRAY_METADATA_FILE_PATTERN
        );
        return m[1];
    }

    /**
     * Each row of this file type defines both a Sample and a Process. The Sample name is in the 'Micro_sampleID' column.
     * The Process is an Interaction, and all of the rest of the columns are the metadata for that Process.
     */
    map(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<MapperData> {
        const trayName = DataImportMapperTray.getTrayNameFromFileName(
            file.name
        );
        const header = data.getHeaderRow();
        const columnIdx = header.indexOf('Column');
        const rowIdx = header.indexOf('Row');
        const sampleIDIdx = header.indexOf('Micro_sampleID');
        const biologicIdx = header.indexOf('Biologic_source_ID');
        const glycanIdx = header.indexOf('Glycan_source_ID');
        const assayIdx = header.indexOf('Assay_Type');
        const study1Idx = header.indexOf('Study_1_ID');
        const study2Idx = header.indexOf('Study_2_ID');
        Object.keys(this.optionalHeaderIndices).forEach((key) => {
            this.optionalHeaderIndices[key] = header.indexOf(key);
        });

        const processes: { [key: string]: Process } = {};
        data.getDataRows()
            .filter((row) => {
                const e = row[biologicIdx] === 'E' && row[glycanIdx] === 'E';
                const na = row[biologicIdx] === 'NA' && row[glycanIdx] === 'NA';
                return !e && !na;
            })
            .forEach((row) => {
                const processKey = row[biologicIdx] + '_' + row[glycanIdx];
                if (processKey in processes) {
                    // Add the Cell in to the existing Metadata.
                    const process = processes[processKey];
                    const cells: CellData[] = JSON.parse(
                        process.metadata['Interaction']['Cells'] as string
                    );
                    cells.push({
                        replicate: row[rowIdx] + String(row[columnIdx]),
                        value:
                            String(row[assayIdx]) === 'E'
                                ? String(row[assayIdx])
                                : String(row[assayIdx]).toLowerCase(),
                    });
                    process.metadata['Interaction']['Cells'] =
                        JSON.stringify(cells);
                } else {
                    // Create new Process and associated Samples.
                    const parentSamples: Sample[] = [];
                    if (row[biologicIdx] !== 'NA') {
                        const biologicSample = new Sample();
                        biologicSample.id = row[biologicIdx];
                        parentSamples.push(biologicSample);
                    }
                    if (row[glycanIdx] !== 'NA') {
                        const glycanSample = new Sample();
                        glycanSample.id = row[glycanIdx];
                        parentSamples.push(glycanSample);
                    }
                    const childSample = new Sample();
                    childSample.id = '-' + row[sampleIDIdx]; // Will be prepended with BCD Id.

                    const studies: StudyNode[] = [];
                    if (row[study1Idx] !== 'null') {
                        studies.push({ studyId: row[study1Idx] });
                    }
                    if (row[study2Idx] !== 'null') {
                        studies.push({ studyId: row[study2Idx] });
                    }

                    // All the other columns are Metadata that is associated with this Process.
                    const cell = [
                        {
                            replicate: row[rowIdx] + String(row[columnIdx]),
                            value:
                                String(row[assayIdx]) === 'E'
                                    ? String(row[assayIdx])
                                    : String(row[assayIdx]).toLowerCase(),
                        },
                    ];
                    const interactionMetadata = {
                        'Tray Name': trayName,
                        Cells: JSON.stringify(cell),
                    };
                    Object.entries(this.optionalHeaderIndices).forEach(
                        ([headerKey, idx]) => {
                            if (idx > -1) {
                                interactionMetadata[
                                    this.optionalMetadataMap[headerKey]
                                ] = row[idx];
                            }
                        }
                    );
                    processes[processKey] = {
                        id: '',
                        type: 'Interaction',
                        parentSamples: parentSamples,
                        childSamples: [childSample],
                        studies: studies,
                        metadata: {
                            Interaction: interactionMetadata,
                        },
                    };
                }
            });

        const processesArray = Object.values(processes);
        return this.createBcdIdGQL.mutate({ n: processesArray.length }).pipe(
            map((result) => {
                result.data.create_bcd_id.forEach((row, i) => {
                    const p = processesArray[i];
                    p.id = row.bcd_id;
                    p.childSamples[0].id = row.bcd_id + p.childSamples[0].id;
                });
                return { processes: processesArray };
            })
        );
    }

    rowMap(headerRow: string[], row: string[]): Sample {
        throw new Error(
            'rowMap() should not be called on DataImportMapperTray'
        );
    }
}
