<form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form-inline">
    <label for="searchTerm" class="sr-only">Sample Search</label>
    <input
        type="text"
        class="form-control"
        id="searchTerm"
        placeholder="Enter search terms..."
        formControlName="searchTerm"
    />
    <button type="submit" class="btn btn-primary">Search</button>
</form>
