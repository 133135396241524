import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CreateStudyGQL,
    DeleteStudyGQL,
    EditStudyGQL,
    StudyDetailGQL,
    StudyListGQL,
    Study,
    Study_Set_Input,
    Study_Insert_Input,
    CreateBcdIdGQL,
    AddStudyToProjectGQL,
    DeleteStudyFromProjectGQL,
    DeleteStudyUrlInfoGQL,
    UpsertStudyUrlInfoGQL,
} from '@bcdbio/udb-graphql';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StudyApiService {
    constructor(
        private studyDetailGQL: StudyDetailGQL,
        private studyListGQL: StudyListGQL,
        private createStudyGQL: CreateStudyGQL,
        private editStudyGQL: EditStudyGQL,
        private deleteStudyGQL: DeleteStudyGQL,
        private createBcdIdGQL: CreateBcdIdGQL,
        private addStudyToProjectGQL: AddStudyToProjectGQL,
        private deleteStudyFromProjectGQL: DeleteStudyFromProjectGQL,
        private deleteStudyUrlInfoGQL: DeleteStudyUrlInfoGQL,
        private upsertStudyUrlInfoGQL: UpsertStudyUrlInfoGQL
    ) {}

    public createBcdId() {
        return this.createBcdIdGQL.mutate().pipe(
            map((result) => {
                return result.data.create_bcd_id[0].bcd_id;
            })
        );
    }

    public getStudyList(): Observable<Study[]> {
        return this.studyListGQL
            .fetch(
                {},
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.study as Study[];
                })
            );
    }

    public getStudyDetail(id: string): Observable<Study> {
        return this.studyDetailGQL
            .fetch(
                {
                    studyId: id,
                },
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.study_by_pk as Study;
                })
            );
    }

    public createNewStudy(
        study: Study_Insert_Input,
        studyId: string
    ): Observable<Study> {
        return this.createStudyGQL
            .mutate({
                study,
                studyId,
            })
            .pipe(
                map((results) => {
                    return results.data.insert_study_one as Study;
                })
            );
    }

    public editStudy(study: Study_Set_Input): Observable<any> {
        return this.editStudyGQL
            .mutate({
                studyId: study.studyId,
                study: study,
            })
            .pipe(
                map((results) => {
                    return results.data.update_study_by_pk;
                })
            );
    }

    public deleteStudy(studyId: string): Observable<{ studyId: string }> {
        return this.deleteStudyGQL
            .mutate({
                studyId,
            })
            .pipe(
                map((results) => {
                    return results.data.delete_study_by_pk;
                })
            );
    }

    public addStudyToProject(
        studyId: string,
        projectId: string
    ): Observable<any> {
        return this.addStudyToProjectGQL
            .mutate({
                studyId,
                projectId,
            })
            .pipe(
                map((results) => {
                    return results.data.insert_project_study_one;
                })
            );
    }

    public deleteStudyFromProject(studyProjectId: number): Observable<any> {
        return this.deleteStudyFromProjectGQL
            .mutate({
                studyProjectId,
            })
            .pipe(
                map((results) => {
                    return results.data.delete_project_study_by_pk;
                })
            );
    }

    public editStudyUrlInfo(deleted, updated): Observable<any> {
        return this.deleteStudyUrlInfoGQL
            .mutate({
                ids: deleted,
            })
            .pipe(
                switchMap((results) => {
                    return this.upsertStudyUrlInfoGQL
                        .mutate({
                            studyUrlInfo: updated,
                        })
                        .pipe(
                            map((data) => {
                                console.log('returned: ', data);
                                return data;
                            })
                        );
                })
            );
    }
}
