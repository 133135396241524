import { DataImportData, ImportMapping } from './data-import-data';
import { SampleType } from '../../model/sample.model';

export class DataImportDataFecalSource extends DataImportData {
    public static MAPPING = [
        {
            importColumnHeader: 'donor code',
            neo4jName: null,
            validation: 'string',
        },
        {
            importColumnHeader: 'donor number',
            neo4jName: 'donorNumber',
            validation: 'string',
        },
        {
            importColumnHeader: 'collection date',
            neo4jName: 'collectionDate',
            validation: 'date',
        },
        {
            importColumnHeader: 'expiration date',
            neo4jName: 'expirationDate',
            validation: 'date',
        },
        {
            importColumnHeader: 'storage location',
            neo4jName: 'storageLocation',
            validation: 'string',
        },
        {
            importColumnHeader: 'nda/mta',
            neo4jName: 'ndaMta',
            validation: 'regexp:Yes|No',
        },
        {
            importColumnHeader: 'unit id',
            neo4jName: 'unitId',
            validation: 'string',
        },
        {
            importColumnHeader: 'sex',
            neo4jName: 'sex',
            validation: 'string',
        },
        {
            importColumnHeader: 'type',
            neo4jName: 'type',
            validation: 'string',
        },
        {
            importColumnHeader: 'health status',
            neo4jName: 'healthStatus',
            validation: 'regexp:Healthy|Unhealthy|Unknown',
        },
        {
            importColumnHeader: 'aliquotes left',
            neo4jName: 'aliquotesLeft',
            validation: 'number',
        },
        {
            importColumnHeader: 'appearance',
            neo4jName: 'appearance',
            validation: 'regexp:Normal|Irregular',
        },
        {
            importColumnHeader: 'notes',
            neo4jName: 'notes',
            validation: 'string',
        },
    ] as ImportMapping[];

    constructor(data: Array<string[]>) {
        super(data);
        this.headers = DataImportDataFecalSource.MAPPING.map(
            (m) => m.importColumnHeader
        );
        this.mapping = DataImportDataFecalSource.MAPPING;
    }

    getName(headers: string[], row: string[]) {
        return (
            row[headers.indexOf('donor code')] +
            '-' +
            row[headers.indexOf('donor number')]
        );
    }
    getType() {
        return SampleType.FecalSource;
    }
}
