import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from '@bcdbio/udb-graphql';

@Component({
    selector: 'bcdbio-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
    @Input() projects: Project[];
    @Output() clickCreateNew = new EventEmitter<boolean>();
    constructor() {}

    ngOnInit(): void {}
}
