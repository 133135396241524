import { DataImportValidator } from './data-import-validator';
import { DataImportType } from '../data-import-type';
import { SamplesByBcdIdGQL, SamplesByTrayCellGQL } from '@bcdbio/udb-graphql';
import { DataImportData } from '../data/data-import-data';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { DataImportDataFastqMapping } from '../data/data-import-data-fastq-mapping';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DataImportValidatorFastqMapping extends DataImportValidator {
    constructor(
        private samplesByBcdIdGQL: SamplesByBcdIdGQL,
        private samplesByTrayCellGQL: SamplesByTrayCellGQL
    ) {
        super();
    }

    private static collectSampleIds(
        headerRow: string[],
        dataRows: Array<string[]>
    ): string[] {
        const sampleIds: Set<string> = new Set<string>();
        const idIdx = headerRow.indexOf('SampleID');
        for (let i = 0, n = dataRows.length; i < n; i++) {
            const sampleId = dataRows[i][idIdx];
            if (sampleId && sampleId !== 'null') {
                sampleIds.add(sampleId.trim());
            }
        }
        return Array.from(sampleIds);
    }

    private validateHeaders(headerRow: string[], errors: any[]): void {
        DataImportDataFastqMapping.HEADERS.forEach((h) => {
            if (!headerRow.includes(h)) {
                errors.push(`Missing header: '${h}'.`);
            }
        });
    }

    private validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): Observable<boolean> {
        const trayCellObs: Observable<boolean>[] = [];
        const trayIdx = headerRow.indexOf('Tray.Name');
        const cellIdx = headerRow.indexOf('Sample.Name');
        for (let i = 0, n = dataRows.length; i < n; i++) {
            const trayName = dataRows[i][trayIdx];
            const cell = dataRows[i][cellIdx];
            if (trayName !== 'null' && cell !== 'null') {
                trayCellObs.push(
                    this.samplesByTrayCellGQL
                        .fetch({ trayName: trayName, cellId: cell })
                        .pipe(
                            tap((result) => {
                                if (
                                    result.data.samplesByTrayCell.length === 0
                                ) {
                                    errors.push(
                                        `No Sample found for Tray Name '${trayName}' and Cell '${cell}'.`
                                    );
                                }
                            }),
                            mapTo(true)
                        )
                );
            }
        }
        return forkJoin(trayCellObs).pipe(
            defaultIfEmpty(null),
            switchMap(() => {
                const sampleIds: string[] =
                    DataImportValidatorFastqMapping.collectSampleIds(
                        headerRow,
                        dataRows
                    );
                return this.samplesByBcdIdGQL.fetch({ bcdIds: sampleIds }).pipe(
                    tap((result) => {
                        const indexedSamples: Set<string> = new Set<string>();
                        result.data.samples.forEach((s) => {
                            indexedSamples.add(s.bcdId);
                        });
                        sampleIds.forEach((id) => {
                            if (!indexedSamples.has(id)) {
                                errors.push(`Sample does not exist: '${id}'.`);
                            }
                        });
                    }),
                    mapTo(true)
                );
            })
        );
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        this.validateHeaders(headerRow, errors);
        return this.validateDataRows(headerRow, rows, errors).pipe(
            map(() => {
                return {
                    isValid: errors.length === 0,
                    errors: errors,
                };
            })
        );
    }
}
