<div class="border rounded p-3 bg-light mt-3">
    <div class="row">
        <div class="col-sm-6">
            <h3>Source sample info</h3>
        </div>
    </div>
    <h4>Metadata</h4>
    <ng-container *ngIf="hasMetaData(sample); else elseBlock">
        <div class="metadata-group">
            <ng-container *ngFor="let field of orderedMetadata">
                <div *ngIf="field.value && field.name !== 'ndaMta'">
                    <label class="metadata-label"
                        >{{ field.name | unCamelCase }}:</label
                    >
                    <p class="metadata-value">
                        {{ displayMetadataValue(field.value) }}
                    </p>
                </div>
                <div
                    *ngIf="field.value && field.name === 'ndaMta'"
                    class="emphasize-metadata"
                >
                    <label class="metadata-label">NDA/MTA:</label>
                    <p class="metadata-value">{{ field.value || '--' }}</p>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <p>No metadata present.</p>
    </ng-template>
</div>
