import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddProjectUserAssignmentsGQL,
    AddRelatedSampleToProjectGQL,
    CreateProjectGQL,
    DeleteProjectGQL,
    DeleteProjectUrlInfoGQL,
    DeleteProjectUserAssignmentsGQL,
    DeleteRelatedSampleFromProjectGQL,
    EditProjectGQL,
    Project,
    ProjectDetailGQL,
    ProjectListGQL,
    Role,
    RolesListGQL,
    UpsertProjectUrlInfoGQL,
    User,
    UsersListGQL,
} from '@bcdbio/udb-graphql';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProjectApiService {
    constructor(
        private projectDetailGQL: ProjectDetailGQL,
        private projectListGQL: ProjectListGQL,
        private createProjectGQL: CreateProjectGQL,
        private editProjectGQL: EditProjectGQL,
        private deleteProjectUserAssignmentsGQL: DeleteProjectUserAssignmentsGQL,
        private addProjectUserAssignmentsGQL: AddProjectUserAssignmentsGQL,
        private deleteProjectGQL: DeleteProjectGQL,
        private usersListGQL: UsersListGQL,
        private rolesListGQL: RolesListGQL,
        private deleteProjectUrlInfoGQL: DeleteProjectUrlInfoGQL,
        private upsertProjectUrlInfoGQL: UpsertProjectUrlInfoGQL,
        private deleteRelatedSampleFromProjectGQL: DeleteRelatedSampleFromProjectGQL,
        private addRelatedSampleToProjectGQL: AddRelatedSampleToProjectGQL
    ) {}
    public getProjectDetail(id: string): Observable<Project> {
        return this.projectDetailGQL
            .fetch(
                {
                    projectId: id,
                },
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.project_by_pk as Project;
                })
            );
    }

    public getProjectList(): Observable<Project[]> {
        return this.projectListGQL
            .fetch(
                {},
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.project as Project[];
                })
            );
    }

    public createNewProject(
        project:
            | ({ __typename?: 'project' } & Pick<
                  Project,
                  | 'projectId'
                  | 'projectName'
                  | 'client'
                  | 'objective'
                  | 'startDate'
                  | 'completionDate'
                  | 'notes'
                  | 'finalReportUrl'
              >)
            | null
    ): Observable<Project> {
        return this.createProjectGQL
            .mutate({
                project: project,
            })
            .pipe(
                map((results) => {
                    return results.data.insert_project_one as Project;
                })
            );
    }

    public editProject(
        project: Project
    ): Observable<
        | ({ __typename?: 'project' } & Pick<
              Project,
              | 'projectId'
              | 'projectName'
              | 'client'
              | 'objective'
              | 'startDate'
              | 'completionDate'
              | 'notes'
              | 'finalReportUrl'
          >)
        | null
    > {
        return this.editProjectGQL
            .mutate({
                projectId: project.projectId,
                project: project,
            })
            .pipe(
                map((results) => {
                    return results.data.update_project_by_pk;
                })
            );
    }

    public editProjectUsers(deleted, added): Observable<any> {
        return this.deleteProjectUserAssignmentsGQL
            .mutate({
                ids: deleted,
            })
            .pipe(
                switchMap((results) => {
                    return this.addProjectUserAssignmentsGQL
                        .mutate({
                            user_assignments: added,
                        })
                        .pipe(
                            map((data) => {
                                console.log('returned: ', data);
                                return data;
                            })
                        );
                })
            );
    }

    public deleteProject(projectId: string): Observable<{ projectId: string }> {
        return this.deleteProjectGQL
            .mutate({
                projectId,
            })
            .pipe(
                map((results) => {
                    return results.data.delete_project_by_pk;
                })
            );
    }

    public getUserList(): Observable<User[]> {
        return this.usersListGQL
            .fetch(
                {},
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.user as User[];
                })
            );
    }

    public getRolesList(): Observable<Role[]> {
        return this.rolesListGQL
            .fetch(
                {},
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    return results.data.role as Role[];
                })
            );
    }

    public editProjectUrlInfo(deleted, updated): Observable<any> {
        return this.deleteProjectUrlInfoGQL
            .mutate({
                ids: deleted,
            })
            .pipe(
                switchMap((results) => {
                    return this.upsertProjectUrlInfoGQL
                        .mutate({
                            projectUrlInfo: updated,
                        })
                        .pipe(
                            map((data) => {
                                console.log('returned: ', data);
                                return data;
                            })
                        );
                })
            );
    }

    public deleteRelatedSampleFromProject(projectSampleId): Observable<any> {
        return this.deleteRelatedSampleFromProjectGQL
            .mutate({
                projectSampleId,
            })
            .pipe(
                map((results) => {
                    return results.data.delete_project_sample_by_pk;
                })
            );
    }

    public addRelatedSampleToProject(sampleBcdId, projectId): Observable<any> {
        return this.addRelatedSampleToProjectGQL
            .mutate({
                projectId: projectId,
                sampleBcdId: sampleBcdId,
            })
            .pipe(
                map((results) => {
                    console.log('mutation results in service: ', results);
                    return results.data.insert_project_sample_one;
                })
            );
    }
}
