import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    addRelatedSampleToProject,
    clearRelatedSamplesSearchResults,
    getRelatedSamplesSearchQueryParameters,
    loadMoreRelatedSamplesSearchResults,
    relatedSamplesSearchResultsRequested,
    UiPartialState,
} from '@bcdbio/ui';
import { Sample } from '@bcdbio/data';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RelatedSamplesFullTextSearchGQL, Project } from '@bcdbio/udb-graphql';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bcdbio-related-samples-edit',
    templateUrl: './related-samples-edit.component.html',
    styleUrls: ['./related-samples-edit.component.scss'],
})
export class RelatedSamplesEditComponent implements OnInit, OnDestroy {
    project: Project;
    searchQuery$: Observable<{
        searchTerm: string;
        searchQueryMaxResults: number;
    }> = this.storeUi.select(getRelatedSamplesSearchQueryParameters);
    searchType: String;

    readonly RESULTS_PER_SEARCH: number = 10;
    currentNumberOfResults: number;
    searchResultsExhausted = false;
    currentMaxResults: number = this.RESULTS_PER_SEARCH;

    searchResults: Sample[];
    subscriptions: Subscription = new Subscription();

    constructor(
        private storeUi: Store<UiPartialState>,
        private relatedSamplesFullTextSearchGQL: RelatedSamplesFullTextSearchGQL,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.searchQuery$
                .pipe(
                    tap((searchQuery) => {
                        this.currentMaxResults =
                            searchQuery.searchQueryMaxResults;
                        console.log({ searchQuery });
                    }),
                    filter((searchQuery) => searchQuery.searchTerm !== ''),
                    switchMap((searchQuery) => {
                        console.log({ searchQuery });
                        return this.relatedSamplesFullTextSearchGQL.fetch({
                            first: searchQuery.searchQueryMaxResults,
                            searchString: searchQuery.searchTerm,
                        });
                    }),
                    map((results) => {
                        console.log({ results });
                        return results.data.relatedSamplesFullTextSearch.map(
                            (gqlData) => {
                                return Sample.fromGQLData(gqlData);
                            }
                        );
                    })
                )
                .subscribe((results) => {
                    this.searchResultsExhausted =
                        this.areSearchResultsExhausted(
                            results,
                            this.currentMaxResults
                        );
                    this.currentNumberOfResults = results.length;
                    this.searchResults = results;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    searchForTerm(searchTerm: string) {
        this.storeUi.dispatch(
            relatedSamplesSearchResultsRequested({
                searchTerm: searchTerm,
                maxResults: this.RESULTS_PER_SEARCH,
            })
        );
    }

    getMoreResults(requestedMoreSearchResults: boolean) {
        this.storeUi.dispatch(
            loadMoreRelatedSamplesSearchResults({
                maxResults:
                    this.currentNumberOfResults + this.RESULTS_PER_SEARCH,
            })
        );
    }

    areSearchResultsExhausted(
        searchResults: Sample[],
        maxResults: number
    ): boolean {
        if (searchResults == null || maxResults == null) {
            return false;
        } else {
            return searchResults.length < maxResults;
        }
    }

    sampleIsNotCurrentlyRelated(resultSampleId: string): boolean {
        return (
            this.project.relatedSamples &&
            !this.project.relatedSamples.find((rs) => {
                return rs && rs.sampleBcdId === resultSampleId;
            })
        );
    }

    addRelatedSample(resultSampleId: string) {
        this.storeUi.dispatch(
            addRelatedSampleToProject({
                sampleBcdId: resultSampleId,
                projectId: this.project.projectId as string,
            })
        );
    }
    close() {
        this.modalService.hide();
    }
}
