import { Component, Input } from '@angular/core';
import { MetadataDisplay } from '../sample-metadata/sample-metadata.component';

@Component({
    selector: 'bcdbio-pool-sources-readonly',
    templateUrl: './pool-sources-readonly.component.html',
    styleUrls: ['./pool-sources-readonly.component.scss'],
})
export class PoolSourcesReadonlyComponent {
    @Input() orderedMetadata: MetadataDisplay[][];
}
