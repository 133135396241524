import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnChanges,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'bcdbio-url-info-widget',
    templateUrl: './url-info-widget.component.html',
    styleUrls: ['./url-info-widget.component.scss'],
})
export class UrlInfoWidgetComponent implements OnChanges {
    @Input() widgetLabel = 'Additional Info';
    @Input() editModeEnabled: boolean;
    @Input() existingUrlInfo: { id: number; label: string; url: string }[];
    @Input() urlInfoToDelete: number[];
    @Input() urlInfoToAdd: {
        index: number;
        label: string;
        url: string;
        deletePending: boolean;
    }[];
    @Input() isUrlInfoListDirty: boolean;

    @Output() clickAddUrl = new EventEmitter<boolean>();
    @Output() toggleExistingUrlInfo = new EventEmitter<number>();
    @Output() toggleAddedUrlInfo = new EventEmitter<number>();
    @Output() mergeUrlInfo = new EventEmitter<{
        id: number;
        index: number;
        label: string;
        url: string;
    }>();

    modalRef: BsModalRef;
    modalForm = new FormGroup({
        id: new FormControl(''),
        index: new FormControl(-1),
        label: new FormControl('', Validators.required),
        url: new FormControl('', [Validators.required]),
    });
    isAddUrl = false;
    gotUrls = false;
    constructor(private modalService: BsModalService) {}

    ngOnChanges(): void {
        if (this.existingUrlInfo.length > 0) {
            this.gotUrls = true;
            this.existingUrlInfo.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                else return -1;
            });
        }
    }

    openMergeUrlModal(
        template,
        i = -1,
        existingUrlInfo = null,
        urlInfoToAdd = null
    ) {
        this.clickAddUrl.emit(true);
        if (existingUrlInfo) {
            this.modalForm.patchValue({
                id: existingUrlInfo.id,
                index: i,
                label: existingUrlInfo.label,
                url: existingUrlInfo.url,
            });
        } else if (urlInfoToAdd) {
            this.modalForm.patchValue({
                id: null,
                index: i,
                label: urlInfoToAdd.label,
                url: urlInfoToAdd.url,
            });
        } else {
            this.modalForm.patchValue({
                id: null,
                index: -1,
                label: '',
                url: '',
            });
            this.isAddUrl = true;
        }
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }

    onSaveClick() {
        this.modalRef.hide();
        this.isAddUrl = false;
        this.mergeUrlInfo.emit(this.modalForm.value as any);
    }

    onCancelClick() {
        this.modalRef.hide();
        this.clickAddUrl.emit(false);
        this.isAddUrl = false;
    }
}
