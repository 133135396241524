import { Component, Input, OnChanges } from '@angular/core';
import { Sample, SampleType } from '@bcdbio/data';
import { MetadataDisplay } from '../sample-metadata/sample-metadata.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PoolSourcesEditComponent } from '../pool-sources-edit/pool-sources-edit.component';

const DISPLAYED_POOL_SOURCES_METADATA = [
    'sourceName',
    'concentration',
    'ulAdded',
];

const FECAL_POOL_CALCULATED_METADATA = ['sex', 'healthStatus'];

@Component({
    selector: 'bcdbio-pool-sources',
    templateUrl: './pool-sources.component.html',
    styleUrls: ['./pool-sources.component.scss'],
})
export class PoolSourcesComponent implements OnChanges {
    @Input() sample: Sample;
    metadataHeaders: string[];
    calculatedMetadata: string[];
    orderedMetadata: MetadataDisplay[][];
    bsModalRef: BsModalRef;
    editMode = false;

    constructor(private modalService: BsModalService) {}

    ngOnChanges(): void {
        this.calculatedMetadata =
            this.sample.type === SampleType.FecalPool
                ? FECAL_POOL_CALCULATED_METADATA
                : [];
        this.orderedMetadata = this.orderPoolInputMetadata(this.sample);
        this.metadataHeaders = [
            ...DISPLAYED_POOL_SOURCES_METADATA,
            ...this.calculatedMetadata,
        ];
    }

    orderPoolInputMetadata(sample: Sample): MetadataDisplay[][] {
        const sourcesEditableMetadata = this.getSourcesEditableMetadata(sample);
        return sample.type === SampleType.FecalPool
            ? this.combineAncestorMetadata(sample, sourcesEditableMetadata)
            : sourcesEditableMetadata;
    }

    getSourcesEditableMetadata(sample: Sample): MetadataDisplay[][] {
        return sample.poolInputMetadata.map((metadataForInput) =>
            DISPLAYED_POOL_SOURCES_METADATA.reduce((acc, m) => {
                const obj = {
                    name: m,
                    value: metadataForInput[m] || ' ',
                } as MetadataDisplay;
                acc.push(obj);
                return acc;
            }, [])
        );
    }

    combineAncestorMetadata(
        sample: Sample,
        sourcesEditableMetadata: MetadataDisplay[][]
    ): MetadataDisplay[][] {
        return sourcesEditableMetadata.map((sourceEditableMD) => {
            const poolSourceName = (sourceEditableMD as any).find(
                (md) => md.name === 'sourceName'
            ).value;
            const poolSourceParentMetadata = sample.ancestorSamples.find(
                (as) => as.name === poolSourceName
            ).parentSamples[0].metadata;
            const calculatedMD =
                this.calculatedMetadata &&
                this.calculatedMetadata.map((calcMD) => {
                    return {
                        name: calcMD,
                        value:
                            (poolSourceParentMetadata as any).find(
                                (md) => md.name === calcMD
                            ).value || ' ',
                    };
                });
            return [...sourceEditableMD, ...calculatedMD];
        });
    }

    finishEditMode() {
        this.editMode = false;
    }

    editPoolSources(): void {
        this.editMode = true;
    }

    addPoolSource(): void {
        const initialState = {
            sample: this.sample,
        };
        this.bsModalRef = this.modalService.show(PoolSourcesEditComponent, {
            initialState: initialState,
            class: 'modal-lg',
        });
    }
}
