<button
    *ngIf="primaryButtonStyle; else isSecondaryButtonStyle"
    class="btn btn-outline-primary"
    type="button"
    (click)="exportSamples()"
    [disabled]="!samplesToExport || samplesToExport?.length === 0"
>
    {{ buttonLabel }}
</button>
<ng-template #isSecondaryButtonStyle>
    <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="exportSamples()"
        [disabled]="!samplesToExport || samplesToExport?.length === 0"
    >
        {{ buttonLabel }}
    </button>
</ng-template>
