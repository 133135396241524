import { Injectable } from '@angular/core';
import { Sample } from './model/sample.model';
import {
    CalculateCurvesGQL,
    CreateBcdIdGQL,
    CreateFastqMappingMeasurementGQL,
    CreateFileTypeMeasurementGQL,
    CreateGrowthCurveMeasurementGQL,
    CreateInteractionProcessAndSampleGQL,
    CreateProcessAndSampleGQL,
    CreateSampleLinkageMeasurementGQL,
    CreateSampleMetabAnalytesMeasurementGQL,
    CreateSampleMonoOrFreeMonoMeasurementGQL,
    DeleteMeasurementGQL,
    DeleteSampleUrlInfoGQL,
    MergeCalculatedCurvesGQL,
    MergeProcessMetadataGQL,
    MergeSampleMetadataGQL,
    MergeSourceSampleGQL,
    MetadataGroupGQL,
    SampleDetailGQL,
    SampleTypesMetadataGQL,
    UpsertSampleUrlInfoGQL,
} from '@bcdbio/udb-graphql';
import {
    catchError,
    filter,
    finalize,
    map,
    mapTo,
    mergeMap,
    reduce,
    retryWhen,
    switchMap,
} from 'rxjs/operators';
import { from, Observable, of, throwError, timer } from 'rxjs';
import { SampleSaveResult, SaveResult } from './data-import/sample-save-result';
import { formatDate } from '@angular/common';
import { MapperData } from './data-import/mapping/mapper-data';
import { DataImportType } from './data-import/data-import-type';
import { GraphQLError } from 'graphql';

const genericRetryStrategy =
    ({
        maxRetryAttempts = 4,
        scalingDuration = 1000,
        excludedStatusCodes = [],
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        excludedStatusCodes?: number[];
    } = {}) =>
    (attempts: Observable<any>) => {
        return attempts.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;
                // if maximum number of retries have been met
                // or response is a status code we don't wish to retry, throw error
                if (
                    retryAttempt > maxRetryAttempts ||
                    excludedStatusCodes.find((e) => e === error.status)
                ) {
                    return throwError(error);
                }
                console.log(
                    `Attempt ${retryAttempt}: retrying in ${
                        retryAttempt * scalingDuration
                    }ms`
                );
                // retry after 1s, 2s, etc...
                return timer(retryAttempt * scalingDuration);
            }),
            finalize(() => console.log('We are done!'))
        );
    };
const LINKAGE_NAMES = {
    v2Allose: '2-Allose',
    v2Deoxyhexose: '2-Deoxyhexose',
    v2FArabinose: '2-F-Arabinose',
    v2Fucose: '2-Fucose',
    v2Galactose: '2-Galactose',
    v2Glucose: '2-Glucose',
    v2GlucuronicAcid: '2-Glucuronic Acid',
    v2Mannose: '2-Mannose',
    v2PXylose: '2-P-Xylose',
    v2PentoseA: '2-Pentose a',
    v2PentoseB: '2-Pentose b',
    v2PentoseC: '2-Pentose c',
    v2PentoseD: '2-Pentose d',
    v2PentoseE: '2-Pentose e',
    v2PentoseF: '2-Pentose f',
    v2PentoseG: '2-Pentose g',
    v2PentoseH: '2-Pentose h',
    v2PentoseI: '2-Pentose i',
    v2PentoseJ: '2-Pentose j',
    v2PentoseK: '2-Pentose k',
    v2PentoseL: '2-Pentose l',
    v2PentoseM: '2-Pentose m',
    v2PentoseN: '2-Pentose n',
    v2PentoseO: '2-Pentose o',
    v2PentoseP: '2-Pentose p',
    v2Rhamnose: '2-Rhamnose',
    v2Ribose: '2-Ribose',
    v2XDeoxyhexose: '2,X-Deoxyhexose',
    v2XGlucoseA: '2,X-Glucose a',
    v2XGlucoseI: '2,X-Glucose (I)',
    v2XHexoseA: '2,X-Hexose a',
    v2XHexoseB: '2,X-Hexose b',
    v2XHexoseC: '2,X-Hexose c',
    v2XHexoseD: '2,X-Hexose d',
    v2XHexoseE: '2,X-Hexose e',
    v2XHexoseF: '2,X-Hexose f',
    v2XHexoseG: '2,X-Hexose g',
    v2XHexoseH: '2,X-Hexose h',
    v2XHexoseI: '2,X-Hexose (I)',
    v2XHexoseII: '2,X-Hexose (II)',
    v2XHexoseIII: '2,X-Hexose (III)',
    v2XHexoseIV: '2,X-Hexose (IV)',
    v2XHexoseJ: '2,X-Hexose j',
    v2XHexoseK: '2,X-Hexose k',
    v2XHexoseL: '2,X-Hexose l',
    v2XHexoseM: '2,X-Hexose m',
    v2XHexoseN: '2,X-Hexose n',
    v2XHexoseO: '2,X-Hexose o',
    v2XHexoseP: '2,X-Hexose p',
    v2XHexoseV: '2,X-Hexose (V)',
    v2XHexosei: '2,X-Hexose i',
    v2XPentoseA: '2,X-Pentose a',
    v2XPentoseB: '2,X-Pentose b',
    v2XPentoseC: '2,X-Pentose c',
    v2XPentoseD: '2,X-Pentose d',
    v2XPentoseE: '2,X-Pentose e',
    v2XPentoseF: '2,X-Pentose f',
    v2XPentoseG: '2,X-Pentose g',
    v2XPentoseH: '2,X-Pentose h',
    v2XPentoseI: '2,X-Pentose (I)',
    v2XPentoseII: '2,X-Pentose (II)',
    v2XPentoseIII: '2,X-Pentose (III)',
    v2XPentoseIV: '2,X-Pentose (IV)',
    v2XPentoseJ: '2,X-Pentose j',
    v2XPentoseK: '2,X-Pentose k',
    v2XPentoseL: '2,X-Pentose l',
    v2XPentoseM: '2,X-Pentose m',
    v2XPentoseN: '2,X-Pentose n',
    v2XPentoseO: '2,X-Pentose o',
    v2XPentoseP: '2,X-Pentose p',
    v2XPentoseV: '2,X-Pentose (V)',
    v2XPentoseVI: '2,X-Pentose (VI)',
    v2XPentosei: '2,X-Pentose i',
    v2XXHexoseA: '2,X,X-Hexose a',
    v2XXHexoseB: '2,X,X-Hexose b',
    v2XXHexoseC: '2,X,X-Hexose c',
    v2XXHexoseD: '2,X,X-Hexose d',
    v2XXHexoseE: '2,X,X-Hexose e',
    v2XXHexoseF: '2,X,X-Hexose f',
    v2XXHexoseG: '2,X,X-Hexose g',
    v2XXHexoseH: '2,X,X-Hexose h',
    v2XXHexoseI: '2,X,X-Hexose (I)',
    v2XXHexoseII: '2,X,X-Hexose (II)',
    v2XXHexoseIII: '2,X,X-Hexose (III)',
    v2XXHexoseJ: '2,X,X-Hexose j',
    v2XXHexoseK: '2,X,X-Hexose k',
    v2XXHexoseL: '2,X,X-Hexose l',
    v2XXHexoseM: '2,X,X-Hexose m',
    v2XXHexoseN: '2,X,X-Hexose n',
    v2XXHexoseO: '2,X,X-Hexose o',
    v2XXHexoseP: '2,X,X-Hexose p',
    v2XXHexosei: '2,X,X-Hexose i',
    v2XXRhamnose: '2,X,X-Rhamnose',
    v2Xylose: '2-Xylose',
    v3Galactose: '3-Galactose',
    v3Glucose: '3-Glucose',
    v3Mannose: '3-Mannose',
    v3Or4Mannose: '3/4-Mannose',
    v3PArabinose: '3-P-Arabinose',
    v4Galactose: '4-Galactose',
    v4GlcA: '4-GlcA',
    v4Glucose: '4-Glucose',
    v4Mannose: '4-Mannose',
    v4PXylose: '4-P-Xylose',
    v4Rhamnose: '4-Rhamnose',
    v4Xylose: '4-Xylose',
    v4XyloseOr5Arabinose: '4-Xylose/5-Arabinose',
    v5Arabinose: '5-Arabinose',
    v5FArabinose: '5-F-Arabinose',
    v6Galactose: '6-Galactose',
    v6Glucose: '6-Glucose',
    v6Mannose: '6-Mannose',
    v24Xylose: '2,4-Xylose',
    v25Arabinose: '2,5-Arabinose',
    v34FArabinose: '3,4-F-Arabinose',
    v34Fucose: '3,4-Fucose',
    v34Galactose: '3,4-Galactose',
    v34GalactoseOr36Mannose: '3,4-Galactose/3,6-Mannose',
    v34GalacturonicAcid: '3,4-Galacturonic Acid',
    v34GlucuronicAcid: '3,4-Glucuronic Acid',
    v34PArabinose: '3,4-P-Arabinose',
    v34PXylose: '3,4-P-Xylose',
    v34Rhamnose: '3,4-Rhamnose',
    v34Ribose: '3,4-Ribose',
    v34Xylose: '3,4-Xylose',
    v34XyloseOr35Arabinose: '3,4-Xylose/3,5-Arabinose',
    v35Arabinose: '3,5-Arabinose',
    v36Galactose: '3,6-Galactose',
    v36Mannose: '3,6-Mannose',
    v46Glucose: '4,6-Glucose',
    v46Mannose: '4,6-Mannose',
    v234Xylose: '2,3,4-Xylose',
    v235Arabinose: '2,3,5-Arabinose',
    v346Allose: '3,4,6-Allose',
    v346Galactose: '3,4,6-Galactose',
    v346Glucose: '3,4,6-Glucose',
    v36Glucose: '3,6-Glucose',
    v346HexoseI: '3,4,6-Hexose (I)',
    v346HexoseII: '3,4,6-Hexose (II)',
    v346HexoseIII: '3,4,6-Hexose (III)',
    v346HexoseIV: '3,4,6-Hexose (IV)',
    v346HexoseV: '3,4,6-Hexose (V)',
    v346Mannose: '3,4,6-Mannose',
    vTAllose: 'T-Allose',
    vTArabinoseF: 'T-Arabinose-f',
    vTArabinoseFOr4Rhamnose: 'T-Arabinose-f/4-Rhamnose',
    vTArabinoseP: 'T-Arabinose-p',
    vTFArabinose: 'T-F-Arabinose',
    vTFXylose: 'T-F-Xylose',
    vTFructose: 'T-Fructose',
    vTFucose: 'T-Fucose',
    vTFucoseA: 'T-Fucose a',
    vTFucoseAOrTRhamnose: 'T-Fucose a/T-Rhamnose',
    vTFucoseB: 'T-Fucose b',
    vTGalAA: 'T-GalA a',
    vTGalAB: 'T-GalA b',
    vTGalactose: 'T-Galactose',
    vTGalactoseF: 'T-Galactose-f',
    vTGalactoseP: 'T-Galactose-p',
    vTGlcA: 'T-GlcA',
    vTGlucose: 'T-Glucose',
    vTGlucoseF: 'T-Glucose-f',
    vTGlucoseOrTMannose: 'T-Glucose/T-Mannose',
    vTGlucoseP: 'T-Glucose-p',
    vTHexuronicAcidI: 'T-Hexuronic Acid (I)',
    vTHexuronicAcidII: 'T-Hexuronic Acid (II)',
    vTMannose: 'T-Mannose',
    vTPArabinose: 'T-P-Arabinose',
    vTPXylose: 'T-P-Xylose',
    vTRhamnose: 'T-Rhamnose',
    vTRibose: 'T-Ribose',
    vTRiboseA: 'T-Ribose a',
    vTRiboseAOrTArabinoseP: 'T-Ribose a/T-Arabinose-p',
    vTRiboseB: 'T-Ribose b',
    vTXylose: 'T-Xylose',
    vXDeoxyhexoseI: 'X-Deoxyhexose (I)',
    vXDeoxyhexoseII: 'X-Deoxyhexose (II)',
    vXDeoxyhexoseIII: 'X-Deoxyhexose (III)',
    vXDeoxyhexoseIV: 'X-Deoxyhexose (IV)',
    vXDeoxyhexoseV: 'X-Deoxyhexose (V)',
    vXDeoxyhexoseVI: 'X-Deoxyhexose (VI)',
    vXDeoxyhexoseVII: 'X-Deoxyhexose (VII)',
    vXDeoxyhexoseVIII: 'X-Deoxyhexose (VIII)',
    vXFucoseA: 'X-Fucose a',
    vXHexose: 'X-Hexose',
    vXHexoseA: 'X-Hexose a',
    vXHexoseB: 'X-Hexose b',
    vXHexoseC: 'X-Hexose c',
    vXHexoseD: 'X-Hexose d',
    vXHexoseE: 'X-Hexose e',
    vXHexoseF: 'X-Hexose f',
    vXHexoseG: 'X-Hexose g',
    vXHexoseH: 'X-Hexose h',
    vXHexoseI: 'X-Hexose (I)',
    vXHexoseII: 'X-Hexose (II)',
    vXHexoseJ: 'X-Hexose j',
    vXHexoseK: 'X-Hexose k',
    vXHexoseL: 'X-Hexose l',
    vXHexoseM: 'X-Hexose m',
    vXHexoseN: 'X-Hexose n',
    vXHexoseO: 'X-Hexose o',
    vXHexoseP: 'X-Hexose p',
    vXHexosei: 'X-Hexose i',
    vXHexuronicI: 'X-Hexuronic (I)',
    vXHexuronicII: 'X-Hexuronic (II)',
    vXHexuronicIII: 'X-Hexuronic (III)',
    vXHexuronicIV: 'X-Hexuronic (IV)',
    vXHexuronicV: 'X-Hexuronic (V)',
    vXPentoseA: 'X-Pentose a',
    vXPentoseB: 'X-Pentose b',
    vXPentoseC: 'X-Pentose c',
    vXPentoseD: 'X-Pentose d',
    vXPentoseE: 'X-Pentose e',
    vXPentoseF: 'X-Pentose f',
    vXPentoseG: 'X-Pentose g',
    vXPentoseH: 'X-Pentose h',
    vXPentoseI: 'X-Pentose (I)',
    vXPentoseII: 'X-Pentose (II)',
    vXPentoseIII: 'X-Pentose (III)',
    vXPentoseJ: 'X-Pentose j',
    vXPentoseK: 'X-Pentose k',
    vXPentoseL: 'X-Pentose l',
    vXPentoseM: 'X-Pentose m',
    vXPentoseN: 'X-Pentose n',
    vXPentoseO: 'X-Pentose o',
    vXPentoseP: 'X-Pentose p',
    vXPentosei: 'X-Pentose i',
    vXXGlucoseI: 'X,X-Glucose (I)',
    vXXGlucoseII: 'X,X-Glucose (II)',
    vXXHexoseA: 'X,X-Hexose a',
    vXXHexoseB: 'X,X-Hexose b',
    vXXHexoseC: 'X,X-Hexose c',
    vXXHexoseD: 'X,X-Hexose d',
    vXXHexoseE: 'X,X-Hexose e',
    vXXHexoseF: 'X,X-Hexose f',
    vXXHexoseG: 'X,X-Hexose g',
    vXXHexoseH: 'X,X-Hexose h',
    vXXHexoseI: 'X,X-Hexose (I)',
    vXXHexoseII: 'X,X-Hexose (II)',
    vXXHexoseIII: 'X,X-Hexose (III)',
    vXXHexoseJ: 'X,X-Hexose j',
    vXXHexoseK: 'X,X-Hexose k',
    vXXHexoseL: 'X,X-Hexose l',
    vXXHexoseM: 'X,X-Hexose m',
    vXXHexoseN: 'X,X-Hexose n',
    vXXHexoseO: 'X,X-Hexose o',
    vXXHexoseP: 'X,X-Hexose p',
    vXXHexosei: 'X,X-Hexose i',
    vXXPentoseA: 'X,X-Pentose a',
    vXXPentoseB: 'X,X-Pentose b',
    vXXPentoseC: 'X,X-Pentose c',
    vXXPentoseD: 'X,X-Pentose d',
    vXXPentoseE: 'X,X-Pentose e',
    vXXPentoseF: 'X,X-Pentose f',
    vXXPentoseG: 'X,X-Pentose g',
    vXXPentoseH: 'X,X-Pentose h',
    vXXPentoseI: 'X,X-Pentose i',
    vXXPentoseJ: 'X,X-Pentose j',
    vXXPentoseK: 'X,X-Pentose k',
    vXXPentoseL: 'X,X-Pentose l',
    vXXPentoseM: 'X,X-Pentose m',
    vXXPentoseN: 'X,X-Pentose n',
    vXXPentoseO: 'X,X-Pentose o',
    vXXPentoseP: 'X,X-Pentose p',
    vXXXHexoseA: 'X,X,X-Hexose a',
    vXXXHexoseB: 'X,X,X-Hexose b',
    vXXXHexoseC: 'X,X,X-Hexose c',
    vXXXHexoseD: 'X,X,X-Hexose d',
    vXXXHexoseE: 'X,X,X-Hexose e',
    vXXXHexoseF: 'X,X,X-Hexose f',
    vXXXHexoseG: 'X,X,X-Hexose g',
    vXXXHexoseH: 'X,X,X-Hexose h',
    vXXXHexoseI: 'X,X,X-Hexose i',
    vXXXHexoseJ: 'X,X,X-Hexose j',
    vXXXHexoseK: 'X,X,X-Hexose k',
    vXXXHexoseL: 'X,X,X-Hexose l',
    vXXXHexoseM: 'X,X,X-Hexose m',
    vXXXHexoseN: 'X,X,X-Hexose n',
    vXXXHexoseO: 'X,X,X-Hexose o',
    vXXXHexoseP: 'X,X,X-Hexose p',
    vXXXPentoseA: 'X,X,X-Pentose a',
    vXXXPentoseB: 'X,X,X-Pentose b',
    vXXXPentoseC: 'X,X,X-Pentose c',
    vXXXPentoseD: 'X,X,X-Pentose d',
    vXXXPentoseE: 'X,X,X-Pentose e',
    vXXXPentoseF: 'X,X,X-Pentose f',
    vXXXPentoseG: 'X,X,X-Pentose g',
    vXXXPentoseH: 'X,X,X-Pentose h',
    vXXXPentoseI: 'X,X,X-Pentose i',
    vXXXPentoseJ: 'X,X,X-Pentose j',
    vXXXPentoseK: 'X,X,X-Pentose k',
    vXXXPentoseL: 'X,X,X-Pentose l',
    vXXXPentoseM: 'X,X,X-Pentose m',
    vXXXPentoseN: 'X,X,X-Pentose n',
    vXXXPentoseO: 'X,X,X-Pentose o',
    vXXXPentoseP: 'X,X,X-Pentose p',
};

@Injectable({
    providedIn: 'root',
})
export class SampleApiService {
    constructor(
        private createBcdIdGQL: CreateBcdIdGQL,
        private sampleDetailGQL: SampleDetailGQL,
        private mergeSourceSampleGQL: MergeSourceSampleGQL,
        private mergeSampleMetadataGQL: MergeSampleMetadataGQL,
        private createSampleAndProcessGQL: CreateProcessAndSampleGQL,
        private createInteractionProcessAndSampleGQL: CreateInteractionProcessAndSampleGQL,
        private metadataGroupGQL: MetadataGroupGQL,
        private createSampleMonoOrFreeMonoMeasurementsGQL: CreateSampleMonoOrFreeMonoMeasurementGQL,
        private mergeProcessMetadataGQL: MergeProcessMetadataGQL,
        private createGrowthCurveMeasurementGQL: CreateGrowthCurveMeasurementGQL,
        private calculateCurvesGQL: CalculateCurvesGQL,
        private mergeCalculatedCurvesGQL: MergeCalculatedCurvesGQL,
        private createFileTypeMeasurementGQL: CreateFileTypeMeasurementGQL,
        private createFastqMappingMeasurementGQL: CreateFastqMappingMeasurementGQL,
        private sampleTypesMetadataGQL: SampleTypesMetadataGQL,
        private createSampleLinkageMeasurements: CreateSampleLinkageMeasurementGQL,
        private createSampleMetabAnalytesMeasurements: CreateSampleMetabAnalytesMeasurementGQL,
        private deleteMeasurementGQL: DeleteMeasurementGQL,
        private deleteSampleUrlInfoGQL: DeleteSampleUrlInfoGQL,
        private upsertSampleUrlInfoGQL: UpsertSampleUrlInfoGQL
    ) {}

    public getSampleDetail(id: string): Observable<Sample> {
        return this.sampleDetailGQL
            .fetch(
                {
                    bcdId: id,
                },
                {
                    fetchPolicy: 'network-only',
                }
            )
            .pipe(
                map((results) => {
                    if (results.data.samples.length > 0) {
                        return Sample.fromGQLData(results.data.samples[0]);
                    } else {
                        return null;
                    }
                })
            );
    }

    public saveSourceSamples(samples: Sample[]): Observable<SampleSaveResult> {
        return from(samples).pipe(
            // merge source sample
            mergeMap((sample) => {
                return this.saveSourceSample(sample);
            }, 5),
            catchError((result) => {
                return of({
                    sample: new Sample(),
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    public saveSourceSample(sample: Sample): Observable<SampleSaveResult> {
        // merge sample
        return this.mergeSourceSampleGQL
            .mutate({
                bcdId: sample.id,
                name: sample.metadata.name
                    ? sample.metadata.name.toString()
                    : sample.id,
                dateReceived: sample.metadata.dateReceived
                    ? sample.metadata.dateReceived.toString()
                    : '',
                type: sample.type,
            })
            .pipe(
                mergeMap((results) => {
                    if (results.data.mergeSourceSample.length > 0) {
                        // if new, create new sample with new generated id
                        return this.saveSampleMetadata(sample);
                    } else {
                        return of({
                            sample: sample,
                            success: false,
                            message: '',
                            error: 'Source sample create failed: ' + sample.id,
                        });
                    }
                }, 2),
                catchError((result) => {
                    return of({
                        sample: sample,
                        success: false,
                        error:
                            "Error saving sample '" +
                            sample.id +
                            "': " +
                            result?.toString(),
                        message: '',
                    });
                })
            );
    }

    public saveSampleMetadata(sample: Sample): Observable<SampleSaveResult> {
        return from(Object.keys(sample.metadata)).pipe(
            filter((key) => !['id', 'name'].includes(key)),
            mergeMap((key) => {
                return this.mergeSampleMetadataGQL.mutate({
                    bcdId: sample.id,
                    metadataName: key,
                    value: sample.metadata[key]?.toString(),
                });
            }, 20),
            map((metadataMergeResult) => {
                if (metadataMergeResult.data.mergeSampleMetadata.length > 0) {
                    return {
                        success: true,
                        error: null,
                    };
                } else {
                    console.error('metadata error', metadataMergeResult);
                    return {
                        success: false,
                        error: metadataMergeResult.errors
                            ?.map((err) => err.message)
                            .join(';'),
                    };
                }
            }),
            reduce((acc, curr) => {
                if (!curr.success) {
                    acc.success = false;
                    acc.error += ';' + curr.error;
                }

                return acc;
            }, Object.assign({}, { sample: sample, success: true, error: '', message: '' })),
            map((retVal) => {
                if (retVal.success) {
                    retVal.message = 'Source sample saved: ' + sample.id;
                }
                return retVal;
            })
        );
    }

    public getSampleTypesMetadata() {
        return this.sampleTypesMetadataGQL.fetch().pipe(
            map((result) => {
                if (result.data.sampleTypes.length > 0) {
                    return result.data.sampleTypes;
                } else {
                    return null;
                }
            })
        );
    }

    public saveMonoMeasurementsForSamples(
        samples: Sample[]
    ): Observable<SampleSaveResult> {
        return from(samples).pipe(
            // merge source sample
            mergeMap((sample) => {
                return this.saveMonoMeasurementsForSample(sample);
            }, 5),
            catchError((result) => {
                return of({
                    sample: new Sample(),
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    public saveMonoMeasurementsForSample(
        sample: Sample
    ): Observable<SampleSaveResult> {
        return from(Object.keys(sample.observedData)).pipe(
            mergeMap((dataType) => {
                return from(sample.observedData[dataType]).pipe(
                    mergeMap((dataset) => {
                        return from(dataset.getGQLMeasurementInputs()).pipe(
                            mergeMap((measurementInput) => {
                                return this.createSampleMonoOrFreeMonoMeasurementsGQL.mutate(
                                    {
                                        bcdId: sample.id,
                                        type: dataType,
                                        observationDt:
                                            dataset.getGQLDateInput(),
                                        measurementData: measurementInput,
                                        monoMethodType: dataset.monoMethodType,
                                        sopVersion: dataset.sopVersion,
                                    }
                                );
                            }),
                            map((saveDataResult) => {
                                return this.processSaveSampleDataResult(
                                    sample,
                                    dataset.observationDate,
                                    dataType,
                                    saveDataResult.data
                                        .createSampleMonoOrFreeMonoMeasurement
                                        .length,
                                    saveDataResult.errors
                                );
                            })
                        );
                    })
                );
            })
        );
    }

    public saveLinkageMeasurementsForSamples(
        samples: Sample[]
    ): Observable<SampleSaveResult> {
        return from(samples).pipe(
            // merge source sample
            mergeMap((sample) => {
                return this.saveLinkageMeasurementsForSample(sample);
            }, 1),
            catchError((result) => {
                return of({
                    sample: new Sample(),
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    public saveLinkageMeasurementsForSample(
        sample: Sample
    ): Observable<SampleSaveResult> {
        return from(Object.keys(sample.observedData)).pipe(
            mergeMap((dataType) => {
                return from(sample.observedData[dataType]).pipe(
                    mergeMap((dataset) => {
                        return from(dataset.getGQLMeasurementInputs()).pipe(
                            mergeMap((measurementInput) => {
                                let notNullMeasurementInput = [];
                                for (const m in measurementInput) {
                                    if (!!measurementInput[m]) {
                                        const variableData = {
                                            name: LINKAGE_NAMES[m],
                                            variable: m,
                                            value: measurementInput[m],
                                        };
                                        notNullMeasurementInput.push(
                                            variableData
                                        );
                                    }
                                }
                                return this.createSampleLinkageMeasurements.mutate(
                                    {
                                        bcdId: sample.id,
                                        observationDt:
                                            dataset.getGQLDateInput(),
                                        measurementData:
                                            notNullMeasurementInput,
                                        sopVersion: dataset.sopVersion,
                                    }
                                );
                            }),
                            map((saveDataResult) => {
                                return this.processSaveSampleDataResult(
                                    sample,
                                    dataset.observationDate,
                                    dataType,
                                    saveDataResult.data
                                        .createSampleLinkageMeasurement.length,
                                    saveDataResult.errors
                                );
                            })
                        );
                    })
                );
            })
        );
    }

    public saveMetabAnalytesForSamples(
        samples: Sample[]
    ): Observable<SampleSaveResult> {
        return from(samples).pipe(
            mergeMap((sample) => {
                return this.saveMetabAnalytesMeasurementsForSample(sample);
            }, 5),
            catchError((result) => {
                return of({
                    sample: new Sample(),
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    public saveMetabAnalytesMeasurementsForSample(
        sample: Sample
    ): Observable<SampleSaveResult> {
        return from(Object.keys(sample.observedData)).pipe(
            mergeMap((dataType) => {
                return from(sample.observedData[dataType]).pipe(
                    mergeMap((dataset) => {
                        return from(dataset.getGQLMeasurementInputs()).pipe(
                            mergeMap((measurementInput) => {
                                return this.createSampleMetabAnalytesMeasurements.mutate(
                                    {
                                        bcdId: sample.id,
                                        observationDt:
                                            dataset.getGQLDateInput(),
                                        measurementData: measurementInput,
                                        replicate: dataset.replicate,
                                    }
                                );
                            }),
                            map((saveDataResult) => {
                                return this.processSaveSampleDataResult(
                                    sample,
                                    dataset.observationDate,
                                    dataType,
                                    saveDataResult.data
                                        .createSampleMetabAnalytesMeasurement
                                        .length,
                                    saveDataResult.errors
                                );
                            })
                        );
                    })
                );
            })
        );
    }

    private processSaveSampleDataResult(
        sample: Sample,
        observationDate: Date,
        dataType: string,
        saveResultLength: number,
        saveResultErrors: ReadonlyArray<GraphQLError>
    ) {
        const dateTimeStr = formatDate(observationDate, 'short', 'en-US');
        if (saveResultLength > 0) {
            return {
                sample: sample,
                success: true,
                message: `${sample.id} (${dateTimeStr}): ${dataType} data saved.`,
                error: '',
            };
        } else {
            const errorMsg = saveResultErrors
                ? saveResultErrors.join('; ')
                : '';
            return {
                sample: sample,
                success: false,
                message: '',
                error:
                    `${sample.id} (${dateTimeStr}): [SAVE ERROR] ` + errorMsg,
            };
        }
    }

    public saveGrowthData(data: MapperData): Observable<SaveResult> {
        return from(Object.entries(data.measurements)).pipe(
            mergeMap(([cell, values]) => {
                return this.saveGrowthCurve(
                    cell,
                    values,
                    data.fileInfo.trayName
                );
            }, 5),
            catchError((result) => {
                return of({
                    success: false,
                    error: result.toString(),
                    message: '',
                });
            })
        );
    }

    private saveGrowthCurve(
        cell: string,
        values: { [time: number]: number },
        trayName: string
    ): Observable<SaveResult> {
        const valuesAndCell = { ...values, ...{ cell: cell } };
        return this.createGrowthCurveMeasurementGQL
            .mutate({
                trayName: trayName,
                replicate: cell,
                values: JSON.stringify(valuesAndCell),
            })
            .pipe(
                mapTo({
                    success: true,
                    message: '',
                    error: '',
                })
            );
        /*
            .pipe(
                mergeMap(() => {
                    return this.calculateAndMergeCurves(
                        JSON.stringify(values),
                        trayName,
                        cell
                    );
                }),
                catchError((result) => {
                    return of({
                        success: false,
                        message: '',
                        error: result.toString(),
                    });
                })
            );
         */
    }

    public calculateCurvesAndMerge(growthfile: string): Observable<SaveResult> {
        console.log('sample-api svc: calculateCurvesAndMerge');
        return this.calculateCurvesGQL
            .fetch({ measurementsJson: growthfile })
            .pipe(
                mapTo({
                    success: true,
                    message: '',
                    error: '',
                })
            );
    }

    private calculateAndMergeCurves(
        jsonValues: string,
        trayName: string,
        cell: string
    ): Observable<SaveResult> {
        return this.calculateCurvesGQL
            .fetch({ measurementsJson: jsonValues })
            .pipe(
                mergeMap((result) => {
                    return this.mergeCalculatedCurvesGQL
                        .mutate({
                            trayName: trayName,
                            replicate: cell,
                            calculatedValues:
                                result.data.calculateCurves.calculated,
                        })
                        .pipe(
                            mapTo({
                                success: true,
                                message: '',
                                error: '',
                            })
                        );
                }),
                retryWhen(genericRetryStrategy())
            );
    }

    public saveFileTypeData(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return from(data.samples).pipe(
            mergeMap((sample) => {
                return this.createFileTypeMeasurementGQL
                    .mutate({
                        sampleId: sample.id,
                        measurementType: dataFileType,
                        filePath: filePath,
                    })
                    .pipe(
                        map(() => {
                            return {
                                success: true,
                                message: '',
                                error: '',
                            };
                        })
                    );
            }),
            catchError((result) => {
                return of({
                    success: false,
                    message: '',
                    error: result.toString(),
                });
            })
        );
    }

    public saveFastqMappingData(data: MapperData): Observable<SaveResult> {
        return from(data.samples).pipe(
            mergeMap((sample) => {
                return this.createFastqMappingMeasurementGQL
                    .mutate({
                        sampleId: sample.id,
                        mappingFileKey: data.fastqInfo.mappingFileKey,
                    })
                    .pipe(
                        map(() => {
                            return {
                                success: true,
                                message: '',
                                error: '',
                            };
                        })
                    );
            }, 5),
            catchError((result) => {
                return of({
                    success: false,
                    message: '',
                    error: result.toString(),
                });
            })
        );
    }

    public deleteMeasurement(
        measurementUuid: string
    ): Observable<{ measurementUuid: string }> {
        return this.deleteMeasurementGQL
            .mutate({
                measurementUuid: measurementUuid,
            })
            .pipe(
                map((deleteMeasurementResults) => {
                    return { measurementUuid: measurementUuid };
                })
            );
    }

    public editSampleUrlInfo(deleted, updated): Observable<any> {
        return this.deleteSampleUrlInfoGQL
            .mutate({
                ids: deleted,
            })
            .pipe(
                switchMap((results) => {
                    return this.upsertSampleUrlInfoGQL
                        .mutate({
                            sampleUrlInfo: updated,
                        })
                        .pipe(
                            map((data) => {
                                console.log('returned: ', data);
                                return data;
                            })
                        );
                })
            );
    }
}
