<div class="container">
    <div class="row">
        <div class="col-md-12">
            <h4>{{ datasetTitle }}</h4>
        </div>
    </div>
</div>
<tabset>
    <tab
        *ngFor="let currentDataset of sortedFileData; index as i"
        heading="{{
            currentDataset.observationDate != null
                ? (currentDataset.observationDate | date : 'M/d/y' : 'GMT')
                : 'Date N/A'
        }}"
        [id]="
            'tab-' +
            datasetTitle +
            '-' +
            currentDataset.observationDate?.getTime()
        "
    >
        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td>
                            <a
                                [href]="
                                    currentDataset.filePath | filePath | async
                                "
                                target="_blank"
                                >Download</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab>
</tabset>
