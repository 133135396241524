import { DataImportMapper } from './data-import-mapper';
import { Sample } from '../../model/sample.model';
import { MetabAnalytesDataset } from '../../model/metab-analytes-dataset.model';
import { DataRow } from '../../model/data-row.model';

export class DataImportMapperMetabAnalytes extends DataImportMapper {
    public MAPPING = {
        'Formate Results|Final Conc.': 'Formate',
        'Propionate Results|Final Conc.': 'Propionate',
        'Butyrate Results|Final Conc.': 'Butyrate',
        'Isobutyrate Results|Final Conc.': 'Isobutyrate',
        'Isovalerate Results|Final Conc.': 'Isovalerate',
        'Valerate Results|Final Conc.': 'Valerate',
        '3-Hydroxybutyrate Results|Final Conc.': '3-Hydroxybutyrate',
        'Glycerate Results|Final Conc.': 'Glycerate',
        'Hexanoate Results|Final Conc.': 'Hexanoate',
        'Isovalerylglycine Results|Final Conc.': 'Isovalerylglycine',
        'L-Acetylcarnitine Results|Final Conc.': 'L-Acetylcarnitine',
        'Propionylcarnitine Results|Final Conc.': 'Propionylcarnitine',
        'Acetate Results|Final Conc.': 'Acetate',
        'Benzoate Results|Final Conc.': 'Benzoate',
        'L-Malate Results|Final Conc.': 'L-Malate',
        'Citrate Results|Final Conc.': 'Citrate',
        'Pyruvate Results|Final Conc.': 'Pyruvate',
        'Succinate Results|Final Conc.': 'Succinate',
        'Isocitrate Results|Final Conc.': 'Isocitrate',
        '2-Hydroxyglutarate Results|Final Conc.': '2-Hydroxyglutarate',
        'Lactate Results|Final Conc.': 'Lactate',
        '2-Hydroxybutyrate Results|Final Conc.': '2-Hydroxybutyrate',
        'Butyrylcarnitine Results|Final Conc.': 'Butyrylcarnitine',
    };

    public rowMap(headerRow: string[], row: string[]): Sample {
        const sample = new Sample();
        const headers = headerRow.map((label) => label.toLowerCase());
        const mapping = Object.entries(this.MAPPING).reduce(
            (m, [key, value]) => {
                m[key.toLowerCase()] = value;
                return m;
            },
            {}
        );
        sample.id = row[headers.indexOf('name')];

        const dataRow: DataRow = {};
        Object.keys(mapping).forEach((key) => {
            if (headers.indexOf(key) > -1) {
                const value = row[headers.indexOf(key)];
                dataRow[mapping[key]] =
                    value === 'null' || value === null
                        ? null
                        : parseFloat(value);
            }
        });

        let replicate = '';
        const replicateMatch = row[headers.indexOf('pos.')].match(/P\d+-(\w+)/);
        if (replicateMatch && replicateMatch.length === 2) {
            replicate = replicateMatch[1];
        }
        const dataset = new MetabAnalytesDataset(
            replicate,
            new Date(row[headers.indexOf('acq. date-time')])
        );
        dataset.data = [dataRow];
        sample.observedData = {};
        sample.observedData['MetabAnalytes'] = [dataset];

        return sample;
    }
}
