import { DataImportValidatorSource } from './data-import-validator-source';
import { DataImportValidator } from './data-import-validator';
import { DataImportValidatorMono } from './data-import-validator-mono';
import { DataImportValidatorLinkage } from './data-import-validator-linkage';
import { DataImportValidatorTray } from './data-import-validator-tray';
import { Injectable } from '@angular/core';
import { DataImportValidatorGrowth } from './data-import-validator-growth';
import { DataImportType } from '../data-import-type';
import { DataImportValidatorFile } from './data-import-validator-file';
import { DataImportValidatorFastqMapping } from './data-import-validator-fastq-mapping';
import { DataImportValidatorFastqFile } from './data-import-validator-fastq-file';
import { DataImportValidatorMetabAnalytes } from './data-import-validator-metab-analytes';
import { DataImportValidatorBio } from './data-import-validator-bio';
import { DataImportValidatorSingleStrain } from './data-import-validator-single-strain';

@Injectable({
    providedIn: 'root',
})
export class DataImportValidatorFactory {
    constructor(
        private dataImportValidatorTray: DataImportValidatorTray,
        private dataImportValidatorFastq: DataImportValidatorFastqMapping,
        private dataImportValidatorFile: DataImportValidatorFile,
        private dataImportValidatorSingleStrain: DataImportValidatorSingleStrain
    ) {}

    public createValidator(dataFileType: string): DataImportValidator {
        switch (dataFileType) {
            case DataImportType.SOURCE:
                return new DataImportValidatorSource();
            case DataImportType.MONO:
                return new DataImportValidatorMono();
            case DataImportType.LINKAGE:
                return new DataImportValidatorLinkage();
            case DataImportType.TRAY_METADATA:
                return this.dataImportValidatorTray;
            case DataImportType.GROWTH_CURVE:
                return new DataImportValidatorGrowth();
            case DataImportType.FASTQ_MAPPING:
                return this.dataImportValidatorFastq;
            case DataImportType.FASTQ:
                return new DataImportValidatorFastqFile();
            case DataImportType.METABOLOMICS_ANALYTES:
                return new DataImportValidatorMetabAnalytes();
            case DataImportType.METABOLOMICS:
                return this.dataImportValidatorFile;
            case DataImportType.SINGLE_STRAIN:
                return this.dataImportValidatorSingleStrain;
            case DataImportType.FECAL_SLURRY:
            case DataImportType.FECAL_POOL:
            case DataImportType.FECAL_SOURCE:
                return new DataImportValidatorBio();
            default:
                throw Error(
                    'DataImportValidationFactory: Invalid data file type'
                );
        }
    }
}
