import { GenericDataset } from './generic-dataset.model';
import { DataRow } from '../model/data-row.model';

export class MetabAnalytesDataset extends GenericDataset {
    replicate: string;
    defaultColumnOrder = [
        'Formate',
        'Propionate',
        'Butyrate',
        'Isobutyrate',
        'Isovalerate',
        'Valerate',
        '3-Hydroxybutyrate',
        'Glycerate',
        'Hexanoate',
        'Isovalerylglycine',
        'L-Acetylcarnitine',
        'Propionylcarnitine',
        'Acetate',
        'Benzoate',
        'L-Malate',
        'Citrate',
        'Pyruvate',
        'Succinate',
        'Isocitrate',
        '2-Hydroxyglutarate',
        'Lactate',
        '2-Hydroxybutyrate',
        'Butyrylcarnitine',
    ];

    GQL_INPUT_MAP = {
        Formate: 'vFormate',
        Propionate: 'vPropionate',
        Butyrate: 'vButyrate',
        Isobutyrate: 'vIsobutyrate',
        Isovalerate: 'vIsovalerate',
        Valerate: 'vValerate',
        '3-Hydroxybutyrate': 'v3Hydroxybutyrate',
        Glycerate: 'vGlycerate',
        Hexanoate: 'vHexanoate',
        Isovalerylglycine: 'vIsovalerylglycine',
        'L-Acetylcarnitine': 'vLAcetylcarnitine',
        Propionylcarnitine: 'vPropionylcarnitine',
        Acetate: 'vAcetate',
        Benzoate: 'vBenzoate',
        'L-Malate': 'vLMalate',
        Citrate: 'vCitrate',
        Pyruvate: 'vPyruvate',
        Succinate: 'vSuccinate',
        Isocitrate: 'vIsocitrate',
        '2-Hydroxyglutarate': 'v2Hydroxyglutarate',
        Lactate: 'vLactate',
        '2-Hydroxybutyrate': 'v2Hydroxybutyrate',
        Butyrylcarnitine: 'vButyrylcarnitine',
    };

    constructor(
        replicate: string,
        observationDate: Date,
        data?: Array<DataRow>,
        uuid?: string
    ) {
        super(observationDate, data, uuid);
        this.replicate = replicate;
        // necessary since super doesnt have variables in defaultColumnOrder
        if (data) {
            this.appendFieldsToDefaultColumnOrder(data);
            this.markMissingFieldsNull(data);
            this.data = data;
        }
    }

    getGQLMeasurementInputs(): any[] {
        // make sure all analytes are in input obj
        return this.data.map((measurements) => {
            const gqlInput = {};
            this.defaultColumnOrder.forEach((analyte) => {
                if (!(analyte in measurements)) {
                    gqlInput[this.GQL_INPUT_MAP[analyte]] = null;
                } else {
                    gqlInput[this.GQL_INPUT_MAP[analyte]] =
                        measurements[analyte];
                }
            });
            return gqlInput;
        });
    }
}
