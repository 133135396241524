import { createAction, props } from '@ngrx/store';
import { UiEntity } from './ui.models';
import { Process, Sample } from '@bcdbio/data';
import { DataImportData, DataImportType } from '@bcdbio/data';
import {
    Project,
    User,
    Role,
    Study,
    Study_Insert_Input,
    Study_Set_Input,
    Study_Url_Insert_Input,
    Project_User_Insert_Input,
    Project_Url_Insert_Input,
    Sample_Url_Insert_Input,
} from '@bcdbio/udb-graphql';
import { Sort } from '@angular/material/sort';

export const loadUi = createAction('[Ui] Load Ui');

export const loadUiSuccess = createAction(
    '[Ui] Load Ui Success',
    props<{ ui: UiEntity[] }>()
);

export const loadUiFailure = createAction(
    '[Ui] Load Ui Failure',
    props<{ error: any }>()
);

export const searchResultsRequested = createAction(
    '[Ui] Search Results Requested',
    props<{
        searchTerm: string;
        maxResults: number;
        bcdIdOnly: boolean;
        dataPresent: string[];
        dataSource: string[];
        processInputTypes: string[];
        processOutputTypes: string[];
        sortType: string;
        sortDir: string;
    }>()
);

export const poolSourcesSearchResultsRequested = createAction(
    '[Ui] Pool Sources Search Results Requested',
    props<{ searchTerm: string; maxResults: number }>()
);

export const relatedSamplesSearchResultsRequested = createAction(
    '[Ui] Related Samples Search Results Requested',
    props<{ searchTerm: string; maxResults: number }>()
);

export const clearRelatedSamplesSearchResults = createAction(
    '[Ui] Clear Related Samples Search Results'
);

export const singleSampleRequested = createAction(
    '[Ui] Single Sample Requested',
    props<{ sampleId: string }>()
);

export const singleProjectRequested = createAction(
    '[Ui] Single Project Requested',
    props<{ projectId: string }>()
);

export const projectListRequested = createAction('[Ui] Project List Requested');

export const projectRetrieved = createAction(
    '[Ui] Project Retrieved',
    props<{ currentProject: Project }>()
);

export const projectListRetrieved = createAction(
    '[Ui] Project List Retrieved',
    props<{ projects: Project[] }>()
);

export const singleStudyRequested = createAction(
    '[Ui] Single Study Requested',
    props<{ studyId: string }>()
);

export const studyRetrieved = createAction(
    '[Ui] Single Study Retrieved',
    props<{ currentStudy: Study }>()
);

export const studyListRequested = createAction('[Ui] Study List Requested');

export const studyListRetrieved = createAction(
    '[Ui] Study List Retrieved',
    props<{ studies: Study[] }>()
);

export const createNewStudy = createAction(
    '[Ui] Create New Study',
    props<{ study: Study_Insert_Input; studyId: string }>()
);

export const editStudy = createAction(
    '[Ui] Edit Study',
    props<{ study: Study_Set_Input }>()
);

export const deleteStudy = createAction(
    '[Ui] Delete Study',
    props<{ studyId: string }>()
);

export const studyDeleted = createAction(
    '[Ui] Study Deleted',
    props<{ studyId: string }>()
);

export const addStudyToProject = createAction(
    '[Ui] Add Study To Project',
    props<{ studyId: string; projectId: string }>()
);

export const deleteStudyFromProject = createAction(
    '[Ui] Delete Study From Project',
    props<{ studyProjectId: number; studyId: string }>()
);

export const addRelatedSampleToProject = createAction(
    '[Ui] Add Related Sample To Project',
    props<{ sampleBcdId: string; projectId: string }>()
);

export const deleteRelatedSampleFromProject = createAction(
    '[Ui] Delete Related Sample From Project',
    props<{ projectSampleId: number }>()
);

export const upsertDeleteStudyUrlInfo = createAction(
    '[Ui] Update Delete Study URL Info',
    props<{
        deleted: string[];
        upserted: Study_Url_Insert_Input[];
        studyId: string;
    }>()
);

export const updatedStudyUrlInfo = createAction('[Ui] Updated Study URL Info');

export const upsertDeleteProjectUrlInfo = createAction(
    '[Ui] Update Delete Project URL Info',
    props<{
        deleted: string[];
        upserted: Project_Url_Insert_Input[];
        projectId: string;
    }>()
);

export const updatedProjectUrlInfo = createAction(
    '[Ui] Updated Project URL Info'
);

export const upsertDeleteSampleUrlInfo = createAction(
    '[Ui] Update Delete Sample URL Info',
    props<{
        deleted: string[];
        upserted: Sample_Url_Insert_Input[];
        sampleBcdId: string;
    }>()
);

export const updatedSampleUrlInfo = createAction(
    '[Ui] Updated Sample URL Info'
);

export const createNewProject = createAction(
    '[Ui] Create New Project',
    props<{ project: Project }>()
);

export const editProject = createAction(
    '[Ui] Edit Project',
    props<{ project: Project }>()
);

export const deleteProject = createAction(
    '[Ui] Delete Project',
    props<{ projectId: string }>()
);

export const addDeleteProjectUsers = createAction(
    '[Ui] Add Delete Project Users',
    props<{
        deleted: string[];
        added: Project_User_Insert_Input[];
        projectId: string;
    }>()
);

export const updatedProjectUsers = createAction('[Ui] Updated Project Users');

export const projectDeleted = createAction(
    '[Ui] Project Deleted',
    props<{ projectId: string }>()
);

export const searchResultsRetrieved = createAction(
    '[Ui] Search Results Retrieved',
    props<{ searchResults: Sample[] }>()
);

export const poolSourcesSearchResultsRetrieved = createAction(
    '[Ui] Pool Sources Search Results Retrieved',
    props<{ searchResults: Sample[] }>()
);

export const sampleRetrieved = createAction(
    '[Ui] Sample Retrieved',
    props<{ currentSample: Sample }>()
);

export const setExportSampleIds = createAction(
    '[Ui] Set Export SampleId',
    props<{ exportSampleIds: string[] }>()
);

export const addExportSampleIds = createAction(
    '[Ui] Add Export SampleId',
    props<{ exportSampleIds: string[] }>()
);

export const loadMoreSearchResults = createAction(
    '[Ui] Load More Search Results',
    props<{ maxResults: number }>()
);

export const loadMorePoolSourcesSearchResults = createAction(
    '[Ui] Load More Pool Sources Search Results',
    props<{ maxResults: number }>()
);

export const loadMoreRelatedSamplesSearchResults = createAction(
    '[Ui] Load More Related Samples Search Results',
    props<{ maxResults: number }>()
);

export const dataImportInitiated = createAction(
    '[Ui] Data Import Initiated',
    props<{ file: File; dataFileType: DataImportType }>()
);

export const dataImportFileReadSuccess = createAction(
    '[Ui] Data Import File Read Success',
    props<{ file: File; data: DataImportData; dataFileType: DataImportType }>()
);

export const dataImportFileReadFail = createAction(
    '[Ui] Data Import File Read Error',
    props<{ file: File; dataFileType: DataImportType; errors: string[] }>()
);

export const dataImportFileValidationSuccess = createAction(
    '[Ui] Data Import File Validation Success',
    props<{ file: File; data: DataImportData; dataFileType: DataImportType }>()
);

export const dataImportFileValidationFail = createAction(
    '[Ui] Data Import File Validation Fail',
    props<{
        file: File;
        data: DataImportData;
        dataFileType: string;
        errors: string[];
    }>()
);

export const dataImportFileSaveSuccess = createAction(
    '[Ui] Data Import File Save Success',
    props<{
        file: File;
        data: DataImportData;
        dataFileType: string;
        message: string;
    }>()
);

export const dataImportFileSaveFail = createAction(
    '[Ui] Data Import File Save Fail',
    props<{ file: File; data: DataImportData; errors: string[] }>()
);

export const dataImportFilePostProc = createAction(
    '[Ui] Data Import File Post Process',
    props<{
        file: File;
        data: DataImportData;
        dataFileType: string;
        message: string;
    }>()
);

export const createProcessAndSample = createAction(
    '[Ui] Create Process and Sample',
    props<{
        fromId: string;
        processType: string;
        descriptiveText: string;
        notebookPage: string;
        datePrepared: string;
        sourceSex: string;
        sourceHealthStatus: string;
    }>()
);

export const createProcessAndSampleSuccess = createAction(
    '[Ui] Create Process and Sample Success',
    props<{
        fromId: string;
        processType: string;
        descriptiveText: string;
        sourceSex: string;
        sourceHealthStatus: string;
        sample: Sample;
    }>()
);

export const createProcessAndSampleFail = createAction(
    '[Ui] Create Process and Sample Fail',
    props<{
        fromId: string;
        processType: string;
        descriptiveText: string;
        sourceSex: string;
        sourceHealthStatus: string;
        errors: string[];
    }>()
);

export const deleteSampleAndDescendants = createAction(
    '[Ui] Delete Sample and Descendants',
    props<{
        sampleId: string;
        isSourceSample: boolean;
    }>()
);

export const deleteSampleAndDescendantsSuccess = createAction(
    '[Ui] Delete Sample and Descendants Success',
    props<{
        sampleId: string;
    }>()
);

export const deleteSampleAndDescendantsFail = createAction(
    '[Ui] Delete Sample and Descendants Fail',
    props<{
        sampleId: string;
    }>()
);

export const deleteMeasurement = createAction(
    '[Ui] Delete Measurement',
    props<{
        measurementUuid: string;
    }>()
);

export const deleteMeasurementSuccess = createAction(
    '[Ui] Delete Measurement Success',
    props<{
        measurementUuid: string;
    }>()
);

export const addPoolSource = createAction(
    '[Ui] Add Pool Source',
    props<{
        newPoolSourceId: string;
        processId: string;
        processOutputId: string;
        processOutputType: string;
        numDonors: string;
        sex: string;
        healthStatus: string;
    }>()
);

export const addPoolSourceSuccess = createAction(
    '[Ui] Add Pool Source Success',
    props<{
        newPoolSourceId: string;
        processId: string;
        sampleId: string;
        sampleType: string;
        numDonors: string;
        sex: string;
        healthStatus: string;
        sample: Sample;
    }>()
);

export const addPoolSourceFail = createAction(
    '[Ui] Add Pool Source Fail',
    props<{
        newPoolSourceId: string;
        processId: string;
        sampleId: string;
        sampleType: string;
        numDonors: string;
        sex: string;
        healthStatus: string;
        error: string;
    }>()
);

export const saveProcessMetadata = createAction(
    '[Ui] Save Process Metadata',
    props<{
        id: string;
        metadataGroups: { [key: string]: { [key: string]: string } };
    }>()
);

export const saveProcessMetadataSuccess = createAction(
    '[Ui] Save Process Metadata Success',
    props<{
        id: string;
        metadataGroups: { [key: string]: { [key: string]: string } };
        message: string;
    }>()
);

export const saveProcessMetadataFail = createAction(
    '[Ui] Save Process Metadata Fail',
    props<{
        id: string;
        metadataGroups: { [key: string]: { [key: string]: string } };
        errors: string[];
    }>()
);

export const saveSampleMetadata = createAction(
    '[Ui] Save Sample Metadata',
    props<{ id: string; metadata: { [key: string]: string } }>()
);

export const saveSampleMetadataSuccess = createAction(
    '[Ui] Save Sample Metadata Success',
    props<{
        id: string;
        metadata: { [key: string]: string };
        message: string;
    }>()
);

export const saveSampleMetadataFail = createAction(
    '[Ui] Save Sample Metadata Fail',
    props<{ id: string; metadata: { [key: string]: string }; errors: string }>()
);

export const savePoolSourceMetadata = createAction(
    '[Ui] Save Pool Source Metadata',
    props<{
        sampleId: string;
        processId: string;
        sourceName: string;
        metadataName: string;
        value: string;
    }>()
);

export const savePoolSourceMetadataSuccess = createAction(
    '[Ui] Save Pool Source Metadata Success',
    props<{
        sampleId: string;
        processId: string;
        sourceName: string;
        metadataName: string;
        value: string;
        sample: Sample;
    }>()
);

export const savePoolSourceMetadataFail = createAction(
    '[Ui] Save Pool Source Metadata Success',
    props<{
        sampleId: string;
        processId: string;
        sourceName: string;
        metadataName: string;
        value: string;
        error: string;
    }>()
);

export const userListRequested = createAction('[Ui] User List Requested');

export const userListRetrieved = createAction(
    '[Ui] User List Retrieved',
    props<{ users: User[] }>()
);
export const rolesListRequested = createAction('[Ui] Roles List Requested');

export const rolesListRetrieved = createAction(
    '[Ui] Roles List Retrieved',
    props<{ roles: Role[] }>()
);

export const changeListSearchTerm = createAction(
    '[Ui] Change List Search Term',
    props<{ searchTerm: string }>()
);

export const changeStudyListSort = createAction(
    '[Ui] Change Study List Sort',
    props<{ sort: Sort }>()
);
