import { DataImportDataTabular } from './data-import-data-tabular';

export class DataImportDataMono extends DataImportDataTabular {
    public static HEADERS = [
        '',
        '',
        'Name',
        'Data File',
        'Type',
        'SOP Version',
        'Level',
        'Acq. Date-Time',
        'Dil',
        // NOTE: ordering of column pairs will vary in columns below
        'Fructose Results|Final Conc.',
        'Fructose Results|Resp.',
        'Mannose Results|Final Conc.',
        'Mannose Results|Resp.',
        'Ribose Results|Final Conc.',
        'Ribose Results|Resp.',
        'Allose Results|Final Conc.',
        'Allose Results|Resp.',
        'Rhamnose Results|Final Conc.',
        'Rhamnose Results|Resp.',
        'GlcA Results|Final Conc.',
        'GlcA Results|Resp.',
        'GalA Results|Final Conc.',
        'GalA Results|Resp.',
        'Glucose Results|Final Conc.',
        'Glucose Results|Resp.',
        'GlcNAc Results|Final Conc.',
        'GlcNAc Results|Resp.',
        'Xylose Results|Final Conc.',
        'Xylose Results|Resp.',
        'GalNAc Results|Final Conc.',
        'GalNAc Results|Resp.',
        'Galactose Results|Final Conc.',
        'Galactose Results|Resp.',
        'Arabinose Results|Final Conc.',
        'Arabinose Results|Resp.',
        'Fucose Results|Final Conc.',
        'Fucose Results|Resp.',
        'NeuAc Results|Final Conc.',
        'NeuAc Results|Resp.',
        'NeuGc Results|Final Conc.',
        'NeuGc Results|Resp.',
        'IdoA Results|Final Conc.',
        'IdoA Results|Resp.',
        'Apiose Results|Final Conc.',
        'Apiose Results|Resp.',
        'GlcN Results|Final Conc.',
        'GlcN Results|Resp.',
        'GalN Results|Final Conc.',
        'GalN Results|Resp.',
    ];

    constructor(data: Array<string[]>) {
        super(data);
        this.headers = DataImportDataMono.HEADERS;
    }
}
