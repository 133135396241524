import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { map, skip } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    createNewProject,
    deleteProject,
    editProject,
    getCurrentProject,
    getProjects,
    projectListRequested,
    singleProjectRequested,
    UiPartialState,
    getUsers,
    getRoles,
    addDeleteProjectUsers,
    upsertDeleteProjectUrlInfo,
    deleteRelatedSampleFromProject,
    relatedSamplesSearchResultsRequested,
    clearRelatedSamplesSearchResults,
} from '@bcdbio/ui';
import { Observable, Subscription } from 'rxjs';
import { Project, Role, User } from '@bcdbio/udb-graphql';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { RelatedSamplesEditComponent } from '../../components/related-samples-edit/related-samples-edit.component';

@Component({
    selector: 'bcdbio-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
    currentProject$: Observable<Project> =
        this.storeUi.select(getCurrentProject);
    currentProject: Project;
    initialFormValues: Project;
    projects$: Observable<Project[]> = this.storeUi.select(getProjects);
    users$: Observable<User[]> = this.storeUi.select(getUsers).pipe(
        map((users) => {
            return [...users].sort((a, b) =>
                a.userName > b.userName ? 1 : -1
            );
        })
    );
    roles$: Observable<Role[]> = this.storeUi.select(getRoles).pipe(
        map((roles) => {
            return [...roles].sort((a, b) => (a.role > b.role ? 1 : -1));
        })
    );
    createNew = false;
    subscriptions: Subscription = new Subscription();
    showSpinner = true;
    projectForm = new FormGroup({
        projectId: new FormControl(null),
        projectName: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
        ]),
        client: new FormControl(''),
        objective: new FormControl(''),
        startDate: new FormControl(null),
        completionDate: new FormControl(null),
        notes: new FormControl(''),
        finalReportUrl: new FormControl(''),
    });
    projectUserListDirty = false;
    projectUsersToAdd = [];
    projectUsersToDelete = [];

    modalRef: BsModalRef;

    isUrlInfoListDirty = false;
    urlInfoToAdd: {
        index: number;
        label: string;
        url: string;
        deletePending: boolean;
    }[] = [];
    existingUrlInfo: {
        id: number;
        label: string;
        url: string;
        modified: boolean;
    }[] = [];
    existingUrlInfoToDelete = [];
    relatedSamplesInfo = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storeUi: Store<UiPartialState>,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.storeUi.dispatch(projectListRequested());
        this.route.paramMap
            .pipe(map((params) => params.get('id')))
            .subscribe((id) => {
                if (id === 'new') {
                    this.createNew = true;
                    this.projectForm.enable();
                } else {
                    this.createNew = false;
                    this.projectForm.disable();
                    this.storeUi.dispatch(
                        singleProjectRequested({
                            projectId: id,
                        })
                    );
                }
            });

        this.subscriptions.add(
            this.currentProject$.subscribe((currentProject) => {
                if (currentProject) {
                    console.log('currentProject', currentProject);
                    this.currentProject = currentProject;
                    this.showSpinner = false;
                    this.projectForm.disable();
                    this.projectForm.patchValue({
                        projectId: currentProject.projectId,
                        projectName: currentProject.projectName,
                        client: currentProject.client,
                        objective: currentProject.objective,
                        startDate: currentProject.startDate,
                        completionDate: currentProject.completionDate,
                        notes: currentProject.notes,
                        finalReportUrl: currentProject.finalReportUrl,
                    });
                    this.existingUrlInfo = [];
                    currentProject.project_urls.map((pu) => {
                        this.existingUrlInfo.push({
                            id: pu.id,
                            label: pu.label,
                            url: pu.url,
                            modified: false,
                        });
                    });
                    this.initialFormValues = currentProject;
                    this.relatedSamplesInfo = [];
                    currentProject.relatedSamples.map((s) => {
                        s.sampleNode.map((sn) => {
                            const datePreparedMd =
                                sn.outputByProcess?.metadata.find(
                                    (md) => md.metadata.name === 'Date Prepared'
                                );
                            this.relatedSamplesInfo.push({
                                id: s.id,
                                sampleBcdId: sn.bcdId,
                                processType:
                                    sn.outputByProcess?.processType.name,
                                datePrepared: datePreparedMd
                                    ? datePreparedMd.value
                                    : 'Invalid date',
                            });
                        });
                    });
                }
            })
        );
        this.subscriptions.add(
            this.projects$.pipe(skip(1)).subscribe((projects) => {
                this.showSpinner = false;
            })
        );
    }

    goToProjectList() {
        this.router.navigate(['/project-detail']);
    }

    cancelEdits() {
        this.projectForm.disable();
        this.projectForm.patchValue({
            projectId: this.initialFormValues.projectId,
            projectName: this.initialFormValues.projectName,
            client: this.initialFormValues.client,
            objective: this.initialFormValues.objective,
            startDate: this.initialFormValues.startDate,
            completionDate: this.initialFormValues.completionDate,
            notes: this.initialFormValues.notes,
            finalReportUrl: this.initialFormValues.finalReportUrl,
        });
        this.projectUserListDirty = false;
        this.projectUsersToAdd = [];
        this.projectUsersToDelete = [];
        this.isUrlInfoListDirty = false;
        this.urlInfoToAdd = [];
        this.existingUrlInfoToDelete = [];
    }

    createNewProject() {
        this.router.navigate(['/project-detail/new']);
    }

    exportProject(project) {}

    openDeleteConfirmation(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    deleteProject() {
        this.modalRef.hide();
        this.router.navigate(['/project-detail']);
        this.storeUi.dispatch(
            deleteProject({
                projectId: this.projectForm.get('projectId').value,
            })
        );
    }

    saveOrCreateProject() {
        const project = this.projectForm.value;

        if (project.startDate) {
            project.startDate = moment(project.startDate).format('l');
        }
        if (project.completionDate) {
            project.completionDate = moment(project.completionDate).format('l');
        }

        let newProjectId: string = null;
        if (this.createNew) {
            newProjectId = uuidv4();
            this.storeUi.dispatch(
                createNewProject({
                    project: {
                        ...project,
                        projectId: newProjectId,
                    } as Project,
                })
            );
        } else {
            this.storeUi.dispatch(
                editProject({
                    project: project as Project,
                })
            );
        }
        const usersToAdd = this.projectUsersToAdd
            .filter((row) => !row.deletePending)
            .map((row) => ({
                projectId: project.projectId || newProjectId,
                userId: row.userId,
                roleId: row.roleId,
            }));
        setTimeout(() => {
            this.storeUi.dispatch(
                addDeleteProjectUsers({
                    added: usersToAdd,
                    deleted: this.projectUsersToDelete,
                    projectId: project.projectId || newProjectId,
                })
            );
            this.projectUsersToAdd = [];
            this.projectUsersToDelete = [];
        }, 0);

        this.upsertDeleteProjectUrls(project);

        this.createNew = false;
        this.projectForm.disable();
        this.projectUserListDirty = false;
    }

    addProjectUser(user: string, role: string, data) {
        const userObj = data.users.find((u) => u.userId === user);
        const roleObj = data.roles.find((u) => u.id === parseInt(role, 10));
        this.projectUsersToAdd.push({
            userId: user,
            roleId: role,
            userName: userObj.userName,
            roleName: roleObj.role,
            deletePending: false,
        });
        this.projectUserListDirty = this.hasProjectUserListChanged();
        this.modalRef.hide();
    }

    toggleExistingUser(id) {
        const userIndex = this.projectUsersToDelete.findIndex(
            (utdId) => utdId === id
        );
        if (userIndex >= 0) {
            this.projectUsersToDelete.splice(userIndex, 1);
        } else {
            this.projectUsersToDelete.push(id);
        }
        this.projectUserListDirty = this.hasProjectUserListChanged();
    }

    hasProjectUserListChanged(): boolean {
        return (
            this.projectUsersToDelete.length > 0 ||
            this.projectUsersToAdd.find((uta) => !uta.deletePending)
        );
    }

    toggleUserAssignment(index) {
        this.projectUsersToAdd[index].deletePending =
            !this.projectUsersToAdd[index].deletePending;
        this.projectUserListDirty = this.hasProjectUserListChanged();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    openAddUserModal(template) {
        this.modalRef = this.modalService.show(template);
    }

    upsertDeleteProjectUrls(project) {
        const infoToAdd = this.urlInfoToAdd
            .filter((uita) => !uita.deletePending)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    projectId: project.projectId,
                };
            });
        const infoToEdit = this.existingUrlInfo
            .filter((eui) => eui.modified)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    projectId: project.projectId,
                    id: info.id,
                };
            });
        setTimeout(() => {
            this.storeUi.dispatch(
                upsertDeleteProjectUrlInfo({
                    deleted: this.existingUrlInfoToDelete,
                    upserted: infoToAdd.concat(infoToEdit),
                    projectId: project.projectId,
                })
            );
            this.urlInfoToAdd = [];
            this.existingUrlInfoToDelete = [];
        }, 0);
        this.isUrlInfoListDirty = false;
    }

    onToggleExistingUrlInfo(id) {
        const urlInfoIndex = this.existingUrlInfoToDelete.findIndex(
            (uitdId) => uitdId === id
        );
        if (urlInfoIndex >= 0) {
            this.existingUrlInfoToDelete.splice(urlInfoIndex, 1);
        } else {
            this.existingUrlInfoToDelete.push(id);
        }
        this.isUrlInfoListDirty = this.hasUrlInfoListChanged();
    }

    hasUrlInfoListChanged(): boolean {
        return (
            this.existingUrlInfoToDelete.length > 0 ||
            !!this.urlInfoToAdd.find((uita) => !uita.deletePending)
        );
    }

    onToggleAddedUrlInfo(index) {
        this.urlInfoToAdd[index].deletePending =
            !this.urlInfoToAdd[index].deletePending;
    }

    onMergeUrlInfo(urlInfo) {
        if (urlInfo.id) {
            const infoCopy = [...this.existingUrlInfo];
            infoCopy[urlInfo.index] = {
                id: urlInfo.id,
                label: urlInfo.label,
                url: urlInfo.url,
                modified: true,
            };
            this.existingUrlInfo = infoCopy.sort((a, b) =>
                a.label < b.label ? -1 : 1
            );
            this.isUrlInfoListDirty = true;
        } else {
            if (urlInfo.index >= 0) {
                this.urlInfoToAdd[urlInfo.index] = {
                    index: urlInfo.index,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                };
            } else {
                const newIndex = this.urlInfoToAdd.length;
                this.urlInfoToAdd.push({
                    index: newIndex,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                });
            }
        }
    }
    deleteRelatedSampleFromProject(projectSampleId: number) {
        this.storeUi.dispatch(
            deleteRelatedSampleFromProject({
                projectSampleId: projectSampleId,
            })
        );
    }
    addRelatedSample() {
        this.storeUi.dispatch(clearRelatedSamplesSearchResults());
        const initialState = {
            project: this.currentProject,
        };
        this.modalRef = this.modalService.show(RelatedSamplesEditComponent, {
            initialState: initialState,
            class: 'modal-lg',
        });
    }
}
