<div>
    <div>
        <label>{{ widgetLabel }}</label>
        <div class="font-italic">
            {{
                isUrlInfoListDirty ? 'Click Save to store your URL changes' : ''
            }}
        </div>
        <div
            *ngIf="existingUrlInfo; else noAdditionalInfoBlock"
            id="projectUsers"
        >
            <table class="table table-light">
                <thead class="thead-light">
                    <tr>
                        <th *ngIf="editModeEnabled"></th>
                        <th *ngIf="editModeEnabled"></th>
                        <th *ngIf="gotUrls">Label</th>
                        <!--th>URL</th-->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let existing of existingUrlInfo; let i = index">
                        <td
                            *ngIf="editModeEnabled"
                            class="add-delete-icons"
                            (click)="toggleExistingUrlInfo.emit(existing.id)"
                        >
                            <i class="bi bi-trash3"></i>
                        </td>
                        <td *ngIf="editModeEnabled">
                            <div class="add-delete-icons">
                                <i
                                    *ngIf="
                                        !urlInfoToDelete.includes(existing.id)
                                    "
                                    class="bi bi-pencil-fill"
                                    (click)="
                                        openMergeUrlModal(
                                            addUrlModal,
                                            i,
                                            existing
                                        )
                                    "
                                ></i>
                            </div>
                        </td>
                        <!--td
                            [class]="
                                urlInfoToDelete.includes(existing.id)
                                    ? 'strikethrough'
                                    : ''
                            "
                        >
                            {{ existing.label }}
                        </td-->
                        <td>
                            <div
                                *ngIf="
                                    urlInfoToDelete.includes(existing.id);
                                    else linkBlock
                                "
                                class="strikethrough"
                            >
                                {{ existing.label }}
                            </div>
                            <ng-template #linkBlock>
                                <a [href]="existing.url" target="_blank">{{
                                    existing.label
                                }}</a
                                >&nbsp;
                                <i
                                    *ngIf="existing.url.includes('http')"
                                    class="bi-box-arrow-up-right"
                                ></i>
                            </ng-template>
                        </td>
                    </tr>

                    <tr *ngFor="let newUrl of urlInfoToAdd; let i = index">
                        <td *ngIf="editModeEnabled">
                            <div
                                class="add-delete-icons"
                                (click)="toggleAddedUrlInfo.emit(i)"
                            >
                                <i
                                    class="bi bi-trash3 pr-lg-5 text-success"
                                ></i>
                            </div>
                        </td>
                        <td *ngIf="editModeEnabled">
                            <div class="add-delete-icons">
                                <i
                                    *ngIf="!newUrl.deletePending"
                                    class="bi bi-pencil-fill pr-lg-5 text-success"
                                    (click)="
                                        openMergeUrlModal(
                                            addUrlModal,
                                            i,
                                            null,
                                            urlInfoToAdd[i]
                                        )
                                    "
                                ></i>
                            </div>
                        </td>
                        <td
                            class="text-success pr-3"
                            [class]="
                                newUrl.deletePending ? 'strikethrough' : ''
                            "
                        >
                            {{ newUrl.label }}
                        </td>
                        <td>
                            <div
                                *ngIf="
                                    newUrl.deletePending;
                                    else newUrlLinkBlock
                                "
                                class="text-success"
                                [class]="
                                    newUrl.deletePending ? 'strikethrough' : ''
                                "
                            >
                                {{ newUrl.url }}
                            </div>
                            <ng-template #newUrlLinkBlock>
                                <a
                                    class="text-success"
                                    [href]="newUrl.url"
                                    target="_blank"
                                    >{{ newUrl.url }}</a
                                >
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noAdditionalInfoBlock>
            <div *ngIf="!editModeEnabled">None</div>
        </ng-template>
    </div>
</div>

<div *ngIf="editModeEnabled" class="">
    <button
        type="button"
        class="btn btn-link"
        (click)="openMergeUrlModal(addUrlModal)"
    >
        <i class="bi bi-plus-lg"></i>
        Add URL
    </button>
</div>

<!-- Modal -->
<ng-template #addUrlModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ isAddUrl ? 'Add URL' : 'Edit URL' }}</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="onCancelClick()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body d-flex justify-content-center w-100">
        <div class="d-flex justify-content-center w-100">
            <div class="d-flex flex-column">
                <form [formGroup]="modalForm">
                    <div class="d-flex flex-row">
                        <div>
                            <input
                                placeholder="Label"
                                type="text"
                                formControlName="label"
                                class="form-control label-input"
                            />
                        </div>
                        <div class="ml-2">
                            <input
                                placeholder="URL"
                                type="url"
                                formControlName="url"
                                class="form-control url-input"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-end w-100 pt-3">
                        <div>
                            <button
                                type="button"
                                class="btn btn-outline-danger float-right"
                                (click)="onCancelClick()"
                            >
                                Cancel
                            </button>
                        </div>
                        <div class="ml-2">
                            <button
                                [disabled]="
                                    !editModeEnabled || modalForm.invalid
                                "
                                class="btn btn-primary float-md-right text-nowrap"
                                type="button"
                                (click)="onSaveClick()"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
