<ng-container *ngIf="showSpinner; else loaded">
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <mat-spinner diameter="125"></mat-spinner>
        </div>
    </div>
</ng-container>

<ng-template #loaded>
    <header>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand" routerLink="/">{{ title }}</a>
            <button
                class="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
                <span class="close">X</span>
            </button>
            <div
                class="collapse navbar-collapse expanded-navbar"
                id="navbarNav"
            >
                <div class="d-flex justify-content-between w-100">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                routerLink="/search"
                                routerLinkActive="active"
                                >Search</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                routerLink="/data-import"
                                routerLinkActive="active"
                                >Import</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                routerLink="/project-detail"
                                routerLinkActive="active"
                                >Project</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                routerLink="/study"
                                routerLinkActive="active"
                                >Study</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                href="https://bcdbio.slite.com/api/s/sPH-dC99OOd3QC/UDB-Wiki"
                                target="_blank"
                                >Wiki
                                <i class="bi-box-arrow-up-right"></i>
                            </a>
                        </li>
                    </ul>
                    <bcdbio-auth-nav></bcdbio-auth-nav>
                </div>
            </div>
        </nav>
    </header>
    <router-outlet></router-outlet>
</ng-template>
