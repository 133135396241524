<div class="container">
    <div class="top-buffer">
        <h2
            [ngClass]="{
                maxSources: sample.parentSamples.length >= maxSources
            }"
        >
            Number Spiked Sources: {{ sample.parentSamples.length }}
        </h2>
    </div>

    <div class="top-buffer">
        <h2>{{ searchType }} search</h2>
    </div>

    <bcdbio-search-bar
        (newSearchEvent)="searchForTerm($event)"
        [newval]="''"
    ></bcdbio-search-bar>

    <div class="top-buffer">
        <h2>Search results</h2>
        <table class="table table-hover">
            <thead class="thead-light">
                <tr>
                    <th>Sample (ID)</th>
                    <th>Add to Pool?</th>
                </tr>
            </thead>
            <ng-container
                *ngIf="
                    searchResults && searchResults.length > 0;
                    else noResultsBlock
                "
            >
                <tr *ngFor="let resultSample of searchResults">
                    <td>{{ resultSample.id }}</td>
                    <td>
                        <ng-container
                            *ngIf="
                                sampleIsNotCurrentInput(resultSample.id);
                                else alreadyAddedBlock
                            "
                        >
                            <button
                                class="btn btn-outline-primary float-md-left"
                                type="button"
                                [disabled]="
                                    sample.parentSamples.length >= maxSources
                                "
                                (click)="addPoolSource(resultSample)"
                            >
                                Add
                            </button>
                        </ng-container>
                        <ng-template #alreadyAddedBlock> Added </ng-template>
                    </td>
                </tr>
            </ng-container>
            <ng-template #noResultsBlock>
                <tr>
                    <td colspan="4">No matches.</td>
                </tr>
            </ng-template>
        </table>
        <ng-template #genericNaBlock> -- </ng-template>
        <ng-template #processNaBlock> Native material </ng-template>
        <ng-container
            *ngIf="
                searchResults &&
                    searchResults.length > 0 &&
                    !searchResultsExhausted;
                else noMoreResultsBlock
            "
        >
            <bcdbio-search-pagination
                (getMoreResultsEvent)="getMoreResults($event)"
            ></bcdbio-search-pagination>
        </ng-container>
        <ng-template #noMoreResultsBlock>
            <p *ngIf="searchResults && searchResults.length > 0">
                All results shown for the current search.
            </p>
        </ng-template>
    </div>
</div>
