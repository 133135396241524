import {
    Component,
    Input,
    OnChanges,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { Sample, SampleType, TAXONOMY_RANKS } from '@bcdbio/data';
import * as moment from 'moment';
import { startCase } from 'lodash';

export interface MetadataDisplay {
    name: string;
    value: number | string | Date;
}
@Pipe({
    name: 'metadataFormatPipe',
})
export class MetadataFormatPipe implements PipeTransform {
    transform(value: any): string {
        if (value instanceof Date) {
            const mmt = moment.utc(value);
            return mmt.isValid() ? mmt.format('M/D/YYYY') : '--';
        } else {
            return value.toString();
        }
    }
}

@Component({
    selector: 'bcdbio-sample-metadata',
    templateUrl: './sample-metadata.component.html',
    styleUrls: ['./sample-metadata.component.scss'],
})
export class SampleMetadataComponent implements OnChanges {
    @Input() sample: Sample;

    hasMetadata = false;
    orderedMetadata: MetadataDisplay[];

    constructor() {}

    ngOnChanges(): void {
        if (this.sample) {
            this.hasMetadata = this._hasMetaData(this.sample);
            this.orderedMetadata = this._orderMetadata(this.sample);
        } else {
            this.hasMetadata = false;
        }
    }

    private _hasMetaData(sample: Sample): boolean {
        return sample.metadata && Object.keys(sample.metadata).length > 0;
    }

    private _orderMetadata(sample: Sample): MetadataDisplay[] {
        const metadata = sample.metadata;

        if (sample.displayedSampleMetadata) {
            return sample.displayedSampleMetadata.reduce((acc, m) => {
                const obj = {
                    name: m,
                    value: metadata[m] || undefined,
                } as MetadataDisplay;
                acc.push(obj);
                return acc;
            }, []);
        } else {
            return [];
        }
    }
}
