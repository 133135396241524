import { GrowthCalculatedDataset } from './growth-calculated-dataset.model';
import { DataRow } from './data-row.model';

export class SingleStrainGrowthCalculatedDataset extends GrowthCalculatedDataset {
    defaultColumnOrder = [
        'Replicate',
        'Lag Time (hours)',
        'Max Specific Growth Rate (1/hours)',
        'Doubling Time (hours)',
        ...this.coreODColumnOrder,
        'Delta OD (Median Filtered Data)',
        'R^2',
        'RMSE',
        'Notes',
        ...this.additionalGrowthColumns,
    ];

    constructor(observationDate: Date, data?: Array<DataRow>, uuid?: string) {
        super(observationDate, data, uuid);
    }
}
