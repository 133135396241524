import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    getCurrentSample,
    singleSampleRequested,
    UiPartialState,
    upsertDeleteSampleUrlInfo,
} from '@bcdbio/ui';
import { FileDataset, ObservedDataset, Sample, SampleType } from '@bcdbio/data';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CreateProcessSampleComponent } from '../../components/create-process-sample/create-process-sample.component';
import { ProcessEditComponent } from '../../components/process-edit/process-edit.component';
import { SampleMetadataEditComponent } from '../../components/sample-metadata-edit/sample-metadata-edit.component';
import { DeleteSampleDataModalComponent } from '../../components/delete-sample-data-modal/delete-sample-data-modal.component';
import { AuthService } from '@auth0/auth0-angular';

export const ADMIN_USERS = [
    'rdrexler@bcdbio.com',
    'dblee@dayspringpartners.com',
    'mdenson@dayspringpartners.com',
];

@Component({
    selector: 'bcdbio-sample-detail',
    templateUrl: './sample-detail.component.html',
    styleUrls: ['./sample-detail.component.scss'],
})
export class SampleDetailComponent implements OnInit, OnDestroy {
    currentSample$: Observable<Sample> = this.storeUi.select(getCurrentSample);

    currentSample: Sample;
    subscriptions: Subscription = new Subscription();
    bsModalRef: BsModalRef;
    sampleType = SampleType;
    adminUsers = ADMIN_USERS;
    userIsAdmin: boolean;

    isEditingSampleUrls = false;
    isUrlInfoListDirty = false;
    urlInfoToAdd: {
        index: number;
        label: string;
        url: string;
        deletePending: boolean;
    }[] = [];
    existingUrlInfo: {
        id: number;
        label: string;
        url: string;
        modified: boolean;
    }[] = [];
    existingUrlInfoToDelete = [];

    constructor(
        private route: ActivatedRoute,
        private storeUi: Store<UiPartialState>,
        private modalService: BsModalService,
        public auth: AuthService
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.paramMap
                .pipe(
                    filter((params) => params.has('id')),
                    map((params) => params.get('id')),
                    tap((id) => {
                        this.updateCurrentSample(id);
                    })
                )
                .subscribe()
        );
        this.subscriptions.add(
            this.currentSample$.subscribe((currentSample) => {
                this.existingUrlInfo = [];
                currentSample?.sampleRows?.map((sampleRow) => {
                    this.existingUrlInfo.push({
                        id: sampleRow.id,
                        label: sampleRow.label,
                        url: sampleRow.url,
                        modified: false,
                    });
                });
                this.currentSample = currentSample;
            })
        );
        this.subscriptions.add(
            this.auth.user$.subscribe((user) => {
                this.userIsAdmin = ADMIN_USERS.includes(user.email);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateCurrentSample(sampleId) {
        this.storeUi.dispatch(
            singleSampleRequested({
                sampleId: sampleId,
            })
        );
    }

    createProcessAndSample(): void {
        const initialState = {
            sample: this.currentSample,
        };
        this.bsModalRef = this.modalService.show(CreateProcessSampleComponent, {
            initialState,
        });
    }

    editProcess(): void {
        const initialState = {
            process: this.currentSample.parentProcess,
        };
        this.bsModalRef = this.modalService.show(ProcessEditComponent, {
            initialState: initialState,
            class: 'modal-lg',
        });
    }

    editSampleMetadata(): void {
        const initialState = {
            sample: this.currentSample,
        };
        this.bsModalRef = this.modalService.show(SampleMetadataEditComponent, {
            initialState: initialState,
            class: 'modal-lg',
        });
    }

    isFileData(data: ObservedDataset[]): boolean {
        if (data.length > 0) {
            return data[0] instanceof FileDataset;
        }
        return false;
    }

    deleteSample(): void {
        const initialState = {
            sample: this.currentSample,
        };
        this.bsModalRef = this.modalService.show(
            DeleteSampleDataModalComponent,
            {
                initialState,
            }
        );
    }

    upsertDeleteSampleUrls() {
        const sample = this.currentSample;
        const infoToAdd = this.urlInfoToAdd
            .filter((uita) => !uita.deletePending)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    sampleBcdId: sample.id,
                };
            });
        const infoToEdit = this.existingUrlInfo
            .filter((eui) => eui.modified)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    sampleBcdId: sample.id,
                    id: info.id,
                };
            });
        setTimeout(() => {
            this.storeUi.dispatch(
                upsertDeleteSampleUrlInfo({
                    deleted: this.existingUrlInfoToDelete,
                    upserted: infoToAdd.concat(infoToEdit),
                    sampleBcdId: sample.id,
                })
            );
            this.urlInfoToAdd = [];
            this.existingUrlInfoToDelete = [];
        }, 0);
        this.isUrlInfoListDirty = false;
        this.isEditingSampleUrls = false;
    }

    onClickAddUrl(isAdding) {
        this.isUrlInfoListDirty = isAdding;
    }

    onToggleExistingUrlInfo(id) {
        const urlInfoIndex = this.existingUrlInfoToDelete.findIndex(
            (uitdId) => uitdId === id
        );
        if (urlInfoIndex >= 0) {
            this.existingUrlInfoToDelete.splice(urlInfoIndex, 1);
        } else {
            this.existingUrlInfoToDelete.push(id);
        }
        this.isUrlInfoListDirty = this.hasUrlInfoListChanged();
    }

    hasUrlInfoListChanged(): boolean {
        return (
            this.existingUrlInfoToDelete.length > 0 ||
            !!this.urlInfoToAdd.find((uita) => !uita.deletePending)
        );
    }

    cancelUrlInfoListEdits() {
        this.isEditingSampleUrls = false;
        this.isUrlInfoListDirty = false;
        this.urlInfoToAdd = [];
        this.existingUrlInfoToDelete = [];
    }

    onToggleAddedUrlInfo(index) {
        this.urlInfoToAdd[index].deletePending =
            !this.urlInfoToAdd[index].deletePending;
    }

    onMergeUrlInfo(urlInfo) {
        if (urlInfo.id) {
            const infoCopy = [...this.existingUrlInfo];
            infoCopy[urlInfo.index] = {
                id: urlInfo.id,
                label: urlInfo.label,
                url: urlInfo.url,
                modified: true,
            };
            this.existingUrlInfo = infoCopy.sort((a, b) =>
                a.label < b.label ? -1 : 1
            );
            this.isUrlInfoListDirty = true;
        } else {
            if (urlInfo.index >= 0) {
                this.urlInfoToAdd[urlInfo.index] = {
                    index: urlInfo.index,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                };
            } else {
                const newIndex = this.urlInfoToAdd.length;
                this.urlInfoToAdd.push({
                    index: newIndex,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                });
            }
        }
    }
}
