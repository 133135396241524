import { DataImportData, ImportMapping } from './data-import-data';
import { SampleType } from '../../model/sample.model';

export class DataImportDataSingleStrain extends DataImportData {
    public static MAPPING = [
        {
            importColumnHeader: 'kingdom',
            neo4jName: 'kingdom',
            validation: 'string',
        },
        {
            importColumnHeader: 'phylum',
            neo4jName: 'phylum',
            validation: 'string',
        },
        {
            importColumnHeader: 'class',
            neo4jName: 'class',
            validation: 'string',
        },
        {
            importColumnHeader: 'order',
            neo4jName: 'order',
            validation: 'string',
        },
        {
            importColumnHeader: 'family',
            neo4jName: 'family',
            validation: 'string',
        },
        {
            importColumnHeader: 'genus',
            neo4jName: 'genus',
            validation: 'string',
        },
        {
            importColumnHeader: 'species',
            neo4jName: 'species',
            validation: 'string',
        },
        {
            importColumnHeader: 'strain',
            neo4jName: 'strain',
            validation: 'string',
        },
        {
            importColumnHeader: 'other strain names',
            neo4jName: 'otherStrainNames',
            validation: 'string',
        },
        {
            importColumnHeader: 'nda/mta',
            neo4jName: 'ndaMta',
            validation: 'regexp:Yes|No',
        },
        {
            importColumnHeader: 'bsl',
            neo4jName: 'bsl',
            validation: 'string',
        },
        {
            importColumnHeader: 'media commercial',
            neo4jName: 'mediaCommercial',
            validation: 'string',
        },
        {
            importColumnHeader: 'media minimal',
            neo4jName: 'mediaMinimal',
            validation: 'string',
        },
        {
            importColumnHeader: 'ap time to late exp',
            neo4jName: 'apTimeToLateExp',
            validation: 'string',
        },
        {
            importColumnHeader: 'storage location',
            neo4jName: 'storageLocation',
            validation: 'string',
        },
        {
            importColumnHeader: 'propagation notes',
            neo4jName: 'propagationNotes',
            validation: 'string',
        },
    ] as ImportMapping[];

    constructor(data: Array<string[]>) {
        super(data);
        this.headers = DataImportDataSingleStrain.MAPPING.map(
            (m) => m.importColumnHeader
        );
        this.mapping = DataImportDataSingleStrain.MAPPING;
    }

    getName(headers: string[], row: string[]) {
        return (
            row[headers.indexOf('genus')]
                .toLowerCase()
                .substring(0, 3)
                .replace(' ', '-') +
            '-' +
            row[headers.indexOf('species')].toLowerCase().replace(' ', '-') +
            '-' +
            row[headers.indexOf('strain')].toLowerCase().replace(' ', '-')
        );
    }
    getType() {
        return SampleType.SingleStrain;
    }
}
