import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FileDataset, ObservedDataset, FileClient } from '@bcdbio/data';

@Component({
    selector: 'bcdbio-display-file-data',
    templateUrl: './display-file-data.component.html',
    styleUrls: ['./display-file-data.component.scss'],
})
export class DisplayFileDataComponent implements OnInit, OnChanges {
    @Input() displayData: ObservedDataset[];
    datasetTitle: string;
    sortedFileData: FileDataset[];

    ngOnInit(): void {
        this.datasetTitle = (<FileDataset>this.displayData[0]).dataType;
    }

    ngOnChanges(): void {
        this.sortedFileData = this.displayData
            .slice()
            .sort((a, b) => {
                const aTime = new Date(a.observationDate);
                const bTime = new Date(b.observationDate);
                return aTime.getTime() - bTime.getTime();
            })
            .map((d) => <FileDataset>d);
    }
}
