import { DataImportData } from '../data/data-import-data';
import { Observable } from 'rxjs';
import { DataImportType } from '../data-import-type';

export abstract class DataImportValidator {
    constructor() {}

    abstract validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }>;
}
