<ol class="bcd-ontology">
    <li class="bcd-ontology-link">
        (Primary Lay)
        <strong>{{ sample?.ontology?.primaryLayCategory }}</strong>
    </li>
    <li class="bcd-ontology-link">
        (Secondary Lay)
        <strong>{{ sample?.ontology?.secondaryLayCategory }}</strong>
    </li>
    <li class="bcd-ontology-link">
        (Plant Name) <strong>{{ sample?.ontology?.plantName }}</strong>
    </li>
    <li class="bcd-ontology-link">
        (Plant Part) <strong>{{ sample?.ontology?.plantPart }}</strong>
    </li>
</ol>
