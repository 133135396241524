import { Component, OnInit } from '@angular/core';
import { Output, Input, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'bcdbio-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
    @Output() newSearchEvent = new EventEmitter<string>();
    @Input() newval;

    searchForm: FormGroup;

    constructor() {}

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            searchTerm: new FormControl(this.newval, [
                Validators.required,
                Validators.minLength(1),
            ]),
        });
    }

    onSubmit() {
        this.newSearchEvent.emit(this.searchForm.get('searchTerm').value);
    }

    get searchTerm() {
        return this.searchForm.get('searchTerm');
    }
}
