import { DataImportData } from './data-import-data';

export class DataImportDataSource extends DataImportData {
    public static NUM_COLUMNS = 17;
    public static HEADERS = [
        'Data Source',
        'Unique Identification Number',
        'Lay Name (all details)',
        'Plant Part Analyzed',
        'Primary Lay Categorization',
        'Secondary Categorization',
        'Plant Name',
        'Variety or Unique IDs',
        'Source',
        'Location of raw material',
        'NDA or MTA Restrictions?',
        'Date Received',
        'From Person',
        'Phone',
        'From Address',
        'Additional information about sample',
        'Notes',
    ];

    public getHeaderRow(): string[] {
        return this.data && this.data.length > 0 ? this.data[0] : null;
    }

    public getDataRows(): Array<string[]> {
        if (this.data && this.data.length > 0) {
            // filter out rows without 'Unique Identifier Number'
            return this.data
                .slice(1)
                .filter((row) => row[1] && row[1].length > 0)
                .map((row) => row.map((val) => val.trim()));
        } else {
            return [];
        }
    }
}
