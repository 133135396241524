<div class="container mt-3 mb-1">
    <button
        class="btn btn-outline-primary float-md-right"
        type="button"
        (click)="clickCreateNew.emit(true)"
    >
        Create New Project
    </button>

    <div class="d-flex">
        <h2>Project List</h2>
        <a
            class="nav-link"
            href="https://bcdbio.slite.com/api/s/OWYILJnefnTkR3/Projects"
            target="_blank"
        >
            <i class="bi-box-arrow-up-right"></i>
        </a>
    </div>
    <table class="table table-hover">
        <thead class="thead-light">
            <tr>
                <th>Project Name</th>
                <th>Client</th>
                <th>Objective</th>
            </tr>
        </thead>
        <ng-container
            *ngIf="projects && projects.length > 0; else noResultsBlock"
        >
            <ng-container *ngIf="projects.length > 0">
                <tr *ngFor="let p of projects">
                    <td>
                        <a [routerLink]="['/project-detail', p.projectId]">
                            {{ p.projectName }}
                        </a>
                    </td>
                    <td>
                        {{ p.client }}
                    </td>
                    <td>
                        {{ p.objective }}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
        <ng-template #noResultsBlock>
            <tr>
                <td colspan="4">No projects created.</td>
            </tr>
        </ng-template>
    </table>
</div>
