<div *ngFor="let poolSource of orderedMetadata">
    <div class="metadata-group">
        <ng-container *ngFor="let field of poolSource">
            <div *ngIf="field.value">
                <p class="metadata-value">
                    {{ field.value.toString() }}
                </p>
            </div>
        </ng-container>
    </div>
</div>
