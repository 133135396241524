<div class="metadata-group" [formGroup]="form">
    <ng-container formArrayName="sources">
        <ng-container
            *ngFor="let poolSource of sources.controls; let i = index"
        >
            <ng-container [formGroup]="poolSource">
                <div>
                    <input formControlName="truncSourceName" />
                </div>
                <div>
                    <input formControlName="concentration" />
                </div>
                <div>
                    <input formControlName="ulAdded" />
                </div>
                <div>
                    <input formControlName="sex" />
                </div>
                <div>
                    <input formControlName="healthStatus" />
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<div class="row">
    <div class="col-sm-12">
        <button
            class="btn btn-link float-md-right"
            type="button"
            (click)="cancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary float-md-right"
            type="button"
            (click)="save()"
        >
            Save
        </button>
    </div>
</div>
