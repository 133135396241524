import { Pipe, PipeTransform } from '@angular/core';
import { FileClient } from '@bcdbio/data';
import { Observable } from 'rxjs';

@Pipe({
    name: 'filePath',
})
export class FilePathPipe implements PipeTransform {
    constructor(private fileClient: FileClient) {}

    transform(path: string): Observable<string> {
        return this.fileClient.getSignedUrl(path);
    }
}
