import { Component, Input, OnChanges } from '@angular/core';
import {
    DataImportType,
    DataRow,
    DatasetSummaryStats,
    FreeMonoDataset,
    GenericDataset,
    LinkageDataset,
    MetabAnalytesDataset,
    MonoDataset,
    ObservedDataset,
} from '@bcdbio/data';
import { GrowthCalculatedDataset } from '@bcdbio/data';
import { formatDate } from '@angular/common';
import { DeleteSampleDataModalComponent } from '../delete-sample-data-modal/delete-sample-data-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bcdbio-display-tabular-data',
    templateUrl: './display-tabular-data.component.html',
    styleUrls: ['./display-tabular-data.component.scss'],
})
export class DisplayTabularDataComponent implements OnChanges {
    @Input() displayData: ObservedDataset[] = [];
    @Input() userIsAdmin: boolean;

    bsModalRef: BsModalRef;
    datasetTitle: string;
    displayTableTotalColumns: number;
    headings: string[] = [];
    sortedDisplayData: ObservedDataset[] = [];
    summaryStatsForSummaryTab: DatasetSummaryStats;

    abundanceOrdering = false;
    normalizeData = false;
    isGrowthCurveDataset = false;
    isMonoDataset = false;
    isLinkageDataset = false;
    showNormalizationAbundanceToggle = false;

    type(val): string {
        return typeof val;
    }

    constructor(private modalService: BsModalService) {}

    ngOnChanges(): void {
        this.datasetTitle = this.getDatasetTitle();
        this.displayTableTotalColumns =
            this.displayData.length > 0
                ? this.displayData[0].defaultColumnOrder.length
                : 1;
        this.isGrowthCurveDataset =
            this.displayData[0] instanceof GrowthCalculatedDataset;
        this.isMonoDataset = this.displayData[0] instanceof MonoDataset;
        this.isLinkageDataset = this.displayData[0] instanceof LinkageDataset;

        //
        // Generate stats for summary tab
        //

        const allDataRows: Array<DataRow> = [];
        const allMonoMethodTypes: Array<String> = [];
        const allSOPVersions: Array<String> = [];
        const nullDate = null;
        const tempDisplayData: ObservedDataset[] = []; // If Viscozyme post-processing for Mono data.

        this.displayData.forEach((dataset) => {
            dataset.data.forEach((dataRow) => {
                allDataRows.push(Object.assign({}, dataRow));
            });

            if (this.isMonoDataset) {
                dataset.monoMethodTypes.forEach((String) => {
                    allMonoMethodTypes.push(String);
                });

                dataset.sopVersions.forEach((String) => {
                    allSOPVersions.push(String);
                });

                if (dataset.monoMethodTypes.includes('Vz Blank')) {
                    // Handle special Viscozyme data post processing.
                    const viscoProcessed = dataset.processViscoData(); // Returns ObservedDataset
                    tempDisplayData.push(viscoProcessed);
                    viscoProcessed.data.forEach((dataRow) => {
                        allDataRows.push(Object.assign({}, dataRow));
                    });
                } else {
                    tempDisplayData.push(dataset);
                    dataset.data.forEach((dataRow) => {
                        allDataRows.push(Object.assign({}, dataRow));
                    });
                }
            } else {
                dataset.data.forEach((dataRow) => {
                    allDataRows.push(Object.assign({}, dataRow));
                });

                dataset.sopVersions.forEach((String) => {
                    allSOPVersions.push(String);
                });
            }
        });

        if (this.isMonoDataset) {
            this.displayData = tempDisplayData;
        }

        this.summaryStatsForSummaryTab = new GenericDataset(
            nullDate,
            allDataRows
        ).getSummaryStats();
        this.showNormalizationAbundanceToggle = this.displayData.some(
            (dataset) => dataset.hasSummaryStats
        );

        this.sortedDisplayData = this.displayData.slice().sort((a, b) => {
            const aTime = new Date(a.observationDate);
            const bTime = new Date(b.observationDate);
            return aTime.getTime() - bTime.getTime();
        });

        this.headings = this.sortedDisplayData.map((dataset) => {
            if (dataset instanceof GrowthCalculatedDataset) {
                return (
                    'Version ' +
                    String(dataset.data[0][GrowthCalculatedDataset.VERSION_KEY])
                );
            } else {
                return dataset.observationDate != null
                    ? formatDate(
                          dataset.observationDate,
                          'M/d/y',
                          'en-US',
                          'GMT'
                      )
                    : 'Date N/A';
            }
        });
    }

    getDatasetTitle(): string {
        // NOTE: FreeMono and Mono else if ordering matters b/c FreeMono is a sub-class of Mono
        const dataset = this.displayData[0];
        if (dataset instanceof LinkageDataset) {
            return DataImportType.LINKAGE;
        } else if (dataset instanceof FreeMonoDataset) {
            return DataImportType.FREE_MONO;
        } else if (dataset instanceof MonoDataset) {
            return DataImportType.MONO;
        } else if (dataset instanceof GrowthCalculatedDataset) {
            return DataImportType.GROWTH_CURVE;
        } else if (dataset instanceof MetabAnalytesDataset) {
            return DataImportType.METABOLOMICS_ANALYTES;
        } else {
            return 'Unknown';
        }
    }

    toggleAbundanceOrdering(): void {
        this.abundanceOrdering = !this.abundanceOrdering;
    }

    toggleDataNormalization(): void {
        this.normalizeData = !this.normalizeData;
        if (this.normalizeData) {
            this.hideTotalsColumn();
        } else {
            this.showTotalsColumn();
        }
    }

    hideTotalsColumn(): void {
        this.displayTableTotalColumns -= 1;
    }

    showTotalsColumn(): void {
        this.displayTableTotalColumns += 1;
    }

    deleteTabData(dataset, datasetTitle): void {
        const initialState = {
            measurement: dataset,
            datasetTitle: datasetTitle,
        };
        this.bsModalRef = this.modalService.show(
            DeleteSampleDataModalComponent,
            { initialState }
        );
    }
}
