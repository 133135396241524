import { Component, OnInit, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
import {
    addStudyToProject,
    createNewStudy,
    deleteStudy,
    deleteStudyFromProject,
    editStudy,
    getCurrentStudy,
    getProjects,
    projectListRequested,
    singleStudyRequested,
    UiPartialState,
    upsertDeleteStudyUrlInfo,
} from '@bcdbio/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { StudyApiService } from '@bcdbio/data';
import { Study, Sample, Project } from '@bcdbio/udb-graphql';
import { ObservedDataset, Sample as SampleModel } from '@bcdbio/data';

export interface SampleWithObservedData extends Sample {
    observedData: { [key: string]: Array<ObservedDataset> };
}

@Component({
    selector: 'bcdbio-study-detail',
    templateUrl: './study-detail.component.html',
    styleUrls: ['./study-detail.component.scss'],
})
export class StudyDetailComponent implements OnInit {
    createNew = false;
    addToProjectId = new FormControl(null);
    existingProjectIds: string[];

    studyForm = new FormGroup({
        studyId: new FormControl(null),
        studyName: new FormControl('', [
            Validators.required,
            Validators.minLength(1),
        ]),
        startDate: new FormControl(null),
        endDate: new FormControl(null),
        objective: new FormControl(''),
        notes: new FormControl(''),
    });

    initialFormValues: Study;
    currentStudy$: Observable<Study> = this.storeUi.select(getCurrentStudy);
    subscriptions = new Subscription();
    modalRef: BsModalRef;
    samplesWithObservedData: SampleWithObservedData[];
    projects$: Observable<Project[]> = this.storeUi.select(getProjects);
    isUrlInfoListDirty = false;
    urlInfoToAdd: {
        index: number;
        label: string;
        url: string;
        deletePending: boolean;
    }[] = [];
    existingUrlInfo: {
        id: number;
        label: string;
        url: string;
        modified: boolean;
    }[] = [];
    existingUrlInfoToDelete = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storeUi: Store<UiPartialState>,
        private studyApiService: StudyApiService,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.storeUi.dispatch(projectListRequested());
        this.route.paramMap
            .pipe(map((params) => params.get('id')))
            .subscribe((id) => {
                if (id === 'new') {
                    this.createNew = true;
                    this.studyForm.enable();
                    this.storeUi.dispatch(
                        singleStudyRequested({
                            studyId: null,
                        })
                    );
                    this.subscriptions.add(
                        this.studyApiService
                            .createBcdId()
                            .subscribe((bcdId) => {
                                this.studyForm.patchValue({
                                    studyId: bcdId,
                                });
                            })
                    );
                } else {
                    this.createNew = false;
                    this.studyForm.disable();
                    this.storeUi.dispatch(
                        singleStudyRequested({
                            studyId: id,
                        })
                    );
                }
            });

        this.subscriptions.add(
            this.currentStudy$.subscribe((currentStudy) => {
                if (currentStudy) {
                    this.studyForm.disable();
                    this.studyForm.patchValue({
                        startDate: currentStudy.startDate,
                        endDate: currentStudy.endDate,
                        studyId: currentStudy.studyId,
                        studyName: currentStudy.studyName,
                        objective: currentStudy.objective,
                        notes: currentStudy.notes,
                    });
                    this.existingUrlInfo = [];
                    currentStudy.study_urls.map((su) => {
                        this.existingUrlInfo.push({
                            id: su.id,
                            label: su.label,
                            url: su.url,
                            modified: false,
                        });
                    });
                    this.initialFormValues = currentStudy;
                    const studyNode = currentStudy.studyNodes[0];
                    if (studyNode) {
                        this.samplesWithObservedData =
                            currentStudy.studyNodes[0].samples.map((sample) => {
                                const sampleWithComputedData =
                                    SampleModel.fromGQLData(sample);
                                return {
                                    ...sample,
                                    id: sample.bcdId,
                                    observedData:
                                        sampleWithComputedData.observedData,
                                };
                            });
                    }
                    this.existingProjectIds = currentStudy.relatedProjects?.map(
                        (p) => p.project?.projectId
                    );
                }
            })
        );
    }

    cancelEdits() {
        this.studyForm.disable();
        this.studyForm.patchValue({
            startDate: this.initialFormValues.startDate,
            endDate: this.initialFormValues.endDate,
            studyId: this.initialFormValues.studyId,
            studyName: this.initialFormValues.studyName,
            objective: this.initialFormValues.objective,
            notes: this.initialFormValues.notes,
        });
        this.isUrlInfoListDirty = false;
        this.urlInfoToAdd = [];
        this.existingUrlInfoToDelete = [];
    }

    goToStudyList() {
        this.router.navigate(['/study']);
    }

    addToProject() {
        if (this.addToProjectId.value) {
            this.storeUi.dispatch(
                addStudyToProject({
                    studyId: this.studyForm.get('studyId').value,
                    projectId: this.addToProjectId.value,
                })
            );
        }
    }

    deleteFromProject(studyProjectId: number, studyId: string) {
        this.storeUi.dispatch(
            deleteStudyFromProject({
                studyProjectId: studyProjectId,
                studyId,
            })
        );
    }

    exportStudy(study: Study) {}

    openDeleteConfirmation(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    deleteStudy() {
        this.modalRef.hide();
        this.storeUi.dispatch(
            deleteStudy({
                studyId: this.studyForm.get('studyId').value,
            })
        );
    }

    saveOrCreateStudy() {
        const study = this.studyForm.value;
        if (study.startDate) {
            study.startDate = moment(study.startDate).format('l');
        }
        if (study.endDate) {
            study.endDate = moment(study.endDate).format('l');
        }

        if (this.createNew) {
            this.storeUi.dispatch(
                createNewStudy({
                    study,
                    studyId: study.studyId,
                })
            );
        } else {
            this.storeUi.dispatch(
                editStudy({
                    study,
                })
            );
        }
        const infoToAdd = this.urlInfoToAdd
            .filter((uita) => !uita.deletePending)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    studyId: study.studyId,
                };
            });
        const infoToEdit = this.existingUrlInfo
            .filter((eui) => eui.modified)
            .map((info) => {
                return {
                    label: info.label,
                    url: info.url,
                    studyId: study.studyId,
                    id: info.id,
                };
            });
        setTimeout(() => {
            this.storeUi.dispatch(
                upsertDeleteStudyUrlInfo({
                    deleted: this.existingUrlInfoToDelete,
                    upserted: infoToAdd.concat(infoToEdit),
                    studyId: study.studyId,
                })
            );
            this.urlInfoToAdd = [];
            this.existingUrlInfoToDelete = [];
        }, 0);
        this.isUrlInfoListDirty = false;
    }

    onToggleExistingUrlInfo(id) {
        const urlInfoIndex = this.existingUrlInfoToDelete.findIndex(
            (uitdId) => uitdId === id
        );
        if (urlInfoIndex >= 0) {
            this.existingUrlInfoToDelete.splice(urlInfoIndex, 1);
        } else {
            this.existingUrlInfoToDelete.push(id);
        }
        this.isUrlInfoListDirty = this.hasUrlInfoListChanged();
    }

    hasUrlInfoListChanged(): boolean {
        return (
            this.existingUrlInfoToDelete.length > 0 ||
            !!this.urlInfoToAdd.find((uita) => !uita.deletePending)
        );
    }

    onToggleAddedUrlInfo(index) {
        this.urlInfoToAdd[index].deletePending =
            !this.urlInfoToAdd[index].deletePending;
    }

    onMergeUrlInfo(urlInfo) {
        if (urlInfo.id) {
            const infoCopy = [...this.existingUrlInfo];
            infoCopy[urlInfo.index] = {
                id: urlInfo.id,
                label: urlInfo.label,
                url: urlInfo.url,
                modified: true,
            };
            this.existingUrlInfo = infoCopy.sort((a, b) =>
                a.label < b.label ? -1 : 1
            );
            this.isUrlInfoListDirty = true;
        } else {
            if (urlInfo.index >= 0) {
                this.urlInfoToAdd[urlInfo.index] = {
                    index: urlInfo.index,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                };
            } else {
                const newIndex = this.urlInfoToAdd.length;
                this.urlInfoToAdd.push({
                    index: newIndex,
                    label: urlInfo.label,
                    url: urlInfo.url,
                    deletePending: false,
                });
            }
        }
    }
}
