<div class="modal-header">
    <h4 class="modal-title pull-left">Create Process and Sample</h4>
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="bsModalRef.hide()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label for="processType">Process Type</label>
        <select
            class="form-control"
            id="processType"
            [formControl]="processType"
        >
            <option *ngFor="let process of processOptions" [value]="process">
                {{ process }}
            </option>
        </select>
        <label for="descriptiveText">Descriptive text</label>
        <input
            class="form-control"
            type="text"
            [formControl]="descriptiveText"
            id="descriptiveText"
        />
        <label for="notebookPage">Notebook page</label>
        <input
            class="form-control"
            type="text"
            [formControl]="notebookPage"
            id="notebookPage"
        />
        <label for="datePrepared">Date Prepared</label>
        <input
            class="form-control"
            [formControl]="datePrepared"
            bsDatepicker
            autocomplete="off"
            [bsConfig]="{
                dateInputFormat: 'MM/DD/YYYY',
                showWeekNumbers: false
            }"
            id="datePrepared"
        />
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        (click)="createProcessAndSample()"
    >
        Create
    </button>
    <button type="button" class="btn btn-link" (click)="bsModalRef.hide()">
        Close
    </button>
</div>
