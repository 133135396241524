<div *ngIf="process.metadata">
    <div class="metadata-group">
        <div>
            <label>ID:</label>
            <p class="metadata-value">{{ process.id }}</p>
        </div>
        <div>
            <label>Type:</label>
            <p class="metadata-value">{{ process.type }}</p>
        </div>
    </div>
    <ng-container *ngFor="let mdg of metadataGroups">
        <div class="metadata-group" *ngIf="groups[mdg]">
            <h4>{{ mdg }}</h4>
            <ng-container *ngFor="let md of process.metadata[mdg] | keyvalue">
                <div *ngIf="md.value">
                    <label class="metadata-label">
                        {{
                            md.key +
                                '' +
                                (process.metadataUnits &&
                                process.metadataUnits[mdg][md.key]
                                    ? ' (' +
                                      process.metadataUnits[mdg][md.key] +
                                      ')'
                                    : '')
                        }}:
                    </label>
                    <p class="metadata-value">{{ md.value || '--' }}</p>
                </div>
            </ng-container>
            <div *ngIf="percentEthanol">
                <label class="metadata-label">% Ethanol:</label>
                <p class="metadata-value">
                    {{ percentEthanol | percent : '.2' }}
                </p>
            </div>
        </div>
    </ng-container>
</div>
