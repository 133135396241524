import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Papa } from 'ngx-papaparse';

import { Observable, Subscription } from 'rxjs';
import {
    setExportSampleIds,
    getExportSampleIds,
    UiPartialState,
} from '@bcdbio/ui';
import { Store } from '@ngrx/store';
import { Sample, SampleApiService } from '@bcdbio/data';
import { forEach } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'bcdbio-export-selected-samples',
    templateUrl: './export-selected-samples.component.html',
    styleUrls: ['./export-selected-samples.component.scss'],
})
export class ExportSelectedSamplesComponent implements OnInit, OnDestroy {
    @Input() samplesToExport: Array<string>;
    @Input() buttonLabel = 'Export data';
    @Input() primaryButtonStyle = true;
    @Input() includeMetadata = false;
    @Input() studyId: string;

    searchResults$: Observable<string[]>;
    searchResultsSub: Subscription;
    samplesSelectedToExport: Array<Sample> = [];
    select_msg: string = 'Remove All';
    checkREM_ALL: boolean;

    constructor(
        private storeUi: Store<UiPartialState>,
        private papa: Papa,
        private sampleApiService: SampleApiService,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.searchResults$ = this.storeUi.select(getExportSampleIds);
        this.setExportSamples();
    }

    ngOnDestroy() {
        this.searchResultsSub.unsubscribe();
        console.log(this.samplesSelectedToExport);
    }

    setExportSamples() {
        let sampServ = this.sampleApiService;
        let samplesSelectedToExport: Array<Sample> = [];
        this.searchResultsSub = this.searchResults$.subscribe((sampids) => {
            forEach(sampids, function (sampid, key) {
                if (sampid) {
                    sampServ.getSampleDetail(sampid).subscribe((tempSample) => {
                        samplesSelectedToExport.push(tempSample);
                    });
                }
            });
        });
        this.samplesSelectedToExport = samplesSelectedToExport;
        console.log(this.samplesSelectedToExport);
    }

    removeSamples() {
        for (let sampid of this.samplesToExport) {
            let childElem = <HTMLInputElement>(
                document.getElementById('REM_' + sampid + '-input')
            );
            if (childElem.checked)
                if (this.samplesToExport.indexOf(sampid) > -1) {
                    this.samplesToExport = this.samplesToExport
                        .slice(0, this.samplesToExport.indexOf(sampid))
                        .concat(
                            this.samplesToExport.slice(
                                this.samplesToExport.indexOf(sampid) + 1
                            )
                        );
                }
        }
        let tempExp = [];
        tempExp = tempExp.concat(this.samplesToExport);
        this.storeUi.dispatch(
            setExportSampleIds({
                exportSampleIds: tempExp,
            })
        );
        this.setExportSamples();
    }
    checkAll() {
        let mode = 'select';
        this.select_msg = 'De-Select All';

        if (!this.checkREM_ALL) {
            mode = 'deselect';
            this.select_msg = 'Remove All';
        }
        for (let sampid of this.samplesToExport) {
            let childElem = <HTMLInputElement>(
                document.getElementById('REM_' + sampid + '-input')
            );
            childElem.checked = mode === 'select';
        }
    }

    exportDone(event) {
        let tempExp = [];
        if (event) {
            this.storeUi.dispatch(
                setExportSampleIds({
                    exportSampleIds: tempExp,
                })
            );
            this.setExportSamples();
        }
        this.close();
    }

    close() {
        this.modalService.hide();
    }
}
