<ng-container *ngIf="{ study: (currentStudy$ | async) } as data">
    <div class="container bottom-buffer" style="padding-bottom: 100px">
        <div class="row top-buffer">
            <div class="col-md-6">
                <button
                    class="btn btn-outline-danger float-md-left"
                    type="button"
                    (click)="goToStudyList()"
                >
                    Back To Studies
                </button>
            </div>
            <div class="col-md-6">
                <button
                    class="btn btn-outline-primary float-md-right"
                    type="button"
                    (click)="exportStudy(data.study)"
                >
                    Export Project
                </button>
            </div>
        </div>

        <form [formGroup]="studyForm">
            <div class="row top-buffer">
                <div class="col-md-6">
                    <h2 *ngIf="studyForm.disabled">
                        {{ studyForm.get('studyId').value }}
                    </h2>
                </div>
            </div>

            <div class="top-buffer bcd-info-panel">
                <div class="row">
                    <div class="col-sm-6">
                        <h3>Study Information</h3>
                    </div>
                    <div class="col-sm-6">
                        <button
                            *ngIf="!createNew && studyForm.disabled"
                            class="btn btn-danger float-md-right ml-1"
                            type="button"
                            (click)="openDeleteConfirmation(deleteConfirmation)"
                        >
                            Delete
                        </button>
                        <button
                            *ngIf="!createNew && studyForm.enabled"
                            class="btn btn-outline-secondary float-md-right ml-1"
                            type="button"
                            (click)="cancelEdits()"
                        >
                            Cancel
                        </button>
                        <button
                            *ngIf="studyForm.disabled"
                            class="btn btn-outline-primary float-md-right"
                            type="button"
                            (click)="studyForm.enable()"
                        >
                            Edit
                        </button>
                        <button
                            *ngIf="studyForm.enabled"
                            class="btn btn-primary float-md-right"
                            type="button"
                            (click)="saveOrCreateStudy()"
                        >
                            Save
                        </button>
                    </div>
                </div>

                <label for="studyName">Study Name: </label>
                <input
                    formControlName="studyName"
                    class="form-control"
                    type="text"
                    id="studyName"
                />

                <div class="d-flex">
                    <div class="mr-2">
                        <label for="startDate">Start Date</label>
                        <input
                            class="form-control"
                            formControlName="startDate"
                            bsDatepicker
                            [bsConfig]="{
                                dateInputFormat: 'l',
                                showWeekNumbers: false
                            }"
                            id="startDate"
                        />
                    </div>
                    <div class="mr-2">
                        <label for="startDate">End Date</label>
                        <input
                            class="form-control"
                            formControlName="endDate"
                            bsDatepicker
                            [bsConfig]="{
                                dateInputFormat: 'l',
                                showWeekNumbers: false
                            }"
                            id="endDate"
                        />
                    </div>
                </div>

                <label for="objective">Objective</label>
                <textarea
                    class="form-control"
                    type="text"
                    formControlName="objective"
                    id="objective"
                >
                </textarea>

                <label for="notes">Notes</label>
                <textarea
                    class="form-control"
                    type="text"
                    formControlName="notes"
                    id="notes"
                >
                </textarea>

                <bcdbio-url-info-widget
                    [editModeEnabled]="studyForm.enabled"
                    [existingUrlInfo]="existingUrlInfo"
                    [urlInfoToAdd]="urlInfoToAdd"
                    [urlInfoToDelete]="existingUrlInfoToDelete"
                    [isUrlInfoListDirty]="isUrlInfoListDirty"
                    (toggleExistingUrlInfo)="onToggleExistingUrlInfo($event)"
                    (toggleAddedUrlInfo)="onToggleAddedUrlInfo($event)"
                    (mergeUrlInfo)="onMergeUrlInfo($event)"
                >
                </bcdbio-url-info-widget>
            </div>

            <div class="top-buffer bcd-info-panel">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>Related Project(s)</h3>
                    <div class="d-flex align-items-center">
                        <select
                            class="form-control"
                            id="processType"
                            [formControl]="addToProjectId"
                            [style.width]="'140px'"
                        >
                            <option selected disabled [value]="null">
                                Select...
                            </option>
                            <option
                                *ngFor="let project of projects$ | async"
                                [value]="project.projectId"
                                [disabled]="
                                    existingProjectIds?.includes(
                                        project.projectId
                                    )
                                "
                            >
                                {{ project.projectName }}
                            </option>
                        </select>
                        <button
                            [disabled]="!addToProjectId.value"
                            class="btn btn-primary float-md-right"
                            type="button"
                            (click)="addToProject()"
                        >
                            Add
                        </button>
                    </div>
                </div>
                <ul *ngIf="data.study?.relatedProjects; else noData">
                    <li *ngFor="let p of data.study?.relatedProjects">
                        <h5 [style.display]="'inline'">
                            <a
                                [routerLink]="[
                                    '/project-detail',
                                    p.project?.projectId
                                ]"
                            >
                                {{ p.project?.projectName }}
                            </a>
                        </h5>
                        <span
                            class="delete-icon"
                            [style.cursor]="'pointer'"
                            (click)="deleteFromProject(p.id, p.study?.studyId)"
                        >
                            <i class="bi bi-trash3"></i>
                        </span>
                    </li>
                </ul>

                <ng-template #noData>
                    <p>Study not added to any project.</p>
                </ng-template>
            </div>

            <div class="top-buffer bcd-info-panel">
                <h3>Experiments</h3>
                <div *ngIf="data.study; else noSampleData">
                    <bcdbio-selectable-table
                        [trayNames]="data.study.trays | pluck : 'value'"
                        [samples]="samplesWithObservedData"
                        [studyId]="studyForm.get('studyId').value"
                    ></bcdbio-selectable-table>
                </div>
                <ng-template #noSampleData>
                    <p>No trays added.</p>
                </ng-template>
            </div>
        </form>
    </div>
</ng-container>

<!-- Modal -->
<ng-template #deleteConfirmation>
    <div class="modal-header">
        <h4 class="modal-title">Are you sure you want to delete?</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="modalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <button
            type="button"
            class="btn float-right ml-1 btn-danger"
            (click)="deleteStudy()"
        >
            Yes, Delete
        </button>
        <button
            type="button"
            class="btn btn-outline-primary float-right"
            (click)="modalRef.hide()"
        >
            Cancel
        </button>
    </div>
</ng-template>
