import { GenericDataset } from './generic-dataset.model';
import { DataRow } from './data-row.model';

export class GrowthCalculatedDataset extends GenericDataset {
    public static VERSION_KEY = 'Version';

    hasSummaryStats = false;
    coreODColumnOrder = [
        'Max OD',
        'Max OD (Median Filtered Data)',
        'Min OD',
        'Min OD (Median Filtered Data)',
    ];
    additionalGrowthColumns = [
        'Delta_OD / Positive_Control_Delta_OD',
        'Growth Rating',
        'Assay',
        'Bug',
        'Glycan',
        'Media',
    ];
    defaultColumnOrder = [
        'Replicate',
        'Lag Time (hours)',
        'Max Specific Growth Rate (1/hours)',
        'Doubling Time (hours)',
        ...this.coreODColumnOrder,
        'Delta OD (Median Filtered Data)',
        'R^2',
        'RMSE',
        'Notes',
        ...this.additionalGrowthColumns,
    ];

    constructor(observationDate: Date, data?: Array<DataRow>, uuid?: string) {
        super(observationDate, data, uuid);
    }

    getGQLMeasurementInputs(): any[] {
        // make sure all analytes are in input obj
        return this.data.map((measurements) => {
            this.defaultColumnOrder.forEach((analyte) => {
                if (!(analyte in measurements)) {
                    measurements[analyte] = null;
                }
            });
            return measurements;
        });
    }

    public addDataRowFromGQLMeasurement(measurementFromGql) {
        // console.log("given gql measurement to add as a DataRow:", measurementFromGql);
        const dataRow =
            GenericDataset.flattenGQLMeasurementData(measurementFromGql);
        const dataRowContainer = [dataRow];
        // the following line is removed to prevent excess columns showing up in table
        // this.appendFieldsToDefaultColumnOrder(dataRowContainer);
        this.markMissingFieldsNull(dataRowContainer);
        this.data.push(dataRow);
        // console.log("added row to dataset, current data object:", this.data);
    }
}
