import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { UiPartialState, saveProcessMetadata } from '@bcdbio/ui';
import { Process, Metadata, ProcessApiService } from '@bcdbio/data';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'bcdbio-process-edit',
    templateUrl: './process-edit.component.html',
    styleUrls: ['./process-edit.component.scss'],
})
export class ProcessEditComponent implements OnInit {
    form = new FormGroup({
        section: new FormControl(''),
    });

    onSectionChange() {
        // Your logic here to handle the section change, if needed
    }

    process: Process;
    subscriptions = new Subscription();
    generalMetadata: Metadata[];

    //
    // TODO: put all of these in an array, then clean up process-edit.component.html
    //

    processTypeMetadata: Metadata[];
    processTypeMetadataB: Metadata[];
    processTypeMetadata_AE: Metadata[]; // rtk Acid Extraction
    processTypeMetadata_BE: Metadata[]; // rtk Base Extraction
    processTypeMetadata_ET: Metadata[]; // rtk Enzyme Treatment
    processTypeMetadata_CF: Metadata[]; // rtk Centrifugal Filter
    processTypeMetadata_Other: Metadata[];

    processTypeMetadata_COG: Metadata[]; // rtk COG -- support original COG data
    processTypeMetadata_COG_F: Metadata[]; // rtk COG (Fenton)
    processTypeMetadata_COG_BC: Metadata[]; // rtk COG (Base Cleavage)

    // Post-processing
    processTypeMetadata_Post_Ethanol: Metadata[]; // Ethanol
    processTypeMetadata_Post_Crystal: Metadata[]; // Crystallization
    processTypeMetadata_Post_MicroFilt: Metadata[]; // Microfilter
    processTypeMetadata_Post_CentrFilt: Metadata[]; // Centrifugal Filter
    processTypeMetadata_Post_UltraFilt: Metadata[]; // Ultra Filteration
    processTypeMetadata_Post_NanoFilt: Metadata[]; // Nano Filtration
    processTypeMetadata_Post_SprayDry: Metadata[]; // Spray Drying
    processTypeMetadata_Post_Other: Metadata[]; // Other

    microBioMetadata: Metadata[];
    showMicrobioMetadata: boolean;

    // TODO put all of this into an array.
    processEditForm = this.fb.group({
        generalMetadata: this.fb.array([]),
        processTypeMetadata: this.fb.array([]),
        processTypeMetadataB: this.fb.array([]), // original Solvent Extraction
        processTypeMetadata_AE: this.fb.array([]), // rtk Acid Extraction
        processTypeMetadata_BE: this.fb.array([]), // rtk Base Extraction
        processTypeMetadata_ET: this.fb.array([]), // rtk Enzyme Treatment
        processTypeMetadata_CF: this.fb.array([]), // rtk Centrifugal Filter
        processTypeMetadata_Other: this.fb.array([]),
        processTypeMetadata_COG: this.fb.array([]), // rtk COG
        processTypeMetadata_COG_F: this.fb.array([]), // rtk COG (Fenton)
        processTypeMetadata_COG_BC: this.fb.array([]), // rtk COG (Base Cleavage)
        processTypeMetadata_Post_Ethanol: this.fb.array([]), // Ethanol
        processTypeMetadata_Post_Crystal: this.fb.array([]), // Crystallization
        processTypeMetadata_Post_MicroFilt: this.fb.array([]), // Microfilter
        processTypeMetadata_Post_CentrFilt: this.fb.array([]), // Centrifugal Filter
        processTypeMetadata_Post_UltraFilt: this.fb.array([]), // Ultra Filteration
        processTypeMetadata_Post_NanoFilt: this.fb.array([]), // Nano Filtration
        processTypeMetadata_Post_SprayDry: this.fb.array([]), // Spray Drying
        processTypeMetadata_Post_Other: this.fb.array([]), //

        microBioMetadata: this.fb.array([]),
    });
    constructor(
        public bsModalRef: BsModalRef,
        private storeUi: Store<UiPartialState>,
        private processApiService: ProcessApiService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.showMicrobioMetadata =
            this.process?.type !== 'Slurry - PBS glycerol' &&
            this.process?.type !== 'Pool slurry - PBS glycerol' &&
            this.process?.type !== 'Micro (pre-inoculum)';

        // get general process metadata
        this.subscriptions.add(
            this.processApiService
                .getMetadataForGroup('Process')
                .subscribe((metadata) => {
                    if (metadata !== null) {
                        this.generalMetadata = metadata;
                        this.generalMetadata.forEach((md) => {
                            this.generalMetadataGroup.push(
                                this.fb.control({
                                    value: this.getProcessMetadataValue(
                                        'Process',
                                        md.name
                                    ),
                                    disabled: this.isUneditable(
                                        'Process',
                                        md.name
                                    ),
                                })
                            );
                        });
                    }
                })
        );

        // get metadata fields for this process type
        if (this.process.type === 'Pre-processing') {
            //this.getMetadataGroupSubscription('Pre-processing')
            //this.getMetadataGroupSubscription('Pre-processing: Enzyme');
            this.getMetadataGroupSubscription(
                'Pre-processing: Solvent/Extraction'
            );
            this.getMetadataGroupSubscription(
                'Pre-processing: Acid Extraction'
            );
            this.getMetadataGroupSubscription(
                'Pre-processing: Base Extraction'
            );
            this.getMetadataGroupSubscription(
                'Pre-processing: Enzyme Treatment'
            );
            this.getMetadataGroupSubscription(
                'Pre-processing: Centrifugal Filter'
            );
            this.getMetadataGroupSubscription('Pre-processing: Other');
        } else if (this.process.type === 'Post-processing') {
            this.getMetadataGroupSubscription('Post-processing');
            this.getMetadataGroupSubscription('Post-processing: Ethanol');
            this.getMetadataGroupSubscription(
                'Post-processing: Crystallization'
            );
            this.getMetadataGroupSubscription('Post-processing: Microfilter');
            this.getMetadataGroupSubscription(
                'Post-processing: Centrifugal Filter'
            );
            this.getMetadataGroupSubscription(
                'Post-processing: Ultra Filtration'
            );
            this.getMetadataGroupSubscription(
                'Post-processing: Nano Filtration'
            );
            this.getMetadataGroupSubscription('Post-processing: Spray Drying');
            this.getMetadataGroupSubscription('Post-processing: Other');
        } else if (this.process.type === 'COG') {
            //this.getMetadataGroupSubscription('COG');
            this.getMetadataGroupSubscription('COG: Fenton');
            this.getMetadataGroupSubscription('COG: Base Cleavage');
        } else {
            this.getMetadataGroupSubscription(this.process.type);
        }

        // get microbio metadata fields
        this.subscriptions.add(
            this.processApiService
                .getMetadataForGroup('Microbio')
                .subscribe((metadata) => {
                    this.microBioMetadata = metadata;
                    if (metadata !== null) {
                        metadata.forEach((md) => {
                            this.microBioMetadataGroup.push(
                                this.fb.control(
                                    this.getProcessMetadataValue(
                                        'Microbio',
                                        md.name
                                    )
                                )
                            );
                        });
                    }
                })
        );
    }

    getMetadataGroupSubscription(metadataGroup) {
        this.subscriptions.add(
            this.processApiService
                .getMetadataForGroup(metadataGroup)
                .subscribe((metadata) => {
                    if (metadata !== null) {
                        if (metadataGroup == 'Pre-processing: Enzyme') {
                            this.processTypeMetadata = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata.forEach((md) => {
                                this.processTypeMetadataGroup.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup ==
                            'Pre-processing: Solvent/Extraction'
                        ) {
                            this.processTypeMetadataB = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadataB.forEach((md) => {
                                this.processTypeMetadataGroupB.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup == 'Pre-processing: Acid Extraction'
                        ) {
                            this.processTypeMetadata_AE = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_AE.forEach((md) => {
                                this.processTypeMetadataGroup_AE.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup == 'Pre-processing: Base Extraction'
                        ) {
                            this.processTypeMetadata_BE = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_BE.forEach((md) => {
                                this.processTypeMetadataGroup_BE.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup == 'Pre-processing: Enzyme Treatment'
                        ) {
                            this.processTypeMetadata_ET = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_ET.forEach((md) => {
                                this.processTypeMetadataGroup_ET.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup ==
                            'Pre-processing: Centrifugal Filter'
                        ) {
                            this.processTypeMetadata_CF = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_CF.forEach((md) => {
                                this.processTypeMetadataGroup_CF.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (metadataGroup == 'Pre-processing: Other') {
                            this.processTypeMetadata_Other = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_Other.forEach((md) => {
                                this.processTypeMetadataGroup_Other.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (metadataGroup == 'COG: Fenton') {
                            this.processTypeMetadata_COG_F = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_COG_F.forEach((md) => {
                                this.processTypeMetadataGroup_COG_F.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (metadataGroup == 'COG: Base Cleavage') {
                            this.processTypeMetadata_COG_BC = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_COG_BC.forEach((md) => {
                                this.processTypeMetadataGroup_COG_BC.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (metadataGroup == 'COG') {
                            this.processTypeMetadata_COG = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata_COG.forEach((md) => {
                                this.processTypeMetadataGroup_COG.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        } else if (
                            metadataGroup == 'Post-processing: Ethanol'
                        ) {
                            this.processTypeMetadata_Post_Ethanol =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_Ethanol.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_Ethanol.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup == 'Post-processing: Crystallization'
                        ) {
                            this.processTypeMetadata_Post_Crystal =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_Crystal.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_Crystal.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup == 'Post-processing: Microfilter'
                        ) {
                            this.processTypeMetadata_Post_MicroFilt =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_MicroFilt.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_MicroFilt.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup ==
                            'Post-processing: Centrifugal Filter'
                        ) {
                            this.processTypeMetadata_Post_CentrFilt =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_CentrFilt.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_CentrFilt.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup == 'Post-processing: Ultra Filtration'
                        ) {
                            this.processTypeMetadata_Post_UltraFilt =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_UltraFilt.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_UltraFilt.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup == 'Post-processing: Nano Filtration'
                        ) {
                            this.processTypeMetadata_Post_NanoFilt =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_NanoFilt.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_NanoFilt.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (
                            metadataGroup == 'Post-processing: Spray Drying'
                        ) {
                            this.processTypeMetadata_Post_SprayDry =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_SprayDry.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_SprayDry.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else if (metadataGroup == 'Post-processing: Other') {
                            this.processTypeMetadata_Post_Other =
                                metadata.filter((md) => md.name !== 'Cells');
                            this.processTypeMetadata_Post_Other.forEach(
                                (md) => {
                                    this.processTypeMetadataGroup_Post_Other.push(
                                        this.fb.control({
                                            value: this.getProcessMetadataValue(
                                                metadataGroup,
                                                md.name
                                            ),
                                            disabled: this.isUneditable(
                                                metadataGroup,
                                                md.name
                                            ),
                                        })
                                    );
                                }
                            );
                        } else {
                            this.processTypeMetadata = metadata.filter(
                                (md) => md.name !== 'Cells'
                            );
                            this.processTypeMetadata.forEach((md) => {
                                this.processTypeMetadataGroup.push(
                                    this.fb.control({
                                        value: this.getProcessMetadataValue(
                                            metadataGroup,
                                            md.name
                                        ),
                                        disabled: this.isUneditable(
                                            metadataGroup,
                                            md.name
                                        ),
                                    })
                                );
                            });
                        }
                    }
                })
        );
    }

    getProcessMetadataValue(groupName: string, name: string) {
        let retValue = '';
        if (
            this.process.metadata &&
            this.process.metadata[groupName] &&
            this.process.metadata[groupName][name]
        ) {
            retValue = this.process.metadata[groupName][name].toString();
        }
        return retValue;
    }

    get generalMetadataGroup() {
        return this.processEditForm.get('generalMetadata') as FormArray;
    }

    get processTypeMetadataGroup() {
        return this.processEditForm.get('processTypeMetadata') as FormArray;
    }

    get processTypeMetadataGroupB() {
        return this.processEditForm.get('processTypeMetadataB') as FormArray;
    }

    get processTypeMetadataGroup_AE() {
        return this.processEditForm.get('processTypeMetadata_AE') as FormArray;
    }

    get processTypeMetadataGroup_BE() {
        return this.processEditForm.get('processTypeMetadata_BE') as FormArray;
    }

    get processTypeMetadataGroup_ET() {
        return this.processEditForm.get('processTypeMetadata_ET') as FormArray;
    }

    get processTypeMetadataGroup_CF() {
        return this.processEditForm.get('processTypeMetadata_CF') as FormArray;
    }

    get processTypeMetadataGroup_Other() {
        return this.processEditForm.get(
            'processTypeMetadata_Other'
        ) as FormArray;
    }

    get processTypeMetadataGroup_COG_F() {
        return this.processEditForm.get(
            'processTypeMetadata_COG_F'
        ) as FormArray;
    }

    get processTypeMetadataGroup_COG() {
        return this.processEditForm.get('processTypeMetadata_COG') as FormArray;
    }

    get processTypeMetadataGroup_COG_BC() {
        return this.processEditForm.get(
            'processTypeMetadata_COG_BC'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_Ethanol() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_Ethanol'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_Crystal() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_Crystal'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_MicroFilt() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_MicroFilt'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_CentrFilt() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_CentrFilt'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_UltraFilt() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_UltraFilt'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_NanoFilt() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_NanoFilt'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_SprayDry() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_SprayDry'
        ) as FormArray;
    }

    get processTypeMetadataGroup_Post_Other() {
        return this.processEditForm.get(
            'processTypeMetadata_Post_Other'
        ) as FormArray;
    }

    get microBioMetadataGroup() {
        return this.processEditForm.get('microBioMetadata') as FormArray;
    }

    saveProcess() {
        const groups = {};
        groups['Process'] = this.generalMetadata.reduce((acc, md, idx) => {
            if (md.name === 'Date Prepared') {
                acc[md.name] = moment(
                    this.generalMetadataGroup.at(idx).value
                ).format('MM/DD/YYYY');
            } else {
                acc[md.name] = this.generalMetadataGroup.at(idx).value;
            }
            return acc;
        }, {});
        if (this.processTypeMetadata) {
            const metadataGroup =
                this.process.type === 'Pre-processing'
                    ? 'Pre-processing: Enzyme'
                    : this.process.type;
            groups[metadataGroup] = this.processTypeMetadata.reduce(
                (acc, md, idx) => {
                    acc[md.name] = this.processTypeMetadataGroup.at(idx).value;
                    return acc;
                },
                {}
            );
        }
        if (this.processTypeMetadataB) {
            groups['Pre-processing: Solvent/Extraction'] =
                this.processTypeMetadataB.reduce((acc, md, idx) => {
                    acc[md.name] = this.processTypeMetadataGroupB.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_AE) {
            // rtk
            groups['Pre-processing: Acid Extraction'] =
                this.processTypeMetadata_AE.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_AE.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_BE) {
            // rtk
            groups['Pre-processing: Base Extraction'] =
                this.processTypeMetadata_BE.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_BE.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_ET) {
            // rtk
            groups['Pre-processing: Enzyme Treatment'] =
                this.processTypeMetadata_ET.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_ET.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_CF) {
            // rtk
            groups['Pre-processing: Centrifugal Filter'] =
                this.processTypeMetadata_CF.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_CF.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_Other) {
            groups['Pre-processing: Other'] =
                this.processTypeMetadata_Other.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_Other.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_COG_F) {
            // rtk
            groups['COG: Fenton'] = this.processTypeMetadata_COG_F.reduce(
                (acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_COG_F.at(idx).value;
                    return acc;
                },
                {}
            );
        }

        if (this.processTypeMetadata_COG) {
            // rtk
            groups['COG'] = this.processTypeMetadata_COG.reduce(
                (acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_COG.at(idx).value;
                    return acc;
                },
                {}
            );
        }

        if (this.processTypeMetadata_COG_BC) {
            // rtk
            groups['COG: Base Cleavage'] =
                this.processTypeMetadata_COG_BC.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_COG_BC.at(idx).value;
                    return acc;
                }, {});
        }

        this.getMetadataGroupSubscription('Post-processing: ');
        this.getMetadataGroupSubscription('Post-processing: ');
        this.getMetadataGroupSubscription('Post-processing: Spray Drying');
        this.getMetadataGroupSubscription('Post-processing: Other');

        if (this.processTypeMetadata_Post_MicroFilt) {
            // rtk
            groups['Post-processing: Microfilter'] =
                this.processTypeMetadata_Post_MicroFilt.reduce(
                    (acc, md, idx) => {
                        acc[md.name] =
                            this.processTypeMetadataGroup_Post_MicroFilt.at(
                                idx
                            ).value;
                        return acc;
                    },
                    {}
                );
        }

        if (this.processTypeMetadata_Post_UltraFilt) {
            // rtk
            groups['Post-processing: Ultra Filtration'] =
                this.processTypeMetadata_Post_UltraFilt.reduce(
                    (acc, md, idx) => {
                        acc[md.name] =
                            this.processTypeMetadataGroup_Post_UltraFilt.at(
                                idx
                            ).value;
                        return acc;
                    },
                    {}
                );
        }

        if (this.processTypeMetadata_Post_NanoFilt) {
            // rtk
            groups['Post-processing: Nano Filtration'] =
                this.processTypeMetadata_Post_NanoFilt.reduce(
                    (acc, md, idx) => {
                        acc[md.name] =
                            this.processTypeMetadataGroup_Post_NanoFilt.at(
                                idx
                            ).value;
                        return acc;
                    },
                    {}
                );
        }

        if (this.processTypeMetadata_Post_SprayDry) {
            // rtk
            groups['Post-processing: Spray Drying'] =
                this.processTypeMetadata_Post_SprayDry.reduce(
                    (acc, md, idx) => {
                        acc[md.name] =
                            this.processTypeMetadataGroup_Post_SprayDry.at(
                                idx
                            ).value;
                        return acc;
                    },
                    {}
                );
        }

        if (this.processTypeMetadata_Post_Other) {
            // rtk
            groups['Post-processing: Other'] =
                this.processTypeMetadata_Post_Other.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_Post_Other.at(idx).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_Post_Crystal) {
            // rtk
            groups['Post-processing: Crystallization'] =
                this.processTypeMetadata_Post_Crystal.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_Post_Crystal.at(
                            idx
                        ).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_Post_Ethanol) {
            // rtk
            groups['Post-processing: Ethanol'] =
                this.processTypeMetadata_Post_Ethanol.reduce((acc, md, idx) => {
                    acc[md.name] =
                        this.processTypeMetadataGroup_Post_Ethanol.at(
                            idx
                        ).value;
                    return acc;
                }, {});
        }

        if (this.processTypeMetadata_Post_CentrFilt) {
            // rtk
            groups['Post-processing: Centrifugal Filter'] =
                this.processTypeMetadata_Post_CentrFilt.reduce(
                    (acc, md, idx) => {
                        acc[md.name] =
                            this.processTypeMetadataGroup_Post_CentrFilt.at(
                                idx
                            ).value;
                        return acc;
                    },
                    {}
                );
        }

        groups['Microbio'] = this.microBioMetadata.reduce((acc, md, idx) => {
            acc[md.name] = this.microBioMetadataGroup.at(idx).value;
            return acc;
        }, {});

        this.storeUi.dispatch(
            saveProcessMetadata({
                id: this.process.id,
                metadataGroups: groups,
            })
        );

        this.bsModalRef.hide();
    }

    isUneditable(type: string, nameOfMetadata: string): boolean {
        if (this.process.uneditableMetadata[type]) {
            return this.process.uneditableMetadata[type][nameOfMetadata];
        } else {
            return false;
        }
    }
}
