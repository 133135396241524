import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date and time, represented as an ISO-8601 string */
    DateTime: any;
    timestamptz: any;
};

export type CreateMeasurementTypesMutationResponse = {
    __typename?: 'CreateMeasurementTypesMutationResponse';
    measurementTypes: Array<MeasurementType>;
};

export type CreateMeasurementsMutationResponse = {
    __typename?: 'CreateMeasurementsMutationResponse';
    measurements: Array<Measurement>;
};

export type CreateMetadataGroupsMutationResponse = {
    __typename?: 'CreateMetadataGroupsMutationResponse';
    metadataGroups: Array<MetadataGroup>;
};

export type CreateMetadataMutationResponse = {
    __typename?: 'CreateMetadataMutationResponse';
    metadata: Array<Metadata>;
};

export type CreatePoolInputMetadataMutationResponse = {
    __typename?: 'CreatePoolInputMetadataMutationResponse';
    poolInputMetadata: Array<PoolInputMetadata>;
};

export type CreateProcessMetadataValuesMutationResponse = {
    __typename?: 'CreateProcessMetadataValuesMutationResponse';
    processMetadataValues: Array<ProcessMetadataValue>;
};

export type CreateProcessTypesMutationResponse = {
    __typename?: 'CreateProcessTypesMutationResponse';
    processTypes: Array<ProcessType>;
};

export type CreateProcessesMutationResponse = {
    __typename?: 'CreateProcessesMutationResponse';
    processes: Array<Process>;
};

export type CreateSampleMetadataMutationResponse = {
    __typename?: 'CreateSampleMetadataMutationResponse';
    sampleMetadata: Array<SampleMetadata>;
};

export type CreateSampleTypesMutationResponse = {
    __typename?: 'CreateSampleTypesMutationResponse';
    sampleTypes: Array<SampleType>;
};

export type CreateSamplesMutationResponse = {
    __typename?: 'CreateSamplesMutationResponse';
    samples: Array<Sample>;
};

export type CreateStudyNodesMutationResponse = {
    __typename?: 'CreateStudyNodesMutationResponse';
    studyNodes: Array<StudyNode>;
};

export type CreateVariableValuesMutationResponse = {
    __typename?: 'CreateVariableValuesMutationResponse';
    variableValues: Array<VariableValues>;
};

export type CreateVariablesMutationResponse = {
    __typename?: 'CreateVariablesMutationResponse';
    variables: Array<Variable>;
};

export type CurvesOutput = {
    __typename?: 'CurvesOutput';
    calculated?: Maybe<Scalars['String']>;
};

export type DeleteInfo = {
    __typename?: 'DeleteInfo';
    nodesDeleted: Scalars['Int'];
    relationshipsDeleted: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
    _eq?: Maybe<Scalars['Int']>;
    _gt?: Maybe<Scalars['Int']>;
    _gte?: Maybe<Scalars['Int']>;
    _in?: Maybe<Array<Scalars['Int']>>;
    _is_null?: Maybe<Scalars['Boolean']>;
    _lt?: Maybe<Scalars['Int']>;
    _lte?: Maybe<Scalars['Int']>;
    _neq?: Maybe<Scalars['Int']>;
    _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LinkageVariableInput = {
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
    variable?: Maybe<Scalars['String']>;
};

export type Measurement = {
    __typename?: 'Measurement';
    data?: Maybe<Array<Maybe<VariableValues>>>;
    measurementType?: Maybe<MeasurementType>;
    monoMethodType?: Maybe<Scalars['String']>;
    observationDate?: Maybe<Scalars['DateTime']>;
    sample?: Maybe<Sample>;
    sopVersion?: Maybe<Scalars['String']>;
    uuid: Scalars['ID'];
};

export type MeasurementMeasurementTypeArgs = {
    options?: Maybe<MeasurementTypeOptions>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementSampleArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type MeasurementConnectFieldInput = {
    connect?: Maybe<MeasurementConnectInput>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementConnectInput = {
    measurementType?: Maybe<MeasurementTypeConnectFieldInput>;
    sample?: Maybe<SampleConnectFieldInput>;
};

export type MeasurementCreateInput = {
    measurementType?: Maybe<MeasurementMeasurementTypeFieldInput>;
    monoMethodType?: Maybe<Scalars['String']>;
    observationDate?: Maybe<Scalars['DateTime']>;
    sample?: Maybe<MeasurementSampleFieldInput>;
    sopVersion?: Maybe<Scalars['String']>;
    uuid: Scalars['ID'];
};

export type MeasurementDataMetabAnalytesInput = {
    v2Hydroxybutyrate?: Maybe<Scalars['Float']>;
    v2Hydroxyglutarate?: Maybe<Scalars['Float']>;
    v3Hydroxybutyrate?: Maybe<Scalars['Float']>;
    vAcetate?: Maybe<Scalars['Float']>;
    vBenzoate?: Maybe<Scalars['Float']>;
    vButyrate?: Maybe<Scalars['Float']>;
    vButyrylcarnitine?: Maybe<Scalars['Float']>;
    vCitrate?: Maybe<Scalars['Float']>;
    vFormate?: Maybe<Scalars['Float']>;
    vGlycerate?: Maybe<Scalars['Float']>;
    vHexanoate?: Maybe<Scalars['Float']>;
    vIsobutyrate?: Maybe<Scalars['Float']>;
    vIsocitrate?: Maybe<Scalars['Float']>;
    vIsovalerate?: Maybe<Scalars['Float']>;
    vIsovalerylglycine?: Maybe<Scalars['Float']>;
    vLAcetylcarnitine?: Maybe<Scalars['Float']>;
    vLMalate?: Maybe<Scalars['Float']>;
    vLactate?: Maybe<Scalars['Float']>;
    vPropionate?: Maybe<Scalars['Float']>;
    vPropionylcarnitine?: Maybe<Scalars['Float']>;
    vPyruvate?: Maybe<Scalars['Float']>;
    vSuccinate?: Maybe<Scalars['Float']>;
    vValerate?: Maybe<Scalars['Float']>;
};

export type MeasurementDataMonoInput = {
    Allose?: Maybe<Scalars['Float']>;
    Apiose?: Maybe<Scalars['Float']>;
    Arabinose?: Maybe<Scalars['Float']>;
    Fructose?: Maybe<Scalars['Float']>;
    Fucose?: Maybe<Scalars['Float']>;
    GalA?: Maybe<Scalars['Float']>;
    GalN?: Maybe<Scalars['Float']>;
    GalNAc?: Maybe<Scalars['Float']>;
    Galactose?: Maybe<Scalars['Float']>;
    GlcA?: Maybe<Scalars['Float']>;
    GlcN?: Maybe<Scalars['Float']>;
    GlcNAc?: Maybe<Scalars['Float']>;
    Glucose?: Maybe<Scalars['Float']>;
    IdoA?: Maybe<Scalars['Float']>;
    Mannose?: Maybe<Scalars['Float']>;
    NeuAc?: Maybe<Scalars['Float']>;
    NeuGc?: Maybe<Scalars['Float']>;
    Rhamnose?: Maybe<Scalars['Float']>;
    Ribose?: Maybe<Scalars['Float']>;
    Xylose?: Maybe<Scalars['Float']>;
};

export type MeasurementDeleteFieldInput = {
    delete?: Maybe<MeasurementDeleteInput>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementDeleteInput = {
    measurementType?: Maybe<MeasurementMeasurementTypeDeleteFieldInput>;
    sample?: Maybe<MeasurementSampleDeleteFieldInput>;
};

export type MeasurementDisconnectFieldInput = {
    disconnect?: Maybe<MeasurementDisconnectInput>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementDisconnectInput = {
    measurementType?: Maybe<MeasurementTypeDisconnectFieldInput>;
    sample?: Maybe<SampleDisconnectFieldInput>;
};

export type MeasurementMeasurementTypeDeleteFieldInput = {
    delete?: Maybe<MeasurementTypeDeleteInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementMeasurementTypeFieldInput = {
    connect?: Maybe<MeasurementTypeConnectFieldInput>;
    create?: Maybe<MeasurementTypeCreateInput>;
};

export type MeasurementMeasurementTypeUpdateFieldInput = {
    connect?: Maybe<MeasurementTypeConnectFieldInput>;
    create?: Maybe<MeasurementTypeCreateInput>;
    delete?: Maybe<MeasurementTypeDeleteFieldInput>;
    disconnect?: Maybe<MeasurementTypeDisconnectFieldInput>;
    update?: Maybe<MeasurementTypeUpdateInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more MeasurementSort objects to sort Measurements by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<MeasurementSort>>>;
};

export type MeasurementRelationInput = {
    measurementType?: Maybe<MeasurementTypeCreateInput>;
    sample?: Maybe<SampleCreateInput>;
};

export type MeasurementSampleDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type MeasurementSampleFieldInput = {
    connect?: Maybe<SampleConnectFieldInput>;
    create?: Maybe<SampleCreateInput>;
};

export type MeasurementSampleUpdateFieldInput = {
    connect?: Maybe<SampleConnectFieldInput>;
    create?: Maybe<SampleCreateInput>;
    delete?: Maybe<SampleDeleteFieldInput>;
    disconnect?: Maybe<SampleDisconnectFieldInput>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

/** Fields to sort Measurements by. The order in which sorts are applied is not guaranteed when specifying many fields in one MeasurementSort object. */
export type MeasurementSort = {
    monoMethodType?: Maybe<SortDirection>;
    observationDate?: Maybe<SortDirection>;
    sopVersion?: Maybe<SortDirection>;
    uuid?: Maybe<SortDirection>;
};

export type MeasurementType = {
    __typename?: 'MeasurementType';
    measurements?: Maybe<Array<Maybe<Measurement>>>;
    name: Scalars['String'];
    variables?: Maybe<Array<Maybe<Variable>>>;
};

export type MeasurementTypeMeasurementsArgs = {
    options?: Maybe<MeasurementOptions>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementTypeVariablesArgs = {
    options?: Maybe<VariableOptions>;
    where?: Maybe<VariableWhere>;
};

export type MeasurementTypeConnectFieldInput = {
    connect?: Maybe<MeasurementTypeConnectInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementTypeConnectInput = {
    measurements?: Maybe<Array<MeasurementConnectFieldInput>>;
    variables?: Maybe<Array<VariableConnectFieldInput>>;
};

export type MeasurementTypeCreateInput = {
    measurements?: Maybe<MeasurementTypeMeasurementsFieldInput>;
    name: Scalars['String'];
    variables?: Maybe<MeasurementTypeVariablesFieldInput>;
};

export type MeasurementTypeDeleteFieldInput = {
    delete?: Maybe<MeasurementTypeDeleteInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementTypeDeleteInput = {
    measurements?: Maybe<Array<MeasurementTypeMeasurementsDeleteFieldInput>>;
    variables?: Maybe<Array<MeasurementTypeVariablesDeleteFieldInput>>;
};

export type MeasurementTypeDisconnectFieldInput = {
    disconnect?: Maybe<MeasurementTypeDisconnectInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type MeasurementTypeDisconnectInput = {
    measurements?: Maybe<Array<MeasurementDisconnectFieldInput>>;
    variables?: Maybe<Array<VariableDisconnectFieldInput>>;
};

export type MeasurementTypeMeasurementsDeleteFieldInput = {
    delete?: Maybe<MeasurementDeleteInput>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementTypeMeasurementsFieldInput = {
    connect?: Maybe<Array<MeasurementConnectFieldInput>>;
    create?: Maybe<Array<MeasurementCreateInput>>;
};

export type MeasurementTypeMeasurementsUpdateFieldInput = {
    connect?: Maybe<Array<MeasurementConnectFieldInput>>;
    create?: Maybe<Array<MeasurementCreateInput>>;
    delete?: Maybe<Array<MeasurementDeleteFieldInput>>;
    disconnect?: Maybe<Array<MeasurementDisconnectFieldInput>>;
    update?: Maybe<MeasurementUpdateInput>;
    where?: Maybe<MeasurementWhere>;
};

export type MeasurementTypeOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more MeasurementTypeSort objects to sort MeasurementTypes by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<MeasurementTypeSort>>>;
};

export type MeasurementTypeRelationInput = {
    measurements?: Maybe<Array<MeasurementCreateInput>>;
    variables?: Maybe<Array<VariableCreateInput>>;
};

/** Fields to sort MeasurementTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one MeasurementTypeSort object. */
export type MeasurementTypeSort = {
    name?: Maybe<SortDirection>;
};

export type MeasurementTypeUpdateInput = {
    measurements?: Maybe<Array<MeasurementTypeMeasurementsUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    variables?: Maybe<Array<MeasurementTypeVariablesUpdateFieldInput>>;
};

export type MeasurementTypeVariablesDeleteFieldInput = {
    delete?: Maybe<VariableDeleteInput>;
    where?: Maybe<VariableWhere>;
};

export type MeasurementTypeVariablesFieldInput = {
    connect?: Maybe<Array<VariableConnectFieldInput>>;
    create?: Maybe<Array<VariableCreateInput>>;
};

export type MeasurementTypeVariablesUpdateFieldInput = {
    connect?: Maybe<Array<VariableConnectFieldInput>>;
    create?: Maybe<Array<VariableCreateInput>>;
    delete?: Maybe<Array<VariableDeleteFieldInput>>;
    disconnect?: Maybe<Array<VariableDisconnectFieldInput>>;
    update?: Maybe<VariableUpdateInput>;
    where?: Maybe<VariableWhere>;
};

export type MeasurementTypeWhere = {
    AND?: Maybe<Array<MeasurementTypeWhere>>;
    OR?: Maybe<Array<MeasurementTypeWhere>>;
    measurements?: Maybe<MeasurementWhere>;
    measurements_NOT?: Maybe<MeasurementWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    variables?: Maybe<VariableWhere>;
    variables_NOT?: Maybe<VariableWhere>;
};

export type MeasurementUpdateInput = {
    measurementType?: Maybe<MeasurementMeasurementTypeUpdateFieldInput>;
    monoMethodType?: Maybe<Scalars['String']>;
    observationDate?: Maybe<Scalars['DateTime']>;
    sample?: Maybe<MeasurementSampleUpdateFieldInput>;
    sopVersion?: Maybe<Scalars['String']>;
    uuid?: Maybe<Scalars['ID']>;
};

export type MeasurementWhere = {
    AND?: Maybe<Array<MeasurementWhere>>;
    OR?: Maybe<Array<MeasurementWhere>>;
    measurementType?: Maybe<MeasurementTypeWhere>;
    measurementType_IN?: Maybe<Array<MeasurementTypeWhere>>;
    measurementType_NOT?: Maybe<MeasurementTypeWhere>;
    measurementType_NOT_IN?: Maybe<Array<MeasurementTypeWhere>>;
    monoMethodType?: Maybe<Scalars['String']>;
    monoMethodType_CONTAINS?: Maybe<Scalars['String']>;
    monoMethodType_ENDS_WITH?: Maybe<Scalars['String']>;
    monoMethodType_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    monoMethodType_NOT?: Maybe<Scalars['String']>;
    monoMethodType_NOT_CONTAINS?: Maybe<Scalars['String']>;
    monoMethodType_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    monoMethodType_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    monoMethodType_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    monoMethodType_STARTS_WITH?: Maybe<Scalars['String']>;
    observationDate?: Maybe<Scalars['DateTime']>;
    sample?: Maybe<SampleWhere>;
    sample_IN?: Maybe<Array<SampleWhere>>;
    sample_NOT?: Maybe<SampleWhere>;
    sample_NOT_IN?: Maybe<Array<SampleWhere>>;
    sopVersion?: Maybe<Scalars['String']>;
    sopVersion_CONTAINS?: Maybe<Scalars['String']>;
    sopVersion_ENDS_WITH?: Maybe<Scalars['String']>;
    sopVersion_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sopVersion_NOT?: Maybe<Scalars['String']>;
    sopVersion_NOT_CONTAINS?: Maybe<Scalars['String']>;
    sopVersion_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    sopVersion_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sopVersion_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    sopVersion_STARTS_WITH?: Maybe<Scalars['String']>;
    uuid?: Maybe<Scalars['ID']>;
    uuid_CONTAINS?: Maybe<Scalars['ID']>;
    uuid_ENDS_WITH?: Maybe<Scalars['ID']>;
    uuid_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    uuid_NOT?: Maybe<Scalars['ID']>;
    uuid_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    uuid_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    uuid_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    uuid_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    uuid_STARTS_WITH?: Maybe<Scalars['ID']>;
};

export type Metadata = {
    __typename?: 'Metadata';
    metadataGroups?: Maybe<Array<Maybe<MetadataGroup>>>;
    name: Scalars['String'];
    processTypes?: Maybe<Array<Maybe<ProcessType>>>;
    sampleTypes?: Maybe<Array<Maybe<SampleType>>>;
    units?: Maybe<Scalars['String']>;
};

export type MetadataMetadataGroupsArgs = {
    options?: Maybe<MetadataGroupOptions>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataProcessTypesArgs = {
    options?: Maybe<ProcessTypeOptions>;
    where?: Maybe<ProcessTypeWhere>;
};

export type MetadataSampleTypesArgs = {
    options?: Maybe<SampleTypeOptions>;
    where?: Maybe<SampleTypeWhere>;
};

export type MetadataConnectFieldInput = {
    connect?: Maybe<MetadataConnectInput>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataConnectInput = {
    metadataGroups?: Maybe<Array<MetadataGroupConnectFieldInput>>;
    processTypes?: Maybe<Array<ProcessTypeConnectFieldInput>>;
    sampleTypes?: Maybe<Array<SampleTypeConnectFieldInput>>;
};

export type MetadataCreateInput = {
    metadataGroups?: Maybe<MetadataMetadataGroupsFieldInput>;
    name: Scalars['String'];
    processTypes?: Maybe<MetadataProcessTypesFieldInput>;
    sampleTypes?: Maybe<MetadataSampleTypesFieldInput>;
    units?: Maybe<Scalars['String']>;
};

export type MetadataDeleteFieldInput = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataDeleteInput = {
    metadataGroups?: Maybe<Array<MetadataMetadataGroupsDeleteFieldInput>>;
    processTypes?: Maybe<Array<MetadataProcessTypesDeleteFieldInput>>;
    sampleTypes?: Maybe<Array<MetadataSampleTypesDeleteFieldInput>>;
};

export type MetadataDisconnectFieldInput = {
    disconnect?: Maybe<MetadataDisconnectInput>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataDisconnectInput = {
    metadataGroups?: Maybe<Array<MetadataGroupDisconnectFieldInput>>;
    processTypes?: Maybe<Array<ProcessTypeDisconnectFieldInput>>;
    sampleTypes?: Maybe<Array<SampleTypeDisconnectFieldInput>>;
};

export type MetadataGroup = {
    __typename?: 'MetadataGroup';
    metadata?: Maybe<Array<Maybe<Metadata>>>;
    name: Scalars['String'];
};

export type MetadataGroupMetadataArgs = {
    options?: Maybe<MetadataOptions>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataGroupConnectFieldInput = {
    connect?: Maybe<MetadataGroupConnectInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataGroupConnectInput = {
    metadata?: Maybe<Array<MetadataConnectFieldInput>>;
};

export type MetadataGroupCreateInput = {
    metadata?: Maybe<MetadataGroupMetadataFieldInput>;
    name: Scalars['String'];
};

export type MetadataGroupDeleteFieldInput = {
    delete?: Maybe<MetadataGroupDeleteInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataGroupDeleteInput = {
    metadata?: Maybe<Array<MetadataGroupMetadataDeleteFieldInput>>;
};

export type MetadataGroupDisconnectFieldInput = {
    disconnect?: Maybe<MetadataGroupDisconnectInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataGroupDisconnectInput = {
    metadata?: Maybe<Array<MetadataDisconnectFieldInput>>;
};

export type MetadataGroupMetadataDeleteFieldInput = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataGroupMetadataFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
};

export type MetadataGroupMetadataUpdateFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
    delete?: Maybe<Array<MetadataDeleteFieldInput>>;
    disconnect?: Maybe<Array<MetadataDisconnectFieldInput>>;
    update?: Maybe<MetadataUpdateInput>;
    where?: Maybe<MetadataWhere>;
};

export type MetadataGroupOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more MetadataGroupSort objects to sort MetadataGroups by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<MetadataGroupSort>>>;
};

export type MetadataGroupRelationInput = {
    metadata?: Maybe<Array<MetadataCreateInput>>;
};

/** Fields to sort MetadataGroups by. The order in which sorts are applied is not guaranteed when specifying many fields in one MetadataGroupSort object. */
export type MetadataGroupSort = {
    name?: Maybe<SortDirection>;
};

export type MetadataGroupUpdateInput = {
    metadata?: Maybe<Array<MetadataGroupMetadataUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
};

export type MetadataGroupWhere = {
    AND?: Maybe<Array<MetadataGroupWhere>>;
    OR?: Maybe<Array<MetadataGroupWhere>>;
    metadata?: Maybe<MetadataWhere>;
    metadata_NOT?: Maybe<MetadataWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type MetadataMetadataGroupsDeleteFieldInput = {
    delete?: Maybe<MetadataGroupDeleteInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataMetadataGroupsFieldInput = {
    connect?: Maybe<Array<MetadataGroupConnectFieldInput>>;
    create?: Maybe<Array<MetadataGroupCreateInput>>;
};

export type MetadataMetadataGroupsUpdateFieldInput = {
    connect?: Maybe<Array<MetadataGroupConnectFieldInput>>;
    create?: Maybe<Array<MetadataGroupCreateInput>>;
    delete?: Maybe<Array<MetadataGroupDeleteFieldInput>>;
    disconnect?: Maybe<Array<MetadataGroupDisconnectFieldInput>>;
    update?: Maybe<MetadataGroupUpdateInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type MetadataOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more MetadataSort objects to sort Metadata by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<MetadataSort>>>;
};

export type MetadataProcessTypesDeleteFieldInput = {
    delete?: Maybe<ProcessTypeDeleteInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type MetadataProcessTypesFieldInput = {
    connect?: Maybe<Array<ProcessTypeConnectFieldInput>>;
    create?: Maybe<Array<ProcessTypeCreateInput>>;
};

export type MetadataProcessTypesUpdateFieldInput = {
    connect?: Maybe<Array<ProcessTypeConnectFieldInput>>;
    create?: Maybe<Array<ProcessTypeCreateInput>>;
    delete?: Maybe<Array<ProcessTypeDeleteFieldInput>>;
    disconnect?: Maybe<Array<ProcessTypeDisconnectFieldInput>>;
    update?: Maybe<ProcessTypeUpdateInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type MetadataRelationInput = {
    metadataGroups?: Maybe<Array<MetadataGroupCreateInput>>;
    processTypes?: Maybe<Array<ProcessTypeCreateInput>>;
    sampleTypes?: Maybe<Array<SampleTypeCreateInput>>;
};

export type MetadataSampleTypesDeleteFieldInput = {
    delete?: Maybe<SampleTypeDeleteInput>;
    where?: Maybe<SampleTypeWhere>;
};

export type MetadataSampleTypesFieldInput = {
    connect?: Maybe<Array<SampleTypeConnectFieldInput>>;
    create?: Maybe<Array<SampleTypeCreateInput>>;
};

export type MetadataSampleTypesUpdateFieldInput = {
    connect?: Maybe<Array<SampleTypeConnectFieldInput>>;
    create?: Maybe<Array<SampleTypeCreateInput>>;
    delete?: Maybe<Array<SampleTypeDeleteFieldInput>>;
    disconnect?: Maybe<Array<SampleTypeDisconnectFieldInput>>;
    update?: Maybe<SampleTypeUpdateInput>;
    where?: Maybe<SampleTypeWhere>;
};

/** Fields to sort Metadata by. The order in which sorts are applied is not guaranteed when specifying many fields in one MetadataSort object. */
export type MetadataSort = {
    name?: Maybe<SortDirection>;
    units?: Maybe<SortDirection>;
};

export type MetadataUpdateInput = {
    metadataGroups?: Maybe<Array<MetadataMetadataGroupsUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    processTypes?: Maybe<Array<MetadataProcessTypesUpdateFieldInput>>;
    sampleTypes?: Maybe<Array<MetadataSampleTypesUpdateFieldInput>>;
    units?: Maybe<Scalars['String']>;
};

export type MetadataWhere = {
    AND?: Maybe<Array<MetadataWhere>>;
    OR?: Maybe<Array<MetadataWhere>>;
    metadataGroups?: Maybe<MetadataGroupWhere>;
    metadataGroups_NOT?: Maybe<MetadataGroupWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    processTypes?: Maybe<ProcessTypeWhere>;
    processTypes_NOT?: Maybe<ProcessTypeWhere>;
    sampleTypes?: Maybe<SampleTypeWhere>;
    sampleTypes_NOT?: Maybe<SampleTypeWhere>;
    units?: Maybe<Scalars['String']>;
    units_CONTAINS?: Maybe<Scalars['String']>;
    units_ENDS_WITH?: Maybe<Scalars['String']>;
    units_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    units_NOT?: Maybe<Scalars['String']>;
    units_NOT_CONTAINS?: Maybe<Scalars['String']>;
    units_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    units_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    units_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    units_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type MetadataWhere_Remote_Rel_Studytrays = {
    AND?: Maybe<Array<MetadataWhere>>;
    OR?: Maybe<Array<MetadataWhere>>;
    metadataGroups?: Maybe<MetadataGroupWhere>;
    metadataGroups_NOT?: Maybe<MetadataGroupWhere>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    processTypes?: Maybe<ProcessTypeWhere>;
    processTypes_NOT?: Maybe<ProcessTypeWhere>;
    sampleTypes?: Maybe<SampleTypeWhere>;
    sampleTypes_NOT?: Maybe<SampleTypeWhere>;
    units?: Maybe<Scalars['String']>;
    units_CONTAINS?: Maybe<Scalars['String']>;
    units_ENDS_WITH?: Maybe<Scalars['String']>;
    units_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    units_NOT?: Maybe<Scalars['String']>;
    units_NOT_CONTAINS?: Maybe<Scalars['String']>;
    units_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    units_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    units_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    units_STARTS_WITH?: Maybe<Scalars['String']>;
};

/** mutation root */
export type Mutation = {
    __typename?: 'Mutation';
    addPoolSource?: Maybe<Array<Maybe<Sample>>>;
    createFastqMappingMeasurement?: Maybe<Array<Maybe<Measurement>>>;
    createFecalPoolSample?: Maybe<Array<Maybe<Sample>>>;
    createFecalSlurrySample?: Maybe<Array<Maybe<Sample>>>;
    createFecalSourceSample?: Maybe<Array<Maybe<Sample>>>;
    createFileTypeMeasurement?: Maybe<Array<Maybe<Measurement>>>;
    createGrowthCurveMeasurement?: Maybe<Array<Maybe<Measurement>>>;
    createInteractionProcessAndSample?: Maybe<Array<Maybe<Process>>>;
    createInteractionProcessAndSampleSingleParent?: Maybe<
        Array<Maybe<Process>>
    >;
    createMeasurementTypes: CreateMeasurementTypesMutationResponse;
    createMeasurements: CreateMeasurementsMutationResponse;
    createMetadata: CreateMetadataMutationResponse;
    createMetadataGroups: CreateMetadataGroupsMutationResponse;
    createPoolInputMetadata: CreatePoolInputMetadataMutationResponse;
    createProcessAndSample?: Maybe<Array<Maybe<Sample>>>;
    createProcessMetadataValues: CreateProcessMetadataValuesMutationResponse;
    createProcessTypes: CreateProcessTypesMutationResponse;
    createProcesses: CreateProcessesMutationResponse;
    createSampleLinkageMeasurement?: Maybe<Array<Maybe<Sample>>>;
    createSampleMetabAnalytesMeasurement?: Maybe<Array<Maybe<Sample>>>;
    createSampleMetadata: CreateSampleMetadataMutationResponse;
    createSampleMonoOrFreeMonoMeasurement?: Maybe<Array<Maybe<Sample>>>;
    createSampleTypes: CreateSampleTypesMutationResponse;
    createSamples: CreateSamplesMutationResponse;
    createSingleStrainSample?: Maybe<Array<Maybe<Sample>>>;
    createStudy?: Maybe<StudyNode>;
    createStudyNodes: CreateStudyNodesMutationResponse;
    createVariableValues: CreateVariableValuesMutationResponse;
    createVariables: CreateVariablesMutationResponse;
    /** execute VOLATILE function "create_bcd_id" which returns "bcd_id_gen_table" */
    create_bcd_id: Array<Bcd_Id_Gen_Table>;
    deleteMeasurement?: Maybe<Measurement>;
    deleteMeasurementTypes: DeleteInfo;
    deleteMeasurements: DeleteInfo;
    deleteMetadata: DeleteInfo;
    deleteMetadataGroups: DeleteInfo;
    deletePoolInputMetadata: DeleteInfo;
    deleteProcessMetadataValues: DeleteInfo;
    deleteProcessTypes: DeleteInfo;
    deleteProcesses: DeleteInfo;
    deleteSampleAndDescendants?: Maybe<Sample>;
    deleteSampleMetadata: DeleteInfo;
    deleteSampleTypes: DeleteInfo;
    deleteSamples: DeleteInfo;
    deleteStudy?: Maybe<StudyNode>;
    deleteStudyNodes: DeleteInfo;
    deleteVariableValues: DeleteInfo;
    deleteVariables: DeleteInfo;
    /** delete data from the table: "bcd_id_gen_table" */
    delete_bcd_id_gen_table?: Maybe<Bcd_Id_Gen_Table_Mutation_Response>;
    /** delete data from the table: "bcd_id_table" */
    delete_bcd_id_table?: Maybe<Bcd_Id_Table_Mutation_Response>;
    /** delete single row from the table: "bcd_id_table" */
    delete_bcd_id_table_by_pk?: Maybe<Bcd_Id_Table>;
    /** delete data from the table: "import_file" */
    delete_import_file?: Maybe<Import_File_Mutation_Response>;
    /** delete single row from the table: "import_file" */
    delete_import_file_by_pk?: Maybe<Import_File>;
    /** delete data from the table: "project" */
    delete_project?: Maybe<Project_Mutation_Response>;
    /** delete single row from the table: "project" */
    delete_project_by_pk?: Maybe<Project>;
    /** delete data from the table: "project_sample" */
    delete_project_sample?: Maybe<Project_Sample_Mutation_Response>;
    /** delete single row from the table: "project_sample" */
    delete_project_sample_by_pk?: Maybe<Project_Sample>;
    /** delete data from the table: "project_study" */
    delete_project_study?: Maybe<Project_Study_Mutation_Response>;
    /** delete single row from the table: "project_study" */
    delete_project_study_by_pk?: Maybe<Project_Study>;
    /** delete data from the table: "project_url" */
    delete_project_url?: Maybe<Project_Url_Mutation_Response>;
    /** delete single row from the table: "project_url" */
    delete_project_url_by_pk?: Maybe<Project_Url>;
    /** delete data from the table: "project_user" */
    delete_project_user?: Maybe<Project_User_Mutation_Response>;
    /** delete single row from the table: "project_user" */
    delete_project_user_by_pk?: Maybe<Project_User>;
    /** delete data from the table: "role" */
    delete_role?: Maybe<Role_Mutation_Response>;
    /** delete single row from the table: "role" */
    delete_role_by_pk?: Maybe<Role>;
    /** delete data from the table: "sample_url" */
    delete_sample_url?: Maybe<Sample_Url_Mutation_Response>;
    /** delete single row from the table: "sample_url" */
    delete_sample_url_by_pk?: Maybe<Sample_Url>;
    /** delete data from the table: "study" */
    delete_study?: Maybe<Study_Mutation_Response>;
    /** delete single row from the table: "study" */
    delete_study_by_pk?: Maybe<Study>;
    /** delete data from the table: "study_url" */
    delete_study_url?: Maybe<Study_Url_Mutation_Response>;
    /** delete single row from the table: "study_url" */
    delete_study_url_by_pk?: Maybe<Study_Url>;
    /** delete data from the table: "user" */
    delete_user?: Maybe<User_Mutation_Response>;
    /** delete single row from the table: "user" */
    delete_user_by_pk?: Maybe<User>;
    /** insert data into the table: "bcd_id_gen_table" */
    insert_bcd_id_gen_table?: Maybe<Bcd_Id_Gen_Table_Mutation_Response>;
    /** insert a single row into the table: "bcd_id_gen_table" */
    insert_bcd_id_gen_table_one?: Maybe<Bcd_Id_Gen_Table>;
    /** insert data into the table: "bcd_id_table" */
    insert_bcd_id_table?: Maybe<Bcd_Id_Table_Mutation_Response>;
    /** insert a single row into the table: "bcd_id_table" */
    insert_bcd_id_table_one?: Maybe<Bcd_Id_Table>;
    /** insert data into the table: "import_file" */
    insert_import_file?: Maybe<Import_File_Mutation_Response>;
    /** insert a single row into the table: "import_file" */
    insert_import_file_one?: Maybe<Import_File>;
    /** insert data into the table: "project" */
    insert_project?: Maybe<Project_Mutation_Response>;
    /** insert a single row into the table: "project" */
    insert_project_one?: Maybe<Project>;
    /** insert data into the table: "project_sample" */
    insert_project_sample?: Maybe<Project_Sample_Mutation_Response>;
    /** insert a single row into the table: "project_sample" */
    insert_project_sample_one?: Maybe<Project_Sample>;
    /** insert data into the table: "project_study" */
    insert_project_study?: Maybe<Project_Study_Mutation_Response>;
    /** insert a single row into the table: "project_study" */
    insert_project_study_one?: Maybe<Project_Study>;
    /** insert data into the table: "project_url" */
    insert_project_url?: Maybe<Project_Url_Mutation_Response>;
    /** insert a single row into the table: "project_url" */
    insert_project_url_one?: Maybe<Project_Url>;
    /** insert data into the table: "project_user" */
    insert_project_user?: Maybe<Project_User_Mutation_Response>;
    /** insert a single row into the table: "project_user" */
    insert_project_user_one?: Maybe<Project_User>;
    /** insert data into the table: "role" */
    insert_role?: Maybe<Role_Mutation_Response>;
    /** insert a single row into the table: "role" */
    insert_role_one?: Maybe<Role>;
    /** insert data into the table: "sample_url" */
    insert_sample_url?: Maybe<Sample_Url_Mutation_Response>;
    /** insert a single row into the table: "sample_url" */
    insert_sample_url_one?: Maybe<Sample_Url>;
    /** insert data into the table: "study" */
    insert_study?: Maybe<Study_Mutation_Response>;
    /** insert a single row into the table: "study" */
    insert_study_one?: Maybe<Study>;
    /** insert data into the table: "study_url" */
    insert_study_url?: Maybe<Study_Url_Mutation_Response>;
    /** insert a single row into the table: "study_url" */
    insert_study_url_one?: Maybe<Study_Url>;
    /** insert data into the table: "user" */
    insert_user?: Maybe<User_Mutation_Response>;
    /** insert a single row into the table: "user" */
    insert_user_one?: Maybe<User>;
    mergeCalculatedCurveValues?: Maybe<Array<Maybe<Measurement>>>;
    mergeProcessMetadata?: Maybe<Array<Maybe<Process>>>;
    mergeProcessReplicateMetadata?: Maybe<Array<Maybe<Process>>>;
    mergeProcessToStudy?: Maybe<Array<Maybe<Process>>>;
    mergeSampleMetadata?: Maybe<Array<Maybe<Sample>>>;
    mergeSourceSample?: Maybe<Array<Maybe<Sample>>>;
    savePoolSourceMetadata?: Maybe<Array<Maybe<Sample>>>;
    updateMeasurementTypes: UpdateMeasurementTypesMutationResponse;
    updateMeasurements: UpdateMeasurementsMutationResponse;
    updateMetadata: UpdateMetadataMutationResponse;
    updateMetadataGroups: UpdateMetadataGroupsMutationResponse;
    updatePoolInputMetadata: UpdatePoolInputMetadataMutationResponse;
    updateProcessMetadataValues: UpdateProcessMetadataValuesMutationResponse;
    updateProcessTypes: UpdateProcessTypesMutationResponse;
    updateProcesses: UpdateProcessesMutationResponse;
    updateSampleMetadata: UpdateSampleMetadataMutationResponse;
    updateSampleTypes: UpdateSampleTypesMutationResponse;
    updateSamples: UpdateSamplesMutationResponse;
    updateStudyNodes: UpdateStudyNodesMutationResponse;
    updateVariableValues: UpdateVariableValuesMutationResponse;
    updateVariables: UpdateVariablesMutationResponse;
    /** update data of the table: "bcd_id_gen_table" */
    update_bcd_id_gen_table?: Maybe<Bcd_Id_Gen_Table_Mutation_Response>;
    /** update multiples rows of table: "bcd_id_gen_table" */
    update_bcd_id_gen_table_many?: Maybe<
        Array<Maybe<Bcd_Id_Gen_Table_Mutation_Response>>
    >;
    /** update data of the table: "bcd_id_table" */
    update_bcd_id_table?: Maybe<Bcd_Id_Table_Mutation_Response>;
    /** update single row of the table: "bcd_id_table" */
    update_bcd_id_table_by_pk?: Maybe<Bcd_Id_Table>;
    /** update multiples rows of table: "bcd_id_table" */
    update_bcd_id_table_many?: Maybe<
        Array<Maybe<Bcd_Id_Table_Mutation_Response>>
    >;
    /** update data of the table: "import_file" */
    update_import_file?: Maybe<Import_File_Mutation_Response>;
    /** update single row of the table: "import_file" */
    update_import_file_by_pk?: Maybe<Import_File>;
    /** update multiples rows of table: "import_file" */
    update_import_file_many?: Maybe<
        Array<Maybe<Import_File_Mutation_Response>>
    >;
    /** update data of the table: "project" */
    update_project?: Maybe<Project_Mutation_Response>;
    /** update single row of the table: "project" */
    update_project_by_pk?: Maybe<Project>;
    /** update multiples rows of table: "project" */
    update_project_many?: Maybe<Array<Maybe<Project_Mutation_Response>>>;
    /** update data of the table: "project_sample" */
    update_project_sample?: Maybe<Project_Sample_Mutation_Response>;
    /** update single row of the table: "project_sample" */
    update_project_sample_by_pk?: Maybe<Project_Sample>;
    /** update multiples rows of table: "project_sample" */
    update_project_sample_many?: Maybe<
        Array<Maybe<Project_Sample_Mutation_Response>>
    >;
    /** update data of the table: "project_study" */
    update_project_study?: Maybe<Project_Study_Mutation_Response>;
    /** update single row of the table: "project_study" */
    update_project_study_by_pk?: Maybe<Project_Study>;
    /** update multiples rows of table: "project_study" */
    update_project_study_many?: Maybe<
        Array<Maybe<Project_Study_Mutation_Response>>
    >;
    /** update data of the table: "project_url" */
    update_project_url?: Maybe<Project_Url_Mutation_Response>;
    /** update single row of the table: "project_url" */
    update_project_url_by_pk?: Maybe<Project_Url>;
    /** update multiples rows of table: "project_url" */
    update_project_url_many?: Maybe<
        Array<Maybe<Project_Url_Mutation_Response>>
    >;
    /** update data of the table: "project_user" */
    update_project_user?: Maybe<Project_User_Mutation_Response>;
    /** update single row of the table: "project_user" */
    update_project_user_by_pk?: Maybe<Project_User>;
    /** update multiples rows of table: "project_user" */
    update_project_user_many?: Maybe<
        Array<Maybe<Project_User_Mutation_Response>>
    >;
    /** update data of the table: "role" */
    update_role?: Maybe<Role_Mutation_Response>;
    /** update single row of the table: "role" */
    update_role_by_pk?: Maybe<Role>;
    /** update multiples rows of table: "role" */
    update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
    /** update data of the table: "sample_url" */
    update_sample_url?: Maybe<Sample_Url_Mutation_Response>;
    /** update single row of the table: "sample_url" */
    update_sample_url_by_pk?: Maybe<Sample_Url>;
    /** update multiples rows of table: "sample_url" */
    update_sample_url_many?: Maybe<Array<Maybe<Sample_Url_Mutation_Response>>>;
    /** update data of the table: "study" */
    update_study?: Maybe<Study_Mutation_Response>;
    /** update single row of the table: "study" */
    update_study_by_pk?: Maybe<Study>;
    /** update multiples rows of table: "study" */
    update_study_many?: Maybe<Array<Maybe<Study_Mutation_Response>>>;
    /** update data of the table: "study_url" */
    update_study_url?: Maybe<Study_Url_Mutation_Response>;
    /** update single row of the table: "study_url" */
    update_study_url_by_pk?: Maybe<Study_Url>;
    /** update multiples rows of table: "study_url" */
    update_study_url_many?: Maybe<Array<Maybe<Study_Url_Mutation_Response>>>;
    /** update data of the table: "user" */
    update_user?: Maybe<User_Mutation_Response>;
    /** update single row of the table: "user" */
    update_user_by_pk?: Maybe<User>;
    /** update multiples rows of table: "user" */
    update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};

/** mutation root */
export type MutationAddPoolSourceArgs = {
    healthStatus?: Maybe<Scalars['String']>;
    newPoolSourceId?: Maybe<Scalars['String']>;
    numDonors?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    processOutputId?: Maybe<Scalars['String']>;
    processOutputType?: Maybe<Scalars['String']>;
    sex?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateFastqMappingMeasurementArgs = {
    mappingFileKey?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateFecalPoolSampleArgs = {
    aliquotesLeft?: Maybe<Scalars['String']>;
    datePrepared?: Maybe<Scalars['String']>;
    healthStatus?: Maybe<Scalars['String']>;
    notebookPage?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    numMixed?: Maybe<Scalars['String']>;
    outputSampleBcdId?: Maybe<Scalars['String']>;
    outputSampleName?: Maybe<Scalars['String']>;
    processBcdId?: Maybe<Scalars['String']>;
    sex?: Maybe<Scalars['String']>;
    src1BcdId?: Maybe<Scalars['String']>;
    src2BcdId?: Maybe<Scalars['String']>;
    src3BcdId?: Maybe<Scalars['String']>;
    src4BcdId?: Maybe<Scalars['String']>;
    src5BcdId?: Maybe<Scalars['String']>;
    src6BcdId?: Maybe<Scalars['String']>;
    src7BcdId?: Maybe<Scalars['String']>;
    src8BcdId?: Maybe<Scalars['String']>;
    storageLocation?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateFecalSlurrySampleArgs = {
    aliquotesLeft?: Maybe<Scalars['String']>;
    centrifugalCond?: Maybe<Scalars['String']>;
    datePrepared?: Maybe<Scalars['String']>;
    descriptiveText?: Maybe<Scalars['String']>;
    fecesConc?: Maybe<Scalars['String']>;
    glycerolConc?: Maybe<Scalars['String']>;
    inputSampleBcdId?: Maybe<Scalars['String']>;
    notebookPage?: Maybe<Scalars['String']>;
    outputSampleBcdId?: Maybe<Scalars['String']>;
    outputSampleName?: Maybe<Scalars['String']>;
    pbsConc?: Maybe<Scalars['String']>;
    processBcdId?: Maybe<Scalars['String']>;
    storageLocation?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateFecalSourceSampleArgs = {
    aliquotesLeft?: Maybe<Scalars['String']>;
    appearance?: Maybe<Scalars['String']>;
    bcdId?: Maybe<Scalars['String']>;
    collectionDate?: Maybe<Scalars['String']>;
    donorNumber?: Maybe<Scalars['String']>;
    expirationDate?: Maybe<Scalars['String']>;
    healthStatus?: Maybe<Scalars['String']>;
    ndaMta?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    sex?: Maybe<Scalars['String']>;
    storageLocation?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    unitId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateFileTypeMeasurementArgs = {
    filePath?: Maybe<Scalars['String']>;
    measurementType?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateGrowthCurveMeasurementArgs = {
    replicate?: Maybe<Scalars['String']>;
    trayName?: Maybe<Scalars['String']>;
    values?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateInteractionProcessAndSampleArgs = {
    parent1Id?: Maybe<Scalars['String']>;
    parent2Id?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateInteractionProcessAndSampleSingleParentArgs = {
    parentId?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateMeasurementTypesArgs = {
    input: Array<MeasurementTypeCreateInput>;
};

/** mutation root */
export type MutationCreateMeasurementsArgs = {
    input: Array<MeasurementCreateInput>;
};

/** mutation root */
export type MutationCreateMetadataArgs = {
    input: Array<MetadataCreateInput>;
};

/** mutation root */
export type MutationCreateMetadataGroupsArgs = {
    input: Array<MetadataGroupCreateInput>;
};

/** mutation root */
export type MutationCreatePoolInputMetadataArgs = {
    input: Array<PoolInputMetadataCreateInput>;
};

/** mutation root */
export type MutationCreateProcessAndSampleArgs = {
    descriptiveName?: Maybe<Scalars['String']>;
    fromId?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    processType?: Maybe<Scalars['String']>;
    sourceHealthStatus?: Maybe<Scalars['String']>;
    sourceSex?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateProcessMetadataValuesArgs = {
    input: Array<ProcessMetadataValueCreateInput>;
};

/** mutation root */
export type MutationCreateProcessTypesArgs = {
    input: Array<ProcessTypeCreateInput>;
};

/** mutation root */
export type MutationCreateProcessesArgs = {
    input: Array<ProcessCreateInput>;
};

/** mutation root */
export type MutationCreateSampleLinkageMeasurementArgs = {
    bcdId?: Maybe<Scalars['String']>;
    measurementData?: Maybe<Array<Maybe<LinkageVariableInput>>>;
    observationDt?: Maybe<UdbDateTimeInput>;
    sopVersion?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateSampleMetabAnalytesMeasurementArgs = {
    bcdId?: Maybe<Scalars['String']>;
    measurementData?: Maybe<MeasurementDataMetabAnalytesInput>;
    observationDt?: Maybe<UdbDateTimeInput>;
    replicate?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateSampleMetadataArgs = {
    input: Array<SampleMetadataCreateInput>;
};

/** mutation root */
export type MutationCreateSampleMonoOrFreeMonoMeasurementArgs = {
    bcdId?: Maybe<Scalars['String']>;
    measurementData?: Maybe<MeasurementDataMonoInput>;
    monoMethodType?: Maybe<Scalars['String']>;
    observationDt?: Maybe<UdbDateTimeInput>;
    sopVersion?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateSampleTypesArgs = {
    input: Array<SampleTypeCreateInput>;
};

/** mutation root */
export type MutationCreateSamplesArgs = {
    input: Array<SampleCreateInput>;
};

/** mutation root */
export type MutationCreateSingleStrainSampleArgs = {
    apTimeToLateExp?: Maybe<Scalars['String']>;
    bcdId?: Maybe<Scalars['String']>;
    bsl?: Maybe<Scalars['String']>;
    class?: Maybe<Scalars['String']>;
    family?: Maybe<Scalars['String']>;
    genus?: Maybe<Scalars['String']>;
    kingdom?: Maybe<Scalars['String']>;
    mediaCommercial?: Maybe<Scalars['String']>;
    mediaMinimal?: Maybe<Scalars['String']>;
    ndaMta?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['String']>;
    otherStrainNames?: Maybe<Scalars['String']>;
    phylum?: Maybe<Scalars['String']>;
    propagationNotes?: Maybe<Scalars['String']>;
    species?: Maybe<Scalars['String']>;
    storageLocation?: Maybe<Scalars['String']>;
    strain?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateStudyArgs = {
    studyId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationCreateStudyNodesArgs = {
    input: Array<StudyNodeCreateInput>;
};

/** mutation root */
export type MutationCreateVariableValuesArgs = {
    input: Array<VariableValuesCreateInput>;
};

/** mutation root */
export type MutationCreateVariablesArgs = {
    input: Array<VariableCreateInput>;
};

/** mutation root */
export type MutationCreate_Bcd_IdArgs = {
    args?: Maybe<Create_Bcd_Id_Args>;
    distinct_on?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Gen_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

/** mutation root */
export type MutationDeleteMeasurementArgs = {
    measurementUuid?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationDeleteMeasurementTypesArgs = {
    delete?: Maybe<MeasurementTypeDeleteInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

/** mutation root */
export type MutationDeleteMeasurementsArgs = {
    delete?: Maybe<MeasurementDeleteInput>;
    where?: Maybe<MeasurementWhere>;
};

/** mutation root */
export type MutationDeleteMetadataArgs = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

/** mutation root */
export type MutationDeleteMetadataGroupsArgs = {
    delete?: Maybe<MetadataGroupDeleteInput>;
    where?: Maybe<MetadataGroupWhere>;
};

/** mutation root */
export type MutationDeletePoolInputMetadataArgs = {
    where?: Maybe<PoolInputMetadataWhere>;
};

/** mutation root */
export type MutationDeleteProcessMetadataValuesArgs = {
    delete?: Maybe<ProcessMetadataValueDeleteInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

/** mutation root */
export type MutationDeleteProcessTypesArgs = {
    delete?: Maybe<ProcessTypeDeleteInput>;
    where?: Maybe<ProcessTypeWhere>;
};

/** mutation root */
export type MutationDeleteProcessesArgs = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

/** mutation root */
export type MutationDeleteSampleAndDescendantsArgs = {
    isSourceSample?: Maybe<Scalars['Boolean']>;
    sampleId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationDeleteSampleMetadataArgs = {
    where?: Maybe<SampleMetadataWhere>;
};

/** mutation root */
export type MutationDeleteSampleTypesArgs = {
    delete?: Maybe<SampleTypeDeleteInput>;
    where?: Maybe<SampleTypeWhere>;
};

/** mutation root */
export type MutationDeleteSamplesArgs = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

/** mutation root */
export type MutationDeleteStudyArgs = {
    studyId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationDeleteStudyNodesArgs = {
    delete?: Maybe<StudyNodeDeleteInput>;
    where?: Maybe<StudyNodeWhere>;
};

/** mutation root */
export type MutationDeleteVariableValuesArgs = {
    where?: Maybe<VariableValuesWhere>;
};

/** mutation root */
export type MutationDeleteVariablesArgs = {
    delete?: Maybe<VariableDeleteInput>;
    where?: Maybe<VariableWhere>;
};

/** mutation root */
export type MutationDelete_Bcd_Id_Gen_TableArgs = {
    where: Bcd_Id_Gen_Table_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Bcd_Id_TableArgs = {
    where: Bcd_Id_Table_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Bcd_Id_Table_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_Import_FileArgs = {
    where: Import_File_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Import_File_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_ProjectArgs = {
    where: Project_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Project_By_PkArgs = {
    projectId: Scalars['String'];
};

/** mutation root */
export type MutationDelete_Project_SampleArgs = {
    where: Project_Sample_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Project_Sample_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_Project_StudyArgs = {
    where: Project_Study_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Project_Study_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_Project_UrlArgs = {
    where: Project_Url_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Project_Url_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_Project_UserArgs = {
    where: Project_User_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Project_User_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_RoleArgs = {
    where: Role_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Role_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_Sample_UrlArgs = {
    where: Sample_Url_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Sample_Url_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_StudyArgs = {
    where: Study_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Study_By_PkArgs = {
    studyId: Scalars['String'];
};

/** mutation root */
export type MutationDelete_Study_UrlArgs = {
    where: Study_Url_Bool_Exp;
};

/** mutation root */
export type MutationDelete_Study_Url_By_PkArgs = {
    id: Scalars['Int'];
};

/** mutation root */
export type MutationDelete_UserArgs = {
    where: User_Bool_Exp;
};

/** mutation root */
export type MutationDelete_User_By_PkArgs = {
    userId: Scalars['String'];
};

/** mutation root */
export type MutationInsert_Bcd_Id_Gen_TableArgs = {
    objects: Array<Bcd_Id_Gen_Table_Insert_Input>;
};

/** mutation root */
export type MutationInsert_Bcd_Id_Gen_Table_OneArgs = {
    object: Bcd_Id_Gen_Table_Insert_Input;
};

/** mutation root */
export type MutationInsert_Bcd_Id_TableArgs = {
    objects: Array<Bcd_Id_Table_Insert_Input>;
    on_conflict?: Maybe<Bcd_Id_Table_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Bcd_Id_Table_OneArgs = {
    object: Bcd_Id_Table_Insert_Input;
    on_conflict?: Maybe<Bcd_Id_Table_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Import_FileArgs = {
    objects: Array<Import_File_Insert_Input>;
    on_conflict?: Maybe<Import_File_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Import_File_OneArgs = {
    object: Import_File_Insert_Input;
    on_conflict?: Maybe<Import_File_On_Conflict>;
};

/** mutation root */
export type MutationInsert_ProjectArgs = {
    objects: Array<Project_Insert_Input>;
    on_conflict?: Maybe<Project_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_OneArgs = {
    object: Project_Insert_Input;
    on_conflict?: Maybe<Project_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_SampleArgs = {
    objects: Array<Project_Sample_Insert_Input>;
    on_conflict?: Maybe<Project_Sample_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_Sample_OneArgs = {
    object: Project_Sample_Insert_Input;
    on_conflict?: Maybe<Project_Sample_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_StudyArgs = {
    objects: Array<Project_Study_Insert_Input>;
    on_conflict?: Maybe<Project_Study_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_Study_OneArgs = {
    object: Project_Study_Insert_Input;
    on_conflict?: Maybe<Project_Study_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_UrlArgs = {
    objects: Array<Project_Url_Insert_Input>;
    on_conflict?: Maybe<Project_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_Url_OneArgs = {
    object: Project_Url_Insert_Input;
    on_conflict?: Maybe<Project_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_UserArgs = {
    objects: Array<Project_User_Insert_Input>;
    on_conflict?: Maybe<Project_User_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Project_User_OneArgs = {
    object: Project_User_Insert_Input;
    on_conflict?: Maybe<Project_User_On_Conflict>;
};

/** mutation root */
export type MutationInsert_RoleArgs = {
    objects: Array<Role_Insert_Input>;
    on_conflict?: Maybe<Role_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Role_OneArgs = {
    object: Role_Insert_Input;
    on_conflict?: Maybe<Role_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Sample_UrlArgs = {
    objects: Array<Sample_Url_Insert_Input>;
    on_conflict?: Maybe<Sample_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Sample_Url_OneArgs = {
    object: Sample_Url_Insert_Input;
    on_conflict?: Maybe<Sample_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_StudyArgs = {
    objects: Array<Study_Insert_Input>;
    on_conflict?: Maybe<Study_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Study_OneArgs = {
    object: Study_Insert_Input;
    on_conflict?: Maybe<Study_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Study_UrlArgs = {
    objects: Array<Study_Url_Insert_Input>;
    on_conflict?: Maybe<Study_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_Study_Url_OneArgs = {
    object: Study_Url_Insert_Input;
    on_conflict?: Maybe<Study_Url_On_Conflict>;
};

/** mutation root */
export type MutationInsert_UserArgs = {
    objects: Array<User_Insert_Input>;
    on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type MutationInsert_User_OneArgs = {
    object: User_Insert_Input;
    on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type MutationMergeCalculatedCurveValuesArgs = {
    calculatedValues?: Maybe<Scalars['String']>;
    replicate?: Maybe<Scalars['String']>;
    trayName?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationMergeProcessMetadataArgs = {
    metadataGroup?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    metadataValue?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationMergeProcessReplicateMetadataArgs = {
    metadataGroup?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    metadataValue?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    replicateName?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationMergeProcessToStudyArgs = {
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationMergeSampleMetadataArgs = {
    bcdId?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationMergeSourceSampleArgs = {
    dateReceived?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationSavePoolSourceMetadataArgs = {
    metadataName?: Maybe<Scalars['String']>;
    metadataValue?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
    sourceName?: Maybe<Scalars['String']>;
};

/** mutation root */
export type MutationUpdateMeasurementTypesArgs = {
    connect?: Maybe<MeasurementTypeConnectInput>;
    create?: Maybe<MeasurementTypeRelationInput>;
    delete?: Maybe<MeasurementTypeDeleteInput>;
    disconnect?: Maybe<MeasurementTypeDisconnectInput>;
    update?: Maybe<MeasurementTypeUpdateInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

/** mutation root */
export type MutationUpdateMeasurementsArgs = {
    connect?: Maybe<MeasurementConnectInput>;
    create?: Maybe<MeasurementRelationInput>;
    delete?: Maybe<MeasurementDeleteInput>;
    disconnect?: Maybe<MeasurementDisconnectInput>;
    update?: Maybe<MeasurementUpdateInput>;
    where?: Maybe<MeasurementWhere>;
};

/** mutation root */
export type MutationUpdateMetadataArgs = {
    connect?: Maybe<MetadataConnectInput>;
    create?: Maybe<MetadataRelationInput>;
    delete?: Maybe<MetadataDeleteInput>;
    disconnect?: Maybe<MetadataDisconnectInput>;
    update?: Maybe<MetadataUpdateInput>;
    where?: Maybe<MetadataWhere>;
};

/** mutation root */
export type MutationUpdateMetadataGroupsArgs = {
    connect?: Maybe<MetadataGroupConnectInput>;
    create?: Maybe<MetadataGroupRelationInput>;
    delete?: Maybe<MetadataGroupDeleteInput>;
    disconnect?: Maybe<MetadataGroupDisconnectInput>;
    update?: Maybe<MetadataGroupUpdateInput>;
    where?: Maybe<MetadataGroupWhere>;
};

/** mutation root */
export type MutationUpdatePoolInputMetadataArgs = {
    update?: Maybe<PoolInputMetadataUpdateInput>;
    where?: Maybe<PoolInputMetadataWhere>;
};

/** mutation root */
export type MutationUpdateProcessMetadataValuesArgs = {
    connect?: Maybe<ProcessMetadataValueConnectInput>;
    create?: Maybe<ProcessMetadataValueRelationInput>;
    delete?: Maybe<ProcessMetadataValueDeleteInput>;
    disconnect?: Maybe<ProcessMetadataValueDisconnectInput>;
    update?: Maybe<ProcessMetadataValueUpdateInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

/** mutation root */
export type MutationUpdateProcessTypesArgs = {
    connect?: Maybe<ProcessTypeConnectInput>;
    create?: Maybe<ProcessTypeRelationInput>;
    delete?: Maybe<ProcessTypeDeleteInput>;
    disconnect?: Maybe<ProcessTypeDisconnectInput>;
    update?: Maybe<ProcessTypeUpdateInput>;
    where?: Maybe<ProcessTypeWhere>;
};

/** mutation root */
export type MutationUpdateProcessesArgs = {
    connect?: Maybe<ProcessConnectInput>;
    create?: Maybe<ProcessRelationInput>;
    delete?: Maybe<ProcessDeleteInput>;
    disconnect?: Maybe<ProcessDisconnectInput>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

/** mutation root */
export type MutationUpdateSampleMetadataArgs = {
    update?: Maybe<SampleMetadataUpdateInput>;
    where?: Maybe<SampleMetadataWhere>;
};

/** mutation root */
export type MutationUpdateSampleTypesArgs = {
    connect?: Maybe<SampleTypeConnectInput>;
    create?: Maybe<SampleTypeRelationInput>;
    delete?: Maybe<SampleTypeDeleteInput>;
    disconnect?: Maybe<SampleTypeDisconnectInput>;
    update?: Maybe<SampleTypeUpdateInput>;
    where?: Maybe<SampleTypeWhere>;
};

/** mutation root */
export type MutationUpdateSamplesArgs = {
    connect?: Maybe<SampleConnectInput>;
    create?: Maybe<SampleRelationInput>;
    delete?: Maybe<SampleDeleteInput>;
    disconnect?: Maybe<SampleDisconnectInput>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

/** mutation root */
export type MutationUpdateStudyNodesArgs = {
    connect?: Maybe<StudyNodeConnectInput>;
    create?: Maybe<StudyNodeRelationInput>;
    delete?: Maybe<StudyNodeDeleteInput>;
    disconnect?: Maybe<StudyNodeDisconnectInput>;
    update?: Maybe<StudyNodeUpdateInput>;
    where?: Maybe<StudyNodeWhere>;
};

/** mutation root */
export type MutationUpdateVariableValuesArgs = {
    update?: Maybe<VariableValuesUpdateInput>;
    where?: Maybe<VariableValuesWhere>;
};

/** mutation root */
export type MutationUpdateVariablesArgs = {
    connect?: Maybe<VariableConnectInput>;
    create?: Maybe<VariableRelationInput>;
    delete?: Maybe<VariableDeleteInput>;
    disconnect?: Maybe<VariableDisconnectInput>;
    update?: Maybe<VariableUpdateInput>;
    where?: Maybe<VariableWhere>;
};

/** mutation root */
export type MutationUpdate_Bcd_Id_Gen_TableArgs = {
    _set?: Maybe<Bcd_Id_Gen_Table_Set_Input>;
    where: Bcd_Id_Gen_Table_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Bcd_Id_Gen_Table_ManyArgs = {
    updates: Array<Bcd_Id_Gen_Table_Updates>;
};

/** mutation root */
export type MutationUpdate_Bcd_Id_TableArgs = {
    _inc?: Maybe<Bcd_Id_Table_Inc_Input>;
    _set?: Maybe<Bcd_Id_Table_Set_Input>;
    where: Bcd_Id_Table_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Bcd_Id_Table_By_PkArgs = {
    _inc?: Maybe<Bcd_Id_Table_Inc_Input>;
    _set?: Maybe<Bcd_Id_Table_Set_Input>;
    pk_columns: Bcd_Id_Table_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Bcd_Id_Table_ManyArgs = {
    updates: Array<Bcd_Id_Table_Updates>;
};

/** mutation root */
export type MutationUpdate_Import_FileArgs = {
    _inc?: Maybe<Import_File_Inc_Input>;
    _set?: Maybe<Import_File_Set_Input>;
    where: Import_File_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Import_File_By_PkArgs = {
    _inc?: Maybe<Import_File_Inc_Input>;
    _set?: Maybe<Import_File_Set_Input>;
    pk_columns: Import_File_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Import_File_ManyArgs = {
    updates: Array<Import_File_Updates>;
};

/** mutation root */
export type MutationUpdate_ProjectArgs = {
    _set?: Maybe<Project_Set_Input>;
    where: Project_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_By_PkArgs = {
    _set?: Maybe<Project_Set_Input>;
    pk_columns: Project_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Project_ManyArgs = {
    updates: Array<Project_Updates>;
};

/** mutation root */
export type MutationUpdate_Project_SampleArgs = {
    _inc?: Maybe<Project_Sample_Inc_Input>;
    _set?: Maybe<Project_Sample_Set_Input>;
    where: Project_Sample_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_Sample_By_PkArgs = {
    _inc?: Maybe<Project_Sample_Inc_Input>;
    _set?: Maybe<Project_Sample_Set_Input>;
    pk_columns: Project_Sample_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Project_Sample_ManyArgs = {
    updates: Array<Project_Sample_Updates>;
};

/** mutation root */
export type MutationUpdate_Project_StudyArgs = {
    _inc?: Maybe<Project_Study_Inc_Input>;
    _set?: Maybe<Project_Study_Set_Input>;
    where: Project_Study_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_Study_By_PkArgs = {
    _inc?: Maybe<Project_Study_Inc_Input>;
    _set?: Maybe<Project_Study_Set_Input>;
    pk_columns: Project_Study_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Project_Study_ManyArgs = {
    updates: Array<Project_Study_Updates>;
};

/** mutation root */
export type MutationUpdate_Project_UrlArgs = {
    _inc?: Maybe<Project_Url_Inc_Input>;
    _set?: Maybe<Project_Url_Set_Input>;
    where: Project_Url_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_Url_By_PkArgs = {
    _inc?: Maybe<Project_Url_Inc_Input>;
    _set?: Maybe<Project_Url_Set_Input>;
    pk_columns: Project_Url_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Project_Url_ManyArgs = {
    updates: Array<Project_Url_Updates>;
};

/** mutation root */
export type MutationUpdate_Project_UserArgs = {
    _inc?: Maybe<Project_User_Inc_Input>;
    _set?: Maybe<Project_User_Set_Input>;
    where: Project_User_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Project_User_By_PkArgs = {
    _inc?: Maybe<Project_User_Inc_Input>;
    _set?: Maybe<Project_User_Set_Input>;
    pk_columns: Project_User_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Project_User_ManyArgs = {
    updates: Array<Project_User_Updates>;
};

/** mutation root */
export type MutationUpdate_RoleArgs = {
    _inc?: Maybe<Role_Inc_Input>;
    _set?: Maybe<Role_Set_Input>;
    where: Role_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Role_By_PkArgs = {
    _inc?: Maybe<Role_Inc_Input>;
    _set?: Maybe<Role_Set_Input>;
    pk_columns: Role_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Role_ManyArgs = {
    updates: Array<Role_Updates>;
};

/** mutation root */
export type MutationUpdate_Sample_UrlArgs = {
    _inc?: Maybe<Sample_Url_Inc_Input>;
    _set?: Maybe<Sample_Url_Set_Input>;
    where: Sample_Url_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Sample_Url_By_PkArgs = {
    _inc?: Maybe<Sample_Url_Inc_Input>;
    _set?: Maybe<Sample_Url_Set_Input>;
    pk_columns: Sample_Url_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Sample_Url_ManyArgs = {
    updates: Array<Sample_Url_Updates>;
};

/** mutation root */
export type MutationUpdate_StudyArgs = {
    _set?: Maybe<Study_Set_Input>;
    where: Study_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Study_By_PkArgs = {
    _set?: Maybe<Study_Set_Input>;
    pk_columns: Study_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Study_ManyArgs = {
    updates: Array<Study_Updates>;
};

/** mutation root */
export type MutationUpdate_Study_UrlArgs = {
    _inc?: Maybe<Study_Url_Inc_Input>;
    _set?: Maybe<Study_Url_Set_Input>;
    where: Study_Url_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_Study_Url_By_PkArgs = {
    _inc?: Maybe<Study_Url_Inc_Input>;
    _set?: Maybe<Study_Url_Set_Input>;
    pk_columns: Study_Url_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_Study_Url_ManyArgs = {
    updates: Array<Study_Url_Updates>;
};

/** mutation root */
export type MutationUpdate_UserArgs = {
    _inc?: Maybe<User_Inc_Input>;
    _set?: Maybe<User_Set_Input>;
    where: User_Bool_Exp;
};

/** mutation root */
export type MutationUpdate_User_By_PkArgs = {
    _inc?: Maybe<User_Inc_Input>;
    _set?: Maybe<User_Set_Input>;
    pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type MutationUpdate_User_ManyArgs = {
    updates: Array<User_Updates>;
};

export type PoolInputMetadata = {
    __typename?: 'PoolInputMetadata';
    concentration?: Maybe<Scalars['String']>;
    sourceName?: Maybe<Scalars['String']>;
    ulAdded?: Maybe<Scalars['String']>;
};

export type PoolInputMetadataCreateInput = {
    concentration?: Maybe<Scalars['String']>;
    ulAdded?: Maybe<Scalars['String']>;
};

export type PoolInputMetadataOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more PoolInputMetadataSort objects to sort PoolInputMetadata by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<PoolInputMetadataSort>>>;
};

/** Fields to sort PoolInputMetadata by. The order in which sorts are applied is not guaranteed when specifying many fields in one PoolInputMetadataSort object. */
export type PoolInputMetadataSort = {
    concentration?: Maybe<SortDirection>;
    ulAdded?: Maybe<SortDirection>;
};

export type PoolInputMetadataUpdateInput = {
    concentration?: Maybe<Scalars['String']>;
    ulAdded?: Maybe<Scalars['String']>;
};

export type PoolInputMetadataWhere = {
    AND?: Maybe<Array<PoolInputMetadataWhere>>;
    OR?: Maybe<Array<PoolInputMetadataWhere>>;
    concentration?: Maybe<Scalars['String']>;
    concentration_CONTAINS?: Maybe<Scalars['String']>;
    concentration_ENDS_WITH?: Maybe<Scalars['String']>;
    concentration_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    concentration_NOT?: Maybe<Scalars['String']>;
    concentration_NOT_CONTAINS?: Maybe<Scalars['String']>;
    concentration_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    concentration_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    concentration_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    concentration_STARTS_WITH?: Maybe<Scalars['String']>;
    ulAdded?: Maybe<Scalars['String']>;
    ulAdded_CONTAINS?: Maybe<Scalars['String']>;
    ulAdded_ENDS_WITH?: Maybe<Scalars['String']>;
    ulAdded_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    ulAdded_NOT?: Maybe<Scalars['String']>;
    ulAdded_NOT_CONTAINS?: Maybe<Scalars['String']>;
    ulAdded_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    ulAdded_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    ulAdded_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    ulAdded_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type Process = {
    __typename?: 'Process';
    bcdId: Scalars['ID'];
    inputSamples?: Maybe<Array<Maybe<Sample>>>;
    metadata?: Maybe<Array<Maybe<ProcessMetadataValue>>>;
    outputSamples?: Maybe<Array<Maybe<Sample>>>;
    processType?: Maybe<ProcessType>;
    referenceId?: Maybe<Scalars['String']>;
    studies?: Maybe<Array<Maybe<StudyNode>>>;
};

export type ProcessInputSamplesArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type ProcessMetadataArgs = {
    options?: Maybe<ProcessMetadataValueOptions>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessOutputSamplesArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type ProcessProcessTypeArgs = {
    options?: Maybe<ProcessTypeOptions>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessStudiesArgs = {
    options?: Maybe<StudyNodeOptions>;
    where?: Maybe<StudyNodeWhere>;
};

export type ProcessConnectFieldInput = {
    connect?: Maybe<ProcessConnectInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessConnectInput = {
    inputSamples?: Maybe<Array<SampleConnectFieldInput>>;
    metadata?: Maybe<Array<ProcessMetadataValueConnectFieldInput>>;
    outputSamples?: Maybe<Array<SampleConnectFieldInput>>;
    processType?: Maybe<ProcessTypeConnectFieldInput>;
    studies?: Maybe<Array<StudyNodeConnectFieldInput>>;
};

export type ProcessCreateInput = {
    bcdId: Scalars['ID'];
    inputSamples?: Maybe<ProcessInputSamplesFieldInput>;
    metadata?: Maybe<ProcessMetadataFieldInput>;
    outputSamples?: Maybe<ProcessOutputSamplesFieldInput>;
    processType?: Maybe<ProcessProcessTypeFieldInput>;
    referenceId?: Maybe<Scalars['String']>;
    studies?: Maybe<ProcessStudiesFieldInput>;
};

export type ProcessDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessDeleteInput = {
    inputSamples?: Maybe<Array<ProcessInputSamplesDeleteFieldInput>>;
    metadata?: Maybe<Array<ProcessMetadataDeleteFieldInput>>;
    outputSamples?: Maybe<Array<ProcessOutputSamplesDeleteFieldInput>>;
    processType?: Maybe<ProcessProcessTypeDeleteFieldInput>;
    studies?: Maybe<Array<ProcessStudiesDeleteFieldInput>>;
};

export type ProcessDisconnectFieldInput = {
    disconnect?: Maybe<ProcessDisconnectInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessDisconnectInput = {
    inputSamples?: Maybe<Array<SampleDisconnectFieldInput>>;
    metadata?: Maybe<Array<ProcessMetadataValueDisconnectFieldInput>>;
    outputSamples?: Maybe<Array<SampleDisconnectFieldInput>>;
    processType?: Maybe<ProcessTypeDisconnectFieldInput>;
    studies?: Maybe<Array<StudyNodeDisconnectFieldInput>>;
};

export type ProcessInputSamplesDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type ProcessInputSamplesFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
};

export type ProcessInputSamplesUpdateFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
    delete?: Maybe<Array<SampleDeleteFieldInput>>;
    disconnect?: Maybe<Array<SampleDisconnectFieldInput>>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

export type ProcessMetadataDeleteFieldInput = {
    delete?: Maybe<ProcessMetadataValueDeleteInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessMetadataFieldInput = {
    connect?: Maybe<Array<ProcessMetadataValueConnectFieldInput>>;
    create?: Maybe<Array<ProcessMetadataValueCreateInput>>;
};

export type ProcessMetadataUpdateFieldInput = {
    connect?: Maybe<Array<ProcessMetadataValueConnectFieldInput>>;
    create?: Maybe<Array<ProcessMetadataValueCreateInput>>;
    delete?: Maybe<Array<ProcessMetadataValueDeleteFieldInput>>;
    disconnect?: Maybe<Array<ProcessMetadataValueDisconnectFieldInput>>;
    update?: Maybe<ProcessMetadataValueUpdateInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessMetadataValue = {
    __typename?: 'ProcessMetadataValue';
    metadata?: Maybe<Metadata>;
    metadataGroup?: Maybe<MetadataGroup>;
    process?: Maybe<Process>;
    replicate?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
};

export type ProcessMetadataValueMetadataArgs = {
    options?: Maybe<MetadataOptions>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessMetadataValueMetadataGroupArgs = {
    options?: Maybe<MetadataGroupOptions>;
    where?: Maybe<MetadataGroupWhere>;
};

export type ProcessMetadataValueProcessArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessMetadataValueConnectFieldInput = {
    connect?: Maybe<ProcessMetadataValueConnectInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessMetadataValueConnectInput = {
    metadata?: Maybe<MetadataConnectFieldInput>;
    metadataGroup?: Maybe<MetadataGroupConnectFieldInput>;
    process?: Maybe<ProcessConnectFieldInput>;
};

export type ProcessMetadataValueCreateInput = {
    metadata?: Maybe<ProcessMetadataValueMetadataFieldInput>;
    metadataGroup?: Maybe<ProcessMetadataValueMetadataGroupFieldInput>;
    process?: Maybe<ProcessMetadataValueProcessFieldInput>;
    replicate?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
};

export type ProcessMetadataValueDeleteFieldInput = {
    delete?: Maybe<ProcessMetadataValueDeleteInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessMetadataValueDeleteInput = {
    metadata?: Maybe<ProcessMetadataValueMetadataDeleteFieldInput>;
    metadataGroup?: Maybe<ProcessMetadataValueMetadataGroupDeleteFieldInput>;
    process?: Maybe<ProcessMetadataValueProcessDeleteFieldInput>;
};

export type ProcessMetadataValueDisconnectFieldInput = {
    disconnect?: Maybe<ProcessMetadataValueDisconnectInput>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type ProcessMetadataValueDisconnectInput = {
    metadata?: Maybe<MetadataDisconnectFieldInput>;
    metadataGroup?: Maybe<MetadataGroupDisconnectFieldInput>;
    process?: Maybe<ProcessDisconnectFieldInput>;
};

export type ProcessMetadataValueMetadataDeleteFieldInput = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessMetadataValueMetadataFieldInput = {
    connect?: Maybe<MetadataConnectFieldInput>;
    create?: Maybe<MetadataCreateInput>;
};

export type ProcessMetadataValueMetadataGroupDeleteFieldInput = {
    delete?: Maybe<MetadataGroupDeleteInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type ProcessMetadataValueMetadataGroupFieldInput = {
    connect?: Maybe<MetadataGroupConnectFieldInput>;
    create?: Maybe<MetadataGroupCreateInput>;
};

export type ProcessMetadataValueMetadataGroupUpdateFieldInput = {
    connect?: Maybe<MetadataGroupConnectFieldInput>;
    create?: Maybe<MetadataGroupCreateInput>;
    delete?: Maybe<MetadataGroupDeleteFieldInput>;
    disconnect?: Maybe<MetadataGroupDisconnectFieldInput>;
    update?: Maybe<MetadataGroupUpdateInput>;
    where?: Maybe<MetadataGroupWhere>;
};

export type ProcessMetadataValueMetadataUpdateFieldInput = {
    connect?: Maybe<MetadataConnectFieldInput>;
    create?: Maybe<MetadataCreateInput>;
    delete?: Maybe<MetadataDeleteFieldInput>;
    disconnect?: Maybe<MetadataDisconnectFieldInput>;
    update?: Maybe<MetadataUpdateInput>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessMetadataValueOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more ProcessMetadataValueSort objects to sort ProcessMetadataValues by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<ProcessMetadataValueSort>>>;
};

export type ProcessMetadataValueProcessDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessMetadataValueProcessFieldInput = {
    connect?: Maybe<ProcessConnectFieldInput>;
    create?: Maybe<ProcessCreateInput>;
};

export type ProcessMetadataValueProcessUpdateFieldInput = {
    connect?: Maybe<ProcessConnectFieldInput>;
    create?: Maybe<ProcessCreateInput>;
    delete?: Maybe<ProcessDeleteFieldInput>;
    disconnect?: Maybe<ProcessDisconnectFieldInput>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessMetadataValueRelationInput = {
    metadata?: Maybe<MetadataCreateInput>;
    metadataGroup?: Maybe<MetadataGroupCreateInput>;
    process?: Maybe<ProcessCreateInput>;
};

/** Fields to sort ProcessMetadataValues by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProcessMetadataValueSort object. */
export type ProcessMetadataValueSort = {
    replicate?: Maybe<SortDirection>;
    uneditable?: Maybe<SortDirection>;
    value?: Maybe<SortDirection>;
};

export type ProcessMetadataValueUpdateInput = {
    metadata?: Maybe<ProcessMetadataValueMetadataUpdateFieldInput>;
    metadataGroup?: Maybe<ProcessMetadataValueMetadataGroupUpdateFieldInput>;
    process?: Maybe<ProcessMetadataValueProcessUpdateFieldInput>;
    replicate?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
};

export type ProcessMetadataValueWhere = {
    AND?: Maybe<Array<ProcessMetadataValueWhere>>;
    OR?: Maybe<Array<ProcessMetadataValueWhere>>;
    metadata?: Maybe<MetadataWhere>;
    metadataGroup?: Maybe<MetadataGroupWhere>;
    metadataGroup_IN?: Maybe<Array<MetadataGroupWhere>>;
    metadataGroup_NOT?: Maybe<MetadataGroupWhere>;
    metadataGroup_NOT_IN?: Maybe<Array<MetadataGroupWhere>>;
    metadata_IN?: Maybe<Array<MetadataWhere>>;
    metadata_NOT?: Maybe<MetadataWhere>;
    metadata_NOT_IN?: Maybe<Array<MetadataWhere>>;
    process?: Maybe<ProcessWhere>;
    process_IN?: Maybe<Array<ProcessWhere>>;
    process_NOT?: Maybe<ProcessWhere>;
    process_NOT_IN?: Maybe<Array<ProcessWhere>>;
    replicate?: Maybe<Scalars['String']>;
    replicate_CONTAINS?: Maybe<Scalars['String']>;
    replicate_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT?: Maybe<Scalars['String']>;
    replicate_NOT_CONTAINS?: Maybe<Scalars['String']>;
    replicate_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    replicate_STARTS_WITH?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    uneditable_NOT?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
    value_CONTAINS?: Maybe<Scalars['String']>;
    value_ENDS_WITH?: Maybe<Scalars['String']>;
    value_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT?: Maybe<Scalars['String']>;
    value_NOT_CONTAINS?: Maybe<Scalars['String']>;
    value_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    value_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    value_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type ProcessMetadataValueWhere_Remote_Rel_Studytrays = {
    AND?: Maybe<Array<ProcessMetadataValueWhere>>;
    OR?: Maybe<Array<ProcessMetadataValueWhere>>;
    metadata?: Maybe<MetadataWhere_Remote_Rel_Studytrays>;
    metadataGroup?: Maybe<MetadataGroupWhere>;
    metadataGroup_IN?: Maybe<Array<MetadataGroupWhere>>;
    metadataGroup_NOT?: Maybe<MetadataGroupWhere>;
    metadataGroup_NOT_IN?: Maybe<Array<MetadataGroupWhere>>;
    metadata_IN?: Maybe<Array<MetadataWhere>>;
    metadata_NOT?: Maybe<MetadataWhere>;
    metadata_NOT_IN?: Maybe<Array<MetadataWhere>>;
    process?: Maybe<ProcessWhere_Remote_Rel_Studytrays>;
    process_IN?: Maybe<Array<ProcessWhere>>;
    process_NOT?: Maybe<ProcessWhere>;
    process_NOT_IN?: Maybe<Array<ProcessWhere>>;
    replicate?: Maybe<Scalars['String']>;
    replicate_CONTAINS?: Maybe<Scalars['String']>;
    replicate_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT?: Maybe<Scalars['String']>;
    replicate_NOT_CONTAINS?: Maybe<Scalars['String']>;
    replicate_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    replicate_STARTS_WITH?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    uneditable_NOT?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
    value_CONTAINS?: Maybe<Scalars['String']>;
    value_ENDS_WITH?: Maybe<Scalars['String']>;
    value_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT?: Maybe<Scalars['String']>;
    value_NOT_CONTAINS?: Maybe<Scalars['String']>;
    value_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    value_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    value_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type ProcessOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more ProcessSort objects to sort Processes by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<ProcessSort>>>;
};

export type ProcessOutputSamplesDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type ProcessOutputSamplesFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
};

export type ProcessOutputSamplesUpdateFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
    delete?: Maybe<Array<SampleDeleteFieldInput>>;
    disconnect?: Maybe<Array<SampleDisconnectFieldInput>>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

export type ProcessProcessTypeDeleteFieldInput = {
    delete?: Maybe<ProcessTypeDeleteInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessProcessTypeFieldInput = {
    connect?: Maybe<ProcessTypeConnectFieldInput>;
    create?: Maybe<ProcessTypeCreateInput>;
};

export type ProcessProcessTypeUpdateFieldInput = {
    connect?: Maybe<ProcessTypeConnectFieldInput>;
    create?: Maybe<ProcessTypeCreateInput>;
    delete?: Maybe<ProcessTypeDeleteFieldInput>;
    disconnect?: Maybe<ProcessTypeDisconnectFieldInput>;
    update?: Maybe<ProcessTypeUpdateInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessRelationInput = {
    inputSamples?: Maybe<Array<SampleCreateInput>>;
    metadata?: Maybe<Array<ProcessMetadataValueCreateInput>>;
    outputSamples?: Maybe<Array<SampleCreateInput>>;
    processType?: Maybe<ProcessTypeCreateInput>;
    studies?: Maybe<Array<StudyNodeCreateInput>>;
};

/** Fields to sort Processes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProcessSort object. */
export type ProcessSort = {
    bcdId?: Maybe<SortDirection>;
    referenceId?: Maybe<SortDirection>;
};

export type ProcessStudiesDeleteFieldInput = {
    delete?: Maybe<StudyNodeDeleteInput>;
    where?: Maybe<StudyNodeWhere>;
};

export type ProcessStudiesFieldInput = {
    connect?: Maybe<Array<StudyNodeConnectFieldInput>>;
    create?: Maybe<Array<StudyNodeCreateInput>>;
};

export type ProcessStudiesUpdateFieldInput = {
    connect?: Maybe<Array<StudyNodeConnectFieldInput>>;
    create?: Maybe<Array<StudyNodeCreateInput>>;
    delete?: Maybe<Array<StudyNodeDeleteFieldInput>>;
    disconnect?: Maybe<Array<StudyNodeDisconnectFieldInput>>;
    update?: Maybe<StudyNodeUpdateInput>;
    where?: Maybe<StudyNodeWhere>;
};

export type ProcessType = {
    __typename?: 'ProcessType';
    metadata?: Maybe<Array<Maybe<Metadata>>>;
    name: Scalars['String'];
    processes?: Maybe<Array<Maybe<Process>>>;
};

export type ProcessTypeMetadataArgs = {
    options?: Maybe<MetadataOptions>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessTypeProcessesArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessTypeConnectFieldInput = {
    connect?: Maybe<ProcessTypeConnectInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessTypeConnectInput = {
    metadata?: Maybe<Array<MetadataConnectFieldInput>>;
    processes?: Maybe<Array<ProcessConnectFieldInput>>;
};

export type ProcessTypeCreateInput = {
    metadata?: Maybe<ProcessTypeMetadataFieldInput>;
    name: Scalars['String'];
    processes?: Maybe<ProcessTypeProcessesFieldInput>;
};

export type ProcessTypeDeleteFieldInput = {
    delete?: Maybe<ProcessTypeDeleteInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessTypeDeleteInput = {
    metadata?: Maybe<Array<ProcessTypeMetadataDeleteFieldInput>>;
    processes?: Maybe<Array<ProcessTypeProcessesDeleteFieldInput>>;
};

export type ProcessTypeDisconnectFieldInput = {
    disconnect?: Maybe<ProcessTypeDisconnectInput>;
    where?: Maybe<ProcessTypeWhere>;
};

export type ProcessTypeDisconnectInput = {
    metadata?: Maybe<Array<MetadataDisconnectFieldInput>>;
    processes?: Maybe<Array<ProcessDisconnectFieldInput>>;
};

export type ProcessTypeMetadataDeleteFieldInput = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessTypeMetadataFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
};

export type ProcessTypeMetadataUpdateFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
    delete?: Maybe<Array<MetadataDeleteFieldInput>>;
    disconnect?: Maybe<Array<MetadataDisconnectFieldInput>>;
    update?: Maybe<MetadataUpdateInput>;
    where?: Maybe<MetadataWhere>;
};

export type ProcessTypeOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more ProcessTypeSort objects to sort ProcessTypes by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<ProcessTypeSort>>>;
};

export type ProcessTypeProcessesDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessTypeProcessesFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
};

export type ProcessTypeProcessesUpdateFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
    delete?: Maybe<Array<ProcessDeleteFieldInput>>;
    disconnect?: Maybe<Array<ProcessDisconnectFieldInput>>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

export type ProcessTypeRelationInput = {
    metadata?: Maybe<Array<MetadataCreateInput>>;
    processes?: Maybe<Array<ProcessCreateInput>>;
};

/** Fields to sort ProcessTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProcessTypeSort object. */
export type ProcessTypeSort = {
    name?: Maybe<SortDirection>;
};

export type ProcessTypeUpdateInput = {
    metadata?: Maybe<Array<ProcessTypeMetadataUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    processes?: Maybe<Array<ProcessTypeProcessesUpdateFieldInput>>;
};

export type ProcessTypeWhere = {
    AND?: Maybe<Array<ProcessTypeWhere>>;
    OR?: Maybe<Array<ProcessTypeWhere>>;
    metadata?: Maybe<MetadataWhere>;
    metadata_NOT?: Maybe<MetadataWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    processes?: Maybe<ProcessWhere>;
    processes_NOT?: Maybe<ProcessWhere>;
};

export type ProcessUpdateInput = {
    bcdId?: Maybe<Scalars['ID']>;
    inputSamples?: Maybe<Array<ProcessInputSamplesUpdateFieldInput>>;
    metadata?: Maybe<Array<ProcessMetadataUpdateFieldInput>>;
    outputSamples?: Maybe<Array<ProcessOutputSamplesUpdateFieldInput>>;
    processType?: Maybe<ProcessProcessTypeUpdateFieldInput>;
    referenceId?: Maybe<Scalars['String']>;
    studies?: Maybe<Array<ProcessStudiesUpdateFieldInput>>;
};

export type ProcessWhere = {
    AND?: Maybe<Array<ProcessWhere>>;
    OR?: Maybe<Array<ProcessWhere>>;
    bcdId?: Maybe<Scalars['ID']>;
    bcdId_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT?: Maybe<Scalars['ID']>;
    bcdId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    bcdId_STARTS_WITH?: Maybe<Scalars['ID']>;
    inputSamples?: Maybe<SampleWhere>;
    inputSamples_NOT?: Maybe<SampleWhere>;
    metadata?: Maybe<ProcessMetadataValueWhere>;
    metadata_NOT?: Maybe<ProcessMetadataValueWhere>;
    outputSamples?: Maybe<SampleWhere>;
    outputSamples_NOT?: Maybe<SampleWhere>;
    processType?: Maybe<ProcessTypeWhere>;
    processType_IN?: Maybe<Array<ProcessTypeWhere>>;
    processType_NOT?: Maybe<ProcessTypeWhere>;
    processType_NOT_IN?: Maybe<Array<ProcessTypeWhere>>;
    referenceId?: Maybe<Scalars['String']>;
    referenceId_CONTAINS?: Maybe<Scalars['String']>;
    referenceId_ENDS_WITH?: Maybe<Scalars['String']>;
    referenceId_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    referenceId_NOT?: Maybe<Scalars['String']>;
    referenceId_NOT_CONTAINS?: Maybe<Scalars['String']>;
    referenceId_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    referenceId_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    referenceId_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    referenceId_STARTS_WITH?: Maybe<Scalars['String']>;
    studies?: Maybe<StudyNodeWhere>;
    studies_NOT?: Maybe<StudyNodeWhere>;
};

export type ProcessWhere_Remote_Rel_Studytrays = {
    AND?: Maybe<Array<ProcessWhere>>;
    OR?: Maybe<Array<ProcessWhere>>;
    bcdId?: Maybe<Scalars['ID']>;
    bcdId_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT?: Maybe<Scalars['ID']>;
    bcdId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    bcdId_STARTS_WITH?: Maybe<Scalars['ID']>;
    inputSamples?: Maybe<SampleWhere>;
    inputSamples_NOT?: Maybe<SampleWhere>;
    metadata?: Maybe<ProcessMetadataValueWhere>;
    metadata_NOT?: Maybe<ProcessMetadataValueWhere>;
    outputSamples?: Maybe<SampleWhere>;
    outputSamples_NOT?: Maybe<SampleWhere>;
    processType?: Maybe<ProcessTypeWhere>;
    processType_IN?: Maybe<Array<ProcessTypeWhere>>;
    processType_NOT?: Maybe<ProcessTypeWhere>;
    processType_NOT_IN?: Maybe<Array<ProcessTypeWhere>>;
    referenceId?: Maybe<Scalars['String']>;
    referenceId_CONTAINS?: Maybe<Scalars['String']>;
    referenceId_ENDS_WITH?: Maybe<Scalars['String']>;
    referenceId_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    referenceId_NOT?: Maybe<Scalars['String']>;
    referenceId_NOT_CONTAINS?: Maybe<Scalars['String']>;
    referenceId_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    referenceId_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    referenceId_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    referenceId_STARTS_WITH?: Maybe<Scalars['String']>;
    studies?: Maybe<StudyNodeWhere_Remote_Rel_Studytrays>;
    studies_NOT?: Maybe<StudyNodeWhere>;
};

export type Query = {
    __typename?: 'Query';
    /** fetch data from the table: "bcd_id_gen_table" */
    bcd_id_gen_table: Array<Bcd_Id_Gen_Table>;
    /** fetch aggregated fields from the table: "bcd_id_gen_table" */
    bcd_id_gen_table_aggregate: Bcd_Id_Gen_Table_Aggregate;
    /** fetch data from the table: "bcd_id_table" */
    bcd_id_table: Array<Bcd_Id_Table>;
    /** fetch aggregated fields from the table: "bcd_id_table" */
    bcd_id_table_aggregate: Bcd_Id_Table_Aggregate;
    /** fetch data from the table: "bcd_id_table" using primary key columns */
    bcd_id_table_by_pk?: Maybe<Bcd_Id_Table>;
    calculateCurves?: Maybe<CurvesOutput>;
    countSamplesByBcdIdSearch?: Maybe<Scalars['Int']>;
    countSamplesByTypeSearch?: Maybe<Scalars['Int']>;
    countSamplesFullTextSearch?: Maybe<Scalars['Int']>;
    /** fetch data from the table: "import_file" */
    import_file: Array<Import_File>;
    /** fetch aggregated fields from the table: "import_file" */
    import_file_aggregate: Import_File_Aggregate;
    /** fetch data from the table: "import_file" using primary key columns */
    import_file_by_pk?: Maybe<Import_File>;
    measurementTypes: Array<MeasurementType>;
    measurements: Array<Measurement>;
    metadata: Array<Metadata>;
    metadataGroups: Array<MetadataGroup>;
    poolInputMetadata: Array<PoolInputMetadata>;
    poolSourcesFullTextSearch?: Maybe<Array<Maybe<Sample>>>;
    processMetadataValues: Array<ProcessMetadataValue>;
    processTypes: Array<ProcessType>;
    processes: Array<Process>;
    /** fetch data from the table: "project" */
    project: Array<Project>;
    /** fetch aggregated fields from the table: "project" */
    project_aggregate: Project_Aggregate;
    /** fetch data from the table: "project" using primary key columns */
    project_by_pk?: Maybe<Project>;
    /** fetch data from the table: "project_sample" */
    project_sample: Array<Project_Sample>;
    /** fetch aggregated fields from the table: "project_sample" */
    project_sample_aggregate: Project_Sample_Aggregate;
    /** fetch data from the table: "project_sample" using primary key columns */
    project_sample_by_pk?: Maybe<Project_Sample>;
    /** fetch data from the table: "project_study" */
    project_study: Array<Project_Study>;
    /** fetch aggregated fields from the table: "project_study" */
    project_study_aggregate: Project_Study_Aggregate;
    /** fetch data from the table: "project_study" using primary key columns */
    project_study_by_pk?: Maybe<Project_Study>;
    /** fetch data from the table: "project_url" */
    project_url: Array<Project_Url>;
    /** fetch aggregated fields from the table: "project_url" */
    project_url_aggregate: Project_Url_Aggregate;
    /** fetch data from the table: "project_url" using primary key columns */
    project_url_by_pk?: Maybe<Project_Url>;
    /** fetch data from the table: "project_user" */
    project_user: Array<Project_User>;
    /** fetch aggregated fields from the table: "project_user" */
    project_user_aggregate: Project_User_Aggregate;
    /** fetch data from the table: "project_user" using primary key columns */
    project_user_by_pk?: Maybe<Project_User>;
    relatedSamplesFullTextSearch?: Maybe<Array<Maybe<Sample>>>;
    /** fetch data from the table: "role" */
    role: Array<Role>;
    /** fetch aggregated fields from the table: "role" */
    role_aggregate: Role_Aggregate;
    /** fetch data from the table: "role" using primary key columns */
    role_by_pk?: Maybe<Role>;
    sampleMetadata: Array<SampleMetadata>;
    sampleTypes: Array<SampleType>;
    /** fetch data from the table: "sample_url" */
    sample_url: Array<Sample_Url>;
    /** fetch aggregated fields from the table: "sample_url" */
    sample_url_aggregate: Sample_Url_Aggregate;
    /** fetch data from the table: "sample_url" using primary key columns */
    sample_url_by_pk?: Maybe<Sample_Url>;
    samples: Array<Sample>;
    samplesByBcdIdSearch?: Maybe<Array<Maybe<Sample>>>;
    samplesByMappingFileKey?: Maybe<Array<Maybe<Sample>>>;
    samplesByTrayCell?: Maybe<Array<Maybe<Sample>>>;
    samplesByTypeSearch?: Maybe<Array<Maybe<Sample>>>;
    samplesFullTextSearch?: Maybe<Array<Maybe<Sample>>>;
    /** fetch data from the table: "study" */
    study: Array<Study>;
    studyNodes: Array<StudyNode>;
    /** fetch aggregated fields from the table: "study" */
    study_aggregate: Study_Aggregate;
    /** fetch data from the table: "study" using primary key columns */
    study_by_pk?: Maybe<Study>;
    /** fetch data from the table: "study_url" */
    study_url: Array<Study_Url>;
    /** fetch aggregated fields from the table: "study_url" */
    study_url_aggregate: Study_Url_Aggregate;
    /** fetch data from the table: "study_url" using primary key columns */
    study_url_by_pk?: Maybe<Study_Url>;
    /** fetch data from the table: "user" */
    user: Array<User>;
    /** fetch aggregated fields from the table: "user" */
    user_aggregate: User_Aggregate;
    /** fetch data from the table: "user" using primary key columns */
    user_by_pk?: Maybe<User>;
    variableValues: Array<VariableValues>;
    variables: Array<Variable>;
};

export type QueryBcd_Id_Gen_TableArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Gen_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

export type QueryBcd_Id_Gen_Table_AggregateArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Gen_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

export type QueryBcd_Id_TableArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

export type QueryBcd_Id_Table_AggregateArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

export type QueryBcd_Id_Table_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryCalculateCurvesArgs = {
    measurements: Scalars['String'];
};

export type QueryCountSamplesByBcdIdSearchArgs = {
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryCountSamplesByTypeSearchArgs = {
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryCountSamplesFullTextSearchArgs = {
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    searchString?: Maybe<Scalars['String']>;
};

export type QueryImport_FileArgs = {
    distinct_on?: Maybe<Array<Import_File_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Import_File_Order_By>>;
    where?: Maybe<Import_File_Bool_Exp>;
};

export type QueryImport_File_AggregateArgs = {
    distinct_on?: Maybe<Array<Import_File_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Import_File_Order_By>>;
    where?: Maybe<Import_File_Bool_Exp>;
};

export type QueryImport_File_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryMeasurementTypesArgs = {
    options?: Maybe<MeasurementTypeOptions>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type QueryMeasurementsArgs = {
    options?: Maybe<MeasurementOptions>;
    where?: Maybe<MeasurementWhere>;
};

export type QueryMetadataArgs = {
    options?: Maybe<MetadataOptions>;
    where?: Maybe<MetadataWhere>;
};

export type QueryMetadataGroupsArgs = {
    options?: Maybe<MetadataGroupOptions>;
    where?: Maybe<MetadataGroupWhere>;
};

export type QueryPoolInputMetadataArgs = {
    options?: Maybe<PoolInputMetadataOptions>;
    where?: Maybe<PoolInputMetadataWhere>;
};

export type QueryPoolSourcesFullTextSearchArgs = {
    limit?: Maybe<Scalars['Int']>;
    searchString?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type QueryProcessMetadataValuesArgs = {
    options?: Maybe<ProcessMetadataValueOptions>;
    where?: Maybe<ProcessMetadataValueWhere>;
};

export type QueryProcessTypesArgs = {
    options?: Maybe<ProcessTypeOptions>;
    where?: Maybe<ProcessTypeWhere>;
};

export type QueryProcessesArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type QueryProjectArgs = {
    distinct_on?: Maybe<Array<Project_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Order_By>>;
    where?: Maybe<Project_Bool_Exp>;
};

export type QueryProject_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Order_By>>;
    where?: Maybe<Project_Bool_Exp>;
};

export type QueryProject_By_PkArgs = {
    projectId: Scalars['String'];
};

export type QueryProject_SampleArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

export type QueryProject_Sample_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

export type QueryProject_Sample_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryProject_StudyArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

export type QueryProject_Study_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

export type QueryProject_Study_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryProject_UrlArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

export type QueryProject_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

export type QueryProject_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryProject_UserArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

export type QueryProject_User_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

export type QueryProject_User_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryRelatedSamplesFullTextSearchArgs = {
    limit?: Maybe<Scalars['Int']>;
    searchString?: Maybe<Scalars['String']>;
};

export type QueryRoleArgs = {
    distinct_on?: Maybe<Array<Role_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Role_Order_By>>;
    where?: Maybe<Role_Bool_Exp>;
};

export type QueryRole_AggregateArgs = {
    distinct_on?: Maybe<Array<Role_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Role_Order_By>>;
    where?: Maybe<Role_Bool_Exp>;
};

export type QueryRole_By_PkArgs = {
    id: Scalars['Int'];
};

export type QuerySampleMetadataArgs = {
    options?: Maybe<SampleMetadataOptions>;
    where?: Maybe<SampleMetadataWhere>;
};

export type QuerySampleTypesArgs = {
    options?: Maybe<SampleTypeOptions>;
    where?: Maybe<SampleTypeWhere>;
};

export type QuerySample_UrlArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type QuerySample_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type QuerySample_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type QuerySamplesArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type QuerySamplesByBcdIdSearchArgs = {
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    limit?: Maybe<Scalars['Int']>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    sortDir?: Maybe<Scalars['String']>;
    sortType?: Maybe<Scalars['String']>;
};

export type QuerySamplesByMappingFileKeyArgs = {
    mappingFileKey?: Maybe<Scalars['String']>;
};

export type QuerySamplesByTrayCellArgs = {
    cellId?: Maybe<Scalars['String']>;
    trayName?: Maybe<Scalars['String']>;
};

export type QuerySamplesByTypeSearchArgs = {
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    limit?: Maybe<Scalars['Int']>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    sortDir?: Maybe<Scalars['String']>;
    sortType?: Maybe<Scalars['String']>;
};

export type QuerySamplesFullTextSearchArgs = {
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<Array<Maybe<Scalars['String']>>>;
    dataSource?: Maybe<Array<Maybe<Scalars['String']>>>;
    limit?: Maybe<Scalars['Int']>;
    processInputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    processOutputTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    searchString?: Maybe<Scalars['String']>;
    sortDir?: Maybe<Scalars['String']>;
    sortType?: Maybe<Scalars['String']>;
};

export type QueryStudyArgs = {
    distinct_on?: Maybe<Array<Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Order_By>>;
    where?: Maybe<Study_Bool_Exp>;
};

export type QueryStudyNodesArgs = {
    options?: Maybe<StudyNodeOptions>;
    where?: Maybe<StudyNodeWhere>;
};

export type QueryStudy_AggregateArgs = {
    distinct_on?: Maybe<Array<Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Order_By>>;
    where?: Maybe<Study_Bool_Exp>;
};

export type QueryStudy_By_PkArgs = {
    studyId: Scalars['String'];
};

export type QueryStudy_UrlArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

export type QueryStudy_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

export type QueryStudy_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type QueryUserArgs = {
    distinct_on?: Maybe<Array<User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<User_Order_By>>;
    where?: Maybe<User_Bool_Exp>;
};

export type QueryUser_AggregateArgs = {
    distinct_on?: Maybe<Array<User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<User_Order_By>>;
    where?: Maybe<User_Bool_Exp>;
};

export type QueryUser_By_PkArgs = {
    userId: Scalars['String'];
};

export type QueryVariableValuesArgs = {
    options?: Maybe<VariableValuesOptions>;
    where?: Maybe<VariableValuesWhere>;
};

export type QueryVariablesArgs = {
    options?: Maybe<VariableOptions>;
    where?: Maybe<VariableWhere>;
};

export type Sample = {
    __typename?: 'Sample';
    ancestorSamples?: Maybe<Array<Maybe<Sample>>>;
    bcdId: Scalars['ID'];
    childSamples?: Maybe<Array<Maybe<Sample>>>;
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<Array<Maybe<Process>>>;
    measurements?: Maybe<Array<Maybe<Measurement>>>;
    metadata?: Maybe<Array<Maybe<SampleMetadata>>>;
    name?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<Process>;
    parentSamples?: Maybe<Array<Maybe<Sample>>>;
    poolInputMetadata?: Maybe<Array<Maybe<PoolInputMetadata>>>;
    sampleRows: Array<Sample_Url>;
    sampleRows_aggregate: Sample_Url_Aggregate;
    sampleType?: Maybe<SampleType>;
    sourceDescendantSamples?: Maybe<Array<Maybe<Sample>>>;
    sourceId?: Maybe<Scalars['String']>;
    sourceSamples?: Maybe<Array<Maybe<Sample>>>;
};

export type SampleInputToProcessesArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type SampleMeasurementsArgs = {
    options?: Maybe<MeasurementOptions>;
    where?: Maybe<MeasurementWhere>;
};

export type SampleOutputByProcessArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type SampleSampleRowsArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type SampleSampleRows_AggregateArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type SampleSampleTypeArgs = {
    options?: Maybe<SampleTypeOptions>;
    where?: Maybe<SampleTypeWhere>;
};

export type SampleConnectFieldInput = {
    connect?: Maybe<SampleConnectInput>;
    where?: Maybe<SampleWhere>;
};

export type SampleConnectInput = {
    inputToProcesses?: Maybe<Array<ProcessConnectFieldInput>>;
    measurements?: Maybe<Array<MeasurementConnectFieldInput>>;
    outputByProcess?: Maybe<ProcessConnectFieldInput>;
    sampleType?: Maybe<SampleTypeConnectFieldInput>;
};

export type SampleCreateInput = {
    bcdId: Scalars['ID'];
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<SampleInputToProcessesFieldInput>;
    measurements?: Maybe<SampleMeasurementsFieldInput>;
    name?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<SampleOutputByProcessFieldInput>;
    sampleType?: Maybe<SampleSampleTypeFieldInput>;
    sourceId?: Maybe<Scalars['String']>;
};

export type SampleDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type SampleDeleteInput = {
    inputToProcesses?: Maybe<Array<SampleInputToProcessesDeleteFieldInput>>;
    measurements?: Maybe<Array<SampleMeasurementsDeleteFieldInput>>;
    outputByProcess?: Maybe<SampleOutputByProcessDeleteFieldInput>;
    sampleType?: Maybe<SampleSampleTypeDeleteFieldInput>;
};

export type SampleDisconnectFieldInput = {
    disconnect?: Maybe<SampleDisconnectInput>;
    where?: Maybe<SampleWhere>;
};

export type SampleDisconnectInput = {
    inputToProcesses?: Maybe<Array<ProcessDisconnectFieldInput>>;
    measurements?: Maybe<Array<MeasurementDisconnectFieldInput>>;
    outputByProcess?: Maybe<ProcessDisconnectFieldInput>;
    sampleType?: Maybe<SampleTypeDisconnectFieldInput>;
};

export type SampleInputToProcessesDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type SampleInputToProcessesFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
};

export type SampleInputToProcessesUpdateFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
    delete?: Maybe<Array<ProcessDeleteFieldInput>>;
    disconnect?: Maybe<Array<ProcessDisconnectFieldInput>>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

export type SampleMeasurementsDeleteFieldInput = {
    delete?: Maybe<MeasurementDeleteInput>;
    where?: Maybe<MeasurementWhere>;
};

export type SampleMeasurementsFieldInput = {
    connect?: Maybe<Array<MeasurementConnectFieldInput>>;
    create?: Maybe<Array<MeasurementCreateInput>>;
};

export type SampleMeasurementsUpdateFieldInput = {
    connect?: Maybe<Array<MeasurementConnectFieldInput>>;
    create?: Maybe<Array<MeasurementCreateInput>>;
    delete?: Maybe<Array<MeasurementDeleteFieldInput>>;
    disconnect?: Maybe<Array<MeasurementDisconnectFieldInput>>;
    update?: Maybe<MeasurementUpdateInput>;
    where?: Maybe<MeasurementWhere>;
};

export type SampleMetadata = {
    __typename?: 'SampleMetadata';
    name?: Maybe<Scalars['String']>;
    uneditable?: Maybe<Scalars['Boolean']>;
    units?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type SampleMetadataCreateInput = {
    uneditable?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
};

export type SampleMetadataOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more SampleMetadataSort objects to sort SampleMetadata by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<SampleMetadataSort>>>;
};

/** Fields to sort SampleMetadata by. The order in which sorts are applied is not guaranteed when specifying many fields in one SampleMetadataSort object. */
export type SampleMetadataSort = {
    uneditable?: Maybe<SortDirection>;
    value?: Maybe<SortDirection>;
};

export type SampleMetadataUpdateInput = {
    uneditable?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
};

export type SampleMetadataWhere = {
    AND?: Maybe<Array<SampleMetadataWhere>>;
    OR?: Maybe<Array<SampleMetadataWhere>>;
    uneditable?: Maybe<Scalars['Boolean']>;
    uneditable_NOT?: Maybe<Scalars['Boolean']>;
    value?: Maybe<Scalars['String']>;
    value_CONTAINS?: Maybe<Scalars['String']>;
    value_ENDS_WITH?: Maybe<Scalars['String']>;
    value_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT?: Maybe<Scalars['String']>;
    value_NOT_CONTAINS?: Maybe<Scalars['String']>;
    value_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    value_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    value_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    value_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type SampleOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more SampleSort objects to sort Samples by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<SampleSort>>>;
};

export type SampleOutputByProcessDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type SampleOutputByProcessFieldInput = {
    connect?: Maybe<ProcessConnectFieldInput>;
    create?: Maybe<ProcessCreateInput>;
};

export type SampleOutputByProcessUpdateFieldInput = {
    connect?: Maybe<ProcessConnectFieldInput>;
    create?: Maybe<ProcessCreateInput>;
    delete?: Maybe<ProcessDeleteFieldInput>;
    disconnect?: Maybe<ProcessDisconnectFieldInput>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

export type SampleRelationInput = {
    inputToProcesses?: Maybe<Array<ProcessCreateInput>>;
    measurements?: Maybe<Array<MeasurementCreateInput>>;
    outputByProcess?: Maybe<ProcessCreateInput>;
    sampleType?: Maybe<SampleTypeCreateInput>;
};

export type SampleSampleTypeDeleteFieldInput = {
    delete?: Maybe<SampleTypeDeleteInput>;
    where?: Maybe<SampleTypeWhere>;
};

export type SampleSampleTypeFieldInput = {
    connect?: Maybe<SampleTypeConnectFieldInput>;
    create?: Maybe<SampleTypeCreateInput>;
};

export type SampleSampleTypeUpdateFieldInput = {
    connect?: Maybe<SampleTypeConnectFieldInput>;
    create?: Maybe<SampleTypeCreateInput>;
    delete?: Maybe<SampleTypeDeleteFieldInput>;
    disconnect?: Maybe<SampleTypeDisconnectFieldInput>;
    update?: Maybe<SampleTypeUpdateInput>;
    where?: Maybe<SampleTypeWhere>;
};

/** Fields to sort Samples by. The order in which sorts are applied is not guaranteed when specifying many fields in one SampleSort object. */
export type SampleSort = {
    bcdId?: Maybe<SortDirection>;
    dateReceived?: Maybe<SortDirection>;
    name?: Maybe<SortDirection>;
    sourceId?: Maybe<SortDirection>;
};

export type SampleType = {
    __typename?: 'SampleType';
    metadata?: Maybe<Array<Maybe<Metadata>>>;
    name: Scalars['String'];
    samples?: Maybe<Array<Maybe<Sample>>>;
};

export type SampleTypeMetadataArgs = {
    options?: Maybe<MetadataOptions>;
    where?: Maybe<MetadataWhere>;
};

export type SampleTypeSamplesArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type SampleTypeConnectFieldInput = {
    connect?: Maybe<SampleTypeConnectInput>;
    where?: Maybe<SampleTypeWhere>;
};

export type SampleTypeConnectInput = {
    metadata?: Maybe<Array<MetadataConnectFieldInput>>;
    samples?: Maybe<Array<SampleConnectFieldInput>>;
};

export type SampleTypeCreateInput = {
    metadata?: Maybe<SampleTypeMetadataFieldInput>;
    name: Scalars['String'];
    samples?: Maybe<SampleTypeSamplesFieldInput>;
};

export type SampleTypeDeleteFieldInput = {
    delete?: Maybe<SampleTypeDeleteInput>;
    where?: Maybe<SampleTypeWhere>;
};

export type SampleTypeDeleteInput = {
    metadata?: Maybe<Array<SampleTypeMetadataDeleteFieldInput>>;
    samples?: Maybe<Array<SampleTypeSamplesDeleteFieldInput>>;
};

export type SampleTypeDisconnectFieldInput = {
    disconnect?: Maybe<SampleTypeDisconnectInput>;
    where?: Maybe<SampleTypeWhere>;
};

export type SampleTypeDisconnectInput = {
    metadata?: Maybe<Array<MetadataDisconnectFieldInput>>;
    samples?: Maybe<Array<SampleDisconnectFieldInput>>;
};

export type SampleTypeMetadataDeleteFieldInput = {
    delete?: Maybe<MetadataDeleteInput>;
    where?: Maybe<MetadataWhere>;
};

export type SampleTypeMetadataFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
};

export type SampleTypeMetadataUpdateFieldInput = {
    connect?: Maybe<Array<MetadataConnectFieldInput>>;
    create?: Maybe<Array<MetadataCreateInput>>;
    delete?: Maybe<Array<MetadataDeleteFieldInput>>;
    disconnect?: Maybe<Array<MetadataDisconnectFieldInput>>;
    update?: Maybe<MetadataUpdateInput>;
    where?: Maybe<MetadataWhere>;
};

export type SampleTypeOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more SampleTypeSort objects to sort SampleTypes by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<SampleTypeSort>>>;
};

export type SampleTypeRelationInput = {
    metadata?: Maybe<Array<MetadataCreateInput>>;
    samples?: Maybe<Array<SampleCreateInput>>;
};

export type SampleTypeSamplesDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type SampleTypeSamplesFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
};

export type SampleTypeSamplesUpdateFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
    delete?: Maybe<Array<SampleDeleteFieldInput>>;
    disconnect?: Maybe<Array<SampleDisconnectFieldInput>>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

/** Fields to sort SampleTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one SampleTypeSort object. */
export type SampleTypeSort = {
    name?: Maybe<SortDirection>;
};

export type SampleTypeUpdateInput = {
    metadata?: Maybe<Array<SampleTypeMetadataUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    samples?: Maybe<Array<SampleTypeSamplesUpdateFieldInput>>;
};

export type SampleTypeWhere = {
    AND?: Maybe<Array<SampleTypeWhere>>;
    OR?: Maybe<Array<SampleTypeWhere>>;
    metadata?: Maybe<MetadataWhere>;
    metadata_NOT?: Maybe<MetadataWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    samples?: Maybe<SampleWhere>;
    samples_NOT?: Maybe<SampleWhere>;
};

export type SampleUpdateInput = {
    bcdId?: Maybe<Scalars['ID']>;
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<Array<SampleInputToProcessesUpdateFieldInput>>;
    measurements?: Maybe<Array<SampleMeasurementsUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<SampleOutputByProcessUpdateFieldInput>;
    sampleType?: Maybe<SampleSampleTypeUpdateFieldInput>;
    sourceId?: Maybe<Scalars['String']>;
};

export type SampleWhere = {
    AND?: Maybe<Array<SampleWhere>>;
    OR?: Maybe<Array<SampleWhere>>;
    bcdId?: Maybe<Scalars['ID']>;
    bcdId_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT?: Maybe<Scalars['ID']>;
    bcdId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    bcdId_STARTS_WITH?: Maybe<Scalars['ID']>;
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<ProcessWhere>;
    inputToProcesses_NOT?: Maybe<ProcessWhere>;
    measurements?: Maybe<MeasurementWhere>;
    measurements_NOT?: Maybe<MeasurementWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<ProcessWhere>;
    outputByProcess_IN?: Maybe<Array<ProcessWhere>>;
    outputByProcess_NOT?: Maybe<ProcessWhere>;
    outputByProcess_NOT_IN?: Maybe<Array<ProcessWhere>>;
    sampleType?: Maybe<SampleTypeWhere>;
    sampleType_IN?: Maybe<Array<SampleTypeWhere>>;
    sampleType_NOT?: Maybe<SampleTypeWhere>;
    sampleType_NOT_IN?: Maybe<Array<SampleTypeWhere>>;
    sourceId?: Maybe<Scalars['String']>;
    sourceId_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT?: Maybe<Scalars['String']>;
    sourceId_NOT_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    sourceId_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type SampleWhere_Remote_Rel_Project_SamplesampleNode = {
    AND?: Maybe<Array<SampleWhere>>;
    OR?: Maybe<Array<SampleWhere>>;
    bcdId_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT?: Maybe<Scalars['ID']>;
    bcdId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    bcdId_STARTS_WITH?: Maybe<Scalars['ID']>;
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<ProcessWhere>;
    inputToProcesses_NOT?: Maybe<ProcessWhere>;
    measurements?: Maybe<MeasurementWhere>;
    measurements_NOT?: Maybe<MeasurementWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<ProcessWhere>;
    outputByProcess_IN?: Maybe<Array<ProcessWhere>>;
    outputByProcess_NOT?: Maybe<ProcessWhere>;
    outputByProcess_NOT_IN?: Maybe<Array<ProcessWhere>>;
    sampleType?: Maybe<SampleTypeWhere>;
    sampleType_IN?: Maybe<Array<SampleTypeWhere>>;
    sampleType_NOT?: Maybe<SampleTypeWhere>;
    sampleType_NOT_IN?: Maybe<Array<SampleTypeWhere>>;
    sourceId?: Maybe<Scalars['String']>;
    sourceId_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT?: Maybe<Scalars['String']>;
    sourceId_NOT_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    sourceId_STARTS_WITH?: Maybe<Scalars['String']>;
};

export type SampleWhere_Remote_Rel_Sample_UrlsampleNode = {
    AND?: Maybe<Array<SampleWhere>>;
    OR?: Maybe<Array<SampleWhere>>;
    bcdId_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT?: Maybe<Scalars['ID']>;
    bcdId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    bcdId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    bcdId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    bcdId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    bcdId_STARTS_WITH?: Maybe<Scalars['ID']>;
    dateReceived?: Maybe<Scalars['DateTime']>;
    inputToProcesses?: Maybe<ProcessWhere>;
    inputToProcesses_NOT?: Maybe<ProcessWhere>;
    measurements?: Maybe<MeasurementWhere>;
    measurements_NOT?: Maybe<MeasurementWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    outputByProcess?: Maybe<ProcessWhere>;
    outputByProcess_IN?: Maybe<Array<ProcessWhere>>;
    outputByProcess_NOT?: Maybe<ProcessWhere>;
    outputByProcess_NOT_IN?: Maybe<Array<ProcessWhere>>;
    sampleType?: Maybe<SampleTypeWhere>;
    sampleType_IN?: Maybe<Array<SampleTypeWhere>>;
    sampleType_NOT?: Maybe<SampleTypeWhere>;
    sampleType_NOT_IN?: Maybe<Array<SampleTypeWhere>>;
    sourceId?: Maybe<Scalars['String']>;
    sourceId_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT?: Maybe<Scalars['String']>;
    sourceId_NOT_CONTAINS?: Maybe<Scalars['String']>;
    sourceId_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    sourceId_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    sourceId_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    sourceId_STARTS_WITH?: Maybe<Scalars['String']>;
};

export enum SortDirection {
    /** Sort by field values in ascending order. */
    Asc = 'ASC',
    /** Sort by field values in descending order. */
    Desc = 'DESC',
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
    _eq?: Maybe<Scalars['String']>;
    _gt?: Maybe<Scalars['String']>;
    _gte?: Maybe<Scalars['String']>;
    /** does the column match the given case-insensitive pattern */
    _ilike?: Maybe<Scalars['String']>;
    _in?: Maybe<Array<Scalars['String']>>;
    /** does the column match the given POSIX regular expression, case insensitive */
    _iregex?: Maybe<Scalars['String']>;
    _is_null?: Maybe<Scalars['Boolean']>;
    /** does the column match the given pattern */
    _like?: Maybe<Scalars['String']>;
    _lt?: Maybe<Scalars['String']>;
    _lte?: Maybe<Scalars['String']>;
    _neq?: Maybe<Scalars['String']>;
    /** does the column NOT match the given case-insensitive pattern */
    _nilike?: Maybe<Scalars['String']>;
    _nin?: Maybe<Array<Scalars['String']>>;
    /** does the column NOT match the given POSIX regular expression, case insensitive */
    _niregex?: Maybe<Scalars['String']>;
    /** does the column NOT match the given pattern */
    _nlike?: Maybe<Scalars['String']>;
    /** does the column NOT match the given POSIX regular expression, case sensitive */
    _nregex?: Maybe<Scalars['String']>;
    /** does the column NOT match the given SQL regular expression */
    _nsimilar?: Maybe<Scalars['String']>;
    /** does the column match the given POSIX regular expression, case sensitive */
    _regex?: Maybe<Scalars['String']>;
    /** does the column match the given SQL regular expression */
    _similar?: Maybe<Scalars['String']>;
};

export type StudyNode = {
    __typename?: 'StudyNode';
    numCrosses?: Maybe<Scalars['Int']>;
    numTrays?: Maybe<Scalars['Int']>;
    processes?: Maybe<Array<Maybe<Process>>>;
    samples?: Maybe<Array<Maybe<Sample>>>;
    studyId: Scalars['ID'];
};

export type StudyNodeProcessesArgs = {
    options?: Maybe<ProcessOptions>;
    where?: Maybe<ProcessWhere>;
};

export type StudyNodeSamplesArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere>;
};

export type StudyNodeConnectFieldInput = {
    connect?: Maybe<StudyNodeConnectInput>;
    where?: Maybe<StudyNodeWhere>;
};

export type StudyNodeConnectInput = {
    processes?: Maybe<Array<ProcessConnectFieldInput>>;
    samples?: Maybe<Array<SampleConnectFieldInput>>;
};

export type StudyNodeCreateInput = {
    processes?: Maybe<StudyNodeProcessesFieldInput>;
    samples?: Maybe<StudyNodeSamplesFieldInput>;
    studyId: Scalars['ID'];
};

export type StudyNodeDeleteFieldInput = {
    delete?: Maybe<StudyNodeDeleteInput>;
    where?: Maybe<StudyNodeWhere>;
};

export type StudyNodeDeleteInput = {
    processes?: Maybe<Array<StudyNodeProcessesDeleteFieldInput>>;
    samples?: Maybe<Array<StudyNodeSamplesDeleteFieldInput>>;
};

export type StudyNodeDisconnectFieldInput = {
    disconnect?: Maybe<StudyNodeDisconnectInput>;
    where?: Maybe<StudyNodeWhere>;
};

export type StudyNodeDisconnectInput = {
    processes?: Maybe<Array<ProcessDisconnectFieldInput>>;
    samples?: Maybe<Array<SampleDisconnectFieldInput>>;
};

export type StudyNodeOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more StudyNodeSort objects to sort StudyNodes by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<StudyNodeSort>>>;
};

export type StudyNodeProcessesDeleteFieldInput = {
    delete?: Maybe<ProcessDeleteInput>;
    where?: Maybe<ProcessWhere>;
};

export type StudyNodeProcessesFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
};

export type StudyNodeProcessesUpdateFieldInput = {
    connect?: Maybe<Array<ProcessConnectFieldInput>>;
    create?: Maybe<Array<ProcessCreateInput>>;
    delete?: Maybe<Array<ProcessDeleteFieldInput>>;
    disconnect?: Maybe<Array<ProcessDisconnectFieldInput>>;
    update?: Maybe<ProcessUpdateInput>;
    where?: Maybe<ProcessWhere>;
};

export type StudyNodeRelationInput = {
    processes?: Maybe<Array<ProcessCreateInput>>;
    samples?: Maybe<Array<SampleCreateInput>>;
};

export type StudyNodeSamplesDeleteFieldInput = {
    delete?: Maybe<SampleDeleteInput>;
    where?: Maybe<SampleWhere>;
};

export type StudyNodeSamplesFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
};

export type StudyNodeSamplesUpdateFieldInput = {
    connect?: Maybe<Array<SampleConnectFieldInput>>;
    create?: Maybe<Array<SampleCreateInput>>;
    delete?: Maybe<Array<SampleDeleteFieldInput>>;
    disconnect?: Maybe<Array<SampleDisconnectFieldInput>>;
    update?: Maybe<SampleUpdateInput>;
    where?: Maybe<SampleWhere>;
};

/** Fields to sort StudyNodes by. The order in which sorts are applied is not guaranteed when specifying many fields in one StudyNodeSort object. */
export type StudyNodeSort = {
    studyId?: Maybe<SortDirection>;
};

export type StudyNodeUpdateInput = {
    processes?: Maybe<Array<StudyNodeProcessesUpdateFieldInput>>;
    samples?: Maybe<Array<StudyNodeSamplesUpdateFieldInput>>;
    studyId?: Maybe<Scalars['ID']>;
};

export type StudyNodeWhere = {
    AND?: Maybe<Array<StudyNodeWhere>>;
    OR?: Maybe<Array<StudyNodeWhere>>;
    processes?: Maybe<ProcessWhere>;
    processes_NOT?: Maybe<ProcessWhere>;
    samples?: Maybe<SampleWhere>;
    samples_NOT?: Maybe<SampleWhere>;
    studyId?: Maybe<Scalars['ID']>;
    studyId_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT?: Maybe<Scalars['ID']>;
    studyId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    studyId_STARTS_WITH?: Maybe<Scalars['ID']>;
};

export type StudyNodeWhere_Remote_Rel_StudystudyNodes = {
    AND?: Maybe<Array<StudyNodeWhere>>;
    OR?: Maybe<Array<StudyNodeWhere>>;
    processes?: Maybe<ProcessWhere>;
    processes_NOT?: Maybe<ProcessWhere>;
    samples?: Maybe<SampleWhere>;
    samples_NOT?: Maybe<SampleWhere>;
    studyId_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT?: Maybe<Scalars['ID']>;
    studyId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    studyId_STARTS_WITH?: Maybe<Scalars['ID']>;
};

export type StudyNodeWhere_Remote_Rel_Studytrays = {
    AND?: Maybe<Array<StudyNodeWhere>>;
    OR?: Maybe<Array<StudyNodeWhere>>;
    processes?: Maybe<ProcessWhere>;
    processes_NOT?: Maybe<ProcessWhere>;
    samples?: Maybe<SampleWhere>;
    samples_NOT?: Maybe<SampleWhere>;
    studyId_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT?: Maybe<Scalars['ID']>;
    studyId_NOT_CONTAINS?: Maybe<Scalars['ID']>;
    studyId_NOT_ENDS_WITH?: Maybe<Scalars['ID']>;
    studyId_NOT_IN?: Maybe<Array<Maybe<Scalars['ID']>>>;
    studyId_NOT_STARTS_WITH?: Maybe<Scalars['ID']>;
    studyId_STARTS_WITH?: Maybe<Scalars['ID']>;
};

export type Subscription = {
    __typename?: 'Subscription';
    /** fetch data from the table: "bcd_id_gen_table" */
    bcd_id_gen_table: Array<Bcd_Id_Gen_Table>;
    /** fetch aggregated fields from the table: "bcd_id_gen_table" */
    bcd_id_gen_table_aggregate: Bcd_Id_Gen_Table_Aggregate;
    /** fetch data from the table in a streaming manner: "bcd_id_gen_table" */
    bcd_id_gen_table_stream: Array<Bcd_Id_Gen_Table>;
    /** fetch data from the table: "bcd_id_table" */
    bcd_id_table: Array<Bcd_Id_Table>;
    /** fetch aggregated fields from the table: "bcd_id_table" */
    bcd_id_table_aggregate: Bcd_Id_Table_Aggregate;
    /** fetch data from the table: "bcd_id_table" using primary key columns */
    bcd_id_table_by_pk?: Maybe<Bcd_Id_Table>;
    /** fetch data from the table in a streaming manner: "bcd_id_table" */
    bcd_id_table_stream: Array<Bcd_Id_Table>;
    /** fetch data from the table: "import_file" */
    import_file: Array<Import_File>;
    /** fetch aggregated fields from the table: "import_file" */
    import_file_aggregate: Import_File_Aggregate;
    /** fetch data from the table: "import_file" using primary key columns */
    import_file_by_pk?: Maybe<Import_File>;
    /** fetch data from the table in a streaming manner: "import_file" */
    import_file_stream: Array<Import_File>;
    /** fetch data from the table: "project" */
    project: Array<Project>;
    /** fetch aggregated fields from the table: "project" */
    project_aggregate: Project_Aggregate;
    /** fetch data from the table: "project" using primary key columns */
    project_by_pk?: Maybe<Project>;
    /** fetch data from the table: "project_sample" */
    project_sample: Array<Project_Sample>;
    /** fetch aggregated fields from the table: "project_sample" */
    project_sample_aggregate: Project_Sample_Aggregate;
    /** fetch data from the table: "project_sample" using primary key columns */
    project_sample_by_pk?: Maybe<Project_Sample>;
    /** fetch data from the table in a streaming manner: "project_sample" */
    project_sample_stream: Array<Project_Sample>;
    /** fetch data from the table in a streaming manner: "project" */
    project_stream: Array<Project>;
    /** fetch data from the table: "project_study" */
    project_study: Array<Project_Study>;
    /** fetch aggregated fields from the table: "project_study" */
    project_study_aggregate: Project_Study_Aggregate;
    /** fetch data from the table: "project_study" using primary key columns */
    project_study_by_pk?: Maybe<Project_Study>;
    /** fetch data from the table in a streaming manner: "project_study" */
    project_study_stream: Array<Project_Study>;
    /** fetch data from the table: "project_url" */
    project_url: Array<Project_Url>;
    /** fetch aggregated fields from the table: "project_url" */
    project_url_aggregate: Project_Url_Aggregate;
    /** fetch data from the table: "project_url" using primary key columns */
    project_url_by_pk?: Maybe<Project_Url>;
    /** fetch data from the table in a streaming manner: "project_url" */
    project_url_stream: Array<Project_Url>;
    /** fetch data from the table: "project_user" */
    project_user: Array<Project_User>;
    /** fetch aggregated fields from the table: "project_user" */
    project_user_aggregate: Project_User_Aggregate;
    /** fetch data from the table: "project_user" using primary key columns */
    project_user_by_pk?: Maybe<Project_User>;
    /** fetch data from the table in a streaming manner: "project_user" */
    project_user_stream: Array<Project_User>;
    /** fetch data from the table: "role" */
    role: Array<Role>;
    /** fetch aggregated fields from the table: "role" */
    role_aggregate: Role_Aggregate;
    /** fetch data from the table: "role" using primary key columns */
    role_by_pk?: Maybe<Role>;
    /** fetch data from the table in a streaming manner: "role" */
    role_stream: Array<Role>;
    /** fetch data from the table: "sample_url" */
    sample_url: Array<Sample_Url>;
    /** fetch aggregated fields from the table: "sample_url" */
    sample_url_aggregate: Sample_Url_Aggregate;
    /** fetch data from the table: "sample_url" using primary key columns */
    sample_url_by_pk?: Maybe<Sample_Url>;
    /** fetch data from the table in a streaming manner: "sample_url" */
    sample_url_stream: Array<Sample_Url>;
    /** fetch data from the table: "study" */
    study: Array<Study>;
    /** fetch aggregated fields from the table: "study" */
    study_aggregate: Study_Aggregate;
    /** fetch data from the table: "study" using primary key columns */
    study_by_pk?: Maybe<Study>;
    /** fetch data from the table in a streaming manner: "study" */
    study_stream: Array<Study>;
    /** fetch data from the table: "study_url" */
    study_url: Array<Study_Url>;
    /** fetch aggregated fields from the table: "study_url" */
    study_url_aggregate: Study_Url_Aggregate;
    /** fetch data from the table: "study_url" using primary key columns */
    study_url_by_pk?: Maybe<Study_Url>;
    /** fetch data from the table in a streaming manner: "study_url" */
    study_url_stream: Array<Study_Url>;
    /** fetch data from the table: "user" */
    user: Array<User>;
    /** fetch aggregated fields from the table: "user" */
    user_aggregate: User_Aggregate;
    /** fetch data from the table: "user" using primary key columns */
    user_by_pk?: Maybe<User>;
    /** fetch data from the table in a streaming manner: "user" */
    user_stream: Array<User>;
};

export type SubscriptionBcd_Id_Gen_TableArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Gen_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

export type SubscriptionBcd_Id_Gen_Table_AggregateArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Gen_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

export type SubscriptionBcd_Id_Gen_Table_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Bcd_Id_Gen_Table_Stream_Cursor_Input>>;
    where?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
};

export type SubscriptionBcd_Id_TableArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

export type SubscriptionBcd_Id_Table_AggregateArgs = {
    distinct_on?: Maybe<Array<Bcd_Id_Table_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Bcd_Id_Table_Order_By>>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

export type SubscriptionBcd_Id_Table_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionBcd_Id_Table_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Bcd_Id_Table_Stream_Cursor_Input>>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

export type SubscriptionImport_FileArgs = {
    distinct_on?: Maybe<Array<Import_File_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Import_File_Order_By>>;
    where?: Maybe<Import_File_Bool_Exp>;
};

export type SubscriptionImport_File_AggregateArgs = {
    distinct_on?: Maybe<Array<Import_File_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Import_File_Order_By>>;
    where?: Maybe<Import_File_Bool_Exp>;
};

export type SubscriptionImport_File_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionImport_File_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Import_File_Stream_Cursor_Input>>;
    where?: Maybe<Import_File_Bool_Exp>;
};

export type SubscriptionProjectArgs = {
    distinct_on?: Maybe<Array<Project_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Order_By>>;
    where?: Maybe<Project_Bool_Exp>;
};

export type SubscriptionProject_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Order_By>>;
    where?: Maybe<Project_Bool_Exp>;
};

export type SubscriptionProject_By_PkArgs = {
    projectId: Scalars['String'];
};

export type SubscriptionProject_SampleArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

export type SubscriptionProject_Sample_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

export type SubscriptionProject_Sample_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionProject_Sample_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Project_Sample_Stream_Cursor_Input>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

export type SubscriptionProject_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Project_Stream_Cursor_Input>>;
    where?: Maybe<Project_Bool_Exp>;
};

export type SubscriptionProject_StudyArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

export type SubscriptionProject_Study_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

export type SubscriptionProject_Study_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionProject_Study_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Project_Study_Stream_Cursor_Input>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

export type SubscriptionProject_UrlArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

export type SubscriptionProject_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

export type SubscriptionProject_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionProject_Url_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Project_Url_Stream_Cursor_Input>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

export type SubscriptionProject_UserArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

export type SubscriptionProject_User_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

export type SubscriptionProject_User_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionProject_User_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Project_User_Stream_Cursor_Input>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

export type SubscriptionRoleArgs = {
    distinct_on?: Maybe<Array<Role_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Role_Order_By>>;
    where?: Maybe<Role_Bool_Exp>;
};

export type SubscriptionRole_AggregateArgs = {
    distinct_on?: Maybe<Array<Role_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Role_Order_By>>;
    where?: Maybe<Role_Bool_Exp>;
};

export type SubscriptionRole_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionRole_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Role_Stream_Cursor_Input>>;
    where?: Maybe<Role_Bool_Exp>;
};

export type SubscriptionSample_UrlArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type SubscriptionSample_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Sample_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Sample_Url_Order_By>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type SubscriptionSample_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionSample_Url_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Sample_Url_Stream_Cursor_Input>>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

export type SubscriptionStudyArgs = {
    distinct_on?: Maybe<Array<Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Order_By>>;
    where?: Maybe<Study_Bool_Exp>;
};

export type SubscriptionStudy_AggregateArgs = {
    distinct_on?: Maybe<Array<Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Order_By>>;
    where?: Maybe<Study_Bool_Exp>;
};

export type SubscriptionStudy_By_PkArgs = {
    studyId: Scalars['String'];
};

export type SubscriptionStudy_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Study_Stream_Cursor_Input>>;
    where?: Maybe<Study_Bool_Exp>;
};

export type SubscriptionStudy_UrlArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

export type SubscriptionStudy_Url_AggregateArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

export type SubscriptionStudy_Url_By_PkArgs = {
    id: Scalars['Int'];
};

export type SubscriptionStudy_Url_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<Study_Url_Stream_Cursor_Input>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

export type SubscriptionUserArgs = {
    distinct_on?: Maybe<Array<User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<User_Order_By>>;
    where?: Maybe<User_Bool_Exp>;
};

export type SubscriptionUser_AggregateArgs = {
    distinct_on?: Maybe<Array<User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<User_Order_By>>;
    where?: Maybe<User_Bool_Exp>;
};

export type SubscriptionUser_By_PkArgs = {
    userId: Scalars['String'];
};

export type SubscriptionUser_StreamArgs = {
    batch_size: Scalars['Int'];
    cursor: Array<Maybe<User_Stream_Cursor_Input>>;
    where?: Maybe<User_Bool_Exp>;
};

export type UdbDateTimeInput = {
    day?: Maybe<Scalars['Int']>;
    hour?: Maybe<Scalars['Int']>;
    minute?: Maybe<Scalars['Int']>;
    month?: Maybe<Scalars['Int']>;
    second?: Maybe<Scalars['Int']>;
    year?: Maybe<Scalars['Int']>;
};

export type UpdateMeasurementTypesMutationResponse = {
    __typename?: 'UpdateMeasurementTypesMutationResponse';
    measurementTypes: Array<MeasurementType>;
};

export type UpdateMeasurementsMutationResponse = {
    __typename?: 'UpdateMeasurementsMutationResponse';
    measurements: Array<Measurement>;
};

export type UpdateMetadataGroupsMutationResponse = {
    __typename?: 'UpdateMetadataGroupsMutationResponse';
    metadataGroups: Array<MetadataGroup>;
};

export type UpdateMetadataMutationResponse = {
    __typename?: 'UpdateMetadataMutationResponse';
    metadata: Array<Metadata>;
};

export type UpdatePoolInputMetadataMutationResponse = {
    __typename?: 'UpdatePoolInputMetadataMutationResponse';
    poolInputMetadata: Array<PoolInputMetadata>;
};

export type UpdateProcessMetadataValuesMutationResponse = {
    __typename?: 'UpdateProcessMetadataValuesMutationResponse';
    processMetadataValues: Array<ProcessMetadataValue>;
};

export type UpdateProcessTypesMutationResponse = {
    __typename?: 'UpdateProcessTypesMutationResponse';
    processTypes: Array<ProcessType>;
};

export type UpdateProcessesMutationResponse = {
    __typename?: 'UpdateProcessesMutationResponse';
    processes: Array<Process>;
};

export type UpdateSampleMetadataMutationResponse = {
    __typename?: 'UpdateSampleMetadataMutationResponse';
    sampleMetadata: Array<SampleMetadata>;
};

export type UpdateSampleTypesMutationResponse = {
    __typename?: 'UpdateSampleTypesMutationResponse';
    sampleTypes: Array<SampleType>;
};

export type UpdateSamplesMutationResponse = {
    __typename?: 'UpdateSamplesMutationResponse';
    samples: Array<Sample>;
};

export type UpdateStudyNodesMutationResponse = {
    __typename?: 'UpdateStudyNodesMutationResponse';
    studyNodes: Array<StudyNode>;
};

export type UpdateVariableValuesMutationResponse = {
    __typename?: 'UpdateVariableValuesMutationResponse';
    variableValues: Array<VariableValues>;
};

export type UpdateVariablesMutationResponse = {
    __typename?: 'UpdateVariablesMutationResponse';
    variables: Array<Variable>;
};

export type Variable = {
    __typename?: 'Variable';
    measurementTypes?: Maybe<Array<Maybe<MeasurementType>>>;
    name: Scalars['String'];
    values?: Maybe<Array<Maybe<VariableValues>>>;
};

export type VariableMeasurementTypesArgs = {
    options?: Maybe<MeasurementTypeOptions>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type VariableValuesArgs = {
    options?: Maybe<VariableValuesOptions>;
    where?: Maybe<VariableValuesWhere>;
};

export type VariableConnectFieldInput = {
    connect?: Maybe<VariableConnectInput>;
    where?: Maybe<VariableWhere>;
};

export type VariableConnectInput = {
    measurementTypes?: Maybe<Array<MeasurementTypeConnectFieldInput>>;
    values?: Maybe<Array<VariableValuesConnectFieldInput>>;
};

export type VariableCreateInput = {
    measurementTypes?: Maybe<VariableMeasurementTypesFieldInput>;
    name: Scalars['String'];
    values?: Maybe<VariableValuesFieldInput>;
};

export type VariableDeleteFieldInput = {
    delete?: Maybe<VariableDeleteInput>;
    where?: Maybe<VariableWhere>;
};

export type VariableDeleteInput = {
    measurementTypes?: Maybe<Array<VariableMeasurementTypesDeleteFieldInput>>;
    values?: Maybe<Array<VariableValuesDeleteFieldInput>>;
};

export type VariableDisconnectFieldInput = {
    disconnect?: Maybe<VariableDisconnectInput>;
    where?: Maybe<VariableWhere>;
};

export type VariableDisconnectInput = {
    measurementTypes?: Maybe<Array<MeasurementTypeDisconnectFieldInput>>;
    values?: Maybe<Array<VariableValuesDisconnectFieldInput>>;
};

export type VariableMeasurementTypesDeleteFieldInput = {
    delete?: Maybe<MeasurementTypeDeleteInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type VariableMeasurementTypesFieldInput = {
    connect?: Maybe<Array<MeasurementTypeConnectFieldInput>>;
    create?: Maybe<Array<MeasurementTypeCreateInput>>;
};

export type VariableMeasurementTypesUpdateFieldInput = {
    connect?: Maybe<Array<MeasurementTypeConnectFieldInput>>;
    create?: Maybe<Array<MeasurementTypeCreateInput>>;
    delete?: Maybe<Array<MeasurementTypeDeleteFieldInput>>;
    disconnect?: Maybe<Array<MeasurementTypeDisconnectFieldInput>>;
    update?: Maybe<MeasurementTypeUpdateInput>;
    where?: Maybe<MeasurementTypeWhere>;
};

export type VariableOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more VariableSort objects to sort Variables by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<VariableSort>>>;
};

export type VariableRelationInput = {
    measurementTypes?: Maybe<Array<MeasurementTypeCreateInput>>;
    values?: Maybe<Array<VariableValuesCreateInput>>;
};

/** Fields to sort Variables by. The order in which sorts are applied is not guaranteed when specifying many fields in one VariableSort object. */
export type VariableSort = {
    name?: Maybe<SortDirection>;
};

export type VariableUpdateInput = {
    measurementTypes?: Maybe<Array<VariableMeasurementTypesUpdateFieldInput>>;
    name?: Maybe<Scalars['String']>;
    values?: Maybe<Array<VariableValuesUpdateFieldInput>>;
};

export type VariableValues = {
    __typename?: 'VariableValues';
    name?: Maybe<Scalars['String']>;
    replicate?: Maybe<Scalars['String']>;
    stringValue?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
};

export type VariableValuesConnectFieldInput = {
    where?: Maybe<VariableValuesWhere>;
};

export type VariableValuesCreateInput = {
    replicate?: Maybe<Scalars['String']>;
    stringValue?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
};

export type VariableValuesDeleteFieldInput = {
    where?: Maybe<VariableValuesWhere>;
};

export type VariableValuesDisconnectFieldInput = {
    where?: Maybe<VariableValuesWhere>;
};

export type VariableValuesFieldInput = {
    connect?: Maybe<Array<VariableValuesConnectFieldInput>>;
    create?: Maybe<Array<VariableValuesCreateInput>>;
};

export type VariableValuesOptions = {
    limit?: Maybe<Scalars['Int']>;
    skip?: Maybe<Scalars['Int']>;
    /** Specify one or more VariableValuesSort objects to sort VariableValues by. The sorts will be applied in the order in which they are arranged in the array. */
    sort?: Maybe<Array<Maybe<VariableValuesSort>>>;
};

/** Fields to sort VariableValues by. The order in which sorts are applied is not guaranteed when specifying many fields in one VariableValuesSort object. */
export type VariableValuesSort = {
    replicate?: Maybe<SortDirection>;
    stringValue?: Maybe<SortDirection>;
    value?: Maybe<SortDirection>;
};

export type VariableValuesUpdateFieldInput = {
    connect?: Maybe<Array<VariableValuesConnectFieldInput>>;
    create?: Maybe<Array<VariableValuesCreateInput>>;
    delete?: Maybe<Array<VariableValuesDeleteFieldInput>>;
    disconnect?: Maybe<Array<VariableValuesDisconnectFieldInput>>;
    update?: Maybe<VariableValuesUpdateInput>;
    where?: Maybe<VariableValuesWhere>;
};

export type VariableValuesUpdateInput = {
    replicate?: Maybe<Scalars['String']>;
    stringValue?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
};

export type VariableValuesWhere = {
    AND?: Maybe<Array<VariableValuesWhere>>;
    OR?: Maybe<Array<VariableValuesWhere>>;
    replicate?: Maybe<Scalars['String']>;
    replicate_CONTAINS?: Maybe<Scalars['String']>;
    replicate_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT?: Maybe<Scalars['String']>;
    replicate_NOT_CONTAINS?: Maybe<Scalars['String']>;
    replicate_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    replicate_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    replicate_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    replicate_STARTS_WITH?: Maybe<Scalars['String']>;
    stringValue?: Maybe<Scalars['String']>;
    stringValue_CONTAINS?: Maybe<Scalars['String']>;
    stringValue_ENDS_WITH?: Maybe<Scalars['String']>;
    stringValue_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    stringValue_NOT?: Maybe<Scalars['String']>;
    stringValue_NOT_CONTAINS?: Maybe<Scalars['String']>;
    stringValue_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    stringValue_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    stringValue_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    stringValue_STARTS_WITH?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
    value_GT?: Maybe<Scalars['Float']>;
    value_GTE?: Maybe<Scalars['Float']>;
    value_IN?: Maybe<Array<Maybe<Scalars['Float']>>>;
    value_LT?: Maybe<Scalars['Float']>;
    value_LTE?: Maybe<Scalars['Float']>;
    value_NOT?: Maybe<Scalars['Float']>;
    value_NOT_IN?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type VariableWhere = {
    AND?: Maybe<Array<VariableWhere>>;
    OR?: Maybe<Array<VariableWhere>>;
    measurementTypes?: Maybe<MeasurementTypeWhere>;
    measurementTypes_NOT?: Maybe<MeasurementTypeWhere>;
    name?: Maybe<Scalars['String']>;
    name_CONTAINS?: Maybe<Scalars['String']>;
    name_ENDS_WITH?: Maybe<Scalars['String']>;
    name_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT?: Maybe<Scalars['String']>;
    name_NOT_CONTAINS?: Maybe<Scalars['String']>;
    name_NOT_ENDS_WITH?: Maybe<Scalars['String']>;
    name_NOT_IN?: Maybe<Array<Maybe<Scalars['String']>>>;
    name_NOT_STARTS_WITH?: Maybe<Scalars['String']>;
    name_STARTS_WITH?: Maybe<Scalars['String']>;
    values?: Maybe<VariableValuesWhere>;
    values_NOT?: Maybe<VariableValuesWhere>;
};

/** columns and relationships of "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table = {
    __typename?: 'bcd_id_gen_table';
    bcd_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Aggregate = {
    __typename?: 'bcd_id_gen_table_aggregate';
    aggregate?: Maybe<Bcd_Id_Gen_Table_Aggregate_Fields>;
    nodes: Array<Bcd_Id_Gen_Table>;
};

/** aggregate fields of "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Aggregate_Fields = {
    __typename?: 'bcd_id_gen_table_aggregate_fields';
    count: Scalars['Int'];
    max?: Maybe<Bcd_Id_Gen_Table_Max_Fields>;
    min?: Maybe<Bcd_Id_Gen_Table_Min_Fields>;
};

/** aggregate fields of "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Bcd_Id_Gen_Table_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bcd_id_gen_table". All fields are combined with a logical 'AND'. */
export type Bcd_Id_Gen_Table_Bool_Exp = {
    _and?: Maybe<Array<Bcd_Id_Gen_Table_Bool_Exp>>;
    _not?: Maybe<Bcd_Id_Gen_Table_Bool_Exp>;
    _or?: Maybe<Array<Bcd_Id_Gen_Table_Bool_Exp>>;
    bcd_id?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Insert_Input = {
    bcd_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bcd_Id_Gen_Table_Max_Fields = {
    __typename?: 'bcd_id_gen_table_max_fields';
    bcd_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bcd_Id_Gen_Table_Min_Fields = {
    __typename?: 'bcd_id_gen_table_min_fields';
    bcd_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Mutation_Response = {
    __typename?: 'bcd_id_gen_table_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Bcd_Id_Gen_Table>;
};

/** Ordering options when selecting data from "bcd_id_gen_table". */
export type Bcd_Id_Gen_Table_Order_By = {
    bcd_id?: Maybe<Order_By>;
};

/** select columns of table "bcd_id_gen_table" */
export enum Bcd_Id_Gen_Table_Select_Column {
    /** column name */
    BcdId = 'bcd_id',
}

/** input type for updating data in table "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Set_Input = {
    bcd_id?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "bcd_id_gen_table" */
export type Bcd_Id_Gen_Table_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Bcd_Id_Gen_Table_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bcd_Id_Gen_Table_Stream_Cursor_Value_Input = {
    bcd_id?: Maybe<Scalars['String']>;
};

export type Bcd_Id_Gen_Table_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Bcd_Id_Gen_Table_Set_Input>;
    /** filter the rows which have to be updated */
    where: Bcd_Id_Gen_Table_Bool_Exp;
};

/** columns and relationships of "bcd_id_table" */
export type Bcd_Id_Table = {
    __typename?: 'bcd_id_table';
    id: Scalars['Int'];
};

/** aggregated selection of "bcd_id_table" */
export type Bcd_Id_Table_Aggregate = {
    __typename?: 'bcd_id_table_aggregate';
    aggregate?: Maybe<Bcd_Id_Table_Aggregate_Fields>;
    nodes: Array<Bcd_Id_Table>;
};

/** aggregate fields of "bcd_id_table" */
export type Bcd_Id_Table_Aggregate_Fields = {
    __typename?: 'bcd_id_table_aggregate_fields';
    avg?: Maybe<Bcd_Id_Table_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Bcd_Id_Table_Max_Fields>;
    min?: Maybe<Bcd_Id_Table_Min_Fields>;
    stddev?: Maybe<Bcd_Id_Table_Stddev_Fields>;
    stddev_pop?: Maybe<Bcd_Id_Table_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Bcd_Id_Table_Stddev_Samp_Fields>;
    sum?: Maybe<Bcd_Id_Table_Sum_Fields>;
    var_pop?: Maybe<Bcd_Id_Table_Var_Pop_Fields>;
    var_samp?: Maybe<Bcd_Id_Table_Var_Samp_Fields>;
    variance?: Maybe<Bcd_Id_Table_Variance_Fields>;
};

/** aggregate fields of "bcd_id_table" */
export type Bcd_Id_Table_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Bcd_Id_Table_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bcd_Id_Table_Avg_Fields = {
    __typename?: 'bcd_id_table_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bcd_id_table". All fields are combined with a logical 'AND'. */
export type Bcd_Id_Table_Bool_Exp = {
    _and?: Maybe<Array<Bcd_Id_Table_Bool_Exp>>;
    _not?: Maybe<Bcd_Id_Table_Bool_Exp>;
    _or?: Maybe<Array<Bcd_Id_Table_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bcd_id_table" */
export enum Bcd_Id_Table_Constraint {
    /** unique or primary key constraint on columns "id" */
    BcdIdTablePkey = 'bcd_id_table_pkey',
}

/** input type for incrementing numeric columns in table "bcd_id_table" */
export type Bcd_Id_Table_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bcd_id_table" */
export type Bcd_Id_Table_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bcd_Id_Table_Max_Fields = {
    __typename?: 'bcd_id_table_max_fields';
    id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Bcd_Id_Table_Min_Fields = {
    __typename?: 'bcd_id_table_min_fields';
    id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "bcd_id_table" */
export type Bcd_Id_Table_Mutation_Response = {
    __typename?: 'bcd_id_table_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Bcd_Id_Table>;
};

/** on_conflict condition type for table "bcd_id_table" */
export type Bcd_Id_Table_On_Conflict = {
    constraint: Bcd_Id_Table_Constraint;
    update_columns?: Array<Bcd_Id_Table_Update_Column>;
    where?: Maybe<Bcd_Id_Table_Bool_Exp>;
};

/** Ordering options when selecting data from "bcd_id_table". */
export type Bcd_Id_Table_Order_By = {
    id?: Maybe<Order_By>;
};

/** primary key columns input for table: bcd_id_table */
export type Bcd_Id_Table_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "bcd_id_table" */
export enum Bcd_Id_Table_Select_Column {
    /** column name */
    Id = 'id',
}

/** input type for updating data in table "bcd_id_table" */
export type Bcd_Id_Table_Set_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bcd_Id_Table_Stddev_Fields = {
    __typename?: 'bcd_id_table_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bcd_Id_Table_Stddev_Pop_Fields = {
    __typename?: 'bcd_id_table_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bcd_Id_Table_Stddev_Samp_Fields = {
    __typename?: 'bcd_id_table_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bcd_id_table" */
export type Bcd_Id_Table_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Bcd_Id_Table_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bcd_Id_Table_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Bcd_Id_Table_Sum_Fields = {
    __typename?: 'bcd_id_table_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** update columns of table "bcd_id_table" */
export enum Bcd_Id_Table_Update_Column {
    /** column name */
    Id = 'id',
}

export type Bcd_Id_Table_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Bcd_Id_Table_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Bcd_Id_Table_Set_Input>;
    /** filter the rows which have to be updated */
    where: Bcd_Id_Table_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Bcd_Id_Table_Var_Pop_Fields = {
    __typename?: 'bcd_id_table_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bcd_Id_Table_Var_Samp_Fields = {
    __typename?: 'bcd_id_table_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bcd_Id_Table_Variance_Fields = {
    __typename?: 'bcd_id_table_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

export type Create_Bcd_Id_Args = {
    num?: Maybe<Scalars['Int']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
    /** ascending ordering of the cursor */
    Asc = 'ASC',
    /** descending ordering of the cursor */
    Desc = 'DESC',
}

/** columns and relationships of "import_file" */
export type Import_File = {
    __typename?: 'import_file';
    archive_filepath: Scalars['String'];
    datafile_type: Scalars['String'];
    id: Scalars['Int'];
    import_date: Scalars['timestamptz'];
    original_filename: Scalars['String'];
};

/** aggregated selection of "import_file" */
export type Import_File_Aggregate = {
    __typename?: 'import_file_aggregate';
    aggregate?: Maybe<Import_File_Aggregate_Fields>;
    nodes: Array<Import_File>;
};

/** aggregate fields of "import_file" */
export type Import_File_Aggregate_Fields = {
    __typename?: 'import_file_aggregate_fields';
    avg?: Maybe<Import_File_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Import_File_Max_Fields>;
    min?: Maybe<Import_File_Min_Fields>;
    stddev?: Maybe<Import_File_Stddev_Fields>;
    stddev_pop?: Maybe<Import_File_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Import_File_Stddev_Samp_Fields>;
    sum?: Maybe<Import_File_Sum_Fields>;
    var_pop?: Maybe<Import_File_Var_Pop_Fields>;
    var_samp?: Maybe<Import_File_Var_Samp_Fields>;
    variance?: Maybe<Import_File_Variance_Fields>;
};

/** aggregate fields of "import_file" */
export type Import_File_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Import_File_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Import_File_Avg_Fields = {
    __typename?: 'import_file_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "import_file". All fields are combined with a logical 'AND'. */
export type Import_File_Bool_Exp = {
    _and?: Maybe<Array<Import_File_Bool_Exp>>;
    _not?: Maybe<Import_File_Bool_Exp>;
    _or?: Maybe<Array<Import_File_Bool_Exp>>;
    archive_filepath?: Maybe<String_Comparison_Exp>;
    datafile_type?: Maybe<String_Comparison_Exp>;
    id?: Maybe<Int_Comparison_Exp>;
    import_date?: Maybe<Timestamptz_Comparison_Exp>;
    original_filename?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "import_file" */
export enum Import_File_Constraint {
    /** unique or primary key constraint on columns "id" */
    ImportFilePkey = 'import_file_pkey',
}

/** input type for incrementing numeric columns in table "import_file" */
export type Import_File_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "import_file" */
export type Import_File_Insert_Input = {
    archive_filepath?: Maybe<Scalars['String']>;
    datafile_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    import_date?: Maybe<Scalars['timestamptz']>;
    original_filename?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Import_File_Max_Fields = {
    __typename?: 'import_file_max_fields';
    archive_filepath?: Maybe<Scalars['String']>;
    datafile_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    import_date?: Maybe<Scalars['timestamptz']>;
    original_filename?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Import_File_Min_Fields = {
    __typename?: 'import_file_min_fields';
    archive_filepath?: Maybe<Scalars['String']>;
    datafile_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    import_date?: Maybe<Scalars['timestamptz']>;
    original_filename?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "import_file" */
export type Import_File_Mutation_Response = {
    __typename?: 'import_file_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Import_File>;
};

/** on_conflict condition type for table "import_file" */
export type Import_File_On_Conflict = {
    constraint: Import_File_Constraint;
    update_columns?: Array<Import_File_Update_Column>;
    where?: Maybe<Import_File_Bool_Exp>;
};

/** Ordering options when selecting data from "import_file". */
export type Import_File_Order_By = {
    archive_filepath?: Maybe<Order_By>;
    datafile_type?: Maybe<Order_By>;
    id?: Maybe<Order_By>;
    import_date?: Maybe<Order_By>;
    original_filename?: Maybe<Order_By>;
};

/** primary key columns input for table: import_file */
export type Import_File_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "import_file" */
export enum Import_File_Select_Column {
    /** column name */
    ArchiveFilepath = 'archive_filepath',
    /** column name */
    DatafileType = 'datafile_type',
    /** column name */
    Id = 'id',
    /** column name */
    ImportDate = 'import_date',
    /** column name */
    OriginalFilename = 'original_filename',
}

/** input type for updating data in table "import_file" */
export type Import_File_Set_Input = {
    archive_filepath?: Maybe<Scalars['String']>;
    datafile_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    import_date?: Maybe<Scalars['timestamptz']>;
    original_filename?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Import_File_Stddev_Fields = {
    __typename?: 'import_file_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Import_File_Stddev_Pop_Fields = {
    __typename?: 'import_file_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Import_File_Stddev_Samp_Fields = {
    __typename?: 'import_file_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "import_file" */
export type Import_File_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Import_File_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Import_File_Stream_Cursor_Value_Input = {
    archive_filepath?: Maybe<Scalars['String']>;
    datafile_type?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['Int']>;
    import_date?: Maybe<Scalars['timestamptz']>;
    original_filename?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Import_File_Sum_Fields = {
    __typename?: 'import_file_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** update columns of table "import_file" */
export enum Import_File_Update_Column {
    /** column name */
    ArchiveFilepath = 'archive_filepath',
    /** column name */
    DatafileType = 'datafile_type',
    /** column name */
    Id = 'id',
    /** column name */
    ImportDate = 'import_date',
    /** column name */
    OriginalFilename = 'original_filename',
}

export type Import_File_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Import_File_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Import_File_Set_Input>;
    /** filter the rows which have to be updated */
    where: Import_File_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Import_File_Var_Pop_Fields = {
    __typename?: 'import_file_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Import_File_Var_Samp_Fields = {
    __typename?: 'import_file_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Import_File_Variance_Fields = {
    __typename?: 'import_file_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum Order_By {
    /** in ascending order, nulls last */
    Asc = 'asc',
    /** in ascending order, nulls first */
    AscNullsFirst = 'asc_nulls_first',
    /** in ascending order, nulls last */
    AscNullsLast = 'asc_nulls_last',
    /** in descending order, nulls first */
    Desc = 'desc',
    /** in descending order, nulls first */
    DescNullsFirst = 'desc_nulls_first',
    /** in descending order, nulls last */
    DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "project" */
export type Project = {
    __typename?: 'project';
    /** An array relationship */
    assignedUsers: Array<Project_User>;
    /** An aggregate relationship */
    assignedUsers_aggregate: Project_User_Aggregate;
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId: Scalars['String'];
    projectName?: Maybe<Scalars['String']>;
    /** An array relationship */
    project_urls: Array<Project_Url>;
    /** An aggregate relationship */
    project_urls_aggregate: Project_Url_Aggregate;
    /** An array relationship */
    relatedSamples: Array<Project_Sample>;
    /** An aggregate relationship */
    relatedSamples_aggregate: Project_Sample_Aggregate;
    /** An array relationship */
    relatedStudies: Array<Project_Study>;
    /** An aggregate relationship */
    relatedStudies_aggregate: Project_Study_Aggregate;
    startDate?: Maybe<Scalars['String']>;
};

/** columns and relationships of "project" */
export type ProjectAssignedUsersArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectAssignedUsers_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_User_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_User_Order_By>>;
    where?: Maybe<Project_User_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectProject_UrlsArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectProject_Urls_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Url_Order_By>>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectRelatedSamplesArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectRelatedSamples_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Sample_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Sample_Order_By>>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectRelatedStudiesArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

/** columns and relationships of "project" */
export type ProjectRelatedStudies_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

/** aggregated selection of "project" */
export type Project_Aggregate = {
    __typename?: 'project_aggregate';
    aggregate?: Maybe<Project_Aggregate_Fields>;
    nodes: Array<Project>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_Fields = {
    __typename?: 'project_aggregate_fields';
    count: Scalars['Int'];
    max?: Maybe<Project_Max_Fields>;
    min?: Maybe<Project_Min_Fields>;
};

/** aggregate fields of "project" */
export type Project_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Project_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
    _and?: Maybe<Array<Project_Bool_Exp>>;
    _not?: Maybe<Project_Bool_Exp>;
    _or?: Maybe<Array<Project_Bool_Exp>>;
    assignedUsers?: Maybe<Project_User_Bool_Exp>;
    assignedUsers_aggregate?: Maybe<Project_User_Aggregate_Bool_Exp>;
    client?: Maybe<String_Comparison_Exp>;
    completionDate?: Maybe<String_Comparison_Exp>;
    finalReportUrl?: Maybe<String_Comparison_Exp>;
    notes?: Maybe<String_Comparison_Exp>;
    objective?: Maybe<String_Comparison_Exp>;
    projectId?: Maybe<String_Comparison_Exp>;
    projectName?: Maybe<String_Comparison_Exp>;
    project_urls?: Maybe<Project_Url_Bool_Exp>;
    project_urls_aggregate?: Maybe<Project_Url_Aggregate_Bool_Exp>;
    relatedSamples?: Maybe<Project_Sample_Bool_Exp>;
    relatedSamples_aggregate?: Maybe<Project_Sample_Aggregate_Bool_Exp>;
    relatedStudies?: Maybe<Project_Study_Bool_Exp>;
    relatedStudies_aggregate?: Maybe<Project_Study_Aggregate_Bool_Exp>;
    startDate?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project" */
export enum Project_Constraint {
    /** unique or primary key constraint on columns "projectId" */
    ProjectPkey = 'project_pkey',
}

/** input type for inserting data into table "project" */
export type Project_Insert_Input = {
    assignedUsers?: Maybe<Project_User_Arr_Rel_Insert_Input>;
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    project_urls?: Maybe<Project_Url_Arr_Rel_Insert_Input>;
    relatedSamples?: Maybe<Project_Sample_Arr_Rel_Insert_Input>;
    relatedStudies?: Maybe<Project_Study_Arr_Rel_Insert_Input>;
    startDate?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
    __typename?: 'project_max_fields';
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
    __typename?: 'project_min_fields';
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project" */
export type Project_Mutation_Response = {
    __typename?: 'project_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Project>;
};

/** input type for inserting object relation for remote table "project" */
export type Project_Obj_Rel_Insert_Input = {
    data: Project_Insert_Input;
    /** upsert condition */
    on_conflict?: Maybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "project" */
export type Project_On_Conflict = {
    constraint: Project_Constraint;
    update_columns?: Array<Project_Update_Column>;
    where?: Maybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "project". */
export type Project_Order_By = {
    assignedUsers_aggregate?: Maybe<Project_User_Aggregate_Order_By>;
    client?: Maybe<Order_By>;
    completionDate?: Maybe<Order_By>;
    finalReportUrl?: Maybe<Order_By>;
    notes?: Maybe<Order_By>;
    objective?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    projectName?: Maybe<Order_By>;
    project_urls_aggregate?: Maybe<Project_Url_Aggregate_Order_By>;
    relatedSamples_aggregate?: Maybe<Project_Sample_Aggregate_Order_By>;
    relatedStudies_aggregate?: Maybe<Project_Study_Aggregate_Order_By>;
    startDate?: Maybe<Order_By>;
};

/** primary key columns input for table: project */
export type Project_Pk_Columns_Input = {
    projectId: Scalars['String'];
};

/** columns and relationships of "project_sample" */
export type Project_Sample = {
    __typename?: 'project_sample';
    id: Scalars['Int'];
    /** An object relationship */
    project: Project;
    projectId: Scalars['String'];
    sampleBcdId: Scalars['String'];
    sampleNode: Array<Sample>;
};

/** columns and relationships of "project_sample" */
export type Project_SampleSampleNodeArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere_Remote_Rel_Project_SamplesampleNode>;
};

/** aggregated selection of "project_sample" */
export type Project_Sample_Aggregate = {
    __typename?: 'project_sample_aggregate';
    aggregate?: Maybe<Project_Sample_Aggregate_Fields>;
    nodes: Array<Project_Sample>;
};

export type Project_Sample_Aggregate_Bool_Exp = {
    count?: Maybe<Project_Sample_Aggregate_Bool_Exp_Count>;
};

export type Project_Sample_Aggregate_Bool_Exp_Count = {
    arguments?: Maybe<Array<Project_Sample_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
    filter?: Maybe<Project_Sample_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_sample" */
export type Project_Sample_Aggregate_Fields = {
    __typename?: 'project_sample_aggregate_fields';
    avg?: Maybe<Project_Sample_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Project_Sample_Max_Fields>;
    min?: Maybe<Project_Sample_Min_Fields>;
    stddev?: Maybe<Project_Sample_Stddev_Fields>;
    stddev_pop?: Maybe<Project_Sample_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Project_Sample_Stddev_Samp_Fields>;
    sum?: Maybe<Project_Sample_Sum_Fields>;
    var_pop?: Maybe<Project_Sample_Var_Pop_Fields>;
    var_samp?: Maybe<Project_Sample_Var_Samp_Fields>;
    variance?: Maybe<Project_Sample_Variance_Fields>;
};

/** aggregate fields of "project_sample" */
export type Project_Sample_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Project_Sample_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_sample" */
export type Project_Sample_Aggregate_Order_By = {
    avg?: Maybe<Project_Sample_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Project_Sample_Max_Order_By>;
    min?: Maybe<Project_Sample_Min_Order_By>;
    stddev?: Maybe<Project_Sample_Stddev_Order_By>;
    stddev_pop?: Maybe<Project_Sample_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Project_Sample_Stddev_Samp_Order_By>;
    sum?: Maybe<Project_Sample_Sum_Order_By>;
    var_pop?: Maybe<Project_Sample_Var_Pop_Order_By>;
    var_samp?: Maybe<Project_Sample_Var_Samp_Order_By>;
    variance?: Maybe<Project_Sample_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_sample" */
export type Project_Sample_Arr_Rel_Insert_Input = {
    data: Array<Project_Sample_Insert_Input>;
    /** upsert condition */
    on_conflict?: Maybe<Project_Sample_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Sample_Avg_Fields = {
    __typename?: 'project_sample_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_sample" */
export type Project_Sample_Avg_Order_By = {
    id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_sample". All fields are combined with a logical 'AND'. */
export type Project_Sample_Bool_Exp = {
    _and?: Maybe<Array<Project_Sample_Bool_Exp>>;
    _not?: Maybe<Project_Sample_Bool_Exp>;
    _or?: Maybe<Array<Project_Sample_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    project?: Maybe<Project_Bool_Exp>;
    projectId?: Maybe<String_Comparison_Exp>;
    sampleBcdId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_sample" */
export enum Project_Sample_Constraint {
    /** unique or primary key constraint on columns "id" */
    ProjectSamplePkey = 'project_sample_pkey',
}

/** input type for incrementing numeric columns in table "project_sample" */
export type Project_Sample_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_sample" */
export type Project_Sample_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    project?: Maybe<Project_Obj_Rel_Insert_Input>;
    projectId?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Sample_Max_Fields = {
    __typename?: 'project_sample_max_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_sample" */
export type Project_Sample_Max_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    sampleBcdId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Sample_Min_Fields = {
    __typename?: 'project_sample_min_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_sample" */
export type Project_Sample_Min_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    sampleBcdId?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_sample" */
export type Project_Sample_Mutation_Response = {
    __typename?: 'project_sample_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Project_Sample>;
};

/** on_conflict condition type for table "project_sample" */
export type Project_Sample_On_Conflict = {
    constraint: Project_Sample_Constraint;
    update_columns?: Array<Project_Sample_Update_Column>;
    where?: Maybe<Project_Sample_Bool_Exp>;
};

/** Ordering options when selecting data from "project_sample". */
export type Project_Sample_Order_By = {
    id?: Maybe<Order_By>;
    project?: Maybe<Project_Order_By>;
    projectId?: Maybe<Order_By>;
    sampleBcdId?: Maybe<Order_By>;
};

/** primary key columns input for table: project_sample */
export type Project_Sample_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "project_sample" */
export enum Project_Sample_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    SampleBcdId = 'sampleBcdId',
}

/** input type for updating data in table "project_sample" */
export type Project_Sample_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Sample_Stddev_Fields = {
    __typename?: 'project_sample_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_sample" */
export type Project_Sample_Stddev_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Sample_Stddev_Pop_Fields = {
    __typename?: 'project_sample_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_sample" */
export type Project_Sample_Stddev_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Sample_Stddev_Samp_Fields = {
    __typename?: 'project_sample_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_sample" */
export type Project_Sample_Stddev_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_sample" */
export type Project_Sample_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Project_Sample_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Sample_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Sample_Sum_Fields = {
    __typename?: 'project_sample_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_sample" */
export type Project_Sample_Sum_Order_By = {
    id?: Maybe<Order_By>;
};

/** update columns of table "project_sample" */
export enum Project_Sample_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    SampleBcdId = 'sampleBcdId',
}

export type Project_Sample_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Project_Sample_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Project_Sample_Set_Input>;
    /** filter the rows which have to be updated */
    where: Project_Sample_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Sample_Var_Pop_Fields = {
    __typename?: 'project_sample_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_sample" */
export type Project_Sample_Var_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Sample_Var_Samp_Fields = {
    __typename?: 'project_sample_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_sample" */
export type Project_Sample_Var_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Sample_Variance_Fields = {
    __typename?: 'project_sample_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_sample" */
export type Project_Sample_Variance_Order_By = {
    id?: Maybe<Order_By>;
};

/** select columns of table "project" */
export enum Project_Select_Column {
    /** column name */
    Client = 'client',
    /** column name */
    CompletionDate = 'completionDate',
    /** column name */
    FinalReportUrl = 'finalReportUrl',
    /** column name */
    Notes = 'notes',
    /** column name */
    Objective = 'objective',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    ProjectName = 'projectName',
    /** column name */
    StartDate = 'startDate',
}

/** input type for updating data in table "project" */
export type Project_Set_Input = {
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "project" */
export type Project_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Project_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
    client?: Maybe<Scalars['String']>;
    completionDate?: Maybe<Scalars['String']>;
    finalReportUrl?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    projectName?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
};

/** columns and relationships of "project_study" */
export type Project_Study = {
    __typename?: 'project_study';
    id: Scalars['Int'];
    /** An object relationship */
    project: Project;
    projectId: Scalars['String'];
    /** An object relationship */
    study: Study;
    studyId: Scalars['String'];
};

/** aggregated selection of "project_study" */
export type Project_Study_Aggregate = {
    __typename?: 'project_study_aggregate';
    aggregate?: Maybe<Project_Study_Aggregate_Fields>;
    nodes: Array<Project_Study>;
};

export type Project_Study_Aggregate_Bool_Exp = {
    count?: Maybe<Project_Study_Aggregate_Bool_Exp_Count>;
};

export type Project_Study_Aggregate_Bool_Exp_Count = {
    arguments?: Maybe<Array<Project_Study_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
    filter?: Maybe<Project_Study_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_study" */
export type Project_Study_Aggregate_Fields = {
    __typename?: 'project_study_aggregate_fields';
    avg?: Maybe<Project_Study_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Project_Study_Max_Fields>;
    min?: Maybe<Project_Study_Min_Fields>;
    stddev?: Maybe<Project_Study_Stddev_Fields>;
    stddev_pop?: Maybe<Project_Study_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Project_Study_Stddev_Samp_Fields>;
    sum?: Maybe<Project_Study_Sum_Fields>;
    var_pop?: Maybe<Project_Study_Var_Pop_Fields>;
    var_samp?: Maybe<Project_Study_Var_Samp_Fields>;
    variance?: Maybe<Project_Study_Variance_Fields>;
};

/** aggregate fields of "project_study" */
export type Project_Study_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Project_Study_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_study" */
export type Project_Study_Aggregate_Order_By = {
    avg?: Maybe<Project_Study_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Project_Study_Max_Order_By>;
    min?: Maybe<Project_Study_Min_Order_By>;
    stddev?: Maybe<Project_Study_Stddev_Order_By>;
    stddev_pop?: Maybe<Project_Study_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Project_Study_Stddev_Samp_Order_By>;
    sum?: Maybe<Project_Study_Sum_Order_By>;
    var_pop?: Maybe<Project_Study_Var_Pop_Order_By>;
    var_samp?: Maybe<Project_Study_Var_Samp_Order_By>;
    variance?: Maybe<Project_Study_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_study" */
export type Project_Study_Arr_Rel_Insert_Input = {
    data: Array<Project_Study_Insert_Input>;
    /** upsert condition */
    on_conflict?: Maybe<Project_Study_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Study_Avg_Fields = {
    __typename?: 'project_study_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_study" */
export type Project_Study_Avg_Order_By = {
    id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_study". All fields are combined with a logical 'AND'. */
export type Project_Study_Bool_Exp = {
    _and?: Maybe<Array<Project_Study_Bool_Exp>>;
    _not?: Maybe<Project_Study_Bool_Exp>;
    _or?: Maybe<Array<Project_Study_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    project?: Maybe<Project_Bool_Exp>;
    projectId?: Maybe<String_Comparison_Exp>;
    study?: Maybe<Study_Bool_Exp>;
    studyId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_study" */
export enum Project_Study_Constraint {
    /** unique or primary key constraint on columns "id" */
    ProjectStudyPkey = 'project_study_pkey',
}

/** input type for incrementing numeric columns in table "project_study" */
export type Project_Study_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_study" */
export type Project_Study_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    project?: Maybe<Project_Obj_Rel_Insert_Input>;
    projectId?: Maybe<Scalars['String']>;
    study?: Maybe<Study_Obj_Rel_Insert_Input>;
    studyId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Study_Max_Fields = {
    __typename?: 'project_study_max_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_study" */
export type Project_Study_Max_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    studyId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Study_Min_Fields = {
    __typename?: 'project_study_min_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_study" */
export type Project_Study_Min_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    studyId?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_study" */
export type Project_Study_Mutation_Response = {
    __typename?: 'project_study_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Project_Study>;
};

/** on_conflict condition type for table "project_study" */
export type Project_Study_On_Conflict = {
    constraint: Project_Study_Constraint;
    update_columns?: Array<Project_Study_Update_Column>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

/** Ordering options when selecting data from "project_study". */
export type Project_Study_Order_By = {
    id?: Maybe<Order_By>;
    project?: Maybe<Project_Order_By>;
    projectId?: Maybe<Order_By>;
    study?: Maybe<Study_Order_By>;
    studyId?: Maybe<Order_By>;
};

/** primary key columns input for table: project_study */
export type Project_Study_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "project_study" */
export enum Project_Study_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    StudyId = 'studyId',
}

/** input type for updating data in table "project_study" */
export type Project_Study_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Study_Stddev_Fields = {
    __typename?: 'project_study_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_study" */
export type Project_Study_Stddev_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Study_Stddev_Pop_Fields = {
    __typename?: 'project_study_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_study" */
export type Project_Study_Stddev_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Study_Stddev_Samp_Fields = {
    __typename?: 'project_study_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_study" */
export type Project_Study_Stddev_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_study" */
export type Project_Study_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Project_Study_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Study_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Study_Sum_Fields = {
    __typename?: 'project_study_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_study" */
export type Project_Study_Sum_Order_By = {
    id?: Maybe<Order_By>;
};

/** update columns of table "project_study" */
export enum Project_Study_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    StudyId = 'studyId',
}

export type Project_Study_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Project_Study_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Project_Study_Set_Input>;
    /** filter the rows which have to be updated */
    where: Project_Study_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Study_Var_Pop_Fields = {
    __typename?: 'project_study_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_study" */
export type Project_Study_Var_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Study_Var_Samp_Fields = {
    __typename?: 'project_study_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_study" */
export type Project_Study_Var_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Study_Variance_Fields = {
    __typename?: 'project_study_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_study" */
export type Project_Study_Variance_Order_By = {
    id?: Maybe<Order_By>;
};

/** update columns of table "project" */
export enum Project_Update_Column {
    /** column name */
    Client = 'client',
    /** column name */
    CompletionDate = 'completionDate',
    /** column name */
    FinalReportUrl = 'finalReportUrl',
    /** column name */
    Notes = 'notes',
    /** column name */
    Objective = 'objective',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    ProjectName = 'projectName',
    /** column name */
    StartDate = 'startDate',
}

export type Project_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Project_Set_Input>;
    /** filter the rows which have to be updated */
    where: Project_Bool_Exp;
};

/** columns and relationships of "project_url" */
export type Project_Url = {
    __typename?: 'project_url';
    id: Scalars['Int'];
    label: Scalars['String'];
    /** An object relationship */
    project: Project;
    projectId: Scalars['String'];
    url: Scalars['String'];
};

/** aggregated selection of "project_url" */
export type Project_Url_Aggregate = {
    __typename?: 'project_url_aggregate';
    aggregate?: Maybe<Project_Url_Aggregate_Fields>;
    nodes: Array<Project_Url>;
};

export type Project_Url_Aggregate_Bool_Exp = {
    count?: Maybe<Project_Url_Aggregate_Bool_Exp_Count>;
};

export type Project_Url_Aggregate_Bool_Exp_Count = {
    arguments?: Maybe<Array<Project_Url_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
    filter?: Maybe<Project_Url_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_url" */
export type Project_Url_Aggregate_Fields = {
    __typename?: 'project_url_aggregate_fields';
    avg?: Maybe<Project_Url_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Project_Url_Max_Fields>;
    min?: Maybe<Project_Url_Min_Fields>;
    stddev?: Maybe<Project_Url_Stddev_Fields>;
    stddev_pop?: Maybe<Project_Url_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Project_Url_Stddev_Samp_Fields>;
    sum?: Maybe<Project_Url_Sum_Fields>;
    var_pop?: Maybe<Project_Url_Var_Pop_Fields>;
    var_samp?: Maybe<Project_Url_Var_Samp_Fields>;
    variance?: Maybe<Project_Url_Variance_Fields>;
};

/** aggregate fields of "project_url" */
export type Project_Url_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Project_Url_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_url" */
export type Project_Url_Aggregate_Order_By = {
    avg?: Maybe<Project_Url_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Project_Url_Max_Order_By>;
    min?: Maybe<Project_Url_Min_Order_By>;
    stddev?: Maybe<Project_Url_Stddev_Order_By>;
    stddev_pop?: Maybe<Project_Url_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Project_Url_Stddev_Samp_Order_By>;
    sum?: Maybe<Project_Url_Sum_Order_By>;
    var_pop?: Maybe<Project_Url_Var_Pop_Order_By>;
    var_samp?: Maybe<Project_Url_Var_Samp_Order_By>;
    variance?: Maybe<Project_Url_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_url" */
export type Project_Url_Arr_Rel_Insert_Input = {
    data: Array<Project_Url_Insert_Input>;
    /** upsert condition */
    on_conflict?: Maybe<Project_Url_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Url_Avg_Fields = {
    __typename?: 'project_url_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_url" */
export type Project_Url_Avg_Order_By = {
    id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_url". All fields are combined with a logical 'AND'. */
export type Project_Url_Bool_Exp = {
    _and?: Maybe<Array<Project_Url_Bool_Exp>>;
    _not?: Maybe<Project_Url_Bool_Exp>;
    _or?: Maybe<Array<Project_Url_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    label?: Maybe<String_Comparison_Exp>;
    project?: Maybe<Project_Bool_Exp>;
    projectId?: Maybe<String_Comparison_Exp>;
    url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_url" */
export enum Project_Url_Constraint {
    /** unique or primary key constraint on columns "id" */
    ProjectUrlPkey = 'project_url_pkey',
}

/** input type for incrementing numeric columns in table "project_url" */
export type Project_Url_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_url" */
export type Project_Url_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    project?: Maybe<Project_Obj_Rel_Insert_Input>;
    projectId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Url_Max_Fields = {
    __typename?: 'project_url_max_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_url" */
export type Project_Url_Max_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Url_Min_Fields = {
    __typename?: 'project_url_min_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_url" */
export type Project_Url_Min_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_url" */
export type Project_Url_Mutation_Response = {
    __typename?: 'project_url_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Project_Url>;
};

/** on_conflict condition type for table "project_url" */
export type Project_Url_On_Conflict = {
    constraint: Project_Url_Constraint;
    update_columns?: Array<Project_Url_Update_Column>;
    where?: Maybe<Project_Url_Bool_Exp>;
};

/** Ordering options when selecting data from "project_url". */
export type Project_Url_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    project?: Maybe<Project_Order_By>;
    projectId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** primary key columns input for table: project_url */
export type Project_Url_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "project_url" */
export enum Project_Url_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    Url = 'url',
}

/** input type for updating data in table "project_url" */
export type Project_Url_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Url_Stddev_Fields = {
    __typename?: 'project_url_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_url" */
export type Project_Url_Stddev_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Url_Stddev_Pop_Fields = {
    __typename?: 'project_url_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_url" */
export type Project_Url_Stddev_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Url_Stddev_Samp_Fields = {
    __typename?: 'project_url_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_url" */
export type Project_Url_Stddev_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_url" */
export type Project_Url_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Project_Url_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Url_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    projectId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Url_Sum_Fields = {
    __typename?: 'project_url_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_url" */
export type Project_Url_Sum_Order_By = {
    id?: Maybe<Order_By>;
};

/** update columns of table "project_url" */
export enum Project_Url_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    Url = 'url',
}

export type Project_Url_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Project_Url_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Project_Url_Set_Input>;
    /** filter the rows which have to be updated */
    where: Project_Url_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Url_Var_Pop_Fields = {
    __typename?: 'project_url_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_url" */
export type Project_Url_Var_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Url_Var_Samp_Fields = {
    __typename?: 'project_url_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_url" */
export type Project_Url_Var_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Url_Variance_Fields = {
    __typename?: 'project_url_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_url" */
export type Project_Url_Variance_Order_By = {
    id?: Maybe<Order_By>;
};

/** columns and relationships of "project_user" */
export type Project_User = {
    __typename?: 'project_user';
    id: Scalars['Int'];
    /** An object relationship */
    project: Project;
    projectId: Scalars['String'];
    /** An object relationship */
    role: Role;
    roleId: Scalars['Int'];
    /** An object relationship */
    user: User;
    userId: Scalars['String'];
};

/** aggregated selection of "project_user" */
export type Project_User_Aggregate = {
    __typename?: 'project_user_aggregate';
    aggregate?: Maybe<Project_User_Aggregate_Fields>;
    nodes: Array<Project_User>;
};

export type Project_User_Aggregate_Bool_Exp = {
    count?: Maybe<Project_User_Aggregate_Bool_Exp_Count>;
};

export type Project_User_Aggregate_Bool_Exp_Count = {
    arguments?: Maybe<Array<Project_User_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
    filter?: Maybe<Project_User_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_Fields = {
    __typename?: 'project_user_aggregate_fields';
    avg?: Maybe<Project_User_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Project_User_Max_Fields>;
    min?: Maybe<Project_User_Min_Fields>;
    stddev?: Maybe<Project_User_Stddev_Fields>;
    stddev_pop?: Maybe<Project_User_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Project_User_Stddev_Samp_Fields>;
    sum?: Maybe<Project_User_Sum_Fields>;
    var_pop?: Maybe<Project_User_Var_Pop_Fields>;
    var_samp?: Maybe<Project_User_Var_Samp_Fields>;
    variance?: Maybe<Project_User_Variance_Fields>;
};

/** aggregate fields of "project_user" */
export type Project_User_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Project_User_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_user" */
export type Project_User_Aggregate_Order_By = {
    avg?: Maybe<Project_User_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Project_User_Max_Order_By>;
    min?: Maybe<Project_User_Min_Order_By>;
    stddev?: Maybe<Project_User_Stddev_Order_By>;
    stddev_pop?: Maybe<Project_User_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Project_User_Stddev_Samp_Order_By>;
    sum?: Maybe<Project_User_Sum_Order_By>;
    var_pop?: Maybe<Project_User_Var_Pop_Order_By>;
    var_samp?: Maybe<Project_User_Var_Samp_Order_By>;
    variance?: Maybe<Project_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_user" */
export type Project_User_Arr_Rel_Insert_Input = {
    data: Array<Project_User_Insert_Input>;
    /** upsert condition */
    on_conflict?: Maybe<Project_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_User_Avg_Fields = {
    __typename?: 'project_user_avg_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_user" */
export type Project_User_Avg_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_user". All fields are combined with a logical 'AND'. */
export type Project_User_Bool_Exp = {
    _and?: Maybe<Array<Project_User_Bool_Exp>>;
    _not?: Maybe<Project_User_Bool_Exp>;
    _or?: Maybe<Array<Project_User_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    project?: Maybe<Project_Bool_Exp>;
    projectId?: Maybe<String_Comparison_Exp>;
    role?: Maybe<Role_Bool_Exp>;
    roleId?: Maybe<Int_Comparison_Exp>;
    user?: Maybe<User_Bool_Exp>;
    userId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_user" */
export enum Project_User_Constraint {
    /** unique or primary key constraint on columns "id" */
    ProjectUserPkey = 'project_user_pkey',
}

/** input type for incrementing numeric columns in table "project_user" */
export type Project_User_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
    roleId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_user" */
export type Project_User_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    project?: Maybe<Project_Obj_Rel_Insert_Input>;
    projectId?: Maybe<Scalars['String']>;
    role?: Maybe<Role_Obj_Rel_Insert_Input>;
    roleId?: Maybe<Scalars['Int']>;
    user?: Maybe<User_Obj_Rel_Insert_Input>;
    userId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_User_Max_Fields = {
    __typename?: 'project_user_max_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    roleId?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_user" */
export type Project_User_Max_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
    userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_User_Min_Fields = {
    __typename?: 'project_user_min_fields';
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    roleId?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_user" */
export type Project_User_Min_Order_By = {
    id?: Maybe<Order_By>;
    projectId?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
    userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_user" */
export type Project_User_Mutation_Response = {
    __typename?: 'project_user_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Project_User>;
};

/** on_conflict condition type for table "project_user" */
export type Project_User_On_Conflict = {
    constraint: Project_User_Constraint;
    update_columns?: Array<Project_User_Update_Column>;
    where?: Maybe<Project_User_Bool_Exp>;
};

/** Ordering options when selecting data from "project_user". */
export type Project_User_Order_By = {
    id?: Maybe<Order_By>;
    project?: Maybe<Project_Order_By>;
    projectId?: Maybe<Order_By>;
    role?: Maybe<Role_Order_By>;
    roleId?: Maybe<Order_By>;
    user?: Maybe<User_Order_By>;
    userId?: Maybe<Order_By>;
};

/** primary key columns input for table: project_user */
export type Project_User_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "project_user" */
export enum Project_User_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    RoleId = 'roleId',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "project_user" */
export type Project_User_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    roleId?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_User_Stddev_Fields = {
    __typename?: 'project_user_stddev_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_user" */
export type Project_User_Stddev_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_User_Stddev_Pop_Fields = {
    __typename?: 'project_user_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_user" */
export type Project_User_Stddev_Pop_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_User_Stddev_Samp_Fields = {
    __typename?: 'project_user_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_user" */
export type Project_User_Stddev_Samp_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** Streaming cursor of the table "project_user" */
export type Project_User_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Project_User_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_User_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    projectId?: Maybe<Scalars['String']>;
    roleId?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_User_Sum_Fields = {
    __typename?: 'project_user_sum_fields';
    id?: Maybe<Scalars['Int']>;
    roleId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_user" */
export type Project_User_Sum_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** update columns of table "project_user" */
export enum Project_User_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    ProjectId = 'projectId',
    /** column name */
    RoleId = 'roleId',
    /** column name */
    UserId = 'userId',
}

export type Project_User_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Project_User_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Project_User_Set_Input>;
    /** filter the rows which have to be updated */
    where: Project_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_User_Var_Pop_Fields = {
    __typename?: 'project_user_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_user" */
export type Project_User_Var_Pop_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_User_Var_Samp_Fields = {
    __typename?: 'project_user_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_user" */
export type Project_User_Var_Samp_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_User_Variance_Fields = {
    __typename?: 'project_user_variance_fields';
    id?: Maybe<Scalars['Float']>;
    roleId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_user" */
export type Project_User_Variance_Order_By = {
    id?: Maybe<Order_By>;
    roleId?: Maybe<Order_By>;
};

/** columns and relationships of "role" */
export type Role = {
    __typename?: 'role';
    id: Scalars['Int'];
    role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
    __typename?: 'role_aggregate';
    aggregate?: Maybe<Role_Aggregate_Fields>;
    nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
    __typename?: 'role_aggregate_fields';
    avg?: Maybe<Role_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Role_Max_Fields>;
    min?: Maybe<Role_Min_Fields>;
    stddev?: Maybe<Role_Stddev_Fields>;
    stddev_pop?: Maybe<Role_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Role_Stddev_Samp_Fields>;
    sum?: Maybe<Role_Sum_Fields>;
    var_pop?: Maybe<Role_Var_Pop_Fields>;
    var_samp?: Maybe<Role_Var_Samp_Fields>;
    variance?: Maybe<Role_Variance_Fields>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Role_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Role_Avg_Fields = {
    __typename?: 'role_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
    _and?: Maybe<Array<Role_Bool_Exp>>;
    _not?: Maybe<Role_Bool_Exp>;
    _or?: Maybe<Array<Role_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
    /** unique or primary key constraint on columns "id" */
    RolePkey = 'role_pkey',
}

/** input type for incrementing numeric columns in table "role" */
export type Role_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
    __typename?: 'role_max_fields';
    id?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
    __typename?: 'role_min_fields';
    id?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
    __typename?: 'role_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
    data: Role_Insert_Input;
    /** upsert condition */
    on_conflict?: Maybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
    constraint: Role_Constraint;
    update_columns?: Array<Role_Update_Column>;
    where?: Maybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
    id?: Maybe<Order_By>;
    role?: Maybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Role = 'role',
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Role_Stddev_Fields = {
    __typename?: 'role_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Role_Stddev_Pop_Fields = {
    __typename?: 'role_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Role_Stddev_Samp_Fields = {
    __typename?: 'role_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Role_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    role?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Role_Sum_Fields = {
    __typename?: 'role_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Role = 'role',
}

export type Role_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Role_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Role_Set_Input>;
    /** filter the rows which have to be updated */
    where: Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Var_Pop_Fields = {
    __typename?: 'role_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Role_Var_Samp_Fields = {
    __typename?: 'role_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Role_Variance_Fields = {
    __typename?: 'role_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sample_url" */
export type Sample_Url = {
    __typename?: 'sample_url';
    id: Scalars['Int'];
    label: Scalars['String'];
    sampleBcdId: Scalars['String'];
    sampleNode: Array<Sample>;
    url: Scalars['String'];
};

/** columns and relationships of "sample_url" */
export type Sample_UrlSampleNodeArgs = {
    options?: Maybe<SampleOptions>;
    where?: Maybe<SampleWhere_Remote_Rel_Sample_UrlsampleNode>;
};

/** aggregated selection of "sample_url" */
export type Sample_Url_Aggregate = {
    __typename?: 'sample_url_aggregate';
    aggregate?: Maybe<Sample_Url_Aggregate_Fields>;
    nodes: Array<Sample_Url>;
};

/** aggregate fields of "sample_url" */
export type Sample_Url_Aggregate_Fields = {
    __typename?: 'sample_url_aggregate_fields';
    avg?: Maybe<Sample_Url_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Sample_Url_Max_Fields>;
    min?: Maybe<Sample_Url_Min_Fields>;
    stddev?: Maybe<Sample_Url_Stddev_Fields>;
    stddev_pop?: Maybe<Sample_Url_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Sample_Url_Stddev_Samp_Fields>;
    sum?: Maybe<Sample_Url_Sum_Fields>;
    var_pop?: Maybe<Sample_Url_Var_Pop_Fields>;
    var_samp?: Maybe<Sample_Url_Var_Samp_Fields>;
    variance?: Maybe<Sample_Url_Variance_Fields>;
};

/** aggregate fields of "sample_url" */
export type Sample_Url_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Sample_Url_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sample_Url_Avg_Fields = {
    __typename?: 'sample_url_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sample_url". All fields are combined with a logical 'AND'. */
export type Sample_Url_Bool_Exp = {
    _and?: Maybe<Array<Sample_Url_Bool_Exp>>;
    _not?: Maybe<Sample_Url_Bool_Exp>;
    _or?: Maybe<Array<Sample_Url_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    label?: Maybe<String_Comparison_Exp>;
    sampleBcdId?: Maybe<String_Comparison_Exp>;
    url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sample_url" */
export enum Sample_Url_Constraint {
    /** unique or primary key constraint on columns "id" */
    SampleUrlPkey = 'sample_url_pkey',
}

/** input type for incrementing numeric columns in table "sample_url" */
export type Sample_Url_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sample_url" */
export type Sample_Url_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sample_Url_Max_Fields = {
    __typename?: 'sample_url_max_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sample_Url_Min_Fields = {
    __typename?: 'sample_url_min_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sample_url" */
export type Sample_Url_Mutation_Response = {
    __typename?: 'sample_url_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Sample_Url>;
};

/** on_conflict condition type for table "sample_url" */
export type Sample_Url_On_Conflict = {
    constraint: Sample_Url_Constraint;
    update_columns?: Array<Sample_Url_Update_Column>;
    where?: Maybe<Sample_Url_Bool_Exp>;
};

/** Ordering options when selecting data from "sample_url". */
export type Sample_Url_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    sampleBcdId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** primary key columns input for table: sample_url */
export type Sample_Url_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "sample_url" */
export enum Sample_Url_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    SampleBcdId = 'sampleBcdId',
    /** column name */
    Url = 'url',
}

/** input type for updating data in table "sample_url" */
export type Sample_Url_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Sample_Url_Stddev_Fields = {
    __typename?: 'sample_url_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sample_Url_Stddev_Pop_Fields = {
    __typename?: 'sample_url_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sample_Url_Stddev_Samp_Fields = {
    __typename?: 'sample_url_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sample_url" */
export type Sample_Url_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Sample_Url_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sample_Url_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    sampleBcdId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sample_Url_Sum_Fields = {
    __typename?: 'sample_url_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** update columns of table "sample_url" */
export enum Sample_Url_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    SampleBcdId = 'sampleBcdId',
    /** column name */
    Url = 'url',
}

export type Sample_Url_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Sample_Url_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Sample_Url_Set_Input>;
    /** filter the rows which have to be updated */
    where: Sample_Url_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sample_Url_Var_Pop_Fields = {
    __typename?: 'sample_url_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sample_Url_Var_Samp_Fields = {
    __typename?: 'sample_url_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sample_Url_Variance_Fields = {
    __typename?: 'sample_url_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "study" */
export type Study = {
    __typename?: 'study';
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    /** An array relationship */
    relatedProjects: Array<Project_Study>;
    /** An aggregate relationship */
    relatedProjects_aggregate: Project_Study_Aggregate;
    startDate?: Maybe<Scalars['String']>;
    studyId: Scalars['String'];
    studyName?: Maybe<Scalars['String']>;
    studyNodes: Array<StudyNode>;
    /** An array relationship */
    study_urls: Array<Study_Url>;
    /** An aggregate relationship */
    study_urls_aggregate: Study_Url_Aggregate;
    trays: Array<ProcessMetadataValue>;
};

/** columns and relationships of "study" */
export type StudyRelatedProjectsArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyRelatedProjects_AggregateArgs = {
    distinct_on?: Maybe<Array<Project_Study_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Project_Study_Order_By>>;
    where?: Maybe<Project_Study_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudyNodesArgs = {
    options?: Maybe<StudyNodeOptions>;
    where?: Maybe<StudyNodeWhere_Remote_Rel_StudystudyNodes>;
};

/** columns and relationships of "study" */
export type StudyStudy_UrlsArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyStudy_Urls_AggregateArgs = {
    distinct_on?: Maybe<Array<Study_Url_Select_Column>>;
    limit?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    order_by?: Maybe<Array<Study_Url_Order_By>>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

/** columns and relationships of "study" */
export type StudyTraysArgs = {
    options?: Maybe<ProcessMetadataValueOptions>;
    where?: Maybe<ProcessMetadataValueWhere_Remote_Rel_Studytrays>;
};

/** aggregated selection of "study" */
export type Study_Aggregate = {
    __typename?: 'study_aggregate';
    aggregate?: Maybe<Study_Aggregate_Fields>;
    nodes: Array<Study>;
};

/** aggregate fields of "study" */
export type Study_Aggregate_Fields = {
    __typename?: 'study_aggregate_fields';
    count: Scalars['Int'];
    max?: Maybe<Study_Max_Fields>;
    min?: Maybe<Study_Min_Fields>;
};

/** aggregate fields of "study" */
export type Study_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Study_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "study". All fields are combined with a logical 'AND'. */
export type Study_Bool_Exp = {
    _and?: Maybe<Array<Study_Bool_Exp>>;
    _not?: Maybe<Study_Bool_Exp>;
    _or?: Maybe<Array<Study_Bool_Exp>>;
    endDate?: Maybe<String_Comparison_Exp>;
    notes?: Maybe<String_Comparison_Exp>;
    objective?: Maybe<String_Comparison_Exp>;
    relatedProjects?: Maybe<Project_Study_Bool_Exp>;
    relatedProjects_aggregate?: Maybe<Project_Study_Aggregate_Bool_Exp>;
    startDate?: Maybe<String_Comparison_Exp>;
    studyId?: Maybe<String_Comparison_Exp>;
    studyName?: Maybe<String_Comparison_Exp>;
    study_urls?: Maybe<Study_Url_Bool_Exp>;
    study_urls_aggregate?: Maybe<Study_Url_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "study" */
export enum Study_Constraint {
    /** unique or primary key constraint on columns "studyId" */
    StudyPkey = 'study_pkey',
}

/** input type for inserting data into table "study" */
export type Study_Insert_Input = {
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    relatedProjects?: Maybe<Project_Study_Arr_Rel_Insert_Input>;
    startDate?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    studyName?: Maybe<Scalars['String']>;
    study_urls?: Maybe<Study_Url_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Study_Max_Fields = {
    __typename?: 'study_max_fields';
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    studyName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Study_Min_Fields = {
    __typename?: 'study_min_fields';
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    studyName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "study" */
export type Study_Mutation_Response = {
    __typename?: 'study_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Study>;
};

/** input type for inserting object relation for remote table "study" */
export type Study_Obj_Rel_Insert_Input = {
    data: Study_Insert_Input;
    /** upsert condition */
    on_conflict?: Maybe<Study_On_Conflict>;
};

/** on_conflict condition type for table "study" */
export type Study_On_Conflict = {
    constraint: Study_Constraint;
    update_columns?: Array<Study_Update_Column>;
    where?: Maybe<Study_Bool_Exp>;
};

/** Ordering options when selecting data from "study". */
export type Study_Order_By = {
    endDate?: Maybe<Order_By>;
    notes?: Maybe<Order_By>;
    objective?: Maybe<Order_By>;
    relatedProjects_aggregate?: Maybe<Project_Study_Aggregate_Order_By>;
    startDate?: Maybe<Order_By>;
    studyId?: Maybe<Order_By>;
    studyName?: Maybe<Order_By>;
    study_urls_aggregate?: Maybe<Study_Url_Aggregate_Order_By>;
};

/** primary key columns input for table: study */
export type Study_Pk_Columns_Input = {
    studyId: Scalars['String'];
};

/** select columns of table "study" */
export enum Study_Select_Column {
    /** column name */
    EndDate = 'endDate',
    /** column name */
    Notes = 'notes',
    /** column name */
    Objective = 'objective',
    /** column name */
    StartDate = 'startDate',
    /** column name */
    StudyId = 'studyId',
    /** column name */
    StudyName = 'studyName',
}

/** input type for updating data in table "study" */
export type Study_Set_Input = {
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    studyName?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "study" */
export type Study_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Study_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Study_Stream_Cursor_Value_Input = {
    endDate?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
    objective?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    studyName?: Maybe<Scalars['String']>;
};

/** update columns of table "study" */
export enum Study_Update_Column {
    /** column name */
    EndDate = 'endDate',
    /** column name */
    Notes = 'notes',
    /** column name */
    Objective = 'objective',
    /** column name */
    StartDate = 'startDate',
    /** column name */
    StudyId = 'studyId',
    /** column name */
    StudyName = 'studyName',
}

export type Study_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Study_Set_Input>;
    /** filter the rows which have to be updated */
    where: Study_Bool_Exp;
};

/** columns and relationships of "study_url" */
export type Study_Url = {
    __typename?: 'study_url';
    id: Scalars['Int'];
    label: Scalars['String'];
    /** An object relationship */
    study: Study;
    studyId: Scalars['String'];
    url: Scalars['String'];
};

/** aggregated selection of "study_url" */
export type Study_Url_Aggregate = {
    __typename?: 'study_url_aggregate';
    aggregate?: Maybe<Study_Url_Aggregate_Fields>;
    nodes: Array<Study_Url>;
};

export type Study_Url_Aggregate_Bool_Exp = {
    count?: Maybe<Study_Url_Aggregate_Bool_Exp_Count>;
};

export type Study_Url_Aggregate_Bool_Exp_Count = {
    arguments?: Maybe<Array<Study_Url_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
    filter?: Maybe<Study_Url_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "study_url" */
export type Study_Url_Aggregate_Fields = {
    __typename?: 'study_url_aggregate_fields';
    avg?: Maybe<Study_Url_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<Study_Url_Max_Fields>;
    min?: Maybe<Study_Url_Min_Fields>;
    stddev?: Maybe<Study_Url_Stddev_Fields>;
    stddev_pop?: Maybe<Study_Url_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Study_Url_Stddev_Samp_Fields>;
    sum?: Maybe<Study_Url_Sum_Fields>;
    var_pop?: Maybe<Study_Url_Var_Pop_Fields>;
    var_samp?: Maybe<Study_Url_Var_Samp_Fields>;
    variance?: Maybe<Study_Url_Variance_Fields>;
};

/** aggregate fields of "study_url" */
export type Study_Url_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<Study_Url_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "study_url" */
export type Study_Url_Aggregate_Order_By = {
    avg?: Maybe<Study_Url_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Study_Url_Max_Order_By>;
    min?: Maybe<Study_Url_Min_Order_By>;
    stddev?: Maybe<Study_Url_Stddev_Order_By>;
    stddev_pop?: Maybe<Study_Url_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Study_Url_Stddev_Samp_Order_By>;
    sum?: Maybe<Study_Url_Sum_Order_By>;
    var_pop?: Maybe<Study_Url_Var_Pop_Order_By>;
    var_samp?: Maybe<Study_Url_Var_Samp_Order_By>;
    variance?: Maybe<Study_Url_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "study_url" */
export type Study_Url_Arr_Rel_Insert_Input = {
    data: Array<Study_Url_Insert_Input>;
    /** upsert condition */
    on_conflict?: Maybe<Study_Url_On_Conflict>;
};

/** aggregate avg on columns */
export type Study_Url_Avg_Fields = {
    __typename?: 'study_url_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "study_url" */
export type Study_Url_Avg_Order_By = {
    id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "study_url". All fields are combined with a logical 'AND'. */
export type Study_Url_Bool_Exp = {
    _and?: Maybe<Array<Study_Url_Bool_Exp>>;
    _not?: Maybe<Study_Url_Bool_Exp>;
    _or?: Maybe<Array<Study_Url_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    label?: Maybe<String_Comparison_Exp>;
    study?: Maybe<Study_Bool_Exp>;
    studyId?: Maybe<String_Comparison_Exp>;
    url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "study_url" */
export enum Study_Url_Constraint {
    /** unique or primary key constraint on columns "id" */
    StudyUrlPkey = 'study_url_pkey',
}

/** input type for incrementing numeric columns in table "study_url" */
export type Study_Url_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "study_url" */
export type Study_Url_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    study?: Maybe<Study_Obj_Rel_Insert_Input>;
    studyId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Study_Url_Max_Fields = {
    __typename?: 'study_url_max_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "study_url" */
export type Study_Url_Max_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    studyId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Study_Url_Min_Fields = {
    __typename?: 'study_url_min_fields';
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "study_url" */
export type Study_Url_Min_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    studyId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** response of any mutation on the table "study_url" */
export type Study_Url_Mutation_Response = {
    __typename?: 'study_url_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<Study_Url>;
};

/** on_conflict condition type for table "study_url" */
export type Study_Url_On_Conflict = {
    constraint: Study_Url_Constraint;
    update_columns?: Array<Study_Url_Update_Column>;
    where?: Maybe<Study_Url_Bool_Exp>;
};

/** Ordering options when selecting data from "study_url". */
export type Study_Url_Order_By = {
    id?: Maybe<Order_By>;
    label?: Maybe<Order_By>;
    study?: Maybe<Study_Order_By>;
    studyId?: Maybe<Order_By>;
    url?: Maybe<Order_By>;
};

/** primary key columns input for table: study_url */
export type Study_Url_Pk_Columns_Input = {
    id: Scalars['Int'];
};

/** select columns of table "study_url" */
export enum Study_Url_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    StudyId = 'studyId',
    /** column name */
    Url = 'url',
}

/** input type for updating data in table "study_url" */
export type Study_Url_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Study_Url_Stddev_Fields = {
    __typename?: 'study_url_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "study_url" */
export type Study_Url_Stddev_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Study_Url_Stddev_Pop_Fields = {
    __typename?: 'study_url_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "study_url" */
export type Study_Url_Stddev_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Study_Url_Stddev_Samp_Fields = {
    __typename?: 'study_url_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "study_url" */
export type Study_Url_Stddev_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "study_url" */
export type Study_Url_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Study_Url_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Study_Url_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Study_Url_Sum_Fields = {
    __typename?: 'study_url_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "study_url" */
export type Study_Url_Sum_Order_By = {
    id?: Maybe<Order_By>;
};

/** update columns of table "study_url" */
export enum Study_Url_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    Label = 'label',
    /** column name */
    StudyId = 'studyId',
    /** column name */
    Url = 'url',
}

export type Study_Url_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<Study_Url_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<Study_Url_Set_Input>;
    /** filter the rows which have to be updated */
    where: Study_Url_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Study_Url_Var_Pop_Fields = {
    __typename?: 'study_url_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "study_url" */
export type Study_Url_Var_Pop_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Study_Url_Var_Samp_Fields = {
    __typename?: 'study_url_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "study_url" */
export type Study_Url_Var_Samp_Order_By = {
    id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Study_Url_Variance_Fields = {
    __typename?: 'study_url_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "study_url" */
export type Study_Url_Variance_Order_By = {
    id?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
    _eq?: Maybe<Scalars['timestamptz']>;
    _gt?: Maybe<Scalars['timestamptz']>;
    _gte?: Maybe<Scalars['timestamptz']>;
    _in?: Maybe<Array<Scalars['timestamptz']>>;
    _is_null?: Maybe<Scalars['Boolean']>;
    _lt?: Maybe<Scalars['timestamptz']>;
    _lte?: Maybe<Scalars['timestamptz']>;
    _neq?: Maybe<Scalars['timestamptz']>;
    _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
    __typename?: 'user';
    id: Scalars['Int'];
    userId: Scalars['String'];
    userName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
    __typename?: 'user_aggregate';
    aggregate?: Maybe<User_Aggregate_Fields>;
    nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
    __typename?: 'user_aggregate_fields';
    avg?: Maybe<User_Avg_Fields>;
    count: Scalars['Int'];
    max?: Maybe<User_Max_Fields>;
    min?: Maybe<User_Min_Fields>;
    stddev?: Maybe<User_Stddev_Fields>;
    stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
    sum?: Maybe<User_Sum_Fields>;
    var_pop?: Maybe<User_Var_Pop_Fields>;
    var_samp?: Maybe<User_Var_Samp_Fields>;
    variance?: Maybe<User_Variance_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
    columns?: Maybe<Array<User_Select_Column>>;
    distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
    __typename?: 'user_avg_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
    _and?: Maybe<Array<User_Bool_Exp>>;
    _not?: Maybe<User_Bool_Exp>;
    _or?: Maybe<Array<User_Bool_Exp>>;
    id?: Maybe<Int_Comparison_Exp>;
    userId?: Maybe<String_Comparison_Exp>;
    userName?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
    /** unique or primary key constraint on columns "userId" */
    UserPkey = 'user_pkey',
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
    id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
    id?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
    __typename?: 'user_max_fields';
    id?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
    __typename?: 'user_min_fields';
    id?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
    __typename?: 'user_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int'];
    /** data from the rows affected by the mutation */
    returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
    data: User_Insert_Input;
    /** upsert condition */
    on_conflict?: Maybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
    constraint: User_Constraint;
    update_columns?: Array<User_Update_Column>;
    where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
    id?: Maybe<Order_By>;
    userId?: Maybe<Order_By>;
    userName?: Maybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
    userId: Scalars['String'];
};

/** select columns of table "user" */
export enum User_Select_Column {
    /** column name */
    Id = 'id',
    /** column name */
    UserId = 'userId',
    /** column name */
    UserName = 'userName',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
    id?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
    __typename?: 'user_stddev_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
    __typename?: 'user_stddev_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
    __typename?: 'user_stddev_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: User_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
    id?: Maybe<Scalars['Int']>;
    userId?: Maybe<Scalars['String']>;
    userName?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
    __typename?: 'user_sum_fields';
    id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
    /** column name */
    Id = 'id',
    /** column name */
    UserId = 'userId',
    /** column name */
    UserName = 'userName',
}

export type User_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: Maybe<User_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: Maybe<User_Set_Input>;
    /** filter the rows which have to be updated */
    where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
    __typename?: 'user_var_pop_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
    __typename?: 'user_var_samp_fields';
    id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
    __typename?: 'user_variance_fields';
    id?: Maybe<Scalars['Float']>;
};

export type PoolSourcesFullTextSearchQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    searchString?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
}>;

export type PoolSourcesFullTextSearchQuery = { __typename?: 'Query' } & {
    poolSourcesFullTextSearch?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'name' | 'dateReceived'
                > & {
                        sampleType?: Maybe<
                            { __typename?: 'SampleType' } & Pick<
                                SampleType,
                                'name'
                            >
                        >;
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SampleMetadata' } & Pick<
                                        SampleMetadata,
                                        'value' | 'name'
                                    >
                                >
                            >
                        >;
                        parentSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId'
                                    >
                                >
                            >
                        >;
                        childSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId'
                                    >
                                >
                            >
                        >;
                        sourceSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            metadata?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'SampleMetadata';
                                                        } & Pick<
                                                            SampleMetadata,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        outputByProcess?: Maybe<
                            { __typename?: 'Process' } & Pick<
                                Process,
                                'bcdId'
                            > & {
                                    processType?: Maybe<
                                        { __typename?: 'ProcessType' } & Pick<
                                            ProcessType,
                                            'name'
                                        >
                                    >;
                                }
                        >;
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type RelatedSamplesFullTextSearchQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    searchString?: Maybe<Scalars['String']>;
}>;

export type RelatedSamplesFullTextSearchQuery = { __typename?: 'Query' } & {
    relatedSamplesFullTextSearch?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'name' | 'dateReceived'
                > & {
                        sampleType?: Maybe<
                            { __typename?: 'SampleType' } & Pick<
                                SampleType,
                                'name'
                            >
                        >;
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SampleMetadata' } & Pick<
                                        SampleMetadata,
                                        'value' | 'name'
                                    >
                                >
                            >
                        >;
                        parentSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId'
                                    >
                                >
                            >
                        >;
                        childSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId'
                                    >
                                >
                            >
                        >;
                        outputByProcess?: Maybe<
                            { __typename?: 'Process' } & Pick<
                                Process,
                                'bcdId'
                            > & {
                                    processType?: Maybe<
                                        { __typename?: 'ProcessType' } & Pick<
                                            ProcessType,
                                            'name'
                                        >
                                    >;
                                }
                        >;
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate' | 'uuid'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            | 'name'
                                                            | 'value'
                                                            | 'stringValue'
                                                            | 'replicate'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        sourceSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            metadata?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'SampleMetadata';
                                                        } & Pick<
                                                            SampleMetadata,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                            sampleType?: Maybe<
                                                {
                                                    __typename?: 'SampleType';
                                                } & Pick<SampleType, 'name'>
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type ProjectFieldsFragment = { __typename?: 'project' } & Pick<
    Project,
    | 'projectId'
    | 'projectName'
    | 'client'
    | 'objective'
    | 'startDate'
    | 'completionDate'
    | 'notes'
    | 'finalReportUrl'
> & {
        project_urls: Array<
            { __typename?: 'project_url' } & Pick<
                Project_Url,
                'id' | 'label' | 'url'
            >
        >;
        assignedUsers: Array<
            { __typename?: 'project_user' } & Pick<Project_User, 'id'> & {
                    user: { __typename?: 'user' } & Pick<
                        User,
                        'userId' | 'userName'
                    >;
                    role: { __typename?: 'role' } & Pick<Role, 'id' | 'role'>;
                }
        >;
        relatedStudies: Array<
            { __typename?: 'project_study' } & {
                study: { __typename?: 'study' } & Pick<
                    Study,
                    | 'studyId'
                    | 'studyName'
                    | 'objective'
                    | 'startDate'
                    | 'endDate'
                >;
            }
        >;
        relatedSamples: Array<
            { __typename?: 'project_sample' } & Pick<
                Project_Sample,
                'id' | 'sampleBcdId'
            > & {
                    sampleNode: Array<
                        { __typename?: 'Sample' } & Pick<
                            Sample,
                            'bcdId' | 'dateReceived'
                        > & {
                                outputByProcess?: Maybe<
                                    { __typename?: 'Process' } & {
                                        processType?: Maybe<
                                            {
                                                __typename?: 'ProcessType';
                                            } & Pick<ProcessType, 'name'>
                                        >;
                                        metadata?: Maybe<
                                            Array<
                                                Maybe<
                                                    {
                                                        __typename?: 'ProcessMetadataValue';
                                                    } & Pick<
                                                        ProcessMetadataValue,
                                                        'value'
                                                    > & {
                                                            metadata?: Maybe<
                                                                {
                                                                    __typename?: 'Metadata';
                                                                } & Pick<
                                                                    Metadata,
                                                                    'name'
                                                                >
                                                            >;
                                                            metadataGroup?: Maybe<
                                                                {
                                                                    __typename?: 'MetadataGroup';
                                                                } & Pick<
                                                                    MetadataGroup,
                                                                    'name'
                                                                >
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                    }
                                >;
                                measurements?: Maybe<
                                    Array<
                                        Maybe<
                                            {
                                                __typename?: 'Measurement';
                                            } & Pick<
                                                Measurement,
                                                'observationDate' | 'uuid'
                                            > & {
                                                    measurementType?: Maybe<
                                                        {
                                                            __typename?: 'MeasurementType';
                                                        } & Pick<
                                                            MeasurementType,
                                                            'name'
                                                        >
                                                    >;
                                                    data?: Maybe<
                                                        Array<
                                                            Maybe<
                                                                {
                                                                    __typename?: 'VariableValues';
                                                                } & Pick<
                                                                    VariableValues,
                                                                    | 'name'
                                                                    | 'value'
                                                                    | 'stringValue'
                                                                    | 'replicate'
                                                                >
                                                            >
                                                        >
                                                    >;
                                                }
                                        >
                                    >
                                >;
                                sourceSamples?: Maybe<
                                    Array<
                                        Maybe<
                                            { __typename?: 'Sample' } & Pick<
                                                Sample,
                                                'bcdId' | 'name' | 'sourceId'
                                            > & {
                                                    metadata?: Maybe<
                                                        Array<
                                                            Maybe<
                                                                {
                                                                    __typename?: 'SampleMetadata';
                                                                } & Pick<
                                                                    SampleMetadata,
                                                                    | 'value'
                                                                    | 'name'
                                                                >
                                                            >
                                                        >
                                                    >;
                                                    sampleType?: Maybe<
                                                        {
                                                            __typename?: 'SampleType';
                                                        } & Pick<
                                                            SampleType,
                                                            'name'
                                                        >
                                                    >;
                                                }
                                        >
                                    >
                                >;
                            }
                    >;
                }
        >;
    };

export type ProjectListQueryVariables = Exact<{ [key: string]: never }>;

export type ProjectListQuery = { __typename?: 'Query' } & {
    project: Array<
        { __typename?: 'project' } & Pick<
            Project,
            'projectId' | 'projectName' | 'client' | 'objective'
        >
    >;
};

export type ProjectDetailQueryVariables = Exact<{
    projectId: Scalars['String'];
}>;

export type ProjectDetailQuery = { __typename?: 'Query' } & {
    project_by_pk?: Maybe<{ __typename?: 'project' } & ProjectFieldsFragment>;
};

export type UsersListQueryVariables = Exact<{ [key: string]: never }>;

export type UsersListQuery = { __typename?: 'Query' } & {
    user: Array<{ __typename?: 'user' } & Pick<User, 'userId' | 'userName'>>;
};

export type RolesListQueryVariables = Exact<{ [key: string]: never }>;

export type RolesListQuery = { __typename?: 'Query' } & {
    role: Array<{ __typename?: 'role' } & Pick<Role, 'id' | 'role'>>;
};

export type AddProjectUserAssignmentsMutationVariables = Exact<{
    user_assignments?: Maybe<
        Array<Project_User_Insert_Input> | Project_User_Insert_Input
    >;
}>;

export type AddProjectUserAssignmentsMutation = { __typename?: 'Mutation' } & {
    insert_project_user?: Maybe<
        { __typename?: 'project_user_mutation_response' } & {
            returning: Array<
                { __typename?: 'project_user' } & Pick<
                    Project_User,
                    'projectId'
                > & {
                        role: { __typename?: 'role' } & Pick<
                            Role,
                            'id' | 'role'
                        >;
                        user: { __typename?: 'user' } & Pick<
                            User,
                            'userName' | 'userId'
                        >;
                    }
            >;
        }
    >;
};

export type DeleteProjectUserAssignmentsMutationVariables = Exact<{
    ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteProjectUserAssignmentsMutation = {
    __typename?: 'Mutation';
} & {
    delete_project_user?: Maybe<
        { __typename?: 'project_user_mutation_response' } & {
            returning: Array<
                { __typename?: 'project_user' } & Pick<Project_User, 'id'>
            >;
        }
    >;
};

export type CreateProjectMutationVariables = Exact<{
    project: Project_Insert_Input;
}>;

export type CreateProjectMutation = { __typename?: 'Mutation' } & {
    insert_project_one?: Maybe<
        { __typename?: 'project' } & ProjectFieldsFragment
    >;
};

export type EditProjectMutationVariables = Exact<{
    projectId: Scalars['String'];
    project?: Maybe<Project_Set_Input>;
}>;

export type EditProjectMutation = { __typename?: 'Mutation' } & {
    update_project_by_pk?: Maybe<
        { __typename?: 'project' } & ProjectFieldsFragment
    >;
};

export type DeleteProjectMutationVariables = Exact<{
    projectId: Scalars['String'];
}>;

export type DeleteProjectMutation = { __typename?: 'Mutation' } & {
    delete_project_by_pk?: Maybe<
        { __typename?: 'project' } & Pick<Project, 'projectId'>
    >;
};

export type DeleteProjectUrlInfoMutationVariables = Exact<{
    ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteProjectUrlInfoMutation = { __typename?: 'Mutation' } & {
    delete_project_url?: Maybe<
        { __typename?: 'project_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'project_url' } & Pick<Project_Url, 'id'>
            >;
        }
    >;
};

export type UpsertProjectUrlInfoMutationVariables = Exact<{
    projectUrlInfo?: Maybe<
        Array<Project_Url_Insert_Input> | Project_Url_Insert_Input
    >;
}>;

export type UpsertProjectUrlInfoMutation = { __typename?: 'Mutation' } & {
    insert_project_url?: Maybe<
        { __typename?: 'project_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'project_url' } & Pick<
                    Project_Url,
                    'id' | 'projectId' | 'label' | 'url'
                >
            >;
        }
    >;
};

export type DeleteRelatedSampleFromProjectMutationVariables = Exact<{
    projectSampleId: Scalars['Int'];
}>;

export type DeleteRelatedSampleFromProjectMutation = {
    __typename?: 'Mutation';
} & {
    delete_project_sample_by_pk?: Maybe<
        { __typename?: 'project_sample' } & Pick<Project_Sample, 'projectId'>
    >;
};

export type AddRelatedSampleToProjectMutationVariables = Exact<{
    projectId: Scalars['String'];
    sampleBcdId: Scalars['String'];
}>;

export type AddRelatedSampleToProjectMutation = { __typename?: 'Mutation' } & {
    insert_project_sample_one?: Maybe<
        { __typename?: 'project_sample' } & Pick<Project_Sample, 'projectId'>
    >;
};

export type SampleDetailQueryVariables = Exact<{
    bcdId?: Maybe<Scalars['ID']>;
}>;

export type SampleDetailQuery = { __typename?: 'Query' } & {
    samples: Array<
        { __typename?: 'Sample' } & Pick<
            Sample,
            'bcdId' | 'name' | 'sourceId' | 'dateReceived'
        > & {
                sampleRows: Array<
                    { __typename?: 'sample_url' } & Pick<
                        Sample_Url,
                        'url' | 'label' | 'id'
                    >
                >;
                metadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'SampleMetadata' } & Pick<
                                SampleMetadata,
                                'value' | 'name' | 'uneditable'
                            >
                        >
                    >
                >;
                sampleType?: Maybe<
                    { __typename?: 'SampleType' } & Pick<SampleType, 'name'>
                >;
                measurements?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Measurement' } & Pick<
                                Measurement,
                                | 'observationDate'
                                | 'monoMethodType'
                                | 'sopVersion'
                                | 'uuid'
                            > & {
                                    measurementType?: Maybe<
                                        {
                                            __typename?: 'MeasurementType';
                                        } & Pick<MeasurementType, 'name'>
                                    >;
                                    data?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'VariableValues';
                                                } & Pick<
                                                    VariableValues,
                                                    | 'name'
                                                    | 'value'
                                                    | 'stringValue'
                                                    | 'replicate'
                                                >
                                            >
                                        >
                                    >;
                                }
                        >
                    >
                >;
                sourceSamples?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Sample' } & Pick<
                                Sample,
                                'bcdId' | 'name' | 'sourceId'
                            > & {
                                    metadata?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'SampleMetadata';
                                                } & Pick<
                                                    SampleMetadata,
                                                    'value' | 'name'
                                                >
                                            >
                                        >
                                    >;
                                    sampleType?: Maybe<
                                        { __typename?: 'SampleType' } & Pick<
                                            SampleType,
                                            'name'
                                        >
                                    >;
                                }
                        >
                    >
                >;
                parentSamples?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Sample' } & Pick<
                                Sample,
                                'bcdId' | 'name' | 'sourceId'
                            > & {
                                    sampleType?: Maybe<
                                        { __typename?: 'SampleType' } & Pick<
                                            SampleType,
                                            'name'
                                        >
                                    >;
                                    metadata?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'SampleMetadata';
                                                } & Pick<
                                                    SampleMetadata,
                                                    'value' | 'name'
                                                >
                                            >
                                        >
                                    >;
                                    childSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'>
                                            >
                                        >
                                    >;
                                    outputByProcess?: Maybe<
                                        { __typename?: 'Process' } & Pick<
                                            Process,
                                            'bcdId'
                                        > & {
                                                processType?: Maybe<
                                                    {
                                                        __typename?: 'ProcessType';
                                                    } & Pick<
                                                        ProcessType,
                                                        'name'
                                                    >
                                                >;
                                                metadata?: Maybe<
                                                    Array<
                                                        Maybe<
                                                            {
                                                                __typename?: 'ProcessMetadataValue';
                                                            } & Pick<
                                                                ProcessMetadataValue,
                                                                'value'
                                                            > & {
                                                                    metadata?: Maybe<
                                                                        {
                                                                            __typename?: 'Metadata';
                                                                        } & Pick<
                                                                            Metadata,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadataGroup?: Maybe<
                                                                        {
                                                                            __typename?: 'MetadataGroup';
                                                                        } & Pick<
                                                                            MetadataGroup,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                }
                                                        >
                                                    >
                                                >;
                                            }
                                    >;
                                }
                        >
                    >
                >;
                childSamples?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Sample' } & Pick<
                                Sample,
                                'bcdId' | 'name' | 'sourceId'
                            > & {
                                    sampleType?: Maybe<
                                        { __typename?: 'SampleType' } & Pick<
                                            SampleType,
                                            'name'
                                        >
                                    >;
                                    metadata?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'SampleMetadata';
                                                } & Pick<
                                                    SampleMetadata,
                                                    'value' | 'name'
                                                >
                                            >
                                        >
                                    >;
                                    parentSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'>
                                            >
                                        >
                                    >;
                                    outputByProcess?: Maybe<
                                        { __typename?: 'Process' } & Pick<
                                            Process,
                                            'bcdId'
                                        > & {
                                                processType?: Maybe<
                                                    {
                                                        __typename?: 'ProcessType';
                                                    } & Pick<
                                                        ProcessType,
                                                        'name'
                                                    >
                                                >;
                                                metadata?: Maybe<
                                                    Array<
                                                        Maybe<
                                                            {
                                                                __typename?: 'ProcessMetadataValue';
                                                            } & Pick<
                                                                ProcessMetadataValue,
                                                                'value'
                                                            > & {
                                                                    metadata?: Maybe<
                                                                        {
                                                                            __typename?: 'Metadata';
                                                                        } & Pick<
                                                                            Metadata,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadataGroup?: Maybe<
                                                                        {
                                                                            __typename?: 'MetadataGroup';
                                                                        } & Pick<
                                                                            MetadataGroup,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                }
                                                        >
                                                    >
                                                >;
                                            }
                                    >;
                                }
                        >
                    >
                >;
                ancestorSamples?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Sample' } & Pick<
                                Sample,
                                'sourceId' | 'bcdId' | 'name'
                            > & {
                                    childSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'> & {
                                                        outputByProcess?: Maybe<
                                                            {
                                                                __typename?: 'Process';
                                                            } & Pick<
                                                                Process,
                                                                'bcdId'
                                                            > & {
                                                                    processType?: Maybe<
                                                                        {
                                                                            __typename?: 'ProcessType';
                                                                        } & Pick<
                                                                            ProcessType,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadata?: Maybe<
                                                                        Array<
                                                                            Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessMetadataValue';
                                                                                } & Pick<
                                                                                    ProcessMetadataValue,
                                                                                    'value'
                                                                                > & {
                                                                                        metadata?: Maybe<
                                                                                            {
                                                                                                __typename?: 'Metadata';
                                                                                            } & Pick<
                                                                                                Metadata,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                        metadataGroup?: Maybe<
                                                                                            {
                                                                                                __typename?: 'MetadataGroup';
                                                                                            } & Pick<
                                                                                                MetadataGroup,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                    }
                                                                            >
                                                                        >
                                                                    >;
                                                                }
                                                        >;
                                                    }
                                            >
                                        >
                                    >;
                                    parentSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'> & {
                                                        metadata?: Maybe<
                                                            Array<
                                                                Maybe<
                                                                    {
                                                                        __typename?: 'SampleMetadata';
                                                                    } & Pick<
                                                                        SampleMetadata,
                                                                        | 'value'
                                                                        | 'name'
                                                                    >
                                                                >
                                                            >
                                                        >;
                                                        outputByProcess?: Maybe<
                                                            {
                                                                __typename?: 'Process';
                                                            } & Pick<
                                                                Process,
                                                                'bcdId'
                                                            > & {
                                                                    processType?: Maybe<
                                                                        {
                                                                            __typename?: 'ProcessType';
                                                                        } & Pick<
                                                                            ProcessType,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadata?: Maybe<
                                                                        Array<
                                                                            Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessMetadataValue';
                                                                                } & Pick<
                                                                                    ProcessMetadataValue,
                                                                                    'value'
                                                                                > & {
                                                                                        metadata?: Maybe<
                                                                                            {
                                                                                                __typename?: 'Metadata';
                                                                                            } & Pick<
                                                                                                Metadata,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                        metadataGroup?: Maybe<
                                                                                            {
                                                                                                __typename?: 'MetadataGroup';
                                                                                            } & Pick<
                                                                                                MetadataGroup,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                    }
                                                                            >
                                                                        >
                                                                    >;
                                                                }
                                                        >;
                                                    }
                                            >
                                        >
                                    >;
                                    outputByProcess?: Maybe<
                                        { __typename?: 'Process' } & Pick<
                                            Process,
                                            'bcdId'
                                        > & {
                                                processType?: Maybe<
                                                    {
                                                        __typename?: 'ProcessType';
                                                    } & Pick<
                                                        ProcessType,
                                                        'name'
                                                    >
                                                >;
                                            }
                                    >;
                                }
                        >
                    >
                >;
                poolInputMetadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'PoolInputMetadata' } & Pick<
                                PoolInputMetadata,
                                'sourceName' | 'concentration' | 'ulAdded'
                            >
                        >
                    >
                >;
                sourceDescendantSamples?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Sample' } & Pick<
                                Sample,
                                'bcdId'
                            > & {
                                    metadata?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'SampleMetadata';
                                                } & Pick<
                                                    SampleMetadata,
                                                    'value' | 'name'
                                                >
                                            >
                                        >
                                    >;
                                    childSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'> & {
                                                        sampleType?: Maybe<
                                                            {
                                                                __typename?: 'SampleType';
                                                            } & Pick<
                                                                SampleType,
                                                                'name'
                                                            >
                                                        >;
                                                        metadata?: Maybe<
                                                            Array<
                                                                Maybe<
                                                                    {
                                                                        __typename?: 'SampleMetadata';
                                                                    } & Pick<
                                                                        SampleMetadata,
                                                                        | 'value'
                                                                        | 'name'
                                                                    >
                                                                >
                                                            >
                                                        >;
                                                        outputByProcess?: Maybe<
                                                            {
                                                                __typename?: 'Process';
                                                            } & Pick<
                                                                Process,
                                                                'bcdId'
                                                            > & {
                                                                    processType?: Maybe<
                                                                        {
                                                                            __typename?: 'ProcessType';
                                                                        } & Pick<
                                                                            ProcessType,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadata?: Maybe<
                                                                        Array<
                                                                            Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessMetadataValue';
                                                                                } & Pick<
                                                                                    ProcessMetadataValue,
                                                                                    'value'
                                                                                > & {
                                                                                        metadata?: Maybe<
                                                                                            {
                                                                                                __typename?: 'Metadata';
                                                                                            } & Pick<
                                                                                                Metadata,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                        metadataGroup?: Maybe<
                                                                                            {
                                                                                                __typename?: 'MetadataGroup';
                                                                                            } & Pick<
                                                                                                MetadataGroup,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                    }
                                                                            >
                                                                        >
                                                                    >;
                                                                }
                                                        >;
                                                    }
                                            >
                                        >
                                    >;
                                    parentSamples?: Maybe<
                                        Array<
                                            Maybe<
                                                {
                                                    __typename?: 'Sample';
                                                } & Pick<Sample, 'bcdId'> & {
                                                        metadata?: Maybe<
                                                            Array<
                                                                Maybe<
                                                                    {
                                                                        __typename?: 'SampleMetadata';
                                                                    } & Pick<
                                                                        SampleMetadata,
                                                                        | 'value'
                                                                        | 'name'
                                                                    >
                                                                >
                                                            >
                                                        >;
                                                        sampleType?: Maybe<
                                                            {
                                                                __typename?: 'SampleType';
                                                            } & Pick<
                                                                SampleType,
                                                                'name'
                                                            >
                                                        >;
                                                        outputByProcess?: Maybe<
                                                            {
                                                                __typename?: 'Process';
                                                            } & Pick<
                                                                Process,
                                                                'bcdId'
                                                            > & {
                                                                    processType?: Maybe<
                                                                        {
                                                                            __typename?: 'ProcessType';
                                                                        } & Pick<
                                                                            ProcessType,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadata?: Maybe<
                                                                        Array<
                                                                            Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessMetadataValue';
                                                                                } & Pick<
                                                                                    ProcessMetadataValue,
                                                                                    'value'
                                                                                > & {
                                                                                        metadata?: Maybe<
                                                                                            {
                                                                                                __typename?: 'Metadata';
                                                                                            } & Pick<
                                                                                                Metadata,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                        metadataGroup?: Maybe<
                                                                                            {
                                                                                                __typename?: 'MetadataGroup';
                                                                                            } & Pick<
                                                                                                MetadataGroup,
                                                                                                'name'
                                                                                            >
                                                                                        >;
                                                                                    }
                                                                            >
                                                                        >
                                                                    >;
                                                                }
                                                        >;
                                                    }
                                            >
                                        >
                                    >;
                                    outputByProcess?: Maybe<
                                        { __typename?: 'Process' } & Pick<
                                            Process,
                                            'bcdId'
                                        > & {
                                                processType?: Maybe<
                                                    {
                                                        __typename?: 'ProcessType';
                                                    } & Pick<
                                                        ProcessType,
                                                        'name'
                                                    >
                                                >;
                                                metadata?: Maybe<
                                                    Array<
                                                        Maybe<
                                                            {
                                                                __typename?: 'ProcessMetadataValue';
                                                            } & Pick<
                                                                ProcessMetadataValue,
                                                                'value'
                                                            > & {
                                                                    metadata?: Maybe<
                                                                        {
                                                                            __typename?: 'Metadata';
                                                                        } & Pick<
                                                                            Metadata,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                    metadataGroup?: Maybe<
                                                                        {
                                                                            __typename?: 'MetadataGroup';
                                                                        } & Pick<
                                                                            MetadataGroup,
                                                                            'name'
                                                                        >
                                                                    >;
                                                                }
                                                        >
                                                    >
                                                >;
                                            }
                                    >;
                                }
                        >
                    >
                >;
                outputByProcess?: Maybe<
                    { __typename?: 'Process' } & Pick<Process, 'bcdId'> & {
                            processType?: Maybe<
                                { __typename?: 'ProcessType' } & Pick<
                                    ProcessType,
                                    'name'
                                >
                            >;
                            metadata?: Maybe<
                                Array<
                                    Maybe<
                                        {
                                            __typename?: 'ProcessMetadataValue';
                                        } & Pick<
                                            ProcessMetadataValue,
                                            'value' | 'uneditable' | 'replicate'
                                        > & {
                                                metadata?: Maybe<
                                                    {
                                                        __typename?: 'Metadata';
                                                    } & Pick<
                                                        Metadata,
                                                        'name' | 'units'
                                                    >
                                                >;
                                                metadataGroup?: Maybe<
                                                    {
                                                        __typename?: 'MetadataGroup';
                                                    } & Pick<
                                                        MetadataGroup,
                                                        'name'
                                                    >
                                                >;
                                            }
                                    >
                                >
                            >;
                        }
                >;
            }
    >;
};

export type DeleteSampleUrlInfoMutationVariables = Exact<{
    ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteSampleUrlInfoMutation = { __typename?: 'Mutation' } & {
    delete_sample_url?: Maybe<
        { __typename?: 'sample_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'sample_url' } & Pick<Sample_Url, 'id'>
            >;
        }
    >;
};

export type UpsertSampleUrlInfoMutationVariables = Exact<{
    sampleUrlInfo?: Maybe<
        Array<Sample_Url_Insert_Input> | Sample_Url_Insert_Input
    >;
}>;

export type UpsertSampleUrlInfoMutation = { __typename?: 'Mutation' } & {
    insert_sample_url?: Maybe<
        { __typename?: 'sample_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'sample_url' } & Pick<
                    Sample_Url,
                    'id' | 'sampleBcdId' | 'label' | 'url'
                >
            >;
        }
    >;
};

export type SampleFieldsFragment = { __typename?: 'Sample' } & Pick<
    Sample,
    'bcdId' | 'name' | 'dateReceived'
> & {
        sampleType?: Maybe<
            { __typename?: 'SampleType' } & Pick<SampleType, 'name'>
        >;
        metadata?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'SampleMetadata' } & Pick<
                        SampleMetadata,
                        'value' | 'name'
                    >
                >
            >
        >;
        parentSamples?: Maybe<
            Array<Maybe<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId'>>>
        >;
        childSamples?: Maybe<
            Array<Maybe<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId'>>>
        >;
        sourceSamples?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Sample' } & Pick<
                        Sample,
                        'bcdId' | 'name' | 'sourceId'
                    > & {
                            metadata?: Maybe<
                                Array<
                                    Maybe<
                                        {
                                            __typename?: 'SampleMetadata';
                                        } & Pick<
                                            SampleMetadata,
                                            'value' | 'name'
                                        >
                                    >
                                >
                            >;
                        }
                >
            >
        >;
        outputByProcess?: Maybe<
            { __typename?: 'Process' } & Pick<Process, 'bcdId'> & {
                    processType?: Maybe<
                        { __typename?: 'ProcessType' } & Pick<
                            ProcessType,
                            'name'
                        >
                    >;
                    metadata?: Maybe<
                        Array<
                            Maybe<
                                { __typename?: 'ProcessMetadataValue' } & Pick<
                                    ProcessMetadataValue,
                                    'value'
                                > & {
                                        metadata?: Maybe<
                                            { __typename?: 'Metadata' } & Pick<
                                                Metadata,
                                                'name' | 'units'
                                            >
                                        >;
                                        metadataGroup?: Maybe<
                                            {
                                                __typename?: 'MetadataGroup';
                                            } & Pick<MetadataGroup, 'name'>
                                        >;
                                    }
                            >
                        >
                    >;
                }
        >;
        measurements?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'Measurement' } & Pick<
                        Measurement,
                        'observationDate'
                    > & {
                            measurementType?: Maybe<
                                { __typename?: 'MeasurementType' } & Pick<
                                    MeasurementType,
                                    'name'
                                >
                            >;
                            data?: Maybe<
                                Array<
                                    Maybe<
                                        {
                                            __typename?: 'VariableValues';
                                        } & Pick<
                                            VariableValues,
                                            'stringValue' | 'value' | 'name'
                                        >
                                    >
                                >
                            >;
                        }
                >
            >
        >;
    };

export type SampleFullTextSearchQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    searchString?: Maybe<Scalars['String']>;
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    sortType?: Maybe<Scalars['String']>;
    sortDir?: Maybe<Scalars['String']>;
}>;

export type SampleFullTextSearchQuery = { __typename?: 'Query' } & {
    samplesFullTextSearch?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & SampleFieldsFragment>>
    >;
};

export type CountSampleFullTextSearchQueryVariables = Exact<{
    searchString?: Maybe<Scalars['String']>;
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
}>;

export type CountSampleFullTextSearchQuery = { __typename?: 'Query' } & Pick<
    Query,
    'countSamplesFullTextSearch'
>;

export type SamplesByBcdIdSearchQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    sortType?: Maybe<Scalars['String']>;
    sortDir?: Maybe<Scalars['String']>;
}>;

export type SamplesByBcdIdSearchQuery = { __typename?: 'Query' } & {
    samplesByBcdIdSearch?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & SampleFieldsFragment>>
    >;
};

export type CountSamplesByBcdIdSearchQueryVariables = Exact<{
    bcdIdSearchString?: Maybe<Scalars['String']>;
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
}>;

export type CountSamplesByBcdIdSearchQuery = { __typename?: 'Query' } & Pick<
    Query,
    'countSamplesByBcdIdSearch'
>;

export type SamplesByTypeSearchQueryVariables = Exact<{
    first?: Maybe<Scalars['Int']>;
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    sortType?: Maybe<Scalars['String']>;
    sortDir?: Maybe<Scalars['String']>;
}>;

export type SamplesByTypeSearchQuery = { __typename?: 'Query' } & {
    samplesByTypeSearch?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & SampleFieldsFragment>>
    >;
};

export type CountSamplesByTypeSearchQueryVariables = Exact<{
    dataPresent?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    dataSource?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processInputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
    processOutputTypes?: Maybe<
        Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
    >;
}>;

export type CountSamplesByTypeSearchQuery = { __typename?: 'Query' } & Pick<
    Query,
    'countSamplesByTypeSearch'
>;

export type StudyFieldsFragment = { __typename?: 'study' } & Pick<
    Study,
    'studyId' | 'studyName' | 'objective' | 'startDate' | 'endDate' | 'notes'
> & {
        study_urls: Array<
            { __typename?: 'study_url' } & Pick<
                Study_Url,
                'id' | 'label' | 'url'
            >
        >;
        studyNodes: Array<
            { __typename?: 'StudyNode' } & Pick<StudyNode, 'studyId'> & {
                    samples?: Maybe<
                        Array<
                            Maybe<
                                { __typename?: 'Sample' } & Pick<
                                    Sample,
                                    'bcdId' | 'name'
                                > & {
                                        parentSamples?: Maybe<
                                            Array<
                                                Maybe<
                                                    {
                                                        __typename?: 'Sample';
                                                    } & Pick<
                                                        Sample,
                                                        'name' | 'bcdId'
                                                    > & {
                                                            sampleType?: Maybe<
                                                                {
                                                                    __typename?: 'SampleType';
                                                                } & Pick<
                                                                    SampleType,
                                                                    'name'
                                                                >
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                        sourceSamples?: Maybe<
                                            Array<
                                                Maybe<
                                                    {
                                                        __typename?: 'Sample';
                                                    } & Pick<
                                                        Sample,
                                                        'bcdId' | 'name'
                                                    > & {
                                                            metadata?: Maybe<
                                                                Array<
                                                                    Maybe<
                                                                        {
                                                                            __typename?: 'SampleMetadata';
                                                                        } & Pick<
                                                                            SampleMetadata,
                                                                            | 'name'
                                                                            | 'units'
                                                                            | 'value'
                                                                            | 'uneditable'
                                                                        >
                                                                    >
                                                                >
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                        measurements?: Maybe<
                                            Array<
                                                Maybe<
                                                    {
                                                        __typename?: 'Measurement';
                                                    } & Pick<
                                                        Measurement,
                                                        'observationDate'
                                                    > & {
                                                            measurementType?: Maybe<
                                                                {
                                                                    __typename?: 'MeasurementType';
                                                                } & Pick<
                                                                    MeasurementType,
                                                                    'name'
                                                                >
                                                            >;
                                                            data?: Maybe<
                                                                Array<
                                                                    Maybe<
                                                                        {
                                                                            __typename: 'VariableValues';
                                                                        } & Pick<
                                                                            VariableValues,
                                                                            | 'name'
                                                                            | 'value'
                                                                            | 'stringValue'
                                                                            | 'replicate'
                                                                        >
                                                                    >
                                                                >
                                                            >;
                                                        }
                                                >
                                            >
                                        >;
                                        outputByProcess?: Maybe<
                                            { __typename?: 'Process' } & Pick<
                                                Process,
                                                'bcdId'
                                            > & {
                                                    metadata?: Maybe<
                                                        Array<
                                                            Maybe<
                                                                {
                                                                    __typename?: 'ProcessMetadataValue';
                                                                } & Pick<
                                                                    ProcessMetadataValue,
                                                                    | 'value'
                                                                    | 'replicate'
                                                                > & {
                                                                        metadata?: Maybe<
                                                                            {
                                                                                __typename?: 'Metadata';
                                                                            } & Pick<
                                                                                Metadata,
                                                                                'name'
                                                                            >
                                                                        >;
                                                                        metadataGroup?: Maybe<
                                                                            {
                                                                                __typename?: 'MetadataGroup';
                                                                            } & Pick<
                                                                                MetadataGroup,
                                                                                'name'
                                                                            >
                                                                        >;
                                                                    }
                                                            >
                                                        >
                                                    >;
                                                    processType?: Maybe<
                                                        {
                                                            __typename?: 'ProcessType';
                                                        } & Pick<
                                                            ProcessType,
                                                            'name'
                                                        >
                                                    >;
                                                }
                                        >;
                                    }
                            >
                        >
                    >;
                }
        >;
        trays: Array<
            { __typename?: 'ProcessMetadataValue' } & Pick<
                ProcessMetadataValue,
                'value'
            >
        >;
        relatedProjects: Array<
            { __typename?: 'project_study' } & Pick<Project_Study, 'id'> & {
                    study: { __typename?: 'study' } & Pick<
                        Study,
                        'studyId' | 'studyName'
                    >;
                    project: { __typename?: 'project' } & Pick<
                        Project,
                        'projectId' | 'projectName'
                    >;
                }
        >;
    };

export type StudyDetailQueryVariables = Exact<{
    studyId: Scalars['String'];
}>;

export type StudyDetailQuery = { __typename?: 'Query' } & {
    study_by_pk?: Maybe<{ __typename?: 'study' } & StudyFieldsFragment>;
};

export type CreateStudyMutationVariables = Exact<{
    study: Study_Insert_Input;
    studyId?: Maybe<Scalars['String']>;
}>;

export type CreateStudyMutation = { __typename?: 'Mutation' } & {
    insert_study_one?: Maybe<{ __typename?: 'study' } & StudyFieldsFragment>;
    createStudy?: Maybe<{ __typename: 'StudyNode' }>;
};

export type EditStudyMutationVariables = Exact<{
    studyId: Scalars['String'];
    study?: Maybe<Study_Set_Input>;
}>;

export type EditStudyMutation = { __typename?: 'Mutation' } & {
    update_study_by_pk?: Maybe<{ __typename?: 'study' } & StudyFieldsFragment>;
};

export type DeleteStudyMutationVariables = Exact<{
    studyId: Scalars['String'];
}>;

export type DeleteStudyMutation = { __typename?: 'Mutation' } & {
    delete_study_by_pk?: Maybe<
        { __typename?: 'study' } & Pick<Study, 'studyId'>
    >;
    deleteStudy?: Maybe<{ __typename: 'StudyNode' }>;
};

export type AddStudyToProjectMutationVariables = Exact<{
    studyId: Scalars['String'];
    projectId: Scalars['String'];
}>;

export type AddStudyToProjectMutation = { __typename?: 'Mutation' } & {
    insert_project_study_one?: Maybe<
        { __typename?: 'project_study' } & Pick<Project_Study, 'id'>
    >;
};

export type DeleteStudyFromProjectMutationVariables = Exact<{
    studyProjectId: Scalars['Int'];
}>;

export type DeleteStudyFromProjectMutation = { __typename?: 'Mutation' } & {
    delete_project_study_by_pk?: Maybe<
        { __typename?: 'project_study' } & Pick<Project_Study, 'id'>
    >;
};

export type DeleteStudyUrlInfoMutationVariables = Exact<{
    ids?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteStudyUrlInfoMutation = { __typename?: 'Mutation' } & {
    delete_study_url?: Maybe<
        { __typename?: 'study_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'study_url' } & Pick<Study_Url, 'id'>
            >;
        }
    >;
};

export type UpsertStudyUrlInfoMutationVariables = Exact<{
    studyUrlInfo?: Maybe<
        Array<Study_Url_Insert_Input> | Study_Url_Insert_Input
    >;
}>;

export type UpsertStudyUrlInfoMutation = { __typename?: 'Mutation' } & {
    insert_study_url?: Maybe<
        { __typename?: 'study_url_mutation_response' } & {
            returning: Array<
                { __typename?: 'study_url' } & Pick<
                    Study_Url,
                    'id' | 'studyId' | 'label' | 'url'
                >
            >;
        }
    >;
};

export type StudyListQueryVariables = Exact<{ [key: string]: never }>;

export type StudyListQuery = { __typename?: 'Query' } & {
    study: Array<
        { __typename?: 'study' } & Pick<
            Study,
            | 'studyId'
            | 'studyName'
            | 'startDate'
            | 'endDate'
            | 'objective'
            | 'notes'
        > & {
                trays: Array<
                    { __typename?: 'ProcessMetadataValue' } & Pick<
                        ProcessMetadataValue,
                        'value'
                    >
                >;
                studyNodes: Array<
                    { __typename?: 'StudyNode' } & Pick<
                        StudyNode,
                        'studyId' | 'numCrosses' | 'numTrays'
                    >
                >;
            }
    >;
};

export type AddPoolSourceMutationVariables = Exact<{
    newPoolSourceId: Scalars['String'];
    processId: Scalars['String'];
    processOutputId: Scalars['String'];
    processOutputType: Scalars['String'];
    numDonors: Scalars['String'];
    sex: Scalars['String'];
    healthStatus: Scalars['String'];
}>;

export type AddPoolSourceMutation = { __typename?: 'Mutation' } & {
    addPoolSource?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'name' | 'sourceId' | 'dateReceived'
                > & {
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SampleMetadata' } & Pick<
                                        SampleMetadata,
                                        'value' | 'name'
                                    >
                                >
                            >
                        >;
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'name' | 'value'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        sourceSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            metadata?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'SampleMetadata';
                                                        } & Pick<
                                                            SampleMetadata,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        parentSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            childSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        >
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        childSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            parentSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        >
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        ancestorSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'sourceId' | 'bcdId'
                                    > & {
                                            childSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        > & {
                                                                outputByProcess?: Maybe<
                                                                    {
                                                                        __typename?: 'Process';
                                                                    } & Pick<
                                                                        Process,
                                                                        'bcdId'
                                                                    > & {
                                                                            processType?: Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessType';
                                                                                } & Pick<
                                                                                    ProcessType,
                                                                                    'name'
                                                                                >
                                                                            >;
                                                                        }
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                            parentSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        > & {
                                                                outputByProcess?: Maybe<
                                                                    {
                                                                        __typename?: 'Process';
                                                                    } & Pick<
                                                                        Process,
                                                                        'bcdId'
                                                                    > & {
                                                                            processType?: Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessType';
                                                                                } & Pick<
                                                                                    ProcessType,
                                                                                    'name'
                                                                                >
                                                                            >;
                                                                        }
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        outputByProcess?: Maybe<
                            { __typename?: 'Process' } & Pick<
                                Process,
                                'bcdId'
                            > & {
                                    processType?: Maybe<
                                        { __typename?: 'ProcessType' } & Pick<
                                            ProcessType,
                                            'name'
                                        >
                                    >;
                                }
                        >;
                    }
            >
        >
    >;
};

export type CalculateCurvesQueryVariables = Exact<{
    measurementsJson: Scalars['String'];
}>;

export type CalculateCurvesQuery = { __typename?: 'Query' } & {
    calculateCurves?: Maybe<
        { __typename?: 'CurvesOutput' } & Pick<CurvesOutput, 'calculated'>
    >;
};

export type CreateBcdIdMutationVariables = Exact<{
    n?: Maybe<Scalars['Int']>;
}>;

export type CreateBcdIdMutation = { __typename?: 'Mutation' } & {
    create_bcd_id: Array<
        { __typename?: 'bcd_id_gen_table' } & Pick<Bcd_Id_Gen_Table, 'bcd_id'>
    >;
};

export type CreateFastqMappingMeasurementMutationVariables = Exact<{
    sampleId?: Maybe<Scalars['String']>;
    mappingFileKey?: Maybe<Scalars['String']>;
}>;

export type CreateFastqMappingMeasurementMutation = {
    __typename?: 'Mutation';
} & {
    createFastqMappingMeasurement?: Maybe<
        Array<Maybe<{ __typename?: 'Measurement' } & Pick<Measurement, 'uuid'>>>
    >;
};

export type CreateFileTypeMeasurementMutationVariables = Exact<{
    sampleId?: Maybe<Scalars['String']>;
    measurementType?: Maybe<Scalars['String']>;
    filePath?: Maybe<Scalars['String']>;
}>;

export type CreateFileTypeMeasurementMutation = { __typename?: 'Mutation' } & {
    createFileTypeMeasurement?: Maybe<
        Array<Maybe<{ __typename?: 'Measurement' } & Pick<Measurement, 'uuid'>>>
    >;
};

export type CreateGrowthCurveMeasurementMutationVariables = Exact<{
    trayName?: Maybe<Scalars['String']>;
    replicate?: Maybe<Scalars['String']>;
    values?: Maybe<Scalars['String']>;
}>;

export type CreateGrowthCurveMeasurementMutation = {
    __typename?: 'Mutation';
} & {
    createGrowthCurveMeasurement?: Maybe<
        Array<Maybe<{ __typename?: 'Measurement' } & Pick<Measurement, 'uuid'>>>
    >;
};

export type CreateInteractionProcessAndSampleSingleParentMutationVariables =
    Exact<{
        processId?: Maybe<Scalars['String']>;
        sampleId?: Maybe<Scalars['String']>;
        parentId?: Maybe<Scalars['String']>;
    }>;

export type CreateInteractionProcessAndSampleSingleParentMutation = {
    __typename?: 'Mutation';
} & {
    createInteractionProcessAndSampleSingleParent?: Maybe<
        Array<Maybe<{ __typename?: 'Process' } & Pick<Process, 'bcdId'>>>
    >;
};

export type CreateInteractionProcessAndSampleMutationVariables = Exact<{
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
    parent1Id?: Maybe<Scalars['String']>;
    parent2Id?: Maybe<Scalars['String']>;
}>;

export type CreateInteractionProcessAndSampleMutation = {
    __typename?: 'Mutation';
} & {
    createInteractionProcessAndSample?: Maybe<
        Array<Maybe<{ __typename?: 'Process' } & Pick<Process, 'bcdId'>>>
    >;
};

export type CreateProcessAndSampleMutationVariables = Exact<{
    fromId?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    processType?: Maybe<Scalars['String']>;
    descriptiveName?: Maybe<Scalars['String']>;
    sourceSex?: Maybe<Scalars['String']>;
    sourceHealthStatus?: Maybe<Scalars['String']>;
}>;

export type CreateProcessAndSampleMutation = { __typename?: 'Mutation' } & {
    createProcessAndSample?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'name' | 'sourceId' | 'dateReceived'
                > & {
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SampleMetadata' } & Pick<
                                        SampleMetadata,
                                        'value' | 'name'
                                    >
                                >
                            >
                        >;
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'name' | 'value'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        sourceSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            metadata?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'SampleMetadata';
                                                        } & Pick<
                                                            SampleMetadata,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                        parentSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            childSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        >
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        childSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'bcdId' | 'name' | 'sourceId'
                                    > & {
                                            parentSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        >
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        ancestorSamples?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Sample' } & Pick<
                                        Sample,
                                        'sourceId' | 'bcdId'
                                    > & {
                                            childSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        > & {
                                                                outputByProcess?: Maybe<
                                                                    {
                                                                        __typename?: 'Process';
                                                                    } & Pick<
                                                                        Process,
                                                                        'bcdId'
                                                                    > & {
                                                                            processType?: Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessType';
                                                                                } & Pick<
                                                                                    ProcessType,
                                                                                    'name'
                                                                                >
                                                                            >;
                                                                        }
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                            parentSamples?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'Sample';
                                                        } & Pick<
                                                            Sample,
                                                            'bcdId'
                                                        > & {
                                                                outputByProcess?: Maybe<
                                                                    {
                                                                        __typename?: 'Process';
                                                                    } & Pick<
                                                                        Process,
                                                                        'bcdId'
                                                                    > & {
                                                                            processType?: Maybe<
                                                                                {
                                                                                    __typename?: 'ProcessType';
                                                                                } & Pick<
                                                                                    ProcessType,
                                                                                    'name'
                                                                                >
                                                                            >;
                                                                        }
                                                                >;
                                                            }
                                                    >
                                                >
                                            >;
                                            outputByProcess?: Maybe<
                                                {
                                                    __typename?: 'Process';
                                                } & Pick<Process, 'bcdId'> & {
                                                        processType?: Maybe<
                                                            {
                                                                __typename?: 'ProcessType';
                                                            } & Pick<
                                                                ProcessType,
                                                                'name'
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >
                            >
                        >;
                        outputByProcess?: Maybe<
                            { __typename?: 'Process' } & Pick<
                                Process,
                                'bcdId'
                            > & {
                                    processType?: Maybe<
                                        { __typename?: 'ProcessType' } & Pick<
                                            ProcessType,
                                            'name'
                                        >
                                    >;
                                }
                        >;
                    }
            >
        >
    >;
};

export type CreateSampleLinkageMeasurementMutationVariables = Exact<{
    bcdId?: Maybe<Scalars['String']>;
    observationDt?: Maybe<UdbDateTimeInput>;
    measurementData?: Maybe<
        Array<Maybe<LinkageVariableInput>> | Maybe<LinkageVariableInput>
    >;
    sopVersion?: Maybe<Scalars['String']>;
}>;

export type CreateSampleLinkageMeasurementMutation = {
    __typename?: 'Mutation';
} & {
    createSampleLinkageMeasurement?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<Sample, 'bcdId'> & {
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate' | 'sopVersion'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type CreateSampleMetabAnalytesMeasurementMutationVariables = Exact<{
    bcdId?: Maybe<Scalars['String']>;
    observationDt?: Maybe<UdbDateTimeInput>;
    measurementData?: Maybe<MeasurementDataMetabAnalytesInput>;
    replicate?: Maybe<Scalars['String']>;
}>;

export type CreateSampleMetabAnalytesMeasurementMutation = {
    __typename?: 'Mutation';
} & {
    createSampleMetabAnalytesMeasurement?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<Sample, 'bcdId'> & {
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        'observationDate'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type CreateSampleMonoOrFreeMonoMeasurementMutationVariables = Exact<{
    bcdId?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    observationDt?: Maybe<UdbDateTimeInput>;
    measurementData?: Maybe<MeasurementDataMonoInput>;
    monoMethodType?: Maybe<Scalars['String']>;
    sopVersion?: Maybe<Scalars['String']>;
}>;

export type CreateSampleMonoOrFreeMonoMeasurementMutation = {
    __typename?: 'Mutation';
} & {
    createSampleMonoOrFreeMonoMeasurement?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<Sample, 'bcdId'> & {
                        measurements?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'Measurement' } & Pick<
                                        Measurement,
                                        | 'observationDate'
                                        | 'monoMethodType'
                                        | 'sopVersion'
                                    > & {
                                            measurementType?: Maybe<
                                                {
                                                    __typename?: 'MeasurementType';
                                                } & Pick<
                                                    MeasurementType,
                                                    'name'
                                                >
                                            >;
                                            data?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'VariableValues';
                                                        } & Pick<
                                                            VariableValues,
                                                            'value' | 'name'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type CreateFecalSourceSampleMutationVariables = Exact<{
    bcdId?: Maybe<Scalars['String']>;
    donorNumber?: Maybe<Scalars['String']>;
    collectionDate?: Maybe<Scalars['String']>;
    expirationDate?: Maybe<Scalars['String']>;
    storageLocation?: Maybe<Scalars['String']>;
    ndaMta?: Maybe<Scalars['String']>;
    unitId?: Maybe<Scalars['String']>;
    sex?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    healthStatus?: Maybe<Scalars['String']>;
    aliquotesLeft?: Maybe<Scalars['String']>;
    appearance?: Maybe<Scalars['String']>;
    notes?: Maybe<Scalars['String']>;
}>;

export type CreateFecalSourceSampleMutation = { __typename?: 'Mutation' } & {
    createFecalSourceSample?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId'>>>
    >;
};

export type DeleteMeasurementMutationVariables = Exact<{
    measurementUuid: Scalars['String'];
}>;

export type DeleteMeasurementMutation = { __typename?: 'Mutation' } & {
    deleteMeasurement?: Maybe<{ __typename: 'Measurement' }>;
};

export type DeleteSampleAndDescendantsMutationVariables = Exact<{
    sampleId: Scalars['String'];
    isSourceSample: Scalars['Boolean'];
}>;

export type DeleteSampleAndDescendantsMutation = { __typename?: 'Mutation' } & {
    deleteSampleAndDescendants?: Maybe<{ __typename: 'Sample' }>;
};

export type InsertImportFileOneMutationVariables = Exact<{
    datafileType?: Maybe<Scalars['String']>;
    originalFilename?: Maybe<Scalars['String']>;
    archiveFilepath?: Maybe<Scalars['String']>;
}>;

export type InsertImportFileOneMutation = { __typename?: 'Mutation' } & {
    insert_import_file_one?: Maybe<
        { __typename?: 'import_file' } & Pick<
            Import_File,
            | 'id'
            | 'datafile_type'
            | 'original_filename'
            | 'archive_filepath'
            | 'import_date'
        >
    >;
};

export type MergeCalculatedCurvesMutationVariables = Exact<{
    trayName?: Maybe<Scalars['String']>;
    replicate?: Maybe<Scalars['String']>;
    calculatedValues?: Maybe<Scalars['String']>;
}>;

export type MergeCalculatedCurvesMutation = { __typename?: 'Mutation' } & {
    mergeCalculatedCurveValues?: Maybe<
        Array<Maybe<{ __typename?: 'Measurement' } & Pick<Measurement, 'uuid'>>>
    >;
};

export type MergeProcessMetadataMutationVariables = Exact<{
    processId?: Maybe<Scalars['String']>;
    metadataGroup?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    metadataValue?: Maybe<Scalars['String']>;
}>;

export type MergeProcessMetadataMutation = { __typename?: 'Mutation' } & {
    mergeProcessMetadata?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Process' } & Pick<Process, 'bcdId'> & {
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    {
                                        __typename?: 'ProcessMetadataValue';
                                    } & Pick<ProcessMetadataValue, 'value'> & {
                                            metadata?: Maybe<
                                                {
                                                    __typename?: 'Metadata';
                                                } & Pick<Metadata, 'name'>
                                            >;
                                            metadataGroup?: Maybe<
                                                {
                                                    __typename?: 'MetadataGroup';
                                                } & Pick<MetadataGroup, 'name'>
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MergeProcessReplicateMetadataMutationVariables = Exact<{
    processId?: Maybe<Scalars['String']>;
    metadataGroup?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    metadataValue?: Maybe<Scalars['String']>;
    replicateName?: Maybe<Scalars['String']>;
}>;

export type MergeProcessReplicateMetadataMutation = {
    __typename?: 'Mutation';
} & {
    mergeProcessReplicateMetadata?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Process' } & Pick<Process, 'bcdId'> & {
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    {
                                        __typename?: 'ProcessMetadataValue';
                                    } & Pick<ProcessMetadataValue, 'value'> & {
                                            metadata?: Maybe<
                                                {
                                                    __typename?: 'Metadata';
                                                } & Pick<Metadata, 'name'>
                                            >;
                                            metadataGroup?: Maybe<
                                                {
                                                    __typename?: 'MetadataGroup';
                                                } & Pick<MetadataGroup, 'name'>
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MergeProcessStudyMutationVariables = Exact<{
    processId?: Maybe<Scalars['String']>;
    sampleId?: Maybe<Scalars['String']>;
    studyId?: Maybe<Scalars['String']>;
}>;

export type MergeProcessStudyMutation = { __typename?: 'Mutation' } & {
    mergeProcessToStudy?: Maybe<
        Array<Maybe<{ __typename?: 'Process' } & Pick<Process, 'bcdId'>>>
    >;
};

export type MergeSampleMetadataMutationVariables = Exact<{
    bcdId: Scalars['String'];
    metadataName: Scalars['String'];
    value: Scalars['String'];
}>;

export type MergeSampleMetadataMutation = { __typename?: 'Mutation' } & {
    mergeSampleMetadata?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<Sample, 'bcdId'> & {
                        metadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SampleMetadata' } & Pick<
                                        SampleMetadata,
                                        'name' | 'value'
                                    >
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type MergeSourceSampleMutationVariables = Exact<{
    bcdId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    dateReceived?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
}>;

export type MergeSourceSampleMutation = { __typename?: 'Mutation' } & {
    mergeSourceSample?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'sourceId' | 'name' | 'dateReceived'
                >
            >
        >
    >;
};

export type MetadataGroupQueryVariables = Exact<{
    name?: Maybe<Scalars['String']>;
}>;

export type MetadataGroupQuery = { __typename?: 'Query' } & {
    metadataGroups: Array<
        { __typename?: 'MetadataGroup' } & Pick<MetadataGroup, 'name'> & {
                metadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Metadata' } & Pick<
                                Metadata,
                                'name' | 'units'
                            >
                        >
                    >
                >;
            }
    >;
};

export type SampleBasicQueryVariables = Exact<{
    bcdId?: Maybe<Scalars['ID']>;
}>;

export type SampleBasicQuery = { __typename?: 'Query' } & {
    samples: Array<
        { __typename?: 'Sample' } & Pick<Sample, 'bcdId' | 'name'> & {
                metadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'SampleMetadata' } & Pick<
                                SampleMetadata,
                                'value' | 'name'
                            >
                        >
                    >
                >;
            }
    >;
};

export type SampleTypesMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type SampleTypesMetadataQuery = { __typename?: 'Query' } & {
    sampleTypes: Array<
        { __typename?: 'SampleType' } & Pick<SampleType, 'name'> & {
                metadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Metadata' } & Pick<Metadata, 'name'>
                        >
                    >
                >;
            }
    >;
};

export type SamplesByBcdIdQueryVariables = Exact<{
    bcdIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;

export type SamplesByBcdIdQuery = { __typename?: 'Query' } & {
    samples: Array<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId' | 'name'>>;
};

export type SamplesByMappingFileKeyQueryVariables = Exact<{
    mappingFileKey?: Maybe<Scalars['String']>;
}>;

export type SamplesByMappingFileKeyQuery = { __typename?: 'Query' } & {
    samplesByMappingFileKey?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId'>>>
    >;
};

export type SamplesBySampleTypeQueryVariables = Exact<{
    sampleType?: Maybe<Scalars['String']>;
}>;

export type SamplesBySampleTypeQuery = { __typename?: 'Query' } & {
    samples: Array<
        { __typename?: 'Sample' } & Pick<Sample, 'name'> & {
                metadata?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'SampleMetadata' } & Pick<
                                SampleMetadata,
                                'name' | 'value'
                            >
                        >
                    >
                >;
            }
    >;
};

export type SamplesByTrayCellQueryVariables = Exact<{
    trayName?: Maybe<Scalars['String']>;
    cellId?: Maybe<Scalars['String']>;
}>;

export type SamplesByTrayCellQuery = { __typename?: 'Query' } & {
    samplesByTrayCell?: Maybe<
        Array<Maybe<{ __typename?: 'Sample' } & Pick<Sample, 'bcdId'>>>
    >;
};

export type SavePoolSourceMetadataMutationVariables = Exact<{
    sampleId?: Maybe<Scalars['String']>;
    processId?: Maybe<Scalars['String']>;
    sourceName?: Maybe<Scalars['String']>;
    metadataName?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
}>;

export type SavePoolSourceMetadataMutation = { __typename?: 'Mutation' } & {
    savePoolSourceMetadata?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Sample' } & Pick<
                    Sample,
                    'bcdId' | 'name' | 'sourceId'
                > & {
                        poolInputMetadata?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'PoolInputMetadata' } & Pick<
                                        PoolInputMetadata,
                                        | 'sourceName'
                                        | 'concentration'
                                        | 'ulAdded'
                                    >
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type StudiesByStudyIdQueryVariables = Exact<{
    studyIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;

export type StudiesByStudyIdQuery = { __typename?: 'Query' } & {
    studyNodes: Array<
        { __typename?: 'StudyNode' } & Pick<StudyNode, 'studyId'>
    >;
};

export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on project {
        projectId
        projectName
        client
        objective
        startDate
        completionDate
        notes
        project_urls {
            id
            label
            url
        }
        finalReportUrl
        assignedUsers {
            id
            user {
                userId
                userName
            }
            role {
                id
                role
            }
        }
        relatedStudies {
            study {
                studyId
                studyName
                objective
                startDate
                endDate
            }
        }
        relatedSamples {
            id
            sampleBcdId
            sampleNode {
                bcdId
                dateReceived
                outputByProcess {
                    processType {
                        name
                    }
                    metadata {
                        metadata {
                            name
                        }
                        value
                        metadataGroup {
                            name
                        }
                    }
                }
                measurements {
                    measurementType {
                        name
                    }
                    data {
                        name
                        value
                        stringValue
                        replicate
                    }
                    observationDate
                    uuid
                }
                sourceSamples {
                    bcdId
                    name
                    sourceId
                    metadata {
                        value
                        name
                    }
                    sampleType {
                        name
                    }
                }
            }
        }
    }
`;
export const SampleFieldsFragmentDoc = gql`
    fragment SampleFields on Sample {
        bcdId
        name
        sampleType {
            name
        }
        metadata {
            value
            name
        }
        dateReceived
        parentSamples {
            bcdId
        }
        childSamples {
            bcdId
        }
        sourceSamples {
            bcdId
            name
            sourceId
            metadata {
                value
                name
            }
        }
        outputByProcess {
            bcdId
            processType {
                name
            }
            metadata {
                metadata {
                    name
                    units
                }
                value
                metadataGroup {
                    name
                }
            }
        }
        measurements {
            measurementType {
                name
            }
            data {
                stringValue
                value
                name
            }
            observationDate
        }
    }
`;
export const StudyFieldsFragmentDoc = gql`
    fragment StudyFields on study {
        studyId
        studyName
        objective
        startDate
        endDate
        notes
        study_urls {
            id
            label
            url
        }
        studyNodes {
            studyId
            samples {
                bcdId
                name
                parentSamples {
                    name
                    bcdId
                    sampleType {
                        name
                    }
                }
                sourceSamples {
                    bcdId
                    name
                    metadata {
                        name
                        units
                        value
                        uneditable
                    }
                }
                measurements {
                    measurementType {
                        name
                    }
                    data {
                        __typename
                        name
                        value
                        stringValue
                        replicate
                    }
                    observationDate
                }
                outputByProcess {
                    bcdId
                    metadata {
                        value
                        metadata {
                            name
                        }
                        metadataGroup {
                            name
                        }
                        replicate
                    }
                    processType {
                        name
                    }
                }
            }
        }
        trays {
            value
        }
        relatedProjects {
            id
            study {
                studyId
                studyName
            }
            project {
                projectId
                projectName
            }
        }
    }
`;
export const PoolSourcesFullTextSearchDocument = gql`
    query PoolSourcesFullTextSearch(
        $first: Int
        $searchString: String
        $type: String
    ) {
        poolSourcesFullTextSearch(
            limit: $first
            searchString: $searchString
            type: $type
        ) {
            bcdId
            name
            sampleType {
                name
            }
            metadata {
                value
                name
            }
            dateReceived
            parentSamples {
                bcdId
            }
            childSamples {
                bcdId
            }
            sourceSamples {
                bcdId
                name
                sourceId
                metadata {
                    value
                    name
                }
            }
            outputByProcess {
                bcdId
                processType {
                    name
                }
            }
            measurements {
                measurementType {
                    name
                }
                data {
                    value
                    name
                }
                observationDate
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PoolSourcesFullTextSearchGQL extends Apollo.Query<
    PoolSourcesFullTextSearchQuery,
    PoolSourcesFullTextSearchQueryVariables
> {
    document = PoolSourcesFullTextSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RelatedSamplesFullTextSearchDocument = gql`
    query RelatedSamplesFullTextSearch($first: Int, $searchString: String) {
        relatedSamplesFullTextSearch(
            limit: $first
            searchString: $searchString
        ) {
            bcdId
            name
            sampleType {
                name
            }
            metadata {
                value
                name
            }
            dateReceived
            parentSamples {
                bcdId
            }
            childSamples {
                bcdId
            }
            outputByProcess {
                bcdId
                processType {
                    name
                }
            }
            measurements {
                measurementType {
                    name
                }
                data {
                    name
                    value
                    stringValue
                    replicate
                }
                observationDate
                uuid
            }
            sourceSamples {
                bcdId
                name
                sourceId
                metadata {
                    value
                    name
                }
                sampleType {
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class RelatedSamplesFullTextSearchGQL extends Apollo.Query<
    RelatedSamplesFullTextSearchQuery,
    RelatedSamplesFullTextSearchQueryVariables
> {
    document = RelatedSamplesFullTextSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectListDocument = gql`
    query ProjectList {
        project {
            projectId
            projectName
            client
            objective
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class ProjectListGQL extends Apollo.Query<
    ProjectListQuery,
    ProjectListQueryVariables
> {
    document = ProjectListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProjectDetailDocument = gql`
    query ProjectDetail($projectId: String!) {
        project_by_pk(projectId: $projectId) {
            ...ProjectFields
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class ProjectDetailGQL extends Apollo.Query<
    ProjectDetailQuery,
    ProjectDetailQueryVariables
> {
    document = ProjectDetailDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UsersListDocument = gql`
    query UsersList {
        user {
            userId
            userName
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UsersListGQL extends Apollo.Query<
    UsersListQuery,
    UsersListQueryVariables
> {
    document = UsersListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RolesListDocument = gql`
    query RolesList {
        role {
            id
            role
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class RolesListGQL extends Apollo.Query<
    RolesListQuery,
    RolesListQueryVariables
> {
    document = RolesListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddProjectUserAssignmentsDocument = gql`
    mutation AddProjectUserAssignments(
        $user_assignments: [project_user_insert_input!] = {}
    ) {
        insert_project_user(objects: $user_assignments) {
            returning {
                projectId
                role {
                    id
                    role
                }
                user {
                    userName
                    userId
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AddProjectUserAssignmentsGQL extends Apollo.Mutation<
    AddProjectUserAssignmentsMutation,
    AddProjectUserAssignmentsMutationVariables
> {
    document = AddProjectUserAssignmentsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectUserAssignmentsDocument = gql`
    mutation DeleteProjectUserAssignments($ids: [Int!]) {
        delete_project_user(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteProjectUserAssignmentsGQL extends Apollo.Mutation<
    DeleteProjectUserAssignmentsMutation,
    DeleteProjectUserAssignmentsMutationVariables
> {
    document = DeleteProjectUserAssignmentsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProjectDocument = gql`
    mutation CreateProject($project: project_insert_input!) {
        insert_project_one(object: $project) {
            ...ProjectFields
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateProjectGQL extends Apollo.Mutation<
    CreateProjectMutation,
    CreateProjectMutationVariables
> {
    document = CreateProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EditProjectDocument = gql`
    mutation EditProject($projectId: String!, $project: project_set_input) {
        update_project_by_pk(
            pk_columns: { projectId: $projectId }
            _set: $project
        ) {
            ...ProjectFields
        }
    }
    ${ProjectFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class EditProjectGQL extends Apollo.Mutation<
    EditProjectMutation,
    EditProjectMutationVariables
> {
    document = EditProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: String!) {
        delete_project_by_pk(projectId: $projectId) {
            projectId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteProjectGQL extends Apollo.Mutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
> {
    document = DeleteProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteProjectUrlInfoDocument = gql`
    mutation DeleteProjectUrlInfo($ids: [Int!]) {
        delete_project_url(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteProjectUrlInfoGQL extends Apollo.Mutation<
    DeleteProjectUrlInfoMutation,
    DeleteProjectUrlInfoMutationVariables
> {
    document = DeleteProjectUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpsertProjectUrlInfoDocument = gql`
    mutation UpsertProjectUrlInfo(
        $projectUrlInfo: [project_url_insert_input!] = {}
    ) {
        insert_project_url(
            objects: $projectUrlInfo
            on_conflict: {
                constraint: project_url_pkey
                update_columns: [label, url]
            }
        ) {
            returning {
                id
                projectId
                label
                url
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpsertProjectUrlInfoGQL extends Apollo.Mutation<
    UpsertProjectUrlInfoMutation,
    UpsertProjectUrlInfoMutationVariables
> {
    document = UpsertProjectUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteRelatedSampleFromProjectDocument = gql`
    mutation DeleteRelatedSampleFromProject($projectSampleId: Int!) {
        delete_project_sample_by_pk(id: $projectSampleId) {
            projectId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteRelatedSampleFromProjectGQL extends Apollo.Mutation<
    DeleteRelatedSampleFromProjectMutation,
    DeleteRelatedSampleFromProjectMutationVariables
> {
    document = DeleteRelatedSampleFromProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddRelatedSampleToProjectDocument = gql`
    mutation AddRelatedSampleToProject(
        $projectId: String!
        $sampleBcdId: String!
    ) {
        insert_project_sample_one(
            object: { projectId: $projectId, sampleBcdId: $sampleBcdId }
        ) {
            projectId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AddRelatedSampleToProjectGQL extends Apollo.Mutation<
    AddRelatedSampleToProjectMutation,
    AddRelatedSampleToProjectMutationVariables
> {
    document = AddRelatedSampleToProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SampleDetailDocument = gql`
    query SampleDetail($bcdId: ID) {
        samples(where: { bcdId: $bcdId }) {
            bcdId
            name
            sourceId
            dateReceived
            sampleRows {
                url
                label
                id
            }
            metadata {
                value
                name
                uneditable
            }
            sampleType {
                name
            }
            measurements {
                measurementType {
                    name
                }
                data {
                    name
                    value
                    stringValue
                    replicate
                }
                observationDate
                monoMethodType
                sopVersion
                uuid
            }
            sourceSamples {
                bcdId
                name
                sourceId
                metadata {
                    value
                    name
                }
                sampleType {
                    name
                }
            }
            parentSamples {
                bcdId
                name
                sourceId
                sampleType {
                    name
                }
                metadata {
                    value
                    name
                }
                childSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                    metadata {
                        value
                        metadata {
                            name
                        }
                        metadataGroup {
                            name
                        }
                    }
                }
            }
            childSamples {
                bcdId
                name
                sourceId
                sampleType {
                    name
                }
                metadata {
                    value
                    name
                }
                parentSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                    metadata {
                        value
                        metadata {
                            name
                        }
                        metadataGroup {
                            name
                        }
                    }
                }
            }
            ancestorSamples {
                sourceId
                bcdId
                name
                childSamples {
                    bcdId
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                        metadata {
                            value
                            metadata {
                                name
                            }
                            metadataGroup {
                                name
                            }
                        }
                    }
                }
                parentSamples {
                    bcdId
                    metadata {
                        value
                        name
                    }
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                        metadata {
                            value
                            metadata {
                                name
                            }
                            metadataGroup {
                                name
                            }
                        }
                    }
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            poolInputMetadata {
                sourceName
                concentration
                ulAdded
            }
            sourceDescendantSamples {
                bcdId
                metadata {
                    value
                    name
                }
                childSamples {
                    bcdId
                    sampleType {
                        name
                    }
                    metadata {
                        value
                        name
                    }
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                        metadata {
                            value
                            metadata {
                                name
                            }
                            metadataGroup {
                                name
                            }
                        }
                    }
                }
                parentSamples {
                    bcdId
                    metadata {
                        value
                        name
                    }
                    sampleType {
                        name
                    }
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                        metadata {
                            value
                            metadata {
                                name
                            }
                            metadataGroup {
                                name
                            }
                        }
                    }
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                    metadata {
                        value
                        metadata {
                            name
                        }
                        metadataGroup {
                            name
                        }
                    }
                }
            }
            outputByProcess {
                bcdId
                processType {
                    name
                }
                metadata {
                    value
                    uneditable
                    replicate
                    metadata {
                        name
                        units
                    }
                    metadataGroup {
                        name
                    }
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SampleDetailGQL extends Apollo.Query<
    SampleDetailQuery,
    SampleDetailQueryVariables
> {
    document = SampleDetailDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSampleUrlInfoDocument = gql`
    mutation DeleteSampleUrlInfo($ids: [Int!]) {
        delete_sample_url(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteSampleUrlInfoGQL extends Apollo.Mutation<
    DeleteSampleUrlInfoMutation,
    DeleteSampleUrlInfoMutationVariables
> {
    document = DeleteSampleUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpsertSampleUrlInfoDocument = gql`
    mutation UpsertSampleUrlInfo(
        $sampleUrlInfo: [sample_url_insert_input!] = {}
    ) {
        insert_sample_url(
            objects: $sampleUrlInfo
            on_conflict: {
                constraint: sample_url_pkey
                update_columns: [label, url]
            }
        ) {
            returning {
                id
                sampleBcdId
                label
                url
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpsertSampleUrlInfoGQL extends Apollo.Mutation<
    UpsertSampleUrlInfoMutation,
    UpsertSampleUrlInfoMutationVariables
> {
    document = UpsertSampleUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SampleFullTextSearchDocument = gql`
    query SampleFullTextSearch(
        $first: Int
        $searchString: String
        $bcdIdSearchString: String
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
        $sortType: String
        $sortDir: String
    ) {
        samplesFullTextSearch(
            limit: $first
            searchString: $searchString
            bcdIdSearchString: $bcdIdSearchString
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
            sortType: $sortType
            sortDir: $sortDir
        ) {
            ...SampleFields
        }
    }
    ${SampleFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class SampleFullTextSearchGQL extends Apollo.Query<
    SampleFullTextSearchQuery,
    SampleFullTextSearchQueryVariables
> {
    document = SampleFullTextSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CountSampleFullTextSearchDocument = gql`
    query CountSampleFullTextSearch(
        $searchString: String
        $bcdIdSearchString: String
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
    ) {
        countSamplesFullTextSearch(
            searchString: $searchString
            bcdIdSearchString: $bcdIdSearchString
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
        )
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CountSampleFullTextSearchGQL extends Apollo.Query<
    CountSampleFullTextSearchQuery,
    CountSampleFullTextSearchQueryVariables
> {
    document = CountSampleFullTextSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesByBcdIdSearchDocument = gql`
    query SamplesByBcdIdSearch(
        $first: Int
        $bcdIdSearchString: String
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
        $sortType: String
        $sortDir: String
    ) {
        samplesByBcdIdSearch(
            limit: $first
            bcdIdSearchString: $bcdIdSearchString
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
            sortType: $sortType
            sortDir: $sortDir
        ) {
            ...SampleFields
        }
    }
    ${SampleFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesByBcdIdSearchGQL extends Apollo.Query<
    SamplesByBcdIdSearchQuery,
    SamplesByBcdIdSearchQueryVariables
> {
    document = SamplesByBcdIdSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CountSamplesByBcdIdSearchDocument = gql`
    query CountSamplesByBcdIdSearch(
        $bcdIdSearchString: String
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
    ) {
        countSamplesByBcdIdSearch(
            bcdIdSearchString: $bcdIdSearchString
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
        )
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CountSamplesByBcdIdSearchGQL extends Apollo.Query<
    CountSamplesByBcdIdSearchQuery,
    CountSamplesByBcdIdSearchQueryVariables
> {
    document = CountSamplesByBcdIdSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesByTypeSearchDocument = gql`
    query SamplesByTypeSearch(
        $first: Int
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
        $sortType: String
        $sortDir: String
    ) {
        samplesByTypeSearch(
            limit: $first
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
            sortType: $sortType
            sortDir: $sortDir
        ) {
            ...SampleFields
        }
    }
    ${SampleFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesByTypeSearchGQL extends Apollo.Query<
    SamplesByTypeSearchQuery,
    SamplesByTypeSearchQueryVariables
> {
    document = SamplesByTypeSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CountSamplesByTypeSearchDocument = gql`
    query CountSamplesByTypeSearch(
        $dataPresent: [String]
        $dataSource: [String]
        $processInputTypes: [String]
        $processOutputTypes: [String]
    ) {
        countSamplesByTypeSearch(
            dataPresent: $dataPresent
            dataSource: $dataSource
            processInputTypes: $processInputTypes
            processOutputTypes: $processOutputTypes
        )
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CountSamplesByTypeSearchGQL extends Apollo.Query<
    CountSamplesByTypeSearchQuery,
    CountSamplesByTypeSearchQueryVariables
> {
    document = CountSamplesByTypeSearchDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyDetailDocument = gql`
    query StudyDetail($studyId: String!) {
        study_by_pk(studyId: $studyId) {
            ...StudyFields
        }
    }
    ${StudyFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class StudyDetailGQL extends Apollo.Query<
    StudyDetailQuery,
    StudyDetailQueryVariables
> {
    document = StudyDetailDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateStudyDocument = gql`
    mutation CreateStudy($study: study_insert_input!, $studyId: String) {
        insert_study_one(object: $study) {
            ...StudyFields
        }
        createStudy(studyId: $studyId) {
            __typename
        }
    }
    ${StudyFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class CreateStudyGQL extends Apollo.Mutation<
    CreateStudyMutation,
    CreateStudyMutationVariables
> {
    document = CreateStudyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const EditStudyDocument = gql`
    mutation EditStudy($studyId: String!, $study: study_set_input) {
        update_study_by_pk(pk_columns: { studyId: $studyId }, _set: $study) {
            ...StudyFields
        }
    }
    ${StudyFieldsFragmentDoc}
`;

@Injectable({
    providedIn: 'root',
})
export class EditStudyGQL extends Apollo.Mutation<
    EditStudyMutation,
    EditStudyMutationVariables
> {
    document = EditStudyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyDocument = gql`
    mutation DeleteStudy($studyId: String!) {
        delete_study_by_pk(studyId: $studyId) {
            studyId
        }
        deleteStudy(studyId: $studyId) {
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteStudyGQL extends Apollo.Mutation<
    DeleteStudyMutation,
    DeleteStudyMutationVariables
> {
    document = DeleteStudyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddStudyToProjectDocument = gql`
    mutation AddStudyToProject($studyId: String!, $projectId: String!) {
        insert_project_study_one(
            object: { studyId: $studyId, projectId: $projectId }
        ) {
            id
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AddStudyToProjectGQL extends Apollo.Mutation<
    AddStudyToProjectMutation,
    AddStudyToProjectMutationVariables
> {
    document = AddStudyToProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyFromProjectDocument = gql`
    mutation DeleteStudyFromProject($studyProjectId: Int!) {
        delete_project_study_by_pk(id: $studyProjectId) {
            id
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteStudyFromProjectGQL extends Apollo.Mutation<
    DeleteStudyFromProjectMutation,
    DeleteStudyFromProjectMutationVariables
> {
    document = DeleteStudyFromProjectDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteStudyUrlInfoDocument = gql`
    mutation DeleteStudyUrlInfo($ids: [Int!]) {
        delete_study_url(where: { id: { _in: $ids } }) {
            returning {
                id
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteStudyUrlInfoGQL extends Apollo.Mutation<
    DeleteStudyUrlInfoMutation,
    DeleteStudyUrlInfoMutationVariables
> {
    document = DeleteStudyUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpsertStudyUrlInfoDocument = gql`
    mutation UpsertStudyUrlInfo($studyUrlInfo: [study_url_insert_input!] = {}) {
        insert_study_url(
            objects: $studyUrlInfo
            on_conflict: {
                constraint: study_url_pkey
                update_columns: [label, url]
            }
        ) {
            returning {
                id
                studyId
                label
                url
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class UpsertStudyUrlInfoGQL extends Apollo.Mutation<
    UpsertStudyUrlInfoMutation,
    UpsertStudyUrlInfoMutationVariables
> {
    document = UpsertStudyUrlInfoDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudyListDocument = gql`
    query StudyList {
        study {
            studyId
            studyName
            startDate
            endDate
            objective
            notes
            trays {
                value
            }
            studyNodes {
                studyId
                numCrosses
                numTrays
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StudyListGQL extends Apollo.Query<
    StudyListQuery,
    StudyListQueryVariables
> {
    document = StudyListDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const AddPoolSourceDocument = gql`
    mutation AddPoolSource(
        $newPoolSourceId: String!
        $processId: String!
        $processOutputId: String!
        $processOutputType: String!
        $numDonors: String!
        $sex: String!
        $healthStatus: String!
    ) {
        addPoolSource(
            newPoolSourceId: $newPoolSourceId
            processId: $processId
            processOutputId: $processOutputId
            processOutputType: $processOutputType
            numDonors: $numDonors
            sex: $sex
            healthStatus: $healthStatus
        ) {
            bcdId
            name
            sourceId
            dateReceived
            metadata {
                value
                name
            }
            measurements {
                measurementType {
                    name
                }
                data {
                    name
                    value
                }
                observationDate
            }
            sourceSamples {
                bcdId
                name
                sourceId
                metadata {
                    value
                    name
                }
            }
            parentSamples {
                bcdId
                name
                sourceId
                childSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            childSamples {
                bcdId
                name
                sourceId
                parentSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            ancestorSamples {
                sourceId
                bcdId
                childSamples {
                    bcdId
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                    }
                }
                parentSamples {
                    bcdId
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                    }
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            outputByProcess {
                bcdId
                processType {
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class AddPoolSourceGQL extends Apollo.Mutation<
    AddPoolSourceMutation,
    AddPoolSourceMutationVariables
> {
    document = AddPoolSourceDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CalculateCurvesDocument = gql`
    query CalculateCurves($measurementsJson: String!) {
        calculateCurves(measurements: $measurementsJson) {
            calculated
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CalculateCurvesGQL extends Apollo.Query<
    CalculateCurvesQuery,
    CalculateCurvesQueryVariables
> {
    document = CalculateCurvesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateBcdIdDocument = gql`
    mutation CreateBcdId($n: Int = 1) {
        create_bcd_id(args: { num: $n }) {
            bcd_id
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateBcdIdGQL extends Apollo.Mutation<
    CreateBcdIdMutation,
    CreateBcdIdMutationVariables
> {
    document = CreateBcdIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateFastqMappingMeasurementDocument = gql`
    mutation CreateFastqMappingMeasurement(
        $sampleId: String
        $mappingFileKey: String
    ) {
        createFastqMappingMeasurement(
            sampleId: $sampleId
            mappingFileKey: $mappingFileKey
        ) {
            uuid
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateFastqMappingMeasurementGQL extends Apollo.Mutation<
    CreateFastqMappingMeasurementMutation,
    CreateFastqMappingMeasurementMutationVariables
> {
    document = CreateFastqMappingMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateFileTypeMeasurementDocument = gql`
    mutation CreateFileTypeMeasurement(
        $sampleId: String
        $measurementType: String
        $filePath: String
    ) {
        createFileTypeMeasurement(
            sampleId: $sampleId
            measurementType: $measurementType
            filePath: $filePath
        ) {
            uuid
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateFileTypeMeasurementGQL extends Apollo.Mutation<
    CreateFileTypeMeasurementMutation,
    CreateFileTypeMeasurementMutationVariables
> {
    document = CreateFileTypeMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateGrowthCurveMeasurementDocument = gql`
    mutation CreateGrowthCurveMeasurement(
        $trayName: String
        $replicate: String
        $values: String
    ) {
        createGrowthCurveMeasurement(
            trayName: $trayName
            replicate: $replicate
            values: $values
        ) {
            uuid
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateGrowthCurveMeasurementGQL extends Apollo.Mutation<
    CreateGrowthCurveMeasurementMutation,
    CreateGrowthCurveMeasurementMutationVariables
> {
    document = CreateGrowthCurveMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateInteractionProcessAndSampleSingleParentDocument = gql`
    mutation CreateInteractionProcessAndSampleSingleParent(
        $processId: String
        $sampleId: String
        $parentId: String
    ) {
        createInteractionProcessAndSampleSingleParent(
            processId: $processId
            sampleId: $sampleId
            parentId: $parentId
        ) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateInteractionProcessAndSampleSingleParentGQL extends Apollo.Mutation<
    CreateInteractionProcessAndSampleSingleParentMutation,
    CreateInteractionProcessAndSampleSingleParentMutationVariables
> {
    document = CreateInteractionProcessAndSampleSingleParentDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateInteractionProcessAndSampleDocument = gql`
    mutation CreateInteractionProcessAndSample(
        $processId: String
        $sampleId: String
        $parent1Id: String
        $parent2Id: String
    ) {
        createInteractionProcessAndSample(
            processId: $processId
            sampleId: $sampleId
            parent1Id: $parent1Id
            parent2Id: $parent2Id
        ) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateInteractionProcessAndSampleGQL extends Apollo.Mutation<
    CreateInteractionProcessAndSampleMutation,
    CreateInteractionProcessAndSampleMutationVariables
> {
    document = CreateInteractionProcessAndSampleDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProcessAndSampleDocument = gql`
    mutation CreateProcessAndSample(
        $fromId: String
        $id: String
        $processType: String
        $descriptiveName: String
        $sourceSex: String
        $sourceHealthStatus: String
    ) {
        createProcessAndSample(
            fromId: $fromId
            id: $id
            processType: $processType
            descriptiveName: $descriptiveName
            sourceSex: $sourceSex
            sourceHealthStatus: $sourceHealthStatus
        ) {
            bcdId
            name
            sourceId
            dateReceived
            metadata {
                value
                name
            }
            measurements {
                measurementType {
                    name
                }
                data {
                    name
                    value
                }
                observationDate
            }
            sourceSamples {
                bcdId
                name
                sourceId
                metadata {
                    value
                    name
                }
            }
            parentSamples {
                bcdId
                name
                sourceId
                childSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            childSamples {
                bcdId
                name
                sourceId
                parentSamples {
                    bcdId
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            ancestorSamples {
                sourceId
                bcdId
                childSamples {
                    bcdId
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                    }
                }
                parentSamples {
                    bcdId
                    outputByProcess {
                        bcdId
                        processType {
                            name
                        }
                    }
                }
                outputByProcess {
                    bcdId
                    processType {
                        name
                    }
                }
            }
            outputByProcess {
                bcdId
                processType {
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateProcessAndSampleGQL extends Apollo.Mutation<
    CreateProcessAndSampleMutation,
    CreateProcessAndSampleMutationVariables
> {
    document = CreateProcessAndSampleDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSampleLinkageMeasurementDocument = gql`
    mutation createSampleLinkageMeasurement(
        $bcdId: String
        $observationDt: UdbDateTimeInput
        $measurementData: [LinkageVariableInput]
        $sopVersion: String
    ) {
        createSampleLinkageMeasurement(
            bcdId: $bcdId
            observationDt: $observationDt
            measurementData: $measurementData
            sopVersion: $sopVersion
        ) {
            bcdId
            measurements {
                observationDate
                sopVersion
                measurementType {
                    name
                }
                data {
                    value
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateSampleLinkageMeasurementGQL extends Apollo.Mutation<
    CreateSampleLinkageMeasurementMutation,
    CreateSampleLinkageMeasurementMutationVariables
> {
    document = CreateSampleLinkageMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSampleMetabAnalytesMeasurementDocument = gql`
    mutation CreateSampleMetabAnalytesMeasurement(
        $bcdId: String
        $observationDt: UdbDateTimeInput
        $measurementData: MeasurementDataMetabAnalytesInput
        $replicate: String
    ) {
        createSampleMetabAnalytesMeasurement(
            bcdId: $bcdId
            observationDt: $observationDt
            measurementData: $measurementData
            replicate: $replicate
        ) {
            bcdId
            measurements {
                observationDate
                measurementType {
                    name
                }
                data {
                    value
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateSampleMetabAnalytesMeasurementGQL extends Apollo.Mutation<
    CreateSampleMetabAnalytesMeasurementMutation,
    CreateSampleMetabAnalytesMeasurementMutationVariables
> {
    document = CreateSampleMetabAnalytesMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateSampleMonoOrFreeMonoMeasurementDocument = gql`
    mutation CreateSampleMonoOrFreeMonoMeasurement(
        $bcdId: String
        $type: String
        $observationDt: UdbDateTimeInput
        $measurementData: MeasurementDataMonoInput
        $monoMethodType: String
        $sopVersion: String
    ) {
        createSampleMonoOrFreeMonoMeasurement(
            bcdId: $bcdId
            type: $type
            observationDt: $observationDt
            measurementData: $measurementData
            monoMethodType: $monoMethodType
            sopVersion: $sopVersion
        ) {
            bcdId
            measurements {
                observationDate
                monoMethodType
                sopVersion
                measurementType {
                    name
                }
                data {
                    value
                    name
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateSampleMonoOrFreeMonoMeasurementGQL extends Apollo.Mutation<
    CreateSampleMonoOrFreeMonoMeasurementMutation,
    CreateSampleMonoOrFreeMonoMeasurementMutationVariables
> {
    document = CreateSampleMonoOrFreeMonoMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateFecalSourceSampleDocument = gql`
    mutation CreateFecalSourceSample(
        $bcdId: String
        $donorNumber: String
        $collectionDate: String
        $expirationDate: String
        $storageLocation: String
        $ndaMta: String
        $unitId: String
        $sex: String
        $type: String
        $healthStatus: String
        $aliquotesLeft: String
        $appearance: String
        $notes: String
    ) {
        createFecalSourceSample(
            bcdId: $bcdId
            donorNumber: $donorNumber
            collectionDate: $collectionDate
            expirationDate: $expirationDate
            storageLocation: $storageLocation
            ndaMta: $ndaMta
            unitId: $unitId
            sex: $sex
            type: $type
            healthStatus: $healthStatus
            aliquotesLeft: $aliquotesLeft
            appearance: $appearance
            notes: $notes
        ) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class CreateFecalSourceSampleGQL extends Apollo.Mutation<
    CreateFecalSourceSampleMutation,
    CreateFecalSourceSampleMutationVariables
> {
    document = CreateFecalSourceSampleDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMeasurementDocument = gql`
    mutation DeleteMeasurement($measurementUuid: String!) {
        deleteMeasurement(measurementUuid: $measurementUuid) {
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteMeasurementGQL extends Apollo.Mutation<
    DeleteMeasurementMutation,
    DeleteMeasurementMutationVariables
> {
    document = DeleteMeasurementDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteSampleAndDescendantsDocument = gql`
    mutation DeleteSampleAndDescendants(
        $sampleId: String!
        $isSourceSample: Boolean!
    ) {
        deleteSampleAndDescendants(
            sampleId: $sampleId
            isSourceSample: $isSourceSample
        ) {
            __typename
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class DeleteSampleAndDescendantsGQL extends Apollo.Mutation<
    DeleteSampleAndDescendantsMutation,
    DeleteSampleAndDescendantsMutationVariables
> {
    document = DeleteSampleAndDescendantsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const InsertImportFileOneDocument = gql`
    mutation InsertImportFileOne(
        $datafileType: String
        $originalFilename: String
        $archiveFilepath: String
    ) {
        insert_import_file_one(
            object: {
                datafile_type: $datafileType
                original_filename: $originalFilename
                archive_filepath: $archiveFilepath
            }
        ) {
            id
            datafile_type
            original_filename
            archive_filepath
            import_date
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class InsertImportFileOneGQL extends Apollo.Mutation<
    InsertImportFileOneMutation,
    InsertImportFileOneMutationVariables
> {
    document = InsertImportFileOneDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeCalculatedCurvesDocument = gql`
    mutation MergeCalculatedCurves(
        $trayName: String
        $replicate: String
        $calculatedValues: String
    ) {
        mergeCalculatedCurveValues(
            trayName: $trayName
            replicate: $replicate
            calculatedValues: $calculatedValues
        ) {
            uuid
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeCalculatedCurvesGQL extends Apollo.Mutation<
    MergeCalculatedCurvesMutation,
    MergeCalculatedCurvesMutationVariables
> {
    document = MergeCalculatedCurvesDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeProcessMetadataDocument = gql`
    mutation MergeProcessMetadata(
        $processId: String
        $metadataGroup: String
        $metadataName: String
        $metadataValue: String
    ) {
        mergeProcessMetadata(
            processId: $processId
            metadataGroup: $metadataGroup
            metadataName: $metadataName
            metadataValue: $metadataValue
        ) {
            bcdId
            metadata {
                metadata {
                    name
                }
                metadataGroup {
                    name
                }
                value
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeProcessMetadataGQL extends Apollo.Mutation<
    MergeProcessMetadataMutation,
    MergeProcessMetadataMutationVariables
> {
    document = MergeProcessMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeProcessReplicateMetadataDocument = gql`
    mutation MergeProcessReplicateMetadata(
        $processId: String
        $metadataGroup: String
        $metadataName: String
        $metadataValue: String
        $replicateName: String
    ) {
        mergeProcessReplicateMetadata(
            processId: $processId
            metadataGroup: $metadataGroup
            metadataName: $metadataName
            metadataValue: $metadataValue
            replicateName: $replicateName
        ) {
            bcdId
            metadata {
                metadata {
                    name
                }
                metadataGroup {
                    name
                }
                value
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeProcessReplicateMetadataGQL extends Apollo.Mutation<
    MergeProcessReplicateMetadataMutation,
    MergeProcessReplicateMetadataMutationVariables
> {
    document = MergeProcessReplicateMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeProcessStudyDocument = gql`
    mutation MergeProcessStudy(
        $processId: String
        $sampleId: String
        $studyId: String
    ) {
        mergeProcessToStudy(
            processId: $processId
            sampleId: $sampleId
            studyId: $studyId
        ) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeProcessStudyGQL extends Apollo.Mutation<
    MergeProcessStudyMutation,
    MergeProcessStudyMutationVariables
> {
    document = MergeProcessStudyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeSampleMetadataDocument = gql`
    mutation MergeSampleMetadata(
        $bcdId: String!
        $metadataName: String!
        $value: String!
    ) {
        mergeSampleMetadata(
            bcdId: $bcdId
            metadataName: $metadataName
            value: $value
        ) {
            bcdId
            metadata {
                name
                value
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeSampleMetadataGQL extends Apollo.Mutation<
    MergeSampleMetadataMutation,
    MergeSampleMetadataMutationVariables
> {
    document = MergeSampleMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MergeSourceSampleDocument = gql`
    mutation MergeSourceSample(
        $bcdId: String
        $name: String
        $dateReceived: String
        $type: String
    ) {
        mergeSourceSample(
            id: $bcdId
            name: $name
            dateReceived: $dateReceived
            type: $type
        ) {
            bcdId
            sourceId
            name
            dateReceived
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MergeSourceSampleGQL extends Apollo.Mutation<
    MergeSourceSampleMutation,
    MergeSourceSampleMutationVariables
> {
    document = MergeSourceSampleDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MetadataGroupDocument = gql`
    query MetadataGroup($name: String) {
        metadataGroups(where: { name: $name }) {
            name
            metadata {
                name
                units
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MetadataGroupGQL extends Apollo.Query<
    MetadataGroupQuery,
    MetadataGroupQueryVariables
> {
    document = MetadataGroupDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SampleBasicDocument = gql`
    query SampleBasic($bcdId: ID) {
        samples(where: { bcdId: $bcdId }) {
            bcdId
            name
            metadata {
                value
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SampleBasicGQL extends Apollo.Query<
    SampleBasicQuery,
    SampleBasicQueryVariables
> {
    document = SampleBasicDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SampleTypesMetadataDocument = gql`
    query SampleTypesMetadata {
        sampleTypes {
            name
            metadata {
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SampleTypesMetadataGQL extends Apollo.Query<
    SampleTypesMetadataQuery,
    SampleTypesMetadataQueryVariables
> {
    document = SampleTypesMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesByBcdIdDocument = gql`
    query SamplesByBcdId($bcdIds: [ID]) {
        samples(where: { bcdId_IN: $bcdIds }) {
            bcdId
            name
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesByBcdIdGQL extends Apollo.Query<
    SamplesByBcdIdQuery,
    SamplesByBcdIdQueryVariables
> {
    document = SamplesByBcdIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesByMappingFileKeyDocument = gql`
    query SamplesByMappingFileKey($mappingFileKey: String) {
        samplesByMappingFileKey(mappingFileKey: $mappingFileKey) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesByMappingFileKeyGQL extends Apollo.Query<
    SamplesByMappingFileKeyQuery,
    SamplesByMappingFileKeyQueryVariables
> {
    document = SamplesByMappingFileKeyDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesBySampleTypeDocument = gql`
    query SamplesBySampleType($sampleType: String) {
        samples(where: { sampleType: { name: $sampleType } }) {
            name
            metadata {
                name
                value
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesBySampleTypeGQL extends Apollo.Query<
    SamplesBySampleTypeQuery,
    SamplesBySampleTypeQueryVariables
> {
    document = SamplesBySampleTypeDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SamplesByTrayCellDocument = gql`
    query SamplesByTrayCell($trayName: String, $cellId: String) {
        samplesByTrayCell(trayName: $trayName, cellId: $cellId) {
            bcdId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SamplesByTrayCellGQL extends Apollo.Query<
    SamplesByTrayCellQuery,
    SamplesByTrayCellQueryVariables
> {
    document = SamplesByTrayCellDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SavePoolSourceMetadataDocument = gql`
    mutation SavePoolSourceMetadata(
        $sampleId: String
        $processId: String
        $sourceName: String
        $metadataName: String
        $value: String
    ) {
        savePoolSourceMetadata(
            sampleId: $sampleId
            processId: $processId
            sourceName: $sourceName
            metadataName: $metadataName
            metadataValue: $value
        ) {
            bcdId
            name
            sourceId
            poolInputMetadata {
                sourceName
                concentration
                ulAdded
            }
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class SavePoolSourceMetadataGQL extends Apollo.Mutation<
    SavePoolSourceMetadataMutation,
    SavePoolSourceMetadataMutationVariables
> {
    document = SavePoolSourceMetadataDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const StudiesByStudyIdDocument = gql`
    query StudiesByStudyId($studyIds: [ID]) {
        studyNodes(where: { studyId_IN: $studyIds }) {
            studyId
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class StudiesByStudyIdGQL extends Apollo.Query<
    StudiesByStudyIdQuery,
    StudiesByStudyIdQueryVariables
> {
    document = StudiesByStudyIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
