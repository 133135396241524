import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'bcdbio-search-pagination',
    templateUrl: './search-pagination.component.html',
    styleUrls: ['./search-pagination.component.scss'],
})
export class SearchPaginationComponent implements OnInit {
    requestedMoreSearchResults: boolean = true;
    @Output() getMoreResultsEvent = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    getMoreResults() {
        this.getMoreResultsEvent.emit(this.requestedMoreSearchResults);
    }
}
