import { Observable } from 'rxjs';
import { DataImportValidatorBio } from './data-import-validator-bio';
import { map } from 'rxjs/operators';
import { SamplesBySampleTypeGQL } from '@bcdbio/udb-graphql';
import { Injectable } from '@angular/core';
import { DataImportData, DataImportType } from '@bcdbio/data';

@Injectable({
    providedIn: 'root',
})
export class DataImportValidatorSingleStrain extends DataImportValidatorBio {
    constructor(private samplesBySampleTypeGQL: SamplesBySampleTypeGQL) {
        super();
    }

    private static collectStrainNames(
        headerRow: string[],
        dataRows: Array<string[]>
    ): string[] {
        const strainNames: Set<string> = new Set<string>();
        const strainIdx = headerRow.indexOf('strain');
        for (let i = 0, n = dataRows.length; i < n; i++) {
            const strain = dataRows[i][strainIdx];
            strainNames.add(strain.trim());
        }
        return Array.from(strainNames);
    }

    private static parseOtherStrainNames(
        otherStrainNameMetadata: string
    ): string[] {
        return otherStrainNameMetadata.split(/[,;]/);
    }

    private static collectOtherStrainNames(
        headerRow: string[],
        dataRows: Array<string[]>
    ): string[] {
        const otherStrainNames: Set<string> = new Set<string>();
        const otherStrainNamesIdx = headerRow.indexOf('Other Strain Names');

        for (let i = 0, n = dataRows.length; i < n; i++) {
            const otherStrains = dataRows[i][otherStrainNamesIdx];
            this.parseOtherStrainNames(otherStrains).forEach((strainName) => {
                otherStrainNames.add(strainName.trim());
            });
        }
        return Array.from(otherStrainNames);
    }

    private static findDuplicates(list: string[]): string[] {
        const lowercaseList = list.map((item) => item.toLowerCase());
        const toFindDuplicates = (arr) =>
            arr.filter((item, index) => arr.indexOf(item) !== index);
        return toFindDuplicates(lowercaseList);
    }

    private validateStrainNames(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): Observable<boolean> {
        const strainNames: string[] =
            DataImportValidatorSingleStrain.collectStrainNames(
                headerRow,
                dataRows
            );
        const otherStrainNames: string[] =
            DataImportValidatorSingleStrain.collectOtherStrainNames(
                headerRow,
                dataRows
            );

        const duplicateStrainNamesInFile =
            DataImportValidatorSingleStrain.findDuplicates(strainNames);
        const duplicateOtherStrainNamesInFile =
            DataImportValidatorSingleStrain.findDuplicates(otherStrainNames);
        duplicateStrainNamesInFile.forEach((dsn) => {
            errors.push(`Import file has duplicate Strain name: ${dsn}`);
        });
        duplicateOtherStrainNamesInFile.forEach((dosn) => {
            errors.push(`Import file has duplicate Other Strain Name: ${dosn}`);
        });

        const lowercaseStrainNames = strainNames.map((item) =>
            item.toLowerCase()
        );
        otherStrainNames.forEach((osn) => {
            if (lowercaseStrainNames.includes(osn.toLowerCase())) {
                errors.push(
                    `Import file references ${osn} as both a Strain and Other Strain Name.`
                );
            }
        });

        return this.samplesBySampleTypeGQL
            .fetch({ sampleType: 'Single Strain' })
            .pipe(
                map((result) => {
                    const indexedStrains: Set<string> = new Set<string>();
                    const indexedOtherStrainNames: Set<string> =
                        new Set<string>();
                    result.data.samples.forEach((s) => {
                        const md = s.metadata;
                        const strain =
                            md.find((md) => md.name === 'strain')?.value || '';
                        if (indexedStrains.has(strain.toLowerCase())) {
                            console.log(
                                'Database contains duplicate strain: ',
                                strain
                            );
                        } else if (strain) {
                            indexedStrains.add(strain.toLowerCase());
                        }

                        const otherStrainNameMD =
                            md.find((md) => md.name === 'otherStrainNames')
                                ?.value || '';
                        const otherStrainNames =
                            DataImportValidatorSingleStrain.parseOtherStrainNames(
                                otherStrainNameMD
                            );
                        otherStrainNames.forEach((osn) => {
                            const osnLowerCase = osn.toLowerCase();
                            if (indexedOtherStrainNames.has(osnLowerCase)) {
                                console.log(
                                    'Database contains duplicate Other Strain Name: ',
                                    osn
                                );
                            } else if (osn) {
                                indexedOtherStrainNames.add(osnLowerCase);
                            }

                            if (indexedStrains.has(osnLowerCase)) {
                                console.log(
                                    'Database has ',
                                    osn,
                                    ' as both a Single Strain and an Other Strain Name'
                                );
                            }
                        });
                    });
                    strainNames.forEach((name) => {
                        if (indexedStrains.has(name.toLowerCase())) {
                            errors.push(
                                `Import attempted to add Strain ${name} that already exists in database as a Single Strain.`
                            );
                        }
                    });
                    otherStrainNames.forEach((name) => {
                        if (indexedStrains.has(name.toLowerCase())) {
                            errors.push(
                                `Import attempted to add Other Strain Name ${name} that already exists in database as a Single Strain.`
                            );
                        }
                    });
                    return true;
                })
            );
    }

    protected validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): Observable<boolean> {
        super.validateDataRows(headerRow, dataRows, errors);
        return this.validateStrainNames(headerRow, dataRows, errors);
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        this.mappingInfo = data;

        const errors = [];
        const headerRow = data.getHeaderRow();
        const rows = data.getDataRows();
        this.validateHeaders(headerRow, errors);
        return this.validateDataRows(headerRow, rows, errors).pipe(
            map((done) => {
                return {
                    isValid: errors.length < 1,
                    errors: errors,
                };
            })
        );
    }
}
