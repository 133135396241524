import { DataImportData } from './data-import-data';

export abstract class DataImportDataTabular extends DataImportData {
    public getHeaderRow(): string[] {
        if (this.data && this.data.length > 1) {
            return this.data[1].map((cellValue, idx) => {
                // make sure to trim header - some have \t chars at the end
                let headerName = cellValue.trim();

                // combine first header row with second to label concentrations/response cols
                //  - if col header is 'Final Conc.' or 'Resp.' then
                //  append row 0 header value to header (colidx-1 for Resp)
                if (headerName === 'Final Conc.') {
                    headerName = this.data[0][idx].trim() + '|' + headerName;
                } else if (headerName === 'Resp.') {
                    headerName =
                        this.data[0][idx - 1].trim() + '|' + headerName;
                }

                return headerName;
            });
        } else {
            return null;
        }
    }

    public getDataRows(): Array<string[]> {
        if (this.data && this.data.length > 0) {
            return (
                this.data
                    .slice(2)
                    // filter out rows for QC or calibration -- 'Test QC', start with 'QC', Type = Cal
                    .filter((row) => {
                        const name = row[this.headers.indexOf('Name')];
                        return (
                            name &&
                            name !== 'Test QC' &&
                            !name.endsWith(' STD') &&
                            !name.startsWith('QC ')
                        );
                    })
                    // fill blank cells with 'null'
                    .map((row) => {
                        return row.map((val) => (val === '' ? 'null' : val));
                    })
            );
        } else {
            return [];
        }
    }
}
