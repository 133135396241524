import { GenericDataset } from './generic-dataset.model';
import { DataRow } from './data-row.model';

export class LinkageDataset extends GenericDataset {
    defaultColumnOrder = [
        'T-Glucose',
        'T-Glucose-f',
        'T-Glucose-p',
        '6-Glucose',
        '4-Glucose',
        '3-Glucose',
        '2-Glucose',
        '2,X-Glucose a',
        'X,X-Glucose (I)',
        'X,X-Glucose (II)',
        '2,X-Glucose (I)',
        '3,4,6-Hexose (I)',
        '3,4,6-Hexose (II)',
        '3,4,6-Hexose (III)',
        '3,4,6-Hexose (IV)',
        '3,4,6-Hexose (V)',
        '3,4,6-Glucose',
        '3,6-Glucose',
        'T-Galactose',
        '6-Galactose',
        '4-Galactose',
        '3-Galactose',
        '2-Galactose',
        '3,4,6-Galactose',
        'T-Fructose',
        'T-F-Xylose',
        'T-P-Xylose',
        '4-P-Xylose',
        '2-P-Xylose',
        '3,4-P-Xylose',
        'T-F-Arabinose',
        'T-P-Arabinose',
        '5-F-Arabinose',
        '3-P-Arabinose',
        '2-F-Arabinose',
        '3,4-F-Arabinose',
        '3,4-P-Arabinose',
        '2,3,5-Arabinose',
        '2,5-Arabinose',
        'T-Fucose',
        '2-Fucose',
        '3,4-Fucose',
        'T-Rhamnose',
        '2-Rhamnose',
        '3,4-Rhamnose',
        '2-Glucuronic Acid',
        '3,4-Glucuronic Acid',
        '3,4-Galacturonic Acid',
        'T-Mannose',
        '6-Mannose',
        '4-Mannose',
        '3-Mannose',
        '2-Mannose',
        '3,6-Mannose',
        '3,4,6-Mannose',
        'T-Allose',
        '2-Allose',
        '3,4,6-Allose',
        'T-Ribose',
        '2-Ribose',
        '3,4-Ribose',
        'X-Hexose',
        'X-Hexose (I)',
        'X-Hexose (II)',
        'X,X-Hexose (I)',
        'X,X-Hexose (II)',
        'X,X-Hexose (III)',
        '2,X-Hexose (I)',
        '2,X-Hexose (II)',
        '2,X-Hexose (III)',
        '2,X-Hexose (IV)',
        '2,X-Hexose (V)',
        '2,X,X-Hexose (I)',
        '2,X,X-Hexose (II)',
        '2,X,X-Hexose (III)',
        'X-Pentose (I)',
        'X-Pentose (II)',
        'X-Pentose (III)',
        '2,X-Pentose (I)',
        '2,X-Pentose (II)',
        '2,X-Pentose (III)',
        '2,X-Pentose (IV)',
        '2,X-Pentose (V)',
        '2,X-Pentose (VI)',
        'X-Deoxyhexose (I)',
        'X-Deoxyhexose (II)',
        'X-Deoxyhexose (III)',
        'X-Deoxyhexose (IV)',
        'X-Deoxyhexose (V)',
        'X-Deoxyhexose (VI)',
        'X-Deoxyhexose (VII)',
        'X-Deoxyhexose (VIII)',
        '2-Deoxyhexose',
        '2,X-Deoxyhexose',
        'T-Hexuronic Acid (I)',
        'T-Hexuronic Acid (II)',
        'X-Hexuronic (I)',
        'X-Hexuronic (II)',
        'X-Hexuronic (III)',
        'X-Hexuronic (IV)',
        'X-Hexuronic (V)',
        '2-Pentose a',
        '2-Pentose b',
        '2-Pentose c',
        '2-Pentose d',
        '2-Pentose e',
        '2-Pentose f',
        '2-Pentose g',
        '2-Pentose h',
        '2-Pentose i',
        '2-Pentose j',
        '2-Pentose k',
        '2-Pentose l',
        '2-Pentose m',
        '2-Pentose n',
        '2-Pentose o',
        '2-Pentose p',
        '2-Xylose',
        '2,3,4-Xylose',
        '2,4-Xylose',
        '2,X-Hexose a',
        '2,X-Hexose b',
        '2,X-Hexose c',
        '2,X-Hexose d',
        '2,X-Hexose e',
        '2,X-Hexose f',
        '2,X-Hexose g',
        '2,X-Hexose h',
        '2,X-Hexose i',
        '2,X-Hexose j',
        '2,X-Hexose k',
        '2,X-Hexose l',
        '2,X-Hexose m',
        '2,X-Hexose n',
        '2,X-Hexose o',
        '2,X-Hexose p',
        '2,X-Pentose a',
        '2,X-Pentose b',
        '2,X-Pentose c',
        '2,X-Pentose d',
        '2,X-Pentose e',
        '2,X-Pentose f',
        '2,X-Pentose g',
        '2,X-Pentose h',
        '2,X-Pentose i',
        '2,X-Pentose j',
        '2,X-Pentose k',
        '2,X-Pentose l',
        '2,X-Pentose m',
        '2,X-Pentose n',
        '2,X-Pentose o',
        '2,X-Pentose p',
        '2,X,X-Hexose a',
        '2,X,X-Hexose b',
        '2,X,X-Hexose c',
        '2,X,X-Hexose d',
        '2,X,X-Hexose e',
        '2,X,X-Hexose f',
        '2,X,X-Hexose g',
        '2,X,X-Hexose h',
        '2,X,X-Hexose i',
        '2,X,X-Hexose j',
        '2,X,X-Hexose k',
        '2,X,X-Hexose l',
        '2,X,X-Hexose m',
        '2,X,X-Hexose n',
        '2,X,X-Hexose o',
        '2,X,X-Hexose p',
        '2,X,X-Rhamnose',
        '3,4-Galactose',
        '3,4-Galactose/3,6-Mannose',
        '3,4-Xylose',
        '3,4-Xylose/3,5-Arabinose',
        '3,5-Arabinose',
        '3,6-Galactose',
        '3/4-Mannose',
        '4-GlcA',
        '4-Rhamnose',
        '4-Xylose',
        '4-Xylose/5-Arabinose',
        '4,6-Glucose',
        '4,6-Mannose',
        '5-Arabinose',
        'T-Arabinose-f',
        'T-Arabinose-f/4-Rhamnose',
        'T-Arabinose-p',
        'T-Fucose a',
        'T-Fucose a/T-Rhamnose',
        'T-Fucose b',
        'T-GalA a',
        'T-GalA b',
        'T-Galactose-f',
        'T-Galactose-p',
        'T-GlcA',
        'T-Glucose/T-Mannose',
        'T-Ribose a',
        'T-Ribose a/T-Arabinose-p',
        'T-Ribose b',
        'T-Xylose',
        'X-Fucose a',
        'X-Hexose a',
        'X-Hexose b',
        'X-Hexose c',
        'X-Hexose d',
        'X-Hexose e',
        'X-Hexose f',
        'X-Hexose g',
        'X-Hexose h',
        'X-Hexose i',
        'X-Hexose j',
        'X-Hexose k',
        'X-Hexose l',
        'X-Hexose m',
        'X-Hexose n',
        'X-Hexose o',
        'X-Hexose p',
        'X-Pentose a',
        'X-Pentose b',
        'X-Pentose c',
        'X-Pentose d',
        'X-Pentose e',
        'X-Pentose f',
        'X-Pentose g',
        'X-Pentose h',
        'X-Pentose i',
        'X-Pentose j',
        'X-Pentose k',
        'X-Pentose l',
        'X-Pentose m',
        'X-Pentose n',
        'X-Pentose o',
        'X-Pentose p',
        'X,X-Hexose a',
        'X,X-Hexose b',
        'X,X-Hexose c',
        'X,X-Hexose d',
        'X,X-Hexose e',
        'X,X-Hexose f',
        'X,X-Hexose g',
        'X,X-Hexose h',
        'X,X-Hexose i',
        'X,X-Hexose j',
        'X,X-Hexose k',
        'X,X-Hexose l',
        'X,X-Hexose m',
        'X,X-Hexose n',
        'X,X-Hexose o',
        'X,X-Hexose p',
        'X,X-Pentose a',
        'X,X-Pentose b',
        'X,X-Pentose c',
        'X,X-Pentose d',
        'X,X-Pentose e',
        'X,X-Pentose f',
        'X,X-Pentose g',
        'X,X-Pentose h',
        'X,X-Pentose i',
        'X,X-Pentose j',
        'X,X-Pentose k',
        'X,X-Pentose l',
        'X,X-Pentose m',
        'X,X-Pentose n',
        'X,X-Pentose o',
        'X,X-Pentose p',
        'X,X,X-Hexose a',
        'X,X,X-Hexose b',
        'X,X,X-Hexose c',
        'X,X,X-Hexose d',
        'X,X,X-Hexose e',
        'X,X,X-Hexose f',
        'X,X,X-Hexose g',
        'X,X,X-Hexose h',
        'X,X,X-Hexose i',
        'X,X,X-Hexose j',
        'X,X,X-Hexose k',
        'X,X,X-Hexose l',
        'X,X,X-Hexose m',
        'X,X,X-Hexose n',
        'X,X,X-Hexose o',
        'X,X,X-Hexose p',
        'X,X,X-Pentose a',
        'X,X,X-Pentose b',
        'X,X,X-Pentose c',
        'X,X,X-Pentose d',
        'X,X,X-Pentose e',
        'X,X,X-Pentose f',
        'X,X,X-Pentose g',
        'X,X,X-Pentose h',
        'X,X,X-Pentose i',
        'X,X,X-Pentose j',
        'X,X,X-Pentose k',
        'X,X,X-Pentose l',
        'X,X,X-Pentose m',
        'X,X,X-Pentose n',
        'X,X,X-Pentose o',
        'X,X,X-Pentose p',
    ];

    GQL_INPUT_MAP = {
        'T-Glucose': 'vTGlucose',
        'T-Glucose-f': 'vTGlucoseF',
        'T-Glucose-p': 'vTGlucoseP',
        '6-Glucose': 'v6Glucose',
        '4-Glucose': 'v4Glucose',
        '3-Glucose': 'v3Glucose',
        '2-Glucose': 'v2Glucose',
        '2,X-Glucose a': 'v2XGlucoseA',
        'X,X-Glucose (I)': 'vXXGlucoseI',
        'X,X-Glucose (II)': 'vXXGlucoseII',
        '2,X-Glucose (I)': 'v2XGlucoseI',
        '3,4,6-Hexose (I)': 'v346HexoseI',
        '3,4,6-Hexose (II)': 'v346HexoseII',
        '3,4,6-Hexose (III)': 'v346HexoseIII',
        '3,4,6-Hexose (IV)': 'v346HexoseIV',
        '3,4,6-Hexose (V)': 'v346HexoseV',
        '3,4,6-Glucose': 'v346Glucose',
        '3,6-Glucose': 'v36Glucose',
        'T-Galactose': 'vTGalactose',
        '6-Galactose': 'v6Galactose',
        '4-Galactose': 'v4Galactose',
        '3-Galactose': 'v3Galactose',
        '2-Galactose': 'v2Galactose',
        '3,4,6-Galactose': 'v346Galactose',
        'T-Fructose': 'vTFructose',
        'T-F-Xylose': 'vTFXylose',
        'T-P-Xylose': 'vTPXylose',
        '4-P-Xylose': 'v4PXylose',
        '2-P-Xylose': 'v2PXylose',
        '3,4-P-Xylose': 'v34PXylose',
        'T-F-Arabinose': 'vTFArabinose',
        'T-P-Arabinose': 'vTPArabinose',
        '5-F-Arabinose': 'v5FArabinose',
        '3-P-Arabinose': 'v3PArabinose',
        '2-F-Arabinose': 'v2FArabinose',
        '3,4-F-Arabinose': 'v34FArabinose',
        '3,4-P-Arabinose': 'v34PArabinose',
        '2,3,5-Arabinose': 'v235Arabinose',
        '2,5-Arabinose': 'v25Arabinose',
        'T-Fucose': 'vTFucose',
        '2-Fucose': 'v2Fucose',
        '3,4-Fucose': 'v34Fucose',
        'T-Rhamnose': 'vTRhamnose',
        '2-Rhamnose': 'v2Rhamnose',
        '3,4-Rhamnose': 'v34Rhamnose',
        '2-Glucuronic Acid': 'v2GlucuronicAcid',
        '3,4-Glucuronic Acid': 'v34GlucuronicAcid',
        '3,4-Galacturonic Acid': 'v34GalacturonicAcid',
        'T-Mannose': 'vTMannose',
        '6-Mannose': 'v6Mannose',
        '4-Mannose': 'v4Mannose',
        '3-Mannose': 'v3Mannose',
        '2-Mannose': 'v2Mannose',
        '3,6-Mannose': 'v36Mannose',
        '3,4,6-Mannose': 'v346Mannose',
        'T-Allose': 'vTAllose',
        '2-Allose': 'v2Allose',
        '3,4,6-Allose': 'v346Allose',
        'T-Ribose': 'vTRibose',
        '2-Ribose': 'v2Ribose',
        '3,4-Ribose': 'v34Ribose',
        'X-Hexose': 'vXHexose',
        'X-Hexose (I)': 'vXHexoseI',
        'X-Hexose (II)': 'vXHexoseII',
        'X,X-Hexose (I)': 'vXXHexoseI',
        'X,X-Hexose (II)': 'vXXHexoseII',
        'X,X-Hexose (III)': 'vXXHexoseIII',
        '2,X-Hexose (I)': 'v2XHexoseI',
        '2,X-Hexose (II)': 'v2XHexoseII',
        '2,X-Hexose (III)': 'v2XHexoseIII',
        '2,X-Hexose (IV)': 'v2XHexoseIV',
        '2,X-Hexose (V)': 'v2XHexoseV',
        '2,X,X-Hexose (I)': 'v2XXHexoseI',
        '2,X,X-Hexose (II)': 'v2XXHexoseII',
        '2,X,X-Hexose (III)': 'v2XXHexoseIII',
        'X-Pentose (I)': 'vXPentoseI',
        'X-Pentose (II)': 'vXPentoseII',
        'X-Pentose (III)': 'vXPentoseIII',
        '2,X-Pentose (I)': 'v2XPentoseI',
        '2,X-Pentose (II)': 'v2XPentoseII',
        '2,X-Pentose (III)': 'v2XPentoseIII',
        '2,X-Pentose (IV)': 'v2XPentoseIV',
        '2,X-Pentose (V)': 'v2XPentoseV',
        '2,X-Pentose (VI)': 'v2XPentoseVI',
        'X-Deoxyhexose (I)': 'vXDeoxyhexoseI',
        'X-Deoxyhexose (II)': 'vXDeoxyhexoseII',
        'X-Deoxyhexose (III)': 'vXDeoxyhexoseIII',
        'X-Deoxyhexose (IV)': 'vXDeoxyhexoseIV',
        'X-Deoxyhexose (V)': 'vXDeoxyhexoseV',
        'X-Deoxyhexose (VI)': 'vXDeoxyhexoseVI',
        'X-Deoxyhexose (VII)': 'vXDeoxyhexoseVII',
        'X-Deoxyhexose (VIII)': 'vXDeoxyhexoseVIII',
        '2-Deoxyhexose': 'v2Deoxyhexose',
        '2,X-Deoxyhexose': 'v2XDeoxyhexose',
        'T-Hexuronic Acid (I)': 'vTHexuronicAcidI',
        'T-Hexuronic Acid (II)': 'vTHexuronicAcidII',
        'X-Hexuronic (I)': 'vXHexuronicI',
        'X-Hexuronic (II)': 'vXHexuronicII',
        'X-Hexuronic (III)': 'vXHexuronicIII',
        'X-Hexuronic (IV)': 'vXHexuronicIV',
        'X-Hexuronic (V)': 'vXHexuronicV',
        '2-Pentose a': 'v2PentoseA',
        '2-Pentose b': 'v2PentoseB',
        '2-Pentose c': 'v2PentoseC',
        '2-Pentose d': 'v2PentoseD',
        '2-Pentose e': 'v2PentoseE',
        '2-Pentose f': 'v2PentoseF',
        '2-Pentose g': 'v2PentoseG',
        '2-Pentose h': 'v2PentoseH',
        '2-Pentose i': 'v2PentoseI',
        '2-Pentose j': 'v2PentoseJ',
        '2-Pentose k': 'v2PentoseK',
        '2-Pentose l': 'v2PentoseL',
        '2-Pentose m': 'v2PentoseM',
        '2-Pentose n': 'v2PentoseN',
        '2-Pentose o': 'v2PentoseO',
        '2-Pentose p': 'v2PentoseP',
        '2-Xylose': 'v2Xylose',
        '2,3,4-Xylose': 'v234Xylose',
        '2,4-Xylose': 'v24Xylose',
        '2,X-Hexose a': 'v2XHexoseA',
        '2,X-Hexose b': 'v2XHexoseB',
        '2,X-Hexose c': 'v2XHexoseC',
        '2,X-Hexose d': 'v2XHexoseD',
        '2,X-Hexose e': 'v2XHexoseE',
        '2,X-Hexose f': 'v2XHexoseF',
        '2,X-Hexose g': 'v2XHexoseG',
        '2,X-Hexose h': 'v2XHexoseH',
        '2,X-Hexose i': 'v2XHexosei',
        '2,X-Hexose j': 'v2XHexoseJ',
        '2,X-Hexose k': 'v2XHexoseK',
        '2,X-Hexose l': 'v2XHexoseL',
        '2,X-Hexose m': 'v2XHexoseM',
        '2,X-Hexose n': 'v2XHexoseN',
        '2,X-Hexose o': 'v2XHexoseO',
        '2,X-Hexose p': 'v2XHexoseP',
        '2,X-Pentose a': 'v2XPentoseA',
        '2,X-Pentose b': 'v2XPentoseB',
        '2,X-Pentose c': 'v2XPentoseC',
        '2,X-Pentose d': 'v2XPentoseD',
        '2,X-Pentose e': 'v2XPentoseE',
        '2,X-Pentose f': 'v2XPentoseF',
        '2,X-Pentose g': 'v2XPentoseG',
        '2,X-Pentose h': 'v2XPentoseH',
        '2,X-Pentose i': 'v2XPentosei',
        '2,X-Pentose j': 'v2XPentoseJ',
        '2,X-Pentose k': 'v2XPentoseK',
        '2,X-Pentose l': 'v2XPentoseL',
        '2,X-Pentose m': 'v2XPentoseM',
        '2,X-Pentose n': 'v2XPentoseN',
        '2,X-Pentose o': 'v2XPentoseO',
        '2,X-Pentose p': 'v2XPentoseP',
        '2,X,X-Hexose a': 'v2XXHexoseA',
        '2,X,X-Hexose b': 'v2XXHexoseB',
        '2,X,X-Hexose c': 'v2XXHexoseC',
        '2,X,X-Hexose d': 'v2XXHexoseD',
        '2,X,X-Hexose e': 'v2XXHexoseE',
        '2,X,X-Hexose f': 'v2XXHexoseF',
        '2,X,X-Hexose g': 'v2XXHexoseG',
        '2,X,X-Hexose h': 'v2XXHexoseH',
        '2,X,X-Hexose i': 'v2XXHexosei',
        '2,X,X-Hexose j': 'v2XXHexoseJ',
        '2,X,X-Hexose k': 'v2XXHexoseK',
        '2,X,X-Hexose l': 'v2XXHexoseL',
        '2,X,X-Hexose m': 'v2XXHexoseM',
        '2,X,X-Hexose n': 'v2XXHexoseN',
        '2,X,X-Hexose o': 'v2XXHexoseO',
        '2,X,X-Hexose p': 'v2XXHexoseP',
        '2,X,X-Rhamnose': 'v2XXRhamnose',
        '3,4-Galactose': 'v34Galactose',
        '3,4-Galactose/3,6-Mannose': 'v34GalactoseOr36Mannose',
        '3,4-Xylose': 'v34Xylose',
        '3,4-Xylose/3,5-Arabinose': 'v34XyloseOr35Arabinose',
        '3,5-Arabinose': 'v35Arabinose',
        '3,6-Galactose': 'v36Galactose',
        '3/4-Mannose': 'v3Or4Mannose',
        '4-GlcA': 'v4GlcA',
        '4-Rhamnose': 'v4Rhamnose',
        '4-Xylose': 'v4Xylose',
        '4-Xylose/5-Arabinose': 'v4XyloseOr5Arabinose',
        '4,6-Glucose': 'v46Glucose',
        '4,6-Mannose': 'v46Mannose',
        '5-Arabinose': 'v5Arabinose',
        'T-Arabinose-f': 'vTArabinoseF',
        'T-Arabinose-f/4-Rhamnose': 'vTArabinoseFOr4Rhamnose',
        'T-Arabinose-p': 'vTArabinoseP',
        'T-Fucose a': 'vTFucoseA',
        'T-Fucose a/T-Rhamnose': 'vTFucoseAOrTRhamnose',
        'T-Fucose b': 'vTFucoseB',
        'T-GalA a': 'vTGalAA',
        'T-GalA b': 'vTGalAB',
        'T-Galactose-f': 'vTGalactoseF',
        'T-Galactose-p': 'vTGalactoseP',
        'T-GlcA': 'vTGlcA',
        'T-Glucose/T-Mannose': 'vTGlucoseOrTMannose',
        'T-Ribose a': 'vTRiboseA',
        'T-Ribose a/T-Arabinose-p': 'vTRiboseAOrTArabinoseP',
        'T-Ribose b': 'vTRiboseB',
        'T-Xylose': 'vTXylose',
        'X-Fucose a': 'vXFucoseA',
        'X-Hexose a': 'vXHexoseA',
        'X-Hexose b': 'vXHexoseB',
        'X-Hexose c': 'vXHexoseC',
        'X-Hexose d': 'vXHexoseD',
        'X-Hexose e': 'vXHexoseE',
        'X-Hexose f': 'vXHexoseF',
        'X-Hexose g': 'vXHexoseG',
        'X-Hexose h': 'vXHexoseH',
        'X-Hexose i': 'vXHexosei',
        'X-Hexose j': 'vXHexoseJ',
        'X-Hexose k': 'vXHexoseK',
        'X-Hexose l': 'vXHexoseL',
        'X-Hexose m': 'vXHexoseM',
        'X-Hexose n': 'vXHexoseN',
        'X-Hexose o': 'vXHexoseO',
        'X-Hexose p': 'vXHexoseP',
        'X-Pentose a': 'vXPentoseA',
        'X-Pentose b': 'vXPentoseB',
        'X-Pentose c': 'vXPentoseC',
        'X-Pentose d': 'vXPentoseD',
        'X-Pentose e': 'vXPentoseE',
        'X-Pentose f': 'vXPentoseF',
        'X-Pentose g': 'vXPentoseG',
        'X-Pentose h': 'vXPentoseH',
        'X-Pentose i': 'vXPentosei',
        'X-Pentose j': 'vXPentoseJ',
        'X-Pentose k': 'vXPentoseK',
        'X-Pentose l': 'vXPentoseL',
        'X-Pentose m': 'vXPentoseM',
        'X-Pentose n': 'vXPentoseN',
        'X-Pentose o': 'vXPentoseO',
        'X-Pentose p': 'vXPentoseP',
        'X,X-Hexose a': 'vXXHexoseA',
        'X,X-Hexose b': 'vXXHexoseB',
        'X,X-Hexose c': 'vXXHexoseC',
        'X,X-Hexose d': 'vXXHexoseD',
        'X,X-Hexose e': 'vXXHexoseE',
        'X,X-Hexose f': 'vXXHexoseF',
        'X,X-Hexose g': 'vXXHexoseG',
        'X,X-Hexose h': 'vXXHexoseH',
        'X,X-Hexose i': 'vXXHexosei',
        'X,X-Hexose j': 'vXXHexoseJ',
        'X,X-Hexose k': 'vXXHexoseK',
        'X,X-Hexose l': 'vXXHexoseL',
        'X,X-Hexose m': 'vXXHexoseM',
        'X,X-Hexose n': 'vXXHexoseN',
        'X,X-Hexose o': 'vXXHexoseO',
        'X,X-Hexose p': 'vXXHexoseP',
        'X,X-Pentose a': 'vXXPentoseA',
        'X,X-Pentose b': 'vXXPentoseB',
        'X,X-Pentose c': 'vXXPentoseC',
        'X,X-Pentose d': 'vXXPentoseD',
        'X,X-Pentose e': 'vXXPentoseE',
        'X,X-Pentose f': 'vXXPentoseF',
        'X,X-Pentose g': 'vXXPentoseG',
        'X,X-Pentose h': 'vXXPentoseH',
        'X,X-Pentose i': 'vXXPentoseI',
        'X,X-Pentose j': 'vXXPentoseJ',
        'X,X-Pentose k': 'vXXPentoseK',
        'X,X-Pentose l': 'vXXPentoseL',
        'X,X-Pentose m': 'vXXPentoseM',
        'X,X-Pentose n': 'vXXPentoseN',
        'X,X-Pentose o': 'vXXPentoseO',
        'X,X-Pentose p': 'vXXPentoseP',
        'X,X,X-Hexose a': 'vXXXHexoseA',
        'X,X,X-Hexose b': 'vXXXHexoseB',
        'X,X,X-Hexose c': 'vXXXHexoseC',
        'X,X,X-Hexose d': 'vXXXHexoseD',
        'X,X,X-Hexose e': 'vXXXHexoseE',
        'X,X,X-Hexose f': 'vXXXHexoseF',
        'X,X,X-Hexose g': 'vXXXHexoseG',
        'X,X,X-Hexose h': 'vXXXHexoseH',
        'X,X,X-Hexose i': 'vXXXHexoseI',
        'X,X,X-Hexose j': 'vXXXHexoseJ',
        'X,X,X-Hexose k': 'vXXXHexoseK',
        'X,X,X-Hexose l': 'vXXXHexoseL',
        'X,X,X-Hexose m': 'vXXXHexoseM',
        'X,X,X-Hexose n': 'vXXXHexoseN',
        'X,X,X-Hexose o': 'vXXXHexoseO',
        'X,X,X-Hexose p': 'vXXXHexoseP',
        'X,X,X-Pentose a': 'vXXXPentoseA',
        'X,X,X-Pentose b': 'vXXXPentoseB',
        'X,X,X-Pentose c': 'vXXXPentoseC',
        'X,X,X-Pentose d': 'vXXXPentoseD',
        'X,X,X-Pentose e': 'vXXXPentoseE',
        'X,X,X-Pentose f': 'vXXXPentoseF',
        'X,X,X-Pentose g': 'vXXXPentoseG',
        'X,X,X-Pentose h': 'vXXXPentoseH',
        'X,X,X-Pentose i': 'vXXXPentoseI',
        'X,X,X-Pentose j': 'vXXXPentoseJ',
        'X,X,X-Pentose k': 'vXXXPentoseK',
        'X,X,X-Pentose l': 'vXXXPentoseL',
        'X,X,X-Pentose m': 'vXXXPentoseM',
        'X,X,X-Pentose n': 'vXXXPentoseN',
        'X,X,X-Pentose o': 'vXXXPentoseO',
        'X,X,X-Pentose p': 'vXXXPentoseP',
    };

    constructor(
        observationDate: Date,
        data?: Array<DataRow>,
        uuid?: string,
        sopVersion?: string
    ) {
        super(observationDate, data, uuid);
        this.observationDate = observationDate;
        this.sopVersion = sopVersion;

        // necessary since super doesnt have linkages in defaultColumnOrder
        if (data) {
            this.appendFieldsToDefaultColumnOrder(data);
            this.markMissingFieldsNull(data);
            this.data = data;
        }

        if (sopVersion) {
            this.sopVersion = sopVersion;
            this.sopVersions.push(sopVersion);
        }
    }

    getGQLMeasurementInputs(): any[] {
        // make sure all analytes are in input obj
        return this.data.map((measurements) => {
            const gqlInput = {};
            this.defaultColumnOrder.forEach((analyte) => {
                if (!(analyte in measurements)) {
                    gqlInput[this.GQL_INPUT_MAP[analyte]] = null;
                } else {
                    gqlInput[this.GQL_INPUT_MAP[analyte]] =
                        measurements[analyte];
                }
            });
            return gqlInput;
        });
    }
}
