import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Process } from '@bcdbio/data';
import { Dictionary } from '@ngrx/entity';

@Component({
    selector: 'bcdbio-parent-process-metadata',
    templateUrl: './parent-process-metadata.component.html',
    styleUrls: ['./parent-process-metadata.component.scss'],
})
export class ParentProcessMetadataComponent implements OnChanges {
    @Input() process: Process;
    groups: any;
    percentEthanol: number;
    metadataGroups: string[];
    processTypeMetadata: string[];

    constructor() {}

    ngOnChanges(): void {
        if (this.process) {
            if (this.process.type === 'Pre-processing') {
                this.processTypeMetadata = [
                    'Pre-processing',
                    'Pre-processing: Enzyme',
                    'Pre-processing: Solvent/Extraction',
                    'Pre-processing: Acid Extraction',
                    'Pre-processing: Base Extraction',
                    'Pre-processing: Enzyme Treatment',
                    'Pre-processing: Centrifugal Filter',
                    'Pre-processing: Other',
                ];
            } else if (this.process.type === 'COG') {
                this.processTypeMetadata = [
                    'COG: Fenton',
                    'COG: Base Cleavage',
                    'COG',
                ];
            } else if (this.process.type === 'Post-processing') {
                this.processTypeMetadata = [
                    'Post-processing',
                    'Post-processing: Ethanol',
                    'Post-processing: Crystallization',
                    'Post-processing: Microfilter',
                    'Post-processing: Centrifugal Filter',
                    'Post-processing: Ultra Filtration',
                    'Post-processing: Nano Filtration',
                    'Post-processing: Spray Drying',
                    'Post-processing: Other',
                ];
            } else {
                this.processTypeMetadata = [this.process.type];
            }

            this.metadataGroups = ['Process', ...this.processTypeMetadata];
            if (
                this.process?.type !== 'Slurry - PBS glycerol' &&
                this.process?.type !== 'Pool slurry - PBS glycerol' &&
                this.process?.type !== 'Micro (pre-inoculum)'
            ) {
                this.metadataGroups.push('Microbio');
            }
            this.groups = this.metadataGroups.reduce((acc: any, name) => {
                if (this.areMetadataValuesForGroup(name)) {
                    acc[name] = {
                        type: name,
                    };
                }
                return acc;
            }, {});
            this.percentEthanol = this.getPercentEthanolValue();
        } else {
            this.groups = {};
            this.percentEthanol = undefined;
        }
    }

    areMetadataValuesForGroup(groupName: string): boolean {
        // console.log('checking for metadata values in:', groupName);
        const metadata = this.process.metadata;
        if (groupName in metadata) {
            for (const mdName of Object.keys(metadata[groupName])) {
                if (metadata[groupName][mdName].toString().length > 0) {
                    return true;
                }
            }
        }

        return false;
    }

    getPercentEthanolValue(): number {
        const metadata = this.process.metadata;
        const procName = this.process.type;
        if (
            metadata[procName] &&
            metadata[procName]['Ethanol volume'] &&
            metadata[procName]['Water volume']
        ) {
            const ethVol = metadata[procName]['Ethanol volume'].toString();
            const h2oVol = metadata[procName]['Water volume'].toString();
            return !isNaN(this.computePercentEthanol(ethVol, h2oVol))
                ? this.computePercentEthanol(ethVol, h2oVol)
                : undefined;
        } else {
            return undefined;
        }
    }

    computePercentEthanol(ethVol: string, h2oVol: string): number {
        const ethVolume = parseFloat(ethVol);
        const h2oVolume = parseFloat(h2oVol);
        return ethVolume / (ethVolume + h2oVolume);
    }
}
