<div class="navbar-nav flex-row justify-content-end">
    <div class="container" *ngIf="auth.user$ | async as user">
        <span>
            Logged in as
            <a routerLink="profile"
                ><strong>{{ user.name }}</strong></a
            >
        </span>
    </div>
    <div class="container d-flex justify-content-end">
        <bcdbio-authentication-button></bcdbio-authentication-button>
    </div>
</div>
