import { DataImportValidator } from './data-import-validator';
import { DataImportData } from '../data/data-import-data';
import { DataImportDataFecalSource } from '../data/data-import-data-fecal-source';
import { Observable, of } from 'rxjs';
import { DataImportType } from '../data-import-type';

export class DataImportValidatorBio extends DataImportValidator {
    protected mappingInfo: DataImportData;

    protected validateHeaders(headerRow: string[], errors: any[]): void {
        for (let h = 0; h < headerRow.length; h++) {
            if (
                !this.mappingInfo.headers.includes(headerRow[h].toLowerCase())
            ) {
                errors.push(`Invalid header: '${headerRow[h]}'.`);
            }
        }
    }

    protected validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): void {
        const headers = headerRow.map((label) => label.toLowerCase());

        for (let i = 0; i < dataRows.length; i++) {
            const row = dataRows[i];
            const donorCode = row[headers.indexOf('donor code')];
            const donorNumber = row[headers.indexOf('donor number')];
            const name = `row ${i + 1}`;

            this.validateRow(name, headers, row, errors);
        }
    }

    validateRow(name: string, headers: string[], row: any[], errors: any[]) {
        if (row.length !== headers.length) {
            errors.push(
                `${name}: Expected ${headers.length} columns.  Received ${row.length}.`
            );
        } else {
            this.mappingInfo.mapping.forEach((m) => {
                const value = row[headers.indexOf(m.importColumnHeader)];
                if (value === 'null') {
                    console.log(
                        `${name}: Skipping validation of null value for '${m.importColumnHeader}'.`
                    );
                } else if (m.validation === 'date') {
                    if (isNaN(Date.parse(value))) {
                        errors.push(
                            `${name} (${value}): Invalid date for '${m.importColumnHeader}'.`
                        );
                    }
                } else if (m.validation === 'number') {
                    if (isNaN(Number.parseFloat(value))) {
                        errors.push(
                            `${name} (${value}): Invalid number for '${m.importColumnHeader}'.`
                        );
                    }
                } else if (m.validation.startsWith('regexp:')) {
                    const regex = m.validation.substr(7);
                    const re = new RegExp(regex);
                    if (!value.match(re)) {
                        errors.push(
                            `${name} (${value}): Does not match /${regex}/ for '${m.importColumnHeader}'.`
                        );
                    }
                }
            });
        }
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        this.mappingInfo = data;

        const errors = [];
        const headerRow = data.getHeaderRow();
        const dataRows = data.getDataRows();
        this.validateHeaders(headerRow, errors);
        this.validateDataRows(headerRow, dataRows, errors);

        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
