import { DataImportPersist } from './data-import-persist';
import { DataImportType } from '../data-import-type';
import { MapperData } from '../mapping/mapper-data';
import { Observable } from 'rxjs';
import { SaveResult } from '../sample-save-result';

export class DataImportPersistMetabAnalytes extends DataImportPersist {
    save(
        data: MapperData,
        filePath: string,
        dataFileType: DataImportType
    ): Observable<SaveResult> {
        return this.sampleApiService.saveMetabAnalytesForSamples(data.samples);
    }
}
