import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    changeStudyListSort,
    getListSearchTerm,
    getStudies,
    getStudyListRetrieved,
    getStudyListSort,
    studyListRequested,
    UiPartialState,
} from '@bcdbio/ui';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Study } from '@bcdbio/udb-graphql';
import { Sort } from '@angular/material/sort';
import { filter, map } from 'rxjs/operators';

export interface StudyRow {
    studyId: string;
    studyName: string;
    studyPeriod: string;
    numTrays: number;
    numCrosses: number;
    objective: string;
    notes: string;
}
export enum DateIdx {
    START_MONTH,
    START_DAY,
    START_YEAR,
    END_MONTH,
    END_DAY,
    END_YEAR,
}

@Component({
    selector: 'bcdbio-study',
    templateUrl: './study.component.html',
    styleUrls: ['./study.component.scss'],
})
export class StudyComponent implements OnInit, OnDestroy {
    studies: Study[];
    studies$: Observable<Study[]> = this.storeUi.select(getStudies);
    sortedStudies: StudyRow[];
    studiesSub: Subscription;
    studyListSortSub: Subscription;
    showSpinner = true;
    indexComparisonOrder = [
        DateIdx.START_YEAR,
        DateIdx.START_MONTH,
        DateIdx.START_DAY,
        DateIdx.END_YEAR,
        DateIdx.END_MONTH,
        DateIdx.END_DAY,
    ];
    studyListRetrieved$: Observable<boolean> = this.storeUi.select(
        getStudyListRetrieved
    );
    studyListSort$: Observable<Sort> = this.storeUi.select(getStudyListSort);
    listSearchTermRetrieved$: Observable<string> =
        this.storeUi.select(getListSearchTerm);
    trunObjective: string;
    trunNotes: string;

    constructor(
        private router: Router,
        private storeUi: Store<UiPartialState>
    ) {}

    ngOnInit(): void {
        this.storeUi.dispatch(studyListRequested());
        this.studiesSub = combineLatest([
            this.studies$,
            this.studyListRetrieved$,
            this.listSearchTermRetrieved$,
        ])
            .pipe(
                map(
                    ([
                        studies,
                        studyListRetrieved,
                        listSearchTermRetrieved,
                    ]) => {
                        const lowerSearchTerm =
                            listSearchTermRetrieved.toLowerCase();
                        return studyListRetrieved
                            ? studies.filter(
                                  (s) =>
                                      s.studyId
                                          .toLowerCase()
                                          .includes(lowerSearchTerm) ||
                                      s.studyName
                                          .toLowerCase()
                                          .includes(lowerSearchTerm)
                              )
                            : null;
                    }
                ),
                filter((studies) => !!studies)
            )
            .subscribe((studies) => {
                this.sortedStudies = studies?.map((study) =>
                    this.formStudyRow(study)
                );
                this.showSpinner = false;
            });
        this.studyListSortSub = this.studyListSort$.subscribe((sort) => {
            this.sortStudies(sort);
        });
    }

    changeStudiesSort(sort: Sort) {
        this.storeUi.dispatch(changeStudyListSort({ sort }));
    }

    ngOnDestroy(): void {
        if (this.studiesSub) {
            this.studiesSub.unsubscribe();
        }
        if (this.studyListSortSub) {
            this.studyListSortSub.unsubscribe();
        }
    }

    formStudyRow(study: Study): StudyRow {
        this.trunObjective = study.objective;
        if (this.trunObjective.length > 125)
            this.trunObjective =
                this.trunObjective.substring(
                    0,
                    this.trunObjective.lastIndexOf(' ', 125)
                ) + '(...)';
        this.trunNotes = study.notes;
        if (this.trunNotes && this.trunNotes.length > 125)
            this.trunNotes =
                this.trunNotes.substring(
                    0,
                    this.trunNotes.lastIndexOf(' ', 125)
                ) + '(...)';
        return {
            studyId: study.studyId,
            studyName: study.studyName,
            studyPeriod: study.startDate + ' - ' + study.endDate,
            numTrays: study.studyNodes[0]?.numTrays,
            numCrosses: study.studyNodes[0]?.numCrosses,
            objective: this.trunObjective,
            notes: this.trunNotes,
        };
    }

    sortStudies(sort: Sort) {
        const data = this.sortedStudies && this.sortedStudies.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedStudies = data;
            return;
        }

        this.sortedStudies = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'studyName':
                    return compare(a.studyName, b.studyName, isAsc);
                case 'studyId':
                    return compare(a.studyId, b.studyId, isAsc);
                case 'studyPeriod':
                    return this.compareStudyPeriods(
                        a.studyPeriod,
                        b.studyPeriod,
                        isAsc
                    );
                case 'numCrosses':
                    return compare(a.numCrosses, b.numCrosses, isAsc);
                case 'numTrays':
                    return compare(a.numTrays, b.numTrays, isAsc);
                case 'objective':
                    return compare(a.objective, b.objective, isAsc);
                case 'notes':
                    return compare(a.notes, b.notes, isAsc);
            }
        });
    }

    createNewStudy() {
        this.router.navigate(['/study/new']);
    }

    compareStudyPeriods(
        studyPeriodA: string,
        studyPeriodB: string,
        isAsc: boolean
    ) {
        const studyPeriodAParts = studyPeriodA.replace(' - ', '/').split('/');
        const studyPeriodBParts = studyPeriodB.replace(' - ', '/').split('/');
        let index;

        for (const idx of this.indexComparisonOrder) {
            if (studyPeriodAParts[idx] !== studyPeriodBParts[idx]) {
                index = idx;
                break;
            }
        }
        return (
            (+studyPeriodAParts[index] < +studyPeriodBParts[index] ? -1 : 1) *
            (isAsc ? 1 : -1)
        );
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
