<h4>Metadata</h4>
<ng-container *ngIf="hasMetadata; else elseBlock">
    <div class="metadata-group">
        <ng-container *ngFor="let field of orderedMetadata">
            <div
                *ngIf="
                    field.value &&
                    field.name !== 'ndaMta' &&
                    field.name !== 'lastEditDate' &&
                    field.name !== 'lastEditUser'
                "
            >
                <label class="metadata-label"
                    >{{ field.name | unCamelCase }}:</label
                >
                <p class="metadata-value">
                    {{ field.value | metadataFormatPipe }}
                </p>
            </div>
            <div
                *ngIf="field.value && field.name === 'ndaMta'"
                class="emphasize-metadata"
            >
                <label class="metadata-label">NDA/MTA:</label>
                <p class="metadata-value">{{ field.value || '--' }}</p>
            </div>
        </ng-container>
    </div>
    <div class="metadata-group">
        <ng-container *ngFor="let field of orderedMetadata">
            <div
                *ngIf="
                    field.value &&
                    (field.name === 'lastEditDate' ||
                        field.name === 'lastEditUser')
                "
            >
                <label class="metadata-label"
                    >{{ field.name | unCamelCase }}:</label
                >
                <p class="metadata-value">
                    {{ field.value | metadataFormatPipe }}
                </p>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #elseBlock>
    <p>No metadata present.</p>
</ng-template>
