import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UiPartialState } from '@bcdbio/ui';
import { Store } from '@ngrx/store';
import * as UiActions from '../../../../libs/ui/src/lib/+state/ui.actions';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bcdbio-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'udb';
    authSub: Subscription;
    showSpinner = true;

    constructor(
        public auth: AuthService,
        private storeUi: Store<UiPartialState>
    ) {}

    ngOnInit(): void {
        this.authSub = this.auth.isLoading$.subscribe((authenticated) => {
            if (authenticated) {
                this.showSpinner = false;
                this.storeUi.dispatch(UiActions.userListRequested());
                this.storeUi.dispatch(UiActions.rolesListRequested());
            }
        });
    }

    ngOnDestroy() {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
    }
}
