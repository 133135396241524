import { DataImportValidator } from './data-import-validator';
import { DataImportData } from '../data/data-import-data';
import { DataImportDataMono } from '../data/data-import-data-mono';
import { Observable, of } from 'rxjs';
import { DataImportType } from '../data-import-type';

export class DataImportValidatorMono extends DataImportValidator {
    private static validateHeaders(headerRow: string[], errors: any[]): void {
        for (let h = 0; h < headerRow.length; h++) {
            if (!DataImportDataMono.HEADERS.includes(headerRow[h])) {
                errors.push(`Invalid header: '${headerRow[h]}'.`);
            }
        }
    }

    private static validateDataRows(
        headerRow: string[],
        dataRows: string[][],
        errors: any[]
    ): void {
        for (let i = 0; i < dataRows.length; i++) {
            const name = dataRows[i][headerRow.indexOf('Name')];
            const dateTimeStr =
                dataRows[i][headerRow.indexOf('Acq. Date-Time')];
            const sopValue = dataRows[i][headerRow.indexOf('SOP Version')];

            if (dataRows[i].length !== headerRow.length) {
                errors.push(
                    `${name} (${dateTimeStr}): Expected ${headerRow.length} columns.  Received ${dataRows[i].length}.`
                );
            }
            if (isNaN(Date.parse(dateTimeStr))) {
                errors.push(
                    `${name} (${dateTimeStr}): Invalid date for 'Acq. Date-Time'.`
                );
            }
            if (
                sopValue === 'null' ||
                sopValue === null ||
                sopValue === undefined ||
                sopValue.trim() === ''
            ) {
                errors.push(
                    `${name}: The 'SOP Version' column must not be empty.`
                );
            }
        }
    }

    validate(
        file: File,
        data: DataImportData,
        dataFileType: DataImportType
    ): Observable<{ isValid: boolean; errors: string[] }> {
        const errors = [];
        const headerRow = data.getHeaderRow();
        const dataRows = data.getDataRows();
        DataImportValidatorMono.validateHeaders(headerRow, errors);
        DataImportValidatorMono.validateDataRows(headerRow, dataRows, errors);

        return of({
            isValid: errors.length < 1,
            errors: errors,
        });
    }
}
