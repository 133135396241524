import { GenericDataset } from './generic-dataset.model';
import { DataImportType } from '../data-import/data-import-type';

export class FileDataset extends GenericDataset {
    filePath: string;
    dataType: DataImportType;
    uuid: string;
    constructor(
        observationDate: Date,
        dataType: DataImportType,
        filePath: string,
        uuid?: string
    ) {
        super(observationDate);
        this.dataType = dataType;
        this.filePath = filePath;
        this.uuid = uuid;
    }
}
