<div class="col-lg-12 sample-action-panel wrapper">
    <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="container" *ngIf="{ results: (searchResults$ | async) } as obs">
        <div class="col-lg-12">
            <h5>Selected Export Samples</h5>
            <mat-checkbox
                ngDefaultControl
                (change)="checkAll()"
                id="REM_ALL"
                [(ngModel)]="checkREM_ALL"
            >
            </mat-checkbox
            >&nbsp;{{ select_msg }}

            <table class="table table-hover">
                <thead class="thead-light">
                    <tr>
                        <th>Remove</th>
                        <th>Sample (ID)</th>
                    </tr>
                </thead>
                <ng-container>
                    <tr *ngFor="let sample of obs.results">
                        <td>
                            <mat-checkbox
                                ngDefaultControl
                                id="REM_{{ sample }}"
                            >
                            </mat-checkbox>
                        </td>

                        <td>
                            <a [routerLink]="['/sample-detail', sample]">
                                {{ sample }}
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
        <div>
            <button
                class="btn btn-outline-primary"
                type="button"
                (click)="removeSamples()"
                [disabled]="
                    !samplesSelectedToExport ||
                    samplesSelectedToExport?.length === 0
                "
            >
                Remove
            </button>
            &nbsp;&nbsp;
            <bcdbio-export-samples
                *ngIf="samplesSelectedToExport"
                [buttonLabel]="'Export'"
                [samplesToExport]="samplesSelectedToExport"
                [includeMetadata]="true"
                (clickExport)="exportDone($event)"
            ></bcdbio-export-samples>
            &nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-outline-primary"
                (click)="close()"
            >
                Close
            </button>
        </div>
    </div>
</div>
