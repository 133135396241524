interface AppData {
    envName: string;
    authDomain: string;
    authClientId: string;
    authAudience: string;
    graphqlUrl: string;
    importFileUrl: string;
    importFilePath: string;
    importFileBucket: string;
    awsRegion: string;
    cognitoIdentityPoolId: string;
}

const appData = (window as any).APP_DATA
    ? ((window as any).APP_DATA as AppData)
    : ({
          envName: '$ENV_NAME',
          authDomain: '$AUTH_DOMAIN',
          authClientId: '$AUTH_CLIENT_ID',
          authAudience: '$AUTH_AUDIENCE',
          graphqlUrl: '$GRAPHQL_URL',
          importFileUrl: '$IMPORT_FILE_URL',
          importFilePath: '$IMPORT_FILE_PATH',
          importFileBucket: '$IMPORT_FILE_BUCKET',
          awsRegion: '$AWS_REGION',
          cognitoIdentityPoolId: '$COGNITO_IDENTITY_POOL_ID',
      } as AppData);

export const environment = {
    production: true,
    envName: appData.envName,
    auth: {
        domain: appData.authDomain,
        clientId: appData.authClientId,
        audience: appData.authAudience,
        redirectUri: window.location.origin,
    },
    graphqlUrl: appData.graphqlUrl,
    importArchive: {
        endpointUrl: appData.importFileUrl,
        importFilePath: appData.importFilePath,
        importFileBucket: appData.importFileBucket,
        awsRegion: appData.awsRegion,
        cognitoIdentityPoolId: appData.cognitoIdentityPoolId,
    },
};
