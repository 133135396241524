export interface ImportMapping {
    importColumnHeader: string;
    neo4jName: string;
    validation: string;
}
export abstract class DataImportData {
    data: Array<string[]>;
    public headers: string[];
    public mapping: ImportMapping[];

    public getName(headers: string[], row: string[]) {
        return '';
    }
    public getType() {
        return '';
    }

    constructor(data: Array<string[]>) {
        this.data = data;
    }

    public getHeaderRow(): string[] {
        if (this.data && this.data.length > 0) {
            return this.data[0].map((cellValue) => cellValue.trim());
        }
        return null;
    }

    public getDataRows(): Array<string[]> {
        if (this.data && this.data.length > 1) {
            return (
                this.data
                    .slice(1)
                    // fill blank cells with null
                    .map((row) => {
                        return row.map((val) =>
                            val === '' ? 'null' : val.trim()
                        );
                    })
            );
        }
        return [];
    }
}

export interface DataImportDataResults {
    data: DataImportData;
    errors: string[];
}
