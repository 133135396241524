import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '@bcdbio/data';
import { MetadataDisplay } from '../sample-metadata/sample-metadata.component';
import * as moment from 'moment';

@Component({
    selector: 'bcdbio-source-sample-metadata',
    templateUrl: './source-sample-metadata.component.html',
    styleUrls: ['./source-sample-metadata.component.scss'],
})
export class SourceSampleMetadataComponent implements OnInit {
    @Input() sample: Sample;

    orderedMetadata: MetadataDisplay[];

    constructor() {}

    ngOnInit(): void {
        this.orderedMetadata = this.orderMetadata(this.sample);
    }

    hasMetaData(sample: Sample): boolean {
        return (
            sample.source &&
            sample.source.metadata &&
            Object.keys(sample.source.metadata).length > 0
        );
    }

    displayMetadataValue(value) {
        if (value instanceof Date) {
            const mmt = moment.utc(value);
            return mmt.isValid() ? mmt.format('M/D/YYYY') : '--';
        } else {
            return value.toString();
        }
    }

    orderMetadata(sample: Sample): MetadataDisplay[] {
        const metadata = sample.source.metadata;
        if (sample.displayedSourceSampleMetadata) {
            return sample.displayedSourceSampleMetadata.reduce((acc, m) => {
                const obj = {
                    name: m,
                    value: metadata[m] || ' ',
                } as MetadataDisplay;
                acc.push(obj);
                return acc;
            }, []);
        } else {
            return [];
        }
    }
}
